import React, { useState, useRef, useEffect } from "react";
import { Button, Dropdown, Form, Modal } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { BsThreeDots } from "react-icons/bs";
import { toast } from "react-toastify";
import { PortfolioDashboard } from "../../../Common/Services/PortfolioDashboard";
import AddRenameModal from "./AddRenameModal";
import { resolve, reject } from "q";
import OutsideClickHandler from "react-outside-click-handler";

interface DropDownListProps {
  data: any[]; // List of items to display
  placeholder?: string; // Placeholder text for the dropdown
  onItemSelected: (item: any) => void; // Callback when an item is selected
  onCreateDashboard: () => void;
  canCreateNewDashboard: boolean,
}

function sortData(data) {
  return [...data].sort((a, b) => a.orderBy - b.orderBy);
}

const defaultItem = {
  dashboardName: "",
  description: "",
};

export const DashboardsListDropdown: React.FC<any> = ({
  data,
  placeholder = "Select an option",
  onItemSelected,
  defaultValue,
  onCreateDashboard,
  canCreateNewDashboard
}) => {
  const [selectedItem, setSelectedItem] = useState<any | null>(null);
  const [editItem, setEditItem] = useState<any | null>(null);
  const [filterData, setFilterData] = useState(data);
  const [inputValue, setInputValue] = useState("");
  const [mode, setMode] = useState<any>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [editDropdown, setEditdropdown] = useState<any | null>(null);
  const [userClickPosition, setUserClickPosition] = useState(0);

  useEffect(() => {
    // const sortedData=sortData(data)
    setFilterData(data);
  }, [data]);

  useEffect(() => {
    // if (selectedItem?.dashboardId != defaultValue) {
      const _selectedItem = data.find(
        (item) => item.dashboardId == defaultValue
      );

      if (selectedItem?.dashboardName != _selectedItem?.dashboardName){
        setSelectedItem(_selectedItem);
      }

     
    // }
  }, [defaultValue,data ]);

  // Handle dropdown search/filter
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase();
    const filteredItems = data.filter((item) =>
      item.label.toLowerCase().includes(value)
    );
    setFilterData(filteredItems);
    setInputValue(e.target.value);
  };

  const handleDropdownShow =()=>{
    setIsDropdownOpen(true)
  }

  const handleDropdownHide =()=>{
    setIsDropdownOpen(false)
  }

  // Handle selection of an item
  const handleItemSelect = (item: any) => {
    if(item.dashboardId ==  selectedItem.dashboardId){
      return 
    }
    setSelectedItem(item);
    onItemSelected(item);
    setInputValue(""); // Reset search box
    // const sortedData=sortData(data)
    setFilterData(data); // Reset filter
  };

  const handleAllowDuplicateConfirmation = (updatedItem: any) => {
    confirmAlert({
      title: "Duplicate Dashboard Name",
      message: "This name already exists. Do you want to keep it?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            handleAddRenameSave(updatedItem, 1);
          },
        },
        {
          label: "No",
          onClick: () => {
            setIsSubmitting(false);
          
            reject()
          },
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  };

  const resetRenameModal =()=>{
    setMode(null);
    setEditItem(null);

    setIsSubmitting(false);
  }

  const handleAddRenameSave = (updatedItem: any, allowDuplicate = 0) => {
    const requestData: any = {
      DashboardName: updatedItem.dashboardName,
      Description: updatedItem.description,
      AllowDuplicate: allowDuplicate,
     
    };
    if (mode == "edit") {
      requestData.DashboardId = updatedItem.dashboardId;
      requestData.DashboardLayout = updatedItem.dashboardLayout;
      // requestData.DefaultDaterange = updatedItem.defaultDaterange;
      // requestData.DefaultProperties = updatedItem.defaultProperties;
    } else if (mode == "add") {
      requestData.DashboardLayout = "3";
      requestData.TemplateId=updatedItem?.templateId
      // requestData.DefaultDaterange = "3";
      // requestData.DefaultProperties = 0;
    }

    setIsSubmitting(true);
    PortfolioDashboard.AddEditDashboardMaster(requestData)
      .then((res) => {
        if (res.success) {
          let message = "Dashboard added successfully.";

          if (mode == "edit") {
            message = "Dashboard updated successfully.";
            onCreateDashboard();
          } else {
            onCreateDashboard(res.result);
          }
          setShowRenameModal(false);
          toast.success(message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          resetRenameModal()
          
        } else {
          if (res?.result?.hasduplicate) {
            handleAllowDuplicateConfirmation(updatedItem);
          }
          else{
            toast.error("Something went wrong.", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            resetRenameModal()
          }
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        resetRenameModal()
      })
    
  };

  const handleDeleteConfirmation = (DashboardId) => {
    confirmAlert({
      title: "Delete Dashboard",
      message: "Are you sure you want to Delete Dashboard?",
      buttons: [
        {
          label: "Yes",
          onClick: () => handleDeleteDashboardMaster(DashboardId),
        },
        {
          label: "No",
          onClick: () => reject(),
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  };

  const handleDeleteDashboardMaster = (DashboardId: any) => {
    const requestData = {
      DashboardId: DashboardId,
    };
    PortfolioDashboard.DeleteDashboardMaster(requestData)
      .then((res) => {
     
        if (res.success) {
          toast.success("Dashboard deleted successfully.", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          onCreateDashboard(null, true);
        } else {
          toast.error("Something went wrong.", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      })
      .finally(() => {
       
      });
  };

  const handleAddRenameClose = () => {
    if(mode == "edit"){
      setIsDropdownOpen(true)
    }
    setShowRenameModal(false);
    // setSelectedItem(null);
    setMode(null);
    setEditItem(null);
  };

  const handleRename = (item) => {
    setEditItem(item);
    setShowRenameModal(true);
    handleDropdownHide()
    setEditdropdown(null)
    setMode("edit");
  };

  const handleCreateModal = (e) => {
    e.stopPropagation();
    setShowRenameModal(true);

    setMode("add");
  };
const showDropdown = (e, id) => {
  e.preventDefault(); 
  e.stopPropagation();
  setEditdropdown(id)
  const { clientX, clientY } = e;
  console.log("position" + clientY);
  setUserClickPosition(clientY);
  
}
const handleToggleDropdown = () => {
  // setEditdropdown(null)
  setIsDropdownOpen((prev) => {
    if (prev == true) {
      setEditdropdown(null);
    }
    return !prev;
  });
};


  return (
    <>
      {data.length == 0 && canCreateNewDashboard ?  
        <div className="create-dashboard cursor-pointer" onClick={handleCreateModal}>
          <span>Create Dashboard</span>
        </div> :
      <Dropdown show={isDropdownOpen} onToggle={handleToggleDropdown}>
        <Dropdown.Toggle
          id="dropdown-custom-components"
          className="dropdown-toggle"
        >
          <EllipsisWithTooltip placement="bottom">
            {selectedItem ? selectedItem.dashboardName : placeholder}
          </EllipsisWithTooltip>
        </Dropdown.Toggle>

        <Dropdown.Menu className="dropdown-menu">
          {/* Dropdown items */}
          <div className="list-items" onWheel={() => setEditdropdown(false)}>
            {filterData.length > 0 ? (
              filterData.map((item, index) => (
                <Dropdown.Item
                  key={item.dashboardId.toString()}
                  onClick={() => handleItemSelect(item)}
                  className={`${item.dashboardId == selectedItem?.dashboardId ? "active" :"" } ${item.disabled ? "disabled" : ""}`}
                  disabled={item.disabled}
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <div className="d-flex align-items-center">
                        <div className="dashboard-name">
                          <EllipsisWithTooltip placement="bottom">
                            {item.dashboardName}
                          </EllipsisWithTooltip>
                        </div>
                        <span className={`badge type ${item.orderBy == 1 ? "owner" : "shared"}`}>
                          {item.orderBy == 1 ? "Owner" : "Shared"}
                        </span>
                      </div>
                      <div className="item-description">
                        <EllipsisWithTooltip placement="bottom">
                          {item.description}
                        </EllipsisWithTooltip>
                      </div>
                      
                    </div>
                    {/* <OutsideClickHandler onOutsideClick={hideDropdown}> */}
                  
                     {item.orderBy == 1 ? <Button  onClick={(e) => showDropdown(e, item.dashboardId)} className="ml-auto">
                        <BsThreeDots />
                      </Button> : <></>}
                    {/* </OutsideClickHandler> */}
                  </div>
                  <div className="edit-dropdown">
                      { editDropdown == item.dashboardId && 
                      <div className="edit-menu" style={{ top: (userClickPosition - 80) + "px"}}>
                        <div className="item">
                          <Button
                            onClick={(e) => {
                              e.preventDefault(); 
                              e.stopPropagation();
                              handleRename(item);
                            }}
                          >
                            Rename
                          </Button>
                        </div>
                        <div className="item">
                          <Button
                            onClick={(e) => {
                              e.preventDefault(); 
                              e.stopPropagation();
                              handleDeleteConfirmation(item.dashboardId);
                            }}
                            className="delete-btn"
                          >
                            Delete
                          </Button>
                        </div> 
                      </div>
                      }
                      
                    </div>
                </Dropdown.Item>
              ))
            ) : (
              <div className="no-results">No results found</div>
            )}
          </div>

          <Dropdown.Item
            className="view-all border-top border-bottom-0"
            onClick={(e)=>{
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <div>View All</div>
           
          </Dropdown.Item>

          {/* "Create Dashboard" option */}
          { canCreateNewDashboard &&<Dropdown.Item
            className="create-dashboard"
            onClick={handleCreateModal}
          >
            <span>Create New Dashboard</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
            >
              <path
                d="M13.5 9.75H9.75V13.5C9.75 13.9125 9.4125 14.25 9 14.25C8.5875 14.25 8.25 13.9125 8.25 13.5V9.75H4.5C4.0875 9.75 3.75 9.4125 3.75 9C3.75 8.5875 4.0875 8.25 4.5 8.25H8.25V4.5C8.25 4.0875 8.5875 3.75 9 3.75C9.4125 3.75 9.75 4.0875 9.75 4.5V8.25H13.5C13.9125 8.25 14.25 8.5875 14.25 9C14.25 9.4125 13.9125 9.75 13.5 9.75Z"
                fill="#2CAF92"
              />
            </svg>
          </Dropdown.Item>}
        </Dropdown.Menu>
      </Dropdown>}

      {mode && (
        <AddRenameModal
          mode={mode}
          show={showRenameModal}
          item={mode == "edit" ? editItem : defaultItem}
          onSave={handleAddRenameSave}
          onClose={handleAddRenameClose}
          isSubmitting={isSubmitting}
        />
      )}
    </>
  );
};

export default DashboardsListDropdown;
