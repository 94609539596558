import React from "react";
import { Dropdown, Form, OverlayTrigger, Tooltip, Container, Modal, Button, Spinner, } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { resolve, reject } from "q";
import $ from "jquery";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { Utils } from "../../../Common/Utilis";
import { ReactPageLoader } from "../../../Common/Components/ReactPageLoader";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import TableSearch from "../../../Common/Components/TableSearch";
import OutsideClickHandler from "react-outside-click-handler";
import { EntityManagementService } from "../../../Common/Services/EntityManagementService";
import { SingleSearchDropdownList } from "../../../Common/Components/SingleSearchDropdownList";
import { TipsEmployee } from "../../../Common/Components/TipsEmployee";
import { CAType } from "./CAType";
import { faBullseye } from "@fortawesome/free-solid-svg-icons";
import { CASubType } from "./CASubType";
import _ from "lodash";
import { confirmAlert } from "react-confirm-alert";

export class CommonAreas extends React.Component<any, any> {
  private inputElement: any;
  private inputElement1: any;
  private checkAllRef: any;
  private commonArea: any;
  private input: any;
  private tableSearchRef: any;
  constructor(props: any) {
    super(props);
    this.tableSearchRef = React.createRef();
    this.checkAllRef = React.createRef();
    this.inputElement = React.createRef(); 
    this.commonArea = React.createRef(); 
    this.input = React.createRef();
    this.state = {
      Status: "Status",
      viewType: "",
      CADetails:[],
      date:
        JSON.parse(localStorage.storage).currentUtcTime !== ""
          ? new Date(JSON.parse(localStorage.storage).currentUtcTime)
          : new Date(),
      selectedRows: [],
      preSelectedRows: [],
      completedCheakox: [],
      SubTypes: [{ SubType: "ABC" }, { SubType: "EFG" }, { SubType: "HIJ" }],
      showSubTypeModal: false,
      searchSubTypes: "",
      TypeList: [],
      inputValue: "",
      isDropdownOpen: false,
      loaderTrue:false,
      footerCAStatus:"Status",
      isAdded:false,
      Allstatus: [{ Status: "Online" },{ Status: "Offline" },{ Status: "Archive" }],
      sortingField: "roomName",
      typeId : 0,
      discardTableCADetails: [],
      SubTypeList : this.props.SubTypeList,
      load : false,
      tableLoader: false,
      footerCommonSubTypeId: 0,
      footerCommonSubType: "Subtype",
      allSameTypeName : false,
      footerSubTypeFilterList : [],
      savingChanges : false,
      copyCADetails :[],
      noLocationMatch : false,
      sortingOrder : "asc",
      searchValue : false
    };
  }

  componentDidMount() {
    this.getCADetails();
    this.GetFacilityType();
   // this.getCASubTypes();
   if(this.props.type == "commonareas"){
    this.setState({ tableLoader: true });
  }


  }

  getCADetails = () => {
    EntityManagementService.GetCommonAreaDetails(this.props.hotelId)
      .then((result: any) => {
        let data = [];
        let count = 0;
        data = Object.assign([], JSON.parse(JSON.stringify(result)));
        data.map((item: any) => {
          count = count + 1;
          item.id = count;
          item.isNewRow = false;
          item.LocationNameValidation = false;
          item.typeValidation = false;
          item.LocationNameValidation_Mssg = false;
          item.nots_validation = false;

        });
        this.props.CAChanges(false);
        this.setState({ CADetails: data, discardTableCADetails: data, tableLoader: false , copyCADetails : data });

      })
      .catch((error) => {
        Utils.toastError(error.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "CommonArea",
        });
      });
  }

  GetFacilityType = () => {
    this.setState({ loaderTrue: true });
    EntityManagementService.GetFacilityType()
    .then(async (result: any) => {
      console.log("result of FT",result.data.result);
      this.setState({TypeList : result.data.result});
      this.setState({ loaderTrue: false });
      
    })
  }

  // getCASubTypes =  (typeID) => {
  //  this.setState({ loaderTrue: true });
  //   EntityManagementService.GetCommonAreaRoomSubType()
  //   .then(async (result: any) => {bulkCancleClick 
  //     this.setState({SubTypeList : result.data.result});
  //     this.setState({ loaderTrue: false });
  //   })
  // }

  // getCASubTypes =  () => {
  //  // this.setState({ loaderTrue: true });
  //    EntityManagementService.GetCommonAreaRoomSubType(this.props.hotelId)
  //    .then(async (result: any) => {
     
  //     let newtableData: any = [];
  //     let finalTable: any = [];
  //     let count = 0;
  //     newtableData = Object.assign([], JSON.parse(JSON.stringify(result.data.result)));
  //     newtableData.map((item: any) => {
  //       if(item.type==="Common")
  //       {
  //       let newRow = {} as any;
  //       newRow.uniqueno = item.subTypeID;
  //       newRow.subTypeName = item.subTypeName;
  //       newRow.typeID = item.typeID;
  //       finalTable.push(newRow);
  //       }

  //     });
  //     this.setState({ SubTypeList: finalTable }, () => {
  //       console.log("SubTypeList updated:", this.state.SubTypeList);
  //     });
       
  //     // this.setState({ loaderTrue: false });
  //    })
  //  }






  handleToggleClick = (Type) => {
    this.setState({ viewType: Type });
  }

  caStatusChangesData=(row: any, rowIndex: any, item: any)=>{
    let newTableData = [...this.state.CADetails];
    newTableData = newTableData.map((d) => {
      if (d.id === row.id) {
        return {
          ...d,
          status: item.Status,
          // contractorEmail_validation: false,
         
        };
      } else {
        return d;
      }
    });
    this.setState(
      (curr: any) => ({
        ...curr,
        CADetails: newTableData,
        isAdded: true,
      }));
  }

  CAStatus = (cell: any, row: any, rowIndex: any) => {
    return (
      <div className="payrollExport">
        <Dropdown className="status-dropdown">
          <Dropdown.Toggle
            id="dropdown-importFrom"
            className={row.status.toLowerCase() == "offline" ? "red" : row.status.toLowerCase() == "online" ? "green" : ""}
          // disabled={!this.props.parentState.permissionManageSettings}
          >
            <EllipsisWithTooltip placement="bottom">{row.status}</EllipsisWithTooltip>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {this.state.Allstatus.map((item: any, idx: any) => (
              <Dropdown.Item
                key={idx}
                onClick={() => {
                  this.caStatusChangesData(row, rowIndex, item);
                }}
                className={item.Status.toLowerCase() == "offline" ? "red" : item.Status.toLowerCase() == "online" ? "green" : ""}
              >
                <EllipsisWithTooltip placement="bottom">{item.Status}</EllipsisWithTooltip>
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  };

  CASubtype = (cell: any, row: any, rowIndex: any) => {
    return (
      <CASubType
      ref={this.commonArea}
      row={row}
      SubTypeList={this.state.SubTypeList}
      //employeeList={this.state.tipsEmployeeList}
      subTypeSelected={this.subTypeSelected}
      AddNewSubType={this.AddNewSubType}
      hotelId = {this.props.hotelId}
      getCASubTypes = {this.props.getCASubTypes}

    />
    );

  };

  onNameChange = (e: any, row: any, rowIndex: any) => {
    let inputId = `${rowIndex}name`;
    let value = e.target.value;
    let cursorIndex = e.target.selectionStart;

    let newTableData = [...this.state.CADetails];
    newTableData = newTableData.map((d) => {
      if (d.id === row.id) {
        return {
          ...d,
          roomname: value,
          LocationNameValidation: false,
        };
      } else {
        return d;
      }
    });
    this.setState(
      (curr: any) => ({
        ...curr,
        CADetails: newTableData,
        isAdded: true,
      }),
      () => {
        $("#" + inputId)
          .focus()
          .prop("selectionEnd", cursorIndex);
      }
    );
    this.props.CAChanges(true)
  }

  

    onNameChangeBlur = (e: any, row: any, rowIndex: any) => {

    let copyTabletableData: any = []; 
    let newTableData = [...this.state.CADetails];   
    copyTabletableData = Object.assign([], this.state.CADetails); 
    newTableData.map((item: any) => {     
      if (item.roomname !== "") {
        let row = copyTabletableData.filter((x) => x.roomname?.toLowerCase() === item?.roomname.toLowerCase() && x.typeName?.toLowerCase() == item?.typeName?.toLowerCase());
        if (row.length > 1) {        
          item.LocationNameValidation_Mssg = true;
        } else {
          item.LocationNameValidation= false;
          item.LocationNameValidation_Mssg = false;
        }
      } 
    });

    this.setState({CADetails:[]},()=>{
      this.setState({CADetails:newTableData})
    });
  }



  CAName = (cell: any, row: any, rowIndex: any) => {
    return (
      <div className="email-formatter">
        <Form.Group>
          <OverlayTrigger
              placement="bottom"
              delay={{ show: 100, hide: 200 }}
              overlay={
                <Tooltip id={"gName"} className={row.roomname?.length > 20 ? "d-block" : "d-none"}>
                {row.roomname}
              </Tooltip>
              }>
            <Form.Control
              ref={this.input}
              className={row.LocationNameValidation || row.LocationNameValidation_Mssg? "alert-danger" : ""}
              id={`${rowIndex}name`}
              //placeholder="-"
              value={row.roomname}
              // disabled={!this.props.parentState.permissionManageSettings}
              onChange={(e: any) => this.onNameChange(e, row, rowIndex)}
              onBlur={(e: any) => this.onNameChangeBlur(e, row, rowIndex)}
              max={500}
              autoComplete="Off"
            />
          </OverlayTrigger>
        </Form.Group>
      </div>
    );
  };

  chkCA = (cell: any, row: any, rowIndex: any) => {
    return (
      <div className="email-formatter">
        <div className="bdr-left height-48 d-flex align-items-center">
          <Form.Check
            custom
            //id={"chk1_" + header.name + row.id}
            // key={"chk1_" + header.name + row.id}
            type="checkbox"
            label={""}
          //checked={row[header.name] === "Yes" ? true : false}
          //  onChange={(e) => this.handleCheckbox(e, row, header.name)}
          // disabled={this.state.disabledCheckboxes[`${row["ehid"]}_${row["id"]}`]}
          />
        </div>
      </div>
    );
  };



  CAType = (cell: any, row: any, rowIndex: any) => {
return(
    <CAType
                row={row}
                TypeList={this.state.TypeList}
                //employeeList={this.state.tipsEmployeeList}
                typeSelected={this.typeSelected}
              />
)

  };

  onNotesChange = (e: any, row: any, rowIndex: any) => {
    let inputId = `${rowIndex}notes`;
    let value = e.target.value;
    let cursorIndex = e.target.selectionStart;

    let newTableData = [...this.state.CADetails];
    newTableData = newTableData.map((d) => {
      if (d.id === row.id) {
        return {
          ...d,
          notes: value,
          nots_validation: false,
        };
      } else {
        return d;
      }
    });
    this.setState(
      (curr: any) => ({
        ...curr,
        CADetails: newTableData,
        isAdded: true,
      }),
      () => {
        $("#" + inputId)
          .focus()
          .prop("selectionEnd", cursorIndex);
      }
    );
  }

  CANotes = (cell: any, row: any, rowIndex: any) => {
    return (
      <div className="email-formatter">
        <Form.Group>
          <OverlayTrigger
              placement="top"
            delay={{ show: 100, hide: 200 }}
            overlay={
              <Tooltip id={"gName"} className={row.notes?.length > 25 ? "d-block width-300" : "d-none width-300"}>
              {row.notes}
            </Tooltip>
            }>
            <Form.Control
             className={row.nots_validation ? "alert-danger w-100" : "w-100"}
              id={`${rowIndex}notes`}
              //placeholder="-"
              value={row.notes}
              // disabled={!this.props.parentState.permissionManageSettings}
              onChange={(e: any) => this.onNotesChange(e, row, rowIndex)}
              // onBlur={(e: any) => this.onEmailBlur(e, row, rowIndex)}
              autoComplete="Off"
             // maxLength={500}
            />
          </OverlayTrigger>
        </Form.Group>
      </div>
    );
  };

  caRemoveButton = (cell: any, row: any, rowIndex: any) => {
    if (row.isNewRow === true) {
      return (
        <div className="email-formatter">
          <button
            type="button"
            className="btn btn-primary fa-trash"
            // onClick={this.handleRemoveNewRow}
            onClick={(e: any) => this.handleRemoveNewRow(e, row, rowIndex)}
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z"
                fill="#2caf92"
              />
            </svg>
          </button>
        </div>

      );
    }
  };
  handleRemoveNewRow = (e: any, row: any, rowIndex: any) => {
    
    let newtableData: any = [];
    newtableData = Object.assign([], this.state.CADetails);
    newtableData.splice(rowIndex, 1);
    let filterDate = newtableData.filter((x) => x.isNewRow === true);
    if(filterDate.length===0)
    {
      //this.setState({ CADetails: newtableData,isAdded:false });
      this.setState({ CADetails: newtableData});
    }else{
      this.setState({ CADetails: newtableData });
    }
   
  };


  onRowSelect = (
    selectedRow: any,
    isSelect: boolean,
    rowIndex: any,
    e: any
  ) => {

    let data = this.state.CADetails;
    let selectedRows = this.state.selectedRows;
    let preSelectedRows = this.state.preSelectedRows;

    if (isSelect === true) {
      selectedRows.push(selectedRow);
      preSelectedRows.push(selectedRow.id);

      this.setState({ CADetails: data });
      this.setState({
        selectedRows: selectedRows,
        preSelectedRows: preSelectedRows,

      });
      this.props.CAChanges(true);
    } else {
      let indexOf = selectedRows.indexOf(selectedRow);
      selectedRows.splice(indexOf, 1);
      preSelectedRows.splice(indexOf, 1);

      this.setState({ CADetails: data });
      this.setState({
        selectedRows: selectedRows,
        preSelectedRows: preSelectedRows,

      } , () => {
        if(this.state.preSelectedRows.length > 0)
          {
            this.props.CAChanges(true);
          }
          else{
            this.props.CAChanges(false);
          }
      });
    }
debugger;
const allSameTypeName =
selectedRows.length > 0 &&
selectedRows.every((row) => row.typeName === selectedRows[0].typeName);

this.setState({ allSameTypeName }, () => {
if (allSameTypeName) {
  const filteredSubTypeList = this.state.SubTypeList.filter(
    (subType) => subType.typeID === selectedRows[0].typeID
  );
  this.setState({ footerSubTypeFilterList: filteredSubTypeList });
} else {
  this.setState({ footerSubTypeFilterList: [] });
}
});

  };

  onRowSelectAll = (isSelect: any, rows: any, e: any) => {

    let data = this.state.CADetails;
    if (isSelect === true) {
      let tempSelectedRows: any = [];
      let selectedRows = this.state.selectedRows;
      let preSelectedRows = this.state.preSelectedRows;

      selectedRows = tempSelectedRows;
      for (let i = 0; i < rows.length; i++) {
        selectedRows.push(rows[i]);
        preSelectedRows.push(rows[i].id);

      }

      this.setState({ CADetails: data });
      this.setState({
        selectedRows: selectedRows,
        preSelectedRows: preSelectedRows,

      });
      this.props.CAChanges(true);
    } else {
      this.setState({
        multipleRowSelected: false,
        selectedRows: [],
        preSelectedRows: [],
        // completedCheakox: [],
      });

      this.setState({ CADetails: data }, () => {
        if(this.state.preSelectedRows.length > 0)
          {
            this.props.CAChanges(true);
          }
          else{
            this.props.CAChanges(false);
          }
      });
    }
  };

  footerBulkSelectAll = (e: any) => {
    if (e.target.checked) {
      let preSelectedRows: any = [];
      let selectedRows: any = [];
      let data = [...this.state.CADetails];

      for (let i = 0; i < data.length; i++) {
        selectedRows.push(data[i]);
        preSelectedRows.push(data[i].id);
      }
      this.setState({ CADetails: data });
      this.setState({
        selectedRows: preSelectedRows,
        preSelectedRows: preSelectedRows,

      });

  
      

    } else {
      let data = [...this.state.CADetails];
      let preSelectedRows = [...this.state.preSelectedRows];
      let selectedRows = [...this.state.selectedRows];

      this.setState({ CADetails: data });
      this.setState({
        selectedRows: [],
        preSelectedRows: [],
      });
    }

  }

  AddNewSubType = () => {
    this.setState({ showSubTypeModal: true });
  }

  closeSubTypeModal = () => {
    this.setState({
      showSubTypeModal: false
    });
  };

  handleSearchSubType = (e: { target: { value: string } }) => {
    this.setState({ loaderTrue: true });
    debugger;
    let data = [...this.state.SubTypes]


    const updatedList = data.filter(
      (item: { SubType: string }) => {
        return (
          item.SubType.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1
        );
      }
    );

    this.setState({ SubTypes: [] }, () => {
      this.setState({
        //filterData: updatedList,
        inputValue: e.target.value,
        loaderTrue: false,
        SubTypes: updatedList,

      });
    })

  };


  typeSelected = (item: any, row: any) => {
    debugger;
    console.log("itemmmm",item);
    
    const newTableData = this.state.CADetails.map((d) => {
      if (d.id === row.id) {
        return {
          ...d,
          typeName: item.typeName,
          typeID : item.typeID,
          typeValidation:false,
          subTypeName : "",
          subTypeID : 0
        };
      }
      return d;
    });
    debugger;
    // const filteredSubTypeList = this.state.SubTypeList.filter(
    //   (subType) => subType.typeID === item.typeID
    // );
    this.props.CAChanges(true);
    this.commonArea.current?.callFromParent();
   // this.setState((curr: any) => ({ ...curr, CADetails: newTableData , typeId : item.typeID }));
  //   this.setState({ CADetails: newTableData , typeId : item.typeID , SubTypeList: filteredSubTypeList} , () => {
  //    this.commonArea.current?.handleNew();
  //    //this.getCASubTypes();
  //  } );
  this.setState({ CADetails: newTableData , typeId : item.typeID ,  isAdded : true,})
    
  };

  subTypeSelected = (item: any, row: any) => {
    debugger;
    console.log("item 1", item);
    
    const newTableData = this.state.CADetails.map((d) => {
      if (d.id === row.id) {
        return {
          ...d,
          subTypeName: item.subTypeName,
          subTypeID : item.subTypeID,
        };
      }
      return d;
    });
    this.props.CAChanges(true);
    this.setState({ CADetails: newTableData , isAdded : true});
  };
  
  AddCA = () => {
    let newtableData: any = [];
    let datalen = [...this.state.CADetails].length;
    newtableData = Object.assign([], this.state.CADetails);
    let count = 0;
    newtableData.map((item: any) => {
      count = count + 1;
      item.id = count;
    });
    let newRow = {} as any;
    newRow.roomid = 0;
    newRow.roomname = "";
    newRow.status = "Online";
    newRow.typeName = "";
    newRow.notes = "";
    newRow.subTypeName = "";
    newRow.id = count + 1;
    newRow.isNewRow = true;
    newtableData.push(newRow);
    
    this.setState(
      {
        CADetails: [],
      },
      () => {
        this.props.CAChanges(true);
        this.setState(
          {
            CADetails: newtableData,
            isAdded: true,
            noLocationMatch : false
          },
          () => {
            // if (this?.tableSearchRef?.current) {
            //   this.tableSearchRef?.current?.CleanSearch(); 
            // }
            this.input?.current?.focus();
          }
        );
      }
    );
  //})
  }

  bulkCancleClick = () => {
    this.setState({
      preSelectedRows: [],
      selectedRows: [],
      footerCAStatus:"Status",
    });
    this.props.CAChanges(false);
  }

  caFooterStatusChanges=(item: any)=>{
    debugger;
    this.setState({ footerCAStatus: item.Status});
  }

  handleCASave = () => {
    debugger;
    let newtableData: any = [];
    let copyTabletableData: any = [];
    let checkLocationRequired = false;
    let checkLocationTypeRequired = false;
    let checkDuplicateRoomValidation = false;
    let checkNotesValidation = false;
    copyTabletableData = Object.assign([], this.state.CADetails);
    newtableData = Object.assign([], this.state.CADetails);
    newtableData.map((item: any) => {
      item.roomname = item.roomname.trim().replace(/\s+/g, ' ');
      if (item.roomname === "") {
        item.LocationNameValidation = true;
        checkLocationRequired = true;
      }
      else{
        item.LocationNameValidation = false;
      }
      if (item.typeName === "") {
        item.typeValidation = true;
        checkLocationTypeRequired = true;
      }
      if (item.roomname !== "" && item.typeName !== "") {
        let row = copyTabletableData.filter((x) => x.roomname.toLowerCase() === item.roomname.toLowerCase() && x.typeName.toLowerCase() === item.typeName.toLowerCase());
        if (row.length > 1) {
          checkDuplicateRoomValidation = true;
          item.LocationNameValidation_Mssg = true;
        } else {
          item.LocationNameValidation_Mssg = false;
        }
      }
      if (item?.notes?.length>500 ) {
        item.nots_validation = true;
        checkNotesValidation = true;
      }
    
    });

    if (checkLocationRequired) {
      this.setState({ CADetails: [] }, () => {
        this.setState({ CADetails: newtableData });

        Utils.toastError(
          "Location name is required",
          {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "CommonArea",
          }
        );
      });
      return;
    } else if (checkDuplicateRoomValidation) {
      this.setState({ CADetails: [] }, () => {
        this.setState({ CADetails: newtableData });
        Utils.toastError(
          "Location name already exists",
          {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "CommonArea",
          }
        );
      });
      return;
    }else if (checkLocationTypeRequired) {
      this.setState({ CADetails: [] }, () => {
        this.setState({ CADetails: newtableData });
        Utils.toastError(
          "Type is required",
          {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "CommonArea",
          }
        );
      });
      return;
    }
    else if (checkNotesValidation) {
      this.setState({ CADetails: [] }, () => {
        this.setState({ CADetails: newtableData });
        Utils.toastError("Notes cannot exceed 500 characters",
          {
            position: toast.POSITION.BOTTOM_RIGHT,
           // containerId: "GuestRooms",
          }
        );
      });
    }
    else {
      this.setState({savingChanges : true});
      let todayDate = new Date(this.state.date);
      let currentdate = todayDate.getDate();
      let currentMonth = todayDate.getMonth() + 1;
      let currentYear = todayDate.getFullYear();
      let tempDate = currentMonth + "/" + currentdate + "/" + currentYear;
      let requestArray: any = [];
      newtableData.map((item: any) => {
        debugger;
        let requestObject = {} as any;

        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        requestObject.tenantID = tenantID;
        requestObject.hid = this.props.hotelId;
        requestObject.roomID = item.roomid;
        requestObject.roomType = item.typeID;
        requestObject.typeName = item.typeName;
        requestObject.roomName = item.roomname;
        requestObject.Notes = item.notes;;
        requestObject.subRoomType = item.subTypeID === null ? 0 : item.subTypeID;
        requestObject.subTypeName = item.subTypeName === null ? "" : item.subTypeName;
        requestObject.status = item.status;
        requestObject.category = "Common";
        requestArray.push(requestObject);

      });

      EntityManagementService.SaveCALocationDetails(requestArray)

        .then(async (result: any | null) => {
        debugger;
          let successToastMessage = "";
          if (result != null && result.status === "Success") {
            debugger;
            successToastMessage = result.message;
            this.setState({ isAdded: false }, () => {
              this.getCADetails();
              this.props.CAChanges(false);
            });
            toast.success(successToastMessage, {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "CommonArea",
            });
            this.setState({savingChanges : false , });
          } else {
            this.OnSaveValidationCheck(result.result);
            //this.setState({ showSubTypeModal: false });
            resolve();
          }
          resolve();
        })
        .catch((err: any) => {
          Utils.toastError(
            `Server Error, ${err}`,
            {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "CommonArea",
            }
          );
          reject();
        });

    }


  }


  DiscardClick = () => {
    let copyTabletableData: any = [];
    this.props.CAChanges(false);
    this.setState({ isAdded: false },()=>{    
      let copyTabletableData =  _.cloneDeep(this.state.discardTableCADetails);
      copyTabletableData.forEach(item => {
        item.LocationNameValidation = false;
        item.typeValidation = false;
        item.LocationNameValidation_Mssg = false;
        item.nots_validation = false;
      });
     this.setState({CADetails :copyTabletableData , searchinput : "" }) ;
    });
  }

  discardChanges = () => {
    confirmAlert({
      title:
        "Are you sure you want to discard? You will lose any unsaved progress.",
      message: "",
      buttons: [
        {
          label: "No",
          onClick: () => {},
        },
        {
          label: "Yes",
          onClick: () => {
            this.DiscardClick();
          },
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
      overlayClassName: "rooms-alert",
    });
  };

  handleSortByColumn = (field) => {
    this.setState(
      {
        sortingField: field,
        sortingOrder: this.state.sortingOrder === "asc" ? "desc" : "asc",
      },
      () => {
          this.sortTableData(this.state.CADetails, this.state.sortingField);
        }
      
    );
  };


  // sortTableData = (data: any, fieldName) => {
  //   let tempArchived = data.filter((o) => o.status === "Archive");
  //   let tempOther = data.filter((o) => o.status !== "Archive");
  
  //   if (this.state.sortingOrder === "asc") {
  //     tempOther = _.orderBy(tempOther, [fieldName], ["asc"]);
  //     tempArchived = _.orderBy(tempArchived, [fieldName], ["asc"]); 
  //   } else if (this.state.sortingOrder === "desc") {
  //     tempOther = _.orderBy(tempOther, [fieldName], ["desc"]);
  //     tempArchived = _.orderBy(tempArchived, [fieldName], ["desc"]); 
  //   }
  
  //   data = [...tempOther, ...tempArchived];
  
  //   this.setState({ CADetails: data });
  // };


sortTableData = (data: any, fieldName: string) => {
  const normalize = (value: any) => {
    return value?.toString().replace(/[^a-zA-Z0-9]/g, "").toLowerCase() || "";
  };

  let tempArchived = data.filter((o) => o.status === "Archive");
  let tempOther = data.filter((o) => o.status !== "Archive");

  if (this.state.sortingOrder === "asc") {
    tempOther = _.orderBy(
      tempOther,
      [(item) => normalize(item[fieldName])],
      ["asc"]
    );
    tempArchived = _.orderBy(
      tempArchived,
      [(item) => normalize(item[fieldName])],
      ["asc"]
    );
  } else if (this.state.sortingOrder === "desc") {
    tempOther = _.orderBy(
      tempOther,
      [(item) => normalize(item[fieldName])],
      ["desc"]
    );
    tempArchived = _.orderBy(
      tempArchived,
      [(item) => normalize(item[fieldName])],
      ["desc"]
    );
  }

  data = [...tempOther, ...tempArchived];

  this.setState({ CADetails: data });
};

  

  sortImage = (order) => {
    if (!order)
      return (
        <span className="ascending">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.4712 8.47133L11.5286 7.52867L8.66656 10.3907V4H7.33323V10.3907L4.47123 7.52867L3.52856 8.47133L7.9999 12.9427L12.4712 8.47133Z"
              fill="#1D1E20"
            />
          </svg>
        </span>
      );
    else if (order === "asc")
 
    return (
      <span className="ascending">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.4712 8.47133L11.5286 7.52867L8.66656 10.3907V4H7.33323V10.3907L4.47123 7.52867L3.52856 8.47133L7.9999 12.9427L12.4712 8.47133Z"
            fill="#1D1E20"
          />
        </svg>
      </span>
    );
    else if (order === "desc")
    return (
      <span className="descending">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.4712 8.47133L11.5286 7.52867L8.66656 10.3907V4H7.33323V10.3907L4.47123 7.52867L3.52856 8.47133L7.9999 12.9427L12.4712 8.47133Z"
            fill="#1D1E20"
          />
        </svg>
      </span>
    );
 
    return null;
  };

  handleBulkSave = () => {
    debugger;
    if (this.state.footerCAStatus === "Status" && this.state.footerCommonSubType === "Subtype" ) {
      Utils.toastError(
        "Please select Status or Subtype",
        {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "CommonArea",
        }
      );
      return;
    }

debugger;
    let newtableData: any = [];
    newtableData = Object.assign([], this.state.selectedRows);
   // let tempDate = currentMonth + "/" + currentdate + "/" + currentYear;
    let requestArray: any = [];
    newtableData.map((item: any) => {
      let requestObject = {} as any;

      let storage = JSON.parse(localStorage.getItem("storage")!);
      let tenantID = storage === null ? 0 : (storage.tenantID as any);
      requestObject.tenantID = tenantID;
      requestObject.hid = this.props.hotelId;
      requestObject.roomID = item.roomid;
      requestObject.roomType = item.typeID;
      requestObject.roomName = item.roomname;
      requestObject.Notes = item.notes;
      requestObject.subRoomType = this.state.footerCommonSubType == "Subtype" ? item.subTypeID : this.state.footerCommonSubTypeId;
      requestObject.status = this.state.footerCAStatus == "Status" ? item.status : this.state.footerCAStatus;
      requestObject.category = "Common";
      if(requestObject.subRoomType === null)
        {
          requestObject.subRoomType = 0
        }
      requestArray.push(requestObject);

    });

    EntityManagementService.SaveCABulkStatusDetails(requestArray)

      .then(async (result: any | null) => {
        this.setState({loader : true})
        let successToastMessage = "";
        if (result != null && result.status === "Success") {
          successToastMessage = result.message;
          this.setState({selectedRows:[],preSelectedRows:[], footerCAStatus:"Status",footerCommonSubType:"Subtype",});
          this.getCADetails();
          this.props.CAChanges(false);
          this.setState({loader : true})
          toast.success("Bulk update succesfully", {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "CommonArea",
          });
        } else {
          Utils.toastError(
            successToastMessage,
            {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "CommonArea",
            }
          );
        }
        resolve();
      })
      .catch((err: any) => {
        Utils.toastError(
          `Server Error, ${err}`,
          {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "CommonArea",
          }
        );
        reject();
      });




  }

  bulkSubtype = (item: any) => {
    debugger
    this.setState({ footerCommonSubTypeId: item.subTypeID, footerCommonSubType: item.subTypeName });
  }

  handleChange = (e) => {
    debugger;
    this.setState({noLocationMatch : false , searchinput :e.target.value })
    let data = [...this.state.copyCADetails]
     let value = e.target.value;
    const filteredData = data.filter((row) =>
      row?.roomname?.toLowerCase().includes(value.toLowerCase()) ||
      row?.typeName?.toLowerCase().includes(value.toLowerCase()) ||
      row?.subTypeName?.toLowerCase().includes(value.toLowerCase())

    );

    this.setState({ CADetails : filteredData } , () => {
      if(filteredData.length === 0){
        this.setState({noLocationMatch : true})
      }
      else{
        this.setState({noLocationMatch : false})
      }
    });
  }

  OnSaveValidationCheck = (list: any) => {
    debugger;
    let duplicate=false;
    let copyTabletableData: any = [];
    let newTableData = [...this.state.CADetails];
    copyTabletableData = Object.assign([], list);
    newTableData.map((item: any) => {
      if (item.roomname !== "") {
        let row = copyTabletableData.filter((x) => x.roomname?.toLowerCase() === item?.roomname.toLowerCase() && x.typeName?.toLowerCase() === item?.typeName.toLowerCase() && x.isValidName === "false");
        if (row.length > 0) {
          item.LocationNameValidation_Mssg = true;
          duplicate=true;
        }
      }
    });

    this.setState({ CADetails: [] }, () => {
      this.setState({ CADetails: newTableData });
      if(duplicate)
      {
        Utils.toastError("Location name already exists",
        {
          position: toast.POSITION.BOTTOM_RIGHT,
          //containerId: "GuestRooms",
        }
      );
      }
      
    });
  }

  render() {

    let selectedRows = [...this.state.selectedRows];
    let CADetails = [...this.state.CADetails];

    const caSelectRow = {
      mode: "checkbox",
      classes: "selection-row",
      selected: this.state.preSelectedRows,
      clickToExpand: true,
      onSelect: (
        selectedRow: any,
        isSelect: boolean,
        rowIndex: any,
        e: any
      ) => {
        this.onRowSelect(selectedRow, isSelect, rowIndex, e);
        // this.setState((prev) => ({isRowSelected: !prev.isRowSelected}))
      },
      onSelectAll: (isSelect: boolean, rows: any, e: any) => {
        this.onRowSelectAll(isSelect, rows, e);
      },
    };


    const rowClasses = (row: any, rowIndex: any) => {
      if (this.state.isAdded) {
        return "checkbox-disabled";
      }
    };



    const columns = [


      {
        dataField: "roomname",
        text: "Name",
        editable: false,
        headerClasses: 'width-30',
        classes: 'width-30',
        headerFormatter: (column) => {
          return (
            <div
              className={`d-inline-block ${this.state.sortingField === "roomname" ? "active" : ""
                }`}
                onClick={() => this.handleSortByColumn("roomname")}>
              {column.text}
              {this.sortImage(this.state.sortingOrder)} 
            </div>
          );
        },
        formatter: this.CAName,
      },
      {
        dataField: "typeName",
        text: "Type",
        headerClasses: 'width-30',
        classes: 'width-30',
        editable: false,
        formatter: this.CAType,
        headerFormatter: (column) => {
          return (
            <div
              className={`d-inline-block ${this.state.sortingField === "typeName" ? "active" : ""
                }`}
                onClick={() => this.handleSortByColumn("typeName")}>
              {column.text}
              {this.sortImage(this.state.sortingOrder)} 
            </div>
          );
        },
      },
      {
        dataField: "subTypeName",
        text: "Subtype",
        // editable: false,
        headerClasses: 'width-50',
        classes: 'width-50',
        editable: (content: any, row: any) => {
          // Disable editing if row.typeID is not present
          return !!row.typeID;
        },
        formatter: this.CASubtype,
        hidden: this.state.columnHideForContractor,
        headerFormatter: (column) => {
          return (
            <div
              className={`d-inline-block ${this.state.sortingField === "subTypeName" ? "active" : ""
                }`}
                onClick={() => this.handleSortByColumn("subTypeName")}>
              {column.text}
              {this.sortImage(this.state.sortingOrder)} 
            </div>
          );
        },
      },
      {
        dataField: "notes",
        text: "Notes",
        headerClasses: 'width-150',
        classes: 'width-150',
        editable: false,
        formatter: this.CANotes,
      }, 
      {
        dataField: "status",
        text: "Status",
        headerClasses: 'width-200',
        classes: 'width-200 overflow-visible',
        editable: false,
        formatter: this.CAStatus,
        headerFormatter: (column) => {
          return (
            <div
              className={`d-inline-block ${this.state.sortingField === "status" ? "active" : ""
                }`}
                onClick={() => this.handleSortByColumn("status")}>
              {column.text}
              {this.sortImage(this.state.sortingOrder)} 
            </div>
          );
        },
      }, 
      {
        dataField: "status",
        text: "",
        headerClasses: 'width-200',
        classes: 'width-200 overflow-visible',
        editable: false,
        formatter: this.caRemoveButton,
      },


    ];

    return (
      <>
        {this.state.loaderTrue ? (
          <ReactPageLoader useas={"Facility-CommonAreas"} />
        ) : (

          <div>

            <ToolkitProvider
              keyField="caid"
              data={this.state.CADetails}
              columns={columns}
              //rowEvents={this.rowEvents}
              search={{
                afterSearch: (newResult: any) => {
                  //this.searchedData(newResult);
                },
              }}
            >
              {(props: {
                searchProps: JSX.IntrinsicAttributes;
                baseProps: JSX.IntrinsicAttributes;
              }) => (
                <div>
                  <div className="search-filter d-flex ">
                    <label id="labeltableSearch" className="search-label">
                      {/* <TableSearch
                        ref={this.tableSearchRef}
                        id="tableSearch"
                        key="tableSearch"
                        {...props.searchProps}
                        placeholder={"Search..."}
      /> */}
                    <input
                      ref={this.tableSearchRef}
                      key={"text-Filter"}
                      type="text"
                      className="form-control"
                      onChange={this.handleChange}
                      placeholder="Search..."
                      value={this.state.searchinput}
                    />
                    </label>
                  </div>
                  <div className="cusPos">
                    <div>
                      <div
                      >
                        <div className={`CommonArea ${this.state.isAdded ? "set-table-height" : ""}`}>
                          {
                            this.state.tableLoader ? 
                            <ReactPageLoader useas={"location-rooms"} />
                            :
                          <div className={`table-outer ${this.state.preSelectedRows.length > 0 ? "freez-table" : ""}`}>
                            <BootstrapTable
                              {...props.baseProps}
                              id="CommonArea"
                              keyField="id"
                              data={this.state.CADetails}
                              columns={columns}
                              hover
                              //noDataIndication={this.state.noLocationMatch === true ? "No locations match your current filters" : "alksjdlaks"}
                              selectRow={caSelectRow}
                              rowClasses={rowClasses}
                                headerClasses={
                                  this.state.isAdded
                                    ? "checkbox-disabled"
                                    : ""
                                }
                            />
                            {
                              this.state.CADetails.length < 1 && this.state.noLocationMatch === false &&
                              <div className="no-data-mssg text-center border-bottom mb-3 pb-1">
                                <h6 className="mt-1">No common areas found. Click 'Add Common Area' to create your first location.</h6>
                              </div>
                            }
                             {
                              this.state.noLocationMatch &&
                              <div className="no-data-mssg text-center border-bottom mb-3 pb-1">
                                <h6 className="mt-1">No locations match your current filters.</h6>
                              </div>
                            }
                            <div className="table-footer">
                              <div className="d-flex justify-content-between">
                                <button onClick={this.AddCA}
                                disabled={this.state.preSelectedRows.length > 0 ? true:false }
                                >Add Common Area</button>
                                {this.state.CADetails?.length > 0 ? 
                                <div className="total"><span>{this.state.CADetails?.length}</span>Total</div>
                                : <></>}
                              </div>
                            </div> 
                          </div>
                          }
                        </div>

                        {this.state.isAdded && (
                          <div className="fixed-action">
                            <div className="d-flex align-content-center flex-wrap forecast-action-strip">
                              <div className="mr-auto message">
                                <span>You have unsaved changes?</span>
                              </div>

                              <button
                                type="button"
                                className="btn btn-primary btn-discard border-0 btn-changes-discard"
                                onClick={this.discardChanges}
                                style={{ color: "#1D1E20" }}
                              >
                                Discard
                              </button>

                              <button
                                type="button"
                                className="save-btn btn btn-primary"
                                onClick={this.handleCASave}
                                //disabled={this.state.savingChanges}
                              >
                                Save Changes
                              </button>

                              
                            </div>
                          </div>
                        )}

                        {this.state.preSelectedRows.length > 0 && (
                          <div className="bulk-selection">
                            <div className="d-flex align-content-center flex-wrap justify-content-between">
                              <div className="bulkSelectFooter d-flex align-items-center">
                                <Form.Check
                                  type="checkbox"
                                  label=""
                                  id="footerBulkCheckBox"
                                  ref={(chkRef) => {
                                    if (chkRef) {

                                      if (
                                        selectedRows.length == CADetails.length
                                      ) {
                                        chkRef.checked = true;
                                      } else {
                                        chkRef.checked = false;
                                      }

                                      if (
                                        selectedRows.length > 0 && !chkRef?.checked
                                      ) {
                                        chkRef.indeterminate = true;
                                      } else {
                                        chkRef.indeterminate = false;
                                      }
                                    }
                                  }}

                                  onClick={(e: any) => {
                                    e.stopPropagation();
                                    this.footerBulkSelectAll(e);
                                  }}
                                />
                                <span className="shift-selected-count">
                                  {this.state.preSelectedRows.length}{" "}
                                  selected
                                </span>
                                {this.state.allSameTypeName === true &&
                                 (<div className="payrollExport">
                                 <Dropdown className="subtype-dropdown mx-3">
                                   <Dropdown.Toggle
                                     id="dropdown-importFrom"
                                     className=""
                                   // disabled={!this.props.parentState.permissionManageSettings}
                                   >
                                     <EllipsisWithTooltip placement="top">{this.state.footerCommonSubType}</EllipsisWithTooltip>

                                   </Dropdown.Toggle>
                                   <Dropdown.Menu>
                                     {this.state.footerSubTypeFilterList.map((item: any, idx: any) => (
                                       <Dropdown.Item
                                         key={idx}
                                         onClick={() => {
                                           this.bulkSubtype(item);
                                         }}
                                         className={item.subTypeName == this.state.footerCommonSubType ? "active" : ""}
                                       >
                                         <EllipsisWithTooltip placement="bottom">{item.subTypeName}</EllipsisWithTooltip>
                                       </Dropdown.Item>
                                     ))}
                                   </Dropdown.Menu>
                                 </Dropdown>
                               </div>)
                                }
                               

                                <div className="status-dropdown">
                                  <Dropdown>
                                    <Dropdown.Toggle
                                      id="dropdown-importFrom"
                                      className=""
                                    // disabled={!this.props.parentState.permissionManageSettings}
                                    >
                                      <EllipsisWithTooltip placement="bottom">{this.state.footerCAStatus}</EllipsisWithTooltip>
                                      
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      {this.state.Allstatus.map((item: any, idx: any) => (
                                        <Dropdown.Item
                                          key={idx}
                                          onClick={() => {
                                            this.caFooterStatusChanges(item);
                                          }}
                                          className={item.Status.toLowerCase() == "online" ? "green" : item.Status.toLowerCase() == "offline" ? "red" : ""}
                                        >
                                          <EllipsisWithTooltip placement="bottom">{item.Status}</EllipsisWithTooltip>
                                        </Dropdown.Item>
                                      ))}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>


                              </div>
                              <div>
                                <button
                                  type="button"
                                  className="btn mr-4 btn-outline-primary btn btn-primary"
                                  onClick={this.handleBulkSave}
                                >
                                  Update
                                </button>

                                <button
                                  type="button"
                                  className="save-btn btn pl-0"
                                  onClick={this.bulkCancleClick}
                                >
                                  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                                    <path d="M9.72406 0.282539C9.43156 -0.00996076 8.95906 -0.00996076 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566562 -0.0174609 0.274062 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274062 1.33254L3.94156 5.00004L0.274062 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274062 9.72504C0.566562 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z" fill="white"/>
                                  </svg>
                                </button>
                              </div>
                            </div>
                          </div>
                        )}



                      </div>

                    </div>
                  </div>
                </div>
              )}
            </ToolkitProvider>

          </div>
        )}

        {/*=====================Save New SubType Modal========================*/}
        <Modal
          className="save-template-modal"
          show={this.state.showSubTypeModal}
          onHide={this.closeSubTypeModal}
          aria-labelledby="contained-modal-title-vcenter"
          animation={true}
          centered
          backdrop="static"
          keyboard={false}
        >
          <OutsideClickHandler
            onOutsideClick={this.closeSubTypeModal}
          >
            <Modal.Header closeButton>
              <Modal.Title>Add SubType</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group controlId="subtype">
                <Form.Label>Label</Form.Label>
                <div className="search-header mr-auto auto-complete">
                  <Form.Control
                    type="text"
                    placeholder="Enter value"
                    value={this.state.subType}
                    onChange={(e) => this.setState({ subType: e.target.value })}
                    autoComplete="off"
                  />
                </div>
                <div>
                <Dropdown
               className="subtype-dropdown"
            >
              <Dropdown.Toggle
                 id="dropdown-importFrom"
                 className=""
               >
                <EllipsisWithTooltip placement="bottom">{this.state.Type}</EllipsisWithTooltip>
              </Dropdown.Toggle>
               <Dropdown.Menu>
                
                  <div className="menu">
                    {this.state.TypeList.length > 0 ? (
                    this.state.TypeList.map((item: any) => (
                      <Dropdown.Item
                      //onClick={() => this.handleDropdownChange(item)}
                      //key={idx}
                      >
                        <EllipsisWithTooltip placement="bottom">{item.typeName}</EllipsisWithTooltip>
                        <button>
                          <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"></path></svg>
                        </button>
                      </Dropdown.Item>
                    ))
                  ) : (
                    <Dropdown.Item>No Type match your current filters.</Dropdown.Item>
                  )}
                  </div>
               </Dropdown.Menu>
             </Dropdown>
                  </div>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className={
                  this.state.saveTemplateButtonSpinner
                    ? "saveTemplate-button p-none"
                    : this.state.templateSaveConfirmation
                      ? "saveTemplateAlertButton"
                      : "saveTemplate-button"
                }
              //onClick={() => this.handleSaveTemplate("Template")}
              >
                {this.state.saveTemplateButtonSpinner && (
                  <Spinner
                    className="mr-2"
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
                Save
              </Button>
            </Modal.Footer>

          </OutsideClickHandler>
        </Modal>
       

      </>
    );

  }
}