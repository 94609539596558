import * as React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Button, Container, Dropdown, Form, Spinner } from "react-bootstrap";
import { FiChevronDown } from "react-icons/fi";
import { ToastContainer, toast } from "react-toastify";
import { UserManagement } from "../../../Common/Services/UserManagement";
import { HotelFeatureAccessService } from "../../../Common/Services/HotelFeatureAccessService";
import "../../../Common/Assets/Styles/Modules/epay-onboarding.scss";
//import { ReactPageLoader } from "../../../Common/Components/ReactPageLoader";
import { resolve, reject } from "q";
import _ from "lodash";
import { CommonService } from "../../../Common/Services/CommonService";
import { Utils } from "../../../Common/Utilis";

export class HotelFeatureAccess extends React.Component<any, any> {
  private tenantSelector: any;
  constructor(props: any) {
    super(props);
    this.tenantSelector = React.createRef();
    this.state = {
      selectedTenant: { id: -1, name: "", status: "" },
      tenantSearch: "",
      isTenantLoading: false,
      tenantsList: [],
      filterTenantList: [],
      subscriberModules: [],
      isTableLoading: false,
      isSaveEnabled: false,
      isSubmitLoading: false,
      headerData: [],
      advAccHotelData: [],
      advAccHotelDataCopy: [],
      tableData: [],
      tableDataCopy: [],
      subscribedDynamicColumnsArray: [],
      staticColumnsModuleWise: [],
    };
  }

  componentDidMount() {
    this.GetAdvancedAccountingHeaderData();
    this.getTenantsList();
  }
  getTenantsList = () => {
    this.setState({ isTenantLoading: true });
    UserManagement.GetTenantList().then(async (result: any) => {
      const data = result?.result?.result;
      let filterItem: any[] = [];
      filterItem = data.filter(
        (item: any) => item.status.toString().toLocaleLowerCase() === "active"
      );
      this.setState({
        tenantsList: filterItem,
        filterTenantList: filterItem,
        isTenantLoading: false,
      });
    });
  };

  handleAccordion = (item: any) => {
    let { subscriberModules } = this.state;
    let moduleName = item.moduleName.toString().toLocaleLowerCase();
    subscriberModules.forEach((item: any) => {
      if (item.moduleName.toString().toLocaleLowerCase() === moduleName) {
        item.isToggle = !item.isToggle;
      }
    });
    this.setState({ subscriberModules });
  };

  searchItems = (e: any) => {
    const dataList = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.tenantsList))
    );
    let { filterTenantList } = this.state;
    let result = e.target.value;
    this.setState({ tenantSearch: result });
    if (result !== "") {
      filterTenantList = dataList.filter((o: any) =>
        o.tenantname.toLowerCase().includes(result.toLowerCase().trim())
      );
    } else {
      filterTenantList = dataList;
    }
    this.setState({ filterTenantList });
  };

  tenantDDToggle = (e: any) => {
    const dataList = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.tenantsList))
    );
    this.setState({ tenantSearch: "", filterTenantList: dataList });
    e === true &&
      setTimeout(() => {
        $("#idTenantSearch").focus();
      }, 50);
    setTimeout(() => {
      this.tenantSelector.current.getElementsByClassName("active")[0]?.focus();
    }, 100);
  };

  tanentChange = (item: any) => {
    let { selectedTenant } = this.state;
    selectedTenant.id = item.tenantid;
    selectedTenant.name = item.tenantname;
    selectedTenant.status = item.status;
    this.setState(
      {
        selectedTenant,
        isTableLoading: true,
        isSaveEnabled: false,
        subscriberModules: [],
        advAccHotelData: [],
        advAccHotelDataCopy: [],
        tableData: [],
        tableDataCopy: [],
      },
      () => {
        this.GetAdvancedAccountingHotelData(item.tenantid);
      }
    );
  };

  GetAdvancedAccountingHeaderData = () => {
    let request: any = {};
    // request.TenantId = tenantid;
    HotelFeatureAccessService.GetHotelFeatureAccessTableHeaderData(request)
      .then(async (result: any | null) => {
        if (result !== null && result.length > 0) {
          // debugger;
          this.setState({ headerData: result }, () => {
            let subscribedDynamicColumnsArray: any = [];

            let staticColumnsModuleWise: any[] = [];
            _.uniq(this.state.headerData.map((x) => x.moduleName)).forEach(
              (moduleName) => {
                staticColumnsModuleWise = [
                  ...staticColumnsModuleWise,
                  ...[
                    {
                      dataField: "ehid",
                      text: "EHID",
                      headerClasses: "",
                      moduleName: moduleName,
                      formatter: (cell: any, row: any, rowIndex: any) => {
                        return (
                          <div className="d-flex align-items-center">
                            <div className="col-text sub-module">
                              {row.ehid}
                            </div>
                          </div>
                        );
                      },
                    },
                    {
                      dataField: "isCheckAll",
                      text: "Check All",
                      headerClasses: "",
                      moduleName: moduleName,
                      classes: "",
                      headerFormatter: (column, colIndex) => {
                        return (
                          <div className="d-flex">
                            <Form.Check
                              custom
                              id={"chkheader1_isCheckAll" + moduleName}
                              key={"chkheader1_isCheckAll" + moduleName}
                              type="checkbox"
                              ref={(chkRef) => {
                                if (chkRef) {
                                  if (
                                    this.isColumnHeaderChecked(
                                      "isCheckAll",
                                      moduleName
                                    )
                                  ) {
                                    chkRef.checked = true;
                                  } else {
                                    chkRef.checked = false;
                                  }

                                  if (
                                    this.state.tableData.filter(
                                      (x) =>
                                        x["isCheckAll"] === "Yes" &&
                                        x.moduleName === moduleName
                                    )?.length > 0 &&
                                    !chkRef?.checked
                                  ) {
                                    chkRef.indeterminate = true;
                                  } else {
                                    chkRef.indeterminate = false;
                                  }
                                }
                              }}
                              label={""}
                              // checked={this.isColumnHeaderChecked(
                              //   "isCheckAll",
                              //   moduleName
                              // )}
                              onChange={(e) =>
                                this.handleHeaderCheckbox(
                                  e,
                                  column.dataField,
                                  moduleName
                                )
                              }
                            />
                            <div>{column.text}</div>
                          </div>
                        );
                      },
                      formatter: (cell: any, row: any, rowIndex: any) => {
                        return (
                          <div className="bdr-left height-48 d-flex align-items-center">
                            <Form.Check
                              custom
                              id={"chk1_isCheckAll" + row.id + moduleName}
                              key={"chk1_isCheckAll" + row.id + moduleName}
                              type="checkbox"
                              label={""}
                              ref={(chkRef) => {
                                if (chkRef) {
                                  if (row?.isCheckAll === "Yes") {
                                    chkRef.checked = true;
                                  } else {
                                    chkRef.checked = false;
                                  }

                                  if (
                                    this.state.headerData.filter(
                                      (x) =>
                                        x.moduleName === moduleName &&
                                        row[x.name] === "Yes"
                                    )?.length > 0 &&
                                    !chkRef?.checked
                                  ) {
                                    chkRef.indeterminate = true;
                                  } else {
                                    chkRef.indeterminate = false;
                                  }
                                }
                              }}
                              ///  checked={row?.isCheckAll === "Yes" ? true : false}
                              onChange={(e) => this.handleCheckAllRow(e, row)}
                            />
                          </div>
                        );
                      },
                    },
                  ],
                ];
              }
            );
            this.state.headerData.length > 0 &&
              this.state.headerData.map((header) => {
                let subscribedDynamicColumnsObj: any = {};
                subscribedDynamicColumnsObj.dataField = header.name;
                subscribedDynamicColumnsObj.text = header.displayName;
                subscribedDynamicColumnsObj.moduleName = header.moduleName;
                subscribedDynamicColumnsObj.headerFormatter = (
                  column,
                  colIndex
                ) => {
                  return (
                    <div className="d-flex">
                      <Form.Check
                        custom
                        id={"chkheader1_" + header.name + header.moduleName}
                        key={"chkheader1_" + header.name + header.moduleName}
                        type="checkbox"
                        label={""}
                        ref={(chkRef) => {
                          if (chkRef) {
                            if (
                              this.isColumnHeaderChecked(
                                header.name,
                                header?.moduleName
                              )
                            ) {
                              chkRef.checked = true;
                            } else {
                              chkRef.checked = false;
                            }

                            if (
                              this.state.tableData.filter(
                                (x) =>
                                  x[header.name] === "Yes" &&
                                  x.moduleName === header.moduleName
                              )?.length > 0 &&
                              !chkRef?.checked
                            ) {
                              chkRef.indeterminate = true;
                            } else {
                              chkRef.indeterminate = false;
                            }
                          }
                        }}
                        // checked={this.isColumnHeaderChecked(
                        //   header.name,
                        //   header?.moduleName
                        // )}
                        onChange={(e) =>
                          this.handleHeaderCheckbox(
                            e,
                            header.name,
                            header?.moduleName
                          )
                        }
                      />
                      <div>{column.text}</div>
                    </div>
                  );
                };
                subscribedDynamicColumnsObj.formatter = (
                  cell: any,
                  row: any,
                  rowIndex: any
                ) => {
                  return (
                    <div className="bdr-left height-48 d-flex align-items-center">
                      <Form.Check
                        custom
                        id={
                          "chk1_" +
                          header.name +
                          row.id +
                          header.id +
                          header.moduleName
                        }
                        key={
                          "chk1_" +
                          header.name +
                          row.id +
                          header.id +
                          header.moduleName
                        }
                        type="checkbox"
                        label={""}
                        checked={
                          row[header.name] === "Yes" &&
                          row.moduleName === header.moduleName
                            ? true
                            : false
                        }
                        onChange={(e) =>
                          this.handleCheckbox(
                            e,
                            row,
                            header.name,
                            header.moduleName
                          )
                        }
                      />
                    </div>
                  );
                };
                subscribedDynamicColumnsArray.push(subscribedDynamicColumnsObj);
              });

            this.setState({
              subscribedDynamicColumnsArray,
              staticColumnsModuleWise,
            });
          });
        }
        resolve();
      })
      .catch((error) => {
        reject();
      });
  };

  GetAdvancedAccountingHotelData = (
    tenantid: any,
    isCalledFromSave = false
  ) => {
    let request: any = {};
    request.selectedTenantId = tenantid;
    HotelFeatureAccessService.GetHotelFeatureAccessData(request)
      .then(async (result: any | null) => {
        if (result !== null && result.length > 0) {
          // debugger;
          this.setState(
            {
              advAccHotelData: result,
              advAccHotelDataCopy: result,
              isTableLoading: false,
            },
            () => {
              let tableData: any = [];
              let dintinctEhidArr = _.uniqWith(
                this.state.advAccHotelData.map((data) => ({
                  ehid: data.ehid,
                  moduleName: data.moduleName,
                })),
                _.isEqual
              );
              dintinctEhidArr.map((item, index) => {
                let tableDataObj = {};
                tableDataObj["id"] = index;

                tableDataObj["ehid"] = item.ehid;
                tableDataObj["hotelid"] = this.state.advAccHotelData.filter(
                  (data) => data.ehid === item.ehid
                )[0].hotelid;
                tableDataObj["moduleName"] = item.moduleName;
                tableDataObj["isCheckAll"] =
                  this.state.advAccHotelData.filter(
                    (data) =>
                      data.ehid === item.ehid &&
                      data.moduleName === item.moduleName &&
                      data.checkValue === "Yes"
                  ).length ===
                  this.state.headerData.filter(
                    (data) => data.moduleName === item.moduleName
                  ).length
                    ? "Yes"
                    : "No";

                this.state.headerData
                  .filter((x) => x.moduleName === item.moduleName)
                  .map((header, index) => {
                    tableDataObj[header.name] = this.state.advAccHotelData.find(
                      (data) =>
                        data.ehid === item.ehid &&
                        data.id === header.id &&
                        data.moduleName === header.moduleName
                    )?.checkValue;
                  });

                tableData.push(tableDataObj);
              });

              const accordianModuleNames = _.uniq(
                tableData.map((x) => x.moduleName).sort((a, b) => a - b)
              );

              const subscriberModules = accordianModuleNames.map(
                (name, index) => {
                  var data = this.state.subscriberModules.find(
                    (x) => x.moduleName === name
                  );
                  return {
                    rno: index + 1,
                    moduleName: name,
                    isToggle: data ? data?.isToggle : index > 0 ? false : true,
                  };
                }
              );
              this.setState({ subscriberModules: [] }, () => {
                this.setState(
                  { subscriberModules, tableData, tableDataCopy: tableData },
                  () => {
                    let tableData = Object.assign(
                      [],
                      JSON.parse(JSON.stringify(this.state.tableData))
                    );
                    for (let i = 0; i < tableData.filter.length; i++) {
                      // debugger;
                      for (let j = 0; j < this.state.headerData.length; j++) {
                        if (
                          tableData[i][this.state.headerData[j]["name"]] ===
                          "Yes"
                        ) {
                        } else {
                          tableData[i]["isCheckAll"] = "No";
                          break;
                        }
                        tableData[i]["isCheckAll"] = "Yes";
                      }
                    }
                    this.setState({ tableData, tableDataCopy: tableData });
                  }
                );
              });
            }
          );
        }
        resolve();
      })
      .catch((error) => {
        this.setState({ isTableLoading: false });
        Utils.toastError(error.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        reject();
      });
  };

  handleSaveAA = () => {
    this.setState({ isSubmitLoading: true });
    let request = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.advAccHotelData))
    );
    request.map(
      (items) => (items["selectedTenantId"] = this.state.selectedTenant.id)
    );
    HotelFeatureAccessService.SaveHotelFeatureAccessMapping(request)
      .then(async (result: any | null) => {
        // debugger;
        if (result.saveStatus.toLowerCase() === "success") {
          toast.success(result.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "AAccounting",
          });
          this.setState(
            {
              isSubmitLoading: false,
              isSaveEnabled: false,
              // isTableLoading: true,
            },
            () => {
              this.GetAdvancedAccountingHotelData(this.state.selectedTenant.id);
            }
          );
        } else {
          this.setState({ isSubmitLoading: false });
          Utils.toastError("Something went wrong.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "AAccounting",
          });
        }
        resolve();
      })
      .catch((error) => {
        this.setState({ isSubmitLoading: false });
        Utils.toastError("Something went wrong.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "AAccounting",
        });
        reject();
      });
  };

  handleCheckbox = (event, row, type, moduleName) => {
    debugger;
    let tableData = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.tableData))
    );
    let advAccHotelData = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.advAccHotelData))
    );

    var advAccIndex = advAccHotelData.findIndex(
      (x) =>
        x.ehid === row.ehid && x.name == type && x.moduleName === moduleName
    );
    if (advAccIndex >= 0) {
      advAccHotelData[advAccIndex].checkValue = event.target.checked
        ? "Yes"
        : "No";
      advAccHotelData[advAccIndex].isUpdated = "Yes";
    }

    var tableIndex = tableData.findIndex(
      (x) => x.ehid === row.ehid && x.moduleName === moduleName
    );

    if (tableIndex >= 0) {
      tableData[tableIndex][type] = event.target.checked ? "Yes" : "No";

      tableData[tableIndex]["isCheckAll"] =
        advAccHotelData.filter(
          (x) =>
            x.ehid === row.ehid &&
            x.moduleName === moduleName &&
            x.checkValue === "Yes"
        ).length ===
        this.state.headerData.filter((data) => data.moduleName === moduleName)
          .length
          ? "Yes"
          : "No";
    }

    this.setState({ tableData, advAccHotelData, isSaveEnabled: true });
  };

  handleCheckAllRow = (event, row) => {
    debugger;
    let tableData = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.tableData))
    );
    let advAccHotelData = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.advAccHotelData))
    );
    for (let i = 0; i < advAccHotelData.length; i++) {
      if (
        advAccHotelData[i]["ehid"] === row.ehid &&
        advAccHotelData[i]["moduleName"] === row?.moduleName
      ) {
        advAccHotelData[i].checkValue = event.target.checked ? "Yes" : "No";
        advAccHotelData[i].isUpdated = "Yes";
      }
    }
    for (let i = 0; i < tableData.length; i++) {
      if (
        tableData[i]["ehid"] === row.ehid &&
        tableData[i]["moduleName"] === row?.moduleName
      ) {
        for (let j = 0; j < this.state.headerData.length; j++) {
          tableData[i][this.state.headerData[j]["name"]] = event.target.checked
            ? "Yes"
            : "No";
        }
        tableData[i]["isCheckAll"] = event.target.checked ? "Yes" : "No";
      }
    }
    this.setState({ tableData, advAccHotelData, isSaveEnabled: true }, () => {
      let tableData = Object.assign(
        [],
        JSON.parse(JSON.stringify(this.state.tableData))
      );
      for (let i = 0; i < tableData.length; i++) {
        if (
          tableData[i]["ehid"] === row.ehid &&
          tableData[i]["moduleName"] === row?.moduleName
        ) {
          for (let j = 0; j < this.state.headerData.length; j++) {
            if (tableData[i][this.state.headerData[j]["name"]] === "Yes") {
            } else {
              tableData[i]["isCheckAll"] = "No";
              this.setState({ tableData });
              return;
            }
          }
          tableData[i]["isCheckAll"] = "Yes";
          this.setState({ tableData });
        }
      }
    });
  };

  handleHeaderCheckbox = (event, dataField, moduleName) => {
    debugger;
    let tableData = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.tableData))
    );
    let advAccHotelData = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.advAccHotelData))
    );

    if (dataField === "isCheckAll") {
      for (let i = 0; i < advAccHotelData.length; i++) {
        if (advAccHotelData[i]["moduleName"] === moduleName) {
          advAccHotelData[i].checkValue = event.target.checked ? "Yes" : "No";
          advAccHotelData[i].isUpdated = "Yes";
        }
      }
      for (let i = 0; i < tableData.length; i++) {
        if (tableData[i]["moduleName"] === moduleName) {
          for (let j = 0; j < this.state.headerData.length; j++) {
            tableData[i][this.state.headerData[j]["name"]] = event.target
              .checked
              ? "Yes"
              : "No";
          }
          tableData[i]["isCheckAll"] = event.target.checked ? "Yes" : "No";
        }
      }
    } else {
      for (let i = 0; i < advAccHotelData.length; i++) {
        if (
          advAccHotelData[i]["moduleName"] === moduleName &&
          advAccHotelData[i]["name"] === dataField
        ) {
          advAccHotelData[i].checkValue = event.target.checked ? "Yes" : "No";
          advAccHotelData[i].isUpdated = "Yes";
        }
      }
      for (let i = 0; i < tableData.length; i++) {
        if (tableData[i]["moduleName"] === moduleName) {
          tableData[i][dataField] = event.target.checked ? "Yes" : "No";
        }
      }
    }
    this.setState({ tableData, advAccHotelData, isSaveEnabled: true }, () => {
      let tableData = Object.assign(
        [],
        JSON.parse(JSON.stringify(this.state.tableData))
      );
      for (let i = 0; i < tableData.length; i++) {
        // debugger;
        for (let j = 0; j < this.state.headerData.length; j++) {
          if (tableData[i][this.state.headerData[j]["name"]] === "Yes") {
          } else {
            tableData[i]["isCheckAll"] = "No";
            break;
          }
          tableData[i]["isCheckAll"] = "Yes";
        }
      }
      this.setState({ tableData });
    });
  };

  isColumnHeaderChecked = (type, moduleName) => {
    // debugger;
    let tableData = Object.assign(
      [],
      JSON.parse(
        JSON.stringify(
          this.state.tableData.filter((x) => x.moduleName === moduleName)
        )
      )
    );
    if (tableData.length === 0) {
      return false;
    }
    for (let i = 0; i < tableData.length; i++) {
      if (tableData[i][type] === "Yes") {
      } else {
        return false;
      }
    }
    return true;
  };

  render() {
    const {
      isTenantLoading,
      filterTenantList,
      selectedTenant,
      subscriberModules,
      isTableLoading,
      isSubmitLoading,
      isSaveEnabled,
      tableData,
      staticColumnsModuleWise,
    } = this.state;

    const checkItem = () => {
      return (
        <span className="itemcheck">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.49998 11.6895L5.03023 9.21973L3.96973 10.2802L7.49998 13.8105L14.7802 6.53023L13.7197 5.46973L7.49998 11.6895Z"
              fill="#2CAF92"
            />
          </svg>
        </span>
      );
    };
    return (
      <div className="ePayOnboardingControlMain advanced-accounting">
        <Container fluid className="body-sec pos-rel">
          <ToastContainer
            autoClose={3000}
            containerId={"AAccounting"}
            enableMultiContainer
          />

          <div className="page-heading underline d-flex">
            <div className="mr-auto">Feature Access</div>
            <div className="action-group d-flex">{/**/}</div>
          </div>
          <div className="d-flex headerDownControl">
            <div className="ePayTenantSelector">
              <label>Tenant</label>
              <div className="d-flex">
                <Dropdown
                  className={`${
                    isTenantLoading ? "disabled-area" : ""
                  } ePayTenantSelectorDropdown`}
                  onToggle={this.tenantDDToggle}
                >
                  <Dropdown.Toggle className="ePayButton" id="dropdown-Tenant">
                    <div className="d-flex align-items-center justify-content-center">
                      <div className="">
                        {selectedTenant.id !== -1
                          ? selectedTenant.name
                          : "Select Tenant"}
                      </div>
                      <div className="ml-auto drop-arrow d-flex">
                        <FiChevronDown />
                      </div>
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    className="ePayTenantSelectorMenu"
                    ref={this.tenantSelector}
                  >
                    <div className="ePayTenantSearch">
                      <Form.Group controlId="ePayTenantSearch">
                        <Form.Control
                          id="idTenantSearch"
                          type="text"
                          name="search"
                          value={this.state.tenantSearch}
                          autoComplete="off"
                          onChange={this.searchItems}
                          placeholder="Search Tenant"
                          autoFocus={true}
                        />
                      </Form.Group>
                    </div>
                    <div className="dropdownItems">
                      {filterTenantList.length > 0 ? (
                        <>
                          {filterTenantList.map((item: any, idx: any) => (
                            <Dropdown.Item
                              className={
                                selectedTenant.name === item.tenantname
                                  ? "active"
                                  : ""
                              }
                              key={idx}
                              eventKey={item.tenantid}
                              onClick={() => this.tanentChange(item)}
                            >
                              <div className="d-flex align-items-center justify-content-center">
                                <div className=""> {item.tenantname}</div>
                                <div className="ml-auto">
                                  {item.tenantid === selectedTenant.id &&
                                    checkItem()}
                                </div>
                              </div>
                            </Dropdown.Item>
                          ))}
                        </>
                      ) : (
                        <Dropdown.Item>No Item Found</Dropdown.Item>
                      )}
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>

          {selectedTenant.id !== -1 && (
            <>
              {isTableLoading ? (
                <></>
              ) : (
                <>
                  <div className="ePayOnBoardingListItems inner-hide">
                    <div className="ePayOnBoardingListBody">
                      {subscriberModules?.length > 0 &&
                        subscriberModules.map((item: any, itemIdx: any) => (
                          <div className="ePayListItems">
                            <Button
                              className={`ePayheaderbtn btn-collapse ${
                                item.moduleName?.toLocaleLowerCase() ==
                                "accounting"
                                  ? "mt-0"
                                  : ""
                              }`}
                            >
                              <div className="d-flex">
                                <div
                                  onClick={() => this.handleAccordion(item)}
                                  className={
                                    item.isToggle
                                      ? "activeToggle arrowIcon"
                                      : "toggleItem arrowIcon"
                                  }
                                >
                                  <svg
                                    stroke="currentColor"
                                    fill="currentColor"
                                    stroke-width="0"
                                    viewBox="0 0 24 24"
                                    height="20"
                                    width="20"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fill="none"
                                      d="M0 0h24v24H0V0z"
                                    ></path>
                                    <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"></path>
                                  </svg>
                                </div>
                                <div
                                  onClick={() => this.handleAccordion(item)}
                                  className="ePayHeaderTitle"
                                >
                                  {item.moduleName}
                                </div>
                              </div>
                            </Button>
                            <div
                              className={`${
                                item.isToggle ? "" : "d-none"
                              } ePayCollapse`}
                              id={`id_${itemIdx}`}
                            >
                              <div
                                className={`moduleFeatureItemBody table-${item.moduleName}`}
                              >
                                <BootstrapTable
                                  keyField="rno"
                                  data={tableData.filter(
                                    (x) => x.moduleName === item.moduleName
                                  )}
                                  columns={[
                                    ...staticColumnsModuleWise.filter(
                                      (x) => x.moduleName === item.moduleName
                                    ),
                                    ...this.state.subscribedDynamicColumnsArray.filter(
                                      (x) => x.moduleName === item.moduleName
                                    ),
                                  ]}
                                  noDataIndication={"No record found."}
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className="ePaySubmitPanel">
                    <Button
                      disabled={!isSaveEnabled || isSubmitLoading}
                      onClick={this.handleSaveAA}
                    >
                      {isSubmitLoading && (
                        <Spinner
                          size="sm"
                          className="disabled-area"
                          animation="border"
                        />
                      )}{" "}
                      Save
                    </Button>
                  </div>
                </>
              )}
            </>
          )}
        </Container>
      </div>
    );
  }
}
