import * as React from "react";
import {
  Form,
  //  Dropdown
} from "react-bootstrap";
import OutsideClickHandler from "react-outside-click-handler";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
class MultiSelectSearchCustomCheck extends React.Component<any, any> {
  static defaultProps = {
    isSearch: true,
    placeholder :"Select Accounts"
  };

  private btnSection: any;
  private searchSection: any;
  private dropDownFooter: any;
  toggleRef: any = React.createRef();
  
  constructor(props: any) {
    super(props);
    this.btnSection = React.createRef();
    this.searchSection = React.createRef();
    this.dropDownFooter = React.createRef();
   
    this.state = {
      selectedItems: [],
      filterData: [],
      filterDataCopy: [],
      opened: false,
      itemList: [],
      isSelectAll: props.isSelectAll,
      filtertitle: props.filtertitle,
      inputValue: "",
      childClick: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
    this.handleSelectAll = this.handleSelectAll.bind(this);
    this.handleClearAll = this.handleClearAll.bind(this);
  }
  componentDidMount() {
    this.getData("onMount");
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.itemList !== this.props.itemList) {
      this.getData("listUpdate");
    }
  }
  onChipMouseOver = (): void => {
    this.setState({ childClick: true });
  };
  onChipMouseOut = (): void => {
    this.setState({ childClick: false });
  };

  getData = (type: string) => {
    //debugger
    const itemList = this.props.itemList;
    const activeChip = itemList.filter((itemList: any) => itemList.isChecked);
    const updatedList = itemList.filter(
      (item: { description: string }) => {
        return (
          item?.description?.toLowerCase()?.indexOf(this.state?.inputValue?.toLowerCase()) >
          -1
        );
      }
    );

    this.setState({
      filterData: updatedList,
      itemList,
      selectedItems: activeChip
    });

    if (type === "listUpdate") {
      const activeChip = itemList.filter((itemList: any) => itemList.isChecked);
      this.setState({ selectedItems: activeChip });
    }
  };
  /**For remove COA(s) list **/
  handleRemoveSpecificChip = (idx: number) => () => {
    this.setState({ childClick: true });
    this.props.dataChange();
    const itemList = [...this.state.itemList];
    const selectedItems = [...this.state.selectedItems];
    const removeSelect = this.getIndex(selectedItems[idx].value, "removeChip");
    let isCheck = this.props?.hidofgroup?.some((o: any) => o.hotelID === itemList[removeSelect]?.hotelID);
    itemList[removeSelect].isChecked = false;
    selectedItems.splice(idx, 1);
    this.setState({ selectedItems });
    this.setState({ itemList });
    const coaSelectedItem = this.state.itemList.filter(
      (itemList: { isChecked: boolean }) => itemList.isChecked === true
    );
    this.props.updateMultiSelectItem(coaSelectedItem, itemList[removeSelect]?.hotelID, isCheck);
    this.setState({ childClick: false });
  };

  /************checkbox check unchek********/
  handleCheckCheckbox = (idx: any) => (event: any) => {
    this.props.dataChange();
    const itemList = [...this.state.itemList];
    const selectedItems = [...this.state.selectedItems];
    let isSelectSingle = (this.props?.moduleReportId === 2166 || this.props?.moduleReportId === 2167 || this.props?.moduleReportId === 2168);
    this.setState({
      filterDataCopy: this.state.itemList,
    });
    (isSelectSingle && itemList.map((o: any) => o.isChecked = false));
    const index = itemList.findIndex((xvalue) => xvalue.value === idx);
    itemList[index].isChecked = event.target.checked;

    idx = index;


    this.setState({ itemList });

    //Item push and remove
    const item = {
      value: itemList[idx].value,
      isChecked: event.target.checked,
    };
    if (event.target.checked === true) {
      this.setState({
        selectedItems: isSelectSingle ? [item] : [...this.state.selectedItems, item],
      });
    } else {
      const removeIndex = this.getIndex(itemList[idx].value, "selectbox");
      selectedItems.splice(removeIndex, 1);
      this.setState({ selectedItems });
    }
    const coaSelectedItem = this.state.itemList.filter(
      (itemList: { isChecked: boolean }) => itemList.isChecked === true
    );

    let isClose = false;
    if (event.target.checked === false) {
      isClose = this.props?.hidofgroup.some((o: any) => o.hotelID === itemList[idx]?.hotelID)
    }
    this.props.updateMultiSelectItem(coaSelectedItem, itemList[index]?.hotelID, isClose);
  };

  
  onTabKeyDown = (e) => {
    e.preventDefault();
    if (!this.state.opened && e?.keyCode === 9) {
      this.handleClick();

    }
  };
  
  //Get array Index
  getIndex(val: any, type: string) {
    if (type === "removeChip") {
      let itemList = [...this.state.itemList];
      return itemList.findIndex((obj) => obj.value === val);
    } else {
      let selectedItems = [...this.state.selectedItems];
      return selectedItems.findIndex((obj) => obj.value === val);
    }
  }
  handleChange(e: { target: { value: string } }) {
    const updatedList = this.state.itemList.filter(
      (item: { description: string }) => {
        return (
          item.description.toLowerCase().indexOf(e.target.value.toLowerCase()) >
          -1
        );
      }
    );

    this.setState({ filterData: updatedList, inputValue: e.target.value });
  }

  updateSavedItem(data: any) {
    let allCoaList = [...this.state.itemList];
    allCoaList.forEach((element) => {
      element.isChecked = false;
    });
    this.setState({ itemList: allCoaList });
    data.forEach((element: { value: any }) => {
      this.state.itemList.filter(
        (itemList: { value: any }) => itemList.value === element.value
      )[0].isChecked = true;
    });
    this.setState({ selectedItems: data });
  }
  handleClick() {
    ;
    if (this.state?.childClick === true) {
      return false;
    }
    this.setState((prevState) => ({
      opened: !prevState.opened,
    }));


    const updatedList = this.state.itemList.filter(
      (item: { description: string }) => {
        return (
          item?.description?.toLowerCase()?.indexOf(this.state?.inputValue?.toLowerCase()) >
          -1
        );
      }
    );

    this.setState(
      {
        filterData: updatedList,
      },
      () =>
        this.dropDownFooter.current?.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        })
    );
    this.setState({ inputValue: "" });
  }

  handleOutsideClick(e) {
    this.setState((prevState) => ({
      opened: false,
    }));
    this.setState({ inputValue: "" });
  }

  handleSelectAll(e: any) {
    this.props.dataChange();
    let allItemList = [...this.state.itemList];
    let filterList = [...this.state.filterData];
    filterList.forEach((element) => {
      const index = allItemList.indexOf(element);
      allItemList[index].isChecked = true;
      element.isChecked = true;
    });


    this.setState(
      {
        selectedItems: filterList,
        itemList: allItemList,
        opened: false,
        inputValue: "",
      },
      () => this.dropDownFooter.current?.scrollIntoView()
    );
    const coaSelectedItem = allItemList.filter(
      (itemList: { isChecked: boolean }) => itemList.isChecked === true
    );
    this.props.updateMultiSelectItem(coaSelectedItem);
  }

  handleClearAll(e: any) {
    this.props.dataChange();
    let allItemList = [...this.state.itemList];
    allItemList.forEach((element) => {
      element.isChecked = false;
    });
    this.setState({ itemList: allItemList });
    this.setState({ selectedItems: [] });
    this.props.updateMultiSelectItem([], '', true);
  }

  render() {
    const hoverOutEllipsis = () => {
      $(".tooltip").removeClass("show");
    };
    let isSelectSingle = (this.props?.moduleReportId === 2166 || this.props?.moduleReportId === 2167 || this.props?.moduleReportId === 2168);
    return (
      <OutsideClickHandler onOutsideClick={this.handleOutsideClick}>
        <div
          className={
            this.state.opened ? "multiSelectSearch opened" : "multiSelectSearch"
          }
        >
          <div
            tabIndex={0}
            onKeyUp={(e) => this.onTabKeyDown(e)}
            ref={this.toggleRef}
            className="fieldArea"
            onMouseOut={this.onChipMouseOut}
            onClick={this.handleClick}
          >
            {this.state.selectedItems.length === 0 ? (
              <span className="placeholder">{this.props.placeholder || "Select Accounts"}</span>
            ) : (
              <div className="chip" onMouseOut={this.onChipMouseOut}>
                {this.state.selectedItems.map((item: any, idx: any) => (
                  <div
                    onMouseOver={this.onChipMouseOver}
                    className="chipSelect"
                    id={idx}
                    key={idx}
                  >
                    <div className="chipVal">{isSelectSingle ? item?.description : item.value}</div>
                    <button
                      id="btnremove"
                      type="button"
                      className="cross"
                      onMouseOver={this.onChipMouseOver}
                      onClick={this.handleRemoveSpecificChip(idx)}
                    >
                      <svg
                        width="8"
                        height="8"
                        viewBox="0 0 8 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.79465 0.229492L3.96598 3.05749L1.13798 0.229492L0.195312 1.17216L3.02331 4.00016L0.195312 6.82816L1.13798 7.77083L3.96598 4.94282L6.79465 7.77083L7.73731 6.82816L4.90931 4.00016L7.73731 1.17216L6.79465 0.229492Z"
                          fill="#6A6E73"
                        />
                      </svg>
                    </button>
                  </div>
                ))}
              </div>
            )}
            <div
              onMouseOver={this.onChipMouseOver}
              className="coaSearch"
              ref={(searchSection) => {
                this.searchSection = searchSection;
              }}
            >
              <div className="dropDownPanel">
                {this.props.isSearch ? <div className="SearchInput">
                  <input
                    key={"text-Filter"}
                    type="text"
                    className="form-control"
                    onChange={this.handleChange}
                    placeholder={this.state.filtertitle}
                    value={this.state.inputValue}
                  />
                </div> : <></>}
                <ul onWheel={() => hoverOutEllipsis()}>
                  {this.state.filterData.map((item: any, idx: any) => (
                    <>
                      {this.state.filterData[idx - 1]?.hotelType !==
                        item?.hotelType && (
                          <div>
                            <div className="dropdown-header">
                              {+item?.hotelType === 1 ? "ENTERPRISE ACCOUNT" : +item?.hotelType === 2 ? "HOTEL ACCOUNTS" : +item?.hotelType === 3 ? "NON-HOTEL ACCOUNTS" : ""}
                            </div>
                            {/* {idx > 0 && <div className="hotel-separator"> </div>} */}
                          </div>
                        )}
                      <li id={"hid" + idx} key={"hid" + idx}>
                        <Form.Check
                          custom
                          type="checkbox"
                          key={"hidli" + idx}
                          className="pRght"
                          id={"hidli" + idx}
                          label={
                            <EllipsisWithTooltip placement="bottom">
                              {item.description}
                            </EllipsisWithTooltip>
                          }
                          checked={item.isChecked}
                          onChange={this.handleCheckCheckbox(item.value)}
                        />
                      </li>

                      {this.state.filterData[idx - 1]?.hotelType &&
                        this.state.filterData[idx - 1]?.hotelType !==
                        item?.hotelType &&
                        idx === 0 &&
                        this.state?.filterData?.length !== idx + 1 && (
                          <div>
                            <div className="dropdown-header">
                              {+item?.hotelType === 1 ? "ENTERPRISE ACCOUNT" : +item?.hotelType === 2 ? "HOTEL ACCOUNTS" : +item?.hotelType === 3 ? "NON-HOTEL ACCOUNTS" : ""}
                            </div>
                            {/* <div className="hotel-separator"></div> */}
                          </div>
                        )}
                    </>
                  ))}
                </ul>
                <>
                  {!isSelectSingle && this.state.filterData.length > 0 && (
                    <>
                      {this.state.isSelectAll && (
                        <div ref={this.dropDownFooter} className="footer">
                          {this.state.itemList?.length !==
                            this.state.selectedItems?.length && (
                              <button
                                id="selectAll"
                                type="button"
                                className="btn wht-bg txt-green"
                                onClick={this.handleSelectAll}
                              >
                                Select All
                              </button>
                            )}
                          {this.state.itemList?.length ===
                            this.state.selectedItems?.length && (
                              <button
                                id="ClearAll"
                                type="button"
                                className="btn wht-bg txt-green"
                                onClick={this.handleClearAll}
                              >
                                Clear All
                              </button>
                            )}
                        </div>
                      )}
                    </>
                  )}
                </>
              </div>
            </div>
          </div>
        </div>
      </OutsideClickHandler>
    );
  }
}

MultiSelectSearchCustomCheck.defaultProps = {
  isSearch: true,
  placeholder :"Select Accounts"
};

export {MultiSelectSearchCustomCheck}
