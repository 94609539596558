import React, { forwardRef, useRef, useImperativeHandle } from "react";
import { Dropdown, Form } from "react-bootstrap";
import { DropdownItemProps } from "react-bootstrap/DropdownItem";
import { Hotel } from "../Services/Hotel";
import { IHotelResponseDto } from "../Contracts/IHotel";
import { FiChevronDown } from "react-icons/fi";
import { Pendo } from "../ThirdPartyServices/Pendo";


interface ISelectorProps {
  onClickEvent: any;
  ref: any;
  hotelList?: [];
  alertComponent?: boolean;
  modulename?: any;
  extraSelector?: any;
  pageName?: any;
  disabled?: boolean;
  showSearchBar?: boolean;
}

export const HidSelector: React.FC<ISelectorProps> = forwardRef(
  (props, ref) => {
    const propHotelList: IHotelResponseDto[] = ((props.hotelList as any)
      ?.length > 0
      ? props.hotelList
      : []) as any[];
    const [hotelList, setHotelList] = React.useState<IHotelResponseDto[]>(
      propHotelList
    );
    const [hotelCode, setHotelCode] = React.useState("");
    const [hotelID, setHotelID] = React.useState(null);
    
    const [hotelCount, setHotelCount] = React.useState(0);
    let [hasSeprator] = React.useState("1");

    React.useEffect(
      () => {
        console.log('module -' + props.modulename);
        let moduleName = props.modulename === undefined ? "Labor Management" : props.modulename;

        /* NW-33808 Added to fix issues in all pages in scenario when someone selects All from EHID dropdown in Accounts and then goes to a different page with this EHID selector component - START */
        if (localStorage.getItem("hotelSelected")) {
          const hotelData = JSON.parse(
            localStorage.getItem("hotelSelected")!
          );
          let isAllSelected = hotelData.lettercode.toString().toLowerCase() === "all";
          if(isAllSelected) {
            localStorage.removeItem("hotelSelected");
          }
        }
        if (sessionStorage.getItem("hotelSelected")) {
          const hotelData = JSON.parse(
            sessionStorage.getItem("hotelSelected")!
          );
          let isAllSelected = hotelData.lettercode.toString().toLowerCase() === "all";
          if(isAllSelected) {
            sessionStorage.removeItem("hotelSelected");
          }
        }
        /*NW-33808 Added to fix issues in all pages in scenario when someone selects All from EHID dropdown in Accounts and then goes to a different page with this EHID selector component - END */

        if (hotelList?.length === 0) {
          Hotel.getUserHotelAccess(moduleName)
            .then(async (result: IHotelResponseDto[] | null) => {
              if (result != null) {
                // debugger
                // if (props?.pageName === "Payment") {
                //   result = result.filter((o) => o.hotelType !== "3");
                // }
                result.sort(function (a, b) {
                  return parseInt(a.hotelType) - parseInt(b.hotelType);
                });
                setHotelList(result);
                setHotelCount(result.length);
                setfilterResult(result);
                if (result.length === 1 && props?.modulename !== 'Sales 2.0' && sessionStorage.getItem("hotelSelected") === null &&
                  localStorage.getItem("hotelSelected") === null // conparing loacal and session to null as the prop onClickEvent is calling twice in case of single ehid in dropdown
                ) {
                  
                  onHotelClick(result[0]);
                }
                else if (result.length === 1 && props?.modulename === 'Sales 2.0') {                
                onHotelClick(result[0]);
              } else if (result.length > 1 && props?.modulename === 'Sales 2.0')
                {
                  onHotelClick(result[0]);
                }
                else {
                  if (sessionStorage.getItem("hotelSelected")) {
                    const hotelData = JSON.parse(
                      sessionStorage.getItem("hotelSelected")!
                    );

                    let HotelExist = result.filter(x => x.lettercode === hotelData.lettercode.toString()).length;

                    if (HotelExist > 0) {

                      let hotelData1: any = result.filter(x => x.lettercode === hotelData.lettercode.toString())[0];
                      setHotelCode(hotelData1.lettercode);
                      setHotelID(hotelData1.hotelID);
                      if (sessionStorage.getItem("hotelSelected"))
                        sessionStorage.removeItem("hotelSelected");
                      sessionStorage.setItem("hotelSelected", JSON.stringify(hotelData1));

                      if (localStorage.getItem("hotelSelected"))
                        localStorage.removeItem("hotelSelected");
                      localStorage.setItem("hotelSelected", JSON.stringify(hotelData1));
                    }
                    if (!props.extraSelector) {
                      props.onClickEvent(hotelData);
                    }
                  } else if (localStorage.getItem("hotelSelected")) {
                    const hotelData = JSON.parse(
                      localStorage.getItem("hotelSelected")!
                    );
                    let HotelExist = result.filter(x => x.lettercode === hotelData.lettercode.toString()).length;
                    if (HotelExist > 0) {

                      let hotelData1: any = result.filter(x => x.lettercode === hotelData.lettercode.toString())[0];
                      setHotelCode(hotelData1.lettercode);
                      setHotelID(hotelData1.hotelID);
                      if (localStorage.getItem("hotelSelected"))
                        localStorage.removeItem("hotelSelected");
                      localStorage.setItem("hotelSelected", JSON.stringify(hotelData1));

                      if (sessionStorage.getItem("hotelSelected"))
                        sessionStorage.removeItem("hotelSelected");
                      sessionStorage.setItem("hotelSelected", JSON.stringify(hotelData1));
                    }
                    if (!props.extraSelector) {
                      props.onClickEvent(hotelData);
                    }
                  }
                }
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          setHotelList(propHotelList);
          setHotelCount(propHotelList?.length);
          setfilterResult(propHotelList);
        }
        if (hotelCode?.length === 0 && hotelList?.length > 0) {
          if (sessionStorage.getItem("hotelSelected")) {
            const hotelData = JSON.parse(
              sessionStorage.getItem("hotelSelected")!
            );
            setHotelCode(hotelData.lettercode);
            setHotelID(hotelData.hotelID);
            if (!props.extraSelector) {
              props.onClickEvent(hotelData);
            }
          }
          else if (localStorage.getItem("hotelSelected")) {
            const hotelData = JSON.parse(
              localStorage.getItem("hotelSelected")!
            );
            setHotelCode(hotelData.lettercode);
            setHotelID(hotelData.hotelID);
            if (!props.extraSelector) {
              props.onClickEvent(hotelData);
            }
          }
          // Adding Pendo Snippet after successful Ehid Initialization
          Pendo.IntegratePendo("hidSelectorFirst");
        }
      },
      [] // To prevent multiple rerendering don't include any dependency array.
    );

    const onHotelClick = (hotelData: any) => {

      if (!props.alertComponent) {
        //   props.onClickEvent(hotelData);
        //   return false;
        // } else {
        if (hotelData.lettercode.toString().toLowerCase() == 'all' && props?.modulename === 'Sales 2.0') {
            //nothing
        }
        else {
          if (sessionStorage.getItem("hotelSelected"))
            sessionStorage.removeItem("hotelSelected");
          sessionStorage.setItem("hotelSelected", JSON.stringify(hotelData));

          if (localStorage.getItem("hotelSelected"))
            localStorage.removeItem("hotelSelected");
          localStorage.setItem("hotelSelected", JSON.stringify(hotelData));
        }

        setHotelCode(hotelData.lettercode);
        setHotelID(hotelData.hotelID);

        // props.onClickEvent(hotelData);
        // Adding Pendo Snippet after successful any Ehid Change
        Pendo.IntegratePendo("hidSelectorChange");
      }
      props.onClickEvent(hotelData);
    };



    // Updating the State as the component changes on parent and alert is shown // Used on Forecast Page
    useImperativeHandle(ref, () => ({
      updateChildState(hotelData: any) {
        if (hotelCode !== hotelData.lettercode) {
          if (sessionStorage.getItem("hotelSelected"))
            sessionStorage.removeItem("hotelSelected");
          sessionStorage.setItem("hotelSelected", JSON.stringify(hotelData));

          if (localStorage.getItem("hotelSelected"))
            localStorage.removeItem("hotelSelected");
          localStorage.setItem("hotelSelected", JSON.stringify(hotelData));

          setHotelCode(hotelData.lettercode);
          setHotelID(hotelData.hotelID);
          // Adding Pendo Snippet after successful any Ehid Change
          Pendo.IntegratePendo("hidSelectorChange");
        }
      },
      handleHIDList() {
        return hotelList;
      },
    }));

    const [filterResult, setfilterResult] = React.useState<IHotelResponseDto[]>(
      propHotelList
    );

    const [search, setSearch] = React.useState("");

    const searchEHIDs = (e: any) => setSearch(e.target.value);

    React.useEffect(() => {
      setHotelList(
        filterResult.filter((hName) =>
          hName.hotelName.toLowerCase().includes(search.toLowerCase().trim())
        )
      );
    }, [filterResult, search]);

    const hasCheck = () => {
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.49998 11.6895L5.03023 9.21973L3.96973 10.2802L7.49998 13.8105L14.7802 6.53023L13.7197 5.46973L7.49998 11.6895Z"
            fill="#2CAF92"
          />
        </svg>
      );
    };

    const searchClear = () => {
      setSearch("");
    };

    return (
      <Dropdown className="hid-select">
        <Dropdown.Toggle  disabled={props?.disabled}  id="dropdown-hid" onFocus={searchClear}>
          <div className="d-flex align-items-center justify-content-center">
            { hotelCode.length > 0 ? hotelCode : "Select"}
            <div className="drop-arrow d-flex">
              <FiChevronDown />
            </div>
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className="cusEhidSelector">
          {hotelCount > 1 && props.showSearchBar !== false && (
            <div className="EhidSearch">
              <Form.Group controlId="formBasicEmail">
                <Form.Control
                  autoFocus
                  type="text"
                  name="search"
                  value={search}
                  data-lpignore="true"
                  autoComplete="off"
                  onChange={searchEHIDs}
                  placeholder="Filter EHIDs"
                />
              </Form.Group>
            </div>
          )}
          <div className="dropdownItems">
            {hotelList?.length > 0 ? (
              hotelList.map((item: IHotelResponseDto, idx: number) => (
                <React.Fragment key={idx}>
                  {item.hotelType !== hasSeprator
                    ? (hasSeprator = item.hotelType) && <Dropdown.Divider />
                    : null}
                  <Dropdown.Item
                    eventKey={item.lettercode}
                    onClick={(e: React.MouseEvent<DropdownItemProps>): any =>
                      onHotelClick(item)
                    }
                  >
                    <div
                      className={`dropDownListTitle ${item.hotelID === hotelID ? "hasCheck" : null
                        }`}
                    >
                      {
                        props?.modulename === "Invoice" &&
                        <span className={item?.bluePip?.toLowerCase() === "yes" ? "pip blue" : "pip"}></span>
                      }
                      <span className="name">
                        {item.hotelName.split(" - ")[0]}
                      </span>
                      <span className="title">
                        {item.hotelName.replace(" - ", ".").split(".")[1]}
                      </span>
                      <span className="itemcheck">
                        {item.hotelID === hotelID ? hasCheck() : null}
                      </span>
                    </div>
                  </Dropdown.Item>
                </React.Fragment>
              ))
            ) : (
              <Dropdown.Item>
                <b>No Item Found</b>
              </Dropdown.Item>
            )}
          </div>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
);

// Default Props on HidSelector

HidSelector.defaultProps = {
  alertComponent: false,
  extraSelector: false,
  disabled: false,
  showSearchBar: true
};
