import * as React from "react";
import { Link } from "react-router-dom";
import { Navbar, Nav, Modal, Button, Form, Dropdown } from "react-bootstrap";
import logo from "../Assets/Images/logo.png";
import { User } from "../Services/User";
import { HeaderMenu } from "../Services/HeaderMenu";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { resolve, reject } from "q";
import { Pendo } from "../ThirdPartyServices/Pendo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { FreshDeskChat } from "../ThirdPartyServices/FreshDeskChat";
import { TimeClockDeviceDetail } from "../../Common/Services/TimeClockDeviceDetails";
import { Cookies } from "react-cookie";
import OutsideClickHandler from "react-outside-click-handler";
import moment from "moment-timezone";
import { Hotel } from "../Services/Hotel";
// import { HidSelector } from "../../Common/Components/HidSelector";
import { LaborDeptPosition as laborDeptPosition } from "../../Common/Services/LaborDeptPosition";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { ILaborSaveScheduleDetails } from "../../Common/Contracts/ILaborSchedule";
import { LaborPerformance as laborPerformance } from "../../Common/Services/LaborPerfomance";
import { TimeClockCommonFunc } from "../../WebTimeClock/TimeClockCommonFunc";
import { Spinner } from "react-bootstrap";
import { IHotelResponseDto } from "../../Common/Contracts/IHotel";
import { RoleSubPermissionSetting } from "../../Common/Services/RoleSubPermissionSetting";
import { SingleSearchDropdownList } from "../../Common/Components/SingleSearchDropdownList";
import { ProfileUserDetailSlideOut } from "../../Modules/UserManagement/components/user-profile-slideout/ProfileUserDetailSlideOut";
import { ILaborDayApproveRequestDto } from "../../Common/Contracts/ILaborDayApprove";
import { LaborDayApprove } from "../../Common/Services/LaborDayApprove";
import { Hotel as hotel } from "../../Common/Services/Hotel";
import $ from "jquery";
import { Utils } from "../Utilis";
import { SalesService } from "../Services/SalesService"; 

interface IheaderNavLinks {
  mobileMainMenu?: string;
  mobileSubMenu?: string;
  desktopMainMenu?: string;
  desktopSubMenu?: string;
}


const cookies = new Cookies();
export class Header extends React.Component<any, any> {
  private childHID: any;

  constructor(props: any) {
    super(props);
    this.childHID = React.createRef();
    this.state = {
      navigationpipCount: [{ module: 'Accounting', pipCount: 0 }, { module: 'Labor', pipCount: 0 }],
      modulePipCount: [],
      specialCondition: false,
      active: false,
      defaultLink: "Home",
      activeLink: "Home",
      lastActiveLink: "Home",
      subActiveLink: "",
      showMenu: "Home",
      showMenuOver: "Home",
      activeClickSubMenu: "",
      prevClickSubMenu: "",
      menuItems: [],
      menuItemTop: [],
      menuItemBottom: [],
      menusPermission: [],
      userName: "",
      roleName: "",
      opened: true,
      subClickOpened: false,
      hoverMenu: false,
      hoverDelay: 0,
      isToggle: false,
      iconHoverChange: "",
      isSmileID: false,
      pageLoadCount: 0,
      showProfileMenu: false,
      allowPunch: false,
      allowPunchIn: 0,
      allowPunchOut: 0,
      showModalWait: false,
      userPunchModal: false,
      userHasNoSchedule: false,
      hidValue: "Select",
      hotelName: "Select",
      departmentName: "Select",
      departmentNameID: "",
      positionName: "Select",
      positionNameID: "",
      departmentlist: [],
      alldepartmentlist: [],
      allpositionlist: [],
      punchTime: "",
      positionError: false,
      departmentError: false,
      punchTimeError: false,
      punchType: "",
      scheduleData: [],
      selectedSechdule: [],
      enterpriseEarlyPunch: 0,
      navigationCalenderDates: [],
      hotelCurrentTime: "",
      hotelCurrentDate: "",
      punchButtonSpinner: false,
      hidListCount: [],
      // Mobile Navigation State Management
      mobileNavOpen: false,
      selectedMobileMainMenu: "",
      selectedMobileSubMenu: "",
      mobileMenuTop: [],
      mobileMenuBottom: [],
      mobileTopProfile: {},
      tabEnable: 0,
      headerEHID: "Select",
      hasForecast: true,
      forecastMessage: "",
      theme: "",
      showDefaultThemeToggle: "",
      hasLaborPermission: false,
      isOpenUserProfileSlideOut: false,
      Sales1Permission: 'No',
      Sales2Permission: 'No',
      showPip: true,
      isRedirectOldSite: false,
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleClickPinup = this.handleClickPinup.bind(this);
  }

  logout() {
    User.removeToken()
      .then(async (result: any | null) => {
        if (result) {
          User.isAuthenticated = false;
          User.apiLoginResponse = null;
          localStorage.clear();
          sessionStorage.clear();
          // Adding Pendo Snippet after successful logout
          Pendo.IntegratePendo("userLogout");
          let mergeURL = result.result.mergeURL;
          window.open(
            mergeURL + "?logInUser=" + btoa("logout") + "&logInType=3"
          );
        }
        resolve();
      })
      .catch((err) => {
        Utils.toastError(`Server Error, ${err}`);
        reject();
      });

    localStorage.setItem("token", "");
    localStorage.setItem("useDefaultTheme", "");
  }

  handleClick() {
    this.setState({ hoverMenu: false })
    this.setState((prevState: { opened: any }) => ({
      opened: !prevState.opened,
    }), () => {
      const isNavOpened: any[] = [];
      isNavOpened.push({
        fieldName: "isNavOpened",
        fieldValue: this.state.opened,
        pageName: "NavigationHeader",
      });
      laborPerformance.saveDefaultViewProperties(isNavOpened)
        .then(async (result: any | null) => {
          if (result != null) {
            if (result.message === "Success") {
              // toast.success(result?.result?.message, { position: toast.POSITION.BOTTOM_RIGHT });
            }
          }
        })
        .catch((error) => {
          Utils.toastError(error.message, { position: toast.POSITION.BOTTOM_RIGHT });
          this.setState({ showLoader: false });
        });
    }
    );
    // localStorage.setItem(
    //   "opened",
    //   this.state.opened === false ? "true" : "false"
    // );
  }


  handlesubMenu = (menu, subMenu) => {
    this.setState({
      activeLink: menu,
      lastActiveLink: menu,
      showMenu: menu,
      defaultLink: menu,
      subActiveLink: subMenu,
    }, () => {

      localStorage.setItem("mainMenu", menu);
      this.setState({
        activeClickSubMenu: menu,
        prevClickSubMenu: menu,
      });
      this.setState({ subClickOpened: true });
      this.setState({ subActiveLink: subMenu });
      localStorage.setItem("subMenu", subMenu);

    });


  }
  getPreviousUrl = () => {
    let url = document.location.href;
    let pathname = new URL(url).pathname;
    return pathname;

  }

  hideMenuByUrl = () => {
    let url = document.location.href;
    let pathname = new URL(url).pathname;
    if (pathname === '/Account-management/DetailsReport') {
      return false;
    } else {
      return true;

    }
  }

  getFirstItemSelected1 = (menu: any) => {

    let url = document.location.href;
    let pathname = new URL(url).pathname;

    let mainMenu = localStorage.getItem("mainMenu");

    let returnURL = "/";
    if (menu === "Home") {
      returnURL = "/home";
    }
    else if (menu === "Labor" && !mainMenu?.toLowerCase().includes("labor")) {
      returnURL = "/labor-management/approval";
    }
    else if (menu === "Accounting" && !mainMenu?.toLowerCase().includes("accounting")) {
      returnURL = "/Account-management/Overview";
    }
    // else if (menu === "Dashboard") {
    //   returnURL = "/dashboard";
    // }
    else if ((menu === "Settings" || menu === "Administration") && !mainMenu?.toLowerCase().includes("administration")) {
      returnURL = "/labor-settings/role-permission";
    }
    else {
      returnURL = pathname;
    }


    return returnURL;
  };


  handleClickPinup = (eventKey: any) => {
    this.setState({ specialCondition: true, opened: true, hoverMenu: false, });
  }

  componentDidMount() {
    let storage = JSON.parse(localStorage.getItem("storage")!);

    let userName = storage === null ? 0 : (storage.userName as any);
    let roleName = storage === null ? 0 : (storage.roleName as any);
    //FreshDeskChat.hideFreshDesk();
    FreshDeskChat.IntegrateFreshDeskChat(userName);
    // this.getModulePipCount();
    this.getSalesVersionPermission();
    this.getMenuPermission();
    // this.GetMenuList();
    if (localStorage.getItem("showIncompleteSlideOut") === "false") {
      this.GetMenuList();
    }
    this.getPunchPermission();
    this.updateExistingCookie();
    this.loadHotelsDetails();
    let mainMenu =
      localStorage.getItem("mainMenu") === null
        ? "Home"
        : localStorage.getItem("mainMenu");
    let subMenu =
      localStorage.getItem("subMenu") === null
        ? ""
        : localStorage.getItem("subMenu");
    // let opened =
    //   localStorage.getItem("opened") === null
    //     ? true
    //     : localStorage.getItem("opened") === "true"
    //       ? true
    //       : false;

    laborPerformance.getDefaultViewProperties("NavigationHeader")
      .then(async (response: any | null) => {
        if (response?.length > 0) {
          this.setState({ opened: response[0].fieldValue === "false" ? false : true });
        }
        if (response?.length == 0) {
          if (window.innerWidth < 1200 && window.innerWidth >= 320) {
            this.setState({ opened: false });
          }
        }
      })
      .catch((error) => {
        Utils.toastError(error.message, { position: toast.POSITION.BOTTOM_RIGHT });
      });

    let useDefaultTheme = localStorage.getItem("useDefaultTheme");
    let showDefaultThemeToggle = storage.showDefaultThemeToggle;
    this.setState({
      activeLink: mainMenu,
      showMenu: mainMenu,
      subActiveLink: subMenu,
      subClickOpened: subMenu === "" ? false : true,
      // opened: opened,
      theme: useDefaultTheme == "true" ? "" : storage.theme,
      showDefaultThemeToggle
    });

    this.setState({ userName: userName, roleName: roleName });

    if (localStorage.getItem("showIncompleteSlideOut") === "true" && !window.location.href.toString().toLowerCase().includes('/home')) {
      localStorage.removeItem("showIncompleteSlideOut")
      localStorage.removeItem("PopUpState")
      window.location.replace("/login");
    }

    // if (window.innerWidth < 1200 && window.innerWidth >= 320) {
    //   this.setState({ opened: false });
    // }


  }

  updateExistingCookie = () => {
    TimeClockDeviceDetail.GetTimeClockCookieDetailsForUpdateTime()
      .then(async (result: any | null) => {
        if (result?.message === "Success") {
          let found = document.cookie
            .split(";")
            .filter((c) => c.trim().split("=")[0] === "WebTimeClock");
          let cookieValue = found.length > 0 ? found[0].split("=")[1] : "";
          if (cookieValue === undefined) cookieValue = "";

          if (cookieValue !== "") {
            let rowFound = result.result.filter(
              (item: { uniqueID: string }) => item.uniqueID === cookieValue
            );
            if (rowFound.length > 0) {
              if (new Date(rowFound[0].expiryDate) <= new Date("01/01/2025")) {
                let request = {} as any;
                request.RecordID = rowFound[0].iF_Record;
                request.Type = "Cookie";
                request.Value = "Cookie";
                TimeClockDeviceDetail.UpdateCookieExpireTime(request)
                  .then(async (result: any | null) => {
                    if (result === "Success") {
                      cookies.set("WebTimeClock", cookieValue, {
                        expires: new Date("01/01/2025"),
                        domain: window.location.hostname,
                        path: "/",
                      });
                    }
                    resolve();
                  })
                  .catch((err) => {
                    Utils.toastError(`Server Error, ${err}`);
                    reject();
                  });
              }
            }
          }
        }
        resolve();
      })
      .catch((err) => {
        Utils.toastError(`Server Error, ${err}`);
        reject();
      });
  };

  capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  getNavItems = () => {
    let url = document.location.href;
    let pathname = new URL(url).pathname;
    let currentPath: any;
    let path1 = "";
    let path2 = "";
    if (pathname !== undefined) {
      if (this.state.menuItems.length > 0) {
        this.state.menuItems.map((menuItem: { subMenuMaster: any[] }) => {
          menuItem.subMenuMaster.map(
            (menuList: { menuSubItemDetail: any[] }) => {
              menuList.menuSubItemDetail.filter(
                (subMenus: { itemPath: string }) => {
                  if (subMenus.itemPath === pathname) {
                    return (currentPath = subMenus);
                  }
                  return false;
                }
              );
              return false;
            }
          );
          return false;
        });
      }

      if (currentPath !== undefined) {
        if (currentPath.itemPath === "/") {
          path1 = "Home";
          path2 = "";
          window.location.href = "/home";
        } else {
          path1 = currentPath.lavel1MenuName;
          path2 = currentPath.name;
        }

        if (path1 != "Profile") {
          localStorage.removeItem("mainMenu");
          localStorage.removeItem("subMenu");

          localStorage.setItem("mainMenu", path1);
          if (path1 === "Accounting" && path2 === "Overview") {
            localStorage.setItem("subMenu", path1 + "-" + path2);
          }
          else if (path1 === "Facilities" && path2 === "Overview") {
            localStorage.setItem("subMenu", path1 + "-" + path2);
          }
          else if (path1 === "Dashboard" && path2 === "Daily Flash") {
            localStorage.setItem("subMenu", currentPath.itemID + "-" + path2);
          }
          else if (path1 === "Facilities" && path2 === "Daily Tasks") {
            localStorage.setItem("subMenu", currentPath.itemID + "-" + path2);
          }
          else {
            localStorage.setItem("subMenu", path2);
          }
          this.setState({
            activeLink: path1,
            subClickOpened: true,
            showMenu: path1,
            prevClickSubMenu: path1,
          });
          // this.getMenuItems();
        }
      }
      else {
        if (pathname == "/Home" || pathname == "/home") {
          localStorage.setItem("mainMenu", "Home");
          localStorage.setItem("subMenu", "");
          this.setState({
            activeLink: "Home",
            subClickOpened: false,
            showMenu: "Home",
            prevClickSubMenu: "",
          });
        }
      }
    }
  };

  getSalesVersionPermission = () => {

    SalesService.GetSalesVesrionPermission()
      .then(async (result: any | null) => {
        if (result !== null) {

          this.setState({
            Sales1Permission: result.result.sales1Permission,
            Sales2Permission: result.result.sales2Permission
          });
        }
        resolve();
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error, ${err}`);
        reject();
      });
  };

  getParamValueByParamName(paramName: string): any {
    let valuesArray = decodeURIComponent(this.props.history.location.search)?.replace('?', '')?.split('&');
    window.history.replaceState(null, '');
    return valuesArray;
    // const item = valuesArray?.find(r => r?.includes(paramName));
    // return     item ? window.atob(item?.split('=')[1] as any) : "";
  }

  handleDeepLinkingForCommunicationInbox = ()=>{
    const valuesArray = this.getParamValueByParamName("");
    if (valuesArray.length > 0) {
      const { location, history } = this.props;
      //use the state via location.state
      //and replace the state via
      // history.replace();
      
      const page = valuesArray ? valuesArray[0]?.split('=')[1] as any : "";
      
      if(page == "CommunicationsInbox") {
        localStorage.setItem("mainMenu", "Communications");
        window.location.replace("/CommunicationsInbox?activePageTab=All");
       
      }
    }
  }

  getMenuPermission = () => {
    let requestObject = {} as any;
    requestObject.permissionID = "All";
    requestObject.Module = "LaborManagement";
    requestObject.Type = "Menu";
    HeaderMenu.GetMenuPermissionData(requestObject)
      .then(async (result: any | null) => {
        if (result !== null && result.length > 0) {
          this.setState({ menusPermission: result }, () => {
            //deeplinking
            this.handleDeepLinkingForCommunicationInbox()
            if (localStorage.getItem("showIncompleteSlideOut") === "false") {
              // this.getMenuItems();
            }

          });
        }
        resolve();
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error, ${err}`);
        reject();
      });
  };

  getModulePipCount = () => {

    HeaderMenu.getModulePipCount()
      .then(async (result: any | null) => {
        if (result !== null && result.length > 0) {
          //this.setState({ modulePipCount: result });

          let navigationpipCount = [...this.state.navigationpipCount];
          navigationpipCount.map((Item) => {
            if (Item.module === "Accounting") {
              Item.pipCount = Number(Item.pipCount) + Number(result[0].pipCount);
            }
          })
          this.setState({ navigationpipCount }, () => {


          });
        }
        resolve();
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error, ${err}`);
        reject();
      });
  };

  getPunchPermission = () => {
    let requestObject = {} as any;
    requestObject = {} as any;
    requestObject.permissionID = "10035";
    requestObject.Module = "LaborManagement";
    requestObject.Type = "Page";
    HeaderMenu.GetPunchDataPermission(requestObject)
      .then(async (result: any | null) => {
        if (result !== null && result.length > 0) {
          let hasPermission = result.filter(
            (x: { permissionName: string }) =>
              x.permissionName?.toLowerCase().trim() === "punch from anywhere"
          );
          if (hasPermission && hasPermission.length > 0) {
            this.setState({ allowPunch: true });
          }
        }
        resolve();
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error, ${err}`);
        reject();
      });
    HeaderMenu.CheckPunchStatus()
      .then(async (result: any | null) => {
        if (result !== null && result?.result?.length > 0) {
          this.setState({
            allowPunchIn: result.result[0].allowPunchIn,
            allowPunchOut: result.result[0].allowPunchOut,
          });
        }
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error, ${err}`);
        reject();
      });
  };

  checkChildList = (result: any[]) => {
    let defaultItem: any = {};
    result.map((menuItems: any) => {
      let menu = menuItems.name;
      menuItems.isChildItem = 1;

      let subMenuItemlist = menuItems.subMenuMaster.filter(eleItem => eleItem.menuSubItemDetail.length > 0);
      if (subMenuItemlist.length > 0) {
        menuItems.isChildItem = 0;
        subMenuItemlist.map((item) => {
          for (let i = 0; i < item.menuSubItemDetail.length; i++) {
            let lineitem = item.menuSubItemDetail[i];
            let permissionid = lineitem.permissionID;
            if (permissionid === 0) {
              menuItems.isChildItem = 1;
              break;
            }
            else {
              let isPerExist = this.state.menusPermission.filter(per => per.permissionID === permissionid);
              if (isPerExist.length > 0) {
                menuItems.isChildItem = 1;
                break;
              }
            }
          }

        })

      }
    });

    return result;

  }

  getMenuItems() {
    HeaderMenu.GetHeaderData()
      .then(async (result: any[] | null) => {
        if (result != null) {
          let newresult = this.checkChildList(result);
          let resultData = newresult.filter(x => x.isChildItem === 1);

          let menuItemTop: any[] = [];
          let menuItemBottom: any[] = [];
          let hasLaborPermission = false;
          menuItemTop = resultData.filter(
            (e: { itemPosition: string }) => e.itemPosition === "Top"
          );
          menuItemBottom = resultData.filter(
            (e: { itemPosition: string }) => e.itemPosition === "Bottom"
          );
          // Mobile Nav Data Starts
          let mobileMenuTop: any = [];
          let mobileMenuBottom: any = [];
          let mobileTopProfile: any = {};
          for (let i = 0; i < menuItemTop.length; i++) {
            let pushObject: any = menuItemTop[i];
            pushObject.openAction = false;
            if (pushObject.name === "Labor") {
              hasLaborPermission = true;
            }
            mobileMenuTop.push(pushObject);
          }
          for (let i = 0; i < menuItemBottom.length; i++) {
            if (menuItemBottom[i].menuID !== 13) {
              let pushObject: any = menuItemBottom[i];
              pushObject.openAction = false;
              mobileMenuBottom.push(pushObject);
            } else {
              mobileTopProfile = menuItemBottom[i];
              mobileTopProfile.openAction = false;
            }
          }
          // Mobile Nav Data Ends
          this.setState({
            menuItems: resultData,
            overmenuItems: resultData,
            menuItemTop: menuItemTop,
            menuItemBottom: menuItemBottom,
            hoverDelay: resultData[0].menuDelayTime,
            mobileMenuTop,
            mobileMenuBottom,
            mobileTopProfile,
            hasLaborPermission
          }, () => {
            // this.bindDailyAlert();
          });
        }
        resolve();
        if (this.state.pageLoadCount === 0) {
          this.setState({ pageLoadCount: 1 });
          this.getNavItems();
        }
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error, ${err}`);
        reject();
      });
  }

  GetMenuList() {
    HeaderMenu.GetMenuList()
      .then(async (resultData: any[] | null) => {
        if (resultData != null) {
          let menuItemTop: any[] = [];
          let menuItemBottom: any[] = [];
          let hasLaborPermission = false;
          menuItemTop = resultData.filter(
            (e: { itemPosition: string }) => e.itemPosition === "Top"
          );
          menuItemBottom = resultData.filter(
            (e: { itemPosition: string }) => e.itemPosition === "Bottom"
          );
          // Mobile Nav Data Starts
          let mobileMenuTop: any = [];
          let mobileMenuBottom: any = [];
          let mobileTopProfile: any = {};
          for (let i = 0; i < menuItemTop.length; i++) {
            let pushObject: any = menuItemTop[i];
            pushObject.openAction = false;
            if (pushObject.name === "Labor") {
              hasLaborPermission = true;
            }
            mobileMenuTop.push(pushObject);
          }
          for (let i = 0; i < menuItemBottom.length; i++) {
            if (menuItemBottom[i].menuID !== 13) {
              let pushObject: any = menuItemBottom[i];
              pushObject.openAction = false;
              mobileMenuBottom.push(pushObject);
            } else {
              mobileTopProfile = menuItemBottom[i];
              mobileTopProfile.openAction = false;
            }
          }
          // Mobile Nav Data Ends
          this.setState({
            menuItems: resultData,
            overmenuItems: resultData,
            menuItemTop: menuItemTop,
            menuItemBottom: menuItemBottom,
            hoverDelay: resultData[0].menuDelayTime,
            mobileMenuTop,
            mobileMenuBottom,
            mobileTopProfile,
            hasLaborPermission
          }, () => {
            // this.bindDailyAlert();
          });
        }
        resolve();
        if (this.state.pageLoadCount === 0) {
          this.setState({ pageLoadCount: 1 });
          this.getNavItems();
        }
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error, ${err}`);
        reject();
      });
  }


  payrollExportFirstApi = () => {
    let request: any = {};
    let localStorageUser = JSON.parse(localStorage.getItem("storage")!);
    request.tenantID = localStorageUser.tenantID;
    request.hotelID = 0;
    request.LoginUserName = localStorageUser.userName;
    request.callType = "AllPayPeriodCount";
    hotel
      .GetPayrollExportData(request)
      .then(async (result) => {
        if (result.message === "Success") {
          let dayCount = 0;
          let apiResult = result.result;
          let navigationpipCount = [...this.state.navigationpipCount];
          navigationpipCount.map((Item) => {
            if (Item.module === "Labor") {
              Item.pipCount = Number(Item.pipCount) + Number(apiResult[0].pipCount);
            }
          })
          this.setState({ navigationpipCount }, () => {


          });

        }

        resolve();
      })
      .catch((error) => {
        this.setState({ loaderTrue: false });
        Utils.toastError(`Server Error: ${error}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "laborDayOverviewContainer",
        });
        reject();
      });
  };


  TimeOffRequestFirstApi = () => {
    let request: any = {};
    let localStorageUser = JSON.parse(localStorage.getItem("storage")!);
    request.tenantID = localStorageUser.tenantID;
    request.hotelID = 0;
    request.LoginUserName = localStorageUser.userName;
    request.callType = "HotelWiseCount";
    hotel.GetPendingTimeoffCount(request).then(async (result) => {
      if (result.success) {
        let dayCount = 0;
        let apiResult = result.result;
        let navigationpipCount = [...this.state.navigationpipCount];
        navigationpipCount.map((Item) => {
          if (Item.module === "Labor") {
            Item.pipCount = Number(Item.pipCount) + Number(apiResult[0].pipCount);
          }
        })
        this.setState({ navigationpipCount }, () => {


        });

      }
      resolve();
    })
      .catch((error) => {
        this.setState({ loaderTrue: false });
        Utils.toastError(`Server Error: ${error}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "timeoffrequestCountContainer",
        });
        reject();
      });
  };



  bindOverRisk(response: any) {
    hotel.GetOverTimeRiskData(response)
      .then(async (result) => {
        if (result != null && result.message !== "Fail") {
          let data = result.result;
          let navigationpipCount = [...this.state.navigationpipCount];
          navigationpipCount.map((Item) => {
            if (Item.module === "Labor") {
              Item.pipCount = Number(Item.pipCount) + Number(data[0].pipCount);
            }
          })
          this.setState({ navigationpipCount }, () => {


          });

        }
      })
      .catch((error) => {
        reject();
      });
  }

  bindDailyAlert() {
    let localStorageUser = JSON.parse(localStorage.getItem("storage")!);
    let request = {} as ILaborDayApproveRequestDto;
    request.hotelID = "All";
    request.userUniqueNo = localStorageUser.user_UniqueID;
    // this.binDayApproveData(request);
    this.GetLaborApproveDayAlertBoxCount(request);
    this.GetLaborSmileIDAlertBoxCount(request);
    this.GetLaborRoomAssignmentAlertBoxCount(request);
    this.GetLaborHousekeepingApprovalAlertBoxCount(request);
    this.GetLaborBreakAttestationAlertBoxCount(request);
    this.GetLaborHolidayCalendarAlertBoxCount(request);

    let response1: any = {};
    response1.tenantID = localStorageUser.tenantID;
    response1.hotelID = 0;
    response1.loginUserName = localStorageUser.userName;
    response1.callType = "AllHotelCount";
    response1.userName = "";
    this.bindOverRisk(response1);
    this.payrollExportFirstApi();
    this.TimeOffRequestFirstApi();


  }

  GetLaborApproveDayAlertBoxCount = (request: ILaborDayApproveRequestDto) => {
    LaborDayApprove.GetLaborApproveDayAlertBoxCount(request)
      .then(async (result: any | null) => {
        if (result != null && result.length > 0) {
          let navigationpipCount = [...this.state.navigationpipCount];
          navigationpipCount.map((Item) => {
            if (Item.module === "Labor") {
              Item.pipCount = Number(Item.pipCount) + Number(result[0].pipCount);
            }
          })
          this.setState({ navigationpipCount });
        }
        resolve();
      })
      .catch((error) => {
        Utils.toastError(`Server Error: ${error}`, {
        });
        reject();
      });
  }

  GetLaborSmileIDAlertBoxCount = (request: ILaborDayApproveRequestDto) => {
    LaborDayApprove.GetLaborSmileIDAlertBoxCount(request)
      .then(async (result: any | null) => {
        if (result != null && result.length > 0) {
          let navigationpipCount = [...this.state.navigationpipCount];
          navigationpipCount.map((Item) => {
            if (Item.module === "Labor") {
              Item.pipCount = Number(Item.pipCount) + Number(result[0].pipCount);
            }
          })
          this.setState({ navigationpipCount });
        }
        resolve();
      })
      .catch((error) => {
        Utils.toastError(`Server Error: ${error}`, {
        });
        reject();
      });
  }

  GetLaborRoomAssignmentAlertBoxCount = (request: ILaborDayApproveRequestDto) => {
    LaborDayApprove.GetLaborRoomAssignmentAlertBoxCount(request)
      .then(async (result: any | null) => {
        if (result != null && result.length > 0) {
          let navigationpipCount = [...this.state.navigationpipCount];
          navigationpipCount.map((Item) => {
            if (Item.module === "Labor") {
              Item.pipCount = Number(Item.pipCount) + Number(result[0].pipCount);
            }
          })
          this.setState({ navigationpipCount });
        }
        resolve();
      })
      .catch((error) => {
        Utils.toastError(`Server Error: ${error}`, {
        });
        reject();
      });
  }

  GetLaborHousekeepingApprovalAlertBoxCount = (request: ILaborDayApproveRequestDto) => {
    LaborDayApprove.GetLaborHousekeepingApprovalAlertBoxCount(request)
      .then(async (result: any | null) => {
        if (result != null && result.length > 0) {
          let navigationpipCount = [...this.state.navigationpipCount];
          navigationpipCount.map((Item) => {
            if (Item.module === "Labor") {
              Item.pipCount = Number(Item.pipCount) + Number(result[0].pipCount);
            }
          })
          this.setState({ navigationpipCount });
        }
        resolve();
      })
      .catch((error) => {
        Utils.toastError(`Server Error: ${error}`, {
        });
        reject();
      });
  }

  GetLaborBreakAttestationAlertBoxCount = (request: ILaborDayApproveRequestDto) => {
    LaborDayApprove.GetLaborBreakAttestationAlertBoxCount(request)
      .then(async (result: any | null) => {
        if (result != null && result.length > 0) {
          let navigationpipCount = [...this.state.navigationpipCount];
          navigationpipCount.map((Item) => {
            if (Item.module === "Labor") {
              Item.pipCount = Number(Item.pipCount) + Number(result[0].pipCount);
            }
          })
          this.setState({ navigationpipCount });
        }
        resolve();
      })
      .catch((error) => {
        Utils.toastError(`Server Error: ${error}`, {
        });
        reject();
      });
  }

  GetLaborHolidayCalendarAlertBoxCount = (request: ILaborDayApproveRequestDto) => {
    LaborDayApprove.GetLaborHolidayCalendarAlertBoxCount(request)
      .then(async (result: any | null) => {
        if (result != null && result.length > 0) {
          let navigationpipCount = [...this.state.navigationpipCount];
          navigationpipCount.map((Item) => {
            if (Item.module === "Labor") {
              Item.pipCount = Number(Item.pipCount) + Number(result[0].pipCount);
            }
          })
          this.setState({ navigationpipCount });
        }
        resolve();
      })
      .catch((error) => {
        Utils.toastError(`Server Error: ${error}`, {
        });
        reject();
      });
  }

  binDayApproveData(request: ILaborDayApproveRequestDto) {
    LaborDayApprove.GetLaborDayApprovalDetails(request)
      .then(async (result: any | null) => {
        if (result != null && result.length > 0) {

          let totalPipCount = 0;
          for (let x = 0; x < result.length; x++) {
            totalPipCount = totalPipCount + Number(result[x].pipCount);
          }
          let navigationpipCount = [...this.state.navigationpipCount];
          navigationpipCount.map((Item) => {
            if (Item.module === "Labor") {
              Item.pipCount = Number(Item.pipCount) + Number(totalPipCount);
            }
          })
          this.setState({ navigationpipCount }, () => {


          });
        }
        resolve();
      })
      .catch((error) => {
        reject();
      });
  }

  checkLabel = (
    menuList: {
      menuSubItemDetail: any[];
      name: string;
      isHide: number;
      menuID: number;
      displayName: string;
    },
    viewType: string
  ) => {
    if (menuList.isHide === 0 && menuList.menuSubItemDetail?.length > 0) {
      if (viewType === "desktopView") {
        return <div className="section-name">{menuList?.displayName}</div>;
      } else {
        return <div className="sub-menu-heading">{menuList?.displayName}</div>;
      }
    }
  };

  handleOutsideClick = () => {
    !this.state.userPunchModal &&
      this.setState({ showProfileMenu: !this.state.showProfileMenu });
  };

  // Vikas User Punch
  resetUserSchedule = () => {
    this.setState(
      {
        departmentNameID: "",
        positionNameID: "",
        departmentName: "Select",
        positionName: "Select",
        punchTime: "",
        departmentError: false,
        positionError: false,
        punchTimeError: false,
      },
      () => {
        this.setState({
          hidValue: "Select",
          hotelName: "Select",
          headerEHID: "Select",
          hasForecast: true,
          forecastMessage: "",
        });
      }
    );
  };

  setDisplayDate = () => {
    let timeZoneValue = TimeClockCommonFunc.getCurrentTimeZone(
      this.state.hidTimeZone
    );
    let zoneDate: any = moment.tz(timeZoneValue).format("DD");
    let zoneMonth: any = moment.tz(timeZoneValue).format("MM");
    let zoneYear: any = moment.tz(timeZoneValue).format("YYYY");
    let curretnzoneDate = `${zoneMonth}/${zoneDate}/${zoneYear}`;
    this.setState({ displayDate: curretnzoneDate });
  };

  getUserSchedules = () => {
    let request: any = {};
    let storage = JSON.parse(localStorage.getItem("storage")!);
    request.tenantID = storage.tenantID === undefined ? 0 : storage.tenantID;
    request.Employee = storage.userName;
    request.hotelID = 0;
    User.GetSchedulePunchInUser(request)
      .then(async (result: any) => {
        let apiResult = result.result;
        let earlyPunch: any = 0;
        if (apiResult.length > 0 && apiResult !== null) {
          this.GetHotelCurrentDateTime(apiResult.hotelid);
        }
        let scheduleData: any = [];
        let selectedSechdule: any = [];
        apiResult.forEach((elem: any, idx: any) => {
          earlyPunch = elem.earlyPunch;
          let pushObject: any = {};
          pushObject = elem;
          pushObject.isChecked = idx === 0 ? true : false;
          pushObject.departmentName = elem.departmentName.replace(
            /\w\S*/g,
            function (txt: any) {
              return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            }
          );
          pushObject.positionName = elem.positionName.replace(
            /\w\S*/g,
            function (txt: any) {
              return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            }
          );
          pushObject.topStatus = `${pushObject.positionName}, ${pushObject.departmentName}`;
          let dayStatus: any = "Today";
          pushObject.bottomStatus = `${dayStatus} from ${this.formatDate(
            elem.schInDate.split("T")[1]
          )} - ${this.formatDate(elem.schOutDate.split("T")[1])}`;
          scheduleData.push(pushObject);
          if (idx === 0) {
            selectedSechdule.push(pushObject);
          }
          let { userHasNoSchedule } = this.state;
          scheduleData.length > 0
            ? (userHasNoSchedule = false)
            : (userHasNoSchedule = true);
          this.setState({ userHasNoSchedule });
        });
        this.setState({
          scheduleData: scheduleData,
          selectedSechdule: selectedSechdule,
          enterpriseEarlyPunch: earlyPunch,
          userPunchModal: true,
          showModalWait: false,
        });

        resolve();
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error, ${err}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "headerMain",
        });
        reject();
      });
  };

  scheduleCheck = (e: any, idx: any) => {
    let scheduleData: any = [...this.state.scheduleData];
    let selectedSechdule: any = [...this.state.selectedSechdule];
    let result = scheduleData[idx].defaultChecked === "true";
    if (result && e.target.checked) {
      for (let i = 0; i < scheduleData.length; i++) {
        if (i === idx) {
          scheduleData[i].isChecked = true;
        } else {
          scheduleData[i].isChecked = false;
        }
      }
      let checkedSchedule = scheduleData[idx];
      selectedSechdule.pop();
      selectedSechdule.push(checkedSchedule);
      this.setState({
        scheduleData: scheduleData,
        selectedSechdule: selectedSechdule,
        userHasNoSchedule: false,
      });
    } else {
      return false;
    }
  };

  getUserPunchInDetails = (type: number) => {
    this.setState({ showModalWait: true });
    let { punchType } = this.state;
    type === 1 ? (punchType = "Punch In") : (punchType = "Punch Out");
    this.setState({ punchType }, () => {
      this.getUserSchedules();
      this.getPayPeriod();
      this.setDisplayDate();
    });
  };

  showUserSchedule = (e: { target: { checked: any } }) => {
    let checked = e.target.checked;
    let selectedSechdule: any = [...this.state.selectedSechdule];
    if (selectedSechdule.length > 0 && selectedSechdule[0].status !== "P") {
      selectedSechdule[0].isChecked = false;
      this.setState({ selectedSechdule });
    }
    if (!checked) {
      this.getUserSchedules();
    }
    this.setState({ userHasNoSchedule: checked }, () => {
      this.resetUserSchedule();
    });
  };

  hideUserPunchModal = () => {
    this.setState(
      {
        userPunchModal: false,
        punchTime: "",
        punchType: "",
        userHasNoSchedule: false,
      },
      () => {
        this.resetUserSchedule();
      }
    );
  };

  hideUserSchedule = () => {
    this.setState({ userHasNoSchedule: false, punchTime: "" }, () => {
      this.resetUserSchedule();
      this.getUserSchedules();
    });
  };

  loadHotelsDetails() {
    Hotel.getUserHotelAccess("Labor Management")
      .then(async (result: IHotelResponseDto[] | null) => {
        if (result != null) {
          let _result = result.map((item: any, id: any) => {
            return {
              name: item.lettercode,
              id: item.hotelID,
              ehidName: item.ehidName,
              hotelID: item.hotelID,
              hotelName: item.hotelName,
              hotelType: item.hotelType,
              lettercode: item.lettercode,
              tenantID: item.tenantID,
              timeZone: item.timeZone,
            };
          });
          this.setState({ hidListCount: _result });
        }
        resolve();
      })
      .catch((err) => {
        Utils.toastError(`Server Error, ${err}`);
        reject();
      });
  }

  transferControl = (type: any, item: any) => {
    if (type === "headerEhid") {
      this.setState(
        () => ({
          departmentError: false,
          positionError: false,
          punchTimeError: false,
          departmentName: "Select",
          positionName: "Select",
          punchTime: "",
          headerEHID: item.name,
          hotelName: item.lettercode,
          hidValue: item.hotelID,
        }),
        () => {
          this.getScheduleForecast();
          this.getPostionDepartment();
          this.getPayPeriod();
        }
      );
    }
  };

  getScheduleForecast = () => {
    let isForecast = false;
    let forecastMessage = "";
    let currentDay: any = new Date();
    let currentdate = currentDay.getDate();
    let currentMonth = currentDay.getMonth() + 1;
    let currentYear = currentDay.getFullYear();
    let tempDate = currentMonth + "/" + currentdate + "/" + currentYear;
    let requestObject: any = {};
    requestObject.hotelId = Number(
      this.state.hidValue === "" ? 0 : this.state.hidValue
    );
    requestObject.date = tempDate;
    RoleSubPermissionSetting.CheckForecast(requestObject)
      .then(async (result: any | null) => {
        let apiResult = result.result[0];
        if (apiResult !== null) {
          if (result.success) {
            let apiMessage = apiResult.result.toString();
            if (apiMessage.toLowerCase() === "No".toLocaleLowerCase()) {
              isForecast = true;
              forecastMessage =
                "No forecast set for this week. You must first create a forecast before you can create a schedule.";
              Utils.toastError(forecastMessage, {
                // // position: toast.POSITION.BOTTOM_RIGHT,
                // // containerId: "headerMain",
              });
            } else if (
              apiMessage.toLowerCase() === "Incomplete".toLocaleLowerCase()
            ) {
              isForecast = true;
              forecastMessage =
                "No forecast set for this week. An error occurred during a previous forecast import attempt.";
              Utils.toastError(forecastMessage, {
                // // position: toast.POSITION.BOTTOM_RIGHT,
                // // containerId: "headerMain",
              });
            } else if (apiMessage.toLowerCase() === "Yes".toLocaleLowerCase()) {
              forecastMessage = "";
              isForecast = false;
            }
            this.setState({
              hasForecast: isForecast,
              forecastMessage: forecastMessage,
            });
          } else {
            Utils.toastError(`Error.`, {
              // // position: toast.POSITION.BOTTOM_RIGHT,
              // // containerId: "headerMain",
            });
          }
        }
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error, ${err}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "headerMain",
        });
        reject();
      });
  };

  formatDate(time: string) {
    const dataArray = time.split(":");
    let hh = Number(dataArray[0]);
    let m = Number(dataArray[1]);
    let dd = "AM";
    let h = hh;
    if (h >= 12) {
      h = hh - 12;
      dd = "PM";
    }
    if (h === 0) {
      h = 12;
    }
    let min = m < 10 ? "0" + m : m;
    let replacement = h + ":" + min;
    replacement += " " + dd;
    return replacement;
  }

  GetHotelCurrentDateTime = (hid: any) => {
    let request: any = {};
    request.HID = hid;
    User.GetHotelCurrentDateTime(request).then(async (result: any | null) => {
      if (result != null) {
        let data = result.result;
        let Hours = data.split("T")[1];
        let currentTime = this.formatDate(Hours);
        let HDate = data.split("T")[0];
        let day = HDate.split("-")[1];
        let month = HDate.split("-")[2];
        let year = HDate.split("-")[0];
        let calcdate = day + "/" + month + "/" + year;
        this.setState({
          hotelCurrentTime: currentTime,
          hotelCurrentDate: calcdate,
        });
      }
    });
  };

  getPostionDepartment() {
    let localStorageUser = JSON.parse(localStorage.getItem("storage")!);
    let LoginUserName = localStorageUser.userName;
    laborDeptPosition
      .getlaborPositionDeptByemployee(this.state.hidValue, LoginUserName)
      .then(async (result: any[] | null) => {
        if (result != null) {
          this.setState({ departmentlist: result, alldepartmentlist: result });
        } else {
          this.setState({ departmentlist: [], alldepartmentlist: [] });
        }
        resolve();
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error, ${err}`);
        reject();
      });
  }

  departmentSplitChange(event: any, item: any, rowIndex: any) {
    (this.state.departmentName !== "" ||
      this.state.departmentName !== "Select") &&
      this.setState({
        departmentNameID: item.departmentID,
        departmentName: item.departmentName,
        allpositionlist: item.laborPositionlist,
        positionName: "Select",
        departmentError: false,
        SplitShift: { department: item.laborPositionlist },
      });
  }

  positionSplitChange(event: any, item: any, rowIndex: any) {
    (this.state.positionName !== "" || this.state.positionName !== "Select") &&
      this.setState({
        positionName: item.positionName,
        positionNameID: item.positionID,
        positionError: false,
      });
  }

  editPunchTime = (e: { target: { value: any } }) => {
    let { value } = e.target;
    value !== "" &&
      this.setState(
        {
          punchTime: value,
          hotelCurrentTime: "",
          hotelCurrentDate: "",
          punchTimeError: false,
        },
        () => {
          this.GetHotelCurrentDateTime(this.state.hidValue);
        }
      );
  };

  getPayPeriod() {
    let hotelID = this.state.hidValue === "" ? 0 : this.state.hidValue;
    let currentDay: any = new Date();
    if (JSON.parse(localStorage.storage).currentUtcTime !== "") {
      currentDay = new Date(JSON.parse(localStorage.storage).currentUtcTime);
    }
    laborPerformance
      .getPayPeriodDates(hotelID, currentDay, "Weekly")
      .then(async (result: any[] | null) => {
        if (result != null) {
          this.setState({
            navigationCalenderDates: [result[0].startdate, result[0].enddate],
          });
        }
      });
  }

  submitUserPunch = (type: string) => {
    let {
      userHasNoSchedule,
      positionName,
      departmentName,
      punchTime,
      departmentNameID,
      positionNameID,
      hotelCurrentTime,
      hotelCurrentDate,
      scheduleData,
      selectedSechdule,
    } = this.state;
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let setUserSchedule: any = {};
    this.GetHotelCurrentDateTime(this.state.hidValue);
    if (userHasNoSchedule) {
      if (this.state.hidValue === "Select") {
        Utils.toastError(`Please Select EHID.`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "headerMain",
        });
        return;
      }
      if (this.state.hasForecast) {
        Utils.toastError(this.state.forecastMessage, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "headerMain",
        });
        return;
      }
      if (departmentName === "" || departmentName === "Select") {
        this.setState({ departmentError: true });
        if (positionName === "" || positionName === "Select") {
          this.setState({ positionError: true });
          if (punchTime === "") {
            this.setState({ punchTimeError: true });
          }
        }
      }
      if (positionName === "" || positionName === "Select") {
        this.setState({ positionError: true });
        if (punchTime === "") {
          this.setState({ punchTimeError: true });
        }
      }
      if (punchTime === "") {
        this.setState({ punchTimeError: true });
      }
      if (departmentName === "" || departmentName === "Select") {
        Utils.toastError(`Please select Department name.`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "headerMain",
        });
        return;
      } else if (positionName === "" || positionName === "Select") {
        Utils.toastError(`Please select Position name.`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "headerMain",
        });
        return;
      } else if (punchTime === "") {
        Utils.toastError(`Please enter End time.`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "headerMain",
        });
        return;
      }
      setUserSchedule.departmentid = departmentNameID;
      setUserSchedule.positionid = positionNameID;
      setUserSchedule.punchTime = this.formatDate(punchTime);
      setUserSchedule.currentDate = hotelCurrentDate;
      setUserSchedule.currentTime = hotelCurrentTime;
      setUserSchedule.userName = storage.userName;
      setUserSchedule.tenantID = storage.tenantID;
    } else {
      if (scheduleData.length <= 0) {
        Utils.toastError("No Scheduled Shift", {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "headerMain",
        });
        if (
          typeof selectedSechdule !== "undefined" &&
          selectedSechdule.length > 0
        ) {
          if (selectedSechdule[0].status === "P") {
            return;
          }
        }
        return;
      }
    }
    if (type === "Punch In") {
      if (userHasNoSchedule) {
        this.SaveUserLaborScheduleDetails(type, setUserSchedule);
      } else {
        this.punchInCall();
      }
    } else {
      if (userHasNoSchedule) {
        this.SaveUserLaborScheduleDetails(type, setUserSchedule);
      } else {
        this.punchOutCall();
      }
    }
  };

  SaveUserLaborScheduleDetails = (
    type: string,
    setUserSchedule: {
      currentDate: string;
      departmentid: number;
      userName: string;
      currentTime: string;
      punchTime: string;
      positionid: number;
      tenantID: number;
    }
  ) => {
    let requestItem = {} as ILaborSaveScheduleDetails;
    requestItem.date = setUserSchedule.currentDate;
    requestItem.departmentID = setUserSchedule.departmentid;
    requestItem.editDate = setUserSchedule.currentDate;
    requestItem.editEmployee = "";
    requestItem.employeeName = setUserSchedule.userName;
    requestItem.entryType = "webTimeClock";
    requestItem.hotelID = this.state.hidValue;
    requestItem.inDate = setUserSchedule.currentDate;
    requestItem.inTime = setUserSchedule.currentTime;
    requestItem.isAssignedShift = "Yes";
    requestItem.isPublishShift = "No";
    requestItem.notes = "";
    requestItem.offRepeat = "No";
    requestItem.outDate = setUserSchedule.currentDate;
    requestItem.outTime = setUserSchedule.punchTime;
    requestItem.positionID = setUserSchedule.positionid;
    requestItem.ptoType = -1;
    requestItem.scheduleId = 0;
    requestItem.tenantID = setUserSchedule.tenantID;
    requestItem.weekStartDate = this.state.navigationCalenderDates[0];
    requestItem.weekEndDate = this.state.navigationCalenderDates[1];
    requestItem.EarlyPunch = "No";
    requestItem.PageSource = "ScheduleNav";
    let requestArray: any = [];
    requestArray.push(requestItem);
    this.setState({ punchButtonSpinner: true });
    User.SaveUserLaborScheduleDetails(requestArray)
      .then(async (result: any | null) => {
        if (result != null && result.saveStatus === "Success") {
          this.getUserSchedules();
          this.resetUserSchedule();
          toast.success(result.message, {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "headerMain",
          });
          setTimeout(() => {
            if (type === "Punch In") {
              this.punchInCall();
            } else {
              this.punchOutCall();
            }
          }, 2000);
          this.setState({ userHasNoSchedule: false });
        } else {
          this.setState({ punchButtonSpinner: false });
          Utils.toastError(result.message, {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "headerMain",
          });
        }
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error, ${err}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "headerMain",
        });
        this.setState({ punchButtonSpinner: false });
        reject();
      });
  };

  punchInCall = () => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    this.setState({ punchButtonSpinner: true });
    let selectedSechdule = this.state.selectedSechdule[0];
    let todayDate: any = new Date();
    let currentdate = todayDate.getDate();
    let currentMonth = todayDate.getMonth() + 1;
    let currentYear = todayDate.getFullYear();
    let tempDate = currentMonth + "/" + currentdate + "/" + currentYear;
    let request = {} as any;
    request.UserName = storage.userName;
    request.PageSource = "NavigationPunchIn";
    request.CallingPage = "userProfile";
    request.IsPunchCall = "Yes";
    request.date =
      this.state.hotelCurrentDate === ""
        ? tempDate
        : this.state.hotelCurrentDate;
    if (selectedSechdule !== null && typeof selectedSechdule !== "undefined") {
      request.Hotelid = Number(selectedSechdule.hotelid);
      request.ScheduleNo = Number(selectedSechdule.scheduleNo);
      if (selectedSechdule.status === "P") {
        toast.success("You have already punched in to a shift.", {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "headerMain",
        });
        setTimeout(() => {
          this.setState({ punchButtonSpinner: false, userPunchModal: false });
        }, 1500);
        return;
      } else {
        User.EmployeePunchIn(request)
          .then(async (result: any) => {
            let apiResult = result.result.result;
            if (apiResult.status === "Success") {
              this.setState(
                { userPunchModal: false, punchButtonSpinner: false },
                () => {
                  this.getPunchPermission();
                }
              );
              toast.success("You have been successfully punched in.", {
                // // position: toast.POSITION.BOTTOM_RIGHT,
                // // containerId: "headerMain",
              });
            } else {
              Utils.toastError(apiResult.status, {
                // // position: toast.POSITION.BOTTOM_RIGHT,
                // // containerId: "headerMain",
              });
              this.setState({ punchButtonSpinner: false });
            }
            resolve();
          })
          .catch((err: any) => {
            this.setState({ punchButtonSpinner: false });
            Utils.toastError(`Server Error, ${err}`, {
              // // position: toast.POSITION.BOTTOM_RIGHT,
              // // containerId: "headerMain",
            });
            reject();
          });
      }
    } else {
      this.setState({ punchButtonSpinner: false });
      Utils.toastError(`No Scheduled Shift`, {
        // // position: toast.POSITION.BOTTOM_RIGHT,
        // // containerId: "headerMain",
      });
    }
  };

  punchOutCall = () => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    this.setState({ punchButtonSpinner: true });
    let selectedSechdule = this.state.selectedSechdule[0];
    let todayDate: any = new Date();
    let currentdate = todayDate.getDate();
    let currentMonth = todayDate.getMonth() + 1;
    let currentYear = todayDate.getFullYear();
    let tempDate = currentMonth + "/" + currentdate + "/" + currentYear;
    let request = {} as any;
    request.PageSource = "NavigationPunchOut";
    request.UserName = storage.userName;
    request.CallingPage = "userProfile";
    request.IsPunchCall = "No";
    request.date =
      this.state.hotelCurrentDate === ""
        ? tempDate
        : this.state.hotelCurrentDate;
    if (selectedSechdule !== null && typeof selectedSechdule !== "undefined") {
      request.Hotelid = Number(selectedSechdule.hotelid);
      request.ScheduleNo = Number(selectedSechdule.mainUniqueno);
      User.EmployeePunchOut(request)
        .then(async (result: any) => {
          let apiResult = result.result.result;
          if (apiResult.status === "Success") {
            this.setState(
              { userPunchModal: false, punchButtonSpinner: false },
              () => {
                this.getPunchPermission();
              }
            );
            toast.success("You have been successfully punched Out.", {
              // // position: toast.POSITION.BOTTOM_RIGHT,
              // // containerId: "headerMain",
            });
          } else {
            Utils.toastError(apiResult.status, {
              // // position: toast.POSITION.BOTTOM_RIGHT,
              // // containerId: "headerMain",
            });
            this.setState({ punchButtonSpinner: false });
          }
          resolve();
        })
        .catch((err: any) => {
          this.setState({ punchButtonSpinner: false });
          Utils.toastError(`Server Error, ${err}`, {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "headerMain",
          });
          reject();
        });
    } else {
      this.setState({ punchButtonSpinner: false });
      Utils.toastError(`No Scheduled Shift`, {
        // // position: toast.POSITION.BOTTOM_RIGHT,
        // // containerId: "headerMain",
      });
    }
  };

  // Mobile Navigation Functions Starts
  toggleMobileNav = (e: any) => {
    e.preventDefault();
    this.setState((prevState: any) => ({
      mobileNavOpen: !prevState.mobileNavOpen,
    }));
  };

  mobileMainMenuClick = (e: any, data: any) => {
    e.stopPropagation();
    e.preventDefault();

    if (data.subMenuMaster.length > 0) {
      let mobileMenuTop = [...this.state.mobileMenuTop];
      let mobileMenuBottom = [...this.state.mobileMenuBottom];

      mobileMenuTop = mobileMenuTop.map((d) => {
        if (d.menuID === data.menuID) {
          return {
            ...d,
            openAction: !d.openAction,
          };
        } else {
          return {
            ...d,
            openAction: false,
          };
        }
      });

      mobileMenuBottom = mobileMenuBottom.map((d) => {
        if (d.menuID === data.menuID) {
          return {
            ...d,
            openAction: !d.openAction,
          };
        } else {
          return {
            ...d,
            openAction: false,
          };
        }
      });

      this.setState((curr: any) => ({
        ...curr,
        mobileMenuTop,
        mobileMenuBottom,
      }));
    } else if (
      data.name === "Accounting" ||
      data.name === "Procurment" ||
      data.name === "Procurement" ||
      data.name === "Users"
    ) {
      this.redirectOldSite(data.name);
    } else if (
      data.name === "Sales"
    ) {
      if (this.state.Sales2Permission !== "Yes") {
        this.redirectOldSite(data.name);
      }
    } else if (data.name === "Home") {
      let navMenu: any = {
        name: "",
      };
      this.changeSelectedModule(data, navMenu);
      window.location.href = "/home";
    } else if (data.name === "Communications") {
      let navMenu: any = {
        name: "",
      };
      this.changeSelectedModule(data, navMenu);
      window.location.href = "/CommunicationsInbox";
    } else if (data.name === "Help") {

      FreshDeskChat.redirectToFreshDeskURL("https://support.inn-flow.net/support/home")

    } else {
      return false;
    }
  };

  changeSelectedModule = (data: any, navMenu: any) => {
    let selectedMobileMainMenu: string = data.name;
    let selectedMobileSubMenu: string = navMenu.name;

    if (selectedMobileMainMenu === "Accounting" && selectedMobileSubMenu === "Overview") {
      selectedMobileSubMenu = selectedMobileMainMenu + "-" + selectedMobileSubMenu;
    }
    else if (selectedMobileMainMenu === "Labor" && selectedMobileSubMenu === "Overview") {
      selectedMobileSubMenu = selectedMobileMainMenu + "-" + selectedMobileSubMenu;
    }
    else if (selectedMobileMainMenu === "Facilities" && selectedMobileSubMenu === "Overview") {
      selectedMobileSubMenu = selectedMobileMainMenu + "-" + selectedMobileSubMenu;
    }
    else if (selectedMobileMainMenu === "Dashboard" && selectedMobileSubMenu === "Daily Flash") {
      selectedMobileSubMenu = selectedMobileMainMenu + "-" + selectedMobileSubMenu;
    }
    else if (selectedMobileMainMenu === "Facilities" && selectedMobileSubMenu === "Daily Tasks") {
      selectedMobileSubMenu = selectedMobileMainMenu + "-" + selectedMobileSubMenu;
    }



    let headerNavLinks: IheaderNavLinks = {};
    headerNavLinks.mobileMainMenu = selectedMobileSubMenu;
    headerNavLinks.mobileSubMenu = navMenu.name;

    localStorage.setItem("headerNavLinks", JSON.stringify(headerNavLinks));
    this.setState({
      selectedMobileMainMenu,
      selectedMobileSubMenu,
      mobileNavOpen: false,
    });
  };

  mobileProfileClick = (value: boolean) => {
    let mobileTopProfile = { ...this.state.mobileTopProfile };

    mobileTopProfile.openAction = value;

    this.setState({ mobileTopProfile });
  };

  // Mobile Navigation Functions Ends

  redirectOldSite = (event) => {
   
    User.GenerateToken()
      .then(async (result: any | null) => {
        if (result) {
          let mergeURL = result.result.mergeURL;
          let token = result.result.token;
          let tokenID = result.result.tokenID;
          
          const serverTimeResponse = await User.GetServerTime();
          let currentTimeUTC = serverTimeResponse ? serverTimeResponse : null;

          if (!currentTimeUTC) {
            throw new Error("Failed to retrieve server time from API.");
          }
          console.log(this.state.userName)
          console.log(mergeURL +
            // "http://localhost:50491/login.aspx" +
            "?logInUser=" +
           Utils.encryptString(`${this.state.userName}|${currentTimeUTC}`) +
            "&tokenID=" +
            btoa(tokenID) +
            "&module=" +
            btoa(event) +
            "&logInType=2")
          window.open(
            mergeURL +
            // "http://localhost:50491/login.aspx" +
            "?logInUser=" +
            Utils.encryptString(`${this.state.userName}|${currentTimeUTC}`) +
            "&tokenID=" +
            btoa(tokenID) +
            "&module=" +
            btoa(event) +
            "&logInType=2"
          );
        }
        resolve();
      })
      .catch((err) => {
        Utils.toastError(`Server Error, ${err}`);
        reject();
      });
  };

  switchToDefaultTheme = () => {
    localStorage.setItem("useDefaultTheme", "true");
    this.setState({ theme: "" });
  }

  hideUserProfileSlideOutSlideOut() {

    window.location.replace("/home");
    this.setState({ isOpenUserProfileSlideOut: false });
  }

  updatePipStatus = () => {
    let pipdata: any[] = JSON.parse(localStorage.getItem("pip_data")!);
    pipdata.map((item) => {
      let controlId = item.module;
      let pipControl = (document.getElementById("lbl-" + controlId)!) as any;
      if (pipControl != null) {
        pipControl.innerHTML = item.count;
      }

    })
  }

  render() {
    let { punchType, positionError, departmentError, punchTimeError, hasLaborPermission } =
      this.state;
    let showMenu = this.state.showMenu;
    let showMenuOver = this.state.showMenuOver;
    let useDefaultTheme = localStorage.getItem("useDefaultTheme");

    let showSubMenu =
      localStorage.getItem("subMenu") === (null || "")
        ? this.state.subActiveLink
        : localStorage.getItem("subMenu");
    let activeClickSubMenu = this.state.activeClickSubMenu;
    let prevClickSubMenu = this.state.prevClickSubMenu;
    let opened = this.state.opened;

    const popUpState = localStorage?.getItem("PopUpState") ? JSON.parse(localStorage?.getItem("PopUpState") || "") : {};

    const handleSelect = (eventKey: any) => {
      this.setState({ subClickOpened: false });
      localStorage.setItem("lastMenu", this.state.lastActiveLink);
      this.state.menuItems.map((menuItem: any) => {
        if (menuItem.name === eventKey) {
          if (menuItem.subMenuMaster.length > 0) {
            this.setState({ activeClickSubMenu: eventKey });
            this.setState({ prevClickSubMenu: eventKey });
            this.setState({ showMenu: eventKey });
            setTimeout(() => {
              this.setState({ subClickOpened: true });
            }, 10);
            // return false;
          } else {
            this.setState({ activeClickSubMenu: "" });
            this.setState({ prevClickSubMenu: "" });
            this.setState({ subClickOpened: false });
          }
        }
        // return false;
      });
      this.setState({
        activeLink: eventKey,
        lastActiveLink: eventKey,
        defaultLink: eventKey,
        subActiveLink: "",
      });
      localStorage.setItem("mainMenu", eventKey);

      if (
        //eventKey === "Accounting" ||
        eventKey === "Procurment" ||
        eventKey === "Procurement" ||
        eventKey === "Users"
      ) {
        this.redirectOldSite(eventKey);
      }
      if (eventKey === "Sales") {
        if (this.state.Sales2Permission !== "Yes") {
          this.redirectOldSite(eventKey);
        }
      }
      if (eventKey === "Help") {
        FreshDeskChat.redirectToFreshDeskURL("https://support.inn-flow.net/support/home")
      }
      if (eventKey === "Home") {
        localStorage.setItem("subMenu", "");
      }

    };

    const checkpopupblocked = () => {

      const popupBlocked = window.open("https://www.google.com", "_blank");
      if (popupBlocked === null || typeof popupBlocked === "undefined") {
        return true;
      } else {
        popupBlocked.close();
        return false;
      }
    }

    const handleAccountingRedirection = (pagePath) => {
      this.setState({ isRedirectOldSite: true });
      User.GenerateToken()
        .then(async (result: any | null) => {
          if (result) {


            let mergeURL = result.result.mergeURL;
            let token = result.result.token;
            let tokenID = result.result.tokenID;
            let pageURL,
              queryStringKey,
              queryStringValue,
              functionName = "";

            pageURL = pagePath.split("?")[0];
            queryStringKey = pagePath.split("?")[1].split("=")[0];
            queryStringValue = pagePath.split("?")[1].split("=")[1];
            if (pagePath.includes("*")) {
              functionName = pagePath.split("*")[1];
            } else {
              functionName = "None";
            }
            let hotelID =
              JSON.parse(localStorage.getItem("hotelSelected")!) !== null
                ? JSON.parse(localStorage.getItem("hotelSelected")!).hotelID
                : "Select";
            let hCode =
              JSON.parse(localStorage.getItem("hotelSelected")!) !== null
                ? JSON.parse(localStorage.getItem("hotelSelected")!).lettercode
                : "Select";
            let roleID = JSON.parse(localStorage.getItem("storage")!)?.rolId;
            const serverTimeResponse = await User.GetServerTime();
            let currentTimeUTC = serverTimeResponse ? serverTimeResponse : null;

            if (!currentTimeUTC) {
              throw new Error("Failed to retrieve server time from API.");
            }
            console.log( mergeURL +
              //  "http://localhost:50491/login.aspx" +
              "?logInUser=" +
              Utils.encryptString(`${userName}|${currentTimeUTC}`) +
              "&tokenID=" +
              btoa(tokenID) +
              "&module=" +
              btoa("Accounting") +
              "&logInType=2&PageUrl=" +
              pageURL +
              "&Key=" +
              btoa(queryStringKey) +
              "&Value=" +
              btoa(queryStringValue) +
              "&function=" +
              btoa(functionName) +
              "&hid=" +
              hotelID +
              "&HCode=" +
              hCode)
            window.open(
              mergeURL +
              //  "http://localhost:50491/login.aspx" +
              "?logInUser=" +
              Utils.encryptString(`${userName}|${currentTimeUTC}`) +
              "&tokenID=" +
              btoa(tokenID) +
              "&module=" +
              btoa("Accounting") +
              "&logInType=2&PageUrl=" +
              pageURL +
              "&Key=" +
              btoa(queryStringKey) +
              "&Value=" +
              btoa(queryStringValue) +
              "&function=" +
              btoa(functionName) +
              "&hid=" +
              hotelID +
              "&HCode=" +
              hCode
            );
          }
          resolve();
        })
        .catch((error) => {
          reject();
        });
    };

    const handleSubSelect = (eventKey: any, mainMenu: any) => {

      if (eventKey === 'Sales 1.0 (Legacy)') {
        this.redirectOldSite(mainMenu);
        return false;
      }
      localStorage.setItem("mainMenu", mainMenu);
      this.setState({
        activeClickSubMenu: mainMenu,
        prevClickSubMenu: mainMenu,
      });
      this.setState({ subClickOpened: true });
      this.setState({ subActiveLink: eventKey });
      localStorage.setItem("subMenu", eventKey);
      if (eventKey === "Logout") {
        this.logout();
      }
      if (mainMenu === "Profile") {
        this.setState({ showProfileMenu: !this.state.showProfileMenu });
      }

    };




    const getFirstItemSelected = (menu: any) => {
      //debugger
      let returnURL = "/";
      if (menu === "Home") {
        returnURL = "/home";
      }
      // if (menu === "Dashboard") {
      //   returnURL = "/dashboard";
      // }
      if (menu === "Labor") {
        returnURL = "/labor-management/approval";
      }
      if (menu === "Accounting") {
        returnURL = "/Account-management/Overview";
      }

      if (menu === "Settings" || menu === "Administration") {
        returnURL = "/labor-settings/role-permission";
      }
      return returnURL;
    };

    const openSubMenu = (event: any) => {
      this.setState({ active: true });
    };

    const closeSubMenu = (event) => {
      this.setState({ active: false });
      //  if (event === "home") {
      //   window.location.href = "/home";
      // }
    };

    const openUserProfileSlideOut = () => {
      this.setState({ isOpenUserProfileSlideOut: true });
    };

    const closeUserProfileSlideOut = () => {
      this.setState({ isOpenUserProfileSlideOut: false });
    };



    const hoverMenu = (menuName: any) => {
      //this.setState({ hoverMenu: false });
      this.setState({ showMenuOver: menuName });
      this.setState({ iconHoverChange: "" });
      setTimeout(() => {
        if (this.state.menuItems.length > 0) {
          this.state.menuItems.map((menuItem: any) => {
            if (menuItem.name === menuName) {
              if (menuItem.subMenuMaster.length > 0) {
                this.setState({ isToggle: true, hoverMenu: true });
                this.setState({ iconHoverChange: menuName });
                // return false;
              } else {
                this.setState({ isToggle: false, hoverMenu: false });
                this.setState({ iconHoverChange: "" });
              }
            }
            // return false;
          });
        }
      }, this.state.hoverDelay);
      if (this.state.opened === true) {
        this.setState({ activeClickSubMenu: prevClickSubMenu });
      }
    };

    const hoverOutMenu = () => {
    };

    const hoverSubMenu = (menuName: any) => {
      if (this.state.menuItems.length > 0) {
        this.state.menuItems.map((menuItem: any) => {
          if (menuItem.name === menuName) {
            if (menuItem.subMenuMaster.length > 0) {
              this.setState({ isToggle: true });
              // return false;
            } else {
              this.setState({ isToggle: false });
            }
          }
          // return false;
        });
      }
      this.setState({ hoverMenu: true, showMenuOver: menuName });
    };

    const hoverOut = () => {

      if (!this.state.opened) {
        this.setState({ hoverMenu: false, specialCondition: false });
        this.getNavItems();
      } else {
        let mainMenu = localStorage.getItem("mainMenu");
        if (mainMenu === "Home" || mainMenu === "Procurment" || mainMenu === "Procurement" || mainMenu === "Users" || mainMenu === "Help" || mainMenu === "Sales") {
          this.setState({ hoverMenu: false, specialCondition: false });
          this.getNavItems();
        } else if (this.state.isRedirectOldSite) {
          this.setState({ hoverMenu: false, specialCondition: false, isRedirectOldSite: false });
          this.getNavItems();
        }
      }
    };

    const userName = this.state.userName;
    const roleName = this.state.roleName;
    const setActiveMenu = (menu: any, subMenu: any) => {

      this.setState({
        activeLink: menu,
        lastActiveLink: menu,
        showMenu: menu,
        defaultLink: menu,
        subActiveLink: subMenu,
      });
    };

    const toogleProfileState = () => {
      this.setState({ showProfileMenu: !this.state.showProfileMenu });
    };
    let allowPunch = this.state.allowPunch;
    let allowPunchIn = this.state.allowPunchIn;
    let profileMenu: any[] = [];
    let subitems = this.state.menuItems?.filter(
      (x: { name: string }) => x.name === "Profile"
    );
    if (subitems?.length > 0) {
      let generalItem = subitems[0].subMenuMaster?.filter(
        (x: { name: string }) => x.name === "General"
      );
      if (generalItem?.length > 0) {
        profileMenu = generalItem[0].menuSubItemDetail;
        profileMenu = profileMenu?.map((item: any) => {
          if (
            this.state.menuItems.filter(
              (p: { name: string }) => p.name === "Labor"
            )?.length > 0 ||
            item?.itemName === "Change Password" ||
            item?.itemName === "Logout" ||
            item?.itemName === "My Profile" ||
            item?.itemName === "Alert Preferences"
          ) {
            item.hasPermission = true;
          } else {
            item.hasPermission = false;
          }
          return item;
        });
      }
    }

    let profilemenuLink = this.state.menuItemBottom?.filter(
      (x: { name: string }) => x.name === "Profile"
    );

    return (
      <>

        {this.state.isOpenUserProfileSlideOut &&
          <>
            <ProfileUserDetailSlideOut
              imagePath={profilemenuLink?.[0].iConName}
              closeSlideOut={this.hideUserProfileSlideOutSlideOut.bind(this)}

            ></ProfileUserDetailSlideOut>

          </>
        }

        {/* Desktop view navigation starts */}
        {this.hideMenuByUrl() && (
          <div className={"nav-area " + this.state.theme}>
            <Button id="btnpip" className="without-border margin-right-15" variant="primary" style={{ display: "none" }}
              onClick={this.updatePipStatus}
            >
            </Button>
            <div className={this.state.opened ? "hide-area opened" : "hide-area"} onMouseLeave={() => hoverOut()}>
              <div
                className={`main-link ${this.state.opened ? "opened" : ""} ${this.state.subClickOpened ? "sub-menu-opened" : ""}`}
              >
                <Navbar className="d-flex flex-column bg-dark">
                  <Navbar.Brand>
                    <div className="d-flex justify-content-start align-items-center">
                      <div className="icon-width">
                        <svg width="26" height="40" viewBox="0 0 26 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M23.0916 5.46606H9.8288V1.05117C9.8288 0.63654 9.96746 0.338709 10.2428 0.163515C10.4108 0.0545049 10.6276 0 10.8561 0C11.0631 0 11.2799 0.0467185 11.4771 0.136263L23.0916 5.46606Z" fill="white" />
                          <path d="M22.8591 6.24512L11.0064 11.6859C10.5455 11.8961 10.147 12.1278 9.8287 12.4275C9.68026 12.5638 9.54941 12.7156 9.4381 12.885C9.26038 13.1536 9.12953 13.467 9.0475 13.8466C8.98305 14.1405 8.94985 14.4734 8.94985 14.855V33.8421H3.49511C2.35065 33.8421 1.42102 32.9136 1.42102 31.7749V8.31242C1.42102 7.1717 2.35065 6.24512 3.49511 6.24512H22.8591Z" fill="white" />
                          <path d="M24.3841 6.61621V33.9193L24.2865 33.964L23.8198 34.1781L23.7065 34.2308L22.853 34.622L11.7912 39.6988C11.1135 40.016 10.551 40.0861 10.2444 39.8915C9.96901 39.7183 9.928 39.3366 9.928 39.1265V14.8542C9.928 13.4819 10.387 13.04 11.4162 12.5689L24.3549 6.62984L24.3861 6.61621H24.3841Z" fill="white" />
                        </svg>
                      </div>
                      <div className="logo-txt">
                        <svg width="112" height="26" viewBox="0 0 112 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M54.851 7.4335V10.7803H44.1539V7.4335H54.851ZM46.7932 25.2828V5.82988C46.7932 4.6058 47.0422 3.59094 47.5405 2.78525C48.0466 1.97955 48.7278 1.37529 49.5842 0.972439C50.4405 0.56959 51.3944 0.368164 52.4453 0.368164C53.1849 0.368164 53.8428 0.426264 54.4189 0.542477C55.0029 0.658677 55.4388 0.763264 55.7269 0.856227L54.8627 4.20296C54.6837 4.14873 54.4578 4.0945 54.1854 4.04028C53.9129 3.97829 53.6132 3.9473 53.2862 3.9473C52.5076 3.9473 51.9588 4.13324 51.6396 4.5051C51.3281 4.8692 51.1724 5.39213 51.1724 6.07388V25.2828H46.7932Z" fill="white" />
                          <path d="M62.5003 1.48373V25.2827H58.1209V1.48373H62.5003Z" fill="white" />
                          <path d="M74.6427 25.6314C72.8753 25.6314 71.3455 25.2479 70.0532 24.4809C68.7608 23.7139 67.7604 22.641 67.0519 21.262C66.3511 19.8752 66.0008 18.2639 66.0008 16.4279C66.0008 14.584 66.3511 12.9687 67.0519 11.582C67.7604 10.1952 68.7608 9.11849 70.0532 8.35149C71.3455 7.58449 72.8753 7.20111 74.6427 7.20111C76.4021 7.20111 77.9281 7.58449 79.2205 8.35149C80.5129 9.11849 81.5133 10.1952 82.2218 11.582C82.9303 12.9687 83.2846 14.584 83.2846 16.4279C83.2846 18.2639 82.9303 19.8752 82.2218 21.262C81.5133 22.641 80.5129 23.7139 79.2205 24.4809C77.9281 25.2479 76.4021 25.6314 74.6427 25.6314ZM74.6544 22.1684C75.5886 22.1684 76.3633 21.9127 76.9783 21.4014C77.6012 20.8901 78.0643 20.2006 78.3679 19.333C78.6793 18.4652 78.8351 17.4891 78.8351 16.4046C78.8351 15.3277 78.6793 14.3555 78.3679 13.4877C78.0643 12.6124 77.6012 11.919 76.9783 11.4077C76.3633 10.8886 75.5886 10.6291 74.6544 10.6291C73.7123 10.6291 72.926 10.8886 72.2953 11.4077C71.6725 11.919 71.2053 12.6124 70.8939 13.4877C70.5903 14.3555 70.4384 15.3277 70.4384 16.4046C70.4384 17.4891 70.5903 18.4652 70.8939 19.333C71.2053 20.2006 71.6725 20.8901 72.2953 21.4014C72.926 21.9127 73.7123 22.1684 74.6544 22.1684Z" fill="white" />
                          <path d="M89.9851 25.2828L84.9515 7.4335H89.4123L92.4837 19.8675H92.647L95.8001 7.4335H100.203L103.38 19.8094H103.543L106.579 7.4335H111.029L105.995 25.2828H101.418L98.1127 13.3949H97.8678L94.5627 25.2828H89.9851Z" fill="white" />
                          <path d="M1.02618 25.2827V7.43349H3.24512V25.2827H1.02618ZM2.15901 4.38888C1.70741 4.38888 1.31824 4.23781 0.991251 3.93568C0.672051 3.62579 0.51239 3.25394 0.51239 2.8201C0.51239 2.39401 0.672051 2.0299 0.991251 1.72776C1.31824 1.41788 1.70741 1.26294 2.15901 1.26294C2.60283 1.26294 2.98421 1.41788 3.30354 1.72776C3.63053 2.0299 3.79396 2.39401 3.79396 2.8201C3.79396 3.25394 3.63053 3.62579 3.30354 3.93568C2.98421 4.23781 2.60283 4.38888 2.15901 4.38888Z" fill="white" />
                          <path d="M10.5684 14.2082V25.2827H8.34955V7.43349H10.4983V10.234H10.6968C11.1172 9.31986 11.7673 8.58774 12.6471 8.03774C13.5346 7.47999 14.6401 7.20111 15.9636 7.20111C17.1782 7.20111 18.241 7.45286 19.1519 7.95636C20.0627 8.45224 20.7712 9.18824 21.2772 10.1644C21.7911 11.1327 22.048 12.3335 22.048 13.7667V25.2827H19.8292V13.9061C19.8292 12.4575 19.4282 11.3147 18.6263 10.4781C17.8244 9.63361 16.7422 9.21136 15.3797 9.21136C14.4533 9.21136 13.6281 9.41286 12.904 9.81574C12.1799 10.2107 11.6077 10.7841 11.1873 11.5355C10.7746 12.2792 10.5684 13.1701 10.5684 14.2082Z" fill="white" />
                          <path d="M29.3479 14.2082V25.2827H27.1291V7.43349H29.2778V10.234H29.4764C29.8969 9.31986 30.547 8.58774 31.4267 8.03774C32.3142 7.47999 33.4198 7.20111 34.7433 7.20111C35.9578 7.20111 37.0205 7.45286 37.9314 7.95636C38.8423 8.45224 39.5508 9.18824 40.0569 10.1644C40.5707 11.1327 40.8276 12.3335 40.8276 13.7667V25.2827H38.6087V13.9061C38.6087 12.4575 38.2078 11.3147 37.4059 10.4781C36.604 9.63361 35.5218 9.21136 34.1594 9.21136C33.2328 9.21136 32.4076 9.41286 31.6836 9.81574C30.9595 10.2107 30.3873 10.7841 29.9668 11.5355C29.5543 12.2792 29.3479 13.1701 29.3479 14.2082Z" fill="white" />
                        </svg>
                      </div>
                    </div>
                  </Navbar.Brand>
                  <Nav
                    className="flex-column"
                    activeKey={this.state.activeLink}
                    onSelect={handleSelect}
                  >
                    {this.state.menuItemTop.length > 0 &&
                      this.state.menuItemTop.map((menuItem: any, i: any) => (
                        <Nav.Link
                          key={i}
                          id={"defaultSet-" + menuItem.name}
                          className={
                            menuItem.isAdditionalSpace === "Yes"
                              ? "margin-bottom"
                              : ""
                          }
                          eventKey={menuItem.name}
                          as={Link}
                          to={menuItem.name === "Home" ? "/Home" : menuItem.name === "Communications" ? "/CommunicationsInbox": menuItem.name === "Reports" ? "/Reports" : menuItem.name === "LaborPromotion" ? "/labor"  :() => this.getPreviousUrl()}
                          onClick={
                            menuItem.name === "Home" ? (e) => closeSubMenu("home") : openSubMenu
                          }
                        >
                          <>
                            <div className="d-flex justify-content-start align-items-center"
                            onClick={() => menuItem.name === "LaborPromotion" ? this.setState({ showPip: false }) : false }
                            >
                              {opened === false && (
                                <div
                                  className="icon-width d-flex align-items-center justify-content-center"
                                  onClick={() => hoverMenu(menuItem.name)}
                                >
                                  <OverlayTrigger
                                    placement="right"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={
                                      <Tooltip id={"letf-nav1"}>
                                        {menuItem.name}
                                      </Tooltip>
                                    }
                                  >
                                    <img
                                      style = {{ width: menuItem.name === "Communications" ? "16px" : "", height: menuItem.name === "Communications" ? "16px" : "" }}
                                      src={menuItem.iConName}
                                      alt={menuItem.name}
                                    />
                                  </OverlayTrigger>
                                </div>
                              )}
                              {opened === true && prevClickSubMenu === "" && (
                                <div
                                  className="icon-width d-flex align-items-center justify-content-center"
                                >
                                  <img
                                    style = {{ width: menuItem.name === "Communications" ? "16px" : "", height: menuItem.name === "Communications" ? "16px" : "" }}
                                    src={menuItem.iConName}
                                    alt={menuItem.name}
                                  />
                                </div>
                              )}
                              {opened === true && prevClickSubMenu !== "" && prevClickSubMenu !== menuItem.name && (
                                <div
                                  className="icon-width d-flex align-items-center justify-content-center"
                                >
                                  <OverlayTrigger
                                    placement="right"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={
                                      <Tooltip id={"letf-nav1"}>
                                        {menuItem.name}
                                      </Tooltip>
                                    }
                                  >
                                    <img
                                      style = {{ width: menuItem.name === "Communications" ? "16px" : "", height: menuItem.name === "Communications" ? "16px" : "" }}
                                      src={menuItem.iConName}
                                      alt={menuItem.name}
                                    />
                                  </OverlayTrigger>
                                </div>
                              )}
                              {opened === true && prevClickSubMenu !== "" && prevClickSubMenu === menuItem.name && (
                                <div
                                  className="icon-width d-flex align-items-center justify-content-center"
                                >
                                  <img
                                    style = {{ width: menuItem.name === "Communications" ? "16px" : "", height: menuItem.name === "Communications" ? "16px" : "" }}
                                    src={menuItem.iConName}
                                    alt={menuItem.name}
                                  />
                                </div>
                              )}
                              <div className="lable mr-auto">{menuItem.name === "LaborPromotion" ? "Labor" : menuItem.name} 
                                {menuItem.name === "LaborPromotion" && this.state.showPip && menuItem.isAdditionalSpace == "Yes" ? <div className="navigation-pip"><div className="pip-icon red"></div></div> : <></>}
                                            </div>
                              {
                                this.state.navigationpipCount?.map((pipItem) => (
                                  <>
                                    {(pipItem.module === menuItem.name && pipItem.pipCount > 0) && (
                                      <div className={`badge d-flex justify-content-center align-items-center ${!this.state.opened || this.state.subClickOpened ? "pos-abs" : ""}`}
                                      >{pipItem.pipCount}</div>
                                    )}
                                  </>
                                ))}
                            </div>
                          </>
                        </Nav.Link>
                      ))}
                  </Nav>
                  <div className="blank-space mt-auto flex-column"
                  ></div>
                  <Nav
                    className="flex-column"
                    activeKey={this.state.activeLink}
                    onSelect={handleSelect}
                  >
                    <div className="pos-rel">
                      {this.state.menuItemBottom.length > 0 &&
                        this.state.menuItemBottom.map((menuItem: any, i: any) => (
                          <>
                            {menuItem.name === "Profile" && (
                              <div
                                onClick={toogleProfileState}
                                className="nav-link profile d-flex justify-content-start align-items-center"
                              >
                                <div className="icon-width d-flex align-items-center">
                                  {menuItem.iConName !== "" && (
                                    <div className="actual-profile">
                                      <img
                                        src={menuItem.iConName}
                                        className={
                                          menuItem.name === "Profile"
                                            ? "profileimg"
                                            : ""
                                        }
                                        alt={menuItem.name}
                                      />
                                      {allowPunch && (
                                        <div
                                          className={
                                            allowPunchIn === 1
                                              ? "pips red"
                                              : "pips green"
                                          }
                                        ></div>
                                      )}
                                    </div>
                                  )}
                                  {menuItem.iConName === "" && (
                                    <div className="default-profile">
                                      <FontAwesomeIcon icon={faUserCircle} />
                                      {allowPunch && (
                                        <div
                                          className={
                                            allowPunchIn === 1
                                              ? "pips red"
                                              : "pips green"
                                          }
                                        ></div>
                                      )}
                                    </div>
                                  )}
                                </div>
                                <div className="lable mr-auto">
                                  <div className="userName">
                                    <OverlayTrigger
                                      placement="top"
                                      delay={{ show: 250, hide: 400 }}
                                      overlay={
                                        <Tooltip id={"Labor-Cost"}>
                                          {userName}
                                        </Tooltip>
                                      }
                                    >
                                      <span>{userName}</span>
                                    </OverlayTrigger>
                                  </div>
                                </div>
                                <div className="arrow">
                                  <svg
                                    width="8"
                                    height="12"
                                    viewBox="0 0 8 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      opacity="0.5"
                                      d="M1.70697 11.414L7.41397 5.707L1.70697 0L0.292969 1.414L4.58597 5.707L0.292969 10L1.70697 11.414Z"
                                    />
                                  </svg>
                                </div>
                              </div>
                            )}
                            {menuItem.name !== "Profile" && (
                              <Nav.Link
                                key={i}
                                id={"defaultSet-" + menuItem.name}
                                className={
                                  menuItem.isAdditionalSpace === "Yes"
                                    ? "margin-bottom"
                                    : ""
                                }
                                eventKey={menuItem.name}
                                onClick={openSubMenu}
                              >
                                <div className="d-flex justify-content-start align-items-center">
                                  {opened === false && (

                                    <div
                                      className="icon-width d-flex align-items-center"
                                      onClick={() => hoverMenu(menuItem.name)}
                                    >
                                      <OverlayTrigger
                                        placement="right"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={
                                          <Tooltip id={"letf-nav1"}>
                                            {menuItem.name}
                                          </Tooltip>
                                        }
                                      >
                                        <img
                                          src={menuItem.iConName}
                                          alt={menuItem.name}
                                        />
                                      </OverlayTrigger>
                                    </div>

                                  )}
                                  {opened === true && prevClickSubMenu === "" && (
                                    <div
                                      className="icon-width d-flex align-items-center"
                                    >
                                      <img
                                        src={menuItem.iConName}
                                        alt={menuItem.name}
                                      />
                                    </div>
                                  )}
                                  {opened === true && prevClickSubMenu !== "" && prevClickSubMenu !== menuItem.name && (

                                    <div
                                      className="icon-width d-flex align-items-center"
                                    >
                                      <OverlayTrigger
                                        placement="right"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={
                                          <Tooltip id={"letf-nav1"}>
                                            {menuItem.name}
                                          </Tooltip>
                                        }
                                      >
                                        <img
                                          src={menuItem.iConName}
                                          alt={menuItem.name}
                                        />
                                      </OverlayTrigger>
                                    </div>

                                  )}
                                  {opened === true && prevClickSubMenu !== "" && prevClickSubMenu === menuItem.name && (

                                    <div
                                      className="icon-width d-flex align-items-center"
                                    >
                                      <img
                                        src={menuItem.iConName}
                                        alt={menuItem.name}
                                      />
                                    </div>

                                  )}
                                  <div className="lable">
                                    {menuItem.name === "Profile"
                                      ? userName
                                      : menuItem.name}
                                  </div>
                                </div>
                              </Nav.Link>
                            )}
                          </>
                        ))}
                    </div>
                  </Nav>
                </Navbar>
              </div>
              <div>
                {this.state.menuItems.length > 0 &&
                  this.state.menuItems.map((menuItem: any, i: any) => (
                    <>
                      {menuItem.name === showMenu && (
                        <div key={i}>
                          <div
                            className={
                              this.state.subClickOpened
                                ? "sub-menu sec-list opened"
                                : "sub-menu sec-list"
                            }
                          >
                            <div className="add-padding">
                              <div className={`sec-name ${menuItem.name === "Dashboard" ? "mb_20" : ""}`}>{menuItem.name}</div>
                              <div className="scroll-area">
                                {menuItem.subMenuMaster.map(
                                  (menuList: any, i: any) => (
                                    <div className="section" key={i}>
                                      {this.checkLabel(menuList, "desktopView")}
                                      <Nav
                                        className="mt-auto flex-column"
                                        activeKey={showSubMenu}
                                        onSelect={(e: any) =>
                                          handleSubSelect(e, menuItem.name)
                                        }
                                      >
                                        {menuList.menuSubItemDetail.map(
                                          (subMenus: any, i: any) => {
                                            // if (subMenus.permissionID !== 0) {
                                            let tooltip = subMenus.subMenuID === 23;
                                            return (
                                              <React.Fragment>
                                                {
                                                  // this.state.menusPermission.map(
                                                  //   (permittedMenu) => {
                                                  // return (
                                                  <React.Fragment>
                                                    {
                                                      // permittedMenu.permissionID ===
                                                      //   subMenus.permissionID &&
                                                      subMenus.isHide === 0 &&
                                                        !subMenus.itemPath
                                                          .toString()
                                                          .includes("aspx") ? (
                                                        <Nav.Link
                                                          id={
                                                            subMenus.name === "Change Password"
                                                              ? "Change-Password"
                                                              : subMenus.name === "Entity Management"
                                                                ? "Entity_Management"
                                                                : subMenus.name === "Roles & Permissions"
                                                                  ? "Roles_Permissions" : subMenus.name
                                                          }
                                                          key={i}
                                                          as={Link}
                                                          to={
                                                            subMenus.itemPath
                                                          }
                                                          onClick={
                                                            subMenus.name === "My Profile" ? openUserProfileSlideOut : closeUserProfileSlideOut
                                                          }
                                                          eventKey={
                                                            (menuItem.name === "Accounting" && subMenus.name === "Overview") || (menuItem.name === "Facilities" && subMenus.name === "Overview") ?
                                                              menuItem.name + "-" + subMenus.name : (subMenus.name === "Daily Flash" || subMenus.name === "Daily Tasks") ? subMenus.itemID + "-" + subMenus.name : subMenus.name
                                                          }
                                                          className="d-flex align-items-center transition"
                                                        >
                                                          <div
                                                            className="ellipsis"
                                                            id={
                                                              subMenus.subMenuID
                                                            }
                                                          >
                                                            {tooltip ?
                                                              <EllipsisWithTooltip placement="bottom">
                                                                {subMenus.name}
                                                              </EllipsisWithTooltip> :
                                                              subMenus.name}
                                                            {subMenus.subMenuID ===
                                                              19 &&
                                                              subMenus.name ===
                                                              "Labor Performance" && (
                                                                <span className="nav-beta-tag transition">
                                                                  BETA
                                                                </span>
                                                              )}
                                                          </div>{" "}
                                                        </Nav.Link>
                                                      ) : null}
                                                    {
                                                      // permittedMenu.permissionID ===
                                                      //   subMenus.permissionID &&
                                                      subMenus.isHide === 0 &&
                                                        subMenus.itemPath
                                                          .toString()
                                                          .includes("aspx") ? (
                                                        <Nav.Link
                                                          id={
                                                            subMenus.name === "Change Password"
                                                              ? "Change-Password"
                                                              : subMenus.name === "Entity Management"
                                                                ? "Entity_Management"
                                                                : subMenus.name === "Roles & Permissions"
                                                                  ? "Roles_Permissions" : subMenus.name
                                                          }
                                                          key={i}
                                                          as={Link}
                                                          to="#"
                                                          onClick={() =>
                                                            handleAccountingRedirection(
                                                              subMenus.itemPath
                                                            )
                                                          }
                                                          eventKey={
                                                            (menuItem.name === "Accounting" && subMenus.name === "Overview") || (menuItem.name === "Facilities" && subMenus.name === "Overview") ?
                                                              menuItem.name + "-" + subMenus.name : subMenus.name
                                                          }
                                                          className="d-flex align-items-center transition"
                                                        >
                                                          <div
                                                            className="ellipsis"
                                                            id={
                                                              subMenus.subMenuID
                                                            }
                                                          >
                                                            {" "}
                                                            {
                                                              <EllipsisWithTooltip placement="bottom">
                                                                {subMenus.name}
                                                              </EllipsisWithTooltip>
                                                            }{" "}
                                                          </div>{" "}
                                                        </Nav.Link>
                                                      ) : null}
                                                  </React.Fragment>
                                                  // );
                                                }
                                                {/* )} */}
                                              </React.Fragment>
                                            );
                                            // } else {
                                            //   return (
                                            //     <Nav.Link
                                            //       id={
                                            //         subMenus.name === "Change Password"
                                            //           ? "Change-Password"
                                            //           : subMenus.name === "Entity Management"
                                            //             ? "Entity_Management"
                                            //             : subMenus.name === "Roles & Permissions"
                                            //               ? "Roles_Permissions" : subMenus.name
                                            //       }
                                            //       key={i}
                                            //       as={Link}
                                            //       to={subMenus.itemPath}
                                            //       onClick={
                                            //         subMenus.name === "My Profile" ? openUserProfileSlideOut : closeUserProfileSlideOut
                                            //       }
                                            //       eventKey={
                                            //         (menuItem.name === "Accounting" && subMenus.name === "Overview") || ( menuItem.name === "Facilities" && subMenus.name === "Overview") ?
                                            //           menuItem.name + "-" + subMenus.name : (subMenus.name === "Daily Flash" || subMenus.name === "Daily Tasks") ? subMenus.itemID + "-" + subMenus.name : subMenus.name
                                            //       }
                                            //       className="d-flex align-items-center transition"
                                            //     > 
                                            //       <div className="ellipsis">
                                            //         {subMenus.name}
                                            //         {subMenus.subMenuID === 19 &&
                                            //           subMenus.name ===
                                            //           "Labor Performance" && (
                                            //             <span className="nav-beta-tag">
                                            //               Beta
                                            //             </span>
                                            //           )}
                                            //       </div>
                                            //     </Nav.Link>
                                            //   );
                                            // }
                                          }
                                        )}
                                      </Nav>
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  ))}

                {this.state.menuItems.length > 0 &&
                  this.state.menuItems.map((menuItem: any, i: any) => {
                    return (
                      <>
                        {(
                          (menuItem.name === showMenuOver && menuItem.name !== activeClickSubMenu && opened === true) ||
                          (menuItem.name === showMenuOver && activeClickSubMenu === "" && opened === true) ||
                          (menuItem.name === showMenuOver && (activeClickSubMenu === "" || activeClickSubMenu !== "") && opened === false) ||
                          (menuItem.name === showMenuOver && this.state.specialCondition)
                        ) && (
                            <div key={i}>
                              <div
                                className={
                                  this.state.hoverMenu
                                    ? "hover-menu opened"
                                    : "hover-menu"
                                }
                                onMouseOver={() => hoverSubMenu(menuItem.name)}
                              >
                                <div
                                  className={
                                    !this.state.opened
                                      ? "sub-menu sec-list height"
                                      : "sub-menu sec-list"
                                  }
                                >
                                  <div className="add-padding">
                                    <div className="sec-name">{menuItem.name}</div>
                                    <div className="scroll-area">
                                      {menuItem.subMenuMaster.map(
                                        (menuList: any, i: any) => {
                                          return (
                                            <div className="section" key={i}>
                                              {this.checkLabel(
                                                menuList,
                                                "desktopView"
                                              )}
                                              <Nav
                                                className="mt-auto flex-column"
                                                activeKey={showSubMenu}
                                                onSelect={(e: any) =>
                                                  handleSubSelect(e, menuItem.name)
                                                }
                                              >
                                                {menuList.menuSubItemDetail.map(
                                                  (subMenus: any, i: any) => {
                                                    // if (
                                                    //   subMenus.permissionID !== 0
                                                    // ) {
                                                    // return 
                                                    // this.state.menusPermission.map(
                                                    //   (permittedMenu: any) => {
                                                    return (
                                                      <React.Fragment>
                                                        {
                                                          // permittedMenu.permissionID ===
                                                          //   subMenus.permissionID &&
                                                          subMenus.isHide === 0 &&
                                                            !subMenus.itemPath
                                                              .toString()
                                                              .includes("aspx") ?
                                                            (
                                                              <Nav.Link
                                                                id={subMenus.name}
                                                                key={i}
                                                                as={Link}
                                                                to={
                                                                  subMenus.itemPath
                                                                }
                                                                eventKey={
                                                                  (menuItem.name === "Accounting" && subMenus.name === "Overview") || (menuItem.name === "Facilities" && subMenus.name === "Overview") ?
                                                                    menuItem.name + "-" + subMenus.name : subMenus.name
                                                                }
                                                                className="d-flex align-items-center transition"
                                                                onClick={() =>
                                                                  setActiveMenu(
                                                                    menuItem.name,
                                                                    subMenus.name
                                                                  )
                                                                }
                                                              >
                                                                <div className="ellipsis">
                                                                  {subMenus.name}
                                                                </div>
                                                              </Nav.Link>
                                                            ) : null}
                                                        {
                                                          // permittedMenu.permissionID ===
                                                          //   subMenus.permissionID &&
                                                          subMenus.isHide === 0 &&
                                                            subMenus.itemPath
                                                              .toString()
                                                              .includes("aspx") ? (
                                                            <Nav.Link
                                                              id={
                                                                subMenus.name === "Change Password"
                                                                  ? "Change-Password"
                                                                  : subMenus.name === "Entity Management"
                                                                    ? "Entity_Management"
                                                                    : subMenus.name === "Roles & Permissions"
                                                                      ? "Roles_Permissions" : subMenus.name
                                                              }
                                                              key={i}
                                                              as={Link}
                                                              to="#"
                                                              onClick={() =>
                                                                handleAccountingRedirection(
                                                                  subMenus.itemPath
                                                                )
                                                              }
                                                              eventKey={
                                                                (menuItem.name === "Accounting" && subMenus.name === "Overview") || (menuItem.name === "Facilities" && subMenus.name === "Overview") ?
                                                                  menuItem.name + "-" + subMenus.name : subMenus.name
                                                              }
                                                              className="d-flex align-items-center transition"
                                                            >
                                                              <div
                                                                className="ellipsis"
                                                                id={
                                                                  subMenus.subMenuID
                                                                }
                                                              >
                                                                {" "}
                                                                {
                                                                  subMenus.name
                                                                }{" "}
                                                              </div>{" "}
                                                            </Nav.Link>
                                                          ) : null}
                                                      </React.Fragment>

                                                    );
                                                    // }
                                                    // );
                                                    // } else {
                                                    //   return (
                                                    //     <Nav.Link
                                                    //       id={subMenus.name}
                                                    //       key={i}
                                                    //       as={Link}
                                                    //       to={subMenus.itemPath}
                                                    //       eventKey={
                                                    //         (menuItem.name === "Accounting" && subMenus.name === "Overview") || ( menuItem.name === "Facilities" && subMenus.name === "Overview") ?
                                                    //           menuItem.name + "-" + subMenus.name : subMenus.name
                                                    //       }
                                                    //       className="d-flex align-items-center transition"
                                                    //       onClick={() =>
                                                    //         setActiveMenu(
                                                    //           menuItem.name,
                                                    //           subMenus.name
                                                    //         )
                                                    //       }
                                                    //     >
                                                    //       <div className="ellipsis">
                                                    //         {subMenus.name}
                                                    //       </div>
                                                    //     </Nav.Link>
                                                    //   );
                                                    // }
                                                  }
                                                )}
                                              </Nav>
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  </div>
                                </div>

                                <div
                                  className={`toggle-btn icon-change pin-hover                                    
                                      ${(this.state.opened === false && this.state.hoverMenu === true) ? "slide" : ""}                                       
                                      `}
                                >
                                  {(this.state.opened === false && this.state.hoverMenu === true) && (
                                    <Button type="button"
                                      as={Link}
                                      to={this.getFirstItemSelected1(menuItem.name)}
                                      onClick={() => this.handleClickPinup(menuItem.name)}
                                      id="nav-hover-toggle-btn"
                                    >
                                      <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g filter="url(#filter0_d)"><circle cx="24" cy="23" r="14" fill="white" />
                                          <circle cx="24" cy="23" r="13.5" stroke="#E0E3E6" />
                                        </g>
                                        <path
                                          d="M25.0776 18.2441L20.3218 23L25.0776 27.7558L26.2559 26.5775L22.6784 23L26.2559 19.4225L25.0776 18.2441Z"
                                          fill="#364760"
                                        />
                                        <defs>
                                          <filter id="filter0_d" x="0" y="0" width="48" height="48" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                            <feColorMatrix
                                              in="SourceAlpha"
                                              type="matrix"
                                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                            />
                                            <feOffset dy="1" />
                                            <feGaussianBlur stdDeviation="5" />
                                            <feColorMatrix
                                              type="matrix"
                                              values="0 0 0 0 0.172549 0 0 0 0 0.243137 0 0 0 0 0.345098 0 0 0 0.1 0"
                                            />
                                            <feBlend
                                              mode="normal"
                                              in2="BackgroundImageFix"
                                              result="effect1_dropShadow"
                                            />
                                            <feBlend
                                              mode="normal"
                                              in="SourceGraphic"
                                              in2="effect1_dropShadow"
                                              result="shape"
                                            />
                                          </filter>
                                        </defs>
                                      </svg>
                                    </Button>
                                  )}

                                </div>
                              </div>
                            </div>
                          )}
                      </>
                    );
                  })}
              </div>
            </div>



            <div
              className={`toggle-btn                                    
              ${this.state.opened ? "opened" : "icon-change"}
              ${this.state.specialCondition ? "special-condition" : ""}                                                     
            `}
            >
              <button
                type="button"
                onClick={this.handleClick}
                id="nav-toggle-btn"
              >
                <svg
                  width="48"
                  height="48"
                  viewBox="0 0 48 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g filter="url(#filter0_d)">
                    <circle cx="24" cy="23" r="14" fill="white" />
                    <circle cx="24" cy="23" r="13.5" stroke="#E0E3E6" />
                  </g>
                  <path
                    d="M25.0776 18.2441L20.3218 23L25.0776 27.7558L26.2559 26.5775L22.6784 23L26.2559 19.4225L25.0776 18.2441Z"
                    fill="#364760"
                  />
                  <defs>
                    <filter
                      id="filter0_d"
                      x="0"
                      y="0"
                      width="48"
                      height="48"
                      filterUnits="userSpaceOnUse"
                      colorInterpolationFilters="sRGB"
                    >
                      <feFlood floodOpacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      />
                      <feOffset dy="1" />
                      <feGaussianBlur stdDeviation="5" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.172549 0 0 0 0 0.243137 0 0 0 0 0.345098 0 0 0 0.1 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow"
                        result="shape"
                      />
                    </filter>
                  </defs>
                </svg>
              </button>
            </div>








            {this.state.showProfileMenu && (
              <OutsideClickHandler onOutsideClick={this.handleOutsideClick}>
                <div
                  className={
                    this.state.opened
                      ? "profile-submenu opened"
                      : "profile-submenu"
                  }
                >
                  <div className="profile-sec d-flex flex-column justify-content-center">
                    <div className="profile-img">
                      {profilemenuLink?.[0].iConName !== "" && (
                        <div className="d-flex justify-content-center">
                          <img
                            src={profilemenuLink?.[0].iConName}
                            alt={userName}
                          />
                        </div>
                      )}

                      {profilemenuLink?.[0].iConName === "" && (
                        <div className="default-profile d-flex justify-content-center">
                          <FontAwesomeIcon icon={faUserCircle} />
                        </div>
                      )}
                    </div>
                    <div className="profile-details">
                      <div className="name px-3"><EllipsisWithTooltip placement="bottom">{userName}</EllipsisWithTooltip></div>
                      <div className="punch-sec d-flex justify-content-center">
                        {hasLaborPermission && allowPunch && (
                          <button
                            type="button"
                            className={
                              "punch-in d-flex align-items-center" +
                              (allowPunchIn === 1 ? "" : " out")
                            }
                            onClick={(e) =>
                              this.getUserPunchInDetails(allowPunchIn)
                            }
                          >
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M9.99984 1.66669C5.40484 1.66669 1.6665 5.40502 1.6665 10C1.6665 14.595 5.40484 18.3334 9.99984 18.3334C14.5948 18.3334 18.3332 14.595 18.3332 10C18.3332 5.40502 14.5948 1.66669 9.99984 1.66669ZM9.99984 16.6667C6.32401 16.6667 3.33317 13.6759 3.33317 10C3.33317 6.32419 6.32401 3.33335 9.99984 3.33335C13.6757 3.33335 16.6665 6.32419 16.6665 10C16.6665 13.6759 13.6757 16.6667 9.99984 16.6667Z" />
                              <path d="M10.8332 5.83331H9.1665V10.345L11.9107 13.0891L13.089 11.9108L10.8332 9.65498V5.83331Z" />
                            </svg>
                            {this.state.showModalWait ? (
                              "Wait..."
                            ) : (
                              <>{allowPunchIn === 1 ? "Punch In" : "Punch Out"}</>
                            )}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="navigation-section">
                    <div className="section">
                      <Nav
                        className="flex-column"
                        activeKey={showSubMenu}
                        onSelect={(e: any) => handleSubSelect(e, "Profile")}
                      >
                        {
                          useDefaultTheme != "true" && this.state.showDefaultThemeToggle == "true" &&
                          <div className="default-theme-toggle">
                            <Form.Check
                              type="switch"
                              id="switchtodefault"
                              label="Switch to default theme"
                              name="hasEnterpriseAccess"
                              checked={useDefaultTheme == "true"}
                              onChange={(event: any) => {
                                this.switchToDefaultTheme();
                              }}
                            />
                          </div>
                        }
                        {profileMenu?.map((item: any, i: any) => (
                          <>
                            {item.hasPermission && (
                              <>
                                <Nav.Link
                                  id={
                                    item.name === "Change Password"
                                      ? "Change-Password"
                                      : item.name === "Entity Management"
                                        ? "Entity_Management"
                                        : item.name === "Roles & Permissions"
                                          ? "Roles_Permissions" : item.name
                                  }
                                  key={i}
                                  as={Link}

                                  to={item.itemPath}
                                  onClick={
                                    item.name === "My Profile" ? openUserProfileSlideOut : closeUserProfileSlideOut
                                  }
                                  eventKey={item.name}
                                >
                                  {item.name}
                                </Nav.Link>
                                {item.isAdditionalSpace === "Yes" && (
                                  <div className="separator"></div>
                                )}
                              </>
                            )}
                          </>
                        ))}
                      </Nav>
                    </div>
                  </div>
                </div>
              </OutsideClickHandler>
            )}

            <Modal
              show={this.state.userPunchModal}
              onHide={this.hideUserPunchModal}
              centered
              aria-labelledby="example-modal-sizes-title-md"
              className="user-punch-modal user-punch-in-modal userPunchModal"
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>{punchType}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {this.state.scheduleData.length > 0 ? (
                  <div className="body-sec">
                    <div className="employee-card d-flex">
                      <div className="employee-img">
                        {this.state.scheduleData[0].imagePath !== null ? (
                          <img
                            src={this.state.scheduleData[0].imagePath}
                            alt="profile img"
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faUserCircle}
                            className="bg-light"
                          />
                        )}
                        {allowPunch && (
                          <span
                            className={
                              allowPunchIn === 1
                                ? "employee-punch-out"
                                : "employee-punch-in"
                            }
                          ></span>
                        )}
                      </div>

                      <div className="employee-details d-flex">
                        <span className="employee-name">
                          <EllipsisWithTooltip placement="bottom">{this.state.scheduleData[0].userName}</EllipsisWithTooltip>
                        </span>
                        <span className="employee-status">
                          <>
                            {allowPunchIn === 1 ? (
                              <>
                                {allowPunchIn === 1
                                  ? "Punched Out"
                                  : "Punched In"}
                              </>
                            ) : (
                              <>
                                {allowPunchIn === 1
                                  ? "Punched Out "
                                  : "Punched In "}
                                at {this.state.scheduleData[0].inTime}
                              </>
                            )}
                          </>
                        </span>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="body-sec">
                      {this.state.menuItemBottom.length > 0 &&
                        this.state.menuItemBottom.map((menuItem: any, i: any) => (
                          <>
                            {menuItem.name === "Profile" && (
                              <>
                                <div className="employee-card d-flex">
                                  <div className="employee-img">
                                    {menuItem.iConName !== "" && (
                                      <>
                                        <img
                                          src={menuItem.iConName}
                                          alt={menuItem.name}
                                        />
                                        {allowPunch && (
                                          <span
                                            className={
                                              allowPunchIn === 1
                                                ? "employee-punch-out"
                                                : "employee-punch-in"
                                            }
                                          ></span>
                                        )}
                                      </>
                                    )}

                                    {menuItem.iConName === "" && (
                                      <>
                                        <FontAwesomeIcon
                                          icon={faUserCircle}
                                          className="bg-light"
                                        />
                                        {allowPunch && (
                                          <span
                                            className={
                                              allowPunchIn === 1
                                                ? "employee-punch-out"
                                                : "employee-punch-in"
                                            }
                                          ></span>
                                        )}
                                      </>
                                    )}
                                  </div>
                                  <div className="employee-details d-flex">
                                    <span className="employee-name">
                                      <EllipsisWithTooltip placement="bottom">{userName}</EllipsisWithTooltip>
                                    </span>
                                    <span className="employee-status">
                                      {allowPunchIn === 1
                                        ? "Punched Out"
                                        : "Punched In"}
                                    </span>
                                  </div>
                                </div>
                              </>
                            )}
                          </>
                        ))}
                    </div>
                  </>
                )}

                <div className="body-sec">
                  <div className="scheduleBox">
                    <>
                      {this.state.scheduleData.length > 0 ? (
                        <div className="employee-schedule-container">
                          {this.state.scheduleData.map((data: any, idx: any) => (
                            <React.Fragment key={idx}>
                              <div className="employee-schedule d-flex">
                                <Form.Check
                                  disabled={data.status === "P"}
                                  type="checkbox"
                                  label=""
                                  checked={data.isChecked}
                                  onChange={(e: any) => {
                                    this.scheduleCheck(e, idx);
                                  }}
                                />
                                <div className="schedule-details d-flex">
                                  <span className="schedule-name">
                                    {data.topStatus}
                                  </span>
                                  <span className="schedule-time">
                                    {data.bottomStatus}
                                  </span>
                                </div>
                              </div>
                              <div className="schedule-seperator"></div>
                            </React.Fragment>
                          ))}
                        </div>
                      ) : (
                        <>
                          {!this.state.userHasNoSchedule && (
                            <div className="userNoSchedulePanel noSchedule">
                              <header className="d-flex">
                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    id="noSchedule"
                                    disabled
                                    className="form-check-input"
                                  />
                                  <label
                                    htmlFor="noSchedule"
                                    title=""
                                    className="form-check-label"
                                  >
                                    No Scheduled Shift
                                  </label>
                                </div>
                              </header>
                            </div>
                          )}
                        </>
                      )}
                      <>
                        {allowPunchIn === 1 &&
                          (this.state.hidListCount.length > 1 ||
                            this.state.scheduleData.length === 0) && (
                            <div className="userNoSchedulePanel">
                              <header className="d-flex">
                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    id="AddManualShift"
                                    checked={this.state.userHasNoSchedule}
                                    onChange={this.showUserSchedule}
                                    className="form-check-input"
                                  />
                                  <label
                                    htmlFor="AddManualShift"
                                    title=""
                                    className="form-check-label"
                                  >
                                    Add Manual Shift
                                  </label>
                                </div>
                                {this.state.userHasNoSchedule && (
                                  <button
                                    type="button"
                                    className="btn-delete"
                                    onClick={this.hideUserSchedule}
                                  >
                                    <svg
                                      width="20"
                                      height="20"
                                      viewBox="0 0 18 18"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="deleteIconRP"
                                    >
                                      <path
                                        d="M3.75226 15C3.75226 15.8273 4.42501 16.5 5.25226 16.5H12.7523C13.5795 16.5 14.2523 15.8273 14.2523 15V6H15.7523V4.5H13.5023H12.7523V3C12.7523 2.17275 12.0795 1.5 11.2523 1.5H6.75226C5.92501 1.5 5.25226 2.17275 5.25226 3V4.5H4.50226H2.25226V6H3.75226V15ZM6.75226 3H11.2523V4.5H6.75226V3ZM6.00226 6H12.0023H12.7523L12.753 15H5.25226V6H6.00226Z"
                                        fill="#2caf92"
                                      ></path>
                                      <path
                                        d="M6.75226 7.5H8.25226V13.5H6.75226V7.5ZM9.75226 7.5H11.2523V13.5H9.75226V7.5Z"
                                        fill="#2caf92"
                                      ></path>
                                    </svg>
                                  </button>
                                )}
                              </header>

                              {this.state.userHasNoSchedule && (
                                <div className="userNoSchedule">
                                  <div className="EhidSelector d-flex align-items-center">
                                    <strong>Select HID</strong>
                                    {this.state.hidListCount.length > 0 && (
                                      <SingleSearchDropdownList
                                        tabIndex={this.state.tabEnable}
                                        itemList={this.state.hidListCount}
                                        transferControl={this.transferControl.bind(
                                          this,
                                          "headerEhid"
                                        )}
                                        pageType="headerEhid"
                                        defaultItem={this.state.headerEHID}
                                        defaultText={"Select"}
                                        defaultName={this.state.headerEHID}
                                        controlID="2"
                                      />
                                    )}
                                  </div>
                                  <Form.Group className="formPos">
                                    <Form.Label>Department</Form.Label>
                                    <Dropdown className="EmpInput">
                                      <Dropdown.Toggle
                                        className={
                                          departmentError && "border-danger"
                                        }
                                        id="dropdown-department"
                                        disabled={this.state.hasForecast}
                                      >
                                        <EllipsisWithTooltip placement="bottom">
                                          {this.state.departmentName}
                                        </EllipsisWithTooltip>
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu>
                                        {this.state.alldepartmentlist.map(
                                          (item: any, index: any) => (
                                            <Dropdown.Item
                                              key={item.departmentName}
                                              eventKey={item.departmentName}
                                              onClick={(event: any) =>
                                                this.departmentSplitChange(
                                                  event,
                                                  item,
                                                  index
                                                )
                                              }
                                            >
                                              <EllipsisWithTooltip placement="bottom">
                                                {item.departmentName}
                                              </EllipsisWithTooltip>
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                    <input
                                      type="text"
                                      className="textHidden"
                                      name="department"
                                      value={
                                        this.state.departmentName === "Select"
                                          ? ""
                                          : this.state.departmentNameID
                                      }
                                      placeholder={
                                        this.state.departmentName === "Select"
                                          ? "Select"
                                          : ""
                                      }
                                      required
                                      autoComplete="Off"
                                    />
                                  </Form.Group>

                                  <Form.Group className="formPos">
                                    <Form.Label>Position</Form.Label>
                                    <Dropdown className="EmpInput">
                                      <Dropdown.Toggle
                                        className={
                                          positionError && "border-danger"
                                        }
                                        id="dropdown-position"
                                        disabled={this.state.hasForecast}
                                      >
                                        <EllipsisWithTooltip placement="bottom">
                                          {this.state.positionName}
                                        </EllipsisWithTooltip>
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        {this.state.allpositionlist.map(
                                          (item: any, index: any) => (
                                            <Dropdown.Item
                                              key={item.positionName}
                                              eventKey={item.positionName}
                                              onClick={(event: any) =>
                                                this.positionSplitChange(
                                                  event,
                                                  item,
                                                  index
                                                )
                                              }
                                            >
                                              <EllipsisWithTooltip placement="bottom">
                                                {item.positionName}
                                              </EllipsisWithTooltip>
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                    <input
                                      type="text"
                                      className="textHidden"
                                      name="position"
                                      value={
                                        this.state.positionName === "Select"
                                          ? ""
                                          : this.state.positionNameID
                                      }
                                      placeholder={
                                        this.state.positionName === "Select"
                                          ? "Select"
                                          : ""
                                      }
                                      required
                                      autoComplete="Off"
                                    />
                                  </Form.Group>
                                  <Form.Group>
                                    <Form.Label>End Time</Form.Label>
                                    {this.state.hid}
                                    <Form.Control
                                      type="time"
                                      disabled={this.state.hasForecast}
                                      className={
                                        punchTimeError && "border-danger"
                                      }
                                      value={this.state.punchTime}
                                      onChange={this.editPunchTime}
                                      placeholder="Enter email"
                                    />
                                  </Form.Group>
                                </div>
                              )}
                            </div>
                          )}
                      </>
                    </>
                  </div>
                </div>
              </Modal.Body>

              <Modal.Footer className="bg-light">
                <Button className="btn-ghost" onClick={this.hideUserPunchModal}>
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  onClick={(e: any) => this.submitUserPunch(punchType)}
                  disabled={this.state.punchButtonSpinner}
                >
                  {this.state.punchButtonSpinner && (
                    <Spinner className="mr-2" size="sm" animation="border" />
                  )}
                  {punchType}
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        )}
        {/* Desktop view navigation Ends */}

        {/* Mobile view navigation starts */}
        <div className="nav-area-mobile">
          <div
            className={
              this.state.mobileNavOpen
                ? "head-nav d-flex open"
                : "head-nav d-flex closed"
            }
          >
            <span
              className="nav-icon"
              onClick={(e: any) => this.toggleMobileNav(e)}
            >
              {this.state.mobileNavOpen ? (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18.0002 4L11.9993 9.99947L5.99982 4L4 5.99982L9.99947 11.9993L4 17.9988L5.99982 19.9986L11.9993 13.9991L18.0002 19.9986L20 17.9988L14.0005 11.9993L20 5.99982L18.0002 4Z"
                    fill="#2CAF92"
                  />
                </svg>
              ) : (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.8"
                    d="M4 6H20V8H4V6ZM4 11H20V13H4V11ZM4 16H20V18H4V16Z"
                    fill="#fff"
                  />
                </svg>
              )}
            </span>
            <span className="inn-flow-icon d-flex align-items-center mr-auto">
              <span className="update-logo">
                <svg width="26" height="40" viewBox="0 0 26 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M23.0916 5.46606H9.8288V1.05117C9.8288 0.63654 9.96746 0.338709 10.2428 0.163515C10.4108 0.0545049 10.6276 0 10.8561 0C11.0631 0 11.2799 0.0467185 11.4771 0.136263L23.0916 5.46606Z" fill="white" />
                  <path d="M22.8591 6.24512L11.0064 11.6859C10.5455 11.8961 10.147 12.1278 9.8287 12.4275C9.68026 12.5638 9.54941 12.7156 9.4381 12.885C9.26038 13.1536 9.12953 13.467 9.0475 13.8466C8.98305 14.1405 8.94985 14.4734 8.94985 14.855V33.8421H3.49511C2.35065 33.8421 1.42102 32.9136 1.42102 31.7749V8.31242C1.42102 7.1717 2.35065 6.24512 3.49511 6.24512H22.8591Z" fill="white" />
                  <path d="M24.3841 6.61621V33.9193L24.2865 33.964L23.8198 34.1781L23.7065 34.2308L22.853 34.622L11.7912 39.6988C11.1135 40.016 10.551 40.0861 10.2444 39.8915C9.96901 39.7183 9.928 39.3366 9.928 39.1265V14.8542C9.928 13.4819 10.387 13.04 11.4162 12.5689L24.3549 6.62984L24.3861 6.61621H24.3841Z" fill="white" />
                </svg>
              </span>
              {this.state.mobileNavOpen && (
                <span className="inn-flow-text">
                  <svg width="112" height="26" viewBox="0 0 112 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M54.851 7.4335V10.7803H44.1539V7.4335H54.851ZM46.7932 25.2828V5.82988C46.7932 4.6058 47.0422 3.59094 47.5405 2.78525C48.0466 1.97955 48.7278 1.37529 49.5842 0.972439C50.4405 0.56959 51.3944 0.368164 52.4453 0.368164C53.1849 0.368164 53.8428 0.426264 54.4189 0.542477C55.0029 0.658677 55.4388 0.763264 55.7269 0.856227L54.8627 4.20296C54.6837 4.14873 54.4578 4.0945 54.1854 4.04028C53.9129 3.97829 53.6132 3.9473 53.2862 3.9473C52.5076 3.9473 51.9588 4.13324 51.6396 4.5051C51.3281 4.8692 51.1724 5.39213 51.1724 6.07388V25.2828H46.7932Z" fill="white" />
                    <path d="M62.5003 1.48373V25.2827H58.1209V1.48373H62.5003Z" fill="white" />
                    <path d="M74.6427 25.6314C72.8753 25.6314 71.3455 25.2479 70.0532 24.4809C68.7608 23.7139 67.7604 22.641 67.0519 21.262C66.3511 19.8752 66.0008 18.2639 66.0008 16.4279C66.0008 14.584 66.3511 12.9687 67.0519 11.582C67.7604 10.1952 68.7608 9.11849 70.0532 8.35149C71.3455 7.58449 72.8753 7.20111 74.6427 7.20111C76.4021 7.20111 77.9281 7.58449 79.2205 8.35149C80.5129 9.11849 81.5133 10.1952 82.2218 11.582C82.9303 12.9687 83.2846 14.584 83.2846 16.4279C83.2846 18.2639 82.9303 19.8752 82.2218 21.262C81.5133 22.641 80.5129 23.7139 79.2205 24.4809C77.9281 25.2479 76.4021 25.6314 74.6427 25.6314ZM74.6544 22.1684C75.5886 22.1684 76.3633 21.9127 76.9783 21.4014C77.6012 20.8901 78.0643 20.2006 78.3679 19.333C78.6793 18.4652 78.8351 17.4891 78.8351 16.4046C78.8351 15.3277 78.6793 14.3555 78.3679 13.4877C78.0643 12.6124 77.6012 11.919 76.9783 11.4077C76.3633 10.8886 75.5886 10.6291 74.6544 10.6291C73.7123 10.6291 72.926 10.8886 72.2953 11.4077C71.6725 11.919 71.2053 12.6124 70.8939 13.4877C70.5903 14.3555 70.4384 15.3277 70.4384 16.4046C70.4384 17.4891 70.5903 18.4652 70.8939 19.333C71.2053 20.2006 71.6725 20.8901 72.2953 21.4014C72.926 21.9127 73.7123 22.1684 74.6544 22.1684Z" fill="white" />
                    <path d="M89.9851 25.2828L84.9515 7.4335H89.4123L92.4837 19.8675H92.647L95.8001 7.4335H100.203L103.38 19.8094H103.543L106.579 7.4335H111.029L105.995 25.2828H101.418L98.1127 13.3949H97.8678L94.5627 25.2828H89.9851Z" fill="white" />
                    <path d="M1.02618 25.2827V7.43349H3.24512V25.2827H1.02618ZM2.15901 4.38888C1.70741 4.38888 1.31824 4.23781 0.991251 3.93568C0.672051 3.62579 0.51239 3.25394 0.51239 2.8201C0.51239 2.39401 0.672051 2.0299 0.991251 1.72776C1.31824 1.41788 1.70741 1.26294 2.15901 1.26294C2.60283 1.26294 2.98421 1.41788 3.30354 1.72776C3.63053 2.0299 3.79396 2.39401 3.79396 2.8201C3.79396 3.25394 3.63053 3.62579 3.30354 3.93568C2.98421 4.23781 2.60283 4.38888 2.15901 4.38888Z" fill="white" />
                    <path d="M10.5684 14.2082V25.2827H8.34955V7.43349H10.4983V10.234H10.6968C11.1172 9.31986 11.7673 8.58774 12.6471 8.03774C13.5346 7.47999 14.6401 7.20111 15.9636 7.20111C17.1782 7.20111 18.241 7.45286 19.1519 7.95636C20.0627 8.45224 20.7712 9.18824 21.2772 10.1644C21.7911 11.1327 22.048 12.3335 22.048 13.7667V25.2827H19.8292V13.9061C19.8292 12.4575 19.4282 11.3147 18.6263 10.4781C17.8244 9.63361 16.7422 9.21136 15.3797 9.21136C14.4533 9.21136 13.6281 9.41286 12.904 9.81574C12.1799 10.2107 11.6077 10.7841 11.1873 11.5355C10.7746 12.2792 10.5684 13.1701 10.5684 14.2082Z" fill="white" />
                    <path d="M29.3479 14.2082V25.2827H27.1291V7.43349H29.2778V10.234H29.4764C29.8969 9.31986 30.547 8.58774 31.4267 8.03774C32.3142 7.47999 33.4198 7.20111 34.7433 7.20111C35.9578 7.20111 37.0205 7.45286 37.9314 7.95636C38.8423 8.45224 39.5508 9.18824 40.0569 10.1644C40.5707 11.1327 40.8276 12.3335 40.8276 13.7667V25.2827H38.6087V13.9061C38.6087 12.4575 38.2078 11.3147 37.4059 10.4781C36.604 9.63361 35.5218 9.21136 34.1594 9.21136C33.2328 9.21136 32.4076 9.41286 31.6836 9.81574C30.9595 10.2107 30.3873 10.7841 29.9668 11.5355C29.5543 12.2792 29.3479 13.1701 29.3479 14.2082Z" fill="white" />
                  </svg>
                </span>
              )}
            </span>
            {!this.state.mobileNavOpen && (
              <span className="module-name-selected">
                {this.state.selectedMobileMainMenu}
              </span>
            )}
            <div className="user-image ml-auto">
              {Object.keys(this.state.mobileTopProfile).length !== 0 && (
                <div
                  className="user-image-wrapper"
                  onClick={() => this.mobileProfileClick(true)}
                >
                  {this.state.allowPunch && (
                    <div
                      className={
                        this.state.allowPunchIn === 1
                          ? "pips pip red"
                          : "pips pip green"
                      }
                    ></div>
                  )}
                  {this.state.mobileTopProfile?.iConName === "" ? (
                    <FontAwesomeIcon icon={faUserCircle} />
                  ) : (
                    <img
                      src={this.state.mobileTopProfile?.iConName}
                      alt={userName}
                    />
                  )}
                  {this.state.mobileTopProfile?.subMenuMaster.length > 0 &&
                    this.state.mobileTopProfile.openAction && (
                      <OutsideClickHandler
                        onOutsideClick={() => this.mobileProfileClick(false)}
                      >
                        <div className="user-profile-list">
                          {this.state.mobileTopProfile.subMenuMaster[0]?.menuSubItemDetail.map(
                            (data: any, idx: number) => (
                              <Link
                                to={data.itemPath}
                                key={idx}
                                onClick={(
                                  e: React.MouseEvent<HTMLAnchorElement>
                                ) => {
                                  e.stopPropagation();
                                  this.mobileProfileClick(false);
                                  this.setState({ mobileNavOpen: false });
                                }}
                              >
                                <div className="profile-list">{data.name}</div>
                              </Link>
                            )
                          )}
                        </div>
                      </OutsideClickHandler>
                    )}
                </div>
              )}
            </div>
          </div>

          {this.state.mobileNavOpen && (
            <div className="nav-open">
              {this.state.mobileMenuTop.map((data: any, pdx: number) => (
                <div
                  className={
                    data.name === this.state.selectedMobileMainMenu
                      ? "nav-link active"
                      : "nav-link"
                  }
                  key={pdx}
                  onClick={(e) => this.mobileMainMenuClick(e, data)}
                >
                  <div className="d-flex justify-content-start align-items-center">
                    <div className="icon-width">
                      <img
                        style = {{ width: data.name === "Communications" ? "18px" : "", height: data.name === "Communications" ? "18px" : "", borderRadius: data.name === "Communications" ? "0px" : "", marginLeft: data.name === "Communications" ? "3px" : "" }}
                        src={data.iConName}
                        alt="menu_icon"
                        className="nav-link-icon"
                      />
                    </div>
                    <div className="lable mr-auto">{data.name}</div>
                    {
                      this.state.navigationpipCount?.map((pipItem) => (
                        <>
                          {(pipItem.module === data.name && pipItem.pipCount > 0) && (
                            <div className="badge d-flex justify-content-center align-items-center">{pipItem.pipCount}</div>
                          )}
                        </>
                      ))}
                    {data.subMenuMaster.length > 0 && (
                      <div
                        className={
                          data.openAction
                            ? "arrow rotate"
                            : "arrow"
                        }
                      >
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.38418 15.8842L14.2675 10L8.38418 4.11584L6.61584 5.88418L10.7325 10L6.61584 14.1158L8.38418 15.8842Z"
                            fill="#7E94B5"
                          />
                        </svg>
                      </div>
                    )}
                  </div>

                  {data.openAction &&
                    data.subMenuMaster.map((subMenu: any, idx: number) => (
                      <div className="mobile-sub-menu" key={idx}>
                        {this.checkLabel(subMenu, "mobileView")}
                        {subMenu.menuSubItemDetail.map(
                          (navMenu: any, idx: number) => {
                            // if (navMenu.permissionID !== 0) {
                            // return 
                            // this.state.menusPermission.map(
                            //   (permittedMenu: any) => {
                            return (
                              <React.Fragment>
                                {
                                  // permittedMenu.permissionID === navMenu.permissionID &&
                                  navMenu.isHide === 0 &&
                                    !navMenu.itemPath.toString().includes("aspx") ?
                                    <Link
                                      to={navMenu.itemPath}
                                      key={idx}
                                      onClick={() =>
                                        this.changeSelectedModule(
                                          data,
                                          navMenu
                                        )
                                      }
                                    >
                                      <div
                                        className={
                                          ((navMenu.name ===
                                            this.state.selectedMobileSubMenu) || (
                                              data.name + "-" + navMenu.name === this.state.selectedMobileSubMenu))
                                            ? "nav-item-menu active"
                                            : "nav-item-menu"
                                        }
                                      >
                                        {navMenu.name}
                                        {navMenu.subMenuID === 19 &&
                                          navMenu.name ===
                                          "Labor Performance" && (
                                            <span className="nav-beta-tag">
                                              Beta
                                            </span>
                                          )}
                                      </div>
                                    </Link>
                                    : null}
                                {
                                  // permittedMenu.permissionID === navMenu.permissionID && 
                                  navMenu.isHide === 0 &&
                                    navMenu.itemPath.toString().includes("aspx") ?
                                    <Nav.Link
                                      id={navMenu.name}
                                      to="#"
                                      as={Link}
                                      key={idx}
                                      eventKey={
                                        navMenu.name
                                      }
                                      onClick={() =>
                                        handleAccountingRedirection(
                                          navMenu.itemPath
                                        )
                                      }
                                    >
                                      <div
                                        className={
                                          ((navMenu.name ===
                                            this.state.selectedMobileSubMenu) || (
                                              data.name + "-" + navMenu.name === this.state.selectedMobileSubMenu))
                                            ? "nav-item-menu active"
                                            : "nav-item-menu"
                                        }
                                      >
                                        {navMenu.name}
                                        {navMenu.subMenuID === 19 &&
                                          navMenu.name ===
                                          "Labor Performance" && (
                                            <span className="nav-beta-tag">
                                              Beta
                                            </span>
                                          )}
                                      </div>
                                    </Nav.Link>
                                    : null}

                              </React.Fragment>
                            )

                            // if (
                            //   permittedMenu.permissionID ===
                            //   navMenu.permissionID
                            // ) {
                            //   return (
                            //     <Link
                            //       to={navMenu.itemPath}
                            //       key={idx}
                            //       onClick={() =>
                            //         this.changeSelectedModule(
                            //           data,
                            //           navMenu
                            //         )
                            //       }
                            //     >
                            //       <div
                            //         className={
                            //           navMenu.name ===
                            //           this.state.selectedMobileSubMenu
                            //             ? "nav-item-menu active"
                            //             : "nav-item-menu"
                            //         }
                            //       >
                            //         {navMenu.name}
                            //         {navMenu.subMenuID === 19 &&
                            //           navMenu.name ===
                            //             "Labor Performance" && (
                            //             <span className="nav-beta-tag">
                            //               Beta
                            //             </span>
                            //           )}
                            //       </div>
                            //     </Link>
                            //   );
                            // } else {
                            //   return false;
                            // }
                            //   }
                            // );
                            // } else {
                            //   return (
                            //     <Link
                            //       to={navMenu.itemPath}
                            //       key={idx}
                            //       onClick={() =>
                            //         this.changeSelectedModule(data, navMenu)
                            //       }
                            //     >
                            //       <div
                            //         className={
                            //           ((navMenu.name ===
                            //             this.state.selectedMobileSubMenu) || (
                            //               data.name + "-" + navMenu.name === this.state.selectedMobileSubMenu))
                            //             ? "nav-item-menu active"
                            //             : "nav-item-menu"
                            //         }
                            //       >
                            //         {navMenu.name}
                            //       </div>
                            //     </Link>
                            //   );
                            // }
                          }
                        )}
                      </div>
                    ))}
                </div>
              ))}

              <div className="separator-space"></div>

              {this.state.mobileMenuBottom.map((data: any, pdx: number) => (
                <div
                  className={
                    data.name === this.state.selectedMobileMainMenu
                      ? "nav-link active"
                      : "nav-link"
                  }
                  key={pdx}
                  onClick={(e) => this.mobileMainMenuClick(e, data)}
                >
                  <div className="d-flex justify-content-start align-items-center">
                    <div className="icon-width">
                      {(data.iConName === "" && data.name === "Profile") ? <FontAwesomeIcon icon={faUserCircle} /> : <img src={data.iConName} alt="menu_icon" />}
                    </div>
                    <div className="lable">{data.name}</div>
                    {data.subMenuMaster.length > 0 && (
                      <div
                        className={
                          data.openAction
                            ? "arrow ml-auto rotate"
                            : "arrow ml-auto"
                        }
                      >
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.38418 15.8842L14.2675 10L8.38418 4.11584L6.61584 5.88418L10.7325 10L6.61584 14.1158L8.38418 15.8842Z"
                            fill="#7E94B5"
                          />
                        </svg>
                      </div>
                    )}
                  </div>

                  {data.openAction &&
                    data.subMenuMaster.map((subMenu: any, idx: number) => (
                      <div className="mobile-sub-menu" key={idx}>
                        {this.checkLabel(subMenu, "mobileView")}
                        {subMenu.menuSubItemDetail.map(
                          (navMenu: any, idx: number) => {
                            // if (navMenu.permissionID !== 0) {
                            // return 
                            // this.state.menusPermission.map(
                            //   (permittedMenu: any) => {
                            if (
                              navMenu.isHide === 0
                            ) {
                              return (
                                <Link
                                  to={navMenu.itemPath}
                                  key={idx}
                                  onClick={() =>
                                    this.changeSelectedModule(
                                      data,
                                      navMenu
                                    )
                                  }
                                >
                                  <div
                                    className={
                                      ((navMenu.name ===
                                        this.state.selectedMobileSubMenu) || (
                                          data.name + "-" + navMenu.name === this.state.selectedMobileSubMenu))
                                        ? "nav-item-menu active"
                                        : "nav-item-menu"
                                    }
                                  >
                                    {navMenu.name}
                                  </div>
                                </Link>
                              );
                            } else {
                              return false;
                            }
                            //   }
                            // );
                            // } else {
                            //   return (
                            //     <Link
                            //       to={navMenu.itemPath}
                            //       key={idx}
                            //       onClick={() =>
                            //         this.changeSelectedModule(data, navMenu)
                            //       }
                            //     >
                            //       <div
                            //         className={
                            //           ((navMenu.name ===
                            //             this.state.selectedMobileSubMenu) || (
                            //               data.name + "-" + navMenu.name === this.state.selectedMobileSubMenu))
                            //             ? "nav-item-menu active"
                            //             : "nav-item-menu"
                            //         }
                            //       >
                            //         {navMenu.name}
                            //       </div>
                            //     </Link>
                            //   );
                            // }
                          }
                        )}
                      </div>
                    ))}
                </div>
              ))}
            </div>
          )}
        </div>
      </>
    );
  }
}