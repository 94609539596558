import { GridOptions } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import React, { useEffect, useMemo, useState, useRef } from 'react';
import { DropDownList } from '../Reports/common-components/dropdown-list';
import { PeriodSelector } from './Components/PeriodSelector';
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { ReactPageLoader } from '../../Common/Components/ReactPageLoader';
import Axios from 'axios';
import Instense from '../../Common/Services/Axios-config';
import { Breadcrumb, Button, Dropdown, Form, FormLabel } from 'react-bootstrap';
import { LaborDashBoard } from '../../Common/Services/LaborDashBoard';
import { Utils } from '../../Common/Utilis';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from 'moment';
import _ from "lodash"
import LaborCustomHeader from './LaborCustomHeader';
import HouseKeepingSlideOut from "../LaborManagement/Labor/HouseKeeping/HouseKeepingSlideOut";
import laborIcon from "../../Common/Assets/Images/icons/labor-icon-2.svg";
import profile from "../../Common/Assets/Images/profile.png";
import { LaborHouseKeepingDashboardChart } from './LaborHouseKeepingDashboardChart';
import { SortSelector } from './Components/SortSelector';
import OutsideClickHandler from 'react-outside-click-handler';
import ContentLoader from 'react-content-loader';
import { toast } from "react-toastify";
import { FiChevronDown } from 'react-icons/fi';
import { HotelGroupSelector } from './Components/HotelGroupSelector';
import { TableColumnSelector } from './Components/Common-Components/TableColumnSelector';

const MAXLIMIT = 10
//Dropdown Values
const dataComparisonList: any[] = [
    { label: "Last Year Variance", value: 1, type: 1 },
    { label: "Budget Variance", value: 2, type: 1 },
];

const sortDropdDownData: any[] = [
    { label: "Column Sort", value: "Column Sort" },
    { label: "Sort by Variance", value: "Variance" },
    { label: "Sort by Value", value: "Value" },
];

const getPeriods = (date = new Date()) => {
    return [
        { label: `Daily (${moment(date).format('MM/DD')})`, value: 1 },
        { label: `Last 7 Days (${moment(date).subtract(6, "days").format('MM/DD')} - ${moment(date).format('MM/DD')})`, value: 2 },
        { label: `Last 14 Days (${moment(date).subtract(13, "days").format('MM/DD')} - ${moment(date).format('MM/DD')})`, value: 3 },
        { label: `Pay Period to Date`, value: 4 },
        { label: `Month To Date (${moment(date).startOf('month').format('MM/DD')} - ${moment(date).format('MM/DD')})`, value: 5 },
        { label: `Year To Date  (${moment(date).startOf('year').format('MM/DD')} - ${moment(date).format('MM/DD')})`, value: 6 },
        { label: "Trailing 12 Months", value: 7 },
    ];
}

const BUDGETKYES = {
    "hskTotalCleanTime": "hskBudgetTotalCleanTime",
    "hskmpcr": "hskBudgetMPCR",
    "totalCleanTime": "totalCleanTimeBudget",
    "mpcr": "mpcrBudget",
}
const numberComparator = (aVal, bVal, isInverted) => {
    if (aVal == bVal) {
        return 0;
    }
    if (isInverted == true) {
        if (_.isNil(aVal) || _.isNaN(aVal)) return -1;
        if (_.isNil(bVal) || _.isNaN(bVal)) return 1;
    } else {
        if (_.isNil(aVal) || _.isNaN(aVal)) return 1;
        if (_.isNil(bVal) || _.isNaN(bVal)) return -1;
    }
    return aVal < bVal ? -1 : 1;
}

const NumberComparatorValue = (aVal, bVal, nodeA, nodeB, isInverted, columnName) => {
    aVal = parseFloat(nodeA.data[columnName])
    bVal = parseFloat(nodeB.data[columnName])
    const result = numberComparator(aVal, bVal, isInverted)
    return result
};


const NumberComparatorVariance = (aVal, bVal, nodeA, nodeB, isInverted, columnName, varianceValue) => {
    aVal = parseFloat(nodeA.data[columnName])
    bVal = parseFloat(nodeB.data[columnName])

    let aVal_Variance = parseFloat(nodeA.data[columnName + "_diff"] || 0)
    let bVal_Variance = parseFloat(nodeB.data[columnName + "_diff"] || 0)

    let aVal_diff, bVal_diff
    if ("roomOccupiedActual" === columnName) {
        let diff_key = varianceValue == 2 ? "roomOccupiedBudget" : "roomOccupiedLastYear"
        aVal_Variance = nodeA.data[diff_key] || 0
        bVal_Variance = nodeB.data[diff_key] || 0
    }
    aVal_diff = (aVal - aVal_Variance)
    bVal_diff = (bVal - bVal_Variance)

    const result = numberComparator(aVal_diff, bVal_diff, isInverted)
    return result
};



const HHMMComparatorValue = (aVal, bVal, nodeA, nodeB, isInverted, columnName) => {
    aVal = nodeA.data[columnName] || 0
    bVal = nodeB.data[columnName] || 0

    // if(columnName == "mpcr"){

    //     aVal=convertToMMSS((aVal  || 0)?.toString())
    //     bVal=convertToMMSS((bVal  || 0)?.toString())

    // }

    aVal = aVal == 0 ? aVal : aVal?.toString()?.replaceAll(":", ".")?.replaceAll(",", "")
    bVal = bVal == 0 ? bVal : bVal?.toString()?.replaceAll(":", ".")?.replaceAll(",", "")


    let aValMinutes = Utils.getMinutesFromHHMMDOT(Number(aVal).toFixed(2)?.toString());
    let bValMinutes = Utils.getMinutesFromHHMMDOT(Number(bVal).toFixed(2)?.toString());



    const result = numberComparator(aValMinutes, bValMinutes, isInverted)
    return result
};


const HHMMComparatorVariance = (aVal, bVal, nodeA, nodeB, isInverted, columnName, varianceValue) => {
    const isTimeHouseKeeper = ["totalCleanTime", "mpcr"].includes(columnName)

    aVal = nodeA.data[columnName] || 0
    bVal = nodeB.data[columnName] || 0

    let aVal_Variance = nodeA.data[columnName + "_diff"] || 0
    let bVal_Variance = nodeB.data[columnName + "_diff"] || 0


    if (isTimeHouseKeeper) {

        if (varianceValue === 2) {
            const budgetKey = BUDGETKYES[columnName] || columnName
            aVal_Variance = nodeA.data[budgetKey] || 0
            bVal_Variance = nodeB.data[budgetKey] || 0
        }

    }

    // if(columnName == "mpcr"){

    //     aVal=convertToMMSS((aVal  || 0)?.toString())
    //     aVal=convertToMMSS((aVal  || 0)?.toString())

    //     aVal_Variance=convertToMMSS((aVal_Variance  || 0)?.toString())
    //     bVal_Variance=convertToMMSS((bVal_Variance  || 0)?.toString())

    // }

    aVal = aVal == 0 ? aVal : aVal?.toString()?.replaceAll(":", ".")?.replaceAll(",", "")
    bVal = bVal == 0 ? bVal : bVal?.toString()?.replaceAll(":", ".")?.replaceAll(",", "")

    aVal_Variance = aVal_Variance == 0 ? aVal_Variance : aVal_Variance?.toString()?.replaceAll(":", ".")?.replaceAll(",", "")
    bVal_Variance = bVal_Variance == 0 ? bVal_Variance : bVal_Variance?.toString()?.replaceAll(":", ".")?.replaceAll(",", "")


    let aValMinutes = Utils.getMinutesFromHHMMDOT(Number(aVal).toFixed(2)?.toString());
    let bValMinutes = Utils.getMinutesFromHHMMDOT(Number(bVal).toFixed(2)?.toString());

    let aValVarianceMinutes = Utils.getMinutesFromHHMMDOT(Number(aVal_Variance).toFixed(2)?.toString());
    let bValVarianceMinutes = Utils.getMinutesFromHHMMDOT(Number(bVal_Variance).toFixed(2)?.toString());


    let aVal_diff = aValMinutes - aValVarianceMinutes
    let bVal_diff = bValMinutes - bValVarianceMinutes

    const result = numberComparator(aVal_diff, bVal_diff, isInverted)
    return result
};



const StringComparator = (valueA, valueB, nodeA, nodeB, isDescending) => {
    if (valueA?.toLowerCase() == valueB?.toLowerCase()) return 0;
    return (valueA?.toLowerCase()  > valueB?.toLowerCase()) ? 1 : -1;
  }




// const _columnDefs = columnDefs.map((item, index) => {
//     item.order = index;
//     return item;
// })

let RenameKeys = (list, keysList = ['roomsOccupied', 'hskRoomsCleaned', 'hskTotalCleanTime', 'hskmpcr', 'valueActual']) => {

    const newList = list.map(item => {
        if (item.valueActual == "LastYearVar") {
            const newItem = Object.keys(item).map(key => {
                const newKey = !keysList.includes(key) ? key : key + "_diff";
                return { [newKey]: item[key] };
            }
            )
            return Object.assign({}, ...newItem);

        }

        else if (item.valueActual == "BudgetVar") {
            const newItem = Object.keys(item).map(key => {
                const newKey = !keysList.includes(key) ? key : key + "_diff";
                return { [newKey]: item[key] };
            }
            )
            return Object.assign({}, ...newItem);

        }
        return item
    })

    return newList
}


function convertToMMSS(time) {
    var parts = time.split(':');
    var hours = parseInt(parts[0]);
    var minutes = parseInt(parts[1]);
    var seconds = parseInt(parts[2]);

    var totalSeconds = (hours * 3600) + (minutes * 60) + seconds;

    var formattedMinutes = String(Math.floor(totalSeconds / 60)).padStart(2, '0');
    var formattedSeconds = String(totalSeconds % 60).padStart(2, '0');

    return formattedMinutes + '.' + formattedSeconds;
}

const keyMapper = {
    "roomOccupiedActual": "roomsOccupied",
    "roomClean": "hskRoomsCleaned",
    "totalCleanTime": "hskTotalCleanTime",
    "mpcr": "hskmpcr",
    "status": "status",
    "notes": "notes",
}

const keyMapperEHIDToUser = {
    "roomsOccupied": "roomOccupiedActual",
    "hskRoomsCleaned": "roomClean",
    "hskTotalCleanTime": "totalCleanTime",
    "hskmpcr": "mpcr",
    "status": "status",
    "notes": "notes",
}



const hoverOutEllipsis = () => {
    $(".tooltip")?.removeClass("show");
    $(".tooltip")?.removeClass("fade");
}

function convertToHHMMSS(time) {
    var splitTime = time.split(".");
    var minutes = parseInt(splitTime[0] || 0);
    var seconds = parseInt(splitTime[1] || 0);

    var hours = Math.floor(minutes / 60);
    minutes = minutes % 60;

    var formattedTime = hours.toString().padStart(2, '0') + ":" +
        minutes.toString().padStart(2, '0') + ":" +
        seconds.toString().padStart(2, '0');

    return formattedTime;
}


const getFormattedTableData = (data, groupedBy = "hotelid", orderBy = "lettercode") => {

    //Grouped data  with respect to groupedBy
    data = data.map((item) => {

        item.roomsOccupied = Number(item.roomsOccupied || 0);
        item.hskRoomsCleaned = Number(item.hskRoomsCleaned || 0);
        item.hskTotalCleanTime = Number(item.hskTotalCleanTime || 0);
        item.hskmpcr = Number(item.hskmpcr || 0);


        return item;

    })
    const groupedData = _.groupBy(data, v => `"${v[groupedBy]}+${v.valueActual}"`)

    let newData = Object.keys(groupedData).map(item => sumOfArray(groupedData[item]))

    newData = RenameKeys(newData)

    newData = _.groupBy(newData, v => v[groupedBy])

    newData = Object.keys(newData).map(key => Object.assign({}, ...newData[key]));

    newData = newData.filter(item => item[groupedBy])

    newData.sort(function (a, b) {
        if (a[orderBy].toLowerCase() < b[orderBy].toLowerCase()) {
            return -1;
        }
        if (a[orderBy].toLowerCase() > b[orderBy].toLowerCase()) {
            return 1;
        }
        return 0;
    });

    console.log({ newData })
    return newData
}


const sumOfArrayHouseKeeper = (list, calendarDate) => {
    if (!list || list?.length == 0) {
        return {}
    }


    let result = list.reduce((acc, curr) => {
        if (acc === null) {
            return curr
        }
        else {

            return {
                ...curr,

                roomOccupiedActual: Number(curr?.roomOccupiedActual),

            }
        }
    }, null)


    const subGroupData = _.groupBy(list.filter(x => x.user_UniqueID === result?.user_UniqueID), "date")
    let selectedDateData = list.find(x => moment(x.date).format("MM/DD/YY") === moment(calendarDate).format("MM/DD/YY"))
    result.roomOccupiedActual = selectedDateData?.roomOccupiedActual || 0
    result.roomOccupiedBudget = selectedDateData?.roomOccupiedBudget || 0


    // result.roomOccupiedActual  = Object.keys(subGroupData).map(x=> subGroupData[x][0].roomOccupiedActual).reduce((a,b)=>a+b)?.toFixed(0)
    result.roomClean = Object.keys(subGroupData).map(x => subGroupData[x][0].roomClean).reduce((a, b) => a + b)?.toFixed(0)



    // Calculate the total sum in minutes
    const _time = list.reduce((sum, obj) => {
        const minutes = Utils.getMinutesFromHHMMDOT(Number(obj.totalCleanTime || 0).toFixed(2)?.toString());
        return sum + minutes;
    }, 0);

    // Convert the total sum back to HH:MM format
    const totalCleanTime = Utils.getMinutesInHHMMFormat(Number(_time || 0).toFixed(2).toString());
    result.totalCleanTime = totalCleanTime

    // result.totalCleanTime=result?.totalCleanTime || 0
    result.mpcr = result?.mpcr || 0


    return result
}

function mergeArrays(array1: Array<any>, array2: Array<any>, groupedBy): Array<any> {
    const mergedArray: Array<any>[] = [...array1];

    array2.forEach((obj2) => {
        const matchingObj1 = array1.find((obj1) => obj1[groupedBy] === obj2[groupedBy]);

        if (matchingObj1) {
            mergedArray.push(obj2);
        }
    });

    return mergedArray;
}

const getFormattedHousekeeprTableData = (data, groupedBy = "user_UniqueID", orderBy = "userName", varianceValue, calendarDate) => {

    //Grouped data  with respect to groupedBy
    data = data.map((item) => {

        item.roomOccupiedActual = Number(item.roomOccupiedActual || 0);
        item.roomClean = Number(item.roomClean || 0);
        item.totalCleanTime = item.totalCleanTime || 0;
        item.mpcr = item.mpcr || 0;


        return item;

    })
    const groupedData = _.groupBy(data, v => `"${v[groupedBy]}+${v.valueActual}"`)

    // let newData=Object.keys(groupedData).map(item => sumOfArrayHouseKeeper(groupedData[item],calendarDate))
    // const firstnonZero=newData.find(x => x?.roomOccupiedActual)
    // if(firstnonZero)
    // {

    //     newData=newData.map(obj => ({...obj,roomOccupiedActual:firstnonZero.roomOccupiedActual, roomOccupiedBudget : firstnonZero.roomOccupiedBudget}))
    // }

    let newData = data
    if (varianceValue == 1) {
        console.log({ newData })
        const actual = newData.filter(item => item.valueActual == "Actual")
        const lastYr = newData.filter(item => item.valueActual == "LastYearVar")

        newData = mergeArrays(actual, lastYr, groupedBy)

        console.log(newData)
    }



    newData = RenameKeys(newData, ["roomOccupiedActual", "roomClean", "totalCleanTime", "mpcr", "valueActual"])

    newData = _.groupBy(newData, v => v[groupedBy])

    // newData = Object.keys(newData).map(key => Object.assign({}, ...newData[key]));
    newData = Object.keys(newData).map(key => {

        if (newData[key].length == 2) {
            newData[key][1].roomOccupiedLastYear = newData[key][0].roomOccupiedLastYear
        }

        return Object.assign({}, ...newData[key])
    });

    newData = newData.filter(item => item[groupedBy])

    newData.sort(function (a, b) {
        if (a[orderBy].toLowerCase() < b[orderBy].toLowerCase()) {
            return -1;
        }
        if (a[orderBy].toLowerCase() > b[orderBy].toLowerCase()) {
            return 1;
        }
        return 0;
    });

    console.log({ newData })
    return newData
}



// const getDashboardData=(params:any)=>{
//   let storage = JSON.parse(localStorage.getItem("storage")!);
//   let tenantID = storage === null ? 0 : storage.tenantID as any;
//   params.TenantID = tenantID;
//   const DashboardCancelToken=Axios.CancelToken.source(); 

//   const url = "/Dashboard/V2_Accounting_Dashboards_TopLevel"

//   return Instense.get(url,{params}).then((response) => {
//     let result = response.data.result ;
//     return result;
//   });
// }

const sumOfArray = (list) => {
    if (!list || list?.length == 0) {
        return {}
    }


    let result = list.reduce((acc, curr) => {
        if (acc === null) {
            return curr
        }
        else {
            // const calculateTotalHrs= Utils.getMinutesFromHHMMDOT(acc?.totalHours || 0) + Utils.getMinutesFromHHMMDOT(curr?.totalHours || 0)
            return {
                ...curr,
                // totalHours: Number(Utils.getMinutesInHHMMFormatDot(calculateTotalHrs) || 0),
                // totalHours: Number((Number(acc?.totalHours) + Number(curr?.totalHours)) || 0),
                // totalWages: Number((Number(acc?.totalWages) + Number(curr?.totalWages)) || 0)?.toFixed(2),
                // cpor: Number((Number(acc?.cpor) + Number(curr?.cpor)) || 0),
                // "laborCostas%ofRevenue": Number((Number(acc["laborCostas%ofRevenue"]) + Number(curr["laborCostas%ofRevenue"])) || 0),
                roomsOccupied: Number(curr?.roomsOccupied),
                hskmpcr: Number(curr.hskmpcr || 0),
                hskTotalCleanTime: Number(curr.hskTotalCleanTime || 0),
                "hskRoomsCleaned": Number(curr["hskRoomsCleaned"]),
            }
        }
    }, null)

    // Object.keys(temp1).map(x=> temp1[x][0].roomsOccupied).reduce((a,b)=>a+b)
    // _.groupBy(list.filter(x=>x.hotelid ===742),"datadate")


    // const subGroupData =   _.groupBy(list.filter(x=>x.hotelid ===result?.hotelid),"datadate")
    // result.roomsOccupied  = Object.keys(subGroupData).map(x=> subGroupData[x][0].roomsOccupied).reduce((a,b)=>a+b)?.toFixed(0)
    // result.hskRoomsCleaned  = Object.keys(subGroupData).map(x=> subGroupData[x][0].hskRoomsCleaned).reduce((a,b)=>a+b)?.toFixed(0)
    // result.hskRoomsCleaned  = Object.keys(subGroupData).map(x=> subGroupData[x][0].hskRoomsCleaned).reduce((a,b)=>a+b)?.toFixed(0)

    // const roomCount =  Object.keys(subGroupData).map(x=> Number(subGroupData[x][0].roomCount || 0)).reduce((a,b)=>a+b)


    // result["occupied%"]  = (Number(result.roomsOccupied|| 0) === 0 || roomCount == 0) ? 0 : ((Number(result.roomsOccupied|| 0)/ roomCount) * 100)?.toFixed(2);
    // result.hskTotalCleanTime= Utils.getMinutesInHHMMFormat(Utils.getMinutesFromHHMMDOT(result?.hskTotalCleanTime?.toFixed(2)?.toString() || 0))
    // result.hskmpcr= Utils.getMinutesInHHMMFormat(Utils.getMinutesFromHHMMDOT(result?.hskmpcr?.toFixed(2)?.toString() || 0))
    // result.cpor= (result.roomsOccupied != 0 || isNaN(result.roomsOccupied) ? Number(Number(result.totalWages)/Number(result.roomsOccupied) || 0): 0.00)?.toFixed(2);
    // result["laborCostas%ofRevenue"]= (result.roomRevenue  != 0 || isNaN(result.roomRevenue) ? Number(Number(result.totalWages)/Number(result.roomRevenue) || 0): 0.00)?.toFixed(2);

    // Calculate the total sum in minutes
    // let totalSeconds = list.reduce((sum, obj) => {
    // const minutes = Utils.getMinutesFromHHMMDOT(Number(obj.hskmpcr || 0).toFixed(2)?.toString());
    // return sum + minutes;
    // }, 0);

    // totalSeconds=Number(totalSeconds/list.length).toFixed(0)
    // // Convert the total sum back to HH:MM format
    // const hskmpcr = Utils.getMinutesInHHMMFormat(Number(totalSeconds || 0).toFixed(2).toString());
    // result.hskmpcr=hskmpcr

    // const totalMinuteshskTotalCleanTime = list.reduce((sum, obj) => {
    //   const minutes = Utils.getMinutesFromHHMMDOT(Number(obj.hskTotalCleanTime || 0).toFixed(2)?.toString());
    //   return sum + minutes;
    //   }, 0);

    // Convert the total sum back to HH:MM format
    // const hskTotalCleanTime = Utils.getMinutesInHHMMFormat(Number(totalMinuteshskTotalCleanTime || 0).toFixed(2).toString());
    // result.hskTotalCleanTime=hskTotalCleanTime

    return result
}



const getNewValuesWithOldHighligedValue = (oldData, newData, key = "hotelid") => {
    const newArray = newData.map(newItem => {
        const oldDatawithKey = oldData.find(item => item[key] == newItem[key])

        if (oldDatawithKey) {
            return {
                ...newItem,
                highlightedColumnValue: oldDatawithKey?.highlightedColumnValue,
                manuallyChartActive: oldDatawithKey?.manuallyChartActive
            }
        }
        else {
            return newItem
        }
    })

    return newArray
}


const rowClassRules = {
    'rag-total-row': 'data.userName == "Total"',

};

const findChangedData = (oldArray, newArray) => {
    const changedData: any[] = [];
    // let maxCount=20
    // let changesCount = 0
    _.takeWhile(newArray, newObj => {
        // if(changesCount >= maxCount)
        // {
        //     return false
        // }
        const matchingOldObj = _.find(oldArray, { id: newObj.id });
        if (!_.isEqual(_.pick(matchingOldObj, ['id', 'highlightedColumnValue']), _.pick(newObj, ['id', 'highlightedColumnValue']))) {
            changedData.push(newObj);
            // changesCount++
        }

        return true
    });

    return changedData;
};

let gridApi, gridColumnApi



function LaborHouseKeepingDashboardTable({ hotelIds, moduleName, hotelTypes,featureIds, handleHotelChange, subGroupSelectValue, calendarDate, varianceValue, periodValue, handleSaveDefaultView, onChartPeriodChange, onComparisonPeriodChange, isDefaultSaveDataLoaded, houseKeepingSortingData, isChartLoadingMain, handleMainChartLoading, isCellClicked, handleIsCellClicked, columnSelectorData }) {
    const columnDefs: any[] = [
        {
            headerName: "Hotel Name",
            field: "hotelNameAbbr",
            colId: "hotelNameAbbr",
            // sortingOrder: ['asc', 'desc', null],
            //suppressMovable: true,
            lockPosition: true,
            hide: true,
            // checkboxSelection: true,
            // maxWidth: 100,
            // minWidth: 100,
            width: 100,
            flex: 1,
            //pinned: true,
            cellRenderer: "nameRender",
            tooltip: (params) => params?.data?.hotelName,
            headerComponentParams: { getLastSortedColumnDataFromHeader: getLastSortedColumnDataFromHeader },

        },
        {
            headerName: "EHID",
            field: "lettercode",
            colId: "lettercode",
            // sortingOrder: ['asc', 'desc',null],
            //suppressMovable: true,
            lockPosition: true,
            // checkboxSelection: true,
            // maxWidth: 250,
            // minWidth: 200,
            width: 150,
            pinned: true,
            cellRenderer: "nameRender",
            tooltip: (params) => params?.data?.hotelName,
            headerComponentParams: { getLastSortedColumnDataFromHeader: getLastSortedColumnDataFromHeader },


        },
        {
            headerName: "Occupied",
            field: "highlightedColumnValue",
            colId: "roomsOccupied",
            // field: "roomsOccupied",
            cellRenderer: "valueRender",


            // maxWidth: 200,
            // minWidth: 100,
            flex: 1,
            lockPosition: true,
            isChart: true,
            cellClass: 'cursorPointer',
            headerComponentParams: { getLastSortedColumnDataFromHeader: getLastSortedColumnDataFromHeader },
        },
        {
            headerName: "Rooms Cleaned",
            field: "highlightedColumnValue",
            colId: "hskRoomsCleaned",

            // field: "hskRoomsCleaned",
            cellRenderer: "valueRender",
            // maxWidth: 200,
            // minWidth: 100,
            flex: 1,
            cellClass: ["occupancy-per", "cursorPointer"],
            lockPosition: true,
            isChart: true,
            headerComponentParams: { getLastSortedColumnDataFromHeader: getLastSortedColumnDataFromHeader },
        },

        {
            headerName: "Total Clean Time",
            colId: "hskTotalCleanTime",
            field: "highlightedColumnValue",
            // field: "hskTotalCleanTime",
            cellRenderer: "valueRender",
            // maxWidth: 200,
            // minWidth: 100,
            flex: 1,
            cellClass: ["payroll-id", "cursorPointer"],
            lockPosition: true,
            isChart: true,
            headerComponentParams: { getLastSortedColumnDataFromHeader: getLastSortedColumnDataFromHeader },
        },

        {
            headerName: "MPCR",
            colId: "hskmpcr",
            field: "highlightedColumnValue",
            // field: "hskmpcr",
            cellRenderer: "valueRender",
            // maxWidth: 200,
            // minWidth: 100,
            flex: 1,
            cellClass: ["payroll-id", "cursorPointer"],
            lockPosition: true,
            isChart: true,
            headerComponentParams: { getLastSortedColumnDataFromHeader: getLastSortedColumnDataFromHeader },
        },
        {
            headerName: "Status",
            field: "status",
            colId: "status",
            // field: "mpcr",
            cellRenderer: "statusRender",
            // maxWidth: 200,
            // minWidth: 100,
            flex: 1,
            headerClass: ["text-center", "statusCol"],
            cellClass: ["payroll-id", "text-center"],
            lockPosition: true,

        },
        {
            headerName: "Notes",
            field: "notes",
            colId: "notes",
            headerClass: "notesCol",
            // field: "mpcr",
            cellRenderer: "noteRender",
            // maxWidth: 200,
            // minWidth: 100,
            flex: 1,
            cellClass: "payroll-id",
            lockPosition: true,

        },

        {
            headerName: "Occupied",
            field: "highlightedColumnValue",
            colId: "",
            // field: "roomsOccupied",
            cellRenderer: "checkBoxRender",
            headerClass: "checkBoxCol",
            maxWidth: 50,
            minWidth: 50,
            flex: 1,
            cellClass: "checkBox-header",
            type: 'rightAligned',
            headerComponentParams: { isIcon: true },
            lockPosition: true,
            isChart: true,

        },


    ];

    const houseKeepingColumnDefs: any[] = [
        {
            headerName: "Housekeeper",
            field: "userName",
            colId: "userName",
            // sortingOrder: ['asc', 'desc',null],
            //suppressMovable: true,
            lockPosition: true,
            // checkboxSelection: true,
            maxWidth: 250,
            minWidth: 220,
            pinned: true,
            cellRenderer: "nameRender",
            headerComponentParams: { getLastSortedColumnDataFromHeader: getLastSortedColumnDataFromHeader },



        },
        {
            headerName: "Occupied",
            field: "highlightedColumnValue",
            colId: "roomOccupiedActual",
            // field: "roomOccupiedActual",
            cellRenderer: "valueRender",

            // maxWidth: 200,
            // minWidth: 100,
            flex: 1,
            lockPosition: true,
            isChart: true,
            headerComponentParams: { getLastSortedColumnDataFromHeader: getLastSortedColumnDataFromHeader },
        },
        {
            headerName: "Rooms Cleaned",
            field: "highlightedColumnValue",
            colId: "roomClean",
            // field: "roomClean",
            cellRenderer: "valueRender",
            // maxWidth: 200,
            // minWidth: 100,
            flex: 1,
            lockPosition: true,
            isChart: true,
            headerComponentParams: { getLastSortedColumnDataFromHeader: getLastSortedColumnDataFromHeader },
        },

        {
            headerName: "Total Clean Time",
            field: "highlightedColumnValue",
            colId: "totalCleanTime",
            // field: "totalCleanTime",
            cellRenderer: "valueRender",
            // maxWidth: 200,
            // minWidth: 100,
            flex: 1,
            cellClass: "payroll-id",
            lockPosition: true,
            isChart: true,
            headerComponentParams: { getLastSortedColumnDataFromHeader: getLastSortedColumnDataFromHeader },
        },

        {
            headerName: "MPCR",
            field: "highlightedColumnValue",
            colId: "mpcr",
            // field: "mpcr",
            cellRenderer: "valueRender",
            // maxWidth: 200,
            // minWidth: 100,
            flex: 1,
            cellClass: "payroll-id",
            lockPosition: true,
            isChart: true,
            headerComponentParams: { getLastSortedColumnDataFromHeader: getLastSortedColumnDataFromHeader },
        },
        {
            headerName: "Status",
            field: "status",
            colId: "status",
            // field: "mpcr",
            cellRenderer: "statusRender",
            // maxWidth: 200,
            // minWidth: 100,
            flex: 1,
            headerClass: "statusCol",
            cellClass: ["payroll-id", "justify-content-center", "text-center", "status-cell"],
            lockPosition: true,

        },
        {
            headerName: "Notes",
            field: "notes",
            colId: "notes",
            // field: "mpcr",
            cellRenderer: "noteRender",
            // maxWidth: 200,
            // minWidth: 100,
            flex: 1,
            cellClass: ["payroll-id", "second-notes-column"],
            lockPosition: true,

        },
        // {
        //     headerName: "Occupied",
        //     field: "roomOccupiedActual",
        //     cellRenderer: "checkBoxRender",
        //     // maxWidth: 100,
        //     // minWidth: 100,
        //     flex:1,
        //     cellClass: "checkBox-header",
        //     type: 'rightAligned',
        //     headerComponentParams: { isIcon: true },
        //     lockPosition: true,
        // },
    ];

    const [toggleColumnData, setToggleColumnData] = useState<any[]>([])
    const [columnDropdownToggle, setColumnDropdownToggle] = useState(false);
    let [currentColumnSelected, setCurrentColumnSelected] = useState(6);

    const [maxColumnSelected, setMaxColumnSelected] = useState(7);
    const [isSelectAllCols, setIsSelectAllCols] = useState(false);

    const [gridColumns, setGridColumns] = useState(columnDefs)
    const [originTableData, setOriginTableData] = useState<any[]>([])
    const [filteredTableData, setFilteredTableData] = useState<any[]>([])
    const [levelWiseTableData, setLevelWiseTableData] = useState<any[]>([])
    const [tableData, setTableData] = useState<any[]>([])
    const [pathArray, setPathArray] = useState<any[]>([])
    const [showChart, setShowChart] = useState(false)
    const [slideOutData, setslideOutData] = useState({})

    const [isSlideOutShow, setIsSlideOutShow] = useState(false)
    const [isInitialDataDataLoading, setIsInitialDataDataLoading] = useState(true)
    const [lastClickedColumn, setLastClickedColumn] = useState("")
    const [Periods, setPeriods] = useState<any[]>([])
    const [selectedColumn, setSelectedColumn] = useState<any>("")
    const [selectedHids, setSelectedHids] = useState<any>("")
    const [selectedSorting, setSelectedSorting] = useState<any>("Variance")
    const [selectedTotalRow, setSelectedTotalRow] = useState(null)
    const [resetCalled, setResetCalled] = useState(false);
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const [tooltipContent, setTooltipContent] = useState<any>(null);
    const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
    const [lastSortedColumnData, setLastSortedColumnData] = useState<any>({
        columnName: "",
        sortingOrder: ""
    })
    const [isAbbrEHIDNameChecked, setIsAbbrEHIDNameChecked] = useState(false);

    const agGridRef = useRef<any>(null);

    useEffect(() => {
        //handle Filter Change
        resetDrillDown()
    }, [hotelIds])

    useEffect(() => {
        if (isDefaultSaveDataLoaded) {
            buildToggleColumnData();
        }

    }, [periodValue, gridColumns, isDefaultSaveDataLoaded])



    useEffect(() => {
        //handle Filter Change

        if (calendarDate && hotelIds != 0) {
            getDashbaordTableData()
        }
        else {
            setOriginTableData([])
            setTableData([])
            setFilteredTableData([])
            setLevelWiseTableData([])
            // setIsInitialDataDataLoading(false)
            setShowChart(false)
        }


    }, [varianceValue, periodValue, resetCalled, calendarDate])

    useEffect(() => {
        if (calendarDate) {
            const _periods = getPeriods(calendarDate)
            setPeriods(_periods)
        }
    }, [calendarDate])




    useEffect(() => {
        if (isDefaultSaveDataLoaded) {

            setLastSortedColumnData({ ...houseKeepingSortingData.lastSortedColumnData })
            setSelectedSorting(houseKeepingSortingData.selectedSorting || "Variance")
        }
    }, [isDefaultSaveDataLoaded, houseKeepingSortingData])



    const toggleColumnDropdownToggle = (value: boolean) => {
        setColumnDropdownToggle(value);
    };


    const buildToggleColumnData = () => {

        if (periodValue !== 1) {
            const statusIndex = gridColumns.findIndex(x => x.headerName === "Status");
            gridColumns[statusIndex].hide = true;
            const notesIndex = gridColumns.findIndex(x => x.headerName === "Notes");
            gridColumns[notesIndex].hide = true;
        }

        let _toggleColumnData = gridColumns
            .filter((item) => item?.colId)
            .map((item: any, index) => {
                let toggleColumnObject: any = {};
                toggleColumnObject.colDispalyName = item?.headerName;
                toggleColumnObject.colName = item?.colId;
                toggleColumnObject.checked = item?.hide ? false : true;
                toggleColumnObject.order = index;
                toggleColumnObject.disabled =
                    ((item?.field === "lettercode" || item?.field === "hotelNameAbbr" ||
                        item?.field === "userName") && !item?.hide) ? true : false;
                return toggleColumnObject;
            });
        if (columnSelectorData?.length > 0  && toggleColumnData.length==0) {


            _toggleColumnData = _toggleColumnData.map(x => {

                const data = columnSelectorData.find(l => l?.colName === x?.colName);
                if (data?.colName) {
                    x.checked = data.checked;
                    x.disabled = data?.disabled || false;
                }
                return x;
            });




        }

        if (toggleColumnData?.length > 0) {

            _toggleColumnData = _toggleColumnData.map(x => {

                const data = toggleColumnData.find(l => l?.colDispalyName === x?.colDispalyName);
                if (data?.colName) {
                    x.checked = data.checked;
                    x.disabled = data?.disabled || false;

                }
                return x;
            });




        }


        if (periodValue !== 1) {
            _toggleColumnData = _toggleColumnData.filter(x => x.colDispalyName !== "Status" && x.colDispalyName !== "Notes")
        }
        setToggleColumnData(_toggleColumnData);
        setCurrentColumnSelected(_toggleColumnData.filter((item) => item.checked)
            .length);
        setMaxColumnSelected(_toggleColumnData?.length);
        // this.setState({
        //   toggleColumnData,
        //   currentColumnSelected: toggleColumnData.filter((item) => item.checked)
        //     .length,
        //   maxColumnSelected: toggleColumnData?.length,
        // });
    };

    const toggleColumnCheck = (e: any, data: any, idx: any) => {
        let checked = e.target.checked;
        let _gridColumns = _.cloneDeep(gridColumns);
        for (let i = 0; i < _gridColumns.length; i++) {
            if (_gridColumns[i].colId === data.colName) {
                _gridColumns[i].hide = !checked;
                break;
            }
        }




        if (checked) {
            currentColumnSelected = currentColumnSelected + 1;
        } else {
            currentColumnSelected = currentColumnSelected - 1;
        }

        let _toggleColumnData = _.cloneDeep(toggleColumnData);

        _toggleColumnData[idx].checked = checked;
        let isSelectAllCols = false;
        if (
            _toggleColumnData?.filter((item) => item?.checked)?.length ===
            maxColumnSelected
        ) {
            isSelectAllCols = true;
        }

        const toggleIndex = _gridColumns.findIndex(x => x.colId === data.colName);
        if (toggleIndex < 2 && getDrillDownLevel() == 0) {
            if (toggleIndex === 0) {
                _toggleColumnData[0].disabled = true;
                _gridColumns[1].hide = true;
                _toggleColumnData[1].disabled = false;
                _toggleColumnData[1].checked = false;

            } else {
                _toggleColumnData[1].disabled = true;
                _gridColumns[0].hide = true;
                _toggleColumnData[0].disabled = false;
                _toggleColumnData[0].checked = false;

            }
        }




        if (_toggleColumnData?.filter(x => x.checked)?.length < 2 ||
            (_toggleColumnData?.filter(x => x?.colDispalyName !== "Status" && x?.colDispalyName !== "Notes" && x?.checked))?.length  ===1) {
            Utils.toastError("At least one chart data column should be selected", {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "laborDashBoard",
            });

            return;
        }

        if (_toggleColumnData?.filter(x => x.checked)?.length > 7) {
            Utils.toastError("You can select a maximum of 7 columns at one time", {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "laborDashBoard",
            });
            return;
        }

        setGridColumns(_gridColumns);
        sortingOnColumns(_gridColumns,false,_toggleColumnData)
        setCurrentColumnSelected(currentColumnSelected);
        setToggleColumnData(_toggleColumnData);
        setIsSelectAllCols(isSelectAllCols)


        if (!checked) {
            const tableData = getCurrentTableData()
            const _filteredTableData = tableData.map(item => {
                if (item?.highlightedColumnValue?.startsWith(_toggleColumnData[idx]?.colDispalyName)) {
                    item.highlightedColumnValue = "";
                }
                return item;
            })

            updateTableData(_filteredTableData)
        }

    };


    const getDrillDownLevel = () => {
        return pathArray.length == 0 ? 0 : pathArray.length - 1
    }

    const downloadHousekeepingDashbord = () => {

        //debugger

        const EXPORTSORTCOLUMNSValue = {
            "hotelNameAbbr": "hotelNameAbbr",
            "lettercode": "Lettercode",
            "userName": "UserName",
            "roomsOccupied": "RoomsOccupied",
            "hskRoomsCleaned": "HSKRoomsCleaned",
            "hskTotalCleanTime": "HSKTotalCleanTime",
            "hskmpcr": "HSKMPCR",


            "roomOccupiedActual": "RoomOccupiedActual",
            "roomClean": "RoomClean",
            "totalCleanTime": "TotalCleanTime",
            "mpcr": "MPCR",

        }

        const SelectedColumn = toggleColumnData.filter(x => x?.checked).map(x => EXPORTSORTCOLUMNSValue[x.colName])

        const EXPORTSORTCOLUMNSVariamce = {
            "hotelNameAbbr": "hotelNameAbbr",
            "lettercode": "Lettercode",
            "userName": "UserName",
            "roomsOccupied": "RoomsOccupied_Variance",
            "hskRoomsCleaned": "HSKRoomsCleaned_Variance",
            "hskTotalCleanTime": "HSKTotalCleanTime_Variance",
            "hskmpcr": "HSKMPCR_Variance",


            "roomOccupiedActual": "ROOMOCCUPIEDBUDGET",
            "roomClean": "RoomCleanVariance",
            "totalCleanTime": "TotalCleanTimeBudget",
            "mpcr": "MPCRBudget",
        }
        const level = getDrillDownLevel()
        let defaultColumnName = "Lettercode"
        if (level == 0) {
            defaultColumnName = "Lettercode"
        }
        else if (level == 1) {
            defaultColumnName = "UserName"
        }



        const enableSort = selectedSorting === "Value" || selectedSorting === "Variance"
        const EXPORTSORTCOLUMNS = enableSort ? selectedSorting === "Value" ? EXPORTSORTCOLUMNSValue : EXPORTSORTCOLUMNSVariamce : null
        const columnName = enableSort && EXPORTSORTCOLUMNS ? EXPORTSORTCOLUMNS[lastSortedColumnData?.columnName] : defaultColumnName
        const sortDirection = enableSort ? lastSortedColumnData.sortingOrder ? lastSortedColumnData.sortingOrder === "asc" ? "ASC" : "DESC" : "DESC" : "ASC"

        let request: any
        if (pathArray.length == 0) {
            request = {
                HID: pathArray.length == 0 ? hotelIds : pathArray[1].id,
                DataDate: calendarDate,
                DailyFlashType: varianceValue,
                TimePeriod: periodValue,
                ischart: 0,
                columnName: columnName,
                sortDirection: sortDirection,
                totalRow: null,
                showColumns: SelectedColumn


            }
        }
        else {
            request = {
                Hotelid: pathArray[1].id,
                SelectedDate: calendarDate,
                DailyFlashType: varianceValue.toString(),
                TimePeriod: periodValue,
                MPCR: selectedTotalRow ? selectedTotalRow["hskmpcr"] || 0 : 0,
                MPCRBudget: selectedTotalRow ? selectedTotalRow["hskmpcr_diff"] || 0 : 0,
                totalRow: selectedTotalRow,
                columnName: columnName,
                sortDirection: sortDirection,
                showColumns: SelectedColumn
            }
        }


        const fileName = `HousekeepingDashboard-${moment().format("YY-MMDD hh.mm.ss")}`;
        const HousekeepingDataDownload = pathArray.length == 0 ? LaborDashBoard.downloadHousekeepingDashboardData : LaborDashBoard.downloadHousekeeperLevelDashboardDataCalculation
        HousekeepingDataDownload(request, fileName).then(
            (res: any | null) => {

                toast.success(
                    `Housekeeping data downloaded Successfully`,
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        containerId: "laborDashBoard",
                    }
                );
            }
        ).catch((err) => {
            Utils.toastError(
                `Something Went Wrong, Please Try Again`,
                {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    containerId: "laborDashBoard",
                }
            );
        });
    }

    function sortingOnColumns(columns = gridColumns, isResetToFirstColumn = false,toggleData=toggleColumnData) {
        const _columns=getGridColums(columns,toggleData)
        const enableSort = selectedSorting === "Value" || selectedSorting === "Variance"
        const VisibleColumns= _columns.filter(x=>!x.hide).map(x=>x.colId)

        const isSort = (colId) => {
            let sort
            if (pathArray.length == 0) {
                sort = VisibleColumns.includes(colId) && colId === "lettercode"  && enableSort ? 'asc' : VisibleColumns.includes(colId) &&colId === "hotelNameAbbr" && enableSort ? "desc": null
            }
            else {
                sort = colId === "userName" && enableSort ? 'desc' : null

            }
            return sort
        }

        const cols = columns.map(item => {
            if (item.field === "notes" || item.field === "status" || item.cellRenderer === "checkBoxRender") {
                return {
                    ...item,
                    enableSorting: false,
                    sortable: false,
                    sort: null
                }
            }
            else if (varianceValue === 2 && selectedSorting === "Variance" && (item.colId === "hskRoomsCleaned" || item.colId === "roomClean")) {
                return {
                    ...item,
                    enableSorting: false,
                    sortable: false,
                    sort: null
                }
            }

            else {

                const numbersData = ["roomClean", "hskRoomsCleaned", "roomOccupiedActual", "roomsOccupied"]
                const timeData = ["totalCleanTime", "hskTotalCleanTime", "mpcr", "hskmpcr"]

                let comparator = StringComparator

                //For Value
                if (selectedSorting === "Value") {

                    if (numbersData.includes(item.colId)) {
                        comparator = (aVal, bVal, nodeA, nodeB, isInverted) => { return NumberComparatorValue(aVal, bVal, nodeA, nodeB, isInverted, item.colId) }
                    }
                    else if (timeData.includes(item.colId)) {
                        comparator = (aVal, bVal, nodeA, nodeB, isInverted) => { return HHMMComparatorValue(aVal, bVal, nodeA, nodeB, isInverted, item.colId) }

                    }
                }
                else if (selectedSorting === "Variance") {
                    if (numbersData.includes(item.colId)) {
                        comparator = (aVal, bVal, nodeA, nodeB, isInverted) => { return NumberComparatorVariance(aVal, bVal, nodeA, nodeB, isInverted, item.colId, varianceValue) }
                    }
                    else if (timeData.includes(item.colId)) {
                        comparator = (aVal, bVal, nodeA, nodeB, isInverted) => { return HHMMComparatorVariance(aVal, bVal, nodeA, nodeB, isInverted, item.colId, varianceValue) }

                    }
                }







                let sort = isSort(item.colId)


                if (lastSortedColumnData?.columnName && (lastSortedColumnData?.columnName !== "lettercode" &&  lastSortedColumnData?.columnName !== "hotelNameAbbr" && lastSortedColumnData?.columnName !== "userName")) {
                    sort = null
                    if (pathArray.length == 0 && (lastSortedColumnData?.columnName === item.colId || lastSortedColumnData?.columnName === keyMapperEHIDToUser[item.colId])) {
                        sort = lastSortedColumnData.sortingOrder
                    }
                    if (pathArray.length == 2 && (lastSortedColumnData?.columnName === item.colId || keyMapperEHIDToUser[lastSortedColumnData?.columnName] === item.colId)) {
                        sort = lastSortedColumnData.sortingOrder
                    }
                }


                return {
                    ...item,
                    comparator: comparator,
                    enableSorting: enableSort,
                    sortable: enableSort,
                    sort: sort

                }
            }
        })

        setGridColumns(cols)
        let sort = enableSort ? 'desc' : null
        if (agGridRef.current) {
            if (!isResetToFirstColumn) {
                const sortedColumn = cols.filter(x => !x.hide).find((x) => x.sort);

                if (sortedColumn) {

                    agGridRef.current!.columnApi.applyColumnState({
                        state: [{ colId: sortedColumn.colId, sort: sortedColumn.sort }]
                    });
                    setLastSortedColumnData({
                        columnName: sortedColumn.colId,
                        sortingOrder: sortedColumn.sort
                    })
                    return
                }
            }

             if (lastSortedColumnData?.columnName) {
                sort = null;
            }
            const colId=toggleColumnData.filter(x=>x.checked && x.disabled)[0]?.colName;
            if (pathArray.length == 0) {
                agGridRef.current!.columnApi.applyColumnState({
                    state: [{ colId: colId, sort: sort }]
                });

                setLastSortedColumnData({
                    columnName:colId,
                    sortingOrder: sort
                })
            }
            else {
                agGridRef.current!.columnApi.applyColumnState({
                    state: [{ colId: 'userName', sort: sort }]
                });

                setLastSortedColumnData({
                    columnName: "userName",
                    sortingOrder: sort
                })
            }
        }
        else {
            const sortedColumn = cols.filter(x => !x.hide).find((x) => x.sort);
            if (sortedColumn) {
                setLastSortedColumnData({
                    columnName: sortedColumn.colId,
                    sortingOrder: sortedColumn.sort
                })
                return
            }

            const colId=toggleColumnData.filter(x=>x.checked && x.disabled)[0]?.colName;
            if (pathArray.length == 0) {


                setLastSortedColumnData({
                    columnName: colId,
                    sortingOrder: sort
                })
            }
            else {


                setLastSortedColumnData({
                    columnName: "userName",
                    sortingOrder: sort
                })
            }
        }


    }

    useEffect(() => {
        sortingOnColumns()
    }, [selectedSorting, varianceValue, pathArray])

    const getDashbaordTableData = async () => {
        let params: any = pathArray.length == 0 ? {
            HID: hotelIds,
            DataDate: calendarDate,
            DailyFlashType: varianceValue,
            TimePeriod: periodValue
        } : {
            Hotelid: pathArray[1].id,
            SelectedDate: calendarDate,
            DailyFlashType: varianceValue,
            TimePeriod: periodValue
        }
        setIsInitialDataDataLoading(true)
        handleIsCellClicked(false)
        try {

            let data = pathArray.length === 2 ? await LaborDashBoard.getHouseKeepingUserDashboardData({ ...params }) : await LaborDashBoard.getHouseKeepingDashboardData(params)
            // data=data.filter(item => { 
            //     let d=moment(item.datadate).format("MM/DD/YY") 
            //     return moment(d).isSame(calendarDate)})
            if (pathArray.length == 0) {
                const formattedData = data && data.length > 0 ? getFormattedTableData([...data]) : [...data]
                setFilteredTableData((prev) => {
                    const oldData = _.cloneDeep(tableData)
                    let _formattedData = getNewValuesWithOldHighligedValue(oldData, formattedData)
                    _formattedData = updateShowChartToggle(_formattedData, true)
                    setTableData(_formattedData)
                    return [..._formattedData]
                })
                setLevelWiseTableData((prev) => {
                    const oldData = _.cloneDeep(prev)
                    let _formattedData = getNewValuesWithOldHighligedValue(oldData, formattedData)
                    // _formattedData=updateShowChartToggle(_formattedData)
                    return [_formattedData]
                })
                setOriginTableData([...data])
            }
            else if (pathArray.length === 2) {

                const formattedData = data && data.length > 0 ? getFormattedHousekeeprTableData([...data], "user_UniqueID", "userName", varianceValue, calendarDate) : [...data]

                // setGridColumns(houseKeepingColumnDefs)
                // sortingOnColumns(houseKeepingColumnDefs)


                setFilteredTableData((prev) => {
                    const oldData = _.cloneDeep(tableData)
                    let _formattedData = getNewValuesWithOldHighligedValue(oldData, formattedData, "user_UniqueID")
                    _formattedData = updateShowChartToggle(_formattedData, true)
                    setTableData(_formattedData)
                    return [..._formattedData]
                })




                const data0 = await LaborDashBoard.getHouseKeepingDashboardData({
                    HID: hotelIds,
                    DataDate: calendarDate,
                    DailyFlashType: varianceValue,
                    TimePeriod: periodValue
                })
                const formattedData0 = data0 && data0.length > 0 ? getFormattedTableData([...data0]) : [...data0]

                //Total Row 

                let totalRow = formattedData0.find(item => item.hotelid === pathArray[1].id)
                totalRow = { userName: "Total", ...totalRow }
                setSelectedTotalRow(totalRow)


                setLevelWiseTableData(prev => {
                    const levelData = [...prev]
                    levelData.splice(0, 0, formattedData0);
                    return levelData
                })
                setOriginTableData([...data])
            }






            setIsInitialDataDataLoading(false)

        }
        catch (error) {

            if ((error as any)?.message === "Cancel") {
                setIsInitialDataDataLoading(true)
            } else {
                Utils.toastError(
                    `Something Went Wrong, Please Try Again`,
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        containerId: "laborDashBoard",
                    }
                );
                setIsInitialDataDataLoading(false)
            }
        }


    }






    const onGridReady = (params: any) => {
        gridApi = params.api;
        gridColumnApi = params.columnApi;
        // columnWidthSizeToFit();


        const headerSelector = ".ag-header";
        const scrollSelector = ".ag-body-horizontal-scroll";

        const scrollContainerSelector = ".ag-body-horizontal-scroll-container";

        // get scrollbar elements
        const scrollElement: any = document.querySelector(scrollSelector);

        const scrollContainerElement: any = document.querySelector(
            scrollContainerSelector
        );


        let data = getCurrentTableData()
        if (data.length > 0 && pathArray.length != 0) {
            agGridRef.current!.api.setPinnedTopRowData([selectedTotalRow]);
        }

        // const enableSort=selectedSorting === "Value" ||selectedSorting === "Variance"

        // if(pathArray.length == 0){
        //     agGridRef.current!.columnApi.applyColumnState({
        //         state: [{ colId: 'lettercode', sort: 'asc' }]        
        //       });
        // }
        // else{
        //     agGridRef.current!.columnApi.applyColumnState({
        //         state: [{ colId: 'userName', sort: 'asc' }]        
        //       });
        // }


    };

    function getLastSortedColumnDataFromHeader(data) {
        const colId = data?.props?.column?.colId
        const order = data?.order

        setLastSortedColumnData({
            columnName: colId,
            sortingOrder: order
        })

    }

    const columnWidthSizeToFit = () => {

        gridApi?.sizeColumnsToFit();
    }


    const CheckBoxRender = React.memo((params: any) => {
        const [isLoading, setIsLoading] = useState(false)

        const handleColumnClick = (params, status) => {
            if (status) {
                let clickedHeaderName = params?.colDef?.headerName
                const currentData = getCurrentTableData()
                if (isLimitValidation(currentData, clickedHeaderName, true)) {
                    return
                }

            }


            setIsLoading(true)
            onColumnClick(params, status, true)
            setTimeout(() => {
                setIsLoading(false)
            }, 10)
        }

        if (isLoading) {
            return <ContentLoader viewBox="0 0 380 70" style={{ width: '100%' }}>
                {/* Only SVG shapes */}
                <rect x="0" y="0" rx="5" ry="5" width="50" height="70" />

            </ContentLoader>
        }

        return <Form.Check onChange={(e) =>
            // setTimeout(() => {
            handleColumnClick(params, e.target.checked)
            // }, 0)
        } type={"checkbox"}
            id={`default`}
            checked={params.data.highlightedColumnValue ? true : false}
            label={``} />
    })


    const getLevelKeys = (params) => {
        const cols = params?.columnApi?.columnController?.columnDefs
        if (cols?.length > 0) {
            const field = cols[0]?.field
            switch (cols[0]?.headerName) {
                case "EHID":
                    return {
                        level: 0,
                        field: "lettercode"
                    }
                case "Housekeeper":
                    return {
                        level: 1,
                        field: "user_UniqueID"
                    }
                default:
                    return {
                        level: 0,
                        field: "lettercode"
                    }

            }
        } else {
            return {
                level: 0,
                field: "lettercode"
            }
        }
    }

    const getCurrentTableData = () => {
        const currentData: any[] = []
        agGridRef?.current?.api?.forEachNode(node => {
            currentData.push(node?.data)
        })

        return currentData
    }

    const updateTableData = (data) => {
        console.log(data)
        if (data.length > 0) {

            const oldData = getCurrentTableData()
            const newData = findChangedData(oldData, data)
            agGridRef?.current?.api?.applyTransactionAsync({
                update: newData
            })

            setTableData(data)
            const column = getSelectedColumn(data)
            const selectedids = getSelectedHIds(data)
            setSelectedHids(selectedids)
            setSelectedColumn(column)
            // agGridRef.current.api.refreshCells({
            //   force: true
            // });


        }
    }


    const isLimitValidation = (data, clickedColumn, isFromCheckbox = false) => {


        const filterData = data.filter(x => x?.highlightedColumnValue)
        const column = getSelectedColumn(filterData)
        if ((column === clickedColumn || isFromCheckbox) && filterData.length === MAXLIMIT) {
            Utils.toastError(
                "Please limit chart selections to 10 properties.",
                {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    containerId: "laborDashBoard",
                }
            );

            return true
        }

        return false
    }


    const onColumnClick = React.useCallback((params, isChecked = true, isFromCheckbox = false) => {


        // setFilteredTableData(prev => {
        const prev = getCurrentTableData()
        let headerName = params?.colDef?.headerName

        // if(isChecked)
        // {   if(isLimitValidation(prev,headerName))
        //     {
        //         return 
        //     }

        // }
        handleIsCellClicked(true)

        const currentLevelData = getLevelKeys(params)
        if (prev?.filter(x => x.highlightedColumnValue)?.length == 0 && isFromCheckbox && agGridRef) {

            headerName = agGridRef?.current?.columnApi?.columnController?.columnDefs?.filter(x => !x.hide && x?.isChart)[0]?.headerName;
      
         }


        let firstColumnUniqueField = params?.data[currentLevelData.field]

        let highlightedColumnValue = `${headerName}_${currentLevelData.level}_${firstColumnUniqueField}`;



        let _filteredTableData = _.cloneDeep(prev)

        //When changing columns
        setLastClickedColumn(prevLastCLickedColumn => {
            if (isFromCheckbox) {

                if (prev?.filter(x => x.highlightedColumnValue)?.length == 0 && isFromCheckbox && agGridRef) {

                    prevLastCLickedColumn = headerName;
                }
                
                highlightedColumnValue = `${prevLastCLickedColumn !== "" ? prevLastCLickedColumn : headerName}_${currentLevelData.level}_${firstColumnUniqueField}`

                if (prevLastCLickedColumn !== "")
                    return prevLastCLickedColumn
                else
                    return headerName

            }
            else if (prevLastCLickedColumn !== "" && prevLastCLickedColumn !== headerName && !isFromCheckbox) {
                _filteredTableData = _filteredTableData.map(item => {

                    item.highlightedColumnValue = ""

                    return item
                })
                return headerName
            }

            return headerName
        })

        _filteredTableData = _filteredTableData.map(item => {
            if (isChecked) {

                if (item[currentLevelData.field] === params?.data[currentLevelData.field]) {
                    item.highlightedColumnValue = highlightedColumnValue
                }
                else {
                    item.highlightedColumnValue = item?.highlightedColumnValue || ""

                }

            }
            else {
                if (item[currentLevelData.field] === params?.data[currentLevelData.field]) {
                    item.highlightedColumnValue = ""
                }

            }



            return item
        })

        _filteredTableData = _filteredTableData.map(x => ({ ...x, manuallyChartActive: false }))
        _filteredTableData = updateShowChartToggle(_filteredTableData, true)

        setLevelWiseTableData(prev => {
            const levelData = [...prev]
            levelData[currentLevelData.level] = _filteredTableData
            return levelData
        })

        updateTableData(_filteredTableData)
        if (!isChecked) {
            if (_filteredTableData.filter(item => item.highlightedColumnValue).length == 0) {
                setIsInitialDataDataLoading(true)
                agGridRef?.current?.api?.refreshCells({
                    force: true
                });
                setIsInitialDataDataLoading(false)

            }

        }

        if (_filteredTableData.length > 20) {

            setTimeout(() => {
                handleIsCellClicked(false)
            }, 20)
        }
        else {
            handleIsCellClicked(false)
        }



        //     return _filteredTableData
        // })

        // setOriginTableData(prev => {

        //     const currentLevelData = getLevelKeys(params)

        //     let headerName = params?.colDef?.headerName
        //     let firstColumnUniqueField=params?.data[currentLevelData.field]

        //     let highlightedColumnValue = `${headerName}_${currentLevelData.level}_${firstColumnUniqueField}`;




        //     let _filteredTableData = _.cloneDeep(prev)

        //     _filteredTableData = _filteredTableData.map(item => {
        //         if (isChecked) {

        //             if (item[currentLevelData.field] === params?.data[currentLevelData.field]) {
        //                 item.highlightedColumnValue = highlightedColumnValue
        //             }
        //             else {
        //                 item.highlightedColumnValue = item?.highlightedColumnValue || ""

        //             }

        //         }
        //         else {
        //             if (item[currentLevelData.field] === params?.data[currentLevelData.field]) {
        //                 item.highlightedColumnValue = ""
        //             }

        //         }



        //         return item
        //     })



        //     _filteredTableData=_filteredTableData.map(x=>({...x,manuallyChartActive: false}))
        //     return _filteredTableData
        // })
    }, [])

    const handleVisualize = (isChecked, paths) => {
        setShowChart(prev => !prev)

        if (!isChecked) {
            setLastClickedColumn("")
        }

        // setFilteredTableData(prev => {
        const prev = getCurrentTableData()
        let _filteredTableData = _.cloneDeep(prev)

        _filteredTableData = _filteredTableData.map(item => ({ ...item, manuallyChartActive: isChecked, isChartActive: isChecked, highlightedColumnValue: "" }))

        setLevelWiseTableData(prev => {
            const levelData = [...prev]
            if (paths.length == 2) {
                levelData[1] = _filteredTableData
            }
            else if (paths.length == 0) {
                levelData[0] = _filteredTableData
            }

            return levelData
        })

        updateTableData(_filteredTableData)

        //     return _filteredTableData

        // })




    }
    const StatusRender = React.memo((params: any) => {
        const data = params?.data

        const status = data?.totalCount > 0 && data?.totalCount === data.approvedCount ? "Approved" : "Pending Approval"

        const status_class = data?.totalCount > 0 && data?.totalCount === data.approvedCount ? "green" : "orange"
        if ((data.userName === "Total" || !data.userName))
            return <div className={`status ${status_class || ""}`}>{status || ""}</div>

        else
            return <></>
    })
    // function noteRender(params: any){
    //     const data=params?.data


    //     return (<div className="notes-sec">
    //     {(data?.notes?.length>0 &&
    //       <Dropdown className="more-action notes-btn" alignRight>
    //           <Dropdown.Toggle
    //               className="btn-outline-primary  more"
    //               id="dropdown-more"
    //               >
    //               <div className="d-flex">
    //                   <svg
    //                   width="16"
    //                   height="16"
    //                   viewBox="0 0 16 16"
    //                   fill="none"
    //                   xmlns="http://www.w3.org/2000/svg"
    //                   >
    //                   <path d="M13.5556 2.4H11.3889V1H9.94444V2.4H7.05556V1H5.61111V2.4H3.44444C2.64783 2.4 2 3.0279 2 3.8V13.6C2 14.3721 2.64783 15 3.44444 15H13.5556C14.3522 15 15 14.3721 15 13.6V3.8C15 3.0279 14.3522 2.4 13.5556 2.4ZM3.44444 13.6V4.5H5.61111H7.05556H9.94444H11.3889H13.5556V3.8L13.557 13.6H3.44444Z" />
    //                   <path d="M4.6665 6H11.3332V7.33333H4.6665V6ZM4.6665 8.66667H7.99984V10H4.6665V8.66667Z" />
    //                   </svg>
    //                   {/* <span>5</span> */}
    //               </div>
    //           </Dropdown.Toggle>
    //           <Dropdown.Menu>                  
    //             <div className="notes-sec">                    
    //                 <div className="notes-list">                                   
    //                     <div className="comment-list">
    //                     <div className="d-flex profile-details">
    //                         <div className="profile-img">
    //                             <img
    //                                 src={data.noteimageUrl ==="" ?  profile : data.noteimageUrl}
    //                                 className="default"
    //                                 width="40"
    //                                 height="40"                                                                                            
    //                             />
    //                         </div>
    //                         <div>
    //                             <div className="main-ttl">{data.noteUser}</div>
    //                             <div className="sub-ttl">{Utils.getLocalDateTimeFromUtc(data.noteDate)}</div>
    //                         </div>
    //                     </div>
    //                         <ul>
    //                             <li>
    //                                 <div className="d-flex">
    //                                     <div className="detail-sec">
    //                                         <div className="comments">
    //                                         {data.notes}
    //                                         </div>
    //                                     </div>
    //                                 </div>
    //                             </li>
    //                         </ul>
    //                     </div>                                  
    //                 </div>
    //             </div>
    //           </Dropdown.Menu>
    //       </Dropdown>
    //    )}
    //     </div>)
    // }
    const handleShowTooltip = (e, rowData) => {


        // Update tooltip content and position, and show the tooltip
        // setTooltipContent(rowData);

        // setTooltipVisible(true);
    };
    const handleTooltipPosition = (event, rowData) => {

        const buttonRect = event.currentTarget.getBoundingClientRect();
        // Calculate the tooltip position based on the button's position
        const tooltipTop = buttonRect.top + buttonRect.height;
        const tooltipLeft = buttonRect.left;
        setTooltipPosition({ x: tooltipTop, y: tooltipLeft });
        setTooltipContent(rowData);

        setTooltipVisible(true);
    }

    const onOutsideClick = (e) => {
        setTooltipPosition({ x: 0, y: 0 });
        setTooltipContent(null);
        setTooltipVisible(false);
    }
    const removeTooltip = (event) => {

        if (event.deltaY != 0) {
            setTooltipPosition({ x: 0, y: 0 });
            setTooltipContent(null);
            setTooltipVisible(false);
        }

    }

    const NoteRender = React.memo((params: any) => {
        const data = params?.data


        return (<div className="notes-sec text-center">
            {(data?.notes?.length > 0 &&

                <Button
                    className="btn-outline-primary  more"
                    id="dropdown-more"
                    //   onClick={(e)=>handleShowTooltip(e,data)}
                    onClick={(e) => handleTooltipPosition(e, data)}
                >
                    <div className="d-flex">
                        <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M13.5556 2.4H11.3889V1H9.94444V2.4H7.05556V1H5.61111V2.4H3.44444C2.64783 2.4 2 3.0279 2 3.8V13.6C2 14.3721 2.64783 15 3.44444 15H13.5556C14.3522 15 15 14.3721 15 13.6V3.8C15 3.0279 14.3522 2.4 13.5556 2.4ZM3.44444 13.6V4.5H5.61111H7.05556H9.94444H11.3889H13.5556V3.8L13.557 13.6H3.44444Z" />
                            <path d="M4.6665 6H11.3332V7.33333H4.6665V6ZM4.6665 8.66667H7.99984V10H4.6665V8.66667Z" />
                        </svg>
                        {/* <span>5</span> */}
                    </div>
                </Button>
            )}
        </div>)
    })


    function TotalRowRender({ params }) {


        if (params?.colDef?.field === "userName") {
            return <EllipsisWithTooltip><span className='Total'>{params?.data?.userName}</span></EllipsisWithTooltip>
        }



        let field = params?.colDef?.colId
        field = keyMapper[field]

        const isRoundNumber = ["roomsOccupied", "roomOccupiedActual", "hskRoomsCleaned", "roomClean"].includes(field)
        const isTime = ["hskTotalCleanTime", "hskmpcr"].includes(field)
        const isPositiveGreen = ["roomsOccupied", "hskRoomsCleaned"].includes(field)

        const customField = field
        let value = params?.data[customField]
        let diff = params?.data?.[customField + "_diff"] || 0
        let isPositive

        if (isTime) {
            value = value == 0 ? value : value?.toString()?.replaceAll(":", ".")?.replaceAll(",", "")

            let _val = value == 0 ? value : value?.toString()?.replaceAll(":", ".")?.replaceAll(",", "")
            let _diff = diff == 0 ? diff : diff?.toString()?.replaceAll(":", ".")?.replaceAll(",", "")

            _val = Utils.getMinutesFromHHMMDOT(Number(_val || 0).toFixed(2)?.toString())
            _diff = Utils.getMinutesFromHHMMDOT(Number(_diff || 0).toFixed(2)?.toString())

            diff = (_val - _diff)
            isPositive = diff > 0
            diff = Utils.getMinutesInHHMMFormat(diff || 0)

            diff = isPositive || diff == "00:00" ? diff : `-${diff}`
            value = Utils.getMinutesInHHMMFormat(Number(Utils.getMinutesFromHHMMDOT(Number(value || 0).toFixed(2)?.toString()) || 0).toFixed(2).toString())
        }
        else {


            diff = (value - diff)
            isPositive = diff > 0
        }

        // value = isTime ? value :Number(value).toFixed(0) 
        // diff = isTime ? diff :Number(diff).toFixed(0) 


        if (isTime) {
            value = value
            diff = diff
        }
        else if (isRoundNumber) {
            value = Utils.currencyFormatWithOutdoller(Number(value).toFixed(0)).replace(".00", "")
            diff = Utils.currencyFormatWithOutdoller(Number(diff).toFixed(0)).replace(".00", "")
        }

        const valueFormatted = `${value}`
        const diffvalueFormatted = `${isPositive ? `+${diff}` : diff || "0"}`


        return (<div className='variance-neg-pos-value'>
            <div className='d-flex align-items-center'>
                <EllipsisWithTooltip>
                    <div className='data-sets d-flex align-items-center'>
                        <span className="value">{valueFormatted}</span>
                        {varianceValue === 2 && (field === "hskRoomsCleaned") ? <span></span> : <span className={isPositiveGreen ? (isPositive ? "show-pos-variance" : "show-neg-variance") : isPositive ? "show-neg-variance" : "show-pos-variance"}>{diffvalueFormatted}</span>}
                    </div>
                </EllipsisWithTooltip>
            </div>



        </div>)
    }
    const ValueRender = React.memo((params: any) => {
        const [isLoading, setIsLoading] = useState(false)



        if (params?.data.userName === "Total")
            return <TotalRowRender params={params} />

        let field = params?.colDef?.colId


        const isRoundNumber = ["roomsOccupied", "roomOccupiedActual", "hskRoomsCleaned", "roomClean"].includes(field)
        const isTime = ["hskTotalCleanTime", "hskmpcr", "totalCleanTime", "mpcr"].includes(field)
        const isTimeHouseKeeper = ["totalCleanTime", "mpcr"].includes(field)
        const isPositiveGreen = ["roomsOccupied", "roomOccupiedActual", "hskRoomsCleaned", "roomClean"].includes(field)

        const customField = field

        let value = params?.data[customField]
        let diff = params?.data?.[customField + "_diff"] || 0
        let isPositive

        if (isTime) {
            if (isTimeHouseKeeper) {
                value = value
                if (varianceValue === 2) {
                    const budgetKey = BUDGETKYES[customField] || customField
                    diff = params?.data[budgetKey] || 0
                }

            }
            else {
                value = value
                diff = diff
            }


            // if(field == "mpcr"){

            //     value=convertToMMSS((value  || 0)?.toString())
            //     diff=convertToMMSS((diff  || 0)?.toString())
            // }

            value = value == 0 ? value : value?.toString()?.replaceAll(":", ".")?.replaceAll(",", "")

            let _val = value == 0 ? value : value?.toString()?.replaceAll(":", ".")?.replaceAll(",", "")
            let _diff = diff == 0 ? diff : diff?.toString()?.replaceAll(":", ".")?.replaceAll(",", "")

            _val = Utils.getMinutesFromHHMMDOT(Number(_val || 0).toFixed(2)?.toString())
            _diff = Utils.getMinutesFromHHMMDOT(Number(_diff || 0).toFixed(2)?.toString())

            diff = (_val - _diff)
            isPositive = diff > 0
            diff = Utils.getMinutesInHHMMFormat(diff || 0)

            diff = isPositive || diff == "00:00" ? diff : `-${diff}`
            value = Utils.getMinutesInHHMMFormat(Number(Utils.getMinutesFromHHMMDOT(Number(value || 0).toFixed(2)?.toString()) || 0).toFixed(2).toString())



        }
        else {

            if ("roomOccupiedActual" === field) {
                let diff_key = varianceValue == 2 ? "roomOccupiedBudget" : "roomOccupiedLastYear"
                diff = params?.data?.[diff_key] || 0
            }
            diff = (value - diff)
            isPositive = diff > 0
        }

        // diff=value-diff
        // let isPositive = diff > 0


        // value = isTime ? value :Number(value).toFixed(0) 
        // diff = isTime ? diff :Number(diff).toFixed(0) 

        if (isTime) {
            value = value
            diff = diff
        }
        else if (isRoundNumber) {
            value = Utils.currencyFormatWithOutdoller(Number(value).toFixed(0)).replace(".00", "")
            diff = Utils.currencyFormatWithOutdoller(Number(diff).toFixed(0)).replace(".00", "")
        }

        const valueFormatted = `${value}`
        const diffvalueFormatted = `${isPositive ? `+${diff}` : diff || "0"}`


        const currentLevelData = getLevelKeys(params)
        let headerName = params?.colDef?.headerName
        let firstColumnUniqueField = params?.data[currentLevelData.field]
        const highlightedColumnValue = `${headerName}_${currentLevelData.level}_${firstColumnUniqueField}`;

        const handleColumnClick = (params, status = true) => {

            if (status) {
                const currentData = getCurrentTableData()
                if (isLimitValidation(currentData, headerName)) {
                    return
                }

            }

            setIsLoading(true)
            onColumnClick(params, status)
            setTimeout(() => {
                setIsLoading(false)
            }, 10)
        }

        if (isLoading) {
            return <ContentLoader viewBox="0 0 380 70" style={{ width: '100%' }}>
                {/* Only SVG shapes */}
                <rect x="0" y="0" rx="5" ry="5" width="300" height="70" />

            </ContentLoader>
        }

        if(currentLevelData.level != 0 && (field == "roomsOccupied" || field == "roomOccupiedActual") )
        {
            return <></>
        }


        return (
            <div className='variance-neg-pos-value'>
                <div onClick={() => (highlightedColumnValue === params.data.highlightedColumnValue || currentLevelData.level != 0) ? () => { } : handleColumnClick(params)} className='d-flex align-items-center'>

                    <EllipsisWithTooltip>
                        <div className='data-sets d-flex align-items-center'>
                            <span className="value">{valueFormatted}</span>
                            {varianceValue === 2 && (field === "hskRoomsCleaned" || field === "roomClean") ? <span></span> : <span className={isPositiveGreen ? (isPositive ? "show-pos-variance" : "show-neg-variance") : isPositive ? "show-neg-variance" : "show-pos-variance"}>{diffvalueFormatted}</span>}
                            {highlightedColumnValue === params.data.highlightedColumnValue ?
                                <span onClick={(event) => {
                                    event.stopPropagation();
                                    setTimeout(() => {
                                        handleColumnClick(params, false)
                                    }, 0)
                                }
                                } className='remove-highlight-icon'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <rect x="0.5" y="0.5" width="19" height="19" rx="2.5" fill="white" />
                                        <path d="M14.2425 4.34424L9.9995 8.58624L5.7575 4.34424L4.34351 5.75824L8.5855 10.0002L4.34351 14.2422L5.7575 15.6562L9.9995 11.4142L14.2425 15.6562L15.6565 14.2422L11.4145 10.0002L15.6565 5.75824L14.2425 4.34424Z" fill="#84888C" />
                                        <rect x="0.5" y="0.5" width="19" height="19" rx="2.5" stroke="#E8EAED" />
                                    </svg>
                                </span> : !params.data.isChartActive && currentLevelData.level == 0 ? <span className='hover-icon'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <rect x="0.5" y="0.5" width="19" height="19" rx="2.5" fill="white" />
                                        <path d="M11 12.414C11.0928 12.5069 11.203 12.5807 11.3243 12.631C11.4456 12.6813 11.5757 12.7072 11.707 12.7072C11.8383 12.7072 11.9684 12.6813 12.0897 12.631C12.211 12.5807 12.3212 12.5069 12.414 12.414L17.414 7.414L16 6L11.707 10.293L9.414 8C9.32121 7.90705 9.21101 7.83332 9.08969 7.78301C8.96838 7.73269 8.83833 7.7068 8.707 7.7068C8.57567 7.7068 8.44562 7.73269 8.32431 7.78301C8.20299 7.83332 8.09279 7.90705 8 8L3 13L4.414 14.414L8.707 10.121L11 12.414Z" fill="#2CAF92" />
                                        <rect x="0.5" y="0.5" width="19" height="19" rx="2.5" stroke="#E8EAED" />
                                    </svg>
                                </span> : <></>
                            }
                        </div>
                    </EllipsisWithTooltip>
                </div>


                {highlightedColumnValue === params.data.highlightedColumnValue ?
                    <div className='highlights'>
                        <div className='highlight-green'>
                            {/* Highlight */}
                        </div>
                    </div> :
                    params.data.isChartActive ? <div className='highlights'>
                        <div className='highlight-grey'>
                            {/* Highlight */}
                        </div>
                    </div> : <></>
                }
            </div>
        );
    })

    const handlePath = (value, tooltip = null, id) => {
        setPathArray(prev => {
            let _path = [...prev]
            let _tooltipValue = tooltip ? tooltip : value

            if (prev.length === 0) {
                _path = [{ name: 'Properties', level: 0, tooltip: "Properties" }, { name: value, level: 1, tooltip: _tooltipValue, id: id }]
            }
            return _path
        })
    }

    const getLastClickedColumn = (formattedData) => {

        const highlightedColumnValueList = formattedData.filter(item => item.highlightedColumnValue).map(item => item.highlightedColumnValue)

        const highlistedColumn = highlightedColumnValueList?.length > 0 ? highlightedColumnValueList[0]?.split("_")[0] : ""
        setLastClickedColumn(highlistedColumn)
    }


    const handlePreviousPath = (index) => {
        setIsInitialDataDataLoading(true)
        setPathArray(prev => {
            let _path = [...prev]

            //disable click on current level
            if (index == _path.length - 1)
                return _path

            //remove paths
            _path = _path.slice(0, index + 1)

            if (_path.length == 1) {
                //if only properties then don't show any thing
                _path = []
                if (isAbbrEHIDNameChecked) {
                    const index1 = columnDefs.findIndex(x => x.field === "lettercode");
                    if (index1 >= 0) {
                        columnDefs[index1].hide = true;
                    }
                    const index2 = columnDefs.findIndex(x => x.field === "hotelNameAbbr");
                    if (index2 >= 0) {
                        columnDefs[index2].hide = false;
                    }
                } else {

                    const index1 = columnDefs.findIndex(x => x.field === "lettercode");
                    if (index1 >= 0) {
                        columnDefs[index1].hide = false;
                    }
                    const index2 = columnDefs.findIndex(x => x.field === "hotelNameAbbr");
                    if (index2 >= 0) {
                        columnDefs[index2].hide = true;
                    }
                }
                setGridColumns(columnDefs)
                sortingOnColumns(columnDefs)
                setLevelWiseTableData(prev => {
                    const levelData = [...prev]

                    const _filteredTableData = updateShowChartToggle(levelData[0], true)
                    setFilteredTableData([..._filteredTableData])
                    setTableData(_filteredTableData)
                    getLastClickedColumn(_filteredTableData)

                    return [_filteredTableData]
                })
            }


            return _path
        })

        setTimeout(() => { setIsInitialDataDataLoading(false) }, 5)
        // setLastClickedColumn("")
    }

    const scrollToSelectedRow = (_filteredTableData) =>{
        const _tableData = agGridRef?.current ? agGridRef?.current?.api?.getRenderedNodes().map(x =>x.data) : _filteredTableData
        
        const selectedItem= _filteredTableData.find(item => item.highlightedColumnValue != "" && item.highlightedColumnValue != null && item.highlightedColumnValue != undefined)
      
        const index= _tableData.findIndex(item => item?.id == selectedItem?.id)
        const filteredHighlighted= _filteredTableData.filter(item => item.highlightedColumnValue != "" && item.highlightedColumnValue != null && item.highlightedColumnValue != undefined)
       
        if(filteredHighlighted.length == 1)
        {
    
          setTimeout(() => {
            gridApi?.ensureIndexVisible(index,null);
          }, 100);
        }
      }
    
    

    function resetDrillDown() {

        if (isAbbrEHIDNameChecked) {
            const index1 = columnDefs.findIndex(x => x.field === "lettercode");
            if (index1 >= 0) {
                columnDefs[index1].hide = true;
            }
            const index2 = columnDefs.findIndex(x => x.field === "hotelNameAbbr");
            if (index2 >= 0) {
                columnDefs[index2].hide = false;
            }
        } else {

            const index1 = columnDefs.findIndex(x => x.field === "lettercode");
            if (index1 >= 0) {
                columnDefs[index1].hide = false;
            }
            const index2 = columnDefs.findIndex(x => x.field === "hotelNameAbbr");
            if (index2 >= 0) {
                columnDefs[index2].hide = true;
            }
        }
        
        setIsAbbrEHIDNameChecked(false);

        setPathArray([])
        setGridColumns(columnDefs)
        sortingOnColumns(columnDefs)
        setFilteredTableData([])
        setLevelWiseTableData([])
        setTableData([])
        setLastClickedColumn("")
        setShowChart(false)
        setslideOutData({})
        setIsSlideOutShow(false)
        setSelectedTotalRow(null)
        if (agGridRef?.current) {
            agGridRef.current!.api.setPinnedTopRowData(null);
        }
        setResetCalled(prev => !prev)
    }

    const updateShowChartToggle = (_filteredTableData, isDefaultLoad = false) => {

        const isShowChart = _filteredTableData?.filter(x => x?.manuallyChartActive || x?.highlightedColumnValue).length > 0
        _filteredTableData = _filteredTableData.map(item => ({ ...item, isChartActive: isShowChart }))

        if (isDefaultLoad) {
            setShowChart(isShowChart);

        } else {
            // setFilteredTableData(prev =>{	
            const prev = getCurrentTableData()
            const _prevFilterTable = _.cloneDeep(prev)
            const previsShowChart = _prevFilterTable?.filter(x => x?.manuallyChartActive || x?.highlightedColumnValue).length > 0
            setShowChart(previsShowChart);

            // return prev	
            // })
        }


        if (!isShowChart) {
            setLastClickedColumn("")
        }

        scrollToSelectedRow(_filteredTableData)
        return _filteredTableData
    }

    const handleDrillDown = async (data, level) => {
        //Total Row 
        //write code to filter depeneding on column

        const previousDataTable = getCurrentTableData()
        setGridColumns(houseKeepingColumnDefs)
        sortingOnColumns(houseKeepingColumnDefs, true)

        // hide chart
        setShowChart(false)

        let _filteredData
        if (level === 0) {

            setIsAbbrEHIDNameChecked(agGridRef?.current?.columnApi?.columnController?.columnDefs?.filter(x => x?.field === "hotelNameAbbr" && !x?.hide).length === 1);
            let params: any = {
                Hotelid: data.hotelid,
                SelectedDate: calendarDate,
                DailyFlashType: varianceValue,
                TimePeriod: periodValue
            }

            try {
                setIsInitialDataDataLoading(true)
                let res = await LaborDashBoard.getHouseKeepingUserDashboardData(params)

                let _filteredData = res && res.length > 0 ? getFormattedHousekeeprTableData([...res], "user_UniqueID", "userName", varianceValue, calendarDate) : [...res]
                const totalRowData = previousDataTable.find(x => x.hotelid === data.hotelid)
                const totalRow = {
                    userName: "Total",
                    ...totalRowData
                }
                setSelectedTotalRow(totalRow)


                // setShowChart(prev =>{	
                //     if(prev){	
                //         setFilteredTableData(prev => {	
                //             const headerName=getSelectedColumn(prev)  || "Occupied"	
                //             _filteredData=_filteredData.map(item =>({...item, highlightedColumnValue:`${headerName}_${level+1}_${item.user_UniqueID}`}))	
                //             setLastClickedColumn(headerName)	
                //             return prev	
                //         })	

                //     }else{	
                //         setLastClickedColumn("")	
                //     }	
                //     return prev;	
                // })


                _filteredData = updateShowChartToggle(_filteredData)

                handlePath(data.lettercode, data.hotelName, data.hotelid)

                // if(_filteredData.length > 0){
                //     agGridRef?.current?.api?.applyTransaction({
                //       update:_filteredData
                //     })
                //   }
                // updateTableData(_filteredData)
                setFilteredTableData(_filteredData)
                setTableData(_filteredData)
                setLevelWiseTableData(prev => {
                    const levelData = [...prev]
                    levelData.splice(level + 1, 0, _filteredData);
                    return levelData
                })

                //hide chart
                // let filteredDataPrev=_.cloneDeep(prev)    
                // filteredDataPrev=filteredDataPrev.filter(item => item.hotelid === data?.hotelid) 
                // const headerName=  getSelectedColumn(filteredDataPrev)  
                // if(headerName){
                //     _filteredData=_filteredData.map(item =>({...item, highlightedColumnValue:`${headerName}_${level+1}_${item.user_UniqueID}`}))
                //     setLastClickedColumn(headerName)
                // }
                // else{
                //     setLastClickedColumn("")
                // }




                // setFilteredTableData([..._filteredData])

                // setLevelWiseTableData(prev => {
                //     const levelData = [...prev]
                //     levelData.splice(level + 1, 0, _filteredData);
                //     return levelData
                // })



                setIsInitialDataDataLoading(false)
            }
            catch (e) {
                console.log({ e })
            }


        }

        // hide chart

        setShowChart(false)









    }



    const handleHousekeeperSlideOut = (data, date = calendarDate) => {
        console.log({ data })

        const _positionSlideoutData = {
            hotelid: data?.hotelId || "",
            roomClean: data?.roomClean || "0",
            mpor: convertToHHMMSS(data?.mpcr.toString() || "0"),
            mpor1: convertToHHMMSS(data?.mpcrBudget.toString() || "0"),
            notes: data?.notes || "",
            totalBudgetinMin: Utils.getMinutesFromHHMM(data?.totalCleanTimeBudget || 0),
            totalActualinMin: Utils.getMinutesFromHHMM(data?.totalCleanTime || 0),
            total: Utils.getMinutesInHHMMFormat(Number(Utils.getMinutesFromHHMMDOT(Number(data?.totalCleanTime || 0).toFixed(2)?.toString()) || 0).toFixed(2).toString()),
            total1: Utils.getMinutesInHHMMFormat(Number(Utils.getMinutesFromHHMMDOT(Number(data?.totalCleanTimeBudget || 0).toFixed(2)?.toString()) || 0).toFixed(2).toString()),

            noteimageUrl: data?.noteimageUrl || "",
            noteUser: data?.noteUser || "",
            noteDate: data?.noteDate || "",
            // parentName:"Performance",
            date: date || "",
            userName: data?.userName || ""
        }

        setslideOutData(_positionSlideoutData)
        setIsSlideOutShow(true)
    }

    const onChangeMetricParameter = (item) => {
        // setFilteredTableData(prev => {

        const prev = getCurrentTableData()

        let headerName = item?.label
        setLastClickedColumn(headerName)

        let _filteredTableData = _.cloneDeep(prev)

        _filteredTableData = _filteredTableData.map(item => {
            if (item.highlightedColumnValue) {
                let highlightedcolValue = item.highlightedColumnValue
                highlightedcolValue = highlightedcolValue.split("_")
                highlightedcolValue[0] = headerName

                highlightedcolValue = highlightedcolValue.join("_")
                return {
                    ...item,
                    highlightedColumnValue: highlightedcolValue
                }
            }
            else {
                return item
            }
        })


        _filteredTableData = _filteredTableData.map(item => ({ ...item, isChartActive: _filteredTableData?.filter(x => x?.highlightedColumnValue).length > 0 }))
        updateTableData(_filteredTableData)

        //   return _filteredTableData
        // })
    }


    const updateTableSelectedData = (selectedHids, selectedHeaderName) => {
        // setFilteredTableData(prev => {
        setIsInitialDataDataLoading(true)
        const prev = getCurrentTableData()
        let _filteredTableData = _.cloneDeep(prev)
        let keyName = "";
        let highlightKey = "";
        let level = 0;

        if (pathArray?.length == 2) {
            keyName = "user_UniqueID"
            highlightKey = "user_UniqueID";
            level = 1;
        }
        else {
            keyName = "hotelid"
            highlightKey = "lettercode";
        }



        _filteredTableData = _filteredTableData.map((item, index) => {

            if (selectedHids?.includes(+item[keyName])) {
                let highlightedcolValue = `${selectedHeaderName}_${level}_${item[highlightKey]}`;
                if (!item.highlightedColumnValue) {
                    highlightedcolValue = highlightedcolValue
                }
                else {
                    highlightedcolValue = item.highlightedColumnValue
                }

                return {
                    ...item,
                    highlightedColumnValue: highlightedcolValue
                }
            }
            else {
                return { ...item, highlightedColumnValue: null }
            }
        })


        _filteredTableData = updateShowChartToggle(_filteredTableData, true)

        updateTableData(_filteredTableData)

        if (selectedHids.length === 0) {
            if (_filteredTableData.filter(item => item.highlightedColumnValue).length == 0) {
                agGridRef.current.api.refreshCells({
                    force: true
                });
            }

        }

        if (_filteredTableData.length > 20) {
            setFilteredTableData(_filteredTableData)
            setTimeout(() => {
                setIsInitialDataDataLoading(false)
            }, 20)
        }
        else {
            setIsInitialDataDataLoading(false)
        }
        //   return _filteredTableData
        // })
    }


    const NameRender = React.memo((params: any) => {
        if (params?.data.userName === "Total")
            return <TotalRowRender params={params} />

        let field = params?.colDef?.field
        const currentLevelData = getLevelKeys(params)

        return (
            <div className="user-profie"
                onClick={() => {
                    if (currentLevelData.level === 1) {
                        handleHousekeeperSlideOut(params?.data)
                    }
                    else {
                        handleDrillDown(params?.data, currentLevelData.level)
                    }


                }}>

                {
                    field !== "lettercode" && field !== "hotelNameAbbr" ?
                        params?.data?.imagePath ? <img
                            src={params?.data?.imagePath}
                            className="profileimg"
                            width="24"
                            height="24"
                            alt=""
                        /> : <svg
                            width="22"
                            height="22"
                            viewBox="0 0 40 40"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <circle cx="20" cy="20" r="20" fill="#E5F5FF" />
                            <path
                                d="M20.0013 20.2819C22.5548 20.2819 24.6422 18.1945 24.6422 15.6409C24.6422 13.0874 22.5548 11 20.0013 11C17.4477 11 15.3604 13.0874 15.3604 15.6409C15.3604 18.1945 17.4477 20.2819 20.0013 20.2819Z"
                                fill="#065A8C"
                            />
                            <path
                                d="M27.9658 23.9905C27.8442 23.6865 27.6821 23.4027 27.4997 23.1393C26.5674 21.7612 25.1286 20.8492 23.5073 20.6263C23.3047 20.606 23.0817 20.6465 22.9196 20.7681C22.0684 21.3964 21.0551 21.7206 20.0013 21.7206C18.9474 21.7206 17.9341 21.3964 17.0829 20.7681C16.9208 20.6465 16.6979 20.5857 16.4952 20.6263C14.8739 20.8492 13.4148 21.7612 12.5028 23.1393C12.3204 23.4027 12.1583 23.7067 12.0367 23.9905C11.9759 24.1121 11.9962 24.2539 12.057 24.3755C12.2191 24.6592 12.4217 24.943 12.6041 25.1862C12.8878 25.5713 13.1918 25.9157 13.5364 26.24C13.8201 26.5237 14.1444 26.7872 14.4686 27.0506C16.0696 28.2464 17.9949 28.8746 19.981 28.8746C21.9671 28.8746 23.8924 28.2463 25.4934 27.0506C25.8176 26.8075 26.1419 26.5237 26.4256 26.24C26.7499 25.9157 27.0741 25.5712 27.3579 25.1862C27.5605 24.9227 27.743 24.6592 27.9051 24.3755C28.0063 24.2539 28.0266 24.112 27.9658 23.9905Z"
                                fill="#065A8C"
                            />
                        </svg> : ""}
                {field === "lettercode" || field === "hotelNameAbbr" ?
                    <OverlayTrigger
                        placement="bottom"
                        defaultShow={false}
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                            <Tooltip id={"ehid-name"}>
                                {field === "lettercode" ? params?.data["hotelName"] : params?.data[field]}
                            </Tooltip>
                        }
                    >
                        <span className="ehid-name width-auto inline-block" onWheel={hoverOutEllipsis}>{params?.data[field]}</span>

                    </OverlayTrigger>
                    :
                    <EllipsisWithTooltip placement="bottom">
                        <span className="ehid-name  inline-block " onWheel={hoverOutEllipsis}>{params?.data[field]}</span>
                    </EllipsisWithTooltip>
                    // <span className="ehid-name width-auto inline-block">{params?.data[field]}</span>   

                }

                {/* <EllipsisWithTooltip placement="bottom">
          <span className="ehid-name">{params?.data[field]}</span>
        </EllipsisWithTooltip> */}
            </div>
        );
    })

    const getSelectedHotelsIds = (data) => {
        const _tableData = _.cloneDeep(data)


        const filteredData = _tableData.filter(x => x?.highlightedColumnValue)
        let res: any[] = [];

        if (filteredData.length > 0) {
            if (pathArray?.length == 2) {
                res = []

            }
            else {
                res = filteredData.map(item => item.hotelid)
            }
        }

        else {
            res = []
        }

        return res.join(",")
    }

    const getSelectedHIds = (data) => {
        const _tableData = _.cloneDeep(data)


        const filteredData = _tableData.filter(x => x?.highlightedColumnValue)
        let res = [];

        if (filteredData.length > 0) {
            if (pathArray?.length == 2) {
                res = filteredData.map(item => item.user_UniqueID)
            }
            else {
                res = filteredData.map(item => item.hotelid)
            }
        }
        else {
            res = []
        }

        return res.join(",")
    }



    const getSelectedColumn = (data) => {
        const _tableData = _.cloneDeep(data)


        const filteredItem = _tableData.find(x => x?.highlightedColumnValue)
        let res = null
        if (filteredItem) {
            res = filteredItem.highlightedColumnValue.split("_")[0]
        }


        return res
    }


    const getAgGridOptions = () => {
        const gridOptions: GridOptions = {
            rowData: [],
            overlayNoRowsTemplate: 'No data found',
            rowClass: "user-table-row",

            getRowHeight: (params) => {
                return 36;
            },
            headerHeight: 48,
            frameworkComponents: {
                valueRender: ValueRender,
                noteRender: NoteRender,
                statusRender: StatusRender,
                checkBoxRender: CheckBoxRender,
                nameRender: NameRender,
                agColumnHeader: React.memo(LaborCustomHeader),
            },

            rowSelection: "single",
        };
        return gridOptions;
    };

    const TripleMenu = () => {
        return (
            <Dropdown className="more-action" alignRight>
                <Dropdown.Toggle
                    className="btn-outline-primary btn btn-primary more"
                    id="dropdown-more"
                >
                    <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                        <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                        <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                    </svg>
                </Dropdown.Toggle>
                <Dropdown.Menu>

                    <Dropdown.Item
                        onClick={() => {
                            handleSaveDefaultView(true,toggleColumnData, { selectedSorting, lastSortedColumnData })
                        }}
                    // eventKey={item?.eventKey}
                    >
                        Save as Default
                    </Dropdown.Item>
                    <Dropdown.Item
                        onClick={() => {
                            downloadHousekeepingDashbord()
                        }}
                    >
                        Download as Excel
                    </Dropdown.Item>


                </Dropdown.Menu>
            </Dropdown>
        );
    }

    const getGridColums = (columns=gridColumns,toggleData=toggleColumnData) => {
        let _gridColumns = _.cloneDeep(columns);

        if (toggleData?.length > 0) {

            toggleData.forEach(data => {
                for (let i = 0; i < _gridColumns.length; i++) {
                    if (_gridColumns[i].colId === data.colName) {
                        _gridColumns[i].hide = !data.checked;

                    }
                }
            });
            if (periodValue !== 1) {
                _gridColumns = _gridColumns.filter(x => x.headerName !== "Status" && x.headerName !== "Notes")
            }

        }

        return _gridColumns;

    };


    const getRowNodeId = (data, path) => {
        let id=""
       
        if(path.length  === 0)
        {
            id=data.id
        }
        else if(path.length == 2){
            id=data.user_UniqueID
        }

      
        return id
    }

    const pinnedTopRowData = useMemo<any[]>(() => {
        if (filteredTableData.length > 0)
            return filteredTableData[0];
        else
            return []
    }, [filteredTableData]);

    return (
        <>

            {isSlideOutShow && (
                <HouseKeepingSlideOut
                    data={slideOutData}
                    hideSlideOut={() => { setIsSlideOutShow(false) }}
                />

            )}
            <div className='labor-daily-flash-container main-page labor-housekeeping-table' onWheel={(e) => removeTooltip(e)}>
                {/* <div className='visualize-icon'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                        <path d="M10 11.414C10.0928 11.5069 10.203 11.5807 10.3243 11.631C10.4456 11.6813 10.5757 11.7072 10.707 11.7072C10.8383 11.7072 10.9684 11.6813 11.0897 11.631C11.211 11.5807 11.3212 11.5069 11.414 11.414L16.414 6.414L15 5L10.707 9.293L8.414 7C8.32121 6.90705 8.21101 6.83332 8.08969 6.78301C7.96838 6.73269 7.83833 6.7068 7.707 6.7068C7.57567 6.7068 7.44562 6.73269 7.32431 6.78301C7.20299 6.83332 7.09279 6.90705 7 7L2 12L3.414 13.414L7.707 9.121L10 11.414Z" fill="#3F4347" />
                    </svg>
                </div> */}
                {/* <div className="navigation-menus z-index-Change d-flex justify-content-between">
                    <div className='heading-breadcrumbs d-flex flex-direction-column'>
                      
                       
                    </div>

                    <TripleMenu />
                </div> */}
                <div className='navigation-menus'>
                    <div className='d-flex table-navigation-items align-items-center flex-wrap'>
                     
                            <div className="date-column">
                                <div className="period-selector period-selector-dropdwn">
                                    {Periods?.length > 0 && <PeriodSelector
                                        id={"chartPeriodSelector"}
                                        defaultValue={periodValue}
                                        onChange={onChartPeriodChange}
                                        periods={Periods}
                                    ></PeriodSelector>}
                                </div>
                            </div>

                            {dataComparisonList?.length > 0 &&
                                <div className="varience-column">
                                    <div className="period-selector mr-auto">

                                        <DropDownList
                                            id={`chartDropdownSelector`}
                                            placeholderLabel={"Data Comparison"}
                                            data={dataComparisonList}
                                            label={"label"}
                                            value={"value"}
                                            isSearchRequired={false}
                                            defaultValue={varianceValue}
                                            dependent={varianceValue}
                                            onDropDownChange={onComparisonPeriodChange}
                                            selectedItem={dataComparisonList.find((x) => x.value === varianceValue)}
                                        />
                                    </div>
                                </div>
                            }

                            <div className="d-flex group-selector mr-3">
                                {(isDefaultSaveDataLoaded) && (
                                    <>
                                        <HotelGroupSelector
                                            isDashboard={true}
                                            hotelIds={hotelIds}
                                            subGroupSelectValue={subGroupSelectValue}
                                            onHotelChange={handleHotelChange}
                                            notIncludeEnterpriseAccounts={false} // default Props
                                            showOnlyHotelAccounts={false} // default Props
                                            moduleName={moduleName}  // default Props
                                            // featureType={featureType}
                                            hotelTypes={hotelTypes}
                                            featureIds={featureIds}
                                        ></HotelGroupSelector>



                                    </>
                                )
                                }
                            </div> 

                            <div className="sorting-column px-3">
                                <div className="period-selector period-selector-dropdwn ">
                                    {sortDropdDownData?.length > 0 && <SortSelector
                                        id={"chartPeriodSelector"}
                                        defaultValue={selectedSorting}
                                        onChange={(item) => setSelectedSorting(item.value)}
                                        data={sortDropdDownData}
                                    ></SortSelector>}
                                </div>
                            </div>
                            <div className="visualize-column">
                                {pathArray.length == 0 && <div className='d-flex align-items-center justify-content-end'>
                                    <FormLabel className='mb-0'>Visualize</FormLabel>
                                    <Form.Check
                                        checked={showChart}
                                        onChange={(e) => { if (!isInitialDataDataLoading) { handleVisualize(e.target.checked, pathArray) } }}
                                        type="switch"
                                        className="sm-controll toggleButtonRP"
                                        label=""
                                        id="housekeeping-switch"
                                    />
                                </div>}
                            </div>
                            <TableColumnSelector toggleColumnData = {toggleColumnData} drillDownLevel = {getDrillDownLevel()} toggleColumnCheck = {toggleColumnCheck} />
                            
                            <TripleMenu /> 
                    </div>



                </div>

                {pathArray?.length > 0 && <div className='heading-breadcrumbs d-flex flex-direction-column'>
                    <Breadcrumb>
                        {pathArray.map((item, index) => <Breadcrumb.Item active={index === pathArray.length - 1} onClick={() => index === pathArray.length - 1 ? () => { } : handlePreviousPath(index)} >
                            {item.level === 1 ?
                                <OverlayTrigger
                                    placement="bottom"
                                    defaultShow={false}
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={
                                        <Tooltip id={"ehid-name"}>
                                            {item.tooltip}
                                        </Tooltip>
                                    }
                                >
                                    <span className="ehid-name">{item.name}</span>
                                </OverlayTrigger> :
                                <span className="ehid-name">{item.name}</span>

                            }

                        </Breadcrumb.Item>)}
                    </Breadcrumb>
                </div>}

                <div className={`user-management-table housekeepingMgtTable housekeeping-table-wrapper ${pathArray?.length > 0 ? "table-height-with-breadcrumb" : ""}`}>
                    <div
                        id="myGrid"
                        className={"ag-theme-balham"}
                    >
                        {isInitialDataDataLoading ? (
                            <ReactPageLoader useas={"dashboardTable"} />
                        ) :
                            (
                                <div className={`housekeeping-tbl-wrapper ${showChart ? "default-table-height" : ""}`}>
                                    <AgGridReact
                                        ref={agGridRef}
                                        rowClassRules={rowClassRules}
                                        getRowNodeId={(data) => getRowNodeId(data, pathArray)}
                                        onGridReady={onGridReady}
                                        domLayout={"autoHeight"}
                                        rowData={[...filteredTableData]}
                                        columnDefs={getGridColums()}
                                        gridOptions={getAgGridOptions()}
                                        animateRows={false}

                                    />
                                    {(isChartLoadingMain || isCellClicked) &&
                                        <div className="table-overlay">
                                            <ReactPageLoader useas={"dashboardTable"} />
                                        </div>
                                    }
                                    {tooltipVisible && tooltipContent != '' &&
                                        <OutsideClickHandler
                                            onOutsideClick={(e) => {
                                                onOutsideClick(e);
                                            }}
                                        >
                                            <div className="notes-sec notes-sec-popup" style={{ position: "fixed", top: tooltipPosition.x + "px", left: (tooltipPosition.y - 150) + "px", zIndex: 99 }}>
                                                <div className="notes-list">
                                                    <div className="comment-list">
                                                        <div className="d-flex profile-details">
                                                            <div className="profile-img">
                                                                <img
                                                                    src={tooltipContent?.noteimageUrl === "" ? profile : tooltipContent?.noteimageUrl}
                                                                    className="default"
                                                                    width="40"
                                                                    height="40"
                                                                />
                                                            </div>
                                                            <div>
                                                                <div className="main-ttl">{tooltipContent?.noteUser}</div>
                                                                <div className="sub-ttl">{Utils.getLocalDateTimeFromUtc(tooltipContent?.noteDate)}</div>
                                                            </div>
                                                        </div>
                                                        <ul>
                                                            <li>
                                                                <div className="d-flex">
                                                                    <div className="detail-sec">
                                                                        <div className="comments">
                                                                            {tooltipContent?.notes}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </OutsideClickHandler>
                                    }
                                </div>
                            )
                        }
                    </div>


                </div>



            </div>
            {pathArray.length == 0 && showChart &&
                <div>
                    <LaborHouseKeepingDashboardChart
                        selectedhotelIds={getSelectedHIds([...tableData])}
                        hotelIds={getSelectedHotelsIds(tableData)}
                        selectedColumn={selectedColumn}
                        calendarDate={calendarDate}
                        comparisonPeriodValue={varianceValue}
                        periodValue={periodValue}
                        onComparisonPeriodChange={onComparisonPeriodChange}
                        onChangeMetricParameter={onChangeMetricParameter}
                        updateTableSelectedData={updateTableSelectedData}
                        drillDownArray={pathArray}
                        handleDrillDown={handleDrillDown}
                        handleHousekeeperSlideOut={handleHousekeeperSlideOut}
                        toggleColumnData={toggleColumnData}
                        handleMainChartLoading={handleMainChartLoading}
                        isInitialDataDataLoading={isInitialDataDataLoading}
                    />
                </div>
            }


        </>
    )
}

export default React.memo(LaborHouseKeepingDashboardTable)
