import React from "react";
import { Line } from "react-chartjs-2";
import { getCommonOptions } from "./cardConfig";
import CustomLegends from "./CustomLegends";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { isValidChartData } from "../../Data/options";

const LineChartCard = ({ data, chartTitle, formatType, metric,isDummyData }) => {
  

  const renderLineChart =()=>{
    if (!data || !isValidChartData(data)) {
      return <>Something went wrong.</>;
    }
    const datasets = data.datasets?.map((x: any) => x);
    const commonOptions: any = getCommonOptions(formatType,metric,isDummyData);
  
    const lineOptions: any = {
      ...commonOptions,
      cubicInterpolationMode: "monotone",
      pointRadius: 0,
      plugins: {
        ...commonOptions.plugins,
        tooltip: {
          mode: "nearest",
          intersect: false,
          ...commonOptions.plugins.tooltip,
          callbacks:{
            ...commonOptions.plugins.tooltip.callbacks,
            title: (tooltipItem) =>{     
              if(!isDummyData && tooltipItem.length > 0){
                const label= tooltipItem[0].dataset?.formattedDate[tooltipItem[0].dataIndex]
                return  label
              }
             
            },
           
          },
          
        },
      },
      scales: {
  
        x: {
          ticks: {
            autoSkip: false,
            // maxTicksLimit: 12, // Maximum number of ticks to display
          },
          grid: { color: "#F4F6F8" },
          title: {
            display: true,
            text: "Months",
            font: { size: 12 },
            color: "#84888C",
          },
        },
        y: {
          ...commonOptions.scales.y
        },
      },
    };

    return <> <Line data={data} options={lineOptions} />
    <CustomLegends isLine={true} datasets={datasets} /></>
  }


  return (
    <div
      className="line-chart-metric chart-metric"
      style={{ width: "100%", margin: "0 auto" }}
    >
      <h2 className="chart-heading" style={{ whiteSpace: "pre-wrap" }}>
      <EllipsisWithTooltip placement="top">{chartTitle}</EllipsisWithTooltip>
      </h2>
      {renderLineChart()}
    </div>
  );
};

export default LineChartCard;
