import React from "react";

export class MyImage extends React.Component<any, any> {
    constructor(props) {
        super(props);

        this.state = {
            src: props.src,
            errored: false,
        };
    }

    onError = () => {
        if (!this.state.errored) {
            this.setState({
                src: this.props.fallbackSrc,
                errored: true,
            });
        }
    };

    render() {
        const { src } = this.state;
        const {
            src: _1,
            fallbackSrc: _2,
            zoom, // Add zoom prop here
            alt,
            width,
            height,
            className,
            ...props
        } = this.props;

        return (
            <div
                style={{
                    display: "flex",              // Use flexbox for centering
                    justifyContent: "center",     // Horizontally center the image
                    alignItems: "center",         // Vertically center the image
                    width: "100%",                // Take up the full width of the container
                    height: "100%",               // Take up the full height of the container
                    overflow: "hidden",          // Prevent the image from overflowing the container
                }}
            >
                <img
                    src={src}
                    alt={alt}
                    width={width}
                    height={height}
                    className={className}
                    onError={this.onError}
                    style={{
                        transform: `scale(${zoom})`,           // Apply zoom here
                        transformOrigin: "center",            // Center the zoom
                        transition: "transform 0.2s",         // Smooth zoom transition
                        maxWidth: "100%",                      // Ensure the image doesn't overflow
                        maxHeight: "100%",                     // Ensure the image doesn't overflow
                    }}
                    {...props}
                />
            </div>
        );
    }
}
