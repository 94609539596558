import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Utils } from "../../../../Common/Utilis";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";

ChartJS.register(ArcElement, Tooltip, Legend);

const ProgressChart = ({
  budget = 0,
  actual = 0,
  chartTitle,
  formatType,
  chartComparison,
  layoutItem
}) => {
  const aspectRatio=layoutItem ? layoutItem?.w/layoutItem.cols : 1
  const percentage = budget == 0 ? 0 : Math.round((actual / budget) * 100);
  // const cappedPercentage = Math.min(100, percentage);
  const difference = budget - actual;

  const budgetvalue =
    formatType == "currency"
      ? Utils.currencyFormat(Number(budget || 0).toFixed(2))
      : formatType == "percentage"
      ? `${budget}%`
      : budget;

  const actualvalue =
    formatType == "currency"
      ? Utils.currencyFormat(Number(actual || 0).toFixed(2))
      : formatType == "percentage"
      ? `${actual}%`
      : actual;

  const differencevalue =
    formatType == "currency"
      ? Utils.currencyFormat(Number(difference || 0).toFixed(2))
      : formatType == "percentage"
      ? `${difference}%`
      : difference;

  const data = {
    datasets: [
      {
        data: [percentage, 100 - percentage],
        backgroundColor: ["#065A8C", "transparent"],
        borderWidth: 0,
        cutout: "70%", // Controls the thickness of the donut
      },
    ],
  };

  const backgroundData = {
    datasets: [
      {
        data: [100],
        backgroundColor: ["#E5F5FF"],
        borderWidth: 0,
        cutout: "88%", // Controls the thickness of the donut
      },
    ],
  };

  const options = {
    borderRadius: 60,
    rotation: -135, // Start angle for the speedometer effect
    circumference: 270, // Only show half the circle
    plugins: {
      tooltip: { enabled: false },
    },
    // responsive: true,
    // maintainAspectRatio: true,
  };

  return (
    <div className="progress-chart-metric chart-metric">
      <div>
        {/* <div className="d-one">{JSON.stringify(aspectRatio)}</div> */}
        <h3 className="chart-heading" style={{ whiteSpace: "pre-wrap" , }}>
        <EllipsisWithTooltip placement="top">{chartTitle}</EllipsisWithTooltip>
        </h3>

        <div className="chart-wrapper col-md-8">
          {/* Left Side Labels */}
          <div className="chart-description" style={{ paddingRight: `${aspectRatio*50}px`}}>
            {/* <h3>Widget Title</h3> */}
            {chartComparison?.length > 0 && (
              <div style={{ marginBottom: "10px" }}>
                <span>{chartComparison[0]?.value}</span> 
                <div className="card-text" style={{fontSize:`${aspectRatio*80}px`, lineHeight: `${aspectRatio*50}px`}}>{budgetvalue}</div> 
              
              </div>
            )}
            <div className="actual-value" style={{ marginBottom: "10px" }}>
              <span>Actual</span>
              <div className="card-text" style={{fontSize:`${aspectRatio*80}px`, lineHeight: `${aspectRatio*50}px`}}>{actualvalue}</div> 
            </div>
          {chartComparison?.length > 0 &&  <div className="value-difference">
              <span>Difference</span>
              <div className="card-text" style={{fontSize:`${aspectRatio*80}px`, lineHeight: `${aspectRatio*50}px`}}>{differencevalue}</div> 
             
            </div>}
          </div>

          {/* Donut Chart */}
          <div className="chart" style={{ position: "relative", width: aspectRatio <= 0.4 ? "145px" : "250px", height: aspectRatio == 0.33333 ? "170px" : "250px" }}>
            <Doughnut
              data={backgroundData}
              options={{ ...options, circumference: 270, radius: aspectRatio <= 0.4 ? 65 : 110 }}
              key={aspectRatio + "outer"}
            />

            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
            >
              <Doughnut data={data} options={options} key={aspectRatio + "inner"} />
            </div>

            {/* Percentage Text in Center */}
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                textAlign: "center",
                fontSize: "20px",
                color: "#065A8C",
              }}
              className={aspectRatio <= 0.4 ? "pt-4" : ""}
            >
              <div className="d-block">
                <div style={{ fontSize: "12px", color: "#555" }}>Progress</div>
                <div style={{ fontWeight: "bold", fontSize: "30px", lineHeight: "30px" }}>
                  {percentage}%
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgressChart;
