import React, { useEffect, useState, memo, useMemo } from "react";
import { Button, Card, Dropdown } from "react-bootstrap";
import { Bar, Line, Pie } from "react-chartjs-2";
import { Utils } from "../../../Common/Utilis";
import BarChartCard from "./Cards/BarChartCard";
import LineChartCard from "./Cards/LineChartCard";
import NumberCard from "./Cards/NumberCard";
import ProgressChart from "./Cards/ProgressChartCard";
import { dummyMetricDataNumberCard } from "../Data/metricsData";
import ContentLoader from "react-content-loader";
import "../../../../node_modules/react-grid-layout/css/styles.css";
import "../../../../node_modules/react-resizable/css/styles.css";
import moment from "moment";
import _ from "lodash";
import { PortfolioDashboard } from "../../../Common/Services/PortfolioDashboard";
import {
  BRACHARTCOLORS,
  calculatePercentageDifference,
  generateChartDataDummy,
  generateChartDataFromAPI,
  getFormattedDate,
  getPeriodDates,
  getShortMonthName,
  ProgressDummyData,
} from "../Data/options";
import EditRemoveDropdown from "./EditRemoveDropdown";

function MetricChartRender({
  chartTitle,
  chartType,
  metric,
  isDummyData,
  chartComparison,
  showOnly,
  layoutItem,
  widgetData,
  periodValue,
}) {
  const [data, setData] = useState<any>(null);

  const formatType = metric?.paramFormat || "currency";

  useEffect(() => {
  
    const generateChartData = () => {
      const chartTypeLower = chartType?.toLowerCase();
      const isBarOrLineChart = chartTypeLower === "bar" || chartTypeLower === "line";
  
      // Common Data Extraction for Actual and Comparison
      const actualData = widgetData?.find((item) => item?.type?.toLowerCase() === "actual");
      const comparisonData = widgetData?.filter((item) => item?.type?.toLowerCase() !== "actual");
      const actualValue = actualData?.dataValue;
  
      // Calculate common dummy and comparison data
      const commonComparisonSubValue = comparisonData?.length > 0 ? comparisonData?.map((item) => ({
        ...item,
        subvalue: calculatePercentageDifference(actualValue, item.dataValue),
      })) : [];
  
      // Handle different chart types
      if (chartTypeLower === "number") {
        return isDummyData
          ? {
              ...dummyMetricDataNumberCard.find(
                (item) => item.type?.toLowerCase() === formatType
              ) || dummyMetricDataNumberCard[0],
              comparisonSubValue: chartComparison.map((item) => ({
                ...item,
                subvalue: dummyMetricDataNumberCard[0]?.subvalue,
                type: item.value,
              })),
            }
          : {
              value: actualValue,
              comparisonSubValue: commonComparisonSubValue,
            };
      }
  
      if (chartTypeLower === "progress") {
        return isDummyData
          ? ProgressDummyData
          : { actual: actualValue, budget: comparisonData?.[0]?.dataValue };
      }
  
      if ((chartTypeLower == "line" || chartTypeLower == "bar") && isBarOrLineChart) {
        const HidCount = metric?.hids?.length || (isDummyData ? showOnly : 0);
        return isDummyData
          ? generateChartDataDummy(
              HidCount,
              (chartComparison?.length || 0) + 1,
              [
                { value: "Actual", description: "Actual" },
                ...(chartComparison || []),
              ],
              chartTypeLower === "bar" ? "Hotel" : "Month",
              chartType
            )
          : generateChartDataFromAPI(widgetData, chartType, periodValue);
      }
  
      return null; // Fallback for unsupported chart types
    };
  
    // Set data
    setData(generateChartData());
  }, [
    formatType,
    showOnly,
    chartType,
    chartComparison?.length,
    metric,
    metric?.hids?.length,
    widgetData,
    periodValue,
  ]);

  if (!data) {
    return <></>;
  }

  switch (chartType?.toLowerCase()) {
    case "bar":
      // return <></>
      return (
        <BarChartCard
          chartTitle={chartTitle}
          data={data}
          formatType={formatType}
          metric={metric}
          isDummyData={isDummyData}
        />
      );
    case "progress":
      // return <></>
      return (
        <ProgressChart
          layoutItem={layoutItem}
          chartTitle={chartTitle}
          budget={
            isDummyData
              ? chartComparison.length == 0
                ? data.actual
                : data.budget
              : data.budget
          }
          actual={data.actual}
          formatType={formatType}
          chartComparison={chartComparison}
        />
      );
    case "line":
      // return <></>
      return (
        <LineChartCard
          chartTitle={chartTitle}
          data={data}
          formatType={formatType}
          metric={metric}
          isDummyData={isDummyData}
        />
      );
    case "number":
    default:
      return (
        <NumberCard
          favorable={isDummyData ? 1 : metric?.favorable}
          formatType={formatType}
          chartTitle={chartTitle}
          data={data}
          chartComparison={
            isDummyData
              ? chartComparison.map((item) => ({
                  ...item,
                  type: item.value,
                  subvalue: data.subvalue,
                }))
              : data.comparisonSubValue
          }
          layoutItem={layoutItem}
        />
      );
  }
}

const MetricChart = memo(
  ({
    chartType,
    metric,
    chartTitle,
    chartComparison,
    showOnly = null,
    isDummyData = false,
    isDummyCard = true,
    layoutItem,
    periodValue,
    handleEdit,
    handleDelete,
    isEdit=false
  }: any) => {
    const [widgetData, setWidgetData] = useState<any>(null);
    const [loading, setLoading] = useState(false);

    const handleEditCard =(metric)=>{
        handleEdit(metric)
    }


    const handleDeleteCard=(metric)=>{
      //Call API add Delete
      handleDelete(metric)
    }

    const getWidgetData = () => {
      const hidsList = metric.hids.map((item) => item.value);
      const _periodValue =
        chartType.toLowerCase() == "line" && periodValue == 1
          ? 10
          : periodValue;
      const { FromDate, ToDate } = getPeriodDates(_periodValue);
      // const { FromDate, ToDate } = getPeriodDates(
      //   _periodValue,
      //   moment("11/01/2024", "MM/DD/YYYY")
      // );

      const requestData = {
        WidgetId: metric.id,
        Hids: hidsList.join(","),
        FromDate: FromDate,
        ToDate: ToDate,
        Period: periodValue,
      };
      setLoading(true);
      PortfolioDashboard.GetDashboardWidgetData(requestData)
        .then((res) => {
       
         
          setWidgetData(res?.result);
        })
        .catch((err) => {
          console.log({ err });
          setWidgetData(null);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    // Dummy promise to simulate data fetching
    useEffect(() => {
      if (!isDummyData) {
        getWidgetData();
      }
    }, [periodValue]);

    // Memoize the MetricChartRender output
    const memoizedChartRender = useMemo(() => {
      return (
        <MetricChartRender
          chartTitle={chartTitle}
          chartType={chartType}
          metric={metric}
          isDummyData={isDummyData}
          chartComparison={chartComparison}
          showOnly={showOnly}
          layoutItem={layoutItem}
          widgetData={widgetData}
          periodValue={periodValue}
        />
      );
    }, [
      chartTitle,
      chartType,
      metric,
      isDummyData,
      chartComparison,
      showOnly,
      layoutItem,
      widgetData,
    ]);

    if (loading) {
      return (
        <ContentLoader
          height="100%" // Set height to 100%
          width="100%" // Set width to 100%
          speed={2}
          backgroundColor="#ffffff"
          foregroundColor="#ecebeb"
          className="m-2"
          title=""
        >
          <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
        </ContentLoader>
      );
    }

    const style = isDummyCard
      ? {}
      : {
          aspectRatio: "4 / 3", // Adjust this ratio as needed (e.g., "16 / 9" for a widescreen look)
          width: "100%", // This will make it responsive within its container
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        };

    return (
      <div key={metric?.paramDisplayName} style={style}>
        <EditRemoveDropdown
          isDummyData={isDummyData}
          isEdit={isEdit}
          metric={metric}
          handleDeleteCard={handleDelete}
          handleEditCard={handleEditCard}
        />

        {memoizedChartRender}
      </div>
    );
  }, // Custom comparison function (optional)
  (prevProps, nextProps) => {
    // Only re-render if props have actually changed

    return (
      prevProps.chartType === nextProps.chartType &&
      prevProps.metric === nextProps.metric &&
      prevProps.metric?.hids?.length === nextProps.metric?.hids?.length &&
      prevProps.chartTitle === nextProps.chartTitle &&
      prevProps.chartComparison === nextProps.chartComparison &&
      prevProps.showOnly === nextProps.showOnly &&
      prevProps.isDummyData === nextProps.isDummyData &&
      prevProps.periodValue === nextProps.periodValue &&
      prevProps.isDummyCard === nextProps.isDummyCard
    );
  }
);

export default MetricChart;
