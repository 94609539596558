import React from 'react'
import dashboardImg from "../../../Common/Assets/Images/no-permission-view.png"
function NoDashboardPermission() {
  return (
    <div className='no-permission-dashboard-view text-center'>
      <div>
        <h6>No Dashboards Yet</h6>
        <p>A dashboard needs to be shared with you to have access</p>
        <div className="img mx-auto">
          <img src={dashboardImg} className='img-fluid' alt="empty dashboard view" />
        </div>
      </div>
    </div>
  )
}

export default NoDashboardPermission
