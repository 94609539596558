import Instense from "./Axios-config";
export class HotelFeatureAccessService {
  public static GetHotelFeatureAccessTableHeaderData = async (
    request: any
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.TenantId = tenantID;
    const url = `/HotelFeatureAccess/GetHotelFeatureAccessTableHeaderData`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static GetHotelFeatureAccessData = async (
    request: any
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.TenantId = tenantID;
    const url = `/HotelFeatureAccess/GetHotelFeatureAccessData`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static SaveHotelFeatureAccessMapping = async (
    request: any[]
  ): Promise<any | null> => {
    // debugger;
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.map((items) => (items["TenantId"] = tenantID));
    const url = `/HotelFeatureAccess/SaveHotelFeatureAccessMapping`;
    return Instense.post(url, request).then((response: any) => {
      let result = response.data.result as any;
      return result;
    });
  };
}
