import React from "react";
import { Dropdown, Form, OverlayTrigger, Tooltip, Modal, Button, Spinner, } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { resolve, reject } from "q";
import $ from "jquery";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { Utils } from "../../../Common/Utilis";
import { ReactPageLoader } from "../../../Common/Components/ReactPageLoader";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import TableSearch from "../../../Common/Components/TableSearch";
import DatePicker from "react-datepicker";
import OutsideClickHandler from "react-outside-click-handler";
import { EntityManagementService } from "../../../Common/Services/EntityManagementService";
import { faBullseye } from "@fortawesome/free-solid-svg-icons";
import { GuestRoomSubType } from "./GuestRoomSubType";
import { confirmAlert } from "react-confirm-alert";
import _ from "lodash";
import DateWithYearDropdown from "./DateWithYearDropdown";
import moment from "moment";
import Calendar from "react-calendar";
export class GuestRooms extends React.Component<any, any> {
  // private pageName = "Entities";
  private checkAllRef: any;
  private input: any;
  private tableSearchRef: any;
  constructor(props: any) {
    super(props);
    this.tableSearchRef = React.createRef();
    this.checkAllRef = React.createRef();
    this.input = React.createRef();
    this.state = {
      Status: "Status",
      viewType: "",
      GuestRoomsDetails: [],
      date:
        JSON.parse(localStorage.storage).currentUtcTime !== ""
          ? new Date(JSON.parse(localStorage.storage).currentUtcTime)
          : new Date(),
      selectedRows: [],
      preSelectedRows: [],
      completedCheakox: [],
      showSubTypeModal: false,
      summaryData: [],
      Allstatus: [{ Status: "Online" }, { Status: "Offline" }, { Status: "Not Used" }],
      footerGuestStatus: "Status",
      isAdded: false,
      SubTypeList: [],
      loaderTrue: false,
      footerGuestSubType: "Subtype",
      footerGuestSubTypeId: 0,
      discardTableGuestRoomsDetails: [],
      sortingField: "roomname",
      dropDownDateList: this.props.accDateList,
      currentSelectedDate: this.props?.prevDate,
      showCalendar: false,
      calendarSelectedDate: "",
      tableLoader: false,
      savingChanges: false,
      copyGuestRoomsDetails: [],
      noLocationMatch: false,
      sortingOrder: "asc",
      firstTimeDate: "",
      searchinput:"",
      
    };
  }

  componentDidMount() {    
    this.getGuestRoomSubType(this.props.hotelId);
    this.getGuestRoomDetails();
    if (this.props.type == "guestrooms") {
      this.setState({ tableLoader: true });
    }

  }



  getGuestRoomSubType = (hid) => {
    this.setState({ loaderTrue: true });
    EntityManagementService.GetCommonAreaRoomSubType(this.props.hotelId)
      .then(async (result: any) => {

        let newtableData: any = [];
        let finalTable: any = [];
        let count = 0;
        newtableData = Object.assign([], JSON.parse(JSON.stringify(result.data.result)));
        newtableData.map((item: any) => {
          if (item.type === "Guest") {
            let newRow = {} as any;
            newRow.uniqueno = item.subTypeID;
            newRow.roomType = item.subTypeName;
            finalTable.push(newRow);
          }

        });
        this.props.guestRoomChanges(false);
        this.setState({ SubTypeList: finalTable });
        this.setState({ loaderTrue: false });
      })
  }


  getGuestRoomDetails = () => {
    debugger;
    const recentDate = this.mostRecentDate(this.props.accDateList);
    let currenDate=this.props?.prevDate;

    if (this.state?.currentSelectedDate) {
      currenDate = this.state?.currentSelectedDate;
    } else {
      currenDate = recentDate;
    }

    let tempDate = moment(currenDate).format("MM/DD/YYYY");

    EntityManagementService.GetGuestRoomDetails(tempDate, this.props.hotelId)
      .then((result: any) => {
        let data: any[] = [];
        let count = 0;
        debugger;
        data = Object.assign([], JSON.parse(JSON.stringify(result)));
        data.map((item: any) => {
          count = count + 1;
          item.id = count;
          item.isNewRow = false;
          item.LocationNameValidation = false;
          item.subTypeValidation = false;
          item.LocationNameValidation_Mssg = false;
          item.nots_validation = false;


        });

        this.sortTableData(data, this.state.sortingField);
        this.props.guestRoomChanges(false);
        this.setState({
          discardTableGuestRoomsDetails: data,
          copyGuestRoomsDetails: data,
          tableLoader: false,
          currentSelectedDate: currenDate,
          firstTimeDate: currenDate
        }, () => {
          this.CountSummuryData();
        });

        // }




      })
      .catch((error) => {
        Utils.toastError(error.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          //containerId: "addGarnishment",
        });
      });
  }

  mostRecentDate = (dateArray) => {
    let moments = dateArray.map((d) => moment(d.date));
    let maxDate = moment.max(moments);

    return maxDate.format("MM/DD/YYYY");
  };


  handleToggleClick = (Type) => {
    this.setState({ viewType: Type });
  }


  GStatus = (cell: any, row: any, rowIndex: any) => {
    return (
      <div className="payrollExport">
        <Dropdown className="status-dropdown">
          <Dropdown.Toggle
            id="dropdown-importFrom"
            className={row.status?.toLowerCase() == "offline" ? "red" : row.status?.toLowerCase() == "online" ? "green" : ""}
          // disabled={!this.props.parentState.permissionManageSettings}
          >
            <EllipsisWithTooltip placement="bottom">{row.status}</EllipsisWithTooltip>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {this.state.Allstatus.map((item: any, idx: any) => (
              <Dropdown.Item
                key={idx}
                onClick={() => {
                  this.gUestStatusChangesData(row, rowIndex, item);
                }}
                className={item.Status?.toLowerCase() == "offline" ? "red" : item.Status?.toLowerCase() == "online" ? "green" : ""}
              >
                <EllipsisWithTooltip placement="bottom">{item.Status}</EllipsisWithTooltip>
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );

  };

  GSubtype = (cell: any, row: any, rowIndex: any, Extra: any) => {
    return (
      <GuestRoomSubType
        row={row}
        SubTypeList={this.state.SubTypeList}
        GuestRoomDetails={Extra.GuestRoomDetails}
        //employeeList={this.state.tipsEmployeeList}
        typeSelected={(item, row) => this.subTypeSelected(item, row, Extra.GuestRoomDetails)}
      />)

  };

  GName = (cell: any, row: any, rowIndex: any) => {

    return (
      <div className="email-formatter">
        <Form.Group>
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 100, hide: 200 }}
            overlay={
              <Tooltip id={"gName"} className={row.roomname?.length > 25 ? "d-block" : "d-none"}>
                {row.roomname}
              </Tooltip>
            }>
            <Form.Control
              ref={this.input}
              className={row.LocationNameValidation || row.LocationNameValidation_Mssg ? "alert-danger" : ""}
              id={`${rowIndex}name`}
              //placeholder="-"
              value={row.roomname}
              // disabled={!this.props.parentState.permissionManageSettings}
              onChange={(e: any) => this.onNameChange(e, row, rowIndex)}
              onBlur={(e: any) => this.onNameChangeBlur(e, row, rowIndex)}
              max={500}
              autoComplete="Off"
            />
          </OverlayTrigger>
        </Form.Group>

      </div>
    );
  };


  GType = (cell: any, row: any, rowIndex: any) => {
    return <div className="ehid-formatter">{row.guestNameType}</div>;
  };




  GNotes = (cell: any, row: any, rowIndex: any, extraData: any) => {
    const checkTooltip = extraData.isTooltip == row.id;
    if (checkTooltip && !this.state.checkTooltip) {
      this.setState({ checkTooltip: true });
    }
    return (
      <div className="email-formatter">
        <Form.Group>
          <OverlayTrigger
            placement="top"
            delay={{ show: 100, hide: 200 }}
            overlay={
              <Tooltip id={"gNotes"} className={row.notes?.length > 25 ? "d-block width-300" : "d-none width-300"}>
                {row.notes}
              </Tooltip>
            }>
            <Form.Control
              className={row.nots_validation ? "alert-danger w-100" : "w-100"}
              id={`${rowIndex}notes`}
              //placeholder="-"
              value={row.notes}
              // disabled={!this.props.parentState.permissionManageSettings}
              onChange={(e: any) => this.onNotesChange(e, row, rowIndex)}
              // onBlur={(e: any) => this.onEmailBlur(e, row, rowIndex)}
              autoComplete="Off"
              max={500}
            />

          </OverlayTrigger>
        </Form.Group>
      </div>
    );
  };

  GreMoveButton = (cell: any, row: any, rowIndex: any) => {
    if (row.isNewRow === true) {
      return (
        <div className="email-formatter">
          <button
            type="button"
            className="btn btn-primary fa-trash"
            // onClick={this.handleRemoveNewRow}
            onClick={(e: any) => this.handleRemoveNewRow(e, row, rowIndex)}
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z"
                fill="#2caf92"
              />
            </svg>
          </button>
        </div>

      );
    }
  };




  handleSelectDate = (date: any) => {
    if (this.state.isAdded || this.state.preSelectedRows.length > 0) {
      this.datediscardChanges(date)

    }
    else {
      this.setState({ currentSelectedDate: date }, () => {
        this.getGuestRoomSubType(this.props.hotelId);
        this.getGuestRoomDetails();
        // this.mainFilterTable(date);
      });
    }

  }

  mainFilterTable = (date: any) => {
    let discardTableGuestRoomsDetails = [...this.state.discardTableGuestRoomsDetails]
    let filterDate = discardTableGuestRoomsDetails.filter((x) => moment(x.date).format("MM/DD/YYYY") === date);
    this.setState({ GuestRoomsDetails: filterDate, });
  }

  handleCalendarDateChange1 = (value) => {
    const _formattedDate = Utils.AddSlashINInputDateFormat(
      value,
      this.state.calendarSelectedDate,
      false,
      4,
      false
    );
    if (_formattedDate === "invalid") {
      return;
    } else {
      value = _formattedDate;
    }

    this.setState({ calendarSelectedDate: value });
  };

  onRowSelect = (
    selectedRow: any,
    isSelect: boolean,
    rowIndex: any,
    e: any
  ) => {

    let data = this.state.GuestRoomsDetails;
    let selectedRows = this.state.selectedRows;
    let preSelectedRows = this.state.preSelectedRows;

    if (isSelect === true) {
      selectedRows.push(selectedRow);
      preSelectedRows.push(selectedRow.id);

      this.setState({ GuestRoomsDetails: data, selectedRows: selectedRows, preSelectedRows: preSelectedRows, });
      // this.setState({
      //   selectedRows: selectedRows,
      //   preSelectedRows: preSelectedRows,

      // });
      this.props.guestRoomChanges(true);
    } else {
      let indexOf = selectedRows.indexOf(selectedRow);
      selectedRows.splice(indexOf, 1);
      preSelectedRows.splice(indexOf, 1);

      this.setState({ GuestRoomsDetails: data });
      this.setState({
        selectedRows: selectedRows,
        preSelectedRows: preSelectedRows,

      }, () => {
        if (this.state.preSelectedRows.length > 0) {
          this.props.guestRoomChanges(true);
        }
        else {
          this.props.guestRoomChanges(false);
        }
      });

    }
  };

  onRowSelectAll = (isSelect: any, rows: any, e: any) => {

    let data = this.state.GuestRoomsDetails;
    if (isSelect === true) {
      let tempSelectedRows: any = [];
      let selectedRows = this.state.selectedRows;
      let preSelectedRows = this.state.preSelectedRows;

      selectedRows = tempSelectedRows;
      for (let i = 0; i < rows.length; i++) {
        selectedRows.push(rows[i]);
        preSelectedRows.push(rows[i].id);

      }

      this.setState({ GuestRoomsDetails: data, selectedRows: selectedRows, preSelectedRows: preSelectedRows, });
      // this.setState({
      //   selectedRows: selectedRows,
      //   preSelectedRows: preSelectedRows,

      // });
      this.props.guestRoomChanges(true);
    } else {
      this.setState({
        multipleRowSelected: false,
        selectedRows: [],
        preSelectedRows: [],
        // completedCheakox: [],
      });

      this.setState({ GuestRoomsDetails: data }, () => {
        if (this.state.preSelectedRows.length > 0) {
          this.props.guestRoomChanges(true);
        }
        else {
          this.props.guestRoomChanges(false);
        }
      });
    }
  };

  footerBulkSelectAll = (e: any) => {
    if (e.target.checked) {
      let preSelectedRows: any = [];
      let selectedRows: any = [];
      let data = [...this.state.GuestRoomsDetails];

      for (let i = 0; i < data.length; i++) {
        selectedRows.push(data[i]);
        preSelectedRows.push(data[i].id);
      }
      this.setState({ GuestRoomsDetails: data });
      this.setState({
        selectedRows: preSelectedRows,
        preSelectedRows: preSelectedRows,

      });

    } else {
      let data = [...this.state.GuestRoomsDetails];
      let preSelectedRows = [...this.state.preSelectedRows];
      let selectedRows = [...this.state.selectedRows];

      this.setState({ GuestRoomsDetails: data });
      this.setState({
        selectedRows: [],
        preSelectedRows: [],
      });
    }

  }

  AddNewSubType = () => {
    this.setState({ showSubTypeModal: true });
  }

  closeSubTypeModal = () => {
    this.setState({
      showSubTypeModal: false,
      //calendarSelectedDate: ""
    });
  };

  onNameChange = (e: any, row: any, rowIndex: any) => {
    let inputId = `${rowIndex}name`;
    let value = e.target.value;
    let cursorIndex = e.target.selectionStart;
    let newTableData = [...this.state.GuestRoomsDetails];
    newTableData = newTableData.map((d) => {
      if (d.id === row.id) {
        return {
          ...d,
          roomname: value,
          LocationNameValidation: false,
          LocationNameValidation_Mssg: false,
        };
      } else {
        return d;
      }
    });
    this.setState(
      (curr: any) => ({
        ...curr,
        GuestRoomsDetails: newTableData,
        isAdded: true,
      }),
      () => {
        $("#" + inputId)
          .focus()
          .prop("selectionEnd", cursorIndex);
      }
    );
    this.props.guestRoomChanges(true)
  }


  onNameChangeBlur = (e: any, row: any, rowIndex: any) => {
    let inputId = `${rowIndex}name`;
    let value = e.target.value;
    let copyTabletableData: any = [];
    let newTableData = [...this.state.GuestRoomsDetails];
    copyTabletableData = Object.assign([], this.state.GuestRoomsDetails);
    newTableData.map((item: any) => {
      if (item.roomname !== "") {
        let row = copyTabletableData.filter((x) => x.roomname?.toLowerCase() === item?.roomname.toLowerCase());
        if (row.length > 1) {
          item.LocationNameValidation_Mssg = true;
        } else {
          item.LocationNameValidation = false;
          item.LocationNameValidation_Mssg = false;
        }
      }
    });

    this.setState({ GuestRoomsDetails: [] }, () => {
      this.setState({ GuestRoomsDetails: newTableData })
    });
  }





  onNotesChange = (e: any, row: any, rowIndex: any) => {
    let inputId = `${rowIndex}notes`;
    let value = e.target.value;
    let cursorIndex = e.target.selectionStart;

    let newTableData = [...this.state.GuestRoomsDetails];
    newTableData = newTableData.map((d) => {
      if (d.id === row.id) {
        return {
          ...d,
          notes: value,
          nots_validation: false,
        };
      } else {
        return d;
      }
    });
    this.setState(
      (curr: any) => ({
        ...curr,
        GuestRoomsDetails: newTableData,
        isAdded: true,
      }),
      () => {
        $("#" + inputId)
          .focus()
          .prop("selectionEnd", cursorIndex);
      }
    );
  }

  AddGuestRoom = () => {

    let newtableData: any = [];
    let datalen = [...this.state.GuestRoomsDetails].length;
    newtableData = Object.assign([], this.state.GuestRoomsDetails);
    let count = 0;
    newtableData.map((item: any) => {
      count = count + 1;
      item.id = count;
    });
    let newRow = {} as any;
    newRow.roomid = 0;
    newRow.roomname = "";
    newRow.status = "Online";
    newRow.roomType = "";
    newRow.notes = "";
    newRow.guestNameType = "Guest";
    newRow.id = count + 1;
    newRow.isNewRow = true;
    newtableData.push(newRow);
    this.setState(
      {
        GuestRoomsDetails: [],
      },
      () => {
        this.props.guestRoomChanges(true);
        this.setState(
          {
            GuestRoomsDetails: newtableData,
            isAdded: true,
          },
          () => {
            // if (this.tableSearchRef.current) {
            //   this.tableSearchRef?.current?.CleanSearch(); 
            // }
            this.input?.current?.focus();
          }
        );
      }
    );

  }


  handleRemoveNewRow = (e: any, row: any, rowIndex: any) => {

    let newtableData: any = [];
    newtableData = Object.assign([], this.state.GuestRoomsDetails);
    newtableData.splice(rowIndex, 1);
    let filterDate = newtableData.filter((x) => x.isNewRow === true);
    if (filterDate.length === 0) {
      this.setState({ GuestRoomsDetails: newtableData });
    } else {
      this.setState({ GuestRoomsDetails: newtableData });
    }

  };

  bulkCancleClick = () => {
    this.setState({
      preSelectedRows: [],
      selectedRows: [],
      footerGuestStatus: "Status",
      footerGuestSubType: "Subtype"
    });
    this.props.guestRoomChanges(false);
  }
  DiscardClick = () => {
    let copyTabletableData: any = [];
    this.props.guestRoomChanges(false);
    this.setState({ isAdded: false }, () => {
      let copyTabletableData = _.cloneDeep(this.state.discardTableGuestRoomsDetails);//[...this.state.discardTableGuestRoomsDetails];
      copyTabletableData.forEach(item => {
        item.LocationNameValidation = false;
        item.subTypeValidation = false;
        item.LocationNameValidation_Mssg = false;
        item.nots_validation = false;

      });
      this.setState({ GuestRoomsDetails: copyTabletableData });
      //this.mainFilterTable(this.state.currentSelectedDate);
    });
  }

  DateDiscardClick = (date) => {
    this.setState({ date: date, isAdded: false, selectedRows: [], preSelectedRows: [], completedCheakox: [] }, () => {
      this.getGuestRoomSubType(this.props.hotelId);
      this.getGuestRoomDetails();
      // this.mainFilterTable(this.state.currentSelectedDate);
    });
  }

  discardChanges = () => {
    confirmAlert({
      title:
        "Are you sure you want to discard? You will lose any unsaved progress.",
      message: "",
      buttons: [
        {
          label: "No",
          onClick: () => { },
        },
        {
          label: "Yes",
          onClick: () => {
            this.DiscardClick();
          },
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
      overlayClassName: "rooms-alert",
    });
  };

  datediscardChanges = (date) => {
    confirmAlert({
      title:
        "Are you sure you want to discard? You will lose any unsaved progress.",
      message: "",
      buttons: [
        {
          label: "No",
          onClick: () => { },
        },
        {
          label: "Yes",
          onClick: () => {
            this.DateDiscardClick(date);
          },
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
      overlayClassName: "rooms-alert",
    });
  };

  handleSaveGuestRoom = () => {  
    let newtableData: any = [];
    let copyTabletableData: any = [];
    let checkLocationRequired = false;
    let checkLocationTypeRequired = false;
    let checkDuplicateRoomValidation = false;
    let checkNotesalidation = false;
    copyTabletableData = Object.assign([], this.state.GuestRoomsDetails);
    newtableData = Object.assign([], this.state.GuestRoomsDetails);
    newtableData.map((item: any) => {
      item.roomname = item.roomname.trim().replace(/\s+/g, ' ');
      if (item.roomname === "") {
        item.LocationNameValidation = true;
        checkLocationRequired = true;
      }
      if (item.roomType === "") {
        item.subTypeValidation = true;
        checkLocationTypeRequired = true;
      }
      if (item.roomname !== "") {
        let row = copyTabletableData.filter((x) => x.roomname.toLowerCase() === item.roomname.toLowerCase());
        if (row.length > 1) {
          checkDuplicateRoomValidation = true;
          item.LocationNameValidation_Mssg = true;
        } else {
          item.LocationNameValidation_Mssg = false;
        }
      }

      if (item?.notes?.length > 500) {
        item.nots_validation = true;
        checkNotesalidation = true;
      }




    });



    if (checkLocationRequired) {
      this.setState({ GuestRoomsDetails: [] }, () => {
        this.setState({ GuestRoomsDetails: newtableData });

        Utils.toastError("Location name is required",
          {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "GuestRooms",
          }
        );
      });

      const firstValidElement = newtableData.find((x) => x.LocationNameValidation === true);
      if (firstValidElement !== undefined) {
        const index = newtableData.findIndex((x) => x.id === firstValidElement.id);
        const getId = "#" + index + "name";
        $(getId).focus();
      }

      return;
    } else if (checkDuplicateRoomValidation) {
      this.setState({ GuestRoomsDetails: [] }, () => {
        this.setState({ GuestRoomsDetails: newtableData });
        Utils.toastError("Location name already exists",
          {
            position: toast.POSITION.BOTTOM_RIGHT,
            //containerId: "GuestRooms",
          }
        );
      });
      const firstValidElement = newtableData.find((x) => x.LocationNameValidation_Mssg === true);
      if (firstValidElement !== undefined) {
        const index = newtableData.findIndex((x) => x.id === firstValidElement.id);
        const getId = "#" + index + "name";
        $(getId).focus();
      }

      return;
    } else if (checkLocationTypeRequired) {
      this.setState({ GuestRoomsDetails: [] }, () => {
        this.setState({ GuestRoomsDetails: newtableData });
        Utils.toastError("Subtype is required",
          {
            position: toast.POSITION.BOTTOM_RIGHT,
            // containerId: "GuestRooms",
          }
        );
      });
      const index = newtableData.findIndex((x) => x.subTypeValidation === true);
      $(".form-control")
        .find("input[id^='" + index + "']").focus();
      return;
    } else if (checkNotesalidation) {
      this.setState({ GuestRoomsDetails: [] }, () => {
        this.setState({ GuestRoomsDetails: newtableData });
        Utils.toastError("Notes cannot exceed 500 characters",
          {
            position: toast.POSITION.BOTTOM_RIGHT,
            // containerId: "GuestRooms",
          }
        );
      });
      const firstValidElement = newtableData.find((x) => x.nots_validation === true);
      if (firstValidElement !== undefined) {
        const index = newtableData.findIndex((x) => x.id === firstValidElement.id);
        const getId = "#" + index + "name";
        $(getId).focus();
      }
      return;
    }
    else {
      
      let firstTimeDate = this.state.firstTimeDate;
      this.setState({ showSubTypeModal: true, currentSelectedDate: firstTimeDate, calendarSelectedDate: "" });


    }



  }


  handleFinalSaveDate = () => {
    this.setState({ savingChanges: true });
    let newtableData: any = [];
    newtableData = Object.assign([], this.state.GuestRoomsDetails);

    let tempDate = this.state.calendarSelectedDate === ""
      ? moment(this.state.currentSelectedDate)?.format("MM/DD/YYYY")
      : moment(this.state.calendarSelectedDate)?.format("MM/DD/YYYY")
    let requestArray: any = [];
    newtableData.map((item: any) => {
      let requestObject = {} as any;

      let storage = JSON.parse(localStorage.getItem("storage")!);
      let tenantID = storage === null ? 0 : (storage.tenantID as any);
      requestObject.tenantID = tenantID;
      requestObject.hotelID = this.props.hotelId;
      requestObject.AccDate = tempDate;
      requestObject.roomid = item.roomid;
      requestObject.RoomTypeId = item.guestRoomTypeId;
      requestObject.roomname = item.roomname;
      requestObject.Notes = item.notes;;
      requestObject.guestNameType = item.guestNameType;
      requestObject.status = item.status;
      requestObject.RoomTypeName = item.roomType;
      requestArray.push(requestObject);

    });

    EntityManagementService.SaveGuestLocationDetails(requestArray)

      .then(async (result: any | null) => {
        let successToastMessage = "";
        debugger;

        if (result != null && result.message === "Success") {
          successToastMessage = result.message;
          this.setState({ isAdded: false }, () => {
           
            this.props.guestRoomChanges(false);
            debugger;

            if (this.state.calendarSelectedDate !== "") {
              if (this.state.firstTimeDate !== this.state.calendarSelectedDate) {
                this.props.ReloadAccDate();
                this.props.selectedDate(this.state.calendarSelectedDate);
              }
            }else{
              this.getGuestRoomDetails();
            }
            this.setState({ showSubTypeModal: false,searchinput:"" });

          });
          toast.success("Saved successfully", {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "GuestRooms",
          });
          this.setState({ savingChanges: false });
        } else {
          this.OnSaveValidationCheck(result.result);
          this.setState({ showSubTypeModal: false });
          resolve();
        }
      })
      .catch((err: any) => {
        Utils.toastError(
          `Server Error, ${err}`,
          {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "GuestRooms",
          }
        );
        reject();
      });
  }

  OnSaveValidationCheck = (list: any) => {
    let duplicate=false;
    let copyTabletableData: any = [];
    let newTableData = [...this.state.GuestRoomsDetails];
    copyTabletableData = Object.assign([], list);
    newTableData.map((item: any) => {
      if (item.roomname !== "") {
        let row = copyTabletableData.filter((x) => x.roomname?.toLowerCase() === item?.roomname.toLowerCase() && x.isValidName === "false");
        if (row.length > 0) {
          item.LocationNameValidation_Mssg = true;
          duplicate=true;
        }
      }
    });

    this.setState({ GuestRoomsDetails: [] }, () => {
      this.setState({ GuestRoomsDetails: newTableData });
      if(duplicate)
      {
        Utils.toastError("Location name already exists",
        {
          position: toast.POSITION.BOTTOM_RIGHT,
          //containerId: "GuestRooms",
        }
      );
      }
      
    });
  }




  handleBulkSave = () => {    
    if (this.state.footerGuestStatus === "Status" && this.state.footerGuestSubType === "Subtype") {
      Utils.toastError(
        "Please select  subtype or Status",
        {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "GuestRooms",
        }
      );
      return;
    }


    let newtableData: any = [];
    newtableData = Object.assign([], this.state.selectedRows);

    let tempDate = this.state.calendarSelectedDate === ""
      ? moment(this.state.currentSelectedDate)?.format("MM/DD/YYYY")
      : moment(this.state.calendarSelectedDate)?.format("MM/DD/YYYY");   
    let requestArray: any = [];
    newtableData.map((item: any) => {
      let requestObject = {} as any;

      let storage = JSON.parse(localStorage.getItem("storage")!);
      let tenantID = storage === null ? 0 : (storage.tenantID as any);
      requestObject.tenantID = tenantID;
      requestObject.hotelID = this.props.hotelId;
      requestObject.AccDate = tempDate;
      requestObject.roomid = item.roomid;
      requestObject.RoomTypeId = this.state.footerGuestSubType == "Subtype" ? item.guestRoomTypeId : this.state.footerGuestSubTypeId;
      requestObject.roomname = item.roomname;
      requestObject.Notes = item.notes;;
      requestObject.guestNameType = item.guestNameType;
      requestObject.status = this.state.footerGuestStatus == "Status" ? item.status : this.state.footerGuestStatus;
      requestObject.RoomTypeName = item.roomType;
      requestArray.push(requestObject);

    });

    EntityManagementService.SaveBulkGuestSubTypeDetails(requestArray)

      .then(async (result: any | null) => {
        let successToastMessage = "";
        if (result != null && result.status === "Success") {
          successToastMessage = result.message;
          this.setState({ selectedRows: [], preSelectedRows: [], footerGuestSubType: "Subtype", footerGuestStatus: "Status" });
          this.getGuestRoomDetails();
          this.props.guestRoomChanges(false);
          toast.success(successToastMessage, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "GuestRooms",
          });
        } else {
          Utils.toastError(
            successToastMessage,
            {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "GuestRooms",
            }
          );
        }
        resolve();
      })
      .catch((err: any) => {
        Utils.toastError(
          `Server Error, ${err}`,
          {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "GuestRooms",
          }
        );
        reject();
      });




  }





  gUestStatusChangesData = (row: any, rowIndex: any, item: any) => {
    let newTableData = [...this.state.GuestRoomsDetails];
    newTableData = newTableData.map((d) => {
      if (d.id === row.id) {
        return {
          ...d,
          status: item.Status,
          // contractorEmail_validation: false,

        };
      } else {
        return d;
      }
    });
    this.setState(
      (curr: any) => ({
        ...curr,
        GuestRoomsDetails: newTableData,
        isAdded: true,
      }));
  }
  gUestFooterStatusChanges = (item: any) => {

    this.setState({ footerGuestStatus: item.Status });
  }


  subTypeSelected = (item: any, row: any, Extra: any) => {   
    let newTableData = [...Extra];
    newTableData = newTableData.map((d) => {
      if (d.id === row.id) {
        return {
          ...d,
          roomType: item.roomType,
          guestRoomTypeId: item.uniqueno,
          subTypeValidation: false,
        };
      } else {
        return d;
      }
    });
    console.log(newTableData);
    this.setState(
      (curr: any) => ({
        ...curr,
        GuestRoomsDetails: newTableData,
        isAdded: true,
      }));


    this.props.guestRoomChanges(true);
    // this.setState({ GuestRoomsDetails: newTableData, isAdded: true }, () => {
    //   this.CountSummuryData();
    // });

  };


  bulkSubtype = (item: any) => {
    this.setState({ footerGuestSubTypeId: item.uniqueno, footerGuestSubType: item.roomType });
  }

  sortImage = (order) => {
    if (!order)
      return (
        <span className="ascending">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.4712 8.47133L11.5286 7.52867L8.66656 10.3907V4H7.33323V10.3907L4.47123 7.52867L3.52856 8.47133L7.9999 12.9427L12.4712 8.47133Z"
              fill="#1D1E20"
            />
          </svg>
        </span>
      );
    else if (order === "asc")

      return (
        <span className="ascending">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.4712 8.47133L11.5286 7.52867L8.66656 10.3907V4H7.33323V10.3907L4.47123 7.52867L3.52856 8.47133L7.9999 12.9427L12.4712 8.47133Z"
              fill="#1D1E20"
            />
          </svg>
        </span>
      );
    else if (order === "desc")
      return (
        <span className="descending">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.4712 8.47133L11.5286 7.52867L8.66656 10.3907V4H7.33323V10.3907L4.47123 7.52867L3.52856 8.47133L7.9999 12.9427L12.4712 8.47133Z"
              fill="#1D1E20"
            />
          </svg>
        </span>
      );

    return null;
  };

  handleSortByColumn = (field) => {
    debugger;
    this.setState(
      {
        sortingField: field,
        sortingOrder: this.state.sortingOrder === "asc" ? "desc" : "asc",
      },
      () => {
        this.sortTableData(this.state.GuestRoomsDetails, this.state.sortingField);
      }

    );
  };

  sortTableData = (data: any, fieldName: string) => {
    const normalize = (value: string) =>
      value.toString().replace(/[^a-zA-Z0-9]/g, "").toLowerCase();


    const sortedData = _.orderBy(
      data,
      [(item) => normalize(item[fieldName])],
      [this.state.sortingOrder]
    );

    this.setState({ GuestRoomsDetails: sortedData });
  };

  CountSummuryData = () => {
   debugger;
    let newtableData: any = [];
    let newsummaryData: any = [];
    let copyTabletableData: any = [];

    copyTabletableData = Object.assign([], this.state.GuestRoomsDetails);
    newtableData = Object.assign([], this.state.GuestRoomsDetails);

    const distinctArayList = newtableData.filter(
      (thing, i, arr) => arr.findIndex(t => t.roomType === thing.roomType) === i
    );

   
    
    distinctArayList.map((item: any) => {
      let requestObject = {} as any;
      let row = copyTabletableData.filter((x) => x.roomType.toLowerCase() === item.roomType.toLowerCase() && x.status!=="Not Used" );
      if(row.length>0){
        requestObject.roomType = row[0].roomType;
        requestObject.count = row.length;
        newsummaryData.push(requestObject);
      }
      
    });




    this.setState({ summaryData: newsummaryData });
  }

  handleCalendarDateChange = (value: any) => {   
    let date = moment(value)?.format("MM/DD/YYYY");
    this.setState({ calendarSelectedDate: date, showCalendar: false });
  }

  handleChange = (e) => {   
    this.setState({ noLocationMatch: false })
    let data = [...this.state.copyGuestRoomsDetails]
    let value = e.target.value;
    const filteredData = data.filter((row) =>
      row?.roomname?.toLowerCase().includes(value.toLowerCase()) ||
      row?.guestNameType?.toLowerCase().includes(value.toLowerCase()) ||
      row?.roomType?.toLowerCase().includes(value.toLowerCase())

    );

    this.setState({ GuestRoomsDetails: filteredData,searchinput:value }, () => {
      if (filteredData.length === 0) {
        this.setState({ noLocationMatch: true })
      }
      else {
        this.setState({ noLocationMatch: false })
      }
    });
  }

  render() {

    let selectedRows = [...this.state.selectedRows];
    let GuestRoomsDetails = [...this.state.GuestRoomsDetails];
    let dropDownDateList = [...this.state.dropDownDateList];
    let currentSelectedDate = this.state.currentSelectedDate;
    let calendarSelectedDate = this.state.calendarSelectedDate;
    console.log(currentSelectedDate);
    // const leftPanelDatesSorted = _.orderBy(
    //   dropDownDateList,
    //   (item) => new Date(item.date)
    // ); 


    const guestRoomsSelectRow = {
      mode: "checkbox",
      classes: "selection-row",
      selected: this.state.preSelectedRows,
      clickToExpand: true,
      onSelect: (
        selectedRow: any,
        isSelect: boolean,
        rowIndex: any,
        e: any
      ) => {
        this.onRowSelect(selectedRow, isSelect, rowIndex, e);
        // this.setState((prev) => ({isRowSelected: !prev.isRowSelected}))
      },
      onSelectAll: (isSelect: boolean, rows: any, e: any) => {
        this.onRowSelectAll(isSelect, rows, e);
      },
    };

    const rowClasses = (row: any, rowIndex: any) => {
      if (this.state.isAdded) {
        return "checkbox-disabled";
      }
    };

    const displayCalendar = () => {
      this.setState({ showCalendar: true });
    }

    const hideCalender = () => {
      this.setState({ showCalendar: false });
    }


    const columns = [

      {
        dataField: "roomname",
        text: "Name",
        editable: false,
        headerClasses: 'width-30',
        classes: 'width-30',
        headerFormatter: (column) => {
          return (
            <div
              className={`d-inline-block ${this.state.sortingField === "roomname" ? "active" : ""
                }`}
              onClick={() => this.handleSortByColumn("roomname")}>
              {column.text}
              {this.sortImage(this.state.sortingOrder)}
            </div>
          );
        },
        formatter: this.GName,
      },
      {
        dataField: "guestNameType",
        text: "Type",
        headerClasses: 'width-30',
        classes: 'width-30',
        editable: false,
        formatter: this.GType,
      },
      {
        dataField: "roomType",
        text: "Subtype",
        editable: false,
        headerClasses: 'width-50',
        classes: 'width-50',
        headerFormatter: (column) => {
          return (
            <div
              className={`d-inline-block ${this.state.sortingField === "roomType" ? "active" : ""
                }`}
              onClick={() => this.handleSortByColumn("roomType")}>
              {column.text}
              {this.sortImage(this.state.sortingOrder)}
            </div>
          );
        },
        formatExtraData: { "GuestRoomDetails": this.state.GuestRoomsDetails },
        formatter: this.GSubtype,

      },
      {
        dataField: "notes",
        text: "Notes",
        headerClasses: 'width-150',
        classes: 'width-150',
        editable: false,
        formatter: this.GNotes,
        formatExtraData: { isTooltip: this.state.isTooltip },
      },
      {
        dataField: "status",
        text: "Status",
        headerClasses: 'width-200',
        classes: 'width-200 overflow-visible',
        editable: false,
        headerFormatter: (column) => {
          return (
            <div
              className={`d-inline-block ${this.state.sortingField === "status" ? "active" : ""
                }`}
              onClick={() => this.handleSortByColumn("status")}>
              {column.text}
              {this.sortImage(this.state.sortingOrder)}
            </div>
          );
        },
        formatter: this.GStatus,
      },
      {
        dataField: "status",
        text: "",
        headerClasses: 'width-200',
        classes: 'width-200 overflow-visible',
        editable: false,
        formatter: this.GreMoveButton,
      },

    ];

    return (
      <>

        <ToastContainer
          autoClose={3000}
          containerId={"GuestRooms"}
          enableMultiContainer
        />

        {this.state.loaderTrue ? (
          <ReactPageLoader useas={"Facility-GuestRooms"} />

        ) : (

          <div>
            <ToolkitProvider
              keyField="id"
              data={this.state.GuestRoomsDetails}
              columns={columns}
              //rowEvents={this.rowEvents}
              search={{
                afterSearch: (newResult: any) => {
                  //this.searchedData(newResult); 
                },
              }}
            >
              {(props: {
                searchProps: JSX.IntrinsicAttributes;
                baseProps: JSX.IntrinsicAttributes;
              }) => (
                <div>
                  <div className="search-filter d-flex ">
                    <label id="labeltableSearch" className="search-label">
                      {/* <TableSearch
                        ref={this.tableSearchRef}
                        id="tableSearch"
                        key="tableSearch"
                        // ref={this.child}
                        {...props.searchProps}
                        //handleNewPosition={this.handleNewPosition.bind(this)}
                        // CleanSearch={this.CleanSearch.bind(this)}
                        placeholder={"Search..."}
                      /> */}
                      <input
                        ref={this.tableSearchRef}
                        key={"text-Filter"}
                        type="text"
                        className="form-control"
                        onChange={this.handleChange}
                        placeholder="Search"
                        value={this.state.searchinput}
                      />
                    </label>
                    <div className="calendar date-selector" id="date-selector-dropdown">
                      <label>Room configurations</label>
                      <div className="date-picker calender-picker">

                        {dropDownDateList.length > 0 && (
                          <DateWithYearDropdown
                            // disabled={isDisabled}
                            data={dropDownDateList}
                            defaultValue={currentSelectedDate}
                            isSearchRequired={true}
                            label={"date"}
                            value={"date"}
                            dependent={currentSelectedDate}
                            onDropDownChange={(item) => {
                              if (!item) {
                                return;
                              }

                              this.setState(
                                { currentSelectedDate: item.date },
                                () => {
                                  this.handleSelectDate(item.date);
                                }
                              );
                            }}
                            selectedItem={
                              [...dropDownDateList].find(
                                (r: any) => r?.date == currentSelectedDate
                              ) || {
                                date: "Select date",
                              }
                            }
                          />
                        )}


                        
                      </div>
                    </div>

                  </div>
                  <div className="cusPos">
                    <div>
                      <div                
                      >



                        <div className={`GuestRooms ${this.state.isAdded ? "set-table-height" : ""}`}>
                          {this.state.tableLoader ?
                            <ReactPageLoader useas={"location-rooms"} />
                            :

                            <div className={`table-outer ${this.state.preSelectedRows.length > 0 ? "freez-table" : ""}`}>
                              <BootstrapTable
                                {...props.baseProps}
                                id="GuestRooms"
                                keyField="id"
                                data={this.state.GuestRoomsDetails}
                                columns={columns}
                                hover
                                // noDataIndication="No locations match your current filters"
                                selectRow={guestRoomsSelectRow}
                                rowClasses={rowClasses}
                                headerClasses={
                                  this.state.isAdded
                                    ? "checkbox-disabled"
                                    : ""
                                }
                              />
                              {
                                this.state.GuestRoomsDetails.length < 1 && this.state.noLocationMatch === false &&
                                <div className="no-data-mssg text-center border-bottom mb-3 pb-1">
                                  <h6 className="mt-1">Get started by creating your first room or bulk uploading locations.</h6>
                                </div>
                              }
                              {
                              this.state.noLocationMatch &&
                              <div className="no-data-mssg text-center border-bottom mb-3 pb-1">
                                <h6 className="mt-1">No rooms match your current filters.</h6>
                              </div>
                            }
                              <div className="table-footer">
                                <div className="d-flex justify-content-between">
                                  <button onClick={this.AddGuestRoom}
                                    disabled={this.state.preSelectedRows.length > 0 ? true : false}
                                  >Add Guest Room</button>
                                  {/* <button>Count Summary</button> */}


                                  <Dropdown className="summary-dropdown">
                                    <Dropdown.Toggle>Count Summary</Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      <div className="dropdown-content">
                                        {
                                          this.state.summaryData.map((item) => {
                                            return (
                                              <Dropdown.Item>
                                                <div className="type">
                                                  <EllipsisWithTooltip placement="top">{item.roomType}</EllipsisWithTooltip>
                                                </div>
                                                <span>{item.count}</span> 
                                              </Dropdown.Item>
                                            )
                                          })
                                        }
                                      </div>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </div>

                            </div>
                          }
                        </div>

                        {this.state.isAdded && (
                          <div className="fixed-action">
                            <div className="d-flex align-content-center flex-wrap forecast-action-strip">
                              <div className="mr-auto message">
                                <span>You have unsaved changes?</span>
                              </div>

                              <button
                                type="button"
                                className="btn btn-primary border-0 btn-discard btn-changes-discard"
                                onClick={this.discardChanges}
                                style={{ color: "#1D1E20" }}
                              >
                                Discard
                              </button>

                              <button
                                type="button"
                                className="save-btn btn btn-primary"
                                onClick={this.handleSaveGuestRoom}
                              // disabled={this.state.savingChanges}
                              >
                                Save Changes
                              </button>


                            </div>
                          </div>
                        )}





                        {this.state.preSelectedRows.length > 0 && (
                          <div className="bulk-selection">
                            <div className="d-flex align-content-center flex-wrap justify-content-between">
                              <div className="bulkSelectFooter d-flex align-items-center">
                                <Form.Check
                                  type="checkbox"
                                  label=""
                                  id="footerBulkCheckBox"

                                  ref={(chkRef) => {
                                    if (chkRef) {

                                      if (
                                        selectedRows.length == GuestRoomsDetails.length
                                      ) {
                                        chkRef.checked = true;
                                      } else {
                                        chkRef.checked = false;
                                      }

                                      if (
                                        selectedRows.length > 0 && !chkRef?.checked
                                      ) {
                                        chkRef.indeterminate = true;
                                      } else {
                                        chkRef.indeterminate = false;
                                      }
                                    }
                                  }}



                                  onClick={(e: any) => {
                                    e.stopPropagation();
                                    this.footerBulkSelectAll(e);
                                  }}
                                />
                                <span className="shift-selected-count">
                                  {this.state.preSelectedRows.length}{" "}
                                  selected
                                </span>

                                <div className="payrollExport">
                                  <Dropdown className="subtype-dropdown mx-3">
                                    <Dropdown.Toggle
                                      id="dropdown-importFrom"
                                      className=""
                                    // disabled={!this.props.parentState.permissionManageSettings}
                                    >
                                      <EllipsisWithTooltip placement="top">{this.state.footerGuestSubType}</EllipsisWithTooltip>

                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      {this.state.SubTypeList.map((item: any, idx: any) => (
                                        <Dropdown.Item
                                          key={idx}
                                          onClick={() => {
                                            this.bulkSubtype(item);
                                          }}
                                          className={item.roomType == this.state.footerGuestSubType ? "active" : ""}
                                        >
                                          <EllipsisWithTooltip placement="bottom">{item.roomType}</EllipsisWithTooltip>
                                        </Dropdown.Item>
                                      ))}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>

                                <div className="status-dropdown">
                                  <Dropdown>
                                    <Dropdown.Toggle
                                      id="dropdown-importFrom"
                                      className=""
                                    // disabled={!this.props.parentState.permissionManageSettings}
                                    >
                                      <EllipsisWithTooltip placement="bottom">{this.state.footerGuestStatus}</EllipsisWithTooltip>

                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      {this.state.Allstatus.map((item: any, idx: any) => (
                                        <Dropdown.Item
                                          key={idx}
                                          onClick={() => {
                                            this.gUestFooterStatusChanges(item);
                                          }}
                                          className={item.Status?.toLowerCase() == "online" ? "green" : item.Status?.toLowerCase() == "offline" ? "red" : ""}
                                        >
                                          <EllipsisWithTooltip placement="bottom">{item.Status}</EllipsisWithTooltip>
                                        </Dropdown.Item>
                                      ))}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>


                              </div>
                              <div>
                                <button
                                  type="button"
                                  className="btn mr-4 btn-outline-primary btn btn-primary"
                                  onClick={this.handleBulkSave}
                                >
                                  Update
                                </button>

                                <button
                                  type="button"
                                  className="save-btn pl-0 btn"
                                  onClick={this.bulkCancleClick}
                                >
                                  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                                    <path d="M9.72406 0.282539C9.43156 -0.00996076 8.95906 -0.00996076 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566562 -0.0174609 0.274062 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274062 1.33254L3.94156 5.00004L0.274062 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274062 9.72504C0.566562 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z" fill="white" />
                                  </svg>
                                </button>
                              </div>
                            </div>
                          </div>
                        )}



                      </div>

                    </div>
                  </div>
                </div>
              )}
            </ToolkitProvider>

          </div>
        )}

        {/*=====================Save New SubType Modal========================*/}
        <Modal
          className="add-room-type-modal subtype-modal"
          show={this.state.showSubTypeModal}
          onHide={this.closeSubTypeModal}
          aria-labelledby="contained-modal-title-vcenter"
          animation={true}
          centered
          backdrop="static"
          keyboard={false}
        >
          <OutsideClickHandler
            onOutsideClick={this.closeSubTypeModal}
          >
            {/* <Modal.Header closeButton>
              <Modal.Title>Add SubType</Modal.Title>
            </Modal.Header> */}
            <Modal.Body>
              <Form.Group controlId="subtype" className="d-flex date-picker">
                <Form.Label>Select Date</Form.Label>
                {/* <Form.Control
                    type="text"
                    placeholder="Enter value"
                    value={this.state.subType}
                    onChange={(e) => this.setState({ subType: e.target.value })}
                    autoComplete="off"
                  /> */}
                <div className="calender">
                  <div
                    className={`calender-container`}
                  >
                    <div
                      className="calender-display"
                    >
                      <svg
                        onClick={displayCalendar}
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M5.25 8.25H6.75V9.75H5.25V8.25ZM5.25 11.25H6.75V12.75H5.25V11.25ZM8.25 8.25H9.75V9.75H8.25V8.25ZM8.25 11.25H9.75V12.75H8.25V11.25ZM11.25 8.25H12.75V9.75H11.25V8.25ZM11.25 11.25H12.75V12.75H11.25V11.25Z" />
                        <path d="M3.75 16.5H14.25C15.0773 16.5 15.75 15.8273 15.75 15V6V4.5C15.75 3.67275 15.0773 3 14.25 3H12.75V1.5H11.25V3H6.75V1.5H5.25V3H3.75C2.92275 3 2.25 3.67275 2.25 4.5V6V15C2.25 15.8273 2.92275 16.5 3.75 16.5ZM14.25 6L14.2507 15H3.75V6H14.25Z" />
                      </svg>
                      <input
                        onClick={displayCalendar}
                        onInput={(e) => {
                          let value = e.currentTarget.value;

                          value = value?.toString()?.replace(/[^0-9\/]+/gi, "");

                          this.handleCalendarDateChange1(value);
                        }}
                        type="text"
                        placeholder="MM/DD/YYYY"
                        maxLength={8}
                        name="hireDate"
                        autoComplete="hireDate"
                        className="form-control single-date-pick"
                        value={
                          calendarSelectedDate
                            ? calendarSelectedDate
                            : currentSelectedDate
                        }

                      // value={
                      //   currentSelectedDate
                      // }
                      // value={
                      //   calendarSelectedDate === ""
                      //     ? currentSelectedDate
                      //     : moment(calendarSelectedDate)?.format("MM/DD/YYYY")
                      // }
                      />
                      {
                        this.state.showCalendar &&
                        <OutsideClickHandler onOutsideClick={hideCalender}>
                          <Calendar
                            calendarType={"US"}
                            // value={this.state?.currentSelectedDate ? new Date()}
                            onChange={(e) => this.handleCalendarDateChange(e)}
                            value={
                              calendarSelectedDate
                                ? moment(calendarSelectedDate)?.format(
                                  "MM/DD/YYYY"
                                ) === "Invalid date"
                                  ? currentSelectedDate &&
                                    currentSelectedDate != "Select date"
                                    ? new Date(currentSelectedDate)
                                    : new Date()
                                  : new Date(calendarSelectedDate)
                                : currentSelectedDate &&
                                  currentSelectedDate != "Select date"
                                  ? new Date(currentSelectedDate)
                                  : new Date()
                            }
                          />
                        </OutsideClickHandler>
                      }
                    </div>
                  </div>
                </div>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={this.closeSubTypeModal} className="btn-discard">Cancel</Button>
              <Button
                className={
                  this.state.saveTemplateButtonSpinner
                    ? "saveTemplate-button p-none"
                    : this.state.templateSaveConfirmation
                      ? "saveTemplateAlertButton"
                      : "saveTemplate-button"
                }
                onClick={() => this.handleFinalSaveDate()}
              >
                {this.state.saveTemplateButtonSpinner && (
                  <Spinner
                    className="mr-2"
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
                Save
              </Button>
            </Modal.Footer>

          </OutsideClickHandler>
        </Modal>


      </>
    );

  }
}