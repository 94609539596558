import * as React from "react";
import { ReactPageLoader } from "../../../Common/Components/ReactPageLoader";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
    Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import _ from "lodash";
import { CommonAreas } from "./CommonAreas"
import { GuestRooms } from "./GuestRooms"
import "./LocationTab.scss";
import { EntityManagementService } from "../../../Common/Services/EntityManagementService";
import moment from "moment";
import { Modal, Button, Spinner,ProgressBar,Dropdown, Form, OverlayTrigger, Tooltip, } from "react-bootstrap";
import Dropzone from "react-dropzone";
import { Utils } from "../../../Common/Utilis";
import { ToastContainer, toast } from "react-toastify";
import { resolve, reject } from "q";
import { CAType } from "./CAType";
import { CASubType } from "./CASubType";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { faBullseye } from "@fortawesome/free-solid-svg-icons";
import $ from "jquery";
import { confirmAlert } from "react-confirm-alert";
export class LocationMain extends React.Component<any, any> {
    // private pageName = "Entities";
    private commonArea: any;
    private commonAreaParent: any;
    private guestRoomParent: any;
    private input: any;
    private notesInput: any;
    private roomIDInput: any;
    
    constructor(props: any) {
        super(props);
        this.commonArea = React.createRef(); 
        this.commonAreaParent = React.createRef(); 
        this.guestRoomParent = React.createRef(); 
        this.input = React.createRef();
        this.notesInput = React.createRef();
        this.roomIDInput = React.createRef();
        this.state = {
            Status: "Status",
            viewType: "guestrooms",           
            isDisabled: false,
            accDateList:[],
            bulkImportModal: false,
            uploadFiles: [],
            isProgress: Math.floor(Math.random() * 30),
            BlobbaseUrl:"",
            bulkUplodedFilesData: [],
            isUploadesFiles:false,
            fileUploadedError_msg:"",
            isFileUploadedError:false,
            CASubTypeList : [],
            Allstatus: [{ Status: "Online" }, { Status: "Offline" }, { Status: "Not Used" }],
            prevDate:""
            
        };
    }

    componentDidMount() {
        this.GetAccDateGuestRoomDetails();
        this.handleToggleClick(this.state.viewType);
        this.GetFacilityType();    
        this.getCASubTypes();   
       
    }
  
   

    GetFacilityType = () => {       
        EntityManagementService.GetFacilityType()
        .then(async (result: any) => {
          this.setState({TypeList : result.data.result});         
        })
      }

    GetAccDateGuestRoomDetails =  () => {
        this.setState({ loaderTrue: true });
         EntityManagementService.GetAccDateGuestRoomDetails(this.props.hotelId)
         .then(async (result: any) => {   
            if (result.data.result?.length > 0) {
                const leftPanelDates = _.uniqBy(result.data.result?.map((item) => ({
                  date: moment(item?.date).format("MM/DD/YYYY"),
                })),"date")             
               
           
           this.setState({accDateList : leftPanelDates});
        }
           this.setState({ loaderTrue: false });
         })
       }

       getCASubTypes =  () => {
          EntityManagementService.GetCommonAreaRoomSubType(this.props.hotelId)
          .then(async (result: any) => { 
            this.setState({CASubTypeList : result.data.result});
          })
        }
     

    handleToggleClick = (Type) => {
        this.setState({ viewType: Type });
    }


    guestRoomChanges = (data) => {
        this.setState({ isDisabled: data });

        if (data === true) {
            this.props.getLocationTabModificationStatus(true);
        }
        else {
            this.props.getLocationTabModificationStatus(false);
        }
    }

    CAChanges = (data) => {
        this.setState({ isDisabled: data });

        if (data === true) {
            this.props.getLocationTabModificationStatus(true);
        }
        else {
            this.props.getLocationTabModificationStatus(false);
        }
    }

    handleBulkImport = () => {
      this.commonAreaParent?.current?.DiscardClick();
      this.commonAreaParent?.current?.bulkCancleClick();
      this.guestRoomParent?.current?.DiscardClick();
      this.guestRoomParent?.current?.bulkCancleClick();
        this.setState({ bulkImportModal: true,uploadFiles: [],isUploadesFiles:false,
            fileUploadedError_msg:"" });
    }

    onBulkImportClick = () => {
      if(this.state.isDisabled === true)
      {
        this.bulkImportClick();
      }
      else
      {
        this.handleBulkImport();
      }
      
    }

    bulkImportClick = () => {
      confirmAlert({
        title: "Unsaved confirmation?",
        message:
        "You have unsaved changes on this page. Do you want to leave this page and discard your changes?",
        buttons: [
          {
            label: "Yes",
            onClick: () => this.handleBulkImport(),
          },
          {
            label: "No",
            onClick: () => null
          },
        ],
        closeOnEscape: false,
        closeOnClickOutside: false,
        overlayClassName: "rooms-alert",
      });
    };


    bulkHideModule=()=>{
      
        this.setState({ bulkImportModal: false ,uploadFiles: [],fileUploadedError_msg:""});        
    }

    
    onDrop = (uploadFiles: any) => {
        let size = 10485760;
        if (uploadFiles.length === 1) {
            if (uploadFiles[0].size > size) {                
                this.setState({fileUploadedError_msg:"File exceeds 10MB limit."});
            } else {
                this.setState({ uploadFiles }, () => {
                    this.setState({ loader: true });
                    this.locationExcelImport();  
                   // this.getFileSize();
                });
            }
           
        } else if (uploadFiles.length > 1) {
            Utils.toastError("Multiple files are not allowed.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "bulkLocation",
            });
        } else {
            Utils.toastError("Please upload a CSV file.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "bulkLocation",
            });
        }
    };


    locationExcelImport = () => {

        EntityManagementService.locationExcelImport(this.state.uploadFiles, this.props.hotelId)
            .then(async (result: any) => {
                this.setState({ isProgress: 100 });              
                if (result.success) {
                    let count = 0;
                    let data = result?.result;
                    data.map((item: any) => {
                        count = count + 1;
                        item.id = count;
                        item.bugStatus="";  
                        item.subTypeValidation=false;   
                        item.typeValidation=false;   
                        item.locationNameValidation=false;   
                        item.locationStatusValidation=false;    
                      });
                   
                    
                    this.setState({bulkUplodedFilesData:data,isFileUploadedError:true });
                } else {
                    if(result?.message.toLocaleLowerCase() === "invalidcallname")
                    {
                           this.setState({fileUploadedError_msg:result?.result});
                    }
                }
                    
                resolve();
              
            }).catch((error) => {               
                    Utils.toastError("No Records are available to import.", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });            
               
                reject();
            });
    };

    handleCSVImportData=(type:any)=>{
        if(type==="ImportData"){
          this.finalExcelImportInTable();
        }else{
            this.setState({isUploadesFiles:true,fileUploadedError:""});
        }           
    }


    finalExcelImportInTable = () => {
        
        let newtableData: any = [];
        let copyTabletableData: any = [];
        let checkLocationRequired = false;     
       
        copyTabletableData = Object.assign([], this.state.bulkUplodedFilesData);
        newtableData = Object.assign([], this.state.bulkUplodedFilesData);
        newtableData.map((item: any) => {            
          
          if (item.typeName === "") {
            item.bugStatus = "Missing Data";
            item.typeValidation = true;
            checkLocationRequired = true;
          }  
          if (item.roomname === "") {
            item.bugStatus = "Missing Data";
            item.locationNameValidation = true;
            checkLocationRequired = true;
          } 
          if (item.subTypeName === "" || item.subTypeName === "Select SubType")  {

            if (item.typeName === "Guest" )  {
              item.bugStatus = "Missing Data";
              item.subTypeValidation = true;
              checkLocationRequired = true;
            }  
          }  


               
        
        });


        newtableData.map((item: any) => {              
            if (item.roomname !== "") {
              let row = copyTabletableData.filter((x) => x.roomname.toLowerCase() === item.roomname.toLowerCase() && x.typeName?.toLowerCase()== item?.typeName?.toLowerCase());
              if (row.length > 1) {
                  checkLocationRequired = true;
                  if(item.bugStatus ===""){
                    item.bugStatus="Duplicate";
                  }
               
              } else {
                //item.bugStatus = "";
              }
            }
          });

          newtableData.map((item: any) => {              
            if (item.roomname !== "") {
             
              let row = this.state.Allstatus.filter((x) => x.Status.toLowerCase() === item.status.toLowerCase());
              if (row.length ==0) {
                  checkLocationRequired = true;
                  if(item.bugStatus ===""){
                    item.bugStatus="Missing Data";
                    item.locationStatusValidation=true
                  }
               
              } 
            }
          });


          
    
        if (checkLocationRequired) {  
          this.setState({ bulkUplodedFilesData: [] }, () => {
            this.setState({ bulkUplodedFilesData: newtableData });  
          });           
          return;
        }else{

        
            let newtableData: any = [];     
            newtableData = Object.assign([], this.state.bulkUplodedFilesData);  
            let requestArray: any = [];
            newtableData.map((item: any) => {
              let requestObject = {} as any;
        
              let storage = JSON.parse(localStorage.getItem("storage")!);
              let tenantID = storage === null ? 0 : (storage.tenantID as any);

              requestObject.RoomID = item.roomID;
              requestObject.LocationName = item.roomname;
              requestObject.LocationType = item.typeName;
              requestObject.LocationSubType = item.subTypeName;
              requestObject.Notes = item.notes;
              requestObject.RoomStatus = item.status;
              requestObject.Tenantid = tenantID;
              requestObject.Hid = this.props.hotelId;    
              requestObject.TypeID = 0; 
              requestObject.SubTypeID = 0;                  
              
              requestArray.push(requestObject);
        
            });
            let successToastMessage = "";   
            EntityManagementService.SaveLocationImportTemplate(requestArray)        
              .then(async (result: any | null) => {   
              
                successToastMessage = result.message;   
                if (result != null && result.message==="Success") {     
                    this.GetAccDateGuestRoomDetails();
                    this.setState({ bulkImportModal: false,uploadFiles: [],isUploadesFiles:false,
                        fileUploadedError_msg:"",bulkUplodedFilesData:[],viewType:"guestrooms" });
                      
                                   
                  toast.success("Import successfully", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    containerId: "bulkLocation",
                  });
                } else {
                 
                  this.OnValidationCheck(result.result);                 
                }
                resolve();
              })
              .catch((err: any) => {
                Utils.toastError(
                  `Server Error, ${err}`,
                  {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    containerId: "bulkLocation",
                  }
                );
                reject();
              });
        
        } 
    };


    OnValidationCheck = (list:any) => {     
      let copyTabletableData: any = [];
      let newTableData = [...this.state.bulkUplodedFilesData];
      copyTabletableData = Object.assign([], list);
      newTableData.map((item: any) => {
        
        if (item.roomname !== "") {
          let row = copyTabletableData.filter((x) => x.roomname?.toLowerCase() === item?.roomname.toLowerCase() && x.isValidName==="false");
          if(row.length>0){
            item.isValidName = "false";
            item.bugStatus="Duplicate";
          }
          let row1 = copyTabletableData.filter((x) => x.roomname?.toLowerCase() === item?.roomname.toLowerCase() && x.isvalidRoomId==="false");
          if(row1.length>0){
            item.isvalidRoomId = "false";
            item.bugStatus="Missing Data";
          }
        
        }
      });
  
      this.setState({ bulkUplodedFilesData: [] }, () => {
        this.setState({ bulkUplodedFilesData: newTableData })
      });
    }




    handleDownloadCSV=()=>{
        this.downloadTemplate();
    }




    downloadTemplate = () => {
        EntityManagementService.DownloadLocationTemplete(
            this.props.hotelId
          ).then(async (result: any | null) => {           
            const fileName = `${"Download Location Template"}`;
            let csvURL = window.URL.createObjectURL(result.data);
            let tempLink = document.createElement("a");
            tempLink.href = csvURL;
            tempLink.setAttribute("download", fileName + ".csv");
            tempLink.click();
          });
    }

    handleOnCancel=()=>{
        this.setState({isUploadesFiles:false,uploadFiles: [],bulkImportModal: false ,fileUploadedError_msg:"",bulkUplodedFilesData:[] });     
    }

    handleRemoveFile=()=>{
        this.setState({uploadFiles: [],fileUploadedError_msg:"",isFileUploadedError:false});     
    }


    onNameChangeBlur = (e: any, row: any, rowIndex: any) => {     
      let copyTabletableData: any = [];
      let newTableData = [...this.state.bulkUplodedFilesData];
      copyTabletableData = Object.assign([], this.state.bulkUplodedFilesData);
      newTableData.map((item: any) => {
        if (item.roomname !== "") {
          let row = copyTabletableData.filter((x) => x.roomname?.toLowerCase() === item?.roomname.toLowerCase());
          if (row.length > 1) {
            item.isValidName = "false";
          } else {
            item.isValidName = "true";         
          }
        }
      });
  
      this.setState({ bulkUplodedFilesData: [] }, () => {
        this.setState({ bulkUplodedFilesData: newTableData })
      });
    }

    onNameChange = (e: any, row: any, rowIndex: any) => {
        let inputId = `${rowIndex}name`;
        let value = e.target.value;
        let cursorIndex = e.target.selectionStart;   
        let newTableData = [...this.state.bulkUplodedFilesData];
        newTableData = newTableData.map((d) => {    
          if (d.id === row.id) {
            return {
              ...d,
              roomname: value,              
              bugStatus:"",
              locationNameValidation:false,
            };
          } else {
            return d;
          }
        });
        this.setState(
          (curr: any) => ({
            ...curr,
            bulkUplodedFilesData: newTableData,            
          }),
          () => {
            $("#" + inputId)
            // .focus()
            .prop("selectionEnd", cursorIndex);
            this.input.current.focus();
          }
        );
      }

      onNotesChange = (e: any, row: any, rowIndex: any) => {
        let inputId = `${rowIndex}notes`;
        let value = e.target.value;
        let cursorIndex = e.target.selectionStart;   
        let newTableData = [...this.state.bulkUplodedFilesData];
        newTableData = newTableData.map((d) => {    
          if (d.id === row.id) {
            return {
              ...d,
              notes: value,  
            };
          } else {
            return d;
          }
        });
        this.setState(
          (curr: any) => ({
            ...curr,
            bulkUplodedFilesData: newTableData,            
          }),
          () => {
            $("#" + inputId)
            // .focus()
            .prop("selectionEnd", cursorIndex);
            this.notesInput.current.focus();
          }
        );
      }


      onRoomIdChange = (e: any, row: any, rowIndex: any) => {
        let inputId = `${rowIndex}roomID`;
        let value = e.target.value;
        let cursorIndex = e.target.selectionStart;   
        let newTableData = [...this.state.bulkUplodedFilesData];
        newTableData = newTableData.map((d) => {    
          if (d.id === row.id) {
            return {
              ...d,
              roomID: value,  
            };
          } else {
            return d;
          }
        });
        this.setState(
          (curr: any) => ({
            ...curr,
            bulkUplodedFilesData: newTableData,            
          }),
          () => {
            $("#" + inputId)
            // .focus()
            .prop("selectionEnd", cursorIndex);
            this.roomIDInput.current.focus();
          }
        );
      }

    CAName = (cell: any, row: any, rowIndex: any) => {
        return (
          <div className="email-formatter">
            <Form.Group>
              <Form.Control
                ref={this.input} 
                className={row.bugStatus==="Duplicate" || row.locationNameValidation === true || row.isValidName === "false"? "alert-danger w-100" : "w-100"}
                id={`${rowIndex}name`}
                //placeholder="-" 
                value={row.roomname}
                // disabled={!this.props.parentState.permissionManageSettings}
                onChange={(e: any) => this.onNameChange(e, row, rowIndex)}
                onBlur={(e: any) => this.onNameChangeBlur(e, row, rowIndex)}
                max={500}
                autoComplete="Off"
              />
            </Form.Group>
          </div>
        );
      };
      CAType = (cell: any, row: any, rowIndex: any) => {
        return(
            <div className={row.typeName==="Guest" ? "guestDropDownDisable" :""}>
            <CAType
         
                        row={row}
                        TypeList={this.state.TypeList}
                        //employeeList={this.state.tipsEmployeeList}
                        typeSelected={this.typeSelected}
                      />
              </div>
                    
                
        )
        
          };

          CASubtype = (cell: any, row: any, rowIndex: any) => {
            return (
              <CASubType
             ref={this.commonArea}
              row={row}
              SubTypeList={this.state.CASubTypeList}
             subTypeSelected={this.subTypeSelected}
             //AddNewSubType={this.AddNewSubType}
              hotelId = {this.props.hotelId}
            />
            );
        
          };
          CANotes = (cell: any, row: any, rowIndex: any) => {
            return (
              <div className="notes-col">
                <Form.Group>
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 100, hide: 200 }}
                    overlay={
                      <Tooltip id={"caNotes"} className={row.notes?.length > 24 ? "d-block width-350" : "d-none width-350"}>
                      {row.notes}
                    </Tooltip>
                    }>
                    <Form.Control
                      className={"w-100"}
                      id={`${rowIndex}notes`}
                     ref={this.notesInput} 
                      //placeholder="-"
                      value={row.notes}
                      // disabled={!this.props.parentState.permissionManageSettings}
                      onChange={(e: any) => this.onNotesChange(e, row, rowIndex)}
                      // onBlur={(e: any) => this.onEmailBlur(e, row, rowIndex)}
                      autoComplete="Off"
                    />
                  </OverlayTrigger>
                </Form.Group>
              </div>
            );
          };

          CAStatus = (cell: any, row: any, rowIndex: any) => {  
            return (
                <div className="payrollExport">
                  <Dropdown className="status-dropdown">
                    <Dropdown.Toggle
                      id="dropdown-importFrom"
                      className={row.status?.toLowerCase() == "offline" ? "red" : row.status?.toLowerCase() == "online" ? "green" : row.locationStatusValidation==true ? "alert-danger":""}
                    // disabled={!this.props.parentState.permissionManageSettings}
                    >
                      <EllipsisWithTooltip placement="bottom">{row.status}</EllipsisWithTooltip>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {this.state.Allstatus.map((item: any, idx: any) => (
                        <Dropdown.Item
                          key={idx}
                          onClick={() => {
                            this.gUestStatusChangesData(row, rowIndex, item);
                          }}
                          className={item.Status?.toLowerCase() == "offline" ? "red" : item.Status?.toLowerCase() == "online" ? "green" : ""}
                        >
                          <EllipsisWithTooltip placement="bottom">{item.Status}</EllipsisWithTooltip>
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              );




          };

          bugStatus = (cell: any, row: any, rowIndex: any) => {
            return(
            <div  className={row.bugStatus !=="" ? "email-formatter alert-danger" : "email-formatter"}>
                  {row.bugStatus}
                </div>
                );
          };
          roomId = (cell: any, row: any, rowIndex: any) => {
            return(
            <div>
                  {/* {row.roomID} */}
              <Form.Group className="p-0">
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 100, hide: 200 }}
                  overlay={
                    <Tooltip id={"roomID"} className={row.roomID?.length > 9 ? "d-block" : "d-none"}>
                      {row.roomID}
                    </Tooltip>
                  }>
                  <Form.Control
                    // ref={this.input}
                    id={`${rowIndex}roomID`}
                    ref={this.roomIDInput} 
                    className={row.isvalidRoomId === "false" ? "email-formatter alert-danger" : "email-formatter"}
                    //id={rowIndex}
                    autoComplete="Off"
                    value={row.roomID}
                   
                    onChange={(e: any) => this.onRoomIdChange(e, row, rowIndex)}
                  />
                  </OverlayTrigger>
                </Form.Group>
            </div>
                );
          };

    typeSelected = (item: any, row: any) => {      
        const newTableData = this.state.bulkUplodedFilesData.map((d) => {
            //if (d.id === row.id) {
                if (d.id === row.id) {
                return {
                    ...d,
                    typeName: item.typeName,
                    typeID: item.typeID,
                    typeValidation: false,
                    subTypeName: "",
                    bugStatus:"",
                };
            }
            return d;
        });      
        this.commonArea.current?.callFromParent();
        this.setState({ bulkUplodedFilesData: newTableData, typeId: item.typeID, isAdded: true, })

    };

    gUestStatusChangesData = (row: any, rowIndex: any, item: any) => {      
        const newTableData = this.state.bulkUplodedFilesData.map((d) => {
            //if (d.id === row.id) {
                if (d.id === row.id) {
                return {
                    ...d,
                    status: item.Status,
                    locationStatusValidation:false,
                    bugStatus:"",
                };
            }
            return d;
        });            
        this.setState({ bulkUplodedFilesData: newTableData })

    };


    subTypeSelected = (item: any, row: any) => {
        const newTableData = this.state.bulkUplodedFilesData.map((d) => {        
            if (d.id === row.id) {
            return {
              ...d,
              subTypeName: item.subTypeName,
              subTypeID : item.uniqueno,              
              subTypeValidation: false,
              bugStatus:"",
            };
          }
          return d;
        });      
        this.setState({ bulkUplodedFilesData: newTableData });
      };

      handleBackScreen=()=>{
        if(!this.state.isUploadesFiles)
        {
            this.setState({ bulkImportModal: false ,uploadFiles: [],fileUploadedError_msg:""});
        }else{
           
            this.setState({isUploadesFiles:false,uploadFiles: [],fileUploadedError_msg:"",bulkUplodedFilesData:[],isFileUploadedError:false });   
        }  
      }

      setDate=(date:any)=>{
        debugger;
        this.setState({prevDate:date});
      }
          
    render() {


      let bulkUplodedFilesData =[...this.state.bulkUplodedFilesData]
      let isMissingData = bulkUplodedFilesData.filter((x) => x.bugStatus === "Missing Data");
      let isDuplecateData= bulkUplodedFilesData.filter((x) => x.bugStatus === "Duplicate");

        const files = this.state.uploadFiles.map((file) => (
            <li key={file.name}>
                <div className="d-flex align-items-center">
                    <div className="file-name">{file.name}</div>
                    <div className="file-loader mr-auto">
                        <ProgressBar variant="success" now={this.state.isProgress} />
                    </div>
                    {/* <div className="file-percentage">{this.state.isProgress} %</div> */}
                    <button className="remove-btn"    onClick={this.handleRemoveFile} >
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
                            <path d="M13.2997 1.20997C12.9097 0.819971 12.2797 0.819971 11.8897 1.20997L6.99973 6.08997L2.10973 1.19997C1.71973 0.809971 1.08973 0.809971 0.699727 1.19997C0.309727 1.58997 0.309727 2.21997 0.699727 2.60997L5.58973 7.49997L0.699727 12.39C0.309727 12.78 0.309727 13.41 0.699727 13.8C1.08973 14.19 1.71973 14.19 2.10973 13.8L6.99973 8.90997L11.8897 13.8C12.2797 14.19 12.9097 14.19 13.2997 13.8C13.6897 13.41 13.6897 12.78 13.2997 12.39L8.40973 7.49997L13.2997 2.60997C13.6797 2.22997 13.6797 1.58997 13.2997 1.20997Z" fill="#6A6E73"/>
                        </svg>
                    </button>
                </div>
            </li>
        ));



        const columns = [

            {
                dataField: "roomID",
                text: "RoomId",
                headerClasses: 'width-200',
                classes: 'width-200 overflow-visible',
                editable: false,
                formatter: this.roomId,
              }, 
            {
              dataField: "roomname",
              text: "Name",
              editable: false,
              headerClasses: 'width-30',
              classes: 'width-30',            
              formatter: this.CAName,
            },
            {
              dataField: "typeName",
              text: "Type",
              headerClasses: 'width-30',
              classes: 'width-30',
              editable: false,
              formatter: this.CAType,           
            },
            {
              dataField: "subTypeName",
              text: "Subtype",
              // editable: false,
              headerClasses: 'width-50',
              classes: 'width-50',
              editable: (content: any, row: any) => {
                // Disable editing if row.typeID is not present
                return !!row.typeID;
              },
              formatter: this.CASubtype,
              hidden: this.state.columnHideForContractor,
            },
            {
              dataField: "status",
              text: "Status",
              headerClasses: 'width-200',
              classes: 'width-200 overflow-visible',
              editable: false,
              formatter: this.CAStatus,           
            },
            {
              dataField: "notes",
              text: "Notes",
              headerClasses: 'width-150',
              classes: 'width-150',
              editable: false,
              formatter: this.CANotes,
            },
            {
              dataField: "bugStatus",
              text: "",
              headerClasses: 'width-200',
              classes: 'width-200 overflow-visible',
              editable: false,
              formatter: this.bugStatus,
            },
      
      
          ];

         
        return (
            <>
            <ToastContainer
          enableMultiContainer
          autoClose={3000}
          containerId={"bulkLocation"}
        />
                {this.state.loaderTrue ? (
                    <ReactPageLoader useas={"Facility-GuestRooms"} />
                ) : (

                    <div className="location-tab-wrapper">
 
                        <div className="rooms-tab">
                            <button className="bulk-import" onClick={this.onBulkImportClick}>Bulk Import</button>
                            <button
                                onClick={() => this.handleToggleClick("guestrooms")}
                                className={this.state.viewType == "guestrooms" ? "active" : ""}
                                disabled={this.state.isDisabled}
                            >
                                Guest Rooms
                            </button>

                            <button
                                onClick={() => this.handleToggleClick("commonareas")}
                                className={this.state.viewType == "commonareas" ? "active" : ""}
                                disabled={this.state.isDisabled}
                            >
                                Common Areas
                            </button>
                        </div>


                        <div>
                            {this.state.viewType === "guestrooms" && (
                                <GuestRooms
                                ref={this.guestRoomParent}
                                    hotelId={this.props.hotelId}
                                    guestRoomChanges={this.guestRoomChanges}
                                    setPastState={true}
                                    accDateList={this.state.accDateList}
                                    ReloadAccDate={this.GetAccDateGuestRoomDetails}
                                    selectedDate={this.setDate}
                                    type={this.state.viewType}
                                    prevDate={this.state.prevDate}
                                />
                            )}
                            {this.state.viewType === "commonareas" && (
                                <CommonAreas
                                ref={this.commonAreaParent}
                                hotelId={this.props.hotelId}
                                CAChanges={this.CAChanges}
                                type={this.state.viewType}
                                SubTypeList={this.state.CASubTypeList}
                                getCASubTypes = {this.getCASubTypes}
                                />
                            )}
                        </div>




                    </div>

                )}
                {/* Bulk import flow */}
                <Modal
                    className="import-credit-card-result-modal importJournalModal location-bulk-import"
                    centered
                    show={this.state.bulkImportModal}
                    onHide={this.bulkHideModule}
                    backdrop="static"
                    dialogClassName="modal-100w"
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Location Import
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className={this.state.isUploadesFiles == false ? "center-content" : ""}>

                      {/* Success mssg */}
                      {this.state.isUploadesFiles && isMissingData.length===0 &&isDuplecateData.length===0 &&(                     
                        <div className="mssg">
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM14.59 5.58L8 12.17L5.41 9.59L4 11L8 15L16 7L14.59 5.58Z" fill="#0E9074"/>
                          </svg>
                          <span>Success! Please review before importing.</span>
                        </div> 
                         )}

                          {isMissingData.length > 0 && (
                        <div className="mssg error">
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM14.59 5.58L8 12.17L5.41 9.59L4 11L8 15L16 7L14.59 5.58Z" fill="#0E9074"/>
                          </svg>
                          <span>{ isMissingData.length } Errors Found. Please Revise.</span>
                        </div> 
                        )}
                        {isDuplecateData.length > 0 && (
                        <div className="mssg duplicate">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M12.0001 5.49372L19.5301 18.5037H4.47012L12.0001 5.49372ZM2.74012 17.5037C1.97012 18.8337 2.93012 20.5037 4.47012 20.5037H19.5301C21.0701 20.5037 22.0301 18.8337 21.2601 17.5037L13.7301 4.49372C12.9601 3.16372 11.0401 3.16372 10.2701 4.49372L2.74012 17.5037ZM11.0001 10.5037V12.5037C11.0001 13.0537 11.4501 13.5037 12.0001 13.5037C12.5501 13.5037 13.0001 13.0537 13.0001 12.5037V10.5037C13.0001 9.95372 12.5501 9.50372 12.0001 9.50372C11.4501 9.50372 11.0001 9.95372 11.0001 10.5037ZM11.0001 15.5037H13.0001V17.5037H11.0001V15.5037Z" fill="#863A02"/>
                          </svg>
                          <span>{isDuplecateData.length} Duplicates Found. Please Revise.</span>
                        </div> 
                         )}
                        {!this.state.isUploadesFiles && (                     
                        <div className="content">
                            <div className="upload-box">
                                <div className="box">
                                    <h6>Step 1: Download and Complete Template </h6>
                                    <Button className="w-100" onClick={this.handleDownloadCSV} >Download CSV Template</Button>
                                </div>
                                <div className="box m-0">
                                    <h6>Step 2: Upload Completed Template</h6>
                                    {/* {!this.state.isUploadedJournal ? ( */}
                                <Dropzone
                                    onDrop={this.onDrop}
                                    accept=".csv"
                                    multiple={false}
                                >
                                    {({
                                        getRootProps,
                                        getInputProps,
                                        isDragActive,
                                        isDragReject,
                                    }) => (
                                        <>
                                            {this.state.uploadFiles.length == 0 && (
                                                <>
                                                    <section
                                                        className={
                                                            isDragActive
                                                                ? "container upload-area default drag-active "
                                                                : "container upload-area default"
                                                        }
                                                    >
                                                        <div
                                                            {...getRootProps({
                                                                className: "dropzone",
                                                            })}
                                                        >
                                                            <input {...getInputProps()} />
                                                            <p>
                                                            Drag and drop your CSV file here or <span>click to browse</span>
                                                                <br />
                                                            </p>
                                                            <div className="acceptedFiles">
                                                                csv. only <span>10MB</span> max file size
                                                            </div>
                                                        </div>
                                                    </section>
                                                    {/* <div className="DownLoadTemplateLink">
                                                        <a className="templateLink" onClick={this.downloadTemplate}>Download Template</a>
                                                    </div> */}
                                                </>
                                            )}
                                            {this.state.uploadFiles.length > 0 && (
                                                <aside className="d-flex flex-column file-list">
                                                    <ul>{files}</ul>
                                                </aside>
                                            )}
                                        </>
                                    )}
                                </Dropzone>
                            {/* ) */}

                                </div>

                                {this.state.fileUploadedError_msg !=="" &&(
                                    <div className="error">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
                                        <path d="M7.00065 4.16671C7.36732 4.16671 7.66732 4.46671 7.66732 4.83337V7.50004C7.66732 7.86671 7.36732 8.16671 7.00065 8.16671C6.63398 8.16671 6.33398 7.86671 6.33398 7.50004V4.83337C6.33398 4.46671 6.63398 4.16671 7.00065 4.16671ZM6.99398 0.833374C3.31398 0.833374 0.333984 3.82004 0.333984 7.50004C0.333984 11.18 3.31398 14.1667 6.99398 14.1667C10.6807 14.1667 13.6673 11.18 13.6673 7.50004C13.6673 3.82004 10.6807 0.833374 6.99398 0.833374ZM7.00065 12.8334C4.05398 12.8334 1.66732 10.4467 1.66732 7.50004C1.66732 4.55337 4.05398 2.16671 7.00065 2.16671C9.94732 2.16671 12.334 4.55337 12.334 7.50004C12.334 10.4467 9.94732 12.8334 7.00065 12.8334ZM7.66732 10.8334H6.33398V9.50004H7.66732V10.8334Z" fill="#D3412E"/>
                                      </svg>
                                      {this.state.fileUploadedError_msg}
                                      </div>
                                )}
                                

                            </div>
                        </div>
                        )}
                         {this.state.isUploadesFiles && ( 
                            <div className="content"> 
                            
                            
                            <BootstrapTable
                             // {...props.baseProps}
                              id="LocationBulkUploadData"
                              keyField="location-tbl"
                              data={this.state.bulkUplodedFilesData}
                              columns={columns}
                              hover
                             // selectRow={caSelectRow}
                            //   rowClasses={rowClasses}
                            //     headerClasses={
                            //       this.state.isAdded
                            //         ? "checkbox-disabled"
                            //         : ""
                            //     }
                            />
                             </div>    
                        )}  

                    </Modal.Body>
                        <Modal.Footer>
                            <button
                                type="button"
                                 onClick={this.handleBackScreen}
                                className="btn-outline-primary btn btn-primary mr-auto"
                            >
                                Back
                            </button>
                            <Button
                            type="button"
                             onClick={this.handleOnCancel}
                            >
                               Cancel
                            </Button>
                            <Button
                            disabled={this.state.isFileUploadedError ? false :true}
                                variant="primary" 
                                 onClick={(e) => {this.handleCSVImportData(this.state.isUploadesFiles ? "ImportData":"Next")}}
                            >
                                {this.state.isImportData && (
                                    <>
                                        <Spinner size="sm" animation="border" />{" "}
                                    </>
                                )}{" "}
                              {this.state.isUploadesFiles ? "Import":"Next"}
                            
                            </Button>
                        </Modal.Footer>
                </Modal>

            </>
        );

    }
}