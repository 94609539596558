import * as React from "react";
import { Button, Dropdown } from "react-bootstrap";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import OutsideClickHandler from "react-outside-click-handler";
import profile from "../Assets/Images/profile.png";

export class CAType extends React.Component<any, any> {
  private inputElement: any;
  constructor(props: any) {
    super(props);
    this.inputElement = React.createRef();
    this.state = {
      row: this.props?.row,
      inputValue: "",
      TypeList: this.props?.TypeList,
      filterTypeList: [],
      Type : this.props?.row?.typeName
    };
    this.handleSearchType = this.handleSearchType.bind(this);
  }

 
  componentDidMount() {
  
  }

  handleDropdownChange(item) {
    debugger;
    this.setState({ inputValue: "" });
    this.props.typeSelected(item, this.state.row);
  }

  handleSearchType = (e: { target: { value: string } }) => {
debugger;
    this.setState({ loaderTrue: true ,inputValue: e.target.value});
    let data = [...this.props.TypeList]
    const updatedList = data.filter(
      (item: { typeName: string }) => {
        return (
          item.typeName.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1
        );
      }
    );

    this.setState({ TypeList: [] }, () => {
      this.setState({
        inputValue: e.target.value,
        loaderTrue: false,
        TypeList: updatedList,

      });
    })

  };

  handleOpen = () => {
    this.setState({inputValue  : "" ,TypeList : this.props.TypeList })
  }



  render() {
    
    return (

            // <div className="payrollExport">
             <Dropdown
               className="subtype-dropdown position-static"
               onToggle={(isOpen) => {
                if (isOpen) {
                  this.handleOpen(); // Load filtered SubTypeList when opening
                }
              }}
            >
              <Dropdown.Toggle
                 id="dropdown-importFrom"
                 className={this.props.row.typeValidation ? "alert-danger" : ""}
               >
                <div className="btn-text">
                  <EllipsisWithTooltip placement="bottom"> {this.state.Type || "Select Type"}</EllipsisWithTooltip>
                </div>
              </Dropdown.Toggle>
               <Dropdown.Menu>
                 <div className="search-sec">
                  <input
                    type="text"
                     className="form-control"
                     onChange={this.handleSearchType}
                     placeholder="Type Search"
                     value={this.state.inputValue}
                     ref={this.inputElement}
                   />
                 </div>
                  <div className="menu">
                    {this.state.TypeList?.length > 0 ? (
                    this.state.TypeList.map((item: any) => (
                      <Dropdown.Item
                      onClick={() => this.handleDropdownChange(item)}
                      key={item.id}
                      >
                        <EllipsisWithTooltip placement="bottom">{item.typeName}</EllipsisWithTooltip>
                        {/* <button>
                          <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"></path></svg>
                        </button> */}
                      </Dropdown.Item>
                    ))
                  ) : (
                    <Dropdown.Item>No Type match your current filters.</Dropdown.Item>
                  )}
                  </div>
                  {/* <Button className="add-subtype">Add Type</Button> */}
               </Dropdown.Menu>
             </Dropdown>
            
        //    </div>
     );
    //   </OutsideClickHandler>
    
    
  }
}
