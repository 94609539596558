import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Container, Tabs, Tab, Dropdown } from "react-bootstrap";
import { HidSelector } from "../../../../Common/Components/HidSelector";
import { Hotel as hotel } from "../../../../Common/Services/Hotel";
import { IHotelResponseDto } from "../../../../Common/Contracts/IHotel";
import { DepartmentView } from "./DepartmentView";
import { LaborDeptPosition as laborDeptPosition } from "../../../../Common/Services/LaborDeptPosition";
import { LaborPerformance as laborPerformance } from "../../../../Common/Services/LaborPerfomance";
import { EmployeeView } from "./EmployeeView";
import { resolve, reject } from "q";
import { PerformanceSlideout } from "./PerformanceSlideout";
import { ApprovalLogHistory } from "./ApprovalLogHistory";
import moment from "moment";
import $ from "jquery";
import { Utils } from "../../../../Common/Utilis";
import { toast } from "react-toastify";
import "./RefreshToast.scss";
import { LaborPerformance } from "../../../../Common/Services/LaborPerfomance";

export class PerformanceMain extends React.Component<any, any> {
  private childHID: any;
  private pageName = "Performance";
  public departmentView: any = React.createRef();
  public employeeView: any = React.createRef();
  constructor(props: any) {
    super(props);
    this.childHID = React.createRef();
    this.state = {
      hidValue: "Select",
      hotelName: "Select",
      hidList: [],
      key: "",
      departmentpositionList: [],
      departmentList: [],
      positionList: [],
      isTabShow: false,
      isSlideOutShow: false,
      stickeyTableHeader: false,
      stickeyTableRow: false,
      isExportedDate: false,
      isEmployeeview: false,
      showRefershBanner: false,
      paytype: "",
      defaultpaytype: "",
      selectDate: "",
      defaultSelectDate: "",
      dataComparison: 0,
      dataFilter: "",

      deptFilterValueNew: {
        filterID: 0,
        filterName: "",
      },
      deptCompValueNew: {
        filterID: 0,
        filterName: "",
      },
      empFilterValueNew: {
        filterID: 0,
        filterName: "",
      },
      empCompValueNew: {
        filterID: 0,
        filterName: "",
      },
      showChangeLogModal: false,
      calenderDates: [],
      hidwiseDate: "",
      moduleName: "Labor Management",
      // featureType: 1
      featureIds: "7",
      hotelTypes: "1,2,3",
    };
  }

  commonFunction = (
    selectedDate: any,
    hotelID: Number,
    paytype: any,
    type: any
  ) => {
    if (type === "department")
      this.departmentView.current.getPayPeroid(selectedDate, hotelID, paytype);
    else if (type === "employee")
      this.employeeView.current.getPayPeroid(selectedDate, hotelID, paytype);
    else if (type === "commonChange") {
      this.departmentView.current.getPayPeroid(selectedDate, hotelID, paytype);
      this.employeeView.current.getPayPeroid(selectedDate, hotelID, paytype);
    }
  };
  commonFunctionForDateChange = (e: any) => {
    this.departmentView.current.calendarChangeBoth(e);
    this.employeeView.current.calendarChangeBoth(e);
  };
  commonFunctionForDateAcyionChange = (e: any) => {
    this.departmentView.current.calenderActionBoth(e);
    this.employeeView.current.calenderActionBoth(e);
  };

  updateNewStateForFilter = (id, value, type) => {
    let new_state = Object.assign({}, this.state);
    if (type === "deptFilter") {
      let fieldData = new_state.deptFilterValueNew;
      fieldData.filterID = id;
      fieldData.filterName = value;
      this.setState({ fieldData });
    } else if (type === "empFilter") {
      let fieldData = new_state.empFilterValueNew;
      fieldData.filterID = id;
      fieldData.filterName = value;
      this.setState({ fieldData });
    } else if (type === "deptComp") {
      let fieldData = new_state.deptCompValueNew;
      fieldData.filterID = id;
      fieldData.filterName = value;
      this.setState({ fieldData });
    } else if (type === "empComp") {
      let fieldData = new_state.empCompValueNew;
      fieldData.filterID = id;
      fieldData.filterName = value;
      this.setState({ fieldData });
    }
  };

  componentDidMount() {
    this.loadHotelsDetails();
    window.addEventListener("scroll", this.handleScroll, true);
    this.getDefaultView();
    this.setDefaultURLParamValues();
  }

  showHideChangeLogModal = () => {
    this.setState({
      showChangeLogModal: !this.state.showChangeLogModal,
    });
  };

  getGetCalenderDates = (calenderDates) => {
    this.setState({ calenderDates });
  };

  getDefaultView() {
    this.setState({ pageloader: false });
    laborPerformance
      .getDefaultViewProperties(this.pageName)
      .then(async (response: any | null) => {
        if (response?.length > 0) {
          const result = response;
          const tabName = result.find(
            (item) => item.fieldName === "tabName"
          )?.fieldValue;

          this.setState({
            key: tabName,
          });
        } else {
          this.setState({
            key: "department",
          });
        }
      })
      .catch((error) => {
        this.setState({
          key: "department",
        });
      });
  }
  componentWillUnmount = () => {
    document.removeEventListener("scroll", this.handleScroll, true);
  };
  handleScroll = (event: any) => {
    if (
      event.target.className === "body-sec horizontal-scroll container-fluid"
    ) {
      let scrollPostion = event.target.scrollTop;
      //console.log("scrollPostion-" + scrollPostion);
      if (scrollPostion > (this.state.isExportedDate ? 261 : 200)) {
        this.setState({ stickeyTableHeader: true });
      } else {
        this.setState({ stickeyTableHeader: false });
      }
      if ($(".top-parent-list")[0]) {
        //let position = $(".top-parent-list").position().top;
        let scrollTableRowPosition: any = $(".top-parent-list").offset()?.top;
        if (scrollTableRowPosition < 132) {
          this.setState({ stickeyTableRow: true });
        } else {
          this.setState({ stickeyTableRow: false });
        }
      }
    }
  };
  hideSticky = () => {
    this.setState({ stickeyTableRow: false });
  };

  loadHotelsDetails() {
    const params = this.getURLParamsValues();
    hotel
      .getUserHotelAccess("Labor Management")
      .then(async (result: IHotelResponseDto[] | null) => {
        if (result != null) {
          this.setState({ hidList: result, paramDate: params?.date }, () => {});
          if (params?.hotelId) {
            const hotelDetail = result.find(
              (r) => +r?.hotelID === +params?.hotelId
            );
            localStorage.setItem("hotelSelected", JSON.stringify(hotelDetail));
            sessionStorage.setItem(
              "hotelSelected",
              JSON.stringify(hotelDetail)
            );
            this.selectHotelDropDown(hotelDetail);
          }
        }
        resolve();
      })
      .catch((error) => {
        reject();
      });
  }

  selectHotelDropDown = (hotelDetails: any) => {
    // if(params?.HotelId !==hotelDetails.hotelID ) {
    // Utils.updateBrowserUrl();
    // }
    debugger;
    this.setState(
      {
        hidValue: hotelDetails.hotelID,
        hotelName: hotelDetails.lettercode,
      },
      () => {
        this.getlaborPositionDept();
        this.GetCurrentDateTimeByHid(
          hotelDetails.hotelID,
          "",
          "selectHotelDropDown"
        );
      }
    );
  };

  GetCurrentDateTimeByHid = (hotelID: any, hotelDetails: any, type: any) => {
    LaborPerformance.GetCurrentDateTimeByHid(hotelID)
      .then(async (result: any | null) => {
        if (result != null) {
          this.setState({ hidwiseDate: result.currentDate });
        }
        resolve();
      })
      .catch((err) => {
        Utils.toastError(`Server Error: ${err}`);
        reject();
      });
  };

  getlaborPositionDept = () => {
    this.setState({ loaderTrue: true, isTabShow: false });
    laborDeptPosition
      .getlaborPositionDept(this.state.hidValue)
      .then(async (result: any[] | null) => {
        this.setState({ loaderTrue: false });
        if (result != null && result.length > 0) {
          let alldepartmentpositionList: any[] = [];
          let departments: any[] = [];
          let positios: any[] = [];
          let department_temp: any[] = [];
          result.forEach((element) => {
            let data = {} as any;
            data.id = element.departmentID;
            data.name = element.departmentName;
            let isexist = department_temp.filter(
              (item) => item.id === element.departmentID
            );
            if (isexist.length === 0) {
              department_temp.push(data);
            }
          });
          if (department_temp.length > 1) {
            departments = [{ id: -1, name: "All" }];
            positios = [{ id: -1, name: "All" }];
          }
          result.forEach((element) => {
            let data = {} as any;
            alldepartmentpositionList.push(element);
            data.id = element.departmentID;
            data.name = element.departmentName;
            let isexist = departments.filter(
              (item) => item.id === element.departmentID
            );
            if (isexist.length === 0) {
              departments.push(data);
            }
            let positiondata = {} as any;
            positiondata.id = element.positionID;
            positiondata.name = element.positionName;
            let isexist1 = positios.filter(
              (item) => item.id === element.positionID
            );
            if (isexist1.length === 0) {
              positios.push(positiondata);
            }
          });
          await this.setState({
            departmentpositionList: alldepartmentpositionList,
            departmentList: departments,
            positionList: positios,
            isTabShow: true,
          });
        }
        resolve();
      })
      .catch((error) => {
        reject();
        this.setState({ loaderTrue: false });
      });
  };

  getURLParamsValues = (): any => {
    const paramObject: any = {
      date: this.getParamValueByParamName("date"),
      hotelId: this.getParamValueByParamName("hotelId"),
      departmentId: this.getParamValueByParamName("departmentId"),
      positionId: this.getParamValueByParamName("positionId"),
      dataComparisonMode: this.getParamValueByParamName("dataComparisonMode"),
    };
    return paramObject;
  };

  setDefaultURLParamValues = (): any => {
    const paramObject: any = this.getURLParamsValues();
    let hidValue = "Select";
    let initialDate = "";
    let selectedDepartment = 0;
    let selectedPosition = 0;
    let currentdate = "";
    let currentMonth = "";
    let currentYear = "";
    let todayDate: any = new Date();
    //if (JSON.parse(localStorage.storage).currentUtcTime !== "") {todayDate = new Date(JSON.parse(localStorage.storage).currentUtcTime);}
    //currentDate.setDate(todayDate.getDate() - 1);
    if (this.state.hidwiseDate === "") {
      if (
        JSON.parse(localStorage.storage).currentUtcTime !== "" &&
        JSON.parse(localStorage.storage).currentUtcTime !== null &&
        JSON.parse(localStorage.storage).currentUtcTime !== undefined
      ) {
        todayDate = new Date(JSON.parse(localStorage.storage).currentUtcTime);
      }
    } else {
      todayDate = new Date(this.state.hidwiseDate);
    }
    currentdate = todayDate.getDate();
    currentMonth = todayDate.getMonth() + 1;
    currentYear = todayDate.getFullYear();
    let tempDate = currentMonth + "/" + currentdate + "/" + currentYear;

    if (paramObject?.hotelId) {
      if (paramObject?.hotelId) {
        hidValue = paramObject?.hotelId;
      }
      if (paramObject?.departmentId) {
        selectedDepartment = paramObject?.departmentId;
      }
      if (paramObject?.positionId) {
        selectedPosition = paramObject?.positionId;
      }
    }

    if (paramObject?.date) {
      initialDate = paramObject?.date;
    } else {
      initialDate = tempDate;
    }

    // this.handleComparisonClick( (+paramObject?.dataComparisonMode||0) );
    // this.setState({ hidValue, selectedDepartment, selectedPosition, initialDate, tempSelectedDate: initialDate  })
  };

  getParamValueByParamName(paramName: string): any {
    const valuesArray = decodeURIComponent(this.props.history.location.search)
      ?.replace("?", "")
      ?.split("&");
    const item = valuesArray?.find((r) => r?.includes(paramName));
    return item ? window.atob(item?.split("=")[1] as any) : "";
  }

  openSlideOut = (
    employee,
    deptId,
    positionId,
    date,
    hotelId,
    deptName,
    positionName,
    userName,
    budgetedHours,
    showBudgetedHours = false,
    isEmployeeview = false
  ) => {
    let todayDate: any = new Date();
    let startYear: any = new Date(this.state.calenderDates[0]).getFullYear();
    let endYear: any = new Date(this.state.calenderDates[1]).getFullYear();
    // let Date = row[day].date + "/" + todayDate.getFullYear(); //'04/19/2021';
    let monthofDate = new Date(date).getMonth() + 1;
    let newDate = new Date(date).getDate();
    if (startYear !== endYear && monthofDate === 12) {
      date = monthofDate + "/" + newDate + "/" + startYear;
    } else {
      date = monthofDate + "/" + newDate + "/" + endYear;
    }
    if (this.state.hidwiseDate === "") {
      if (
        JSON.parse(localStorage.storage).currentUtcTime !== "" &&
        JSON.parse(localStorage.storage).currentUtcTime !== null &&
        JSON.parse(localStorage.storage).currentUtcTime !== undefined
      ) {
        todayDate = new Date(JSON.parse(localStorage.storage).currentUtcTime);
      }
    } else {
      todayDate = new Date(this.state.hidwiseDate);
    }
    if (moment(date).isAfter(moment(todayDate).format("MM/DD/YYYY"))) {
      return;
    }

    let breadDepartments: any[] = [];
    let breadPositions: any[] = [];
    if (deptId && deptName) {
      breadDepartments.push({ id: deptId, name: deptName });
    }
    if (positionId && positionName) {
      const deptData = this.state.departmentpositionList.find(
        (item) => item?.positionID === positionId
      );

      if (breadDepartments.length === 0 && deptData) {
        breadDepartments.push({
          id: deptData?.departmentID,
          name: deptData?.departmentName,
        });
      }

      breadPositions.push({ id: positionId, name: positionName });
    }
    let dataparameter = {
      Employee: employee,
      DeptId: deptId,
      PositionId: positionId,
      Date: date,
      HotelId: hotelId,
      HotelName: this.state.hotelName,
      deptName,
      positionName,
      userName,
      budgetedHours,
      showBudgetedHours,
      breadPositions,
      breadDepartments,
    };

    this.setState({
      isSlideOutShow: true,
      DataParameter: dataparameter,
      isEmployeeview,
    });
  };
  exportedDate = () => {
    this.setState({ isExportedDate: true });
  };

  hideSlideOut = (reloadData) => {
    this.setState({ isSlideOutShow: false, showRefershBanner: reloadData });
    const CustomToastWithLink = () => (
      <>
        <div className="info-badges">
          <div>
            <div style={{ display: "inline" }}>
              Some data may have changed.{""}
            </div>
            <a onClick={() => this.refreshPerformanceData()}>
              Refresh the page
            </a>
            <span>to reflect any data changes.</span>
          </div>
        </div>
      </>
    );

    if (reloadData) {
      $(".Toastify").css("opacity", "1");
      toast.warn(CustomToastWithLink(), {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
        toastId: "toastRefresh",
      });
    }
  };

  refreshPerformanceData = () => {
    if (this.state.isEmployeeview) {
      if (typeof this.employeeView?.current["reloadData"] === "function") {
        this.employeeView.current.reloadData();
      }
    } else {
      if (typeof this.departmentView?.current["reloadData"] === "function") {
        this.departmentView.current.reloadData();
      }
    }
    if ($(".Toastify")) {
      //  $(".Toastify").css("opacity", "0");
    }
    this.setState({ showRefershBanner: false });
  };
  handleTabSelect = (eventKey: any) => {
    // $(".Toastify").css("opacity", "0");
    let isEmpView = eventKey === "employee" ? true : false;
    this.setState({ isEmployeeview: isEmpView });
    let { paytype, defaultpaytype, defaultSelectDate, selectDate } = this.state;
    if (defaultpaytype !== paytype || defaultSelectDate !== selectDate) {
      if (eventKey === "employee") {
        this.employeeView.current.setDefaultCalander();
      } else {
        this.departmentView.current.setDefaultCalander();
      }
    }
    this.setState({ key: eventKey }, () => {
      this.handleTabChange();
    });
  };

  handleTabChange = () => {
    if (this.state.key === "employee") {
      // New State Filter
      let new_state = Object.assign({}, this.state);
      let deptNewFilter = new_state.deptFilterValueNew;

      // New State Comp Filter
      let new_state_Comp = Object.assign({}, this.state);
      let deptCompNew = new_state_Comp.deptCompValueNew;

      if (
        deptNewFilter.filterName === "Budget" ||
        deptNewFilter.filterName === "Schedule"
      ) {
        let data: any = {
          id: "totalScheduleinmin",
          value: "Schedule",
        };
        this.employeeView.current.handleFilterChangeNew(data);
      }
      if (deptNewFilter.filterName === "Actual") {
        let data: any = {
          id: "totalActualinmin",
          value: "Actual",
        };
        this.employeeView.current.handleFilterChangeNew(data);
      }
      if (deptNewFilter.filterName === "Variance") {
        let data: any = {
          id: "totalVarianceinmin",
          value: "Variance",
        };

        this.employeeView.current.handleFilterChangeNew(data);
      }

      //////////// This is for Data Comparision ////////////////////

      if (
        deptCompNew.filterName === "Schedule" ||
        deptCompNew.filterName === "Budgets"
      ) {
        this.employeeView.current.handleComparisonClick(1);
      } else if (deptCompNew.filterName === "Variance") {
        this.employeeView.current.handleComparisonClick(0);
      }
    } else {
      let new_state = Object.assign({}, this.state);
      let empNewFilter = new_state.empFilterValueNew;

      // New State Filter
      let new_state_Comp = Object.assign({}, this.state);
      let empCompNew = new_state_Comp.empCompValueNew;

      if (
        empNewFilter.filterName === "Actual" &&
        empCompNew.filterName !== "Schedule"
      ) {
        let data: any = {
          id: "totalActualinmin",
          value: "Actual",
        };
        this.departmentView.current.handleFilterChangeNew(data);
      }
      if (empNewFilter.filterName === "Variance") {
        let data: any = {
          id: "totalVarianceinmin",
          value: "Variance",
        };
        this.departmentView.current.handleFilterChangeNew(data);
      }
      if (empCompNew.filterName === "Variance") {
        this.departmentView.current.handleComparisonClick(0);
      }
    }
  };

  render() {
    // const handleTabSelect = (eventKey: any) => {
    //     $('.Toastify').css("opacity","0");
    //     let isEmpView = (eventKey === "employee" ? true : false)
    //     this.setState({ key: eventKey,isEmployeeview: isEmpView});
    //}

    return (
      <>
        {this.state.isSlideOutShow && (
          <div className="performance-slideout-wrapper">
            <PerformanceSlideout
              hotelName={this.state.hotelName}
              DataParameter={this.state.DataParameter}
              hideSlideOut={this.hideSlideOut}
            />
          </div>
        )}
        {this.state.showChangeLogModal &&
          this.state.calenderDates?.length > 0 && (
            <ApprovalLogHistory
              hotelName={""}
              hotelId={this.state.hidValue}
              showChangeLogModal={this.state.showChangeLogModal}
              showHideChangeLogModal={this.showHideChangeLogModal}
              shiftId={0}
              hideCrossAndClearFilters={false}
              payPeriodStartDate={this.state.calenderDates[0]}
              payPeriodEndDate={this.state.calenderDates[1]}
              shiftDate={""}
              accType={""}
            />
          )}
        <div className="performance-position performance all-view">
          {/* <ToastContainer autoClose={3000} /> */}
          <Container fluid className="body-sec horizontal-scroll">
            <div className="page-heading d-flex">
              {this.state.hidList.length > 0 && (
                <HidSelector
                  hotelList={this.state.hidList}
                  onClickEvent={this.selectHotelDropDown}
                  ref={this.childHID}
                />
              )}
              <div className="mr-auto d-flex align-items-center">
                Performance
              </div>
              {this.state.hidValue !== "Select" && (
                <Dropdown className="more-action" alignRight>
                  <Dropdown.Toggle
                    className="btn-outline-primary btn btn-primary more"
                    id="dropdown-more"
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                      <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                      <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                    </svg>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => this.showHideChangeLogModal()}
                    >
                      Approval Change Log
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </div>
            {this.state.hidValue !== "Select" && this.state.isTabShow && (
              <Tabs
                id="controlled-tab-example"
                activeKey={this.state.key}
                onSelect={this.handleTabSelect}
              >
                <Tab eventKey="department" title="Department">
                  <DepartmentView
                    ref={this.departmentView}
                    departmentpositionList={this.state.departmentpositionList}
                    departmentList={this.state.departmentList}
                    positionList={this.state.positionList}
                    hidValue={this.state.hidValue}
                    paramDate={this.state.paramDate}
                    hotelName={this.state.hotelName}
                    openSlideOut={this.openSlideOut}
                    hideSticky={this.hideSticky}
                    exportedDate={this.exportedDate}
                    refreshPerformanceData={this.refreshPerformanceData}
                    showRefershBanner={this.state.showRefershBanner}
                    paytype={this.state.paytype}
                    selectDate={this.state.selectDate}
                    setdataComparison={this.state.dataComparison}
                    dataFilter={this.state.dataFilter}
                    commonFunction={this.commonFunction}
                    commonFunctionForDateChange={
                      this.commonFunctionForDateChange
                    }
                    commonFunctionForDateAcyionChange={
                      this.commonFunctionForDateAcyionChange
                    }
                    updateNewStateForFilter={this.updateNewStateForFilter}
                    getGetCalenderDates={this.getGetCalenderDates}
                    history={this.props.history}
                  />
                </Tab>
                <Tab eventKey="employee" title="Employee">
                  <EmployeeView
                    ref={this.employeeView}
                    departmentpositionList={this.state.departmentpositionList}
                    departmentList={this.state.departmentList}
                    positionList={this.state.positionList}
                    hidValue={this.state.hidValue}
                    paramDate={this.state.paramDate}
                    hotelName={this.state.hotelName}
                    openSlideOut={this.openSlideOut}
                    hideSticky={this.hideSticky}
                    exportedDate={this.exportedDate}
                    refreshPerformanceData={this.refreshPerformanceData}
                    showRefershBanner={this.state.showRefershBanner}
                    paytype={this.state.paytype}
                    selectDate={this.state.selectDate}
                    setdataComparison={this.state.dataComparison}
                    dataFilter={this.state.dataFilter}
                    commonFunction={this.commonFunction}
                    commonFunctionForDateChange={
                      this.commonFunctionForDateChange
                    }
                    commonFunctionForDateAcyionChange={
                      this.commonFunctionForDateAcyionChange
                    }
                    updateNewStateForFilter={this.updateNewStateForFilter}
                    getGetCalenderDates={this.getGetCalenderDates}
                  />
                </Tab>
              </Tabs>
            )}
          </Container>
          <>
            {this.state.stickeyTableHeader && (
              <>
                <div className="table-header-bg"></div>
                <div className="day-total-header"></div>
              </>
            )}
            {this.state.stickeyTableRow && (
              <div className="stickey-tableRow"></div>
            )}
          </>
        </div>
      </>
    );
  }
}
