import React from "react";
import {
  Container,
  Button,
  OverlayTrigger,
  Tooltip,
  Dropdown,
  Form,
} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import "react-toastify/dist/ReactToastify.css";
import "react-calendar/dist/Calendar.css";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { HidSelector } from "../../Common/Components/HidSelector";
import { Facility } from "../../Common/Services/Facility";
import { IGetfacilitiesListRequest } from "../../Common/Contracts/IFacilities";
import "./Location.scss";
import "./facilities.scss";
import _ from "lodash";
import { LocationSlideOut } from "./LocationSlideOut";
import { BulkImportInvoiceSlideout } from "../Register/BulkImportInvoice/bulkImportInvoiceSlideout";
import { LocationMain } from "../Settings/EntityManagement/LocationMain";
import { BulkImportModal } from "../../Common/Components/BulkImportModal";


export class Locations extends React.Component<any, any> {
  private wororderSlideOut: any;
  private child: any;
  private childHID: any;
  private departmentSearch: any;
  private empTypeSearch: any;
  private tableTypeSearch: any;
  private scheduleUserViewRef: any;
  constructor(props: any) {
    super(props);
    this.scheduleUserViewRef = React.createRef();
    this.wororderSlideOut = React.createRef();
    this.child = React.createRef();
    this.childHID = React.createRef();
    this.departmentSearch = React.createRef();
    this.empTypeSearch = React.createRef();
    this.tableTypeSearch = React.createRef();
    this.state = {
      pageLoader: false,
      hidValue: "Select",
      hotelName: "Select",
      tableData: [],
      roomTypeData: [],
      roomCategories: [],
      locationTypeData: [],
      statusList: [
        { id: "", name: "All" },
        { id: "Online", name: "Online" },
        { id: "Offline", name: "Offline" },
      ],
      originalRoomType: [],
      statusFilterSelected: (<strong>Type</strong>),
      ehidFilterSelected: "Room Type",
      searchid: "",
      searchText: "",
      status: "",
      roomType: "",
      roomCategory: "",
      locationType: "",
      sortingOrder: "asc",
      sortingField: "roomName",
      showSlideOut: false,
      isLoading: false,
      bulkInvoiceRequest: {},
      bulkImportModal: false,
      // isSlideOut: false,
      // locationTypesList: [],
      // assetTypesList: [],
      // assignToListRightPanel: [],
      // parentRowData: [],
      // assignToList: [],
    };
  }

  componentDidMount() {

    let storage = JSON.parse(localStorage.getItem("hotelSelected")!);
    let storagehotelid = storage === null ? -1 : (storage.hotelID as any);
    this.getRoomCategories();
  }

  selectHotelDropDown = (hotelDetails: any) => {
    this.setState({ pageLoader: true });

    this.setState(
      {
        hotelName: hotelDetails.lettercode,
        hidValue: hotelDetails.hotelID,

      },
      () => {
        this.getFacilities();
        this.getRoomTypes();
        this.getLocationTypes();
      }
    );
    this.setState({ statusFilterSelected: <strong>Type</strong> })
  };

  //   getrooms = () => {
  //     let hotelID = Number(this.state.hidValue);
  //    WorkOrder.GetFacilityRooms(hotelID)
  //        .then(async (result: any | null) => {
  //            let DayApproveData: any = [];

  //            if (result != null && result.length > 0) {
  //                let Data = result.map(x => {
  //                    return { id: x?.roomID, name: x?.roomName };
  //                })

  //                this.setState({
  //                  locationTypesList: Data
  //                });
  //                this.setState({locationSearchTypesList:[]},()=>{
  //                  this.setState({ locationSearchTypesList: [{ id: -1, name: "All" },...Data] });
  //                })

  //            } else {
  //                this.setState({
  //                  locationTypesList: []
  //                });
  //            }
  //            resolve();
  //        })
  //        .catch((err) => {
  //            Utils.toastError(`Server Error, ${err}`, {
  //            });

  //            reject();
  //        });
  // }

  // GetFacilityAsset = () => {
  //   let hotelID = Number(this.state.hidValue);
  //     WorkOrder.GetFacilityAsset(hotelID)
  //         .then(async (result: any | null) => {

  //             if (result != null && result.length > 0) {
  //                 let Data = result.map(x => {
  //                     return { id: x?.roomID, name: x?.roomName };
  //                 })
  //                 this.setState({ assetTypesList: Data });
  //               // this.setState({ assetTypesList: [{ id: 0, name: "Select an asset" }, ...Data] });
  //             } else {
  //                 //this.setState({ assetTypesList: [{ id: 0, name: "Select an asset" }] });
  //                 this.setState({ assetTypesList: [] });
  //             }
  //             resolve();
  //         })
  //         .catch((err) => {
  //             Utils.toastError(`Server Error, ${err}`, {
  //             });

  //             reject();
  //         });
  // }

  // getAssignUserList() {

  //   let request = {} as IWorkOrderRequestDto;
  //   request.hotelID = Number(this.state.hidValue);
  //   request.type = "AssignUsers";
  //   WorkOrder.GetFcUserDetailsList(request)
  //     .then(async (result: any | null) => {

  //       if (result != null && result.length > 0) {
  //         let Data = result.map(x => {
  //           return { id: x?.user_UniqueID, name: x?.employee };
  //         })

  //         this.setState({ assignToList: [] }, () => {
  //           this.setState({ assignToList: [{ id: -1, name: "All" }, ...Data] });
  //           this.setState({assignToListRightPanel:Data});
  //           // this.setState({ assignToListToChild: [{ id: -1, name: "Select a technician" }, ...Data] });
  //         })

  //       } else {
  //         this.setState({ assignToList: [{ id: -1, name: "All" }],assignToListRightPanel:[]});

  //       }
  //       resolve();
  //     })
  //     .catch((err) => {
  //       Utils.toastError(`Server Error, ${err}`, {
  //       });

  //       reject();
  //     });
  // }


  getFacilities = () => {

    const { searchText,
      status,
      roomType,
      locationType,
      locationTypeData,
      roomTypeData,
      roomCategory, hidValue } = this.state;
    this.setState({ isLoading: true })
    const selectedTypes = locationTypeData
      ?.filter((item) => item.isChecked)
      ?.map((item) => item.name);

    const selectedRoomTypes = roomTypeData?.filter((item) => item.isChecked)?.map((item) => item.name)
    const request: IGetfacilitiesListRequest = {
      searchText,
      status,
      hid: hidValue,
      roomType: selectedRoomTypes,
      locationType: selectedTypes,
      roomCategory
    };

    Facility.GetFacilities(request).then(tableData => {

      this.setState({ tableData: _.sortBy(tableData, 'roomName'), isLoading: false });
    });
  }




  getRoomTypes = () => {
    Facility.GetDistinctRoomTypes(
      this.state.hidValue
    ).then((data: any) => {
      console.log("data", data, data?.length, Array.isArray(data))
      if (Array.isArray(data) && data?.length > 0) {
        data?.sort();
        data = data?.map(x => {
          return { id: x, name: x, isChecked: false };
        })
      } else {
        data = []
      }
      // const roomData = data?.sort();
      console.log("roomData", data)
      this.setState({ roomTypeData: [...data], originalRoomType: [...data] });
    }
    );
  };

  getLocationTypes = () => {
    Facility.GetDistinctLocationType(
      this.state.hidValue
    ).then((locationTypeData: any) => {

      if (locationTypeData?.length > 0) {
        locationTypeData = locationTypeData.map(x => {
          return { id: x, name: x, isChecked: false };
        })
      }
      locationTypeData?.sort();
      this.setState({ locationTypeData: [...locationTypeData] });
    }
    );
  };

  getRoomCategories = () => {
    // Facility.GetDistinctRoomCategories().then((roomCategories: any) => {

    //   if (roomCategories?.length > 0) {
    //     roomCategories = roomCategories.map(x => {
    //       return { id: x, name: x };
    //     })
    //   }
    //   this.setState({ roomCategories :[ { id: "", name: "All" },...roomCategories]  });
    // }
    // );
  };

  onSearchChange = (searchText: string) => {
    this.setState({ searchText: searchText?.toString()?.trimStart() }, () => {
      // if (searchText?.length > 1 || !searchText) {
      this.getFacilities();
      // }
    });
  };

  onDDlFiltersChange = (controlID: any, id: any) => {
    let {
      status,
      roomType,
      locationType,
      roomCategory

    } = this.state;
    switch (controlID) {
      case "ddlRoomType":
        roomType = id;
        break;
      case "ddlRoomCategory":
        roomCategory = id;
        break;
      case "ddlLocationType":
        locationType = id;
        break;
      case "ddlStatus":
        status = id;
        break;
      default:
        break;
    }

    this.setState({
      status,
      roomType,
      roomCategory,
      locationType
    }, () => {
      this.getFacilities();
    });
  };

  handleSortByColumn = (field) => {
    this.setState(
      {
        sortingField: field,
        sortingOrder: this.state.sortingOrder === "asc" ? "desc" : "asc",
      },
      () => {
        this.sortTableData(this.state.tableData, this.state.sortingField);
      }

    );
  };

  handleDDToggle = (type: any, value: any = "") => {
    let event = { target: { value: value } }
    // this.handleSearchItem(event, type);
  }


  sortTableData = (data: any, fieldName) => {
    if (this.state.sortingOrder === "asc") {
      data = _.orderBy(data, [fieldName], ["asc", "asc"]);
    } else if (this.state.sortingOrder === "desc") {
      data = _.orderBy(data, [fieldName], ["desc", "asc"]);
    }
    this.setState({ tableData: data });
  };

  sortImage = (order) => {
    if (!order)
      return (
        <span className="ascending">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.4712 8.47133L11.5286 7.52867L8.66656 10.3907V4H7.33323V10.3907L4.47123 7.52867L3.52856 8.47133L7.9999 12.9427L12.4712 8.47133Z"
              fill="#1D1E20"
            />
          </svg>
        </span>
      );
    else if (order === "asc")

      return (
        <span className="ascending">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.4712 8.47133L11.5286 7.52867L8.66656 10.3907V4H7.33323V10.3907L4.47123 7.52867L3.52856 8.47133L7.9999 12.9427L12.4712 8.47133Z"
              fill="#1D1E20"
            />
          </svg>
        </span>
      );
    else if (order === "desc")
      return (
        <span className="descending">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.4712 8.47133L11.5286 7.52867L8.66656 10.3907V4H7.33323V10.3907L4.47123 7.52867L3.52856 8.47133L7.9999 12.9427L12.4712 8.47133Z"
              fill="#1D1E20"
            />
          </svg>
        </span>
      );

    return null;
  };



  handleShowSlideout = (entityRowData: any) => {
    this.setState({ entityRowData }, () => {
      this.setState({
        showSlideout: true
      });
    });
  };

  handleHideSlideout = (isReload) => {
    this.setState({
      showSlideout: false,
    });
  };

  selectedStatus = () => {
    let { locationTypeData } = this.state;
    console.log("locationTypeData", locationTypeData)
    let checkedLen = locationTypeData.filter((o: any) => o.isChecked);
    console.log("checkedLen", checkedLen)
    let isStatusSelected = (checkedLen.length > 1 ? (
      <>
        <strong>{checkedLen.length} Type Selected</strong>
      </>
    ) : checkedLen.length === 1 ? (
      checkedLen[0].name
    ) : (
      <strong>Type</strong>
    ));
    return isStatusSelected;
  }

  itemCheckChange = (event: any, item: any, type: any) => {
    this.setState({ isLoader: true });
    if (type === "Location Type") {
      let { locationTypeData, statusFilterSelected } = this.state;
      locationTypeData.forEach((o: any) => (o?.name?.toString()?.toLocaleLowerCase() === item.name?.toString()?.toLocaleLowerCase()) && (o.isChecked = event.target.checked))
      statusFilterSelected = this.selectedStatus();
      this.setState({ locationTypeData, statusFilterSelected }, () => {
        this.getFacilities();
      });
    }
    else if (type === "Room Type") {
      let { roomTypeData, isEHIDPlaceholder } = this.state;
      roomTypeData.forEach((o: any) => (o.id === item.id) && (o.isChecked = event.target.checked));
      this.setState({ roomTypeData, isEHIDPlaceholder }, () => {
        // this.handleDropdownChange({ groupID: -1, groupName: "Select a Group", subGroups: [] }, false);
        this.getFacilities();
      });
    }
  }

  handleItemSelectAll(event: any, type: any) {
    this.setState({ isLoader: true });
    if (type === "status") {
      let { locationTypeData, statusFilterSelected } = this.state;
      if (this.state.locationTypeData.some(o => o.isChecked === false)) {
        locationTypeData.forEach((o: any) => o.isChecked = true)
      } else {
        locationTypeData.forEach((o: any) => o.isChecked = false)
      }
      statusFilterSelected = this.selectedStatus();
      console.log("statusFilterSelected", statusFilterSelected)
      this.setState({ locationTypeData, statusFilterSelected }, () => {
        this.getFacilities();
      });
    }
    else if (type === "Room Type") {
      let { roomTypeData } = this.state;
      let updatedData;
      if (this.state.roomTypeData.some(o => o.isChecked === false)) {

        updatedData = roomTypeData.map((item) => ({
          ...item,
          isChecked: true,
        }));
      } else {
        updatedData = roomTypeData.map((item) => ({
          ...item,
          isChecked: false,
        }));
      }
      this.setState({ roomTypeData: updatedData }, () => {
        this.getFacilities();
      });
    }
  }

  getOptionStyles = (option) => {
    switch (option) {
      case 'Online':
        return { backgroundColor: '#defff8', color: '#003e38' };
      case 'Offline':
        return { backgroundColor: '#fff3e8', color: '#863a02' };
      case 'Archived':
        return { backgroundColor: '#cccccc', color: '#1d1e20' };
      default:
        return {};
    }
  };

  handleSearchItem = (event: any, type: any) => {
    let searchval = event.target.value;
    if (type === "Room Type") {
      if (searchval !== "") {
        const roomTypeData = [...this.state.roomTypeData];
        const updatefilterState = roomTypeData.filter((o: any) => {
          return (
            o.name.toLowerCase().indexOf(searchval.toLowerCase()) > -1
          )
        });
        console.log("updatefilterState", updatefilterState)
        this.setState({ roomTypeData: updatefilterState, searchid: searchval });
      } else {
        this.setState({ roomTypeData: this.state.originalRoomType, searchid: searchval })
      }
    }
  }

  isFilterApplied = () => {
    const isRoomTypeChecked = this.state.roomTypeData.some(obj => obj.isChecked === true);
    const isLocationTypeChecked = this.state.locationTypeData.some(obj => obj.isChecked === true);
    if (this.state.searchText !== "" || isRoomTypeChecked || isLocationTypeChecked) {
      return true;
    }
    console.log("false============")
    return false;
  }

  BulkImportInvoice = () => {
    let { bulkInvoiceRequest } = this.state;
    bulkInvoiceRequest.hotelName = this.state.hotelName;
    bulkInvoiceRequest.hidValue = this.state.hidValue
    this.setState({ bulkInvoiceRequest, isBulkInvoiceSlideout: true })
  }

  handleBulkImport = () => {
    this.setState({ bulkImportModal: true,
      // uploadFiles: [],isUploadesFiles:false,
        // fileUploadedError_msg:"" 
      });
    }

  bulkHideModule=()=>{ 
    this.setState({ bulkImportModal: false });    
}

  render() {
    let totalChecked: any = this.state.roomTypeData?.filter(o => o.isChecked);
    let selectedAcc = (totalChecked?.length === 1 ? (
      totalChecked[0]?.name
    ) : (
      <>
        <strong>{totalChecked?.length} Subtype Selected</strong>
      </>
    ))
    let isEHIDPlaceholder = (totalChecked?.length > 0 ? (
      selectedAcc
    ) : (
      <strong>Subtype</strong>
    ));
    const ehidCheckLabel = (item: any) => (<>{(item.name)}</>);
    const statusCheckLabel = (item: any, statusClass: any) => (<strong className={`${statusClass} tag`}>{item.name}</strong>);
    const isStatusClass = (status: any) => {
      let statusClass = "";
      if (status.toString().toLowerCase() === "All") {
        statusClass = "orange";
      } else if (status.toString().toLowerCase() === "Common") {
        statusClass = "blue";
      } else if (status.toString().toLowerCase() === "Guest") {
        statusClass = "green";
      }
      return statusClass;
    }
    const columns = [
      {
        dataField: "roomName",
        text: "Name",
        headerFormatter: (column) => {
          return (
            <div
              className={`d-inline-block ${this.state.sortingField === "roomName" ? "active" : ""
                }`}
              onClick={() => this.handleSortByColumn("roomName")}>
              {column.text}
              {this.sortImage(this.state.sortingOrder)}
            </div>
          );
        },
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div className="d-flex flex-wrap thours align-items-center">
              <div className="main-ttl">{row.roomName}</div>

            </div>
          );
        },

      },
      {
        dataField: "openWorkOrder",
        text: "Open Work Orders",
        headerFormatter: (column) => {
          return (
            <div
              className={`d-inline-block ${this.state.sortingField === "openWorkOrder" ? "active" : ""
                }`}
              onClick={() => this.handleSortByColumn("openWorkOrder")}>
              {column.text}
              {this.sortImage(this.state.sortingOrder)}
            </div>
          );
        },
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div className="d-flex flex-wrap thours align-items-center">
              <div className="main-ttl">{row.openWorkOrder > 0 ? row.openWorkOrder : "-"}</div>

            </div>
          );
        },


      },
      {
        dataField: "locationType",
        text: "Type",
        headerFormatter: (column) => {
          return (
            <div
              className={`d-inline-block ${this.state.sortingField === "roomType" ? "active" : ""
                }`}
              onClick={() => this.handleSortByColumn("roomType")}>
              {column.text}
              {this.sortImage(this.state.sortingOrder)}
            </div>
          );
        },
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div>
              {row.locationType}
            </div>
          )
        }

      },
      {
        dataField: "roomType",
        text: "Subtype",
        headerFormatter: (column) => {
          return (
            <div
              className={`d-inline-block ${this.state.sortingField === "roomType" ? "active" : ""
                }`}
              onClick={() => this.handleSortByColumn("roomType")}>
              {column.text}
              {this.sortImage(this.state.sortingOrder)}
            </div>
          );
        },
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div>
              {row.roomType}
            </div>
          )
        }

      }
      ,
      // {
      //   dataField: "category",
      //   text: "Category",
      //   formatter: (cell: any, row: any, rowIndex: any) => {
      //     return (
      //       <div className="main-ttl">
      //         {Utils.CapitalizeFirstLetter(row?.category)}
      //       </div>
      //     );
      //   },

      //   headerFormatter: (column) => {
      //     return (
      //       <div
      //         className={`d-inline-block ${this.state.sortingField === "category" ? "active" : ""
      //           }`}
      //           onClick={() => this.handleSortByColumn("category")}>
      //         {column?.text}
      //         {this.sortImage(this.state.sortingOrder)} 
      //       </div>
      //     );
      //   },

      // },
      {
        dataField: "status",
        text: "Status",
        headerFormatter: (column) => {
          return (
            // <div
            //   className={`d-inline-block `}>
            //   {column.text}
            // </div>
            <div
              className={`d-inline-block ${this.state.sortingField === "status" ? "active" : ""
                }`}
              onClick={() => this.handleSortByColumn("status")}>
              {column.text}
              {this.sortImage(this.state.sortingOrder)}
            </div>
          );
        },
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div className="d-flex flex-wrap thours align-items-center justify-content-start">
              <div className="badge ml-0" style={{ backgroundColor: this.getOptionStyles(row?.status).backgroundColor }}>{row?.status}</div>
            </div>
          );
        },

      }

    ];

    const tableRowEvents = {
      onClick: (e, row, rowIndex) => {
        this.handleShowSlideout(row)
      },

    }
    return (
      <>
        <div className="performance-position performance timesheet location-page-wrapper">
          <Container fluid className="body-sec">
            <div className="underline page-heading d-flex pl-0">
              <HidSelector
                onClickEvent={this.selectHotelDropDown}
                ref={this.childHID}
                modulename={"Facility Maintenance"}
              />
              <div className="mr-auto d-flex align-items-center">
                Locations
              </div>


              {/* {this.state.hidValue !== "Select" && ()}*/}
              <Button>Add Location</Button>
              <div className="action-group d-flex flex-row">
                <Dropdown className="more-action" alignRight>
                  <Dropdown.Toggle
                    className="btn-outline-primary btn btn-primary more"
                    id="dropdown-more"
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                      <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                      <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                    </svg>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="import-location-btn" onClick={this.handleBulkImport}>Import Locations
                  </Dropdown.Menu>
                </Dropdown>
              </div>

            </div>
            {/* {this.state.pageLoader ? (
              <ReactPageLoader useas={"timesheetMain"} />
            ) : ( */}
            {this.state.hidValue !== "Select" && (
              <div className="main-Page">
                <div className="bdy-sec">
                  <ToolkitProvider
                    keyField="userUniqueno"
                    data={this.state.tableData}
                    columns={columns}
                    hover
                  >
                    {(props: {
                      searchProps: JSX.IntrinsicAttributes;
                      baseProps: JSX.IntrinsicAttributes;
                    }) => (
                      <div>
                        <div className="search-header d-flex">
                          <div className="schedule-main">
                            <div className="schedule-department-view-table">
                              <div className="departmentViewNavigation">
                                <div className="navigation-menus d-flex">
                                  <div className="schedule-table-search">

                                    <input
                                      type="text"
                                      onChange={(e) => this.onSearchChange(e.target.value)}
                                      value={this.state.searchText}
                                      className="search-box form-control"
                                      placeholder="Search..."
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>


                          <div className="location-type-dropdown">

                            {this.state.locationTypeData.length > 0 && (
                              <Dropdown className="drop-down-select status-selector">
                                <Dropdown.Toggle id="groups-dropdown" className="border-0">
                                  <div className="d-flex align-items-center">
                                    <div className='selected-val'><EllipsisWithTooltip placement="bottom">
                                      <strong>{this.state.statusFilterSelected}</strong>
                                    </EllipsisWithTooltip></div>
                                    <div className='arrow ml-auto'>
                                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M10 12.8124L5 7.81242L6.16667 6.64575L10 10.4791L13.8333 6.64575L15 7.81242L10 12.8124Z" fill="#1D1E20" />
                                      </svg>
                                    </div>
                                  </div>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <div className="ListItems ListWithMultiSelect">
                                    {this.state.locationTypeData.map((item: any, idx: any) => {
                                      let statusClass = isStatusClass(item?.name);
                                      return (
                                        <div className="dropdownItems border-0" key={item.id}>
                                          <Form.Check
                                            type="checkbox"
                                            id={`checkId_${item.id}`}
                                            name={item.name}
                                            label={statusCheckLabel(item, statusClass)}
                                            value={item.id}
                                            onChange={(event) => this.itemCheckChange(event, item, "Location Type")}
                                            checked={item.isChecked}
                                          />
                                        </div>
                                      )
                                    })}
                                  </div>
                                  {this.state.locationTypeData.length > 0 && (
                                    <div className="selectClearAll isHideCheck dropdownItems">
                                      <Form.Check
                                        type="checkbox"
                                        id={`itemSelectAllStatus`}
                                        label={this.state.locationTypeData.some(o => o.isChecked === false) ? "Select All" : "Clear All"}
                                        value={this.state.locationTypeData.some(o => o.isChecked === false) ? "selectAll" : "clearAll"}
                                        onChange={(event) => this.handleItemSelectAll(event, "status")}
                                        checked={this.state.locationTypeData.some(o => o.isChecked === false) ? false : true}
                                      />
                                    </div>
                                  )}
                                </Dropdown.Menu>
                              </Dropdown>
                            )}
                          </div>
                          <div className="room-type-dropdown">
                            {this.state.originalRoomType?.length > 0 && (
                              <Dropdown
                                onToggle={() => this.handleDDToggle("Room Type")}
                                className="drop-down-select ehid-selector"
                              // onSelect={(event: any) => this.handleDropSelect(event, "ehid")}
                              >
                                <Dropdown.Toggle id="groups-dropdown" className="border-0">
                                  <div className='d-flex flex-class'>
                                    <div className='selected-val'><EllipsisWithTooltip placement="bottom"><strong>{isEHIDPlaceholder}</strong></EllipsisWithTooltip></div>
                                    <div className='arrow ml-auto'>
                                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M10 12.8124L5 7.81242L6.16667 6.64575L10 10.4791L13.8333 6.64575L15 7.81242L10 12.8124Z" fill="#1D1E20" />
                                      </svg>
                                    </div>
                                  </div>
                                </Dropdown.Toggle>
                                <Dropdown.Menu style={{ "width": "250px" }}>
                                  <div className="SearchControl commSearchControl">
                                    <Form.Control type="text"
                                      className="comm-search-input"
                                      name="searchItems"
                                      id="searchItemsFrom"
                                      value={this.state.searchid}
                                      autoComplete="off"
                                      onChange={(e: any) => this.handleSearchItem(e, 'Room Type')}
                                      placeholder="Search..."
                                    />
                                  </div>
                                  {this.state.roomTypeData?.length === 0 && this.state.searchid && (
                                    <div className="footer small p-2 pl-3">
                                      <span>No data found</span>
                                    </div>
                                  )}
                                  <div className="ListItems ListWithMultiSelect">
                                    {(this.state.roomTypeData || []).map((item: any, idx: any) => (
                                      <>
                                        <div className="dropdownItems border-0" key={item.id}>
                                          <Form.Check
                                            type="checkbox"
                                            id={`checkId_${item.id}`}
                                            name={item.name}
                                            label={ehidCheckLabel(item)}
                                            value={item.id}
                                            onChange={(event) => this.itemCheckChange(event, item, "Room Type")}
                                            checked={item.isChecked}
                                          />
                                        </div>
                                      </>
                                    ))}
                                  </div>
                                  {this.state.roomTypeData.length > 0 && (
                                    <div className="selectClearAll isHideCheck dropdownItems">
                                      <Form.Check
                                        type="checkbox"
                                        id={`itemSelectAllEhid`}
                                        label={this.state.roomTypeData.some(o => o.isChecked === false) ? "Select All" : "Clear All"}
                                        value={this.state.roomTypeData.some(o => o.isChecked === false) ? "selectAll" : "clearAll"}
                                        onChange={(event) => this.handleItemSelectAll(event, "Room Type")}
                                        checked={this.state.roomTypeData.some(o => o.isChecked === false) ? false : true}
                                      />
                                    </div>
                                  )}
                                </Dropdown.Menu>
                              </Dropdown>
                              // <MultiSelectDropdownList
                              //   id={"tabletypeddl"}
                              //   isRefresh={this.state.isRefresh}
                              //   itemList={this.state.roomTypeData}
                              //   handleSelectedItem={this.onDDlFiltersChange.bind(
                              //     this,
                              //     "ddlRoomType"
                              //   )}
                              //   defaultItem={this.state.roomType}
                              //   defaultText={"Search Type"}
                              //   defaultName={"Sub Type"}
                              //   controlID="1"
                              // />
                            )}
                          </div>
                          {/* <div className="room-category-dropdown">
                            {this.state.roomCategories.length > 0 && (
                              <SingleSearchDropdownList
                                id={"tabletypeddl"}
                                isRefresh={this.state.isRefresh}
                                itemList={this.state.roomCategories}
                                handleSelectedItem={this.onDDlFiltersChange.bind(
                                  this,
                                  "ddlRoomCategory"
                                )}
                                allowSearch={false}
                                defaultItem={this.state.roomCategory}
                                defaultText={"Search Room Category"}
                                defaultName={"Room Category"}
                                controlID="1"
                              />
                            )}
                          </div> */}
                          {/* <div className="room-status-dropdown">
                            {this.state.statusList.length > 0 && (
                              <SingleSearchDropdownList
                                id={"tabletypeddl"}
                                isRefresh={this.state.isRefresh}
                                itemList={this.state.statusList}
                                handleSelectedItem={this.onDDlFiltersChange.bind(
                                  this,
                                  "ddlStatus"
                                )}
                                allowSearch={false}
                                defaultItem={this.state.status}
                                defaultText={"Search Status"}
                                defaultName={"Status"}
                                controlID="1"
                              />
                            )}
                          </div> */}
                        </div>


                        <div className="hasCursorPointer location-table-wrapper">
                          {this.state.tableData.length > 0 ? (

                            <BootstrapTable
                              id={"location-table"}
                              // id={"timesheet"}
                              {...props.baseProps}
                              keyField="userUniqueno"
                              hover
                              rowEvents={tableRowEvents}
                            />
                          ) : this.state.tableData.length === 0 && !this.state.isLoading === true && !this.isFilterApplied() && (
                            <div className="LocationNoDataFrame d-flex align-items-center justify-content-center flex-direction-column">
                              <div className="font-weight-bold text-dark">You have not created any locations.</div>
                              <div>Select “Add Location” to create a location</div>
                            </div>
                          )}
                        </div>
                        {this.isFilterApplied() && this.state.tableData.length === 0 && (
                          <div className="WorkOrderNoDataFrame d-flex align-items-center justify-content-center flex-direction-column">
                            <div>
                              No results matching your filters
                            </div>
                          </div>
                        )}


                      </div>
                    )}
                  </ToolkitProvider>
                </div>
              </div>

            )
            }
            {/* )} */}


          </Container>
          {
            this.state.showSlideout && <LocationSlideOut hid={this.state.hidValue} handleHideSlideout={this.handleHideSlideout} {...this.props} parentstate={this.state.entityRowData} hotelName={this.state.hotelName} getFacilities={this.getFacilities} />
          }
          {console.log("this.state?.entityRowData", this.state?.entityRowData)}

          {this.state.bulkImportModal && (
            <BulkImportModal
            bulkImportModal={this.handleBulkImport}
            bulkHideModule={this.bulkHideModule}
            hotelId={this.state.hidValue}
            // isBulkInvoiceModal={this.state.isBulkInvoiceSlideout}
            // bulkHideModule={this.bulkHideModule()} 
            />
          )}

          {/* {this.state.isSlideOut && (
            <div>
              <WorkOrderSlideOut
                ref={this.wororderSlideOut}
                closeWorkSlideOut={this.closeWorkSlideOut}
                parentState={this.state}
                parentRowData={this.state.parentRowData}
                workOrderStatusUpdate={this.workOrderStatusUpdate}
              // changesOfSlideOut={this.changesOfSlideOut}
              // refeshTable={this.refeshTable}                               
              />
            </div>


          )} */}
        </div>
      </>
    );
  }
}
