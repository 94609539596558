import React from "react";
import ContentLoader from "react-content-loader";
import "./reactPageLoader.scss";

interface IProps {
  useas: string;
}

export const ReactPageLoader = (props: IProps) => {
  const { useas } = props;
  if (useas === "payrollExportLoader") {
    return (
      <div className="loader-responsive">
        <div className="payrollExport">
          <div className="header d-flex">
            <div className="loadingAnimation"></div>
            <div className="loadingAnimation mr-auto"></div>
            <div className="loadingAnimation"></div>
          </div>
          <div className="highlightBox d-flex">
            <div className="loadingAnimation"></div>
            <div className="loadingAnimation"></div>
          </div>
          <div className="tableHeader loadingAnimation"></div>
          <div className="tableList">
            <ul>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  } else if (useas === "payrollExportSlideOutLoader") {
    return (
      <ContentLoader
        speed={2}
        width={800}
        height={800}
        viewBox="0 0 800 800"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
      >
        <rect x="0" y="0" rx="5" ry="5" width="170" height="32" />
        <rect x="0" y="46" rx="5" ry="5" width="100" height="10" />
        <rect x="0" y="135" rx="5" ry="5" width="100" height="10" />
        <rect x="140" y="135" rx="5" ry="5" width="250" height="10" />
        <rect x="0" y="174" rx="5" ry="5" width="100" height="10" />
        <rect x="140" y="174" rx="5" ry="5" width="250" height="10" />
        <rect x="0" y="217" rx="5" ry="5" width="100" height="10" />
        <rect x="140" y="217" rx="5" ry="5" width="250" height="10" />
        <rect x="0" y="256" rx="5" ry="5" width="100" height="10" />
        <rect x="140" y="256" rx="5" ry="5" width="250" height="10" />
        <rect x="0" y="294" rx="5" ry="5" width="100" height="10" />
        <rect x="140" y="294" rx="5" ry="5" width="250" height="10" />
        <rect x="0" y="370" rx="5" ry="5" width="100" height="10" />
        <rect x="0" y="400" rx="5" ry="5" width="500" height="40" />
        <rect x="0" y="460" rx="5" ry="5" width="340" height="40" />
        <rect x="0" y="520" rx="5" ry="5" width="415" height="40" />
        <rect x="0" y="577" rx="5" ry="5" width="165" height="40" />
      </ContentLoader>
    );
  } else if (useas === "ptoPayoutModal") {
    return (
      <div className="loader-responsive">
        <div className="ptoPayoutModal">
          <div className="inputContainer d-flex flex-column">
            <div className="loadingAnimation"></div>
            <div className="loadingAnimation"></div>
          </div>
          <div className="tableHeader loadingAnimation"></div>
          <div className="btnContainer d-flex">
            <div className="loadingAnimation"></div>
          </div>
        </div>
      </div>
    );
  } else if (useas === "timeOffSettingsLeftPanel") {
    return (
      <div className="loader-responsive">
        <div className="timeOffSettingsLeftPanel">
          <div className="tableList">
            <ul>
              <li className="d-flex">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  } else if (useas === "timeOffSettingsRightPanel") {
    return (
      <div className="loader-responsive">
        <div className="timeOffSettingsRightPanel">
          <div className="header d-flex align-items-center">
            <div className="loadingAnimation mr-auto"></div>
            <div className="loadingAnimation"></div>
          </div>
          <div className="tableList">
            <ul>
              <li className="d-flex">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div className="tableHeader loadingAnimation"></div>
        </div>
      </div>
    );
  }
  else if (useas === "payrollImportLeftPanel") {
    return (
      <div className="loader-responsive">
        <div className="performanceList payrollImportLeftPanel">
          <div className="tableList">
            <ul>
              <li className="d-flex">
                <div className="list d-flex align-self-center mr-auto">
                  <div className="loadingAnimation"></div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="loadingAnimation"></div>
                </div>
              </li>
              <li className="d-flex">
                <div className="list d-flex align-self-center mr-auto">
                  <div className="loadingAnimation"></div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="loadingAnimation"></div>
                </div>
              </li>
              <li className="d-flex">
                <div className="list d-flex align-self-center mr-auto">
                  <div className="loadingAnimation"></div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="loadingAnimation"></div>
                </div>
              </li>
              <li className="d-flex">
                <div className="list d-flex align-self-center mr-auto">
                  <div className="loadingAnimation"></div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="loadingAnimation"></div>
                </div>
              </li>
              <li className="d-flex">
                <div className="list d-flex align-self-center mr-auto">
                  <div className="loadingAnimation"></div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="loadingAnimation"></div>
                </div>
              </li>
              <li className="d-flex">
                <div className="list d-flex align-self-center mr-auto">
                  <div className="loadingAnimation"></div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="loadingAnimation"></div>
                </div>
              </li>
              <li className="d-flex">
                <div className="list d-flex align-self-center mr-auto">
                  <div className="loadingAnimation"></div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="loadingAnimation"></div>
                </div>
              </li>
              <li className="d-flex">
                <div className="list d-flex align-self-center mr-auto">
                  <div className="loadingAnimation"></div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="loadingAnimation"></div>
                </div>
              </li>
              <li className="d-flex">
                <div className="list d-flex align-self-center mr-auto">
                  <div className="loadingAnimation"></div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="loadingAnimation"></div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    )
  } else if (useas === "payrollImportRightPanel") {
    return (
      <div className="loader-responsive">
        <div className="performanceList payrollImportRightPanel">
          <div className="pos-header d-flex align-content-center flex-wrap">
            <div className="pos-ttl loadingAnimation mr-auto"></div>
            <div className="loadingAnimation action-loader"></div>
          </div>
          <div className="pos-body">
            <div className="detail-tbl">
              <div className="data-info">
                <div className="tableList">
                  <ul>
                    <li className="d-flex">
                      <div className="list d-flex align-self-center">
                        <ul>
                          <li className="d-flex">
                            <div className="list d-flex align-self-center">
                              <div className="loadingAnimation"></div>
                            </div>
                            <div className="list d-flex align-self-center">
                              <div className="loadingAnimation"></div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="list d-flex align-self-center last-child">
                        <ul>
                          <li className="d-flex">
                            <div className="list d-flex align-self-center">
                              <div className="loadingAnimation"></div>
                            </div>
                            <div className="list d-flex align-self-center">
                              <div className="loadingAnimation"></div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li className="d-flex">
                      <div className="list d-flex align-self-center">
                        <ul>
                          <li className="d-flex">
                            <div className="list d-flex align-self-center">
                              <div className="loadingAnimation"></div>
                            </div>
                            <div className="list d-flex align-self-center">
                              <div className="loadingAnimation"></div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="list d-flex align-self-center last-child">
                        <ul>
                          <li className="d-flex">
                            <div className="list d-flex align-self-center">
                              <div className="loadingAnimation"></div>
                            </div>
                            <div className="list d-flex align-self-center">
                              <div className="loadingAnimation"></div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li className="d-flex">
                      <div className="list d-flex align-self-center">
                        <ul>
                          <li className="d-flex">
                            <div className="list d-flex align-self-center">
                              <div className="loadingAnimation"></div>
                            </div>
                            <div className="list d-flex align-self-center">
                              <div className="loadingAnimation"></div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="list d-flex align-self-center last-child">
                        <ul>
                          <li className="d-flex">
                            <div className="list d-flex align-self-center">
                              <div className="loadingAnimation"></div>
                            </div>
                            <div className="list d-flex align-self-center">
                              <div className="loadingAnimation"></div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="tableHeader">
                <div className="tableList">
                  <ul>
                    <li className="d-flex">
                      <div className="list d-flex align-self-center">
                        <div className="loadingAnimation"></div>
                      </div>
                      <div className="list d-flex align-self-center">
                        <div className="loadingAnimation"></div>
                      </div>
                      <div className="list d-flex align-self-center">
                        <div className="loadingAnimation"></div>
                      </div>
                      <div className="list d-flex align-self-center">
                        <div className="loadingAnimation"></div>
                      </div>
                      <div className="list d-flex align-self-center">
                        <div className="loadingAnimation"></div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="tableList">
                <ul>
                  <li className="d-flex">
                    <div className="list d-flex align-self-center">
                      <div className="loadingAnimation"></div>
                    </div>
                    <div className="list d-flex align-self-center">
                      <div className="loadingAnimation"></div>
                    </div>
                    <div className="list d-flex align-self-center">
                      <div className="loadingAnimation"></div>
                    </div>
                    <div className="list d-flex align-self-center">
                      <div className="loadingAnimation"></div>
                    </div>
                    <div className="list d-flex align-self-center">
                      <div className="loadingAnimation"></div>
                    </div>
                  </li>
                  <li className="d-flex">
                    <div className="list d-flex align-self-center">
                      <div className="loadingAnimation"></div>
                    </div>
                    <div className="list d-flex align-self-center">
                      <div className="loadingAnimation"></div>
                    </div>
                    <div className="list d-flex align-self-center">
                      <div className="loadingAnimation"></div>
                    </div>
                    <div className="list d-flex align-self-center">
                      <div className="loadingAnimation"></div>
                    </div>
                    <div className="list d-flex align-self-center">
                      <div className="loadingAnimation"></div>
                    </div>
                  </li>
                  <li className="d-flex">
                    <div className="list d-flex align-self-center">
                      <div className="loadingAnimation"></div>
                    </div>
                    <div className="list d-flex align-self-center">
                      <div className="loadingAnimation"></div>
                    </div>
                    <div className="list d-flex align-self-center">
                      <div className="loadingAnimation"></div>
                    </div>
                    <div className="list d-flex align-self-center">
                      <div className="loadingAnimation"></div>
                    </div>
                    <div className="list d-flex align-self-center">
                      <div className="loadingAnimation"></div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  } else if (useas === "laborDashboardTopBox") {
    return (
      <div className="loader-responsive">
        <div className="laborDashboardTopBox">
          <div className="highlightBox d-flex">
            <div className="loadingAnimation"></div>
            <div className="loadingAnimation"></div>
            <div className="loadingAnimation"></div>
          </div>
        </div>
      </div>
    );
  } else if (useas === "dashboardTable") {
    return (
      <div className="loader-responsive mx-3 labor-dashboard-table-loader dashboard-table-loader">
        <div className="tableHeader loadingAnimation"></div>
        <div className="table-list">
          <ul className="p-0">
            <li className="d-flex justify-content-between">
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
            </li>
            <li>
              <div className="border"></div>
            </li>
            <li className="d-flex justify-content-between">
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
            </li>
            <li>
              <div className="border"></div>
            </li>
            <li className="d-flex justify-content-between">
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
            </li>
            <li>
              <div className="border"></div>
            </li>
            <li className="d-flex justify-content-between">
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
            </li>
            <li>
              <div className="border"></div>
            </li>
            <li className="d-flex justify-content-between">
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
            </li>
            <li>
              <div className="border"></div>
            </li>
          </ul>
        </div>
      </div>
    );
  } else if (useas === "daily-task-loader") {
    return (
      <div className="loader-responsive mx-3 labor-dashboard-table-loader dashboard-table-loader">
        <div className="tableHeader loadingAnimation"></div>
        <div className="table-list">
          <ul className="p-0">
            <li className="d-flex justify-content-between">
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
            </li>
            <li>
              <div className="border"></div>
            </li>
            <li className="d-flex justify-content-between">
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
            </li>
            <li>
              <div className="border"></div>
            </li>
            <li className="d-flex justify-content-between">
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
            </li>
            <li>
              <div className="border"></div>
            </li>
            <li className="d-flex justify-content-between">
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
            </li>
            <li>
              <div className="border"></div>
            </li>
            <li className="d-flex justify-content-between">
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
            </li>
            <li>
              <div className="border"></div>
            </li>
          </ul>
        </div>
      </div>
    );
  } else if (useas === "laborDashboardGraph") {
    return (
      <div className="loader-responsive">
        <div className="laborDashboardGraph">
          <div className="highlightBox d-flex">
            <div className="loadingAnimation"></div>
          </div>
        </div>
      </div>
    );
  } else if (useas === "overviewBox") {
    return (
      <ContentLoader
        speed={2}
        width={1200}
        height={1200}
        viewBox="0 0 1200 1200"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
      >
        <rect x="0" y="20" rx="0" ry="0" width="214" height="100" />
        <rect x="234" y="20" rx="0" ry="0" width="214" height="100" />
        <rect x="468" y="20" rx="0" ry="0" width="214" height="100" />
        <rect x="702" y="20" rx="0" ry="0" width="214" height="100" />
        <rect x="936" y="20" rx="0" ry="0" width="214" height="100" />
      </ContentLoader>
    );
  } else if (useas === "usersTable") {
    return (
      <div className="loader-responsive">
        <div className="payrollExport">
          <div className="tableHeader loadingAnimation"></div>
          <div className="tableList">
            <ul>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
  else if (useas === "dashboardTable") {
    return (
      <div className="loader-responsive">
        <div className="dashboard-table-loader">
          <div className="tableHeader loadingAnimation"></div>
          <div className="tableList">
            <ul>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
  else if (useas === "usersTableRow") {
    return (
      <div className="loader-responsive">
        <div className="payrollExport">
          {/* <div className="tableHeader loadingAnimation"></div> */}
          <div className="tableList">
            <ul>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  } else if (useas === "houseKeepingSlideVariance") {
    return (
      <div className="loader-responsive">
        <div className="houseKeepingSlideVariance">
          <div className="tableHeader loadingAnimation"></div>
          <div className="tableList">
            <ul>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  } else if (useas === "changeLog") {
    return (
      <div className="loader-responsive">
        <div className="changeLog">
          <div className="tableHeader loadingAnimation"></div>
          <div className="tableList">
            <ul>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  } else if (useas === "vendorHistoryChangeLog") {
    return (
      <div className="loader-responsive">
        <div className="vendorHistoryChangeLog">
          <div className="tableHeader loadingAnimation"></div>
          <div className="tableList">
            <ul>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  } else if (useas === "changeLogRows") {
    return (
      <div className="loader-responsive">
        <div className="changeLog">
          <div className="tableList">
            <ul>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  } else if (useas === "houseKeepingSlideRoomTypeData") {
    return (
      <div className="loader-responsive">
        <div className="houseKeepingSlideRoomTypeData">
          <div className="tableHeader loadingAnimation"></div>
          <div className="tableList">
            <ul>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  } else if (useas === "payrollProcessorIndex") {
    return (
      <div className="loader-responsive">
        <div className="payrollProcessor">
          <div className="tabs d-flex">
            <div className="loadingAnimation"></div>
            <div className="loadingAnimation"></div>
          </div>
          <div className="tabs-content">
            <div className="tableHeader loadingAnimation"></div>
            <div className="tableList">
              <ul>
                <li className="d-flex justify-content-between">
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                </li>
                <li className="d-flex justify-content-between">
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                </li>
                <li className="d-flex justify-content-between">
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                </li>
                <li className="d-flex justify-content-between">
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (useas === "payrollProcessorExportReciepeint") {
    return (
      <div className="loader-responsive">
        <div className="payrollProcessorExportRecipient">
          <div className="tabs-content">
            <div className="tableHeader loadingAnimation"></div>
            <div className="tableList">
              <ul>
                <li className="d-flex justify-content-between">
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                </li>
                <li className="d-flex justify-content-between">
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                </li>
                <li className="d-flex justify-content-between">
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                </li>
                <li className="d-flex justify-content-between">
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (useas === "payrollProcessorCodeMapping") {
    return (
      <div className="loader-responsive">
        <div className="payrollProcessorExportCodeMapping">
          <div className="header d-flex">
            <div className="loadingAnimation"></div>
            <div className="loadingAnimation"></div>
          </div>
          <div className="tabs-content">
            <div className="tableHeader loadingAnimation"></div>
            <div className="tableList">
              <ul>
                <li className="d-flex justify-content-between">
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                </li>
                <li className="d-flex justify-content-between">
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                </li>
                <li className="d-flex justify-content-between">
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                </li>
                <li className="d-flex justify-content-between">
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (useas === "employeeTimeClock") {
    return (
      <ContentLoader
        speed={2}
        width={1360}
        height={800}
        viewBox="0 0 1360 800"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
      >
        <rect x="0" y="0" rx="0" ry="0" width="400" height="88" />
        <rect x="420" y="0" rx="0" ry="0" width="400" height="88" />
        <rect x="" y="120" rx="0" ry="0" width="400" height="88" />
      </ContentLoader>
    );
  } else if (useas === "performanceSlideout") {
    return (
      <ContentLoader
        speed={2}
        width={1000}
        height={320}
        viewBox="0 0 1000 320"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
      >
        <rect x="0" y="60" rx="0" ry="0" width="988" height="47" />
        <rect x="0" y="0" rx="0" ry="0" width="106" height="36" />
        <rect x="838" y="0" rx="0" ry="0" width="150" height="36" />
        <rect x="117" y="0" rx="0" ry="0" width="76" height="36" />
        <rect x="0" y="191" rx="0" ry="0" width="988" height="1" />
        <rect x="0" y="275" rx="0" ry="0" width="988" height="1" />
        <circle cx="68" cy="146" r="20" />
        <rect x="103" y="126" rx="0" ry="0" width="200" height="21" />
        <rect x="102" y="152" rx="0" ry="0" width="200" height="15" />
        <rect x="380" y="126" rx="0" ry="0" width="60" height="21" />
        <rect x="380" y="152" rx="0" ry="0" width="60" height="21" />
        <rect x="507" y="126" rx="0" ry="0" width="60" height="21" />
        <rect x="617" y="126" rx="0" ry="0" width="60" height="21" />
        <rect x="693" y="126" rx="0" ry="0" width="60" height="21" />
        <rect x="804" y="127" rx="0" ry="0" width="60" height="21" />
        <rect x="507" y="152" rx="0" ry="0" width="60" height="21" />
        <rect x="617" y="152" rx="0" ry="0" width="60" height="21" />
        <rect x="693" y="152" rx="0" ry="0" width="60" height="21" />
        <rect x="804" y="152" rx="0" ry="0" width="60" height="21" />
        <rect x="945" y="145" rx="0" ry="0" width="40" height="15" />
        <rect x="591" y="132" rx="0" ry="0" width="1" height="35" />
        <rect x="777" y="132" rx="0" ry="0" width="1" height="35" />
        <rect x="507" y="210" rx="0" ry="0" width="60" height="21" />
        <rect x="617" y="210" rx="0" ry="0" width="60" height="21" />
        <rect x="693" y="210" rx="0" ry="0" width="60" height="21" />
        <rect x="804" y="211" rx="0" ry="0" width="60" height="21" />
        <rect x="507" y="236" rx="0" ry="0" width="60" height="21" />
        <rect x="617" y="236" rx="0" ry="0" width="60" height="21" />
        <rect x="693" y="236" rx="0" ry="0" width="60" height="21" />
        <rect x="804" y="236" rx="0" ry="0" width="60" height="21" />
        <rect x="591" y="216" rx="0" ry="0" width="1" height="35" />
        <rect x="777" y="216" rx="0" ry="0" width="1" height="35" />
        <rect x="48" y="223" rx="0" ry="0" width="100" height="21" />
        <rect x="0" y="291" rx="0" ry="0" width="100" height="21" />
        <rect x="673" y="0" rx="0" ry="0" width="150" height="36" />
      </ContentLoader>
    );
  } else if (useas === "performanceSlideoutScheduleDetails") {
    return (
      <ContentLoader
        speed={2}
        width={930}
        height={150}
        viewBox="0 0 930 150"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
      >
        <rect x="0" y="45" rx="0" ry="0" width="897" height="47" />
        <rect x="0" y="0" rx="0" ry="0" width="149" height="21" />
        <rect x="80" y="18" rx="0" ry="0" width="1" height="0" />
        <rect x="0" y="139" rx="0" ry="0" width="897" height="1" />
        <rect x="15" y="106" rx="0" ry="0" width="80" height="21" />
        <rect x="130" y="106" rx="0" ry="0" width="80" height="21" />
        <rect x="245" y="106" rx="0" ry="0" width="80" height="21" />
        <rect x="359" y="106" rx="0" ry="0" width="80" height="21" />
        <rect x="474" y="106" rx="0" ry="0" width="80" height="21" />
      </ContentLoader>
    );
  } else if (useas === "performanceSlideoutActualDetails") {
    return (
      <ContentLoader
        speed={2}
        width={930}
        height={201}
        viewBox="0 0 930 201"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
      >
        <rect x="0" y="45" rx="0" ry="0" width="897" height="47" />
        <rect x="0" y="0" rx="0" ry="0" width="149" height="21" />
        <rect x="0" y="139" rx="0" ry="0" width="897" height="1" />
        <rect x="15" y="106" rx="0" ry="0" width="80" height="21" />
        <rect x="130" y="106" rx="0" ry="0" width="80" height="21" />
        <rect x="245" y="106" rx="0" ry="0" width="80" height="21" />
        <rect x="359" y="106" rx="0" ry="0" width="80" height="21" />
        <rect x="474" y="106" rx="0" ry="0" width="80" height="21" />
        <rect x="589" y="106" rx="0" ry="0" width="80" height="21" />
        <rect x="15" y="152" rx="0" ry="0" width="80" height="21" />
        <rect x="245" y="152" rx="0" ry="0" width="80" height="21" />
        <rect x="359" y="152" rx="0" ry="0" width="80" height="21" />
        <rect x="800" y="152" rx="0" ry="0" width="80" height="21" />
        <rect x="838" y="106" rx="0" ry="0" width="40" height="15" />
        <rect x="705" y="152" rx="0" ry="0" width="80" height="21" />
      </ContentLoader>
    );
  } else if (useas === "scheduleUserView") {
    return (
      <div className="loader-responsive">
        <div className="scheduleUserView">
          <div className="header d-flex">
            <div className="loadingAnimation"></div>
            <div className="loadingAnimation mr-auto"></div>
            <div className="loadingAnimation"></div>
            <div className="loadingAnimation"></div>
            <div className="loadingAnimation"></div>
          </div>
          <div className="tableHeader loadingAnimation"></div>
          <div className="tableListTop">
            <ul>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div className="tableList">
            <ul>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="thumbImg loadingAnimation"></div>
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div className="loadingAnimation small-width"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="thumbImg loadingAnimation"></div>
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div className="loadingAnimation small-width"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="thumbImg loadingAnimation"></div>
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div className="loadingAnimation small-width"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="thumbImg loadingAnimation"></div>
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div className="loadingAnimation small-width"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  } else if (useas === "myscheduleUserView") {
    return (
      <div className="loader-responsive">
        <div className="scheduleUserView">
          <div className="header d-flex">
            <div className="loadingAnimation mr-auto"></div>

            <div className="loadingAnimation"></div>
          </div>
          <div className="tableHeader loadingAnimation"></div>
          <div className="tableList">
            <ul>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="thumbImg loadingAnimation"></div>
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div className="loadingAnimation small-width"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="thumbImg loadingAnimation"></div>
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div className="loadingAnimation small-width"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <hr></hr>
          <div className="header d-flex">
            <div className="loadingAnimation mr-auto"></div>

            <div className="loadingAnimation"></div>
          </div>
          <div className="header d-flex">
            <div className="loadingAnimation mr-auto"></div>
          </div>
          <div className="tableHeader loadingAnimation"></div>
          <div className="tableList">
            <ul>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="thumbImg loadingAnimation"></div>
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div className="loadingAnimation small-width"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="thumbImg loadingAnimation"></div>
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div className="loadingAnimation small-width"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  } else if (useas === "myscheduleMobileView") {
    return (
      <div className="loader-responsive">
        <div className="myscheduleMobileView">
          <div className="header d-flex">
            <div className="loadingAnimation mr-auto"></div>
            <div className="loadingAnimation"></div>
          </div>
          <div className="tableHeader loadingAnimation"></div>
          <div className="tableList">
            <ul>
              <li className="d-flex">
                <div className="list d-flex align-self-center">
                  <div className="thumbImg loadingAnimation"></div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="loadingAnimation"></div>
                </div>
              </li>
            </ul>
          </div>
          <hr></hr>
          <div className="header d-flex">
            <div className="loadingAnimation mr-auto"></div>
          </div>
          <div className="header d-flex">
            <div className="loadingAnimation dropdown"></div>
          </div>
          <div className="tableHeader loadingAnimation"></div>
          <div className="tableList">
            <ul>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="thumbImg loadingAnimation"></div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="loadingAnimation"></div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  } else if (useas === "posViewMain" || useas === "deptViewMain") {
    return (
      <div className="loader-responsive">
        <div className="scheduleDeptView">
          <div className="header d-flex">
            <div className="loadingAnimation"></div>
            <div className="loadingAnimation"></div>
            <div className="loadingAnimation mr-auto"></div>
            <div className="loadingAnimation"></div>
            <div className="loadingAnimation"></div>
            <div className="loadingAnimation"></div>
          </div>
          <div className="tableHeader loadingAnimation"></div>
          <div className="tableList">
            <ul>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div className="loadingAnimation small-width"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div className="loadingAnimation small-width"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div className="loadingAnimation small-width"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div className="loadingAnimation small-width"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  } else if (
    useas === "deptViewMainTableLoad" ||
    useas === "posViewMainTableLoad"
  ) {
    return (
      <div className="loader-responsive">
        <div className="scheduleDeptView">
          <div className="tableHeader loadingAnimation"></div>
          <div className="tableList">
            <ul>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div className="loadingAnimation small-width"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div className="loadingAnimation small-width"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div className="loadingAnimation small-width"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div className="loadingAnimation small-width"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation"></div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  } else if (useas === "posAfterExpend" || useas === "deptAfterExpend") {
    return (
      <div className="loader-responsive">
        <div className="deptAfterExpend">
          <div className="tableListTop">
            <ul>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div className="tableList">
            <ul>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="thumbImg loadingAnimation"></div>
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div className="loadingAnimation small-width"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  } else if (useas === "reportsMain") {
    return (
      <div className="loader-responsive">
        <div className="reportsMain">
          <div className="header d-flex">
            <div className="loadingAnimation"></div>
            <div className="loadingAnimation mr-auto"></div>
            <div className="loadingAnimation"></div>
          </div>
          <div className="tableList">
            <ul>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div className="loadingAnimation small-width"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div className="loadingAnimation small-width"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation small-box"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div className="loadingAnimation small-width"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div className="loadingAnimation small-width"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation small-box"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div className="loadingAnimation small-width"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div className="loadingAnimation small-width"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation small-box"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div className="loadingAnimation small-width"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div className="loadingAnimation small-width"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation small-box"></div>
                    <div></div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  } else if (useas === "reportsGallery") {
    return (
      <div className="loader-responsive">
        <div className="reportsGallery">
          <div className="reports-box">
            <ul>
              <li>
                <div className="loadingAnimation"></div>
              </li>
              <li>
                <div className="loadingAnimation"></div>
              </li>
              <li>
                <div className="loadingAnimation"></div>
              </li>
              <li>
                <div className="loadingAnimation"></div>
              </li>
              <li>
                <div className="loadingAnimation"></div>
              </li>
              <li>
                <div className="loadingAnimation"></div>
              </li>
              <li>
                <div className="loadingAnimation"></div>
              </li>
              <li>
                <div className="loadingAnimation"></div>
              </li>
              <li>
                <div className="loadingAnimation"></div>
              </li>
              <li>
                <div className="loadingAnimation"></div>
              </li>
              <li>
                <div className="loadingAnimation"></div>
              </li>
              <li>
                <div className="loadingAnimation"></div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  } else if (useas === "reportsScheduleLeftPanel") {
    return (
      <div className="loader-responsive">
        <div className="reportsScheduleLeftPanel">
          <div className="header d-flex">
            <div className="loadingAnimation"></div>
          </div>
          <div className="tableList">
            <ul>
              <li className="d-flex">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div className="loadingAnimation small-width"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div className="loadingAnimation small-width"></div>
                  </div>
                </div>
              </li>
              <li className="d-flex">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div className="loadingAnimation small-width"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div className="loadingAnimation small-width"></div>
                  </div>
                </div>
              </li>
              <li className="d-flex">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div className="loadingAnimation small-width"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div className="loadingAnimation small-width"></div>
                  </div>
                </div>
              </li>
              <li className="d-flex">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div className="loadingAnimation small-width"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div className="loadingAnimation small-width"></div>
                  </div>
                </div>
              </li>
              <li className="d-flex">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div className="loadingAnimation small-width"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div className="loadingAnimation small-width"></div>
                  </div>
                </div>
              </li>
              <li className="d-flex">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div className="loadingAnimation small-width"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div className="loadingAnimation small-width"></div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  } else if (useas === "reportsScheduleRightPanel") {
    return (
      <div className="loader-responsive" style={{ width: "62%" }}>
        <div className="reportsScheduleRightPanel">
          <div className="header d-flex">
            <div className="loadingAnimation mr-auto"></div>
            <div className="loadingAnimation"></div>
            <div className="loadingAnimation"></div>
          </div>
          <div className="tableList">
            <ul>
              <li className="d-flex">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div></div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  } else if (useas === "forecastMain") {
    return (
      <div className="loader-responsive">
        <div className="forecastMain">
          <div className="header d-flex">
            <div className="loadingAnimation mr-auto"></div>
          </div>
          <div className="tableHeader loadingAnimation"></div>
          <div className="tableList">
            <ul>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  } else if (useas === "tipsEntryMain") {
    return (
      <div className="loader-responsive">
        <div className="tipsEntryMain">
          <div className="header d-flex">
            <div className="loadingAnimation"></div>
            <div className="loadingAnimation"></div>
            <div className="loadingAnimation"></div>
            <div className="loadingAnimation mr-auto"></div>
            <div className="loadingAnimation ml-auto"></div>
          </div>
          <div className="tableHeader loadingAnimation"></div>
          <div className="tableList">
            <ul>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation mr-auto"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation mr-auto"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  } else if (useas === "entity-management-entities") {
    return (
      <div className="loader-responsive">
        <div className="entity-management-entities">
          {/* <div className="header d-flex">
            <div className="loadingAnimation"></div>
            <div className="loadingAnimation"></div>
            <div className="loadingAnimation"></div>
            <div className="loadingAnimation mr-auto"></div>
            <div className="loadingAnimation ml-auto"></div>
          </div> */}
          <div className="tableHeader loadingAnimation"></div>
          <div className="tableList">
            <ul>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation mr-auto"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation mr-auto"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  } else if (useas === "timesheetMain") {
    return (
      <div className="loader-responsive">
        <div className="timesheetMain">
          <div className="tabs d-flex">
            <div className="loadingAnimation"></div>
            <div className="loadingAnimation"></div>
            <div className="loadingAnimation"></div>
          </div>
          <div className="tabs-content">
            <div className="header d-flex">
              <div className="loadingAnimation"></div>
              <div className="loadingAnimation"></div>
              <div className="loadingAnimation"></div>
              <div className="loadingAnimation mr-auto"></div>
            </div>
            <div className="highlightBox d-flex">
              <div className="loadingAnimation"></div>
              <div className="loadingAnimation"></div>
            </div>
            <div className="tableHeader loadingAnimation"></div>
            <div className="tableList">
              <ul>
                <li className="d-flex justify-content-between">
                  <div className="list d-flex align-self-center">
                    <div className="thumbImg loadingAnimation"></div>
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div className="loadingAnimation"></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                </li>
                <li className="d-flex justify-content-between">
                  <div className="list d-flex align-self-center">
                    <div className="thumbImg loadingAnimation"></div>
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div className="loadingAnimation"></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                </li>
                <li className="d-flex justify-content-between">
                  <div className="list d-flex align-self-center">
                    <div className="thumbImg loadingAnimation"></div>
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div className="loadingAnimation"></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (useas === "timesheetSlideout") {
    return (
      <div className="loader-responsive">
        <div className="timesheetSlideout">
          <div className="tabs d-flex">
            <div className="loadingAnimation"></div>
            <div className="loadingAnimation"></div>
            <div className="loadingAnimation"></div>
          </div>
          <div className="tabs-content">
            <div className="header d-flex">
              <div className="loadingAnimation"></div>
              <div className="loadingAnimation"></div>
              <div className="loadingAnimation"></div>
              <div className="loadingAnimation mr-auto"></div>
            </div>
            <div className="tableHeader loadingAnimation"></div>
            <div className="tableList">
              <ul>
                <li className="d-flex justify-content-between">
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                </li>
                <li className="d-flex justify-content-between">
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                </li>
                <li className="d-flex justify-content-between">
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                </li>
                <li className="d-flex justify-content-between">
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (useas === "overViewWebClock") {
    return (
      <div className="loader-responsive">
        <div className="timesheetMain">
          <div className="tabs d-flex">
            <div className="loadingAnimation"></div>
          </div>
          <div className="tabs-content">
            <div className="header d-flex">
              <div className="loadingAnimation"></div>
              <div className="loadingAnimation"></div>
              <div className="loadingAnimation"></div>
              <div className="loadingAnimation mr-auto"></div>
            </div>

            <div className="tableHeader loadingAnimation"></div>
            <div className="tableList">
              <ul>
                <li className="d-flex justify-content-between">
                  <div className="list d-flex align-self-center">
                    <div className="thumbImg loadingAnimation"></div>
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div className="loadingAnimation"></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                </li>
                <li className="d-flex justify-content-between">
                  <div className="list d-flex align-self-center">
                    <div className="thumbImg loadingAnimation"></div>
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div className="loadingAnimation"></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                </li>
                <li className="d-flex justify-content-between">
                  <div className="list d-flex align-self-center">
                    <div className="thumbImg loadingAnimation"></div>
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div className="loadingAnimation"></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (useas === "timesheetUserView") {
    return (
      <div className="loader-responsive">
        <div className="timesheetUserView">
          <div className="tabs-content">
            <div className="header d-flex">
              <div className="loadingAnimation mr-auto"></div>
              <div className="loadingAnimation"></div>
            </div>
            <div className="highlightBox d-flex">
              <div className="loadingAnimation"></div>
              <div className="loadingAnimation"></div>
              <div className="loadingAnimation"></div>
            </div>
            <div className="tableHeader loadingAnimation"></div>
            <div className="tableList">
              <ul>
                <li className="d-flex justify-content-between">
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                </li>
                <li className="d-flex justify-content-between">
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                </li>
                <li className="d-flex justify-content-between">
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                </li>
                <li className="d-flex justify-content-between">
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (useas === "mobileMyTimesheet") {
    return (
      <div className="loader-responsive">
        <div className="mobileMyTimesheet">
          <div className="highlightBox d-flex">
            <div className="loadingAnimation"></div>
            <div className="loadingAnimation"></div>
          </div>
          <div className="tabs d-flex">
            <div className="loadingAnimation"></div>
            <div className="loadingAnimation"></div>
            <div className="loadingAnimation"></div>
            <div className="loadingAnimation"></div>
          </div>
          <div className="tabs-content">
            <div className="highlightBox d-flex">
              <div className="loadingAnimation"></div>
            </div>
            <div className="tableHeader loadingAnimation"></div>
          </div>
        </div>
      </div>
    );
  } else if (useas === "hotelsListOTAR") {
    return (
      <div className="loader-responsive">
        <div className="hotelsListOTAR">
          <div className="loadingAnimation tableTitle"></div>
          <div className="tableList">
            <ul>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div className="loadingAnimation small-width"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div className="loadingAnimation small-width"></div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  } else if (useas === "daysToApprove") {
    return (
      <div className="loader-responsive">
        <div className="hotelsListOTAR">
          <div className="loadingAnimation tableTitle"></div>
          <div className="tableList">
            <ul>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div className="loadingAnimation small-width"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div className="loadingAnimation small-width"></div>
                  </div>
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div className="loadingAnimation small-width"></div>
                  </div>
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div className="loadingAnimation small-width"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div className="loadingAnimation small-width"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div className="loadingAnimation small-width"></div>
                  </div>
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div className="loadingAnimation small-width"></div>
                  </div>
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div className="loadingAnimation small-width"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div className="loadingAnimation small-width"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div className="loadingAnimation small-width"></div>
                  </div>
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div className="loadingAnimation small-width"></div>
                  </div>
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div className="loadingAnimation small-width"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div className="loadingAnimation small-width"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div className="loadingAnimation small-width"></div>
                  </div>
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div className="loadingAnimation small-width"></div>
                  </div>
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div className="loadingAnimation small-width"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div className="loadingAnimation small-width"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div className="loadingAnimation small-width"></div>
                  </div>
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div className="loadingAnimation small-width"></div>
                  </div>
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div className="loadingAnimation small-width"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  } else if (useas === "daysToApproveExpand") {
    return (
      <div className="loader-responsive">
        <div className="daysapproveExpand">
          <div className="header d-flex">
            <div className="loadingAnimation"></div>
            <div className="loadingAnimation"></div>
            <div className="loadingAnimation mr-auto"></div>

            <div className="loadingAnimation"></div>
          </div>
          <div className="tableHeader loadingAnimation"></div>
          <div className="tableListTop">
            <ul>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  } else if (useas === "roomsAssignedExpand") {
    return (
      <div className="loader-responsive">
        <div className="roomsAssignedExpand">
          <div className="header d-flex">
            <div className="loadingAnimation spaceFirst"></div>
            <div className="loadingAnimation  mr-auto"></div>
            <div className="loadingAnimation"></div>
          </div>
          <div className="tableHeader loadingAnimation"></div>
          <div className="tableListTop">
            <ul>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div></div>
                  </div>
                </div>

                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div></div>
                  </div>
                </div>

                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div></div>
                  </div>
                </div>

                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div></div>
                  </div>
                </div>

                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  } else if (useas === "roomsAssignList") {
    return (
      <div className="loader-responsive">
        <div className="roomsAssignment">
          <div className="loadingAnimation tableTitle"></div>
          <div className="loadingAnimation tableTitle2"></div>
          <div className="tableList">
            <ul>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div className="loadingAnimation small-width"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation small-width"></div>
                    <div className="loadingAnimation small-width"></div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  } else if (useas === "daysToApproveDetailList") {
    return (
      <div className="loader-responsive">
        <div className="laborOverviewMain">
          <div className="header">
            <div className="loadingAnimation headerone"></div>
            <div className="loadingAnimation"></div>
          </div>
          <div className="tableList">
            <ul>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation mr-auto"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation mr-auto"></div>
                    <div className="loadingAnimation mr-auto"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation mr-auto"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation mr-auto"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation mr-auto"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation mr-auto"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation mr-auto"></div>
                    <div className="loadingAnimation mr-auto"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation mr-auto"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation mr-auto"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation mr-auto"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation mr-auto"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation mr-auto"></div>
                    <div className="loadingAnimation mr-auto"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation mr-auto"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation mr-auto"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation mr-auto"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation mr-auto"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation mr-auto"></div>
                    <div className="loadingAnimation mr-auto"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation mr-auto"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation mr-auto"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation mr-auto"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation mr-auto"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation mr-auto"></div>
                    <div className="loadingAnimation mr-auto"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation mr-auto"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation mr-auto"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation mr-auto"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  } else if (useas === "HousekeepingDays") {
    return (
      <div className="loader-responsive">
        <div className="laborOverviewMain">
          <div className="header">
            <div className="loadingAnimation headerone"></div>
            <div className="loadingAnimation"></div>
          </div>
          <div className="tableList">
            <ul>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation mr-auto"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation mr-auto"></div>
                    <div className="loadingAnimation mr-auto"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation mr-auto"></div>
                  </div>
                </div>

                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation mr-auto"></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group d-flex">
                    <div className="loadingAnimation"></div>
                    <div className="loadingAnimation btnTwo"></div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  } else if (useas === "OTARPanel") {
    return (
      <div className="loader-responsive">
        <div className="OTARPanel">
          <div className="header">
            <div className="loadingAnimation"></div>
          </div>
          <div className="otarloader d-flex justify-content-start">
            <div className="left-panel">
              <div className="leftPanelOTAR">
                <div className="tableHeader">
                  <div className="loadingAnimation tableTitle"></div>
                  <div className="tableBtnRight">
                    <div className="loadingAnimation buttonLg"></div>
                  </div>
                </div>
                <div className="panelBody">
                  <div className="tableList">
                    <ul>
                      <li className="d-flex">
                        <div className="box1">
                          <div className="col1 loadingAnimation"></div>
                          <div className="col1 loadingAnimation"></div>
                        </div>
                        <div className="box1 pl50">
                          <div className="col1 loadingAnimation"></div>
                          <div className="col1 loadingAnimation"></div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="tableList">
                    <ul>
                      <li className="d-flex">
                        <div className="box1">
                          <div className="col1 loadingAnimation"></div>
                          <div className="col1 loadingAnimation"></div>
                        </div>
                        <div className="box1 pl50">
                          <div className="col1 loadingAnimation"></div>
                          <div className="col1 loadingAnimation"></div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="right-panel">
              <div className="rightPanelOTAR">
                <div className="tableHeader">
                  <div className="loadingAnimation tableTitle"></div>
                  <div className="tableBtnRight">
                    <div className="loadingAnimation buttonLg"></div>
                    <div className="loadingAnimation buttonSm"></div>
                  </div>
                </div>
                <div className="panelBody">
                  <div className="tableList">
                    <ul>
                      <li className="d-flex">
                        <div className="loadingAnimation col1"></div>
                        <div className="loadingAnimation col1 pl"></div>
                      </li>
                      <li className="d-flex">
                        <div className="loadingAnimation col1"></div>
                        <div className="loadingAnimation col1 pl"></div>
                      </li>
                      <li className="d-flex">
                        <div className="loadingAnimation col1"></div>
                        <div className="loadingAnimation col1 pl"></div>
                      </li>
                      <li className="d-flex">
                        <div className="loadingAnimation col2"></div>
                        <div className="loadingAnimation col2"></div>
                      </li>
                    </ul>
                  </div>
                  <div className="tableFullList">
                    <ul>
                      <li className="d-flex">
                        <div className="loadingAnimation col1"></div>
                      </li>
                      <li className="d-flex">
                        <div className="loadingAnimation col"></div>
                      </li>
                      <li className="d-flex">
                        <div className="loadingAnimation colfull"></div>
                      </li>
                      <li className="d-flex">
                        <div className="loadingAnimation colfull"></div>
                      </li>
                      <li className="d-flex">
                        <div className="loadingAnimation col1"></div>
                        <div className="loadingAnimation col1 pl"></div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (useas === "SmileIDPAnel") {
    return (
      <div className="loader-responsive">
        <div className="SmileIDPanel">
          <div className="header">
            <div className="loadingAnimation"></div>
          </div>
          <div className="otarloader d-flex justify-content-start">
            <div className="left-panel">
              <div className="leftPanelOTAR">
                <div className="tableHeader">
                  <div className="loadingAnimation tableTitle"></div>
                  <div className="tableBtnRight">
                    <div className="loadingAnimation buttonLg"></div>
                  </div>
                </div>
                <div className="panelBody">
                  <div className="tableList">
                    <ul>
                      <li className="d-flex">
                        <div className="box1">
                          <div className="col1 loadingAnimation"></div>
                          <div className="col1 loadingAnimation"></div>
                        </div>
                        <div className="box1 pl50">
                          <div className="col1 loadingAnimation"></div>
                          <div className="col1 loadingAnimation"></div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="tableList">
                    <ul>
                      <li className="d-flex">
                        <div className="box1">
                          <div className="col1 loadingAnimation"></div>
                          <div className="col1 loadingAnimation"></div>
                        </div>
                        <div className="box1 pl50">
                          <div className="col1 loadingAnimation"></div>
                          <div className="col1 loadingAnimation"></div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="right-panel">
              <div className="rightPanelOTAR">
                <div className="tableHeader">
                  <div className="loadingAnimation tableTitle"></div>
                  <div className="tableBtnRight">
                    <div className="loadingAnimation buttonLg"></div>
                    <div className="loadingAnimation buttonSm"></div>
                  </div>
                </div>
                <div className="panelBody">
                  <div className="tableList">
                    <ul>
                      <li className="d-flex">
                        <div className="loadingAnimation col1"></div>
                        <div className="loadingAnimation col1 pl"></div>
                      </li>
                      <li className="d-flex">
                        <div className="loadingAnimation col1"></div>
                        <div className="loadingAnimation col1 pl"></div>
                      </li>
                      <li className="d-flex">
                        <div className="loadingAnimation col1"></div>
                        <div className="loadingAnimation col1 pl"></div>
                      </li>
                      <li className="d-flex">
                        <div className="loadingAnimation col2"></div>
                        <div className="loadingAnimation col2"></div>
                      </li>
                    </ul>
                  </div>
                  <div className="tableFullList">
                    <ul>
                      <li className="d-flex">
                        <div className="loadingAnimation col1"></div>
                      </li>
                      <li className="d-flex">
                        <div className="loadingAnimation col"></div>
                      </li>
                      <li className="d-flex">
                        <div className="loadingAnimation colfull"></div>
                      </li>
                      <li className="d-flex">
                        <div className="loadingAnimation colfull"></div>
                      </li>
                      <li className="d-flex">
                        <div className="loadingAnimation col1"></div>
                        <div className="loadingAnimation col1 pl"></div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (useas === "rightPanelOTAR") {
    return (
      <div className="loader-responsive">
        <div className="rightPanelOTAR">
          <div className="tableHeader">
            <div className="loadingAnimation tableTitle"></div>
            <div className="tableBtnRight">
              <div className="loadingAnimation buttonLg"></div>
              <div className="loadingAnimation buttonSm"></div>
            </div>
          </div>
          <div className="panelBody">
            <div className="tableList">
              <ul>
                <li className="d-flex">
                  <div className="loadingAnimation col1"></div>
                  <div className="loadingAnimation col1 pl"></div>
                </li>
                <li className="d-flex">
                  <div className="loadingAnimation col1"></div>
                  <div className="loadingAnimation col1 pl"></div>
                </li>
                <li className="d-flex">
                  <div className="loadingAnimation col1"></div>
                  <div className="loadingAnimation col1 pl"></div>
                </li>
                <li className="d-flex">
                  <div className="loadingAnimation col2"></div>
                  <div className="loadingAnimation col2"></div>
                </li>
              </ul>
            </div>
            <div className="tableFullList">
              <ul>
                <li className="d-flex">
                  <div className="loadingAnimation col1"></div>
                </li>
                <li className="d-flex">
                  <div className="loadingAnimation col"></div>
                </li>
                <li className="d-flex">
                  <div className="loadingAnimation colfull"></div>
                </li>
                <li className="d-flex">
                  <div className="loadingAnimation colfull"></div>
                </li>
                <li className="d-flex">
                  <div className="loadingAnimation col1"></div>
                  <div className="loadingAnimation col1 pl"></div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (useas === "SmileIDPAnel50") {
    return (
      <div className="loader-responsive">
        <div className="SmileIDPAnel50">
          <div className="smileidLoader d-flex justify-content-start">
            <div className="left-panel">
              <div className="leftPanelOTAR">
                <div className="tableHeader">
                  <div className="loadingAnimation tableTitle"></div>
                  <div className="tableBtnRight">
                    <div className="loadingAnimation buttonLg"></div>
                  </div>
                </div>
                <div className="panelBody">
                  <div className="tableList">
                    <ul>
                      <li className="d-flex">
                        <div className="box1">
                          <div className="col1 loadingAnimation"></div>
                          <div className="col1 loadingAnimation"></div>
                        </div>
                        <div className="box1 pl50">
                          <div className="col1 loadingAnimation"></div>
                          <div className="col1 loadingAnimation"></div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="tableList">
                    <ul>
                      <li className="d-flex">
                        <div className="box1">
                          <div className="col1 loadingAnimation"></div>
                          <div className="col1 loadingAnimation"></div>
                        </div>
                        <div className="box1 pl50">
                          <div className="col1 loadingAnimation"></div>
                          <div className="col1 loadingAnimation"></div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="right-panel">
              <div className="rightPanelOTAR">
                <div className="tableHeader">
                  <div className="loadingAnimation tableTitle"></div>
                  <div className="tableBtnRight">
                    <div className="loadingAnimation buttonLg"></div>
                    <div className="loadingAnimation buttonSm"></div>
                  </div>
                </div>
                <div className="panelBody">
                  <div className="tableFullList">
                    <div className="loadingAnimation photoFrame"></div>
                  </div>
                  <div className="tableFullList">
                    <ul>
                      <li className="d-flex">
                        <div className="loadingAnimation colfull"></div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (useas === "accounts") {
    return (
      <div className="loader-responsive">
        <div className="tipsEntryMain">
          <div className="header d-flex">
            <div className="loadingAnimation"></div>
            <div className="loadingAnimation mr-auto"></div>
            <div className="loadingAnimation ml-auto"></div>
          </div>
          <div className="tableHeader loadingAnimation"></div>
          <div className="tableList">
            <ul>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation mr-auto"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation mr-auto"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  } else if (useas === "reconcile") {
    return (
      <div className="loader-responsive">
        <div className="tipsEntryMain">
          <div className="tableHeader loadingAnimation"></div>
          <div className="tableList">
            <ul>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation mr-auto"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation mr-auto"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  } else if (useas === "pmsMonitoring") {
    return (
      <div className="loader-responsive">
        <div className="tipsEntryMain">
          <div className="tableHeader loadingAnimation"></div>
          <div className="tableList">
            <ul>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation mr-auto"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation mr-auto"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation mr-auto"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation mr-auto"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation mr-auto"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  } else if (useas === "smileIDLeftPanelLoader") {
    return (
      <div className="loader-responsive">
        <div className="smileIDLeftPanelLoader">
          <div className="header d-flex flex-column">
            <div className="loadingAnimation"></div>
            <div className="loadingAnimation"></div>
            <div className="loadingAnimation"></div>
            <div className="loadingAnimation"></div>
            <div className="loadingAnimation"></div>
            <div className="loadingAnimation"></div>
            <div className="loadingAnimation"></div>
            <div className="loadingAnimation"></div>
            {/* <div className="loadingAnimation"></div> */}
            {/* <div className="loadingAnimation"></div> */}
          </div>
        </div>
      </div>
    );
  } else if (useas === "smileIDRightPanelLoader") {
    return (
      <div className="loader-responsive">
        <div className="smileIDRightPanelLoader">
          <div className="header d-flex flex-column">
            <div className="loadingAnimation"></div>
          </div>
        </div>
      </div>
    );
  } else if (useas === "ePayOnboarding") {
    return (
      <div className="loader-responsive">
        <div className="ePayOnboardingMain">
          <div className="ePayheader d-flex">
            <div className="loadingAnimation"></div>
            <div className="loadingAnimation"></div>
          </div>

          <div className="ePayheader d-flex">
            <div className="loadingAnimation"></div>
            <div></div>
          </div>

          <div className="loader-responsive epayOnboardingPageLoader">
        <div className="tipsEntryMain">
          <div className="tableHeader loadingAnimation"></div>
          <div className="tableList">
            <ul>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation mr-auto"></div>
                    <div></div>
                  </div>
                </div>                               
              </li>
            </ul>
          </div>
          <div className="tableList epayOnboardingChild">
            <ul>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation mr-auto"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation mr-auto"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>             
            </ul>
          </div>
          <div className="tableList">
            <ul>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation mr-auto"></div>
                    <div></div>
                  </div>
                </div>                               
              </li>
            </ul>
          </div>
          <div className="tableList epayOnboardingChild">
            <ul>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation mr-auto"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation mr-auto"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation mr-auto"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div className="tableList">
            <ul>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation mr-auto"></div>
                    <div></div>
                  </div>
                </div>                               
              </li>
            </ul>
          </div>
          <div className="tableList epayOnboardingChild">
            <ul>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation mr-auto"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation mr-auto"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
                <div className="list d-flex align-self-center">
                  <div className="group">
                    <div className="loadingAnimation"></div>
                    <div></div>
                  </div>
                </div>
              </li>             
            </ul>
          </div>          
        </div>
      </div>
        </div>
      </div>
    );
  } else if (useas === "myPTOLoader") {
    return (
      <div className="loader-responsive mx-3 mt-2 labor-dashboard-table-loader">
        <div className="tableHeader loadingAnimation"></div>
        <div className="table-list">
          <ul className="p-0">
            <li className="d-flex justify-content-between">
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
            </li>
            <li className="d-flex justify-content-between">
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
            </li>
            <li className="d-flex justify-content-between">
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    )
  }
  else if (useas === "entity-rooms") {
    return (
      <div className="loader-responsive mx-3 mt-2 labor-dashboard-table-loader">
        <div className="tableHeader loadingAnimation"></div>
        <div className="table-list">
          <ul className="p-0">
            <li className="d-flex justify-content-between">
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
            </li>
            <li className="d-flex justify-content-between">
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
            </li>
            <li className="d-flex justify-content-between">
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    );
  }
  else if (useas === "location-rooms") {
    return (
      <div className="loader-responsive mx-3 mt-2 labor-dashboard-table-loader">
        <div className="tableHeader loadingAnimation"></div>
        <div className="table-list">
          <ul className="p-0">
            <li className="d-flex justify-content-between">
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
            </li>
            <li className="d-flex justify-content-between">
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
            </li>
            <li className="d-flex justify-content-between">
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
            </li>
            <li className="d-flex justify-content-between">
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
            </li>
            <li className="d-flex justify-content-between">
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
            </li>
            <li className="d-flex justify-content-between">
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
            </li>
            <li className="d-flex justify-content-between">
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
            </li>
            <li className="d-flex justify-content-between">
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
              <div className="list d-flex align-self-center">
                <div className="group">
                  <div className="loadingAnimation small-width"></div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    );
  }
  else if (useas === "field-loader") {
    return (
      <div className="loader-responsive work-order-loader labor-dashboard-table-loader">
        <div className="tableHeader loadingAnimation"></div>
      </div>
    );
  }
  else if (useas === "payroll-position-mapping") {
    return (
      <div className="loader-responsive">
        <div className="payrollProcessorExportCodeMapping">
          <div className="header d-flex">
            <div className="loadingAnimation"></div>
            <div className="loadingAnimation"></div>
          </div>
          <div className="tabs-content">
            <div className="tableHeader loadingAnimation"></div>
            <div className="tableList">
              <ul>
                <li className="d-flex justify-content-between">
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                </li>
                <li className="d-flex justify-content-between">
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                </li>
                <li className="d-flex justify-content-between">
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                </li>
                <li className="d-flex justify-content-between">
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                      <div></div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
  else if (useas === "CommunicationInbox") {
    return (
      <div className="commIboxLoader">
        <div className="single-loader rounded-2 loadingAnimation"></div>
        <div className="single-loader rounded-2 loadingAnimation"></div>
        <div className="single-loader rounded-2 loadingAnimation"></div>
        <div className="single-loader rounded-2 loadingAnimation"></div>
        <div className="single-loader rounded-2 loadingAnimation"></div>
        <div className="single-loader rounded-2 loadingAnimation"></div>
        <div className="single-loader rounded-2 loadingAnimation"></div>
        <div className="single-loader rounded-2 loadingAnimation"></div>
        <div className="single-loader rounded-2 loadingAnimation"></div>
        <div className="single-loader rounded-2 loadingAnimation"></div>
      </div>
    );
  } else if (useas === "CommunicationInboxComments") {
    return (
      <div className="commIboxLoaderComments">
        <div className="listView">
          <div className="single-loader rounded-2 loadingAnimation"></div>
          <div className="single-loader rounded-2 loadingAnimation"></div>
        </div>
      </div>
    );
  }
  else {
    return <></>;
  }
};
