import * as React from "react";
import createDOMPurify from "dompurify";
import logo from "../Common/Assets/Images/maintenance/Logo.svg";
import maintenanceBg from "../Common/Assets/Images/maintenance/maintenanceBg.svg";

const DOMPurify = createDOMPurify(window);

export class UnderMaintenance extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      
    };
  }

    componentDidMount() {

      }
    render() {
        return (
          <div className="underMaintenance">
            <div className="maintenance-info d-flex flex-column align-items-center">
              <div className="logo">
                <img src={logo} />
              </div>
              <div className="ttl">System Maintenance</div>
              {/* <div className="desc">Our site is under scheduled maintenance from 12:30am EST to approximately 2:30am EST.</div> */}
              <div
                  className="desc"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      this.props.bannerMsg
                    ),
                  }}
                ></div>
              <div className="bg"><img src={maintenanceBg} /></div>
            </div>
            <div className="marketing-info">
              <div className="content">
                <div className="sec-ttl">Need something to do in the meantime?</div>

                <div className="section">
                  <div className="ttl">
                    <div className="sub-ttl">Explore More</div>
                    Discover our Resource Library
                  </div>
                  <div className="desc">Dive into our comprehensive collection of industry insights and best practices.</div>
                  <div className="action">
                    <a className="learn-more" href="https://www.inn-flow.com/resources/" target="_blank">
                      Learn more
                      <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 11L6 6L1 1M8 11L13 6L8 1" stroke="#99F6E0" stroke-width="2" stroke-linecap="round"
                          stroke-linejoin="round"></path>
                      </svg>
                    </a>
                  </div>
                </div>

                <div className="section">
                  <div className="ttl">
                    <div className="sub-ttl">Stay Connected</div>
                    Follow us on Social Media
                  </div>
                  <div className="desc">Stay up to date and be part of the story! </div>
                  <div className="action">
                    <div className="d-flex">
                      <a className="icons" href="https://www.linkedin.com/company/inn-flow/mycompany/" target="_blank">
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0_2_6368)">
                            <path
                              d="M29.6313 0H2.3625C1.05625 0 0 1.03125 0 2.30625V29.6875C0 30.9625 1.05625 32 2.3625 32H29.6313C30.9375 32 32 30.9625 32 29.6938V2.30625C32 1.03125 30.9375 0 29.6313 0ZM9.49375 27.2687H4.74375V11.9937H9.49375V27.2687ZM7.11875 9.9125C5.59375 9.9125 4.3625 8.68125 4.3625 7.1625C4.3625 5.64375 5.59375 4.4125 7.11875 4.4125C8.6375 4.4125 9.86875 5.64375 9.86875 7.1625C9.86875 8.675 8.6375 9.9125 7.11875 9.9125ZM27.2687 27.2687H22.525V19.8438C22.525 18.075 22.4937 15.7937 20.0562 15.7937C17.5875 15.7937 17.2125 17.725 17.2125 19.7188V27.2687H12.475V11.9937H17.025V14.0813H17.0875C17.7188 12.8813 19.2688 11.6125 21.575 11.6125C26.3813 11.6125 27.2687 14.775 27.2687 18.8875V27.2687Z"
                              fill="white" />
                          </g>
                          <defs>
                            <clipPath id="clip0_2_6368">
                              <rect width="32" height="32" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </a>
                      <a className="icons" href="https://www.facebook.com/innflow" target="_blank">
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0_2_6372)">
                            <path
                              d="M32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 23.9859 5.85094 30.6053 13.5 31.8056V20.625H9.4375V16H13.5V12.475C13.5 8.465 15.8888 6.25 19.5434 6.25C21.2934 6.25 23.125 6.5625 23.125 6.5625V10.5H21.1075C19.12 10.5 18.5 11.7334 18.5 13V16H22.9375L22.2281 20.625H18.5V31.8056C26.1491 30.6053 32 23.9859 32 16Z"
                              fill="white" />
                          </g>
                          <defs>
                            <clipPath id="clip0_2_6372">
                              <rect width="32" height="32" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </a>
                    </div>

                  </div>
                </div>


              </div>
            </div>
          </div>          
        )
    }
}