/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import {
  Button,
  Container,
  Dropdown,
  Form,
  Modal,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { SingleDropdownListWithoutSearch } from "../../../Common/Components/SingleDropdownListWithoutSearch";
import { SingleSearchDropdownList } from "../../../Common/Components/SingleSearchDropdownList";
import { HidSelector } from "../../../Common/Components/HidSelector";
import { FiChevronDown } from "react-icons/fi";
import { resolve, reject } from "q";
import { Register as registersevice } from "../../../Common/Services/Register";
import "bootstrap-daterangepicker/daterangepicker.css";
import { ToastContainer, toast } from "react-toastify";
import chevronRight from "../../../Common/Assets/Images/icons/bx-chevron-right.svg";
import BankSyncNoDataFrame from "../../../Common/Assets/Images/BankSyncNoDataFrame2302.png";
import { InfoTooltip } from "../../../Common/Components/InfoTooltip";
import TableSearch from "../../../Common/Components/TableSearch";
import $ from "jquery";
import Spinner from "react-bootstrap/Spinner";
import { LaborPerformance as laborPerformance } from "../../../Common/Services/LaborPerfomance";
import _ from "lodash";
import BankSyncChildTable from "./BankSyncChildTable";
import { bankSyncServices } from "../../../Common/Services/bankSyncServices";
import { confirmAlert } from "react-confirm-alert";
import { Utils } from "../../../Common/Utilis";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { TransactionSlideout } from "../../Register/TransactionSlideout";
import { TransferSlideout } from "../../Register/TransferSlideout";
import { InvoiceEntrySlideout } from "../../Register/InvoiceEntrySlideout";
import { AdjustmentModal } from "./AdjustmentModal";
import { CreditCardSlideout } from "../../Register/CreditCardSlideout";
import { BankAccountSlideout } from "../BankAccountSlideout";
import { CreditCardAccountSlideout } from "../CreditCardAccountSlideout";
import { BankSyncWizard } from "../../Accounting/BankSync/BankSyncWizard";
import { RoleSubPermissionSetting } from "../../../Common/Services/RoleSubPermissionSetting";
import { accountService } from "../../../Common/Services/account";
import { ManualStatementUploadModal } from "./ManualStatementUploadModal";
import { CommentWithoutList } from "../../../Common/Components/CommentWithoutList";

import { CSVLink } from "react-csv";
let exportDataSet: any = [];
let callingPage = "BankSync";
interface IBankSyncTableData {
  id?: number;
  syncID?: number;
  date?: string;
  date1?: string;
  description?: string;
  checkType?: string;
  plaidAmount?: number;
  singleTx?: boolean;
  isExpand?: boolean;
  tranType?: string;
  transCode?: string;
  trUniqueNo?: string;
  MatchedEntry?: string;
  transactions: Array<IBankSyncChild>;
  backHid?: number;
  customField?: string;
  checkNo?: string;
  series?: string;
  updateTable?: string;
  transType?: string;
  Hotelid?: number;
  groupUniqueNo?: string;
  adjustment?: number;
  notes?: string;
  checkNumbers?: string;
  badge?: string;
  isFlag?: string;
  flagTrType?: string;
  flagTrID?: number;
  showFlag?: number;
  showDraft?: number;
  reviewStatus?: string;
}

interface IBankSyncChild {
  data: IBankSyncTransactionData;
  name?: string;
}
export interface IBankSyncTransactionData {
  id?: number;
  rowId?: number;
  syncID?: number;
  trUniqueNo?: number;
  transCode?: string;
  amount?: string;
  date?: any;
  vendorName?: string;
  updateTable?: string;
  transType?: string;
  checkType: string;
  plaidAmount?: number | string;
  backHid?: number;
  checkNo?: string;
  series?: string;
  Hotelid?: number;
  groupUniqueNo?: string;
  adjustment?: number | string;
  badge?: string;
}

export class BankSync extends React.Component<any, any> {
  private tableSearchBankSync: any;
  private childHID: any;
  private accountName: any;
  private BankSyncChildTableRef: any;
  constructor(props: any) {
    super(props);
    this.childHID = React.createRef();
    this.accountName = React.createRef();
    this.tableSearchBankSync = React.createRef();
    this.BankSyncChildTableRef = React.createRef();
    this.state = {
      selectedaccount: "",
      selectedOprID: "",
      backPage: "register",
      isShowSlideout: false,
      isCCShowSlideout: false,
      isNew: true,
      isBgBlue: false,
      selectedRow: {},
      hidValue: "Select",
      hotelName: "Select",
      customDate: false,
      repeatingModal: false,
      hotelList: [],
      showChangeLogModal: false,
      moreAction: [
        { name: "Repeating", eventKey: "repeating" },
        { name: "Change Log", eventKey: "changeLog" },
        { name: "Export", eventKey: "Export" },
      ],
      addTransactionAction: [
        { name: "Deposit", eventKey: "deposit", toShow: 0 },
        { name: "Withdrawal", eventKey: "withdrawal", toShow: 0 },
        { name: "Add Debit", eventKey: "AddDebit", toShow: 1 }, //withdrawal
        { name: "Add Credit", eventKey: "AddCredit", toShow: 1 }, //deposit
        { name: "Add Payment", eventKey: "AddPayment", toShow: 1 },
        { name: "Transfer", eventKey: "transfer", toShow: 0 },
      ],
      isCCAcc: false,
      istableloader: false,
      repeatlist: [],
      regularlist: [],
      splitlist: [],
      selectedRows: [],
      tableData: [],
      tableData2: [],
      accountBal: [],
      accountNamelist: [],
      viewType: "regular",
      accountNames: [],
      accountName: "Select Account Name",
      accountNameValue: -1,
      dayFilter: [
        { id: 1, name: "Last 30 Days" },
        { id: 2, name: "Last 60 Days" },
        { id: 3, name: "Last 90 Days" },
      ],
      transactionFilter: [
        { id: 1, name: "For Review" },
        { id: 2, name: "Completed" },
        { id: 3, name: "Excluded" },
      ],
      transactionFilterValue: 1,
      dayText: "Last 30 Days",
      transactionFilterText: "For Review",
      dayValue: 1,
      totalAcctBal: 0,
      totalsharedHID: [],
      totalsharedHIDTemp: 0,
      totalsharedHIDCount: 0,
      sharedcode: "",
      dateRange: "",
      start: "",
      end: "",
      minDate: "",
      maxDate: "",
      isSelect: true,
      ehidSected: 0,
      isloader: true,
      isSlideOut: false,
      trUniqueNo: 0,
      slideOutRequest: {},
      sildeoutHid: -1,
      sildeoutCode: "",
      pageType: "",
      oprId: -1,
      vid: 0,
      isTransferSlideOut: false,
      isExpand: false,
      densityView: [
        { id: 1, densityType: "densityView_1", name: "WIDE" },
        { id: 2, densityType: "densityView_3", name: "NARROW" },
      ],
      defaultDensityView: "densityView_3",
      expandedList: [],
      expList: [],
      valuesArray: false,
      transferRequest: { acctType: "", glArray: [] },
      creditCardStatementModal: false,
      CCPaymentShow: false,
      CardUniqueNo: -1,
      permissionlist: [],
      exportModal: false,
      isExportRun: false,
      sortingOrder: "asc",
      isCCSlideout: false,
      ccSlideType: "",
      isBulkImportSlideout: false,
      bulkImportRequest: {
        pageName: "",
        hotelName: "",
        hidValue: "",
        oprId: "",
        acctType: "",
      },
      accountStatus: "",
      isReconcilePerm: false,
      isGLBalncePerm: false,
      isfromSearch: false,
      loadingFromOther: false,
      sharedHIDList: [],
      lastUpdatedStatus: "",
      accountBalance: "",
      sharedHID: "",
      bulkSelectArray: {},
      hasMultipleTxn: [],
      selectType: "0",
      tenantID: 0,
      logInUserID: 0,
      defaultFilters: ["densityView_3", "For Review", "Last 30 Days", "0", 1],
      selectedAccountType: "",
      invoiceslideoutData: {},
      invoiceSlideout: false,
      confirmSpinner: false,
      showAdjustmentModal: false,
      adjustmentData: {
        accper: "",
        hidValue: "",
        acctType: "",
        AccountType: "",
        AdjEntryType: "",
        OprID: "",
        adjustmentAmount: 0,
        syncID: 0,
      },
      confirmTxData: [],
      AddTransationSyncID: 0,
      entryType: "",
      showBalance: false,
      hasMultipleAdjustments: false,
      showReconnectButton: false,
      errorCodeMsg: "",
      showBanner: false,
      hasConnectPermission: false,
      reconcileAfterSave: false,
      addTranReq: [],
      sortingField: "date",
      selectedPlaidAmount: "",
      isBankWizardShow: false,
      isConnectBankPerm: false,
      hasBankPerm: false,
      hasNoSubscription: false,
      selectedEhid: {},
      selectedAccount: {},
      isAfterAddConnection: false,
      rowEntryType: "",
      isDateUpdated: false,
      isAlerted: false,
      isAdjustment: false,
      batchTotal: 0,
      notes: "",
      trslideoutData: { hotelID: -1, lettercode: '', oprID: -1, acctType: "", date: "", amount: "", checkType: "" },
      bulkSelectArrayList: [],
      showManualUploadModal: false,
      hasManualStatementUploadPermission: false,
      manualUploadColumns: { CheckNo: false, CustomField: false, Description: false },
      isSyncedAcc: false,
      showCommentModal: false,
      selectedRowForFlag: {},
      draftRequest: {
        incompleteUniqueNo: 0,
        uniqueno: 0,
        hotelID: -1,
        transcationDate: null,
        endDate: null,
        accPeriod: "",
        vendorID: 0,
        vendorName: "",
        internalNotes: "",
        checkNo: "",
        transactionTotal: "",
        tenantID: 0,
        pageType: "",
        paymenttype: "",
        oprID: -1,
        entryType: "",
        accountType: "",
        isRepeat: "No",
        repeatDate: null,
        accPeriodstatus: "Active",
        concilestatus: "No",
        saletax: "",
        vendorContract: "",
        aid: 0,
        actionType: "",
        adj: "N",
        oldentryType: "",
        oldpageType: "",
        vendorDays: "0",
        vendorStatus: "Active",
        contractStatus: "Active",
        copyFromentryType: "",
        olduniqueno: 0,
      },
      draftTrxSlideoutData: {},
      isDraftTrxSlideout: false,
      draftTrxAccDetails: {
        oprID: -1,
        acctType: "",
        accountName: ""
      },
      draftTrxSelectedRow: {},
      isLoader: false,
      showFlaggedCol: false,
      statusFilter: [
        { id: 1, name: "Needs Review", class: "orange", isChecked: false },
        { id: 2, name: "Reviewed", class: "blue", isChecked: false },
        { id: 3, name: "Review Complete", class: "green", isChecked: false }
      ],
      statusFilterSelected: "Flagged",
    }
    this.handleDateCallback = this.handleDateCallback.bind(this);
    this.handleNotesChange = this.handleNotesChange.bind(this);
  }

  componentDidMount() {
    this.getConnectbankPermission();
    this.advancedAccounting();
    this.getDefaultView();
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = storage === null ? 0 : (storage.tenantID);
    let logInUserID: any = storage === null ? 0 : (storage.user_UniqueID);
    let newDate = new Date();
    let strMonth = Number(newDate.getMonth()) + 1;
    let strYear = newDate.getFullYear();
    let accountPeriod = (Number(strMonth) > 9 ? strMonth : "0" + strMonth) + "/01/" + strYear.toString().substring(2, 4);
    this.setState({ tenantID: tenantID, logInUserID, accountPeriod });
    this.loadFromAccount();
  }

  getConnectbankPermission = () => {
    let requestObject = {} as any;
    requestObject.permissionID = 1010;
    requestObject.Module = "Accounting";
    requestObject.Type = "page";
    RoleSubPermissionSetting.GetPageSubPermissionDetails(requestObject)
      .then(async (result: any | null) => {
        if (result != null && result.length > 0) {
          this.setState({ isConnectBankPerm: result.some((o) => o.permissionName.toLowerCase() === "connect bank") });
        }
        resolve();
      })
      .catch((err: any) => {
        reject();
      });
  };
  advancedAccounting = () => {
    accountService.AdvancedAccounting()
      .then(async (result: any | null) => {
        if (result !== null && result.length > 0) {
          let hasBankSync = result.some((o) => o.name.toLowerCase() === "banksync");
          this.setState({
            hasBankPerm: hasBankSync,
            hasNoSubscription: !hasBankSync
          });
        } else {
          this.setState({ hasNoSubscription: true });
        }
      })
      .catch((err: any) => {
        reject();
      });
  }
  getTableData = () => {
    const {
      transactionFilterText,
      hidValue,
      accountNameValue,
      selectType, start, end
    } = this.state;
    let request: any = {};
    request.hotelID = hidValue;
    request.oprId = accountNameValue;
    request.tranType = transactionFilterText;
    request.filterDate = selectType;
    request.StartDate = start;
    request.EndDate = end;
    if (transactionFilterText === "For Review") {
      request.tranType = "Pending";
    }
    this.setState({ istableloader: true });
    bankSyncServices
      .getBankSyncTableData(request)
      .then((res) => {
        let tableData: any = [];
        let nonExpandRows: any = [];
        //debugger;
        let showFlaggedCol = false;
        if (res.length > 0) {
          res[0]?.map((item, index) => {
            if (!showFlaggedCol && item.showFlag === 1) {
              showFlaggedCol = true;
            }
            let newDate = moment(item.date).format("MM/DD/YYYY");
            let tableResponse: IBankSyncTableData = {
              transactions: [],
            };
            tableResponse.id = index + 1;
            tableResponse.syncID = item.syncID;
            tableResponse.date = newDate;
            tableResponse.date1 = item.date;
            tableResponse.description = item.discription;
            tableResponse.checkType = item.cheackType;
            tableResponse.customField = item.customField;
            tableResponse.plaidAmount = parseFloat(Number(item.amount).toFixed(2));
            tableResponse.singleTx = true;
            tableResponse.isExpand = false;
            tableResponse.tranType = item.tranType;
            tableResponse.Hotelid = this.state.hidValue;
            tableResponse.adjustment = parseFloat(Number(item.adjustment).toFixed(2));
            tableResponse.notes = item.notes;
            tableResponse.isFlag = item.isFlag;
            tableResponse.flagTrType = item.flagTrType;
            tableResponse.flagTrID = item.flagTrID;
            tableResponse.showFlag = item.showFlag;
            tableResponse.showDraft = item.showDraft;
            tableResponse.reviewStatus = item.reviewStatus;
            if (item.transCode.search(/transfer/i) !== -1) {
              tableResponse.transCode = "Transfer";
            } else if (item.transCode.search(/CreditCard/i) !== -1) {
              tableResponse.transCode = item.transCode;
            } else if (item.transCode.search(/invoice/i) !== -1) {
              tableResponse.transCode = "Invoice";
            } else if (item.transCode.search(/deposit/i) !== -1) {
              tableResponse.transCode = "Deposit";
            } else if (item.transCode.search(/withdrawal/i) !== -1) {
              tableResponse.transCode = "Withdrawal";
            } else {
              tableResponse.transCode = item.transCode;
            }
            tableResponse.trUniqueNo = item.flagTrType === "DraftTransaction" ? item.flagTrID : item.transUniqueno;
            let matchEntry = "";
            let matchEntryDetails = res[1]?.filter(
              (matchData) => matchData.syncid === item.syncID
            );
            tableResponse.transactions = [];
            if (matchEntryDetails.length > 0) {
              const uniqueCheckNos = new Set();
              matchEntryDetails.map((matchData) => {
                uniqueCheckNos.add(matchData.check_Number);
                const matchedEntryCheckNo = Array.from(uniqueCheckNos).join(', ');
                let transCode = "";
                if (matchData.updateTbale.search(/transfer/i) !== -1) {
                  transCode = "Transfer";
                } else if (matchData.updateTbale.search(/CreditCard/i) !== -1) {
                  transCode = matchData[4];
                } else if (matchData.updateTbale.search(/invoice/i) !== -1) {
                  transCode = "Invoice";
                } else if (matchData.updateTbale.search(/deposit/i) !== -1) {
                  transCode = "Deposit";
                } else if (matchData.updateTbale.search(/withdrawal/i) !== -1) {
                  transCode = "Withdrawal";
                } else if (matchData.updateTbale.search(/miscamount/i) !== -1) {
                  transCode = "Withdrawal";
                }
                let data: IBankSyncTransactionData = {
                  syncID: tableResponse.syncID,
                  trUniqueNo: matchData.uniqueno,
                  transCode: transCode,
                  amount: matchData.displayAmount,
                  date: matchData.date,
                  vendorName: matchData.company,
                  updateTable: matchData.updateTbale,
                  transType: matchData.transType,
                  checkType: "",
                  plaidAmount: tableResponse.plaidAmount,
                  backHid: matchData.backHId,
                  checkNo: matchData.check_Number,
                  Hotelid: this.state.hidValue,
                  groupUniqueNo: matchData.groupUniqueno,
                  adjustment: tableResponse.adjustment,
                  badge: matchData.badge
                };
                if (!isNaN(parseInt(item.cheackType))) {
                  data.checkType = item.cheackType;
                }
                tableResponse.backHid = matchData.backHId;
                tableResponse.checkNo = matchData.check_Number;
                tableResponse.series = "";
                tableResponse.updateTable = matchData.updateTbale;
                tableResponse.transType = matchData.transType;
                tableResponse.groupUniqueNo = matchData.groupUniqueno;
                tableResponse.checkNumbers = matchedEntryCheckNo;
                tableResponse.badge = matchData.badge;
                matchEntry = matchData.matchEntry;
                tableResponse.transactions.push({
                  name: matchEntry,
                  data: data,
                });
              });
              if (matchEntryDetails.length > 1) {
                matchEntry = matchEntryDetails.length + " Transactions";
                tableResponse.singleTx = false;
                let plaidAmount = Math.abs(
                  Number(tableResponse.plaidAmount)
                );
                tableResponse.transactions =
                  tableResponse.transactions.sort((a, b) => {
                    return (
                      Math.abs(Number(a.data.amount) - plaidAmount) -
                      Math.abs(Number(b.data.amount) - plaidAmount)
                    );
                  });
              }
            } else {
              tableResponse.singleTx = false;
            }
            tableResponse.MatchedEntry = matchEntry;
            if (item.tranType === "Excluded" || (item.tranType === "Completed" && matchEntryDetails.length <= 1)) {
              nonExpandRows.push(index + 1);
            }
            tableData.push(tableResponse);
          });
        }
        this.setState(
          {
            tableData: tableData,
            tableDataCopy: tableData,
            nonExpandRow: nonExpandRows,
            isloader: false,
            batchTotal: 0,
            showFlaggedCol,
            statusFilter: [
              { id: 1, name: "Needs Review", class: "orange", isChecked: false },
              { id: 2, name: "Reviewed", class: "blue", isChecked: false },
              { id: 3, name: "Review Complete", class: "green", isChecked: false }
            ],
            statusFilterSelected: "Flagged",
          },
          () => {
            setTimeout(() => {
              this.CleanSearch();
              if (this.state.reconcileAfterSave) {
                //debugger;
                const transData = tableData.filter((o) => o.syncID == this.state.addTranReq[0].SyncId)[0].transactions;
                let reconCileData;
                for (let n of transData) {
                  const dataObj = n?.data;
                  if (+(dataObj.trUniqueNo) === this.state.addTranReq[0].Uniqueno) {
                    reconCileData = dataObj;
                    break;
                  }
                }
                this.setState({ reconcileAfterSave: false, addTranReq: [] });
                this.confirmTransation("single", "Completed", [
                  reconCileData,
                ]);
              } else {
                this.setState({ istableloader: false });
              }
              this.handleSortByColumnOnLoad(this.state.sortingField)
            }, 0);
          }
        );
      })
      .catch((error) => {
        this.CleanSearch();
        Utils.toastError(error?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        this.setState({ isloader: false, istableloader: false });
      });
  };

  hideAdjustmentModal = (isSave) => {
    this.setState(
      {
        showAdjustmentModal: false,
        adjustmentData: {
          accper: "",
          hidValue: "",
          acctType: "",
          AccountType: "",
          AdjEntryType: "",
          OprID: "",
          adjustmentAmount: 0,
          syncID: 0,
        },
      },
      () => {
        const { confirmTxData } = this.state;
        if (isSave === "save") {
          this.saveTransactionStatus(
            confirmTxData[0],
            confirmTxData[1],
            confirmTxData[2]
          );
        } else if (isSave === "error") {
          this.setState(
            {
              // tableData,
              bulkSelectRow: false,
              bulkSelect: false,
              bulkSelectArray: {},
              selectedRows: [],
              expandedList: [],
              isloader: true,
              istableloader: true,
            },
            () => {
              this.getTableData();
            }
          );
        } else if (isSave === "close") {
          if (this.state.isDateUpdated)
            this.selectHotelDropDown(this.state.selectedEhid);
        }
      }
    );
    if (this.BankSyncChildTableRef.current !== null) {
      this.BankSyncChildTableRef?.current.enableConfirmBtn();
    }
  };

  getEtype(mtype, rowType) {
    let etype = "";
    etype = registersevice.getEtype(mtype, rowType);
    return etype;
  }

  showConfirmDateAlertForCheck = (dataToSave: any) => {
    confirmAlert({
      title: "Warning!",
      message: `Select an Inn-Flow transaction dated earlier than the corresponding bank transaction.`,
      buttons: [
        {
          label: "OK",
          onClick: () => {
            reject();
            if (this.BankSyncChildTableRef.current !== null) {
              this.BankSyncChildTableRef?.current.enableConfirmBtn();
              this.BankSyncChildTableRef?.current.resetTableData();
              this.BankSyncChildTableRef?.current.ClearSelection();
            }
            if (dataToSave || [].some((o: any) => o?.transCode === "Transfer")) {
              this.getTableData();
            }
          },
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }

  showConfirmDateAlert = (BankTranDate, entryType, actionType, dataToSave) => {
    confirmAlert({
      title: "Alert!",
      message: `By confirming, this will change the Inn-Flow transaction date to ${BankTranDate}. Are you sure you would like to proceed?`,
      buttons: [
        {
          label: "Confirm",
          onClick: () => {
            this.checkAdjustment(BankTranDate, entryType, actionType, dataToSave);
          },
        },
        {
          label: "Cancel",
          onClick: () => {
            reject();
            if (this.BankSyncChildTableRef.current !== null) {
              this.BankSyncChildTableRef?.current.enableConfirmBtn();
              this.BankSyncChildTableRef?.current.resetTableData();
              this.BankSyncChildTableRef?.current.ClearSelection();
            }
          },
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }

  checkAdjustment = async (BankTranDate, entryType, actionType, dataToSave) => {
    const {
      accountNameValue,
      bulkSelectArray,
      tenantID,
    } = this.state;
    let requestArray: any = [];
    if (dataToSave.length === 0) {
      for (const key in bulkSelectArray) {
        dataToSave.push(bulkSelectArray[key]);
      }
    }

    dataToSave.map((item) => {
      let request: any = { ...item };
      request.oprid = accountNameValue;
      request.Tenantid = tenantID;
      request.Type = actionType;
      request.SaveType = entryType;
      request.trUniqueNo = item.trUniqueNo ? item.trUniqueNo : 0;
      request.transType = item.transType ? item.transType : "";
      request.updateTable = item.updateTable ? item.updateTable : "";
      request.SyncId = item.syncID ? item.syncID : 0;
      request.groupUniqueNo = item.groupUniqueNo ? item.groupUniqueNo : "";
      request.transDate = BankTranDate;
      request.backHid = item.backHid;
      request.IFTransDate = item.date;
      requestArray.push(request);
    });
    console.log(requestArray[0]?.Type);
    let diffrentEHID: boolean = false;
    let checkDataSave = dataToSave;
    checkDataSave = _.uniqBy(checkDataSave, 'backHid');
    diffrentEHID = checkDataSave.length > 1;

    if (await this.checkForAdjustment(dataToSave)) {
      if (diffrentEHID) {
        confirmAlert({
          title: "Alert!",
          message: 'You have selected transactions from more than one EHID. Please only select transactions from the same EHID and continue.',
          buttons: [{
            label: "OK", onClick: () => {
              reject();
              if (this.BankSyncChildTableRef.current !== null) {
                this.BankSyncChildTableRef?.current.enableConfirmBtn();
                this.BankSyncChildTableRef?.current.resetTableData();
              }
            }
          }],
          closeOnEscape: false,
          closeOnClickOutside: false,
        });
      } else {
        this.setState({
          showAdjustmentModal: true,
          isAdjustment: true,
          confirmTxData: [entryType, actionType, dataToSave],
          rowEntryType: dataToSave[0].updateTable
        });
      }
    } else {
      this.saveTransactionStatus(entryType, actionType, dataToSave);
    }
  }

  UpdateFromAdjustment = async () => {
    let { BankTranDate, entryType, actionType, dataToSave } = this.state;
    const {
      accountNameValue,
      bulkSelectArray,
      tenantID,
    } = this.state;

    let requestArray: any = [];
    if (dataToSave.length === 0) {
      for (const key in bulkSelectArray) {
        dataToSave.push(bulkSelectArray[key]);
      }
    }

    dataToSave.map((item) => {
      let request: any = { ...item };
      request.oprid = accountNameValue;
      request.Tenantid = tenantID;
      request.Type = actionType;
      request.SaveType = entryType;
      request.trUniqueNo = item.trUniqueNo ? item.trUniqueNo : 0;
      request.transType = item.transType ? item.transType : "";
      request.updateTable = item.updateTable ? item.updateTable : "";
      request.SyncId = item.syncID ? item.syncID : 0;
      request.groupUniqueNo = item.groupUniqueNo ? item.groupUniqueNo : "";
      request.transDate = BankTranDate;
      request.backHid = item.backHid;
      request.IFTransDate = item.date;
      requestArray.push(request);
    });
    const response: any = await Promise.all([bankSyncServices
      .UpdateIFTranDateToPlaidTranDate(requestArray)]);
    return response;
  }

  confirmTransation = async (entryType, actionType, dataToSave: any = []) => {
    let IsPendingPayment = this.state.bulkSelectArrayList.some((o) => (o?.badge === "Draft" || o?.badge === 'Awaiting Approval' || o?.badge === 'Awaiting Payment'));
    if (!IsPendingPayment) {
      let MainTrans = this.state.tableData.filter((o) => o.syncID === dataToSave[0]?.syncID);
      let BankTranDate = MainTrans[0]?.date;
      let isValid: any = true; let isDailyValid: any = true;
      let isDailyActivity = dataToSave.some((e) => e?.updateTable?.toLowerCase() === "depositentry");
      let isPayrollEntry = dataToSave.some((e) => e?.updateTable?.toLowerCase() === "payrollimportexcel" || e?.updateTable?.toLowerCase() === "payrollcheckapi");
      if (isDailyActivity || isPayrollEntry) {
        dataToSave.map((item) => {
          if (item?.updateTable?.toLowerCase() === "depositentry" || item?.updateTable?.toLowerCase() === "payrollimportexcel" || item?.updateTable?.toLowerCase() === "payrollcheckapi") {
            let d1 = `01/${(moment(item.date).format("MM/YY"))}`;
            let d2 = `01/${(moment(BankTranDate).format("MM/YY"))}`;
            if (new Date(d1) > new Date(d2)) {
              Utils.toastError("Can not reconcile future date transations.", {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
              if (this.BankSyncChildTableRef.current !== null) {
                this.BankSyncChildTableRef?.current.enableConfirmBtn();
                this.BankSyncChildTableRef?.current.resetTableData();
                this.BankSyncChildTableRef?.current.ClearSelection();
              }
              isDailyValid = false;
              return false;
            }
          }
        });

      }
      if (isDailyValid) {
        dataToSave.map((item) => {
          if (item?.transType?.toLowerCase() === "check" || item?.transType?.toLowerCase() === "chkgroup" || (!isNaN(item?.checkNo) && item.transType.includes('Transfer')))
            if (new Date(item.date) > new Date(BankTranDate)) {
              this.showConfirmDateAlertForCheck(dataToSave);
              isValid = false;
              return;
            }
        });
        //}
        if (isValid) {
          if (actionType === "Completed") {
            this.checkForValidations(entryType, actionType, dataToSave, BankTranDate);
          } else {
            this.saveTransactionStatus(entryType, actionType, dataToSave);
          }
        }
      }
    } else {
      Utils.toastError("There is some transaction with pending payment.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  checkForValidations = async (entryType, actionType, dataToSave, BankTranDate) => {
    this.setState({
      isAlerted: false,
      entryType: entryType,
      actionType: actionType,
      dataToSave: dataToSave,
      BankTranDate: BankTranDate
    });
    const {
      accountNameValue,
      bulkSelectArray,
      tenantID,
    } = this.state;
    let requestArray: any = [];
    if (dataToSave.length === 0) {
      for (const key in bulkSelectArray) {
        dataToSave.push(bulkSelectArray[key]);
      }
    }
    if (entryType === "bulk" && BankTranDate === undefined)
      BankTranDate = this.state.tableData.filter((o) => o.syncID === dataToSave[0]?.syncID)[0]?.date;

    dataToSave.map((item) => {
      let request: any = {};
      if (entryType === "bulk") {
        request.oprid = accountNameValue;
        request.Tenantid = tenantID;
        request.Type = actionType;
        request.SaveType = entryType;
        request.trUniqueNo = item.trUniqueNo ? item.trUniqueNo : 0;
        request.transType = item.transType ? item.transType : "";
        request.updateTable = item.updateTable ? item.updateTable : "";
        request.SyncId = item.syncID ? item.syncID : 0;
        request.groupUniqueNo = item.groupUniqueNo ? item.groupUniqueNo : "";
        request.transDate = this.state.tableData.filter((o) => o.syncID === item?.syncID)[0]?.date;
        request.backHid = item.backHid;
      } else {
        request.oprid = accountNameValue;
        request.Tenantid = tenantID;
        request.Type = actionType;
        request.SaveType = entryType;
        request.trUniqueNo = item.trUniqueNo ? item.trUniqueNo : 0;
        request.transType = item.transType ? item.transType : "";
        request.updateTable = item.updateTable ? item.updateTable : "";
        request.SyncId = item.syncID ? item.syncID : 0;
        request.groupUniqueNo = item.groupUniqueNo ? item.groupUniqueNo : "";
        request.transDate = BankTranDate;
        request.backHid = item.backHid;
      }
      requestArray.push(request);
    });

    let diffrentEHID: boolean = false;
    let checkDataSave = dataToSave;
    checkDataSave = _.uniqBy(checkDataSave, 'backHid');
    diffrentEHID = checkDataSave.length > 1;
    bankSyncServices
      .BankSyncValidation(requestArray)
      .then(async (res: any) => {
        if (res !== null) {
          if (res.result?.toLowerCase() === 'success') {
            if (await this.checkForAdjustment(dataToSave)) {
              if (diffrentEHID) {
                confirmAlert({
                  title: "Alert!",
                  message: 'You have selected transactions from more than one EHID. Please only select transactions from the same EHID and continue.',
                  buttons: [{
                    label: "OK", onClick: () => {
                      reject();
                      if (this.BankSyncChildTableRef.current !== null) {
                        this.BankSyncChildTableRef?.current.enableConfirmBtn();
                        this.BankSyncChildTableRef?.current.resetTableData();
                      }
                    }
                  }],
                  closeOnEscape: false,
                  closeOnClickOutside: false,
                });
              } else {
                this.setState({
                  showAdjustmentModal: true,
                  isAdjustment: true,
                  confirmTxData: [entryType, actionType, dataToSave],
                  rowEntryType: dataToSave[0].updateTable
                });
              }
            } else {
              this.saveTransactionStatus(entryType, actionType, dataToSave);
            }
          } else {
            if (res?.message?.toLowerCase() == "inactive accounting period can not be confirmed.") {
              this.showConfirmAlert();
            } else if (res?.message?.toLowerCase() == "can not reconcile future date transations.") {
              if (entryType == "bulk") {
                this.checkAdjustment(BankTranDate, entryType, actionType, dataToSave);
              } else {
                this.setState({ isAlerted: true });
                this.showConfirmDateAlert(BankTranDate, entryType, actionType, dataToSave);
              }
            } else {
              Utils.toastError(res.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
              if (this.BankSyncChildTableRef.current !== null) {
                this.BankSyncChildTableRef?.current.enableConfirmBtn();
              }
            }
          }
        }
      })
      .catch((err) => {
        Utils.toastError("Failed to fetch adjustment amount.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        if (this.BankSyncChildTableRef.current !== null) {
          this.BankSyncChildTableRef?.current.enableConfirmBtn();
        }
        return true;
      });

  };

  showConfirmAlert = () => {
    confirmAlert({
      title: "Alert!",
      message: `This bank transaction cannot be reconciled, because the payment date is within a closed accounting period.  Please open the accounting period and then reconcile this transaction.`,
      buttons: [
        {
          label: "Ok",
          onClick: () => {
            reject();
            if (this.BankSyncChildTableRef.current !== null) {
              this.BankSyncChildTableRef?.current.enableConfirmBtn();
              this.BankSyncChildTableRef?.current.resetTableData();
              this.BankSyncChildTableRef?.current.ClearSelection();
            }
          }
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }

  checkForAdjustment = async (dataToSaveA?) => {
    const {
      hidValue,
      accountNameValue,
      hotelName,
      selectedAccountType,
      tenantID,
    } = this.state;
    let dataToSave = dataToSaveA[0];
    let plaidTxAmount = dataToSave.plaidAmount;
    let amountDiff = 0;
    let requestArr: any = [];
    let date;
    let BankTranDate = this.state.tableData.filter((o) => o.syncID === dataToSave?.syncID)[0]?.date;
    if (dataToSave.transactions === undefined) {
      dataToSaveA.map((item) => {
        let requestObj: any = {};
        requestObj.TenantID = tenantID;
        requestObj.Uniqueno = item.trUniqueNo;
        requestObj.Backhid = hidValue;
        requestObj.SyncID = item.syncID;
        requestObj.Oprid = accountNameValue;
        requestObj.TableName = item.updateTable;
        requestObj.PlaidAmount = plaidTxAmount;
        requestObj.groupUniqueNo = item.groupUniqueNo ? item.groupUniqueNo : "";
        requestArr.push(requestObj);
      });
      date = new Date(BankTranDate);
    } else {
      let ifTransData = dataToSave?.transactions[0]?.data;
      let requestObj: any = {};
      requestObj.TenantID = tenantID;
      requestObj.Uniqueno = ifTransData.trUniqueNo;
      requestObj.Backhid = hidValue;
      requestObj.SyncID = ifTransData.syncID;
      requestObj.Oprid = accountNameValue;
      requestObj.TableName = ifTransData.updateTable;
      requestObj.PlaidAmount = plaidTxAmount;
      requestObj.groupUniqueNo = ifTransData.groupUniqueNo ? ifTransData.groupUniqueNo : "";
      requestArr.push(requestObj);
      date = new Date(BankTranDate);
    }

    let response: any = await bankSyncServices
      .GetBankSyncAdjustmentValue(requestArr)
      .then((res: any) => {
        if (res !== null) {
          if (res.length > 0) {
            amountDiff = Number(res[0]?.amount);
            if (amountDiff === 0) {
              return { showAdjustment: false, adjustmentType: "", adjEntryType: "", hotelName: "", backHid: "" };
            } else {
              return {
                showAdjustment: true,
                adjustmentType: res[0]?.type,
                adjEntryType: res[0]?.adjEntryType,
                hotelName: res[0]?.hotelName,
                backHid: res[0]?.backHid
              };
            }
          }
        }
      })
      .catch((err) => {
        Utils.toastError("Failed to fetch adjustment amount.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        return true;
      });

    let syncID = dataToSave.syncID;
    let adjustmentData: any = {
      accTper: this.state.accountPeriod,
      accper: date ? date : "",
      hidValue: response.backHid ? response.backHid : hidValue,
      hotelName: response.hotelName ? response.hotelName : hotelName,
      acctType: selectedAccountType,
      AccountType: response.adjustmentType,
      AdjEntryType: response.adjEntryType,
      OprID: accountNameValue,
      adjustmentAmount: amountDiff.toFixed(2),
      syncID: syncID,
    };

    this.setState({ adjustmentData });
    return response.showAdjustment;

  };
  closeCommentModal = (isUpdate) => {
    this.setState({ showCommentModal: false, selectedRowForFlag: {} });
  }

  openCommentModal = (row) => {
    this.setState({ showCommentModal: true, selectedRowForFlag: row });
  }

  getEntryType = (amount) => {
    let entryType = "";
    switch (this.state.selectedAccountType) {
      case 'O':
        if (amount < 0) {
          entryType = "Withdrawal";
        } else {
          entryType = "Deposit";
        }
        break;
      case 'M':
        if (amount < 0) {
          entryType = "Misc";
        } else {
          entryType = "MiscDeposit";
        }
        break;
      case 'P':
        if (amount < 0) {
          entryType = "PayrollWithdrawal";
        } else {
          entryType = "PayrollDeposit";
        }
        break;
      case 'C':
        if (amount < 0) {
          entryType = "CCDEBIT";
        } else {
          entryType = "CCCREDIT";
        }
        break;
      default:
        break;
    }
    return entryType;
  }

  createAndFlagDraftTrx = (mentionData: any) => {
    let selectedRow = Object.assign({}, JSON.parse(JSON.stringify(this.state.selectedRowForFlag)));
    let request = Object.assign({}, JSON.parse(JSON.stringify(this.state.draftRequest)));
    let trxDate = selectedRow.date;
    let userComments: any = this.getUserCommentData(mentionData);

    request.accPeriod = trxDate === null ? "" : moment(trxDate.split("/")[0] + "/01/" + trxDate.split("/")[2]).format("DD-MMM-YYYY");
    request.transcationDate = trxDate === null ? "" : moment(trxDate).format("DD-MMM-YYYY");
    request.internalNotes = selectedRow.description;
    request.transactionTotal = Utils.removecurrencyFormat(selectedRow.plaidAmount);
    if (selectedRow.plaidAmount < 0) {
      request.transactionTotal = request.transactionTotal * -1;
    }
    request.checkNo = this.state.selectedAccountType === "M" && selectedRow.plaidAmount < 0 ? isNaN(selectedRow.checkType) ? "" : selectedRow.checkType : "";
    request.hotelID = selectedRow.Hotelid;
    request.tenantID = this.state.tenantID;
    request.pageType = this.getEntryType(selectedRow.plaidAmount);
    request.paymenttype = this.state.selectedAccountType === "C" ? "" : this.state.selectedAccountType === "M" && selectedRow.plaidAmount < 0 ? isNaN(selectedRow.checkType) ? "ACH" : "Check" : this.state.selectedAccountType === "O" && selectedRow.plaidAmount < 0 ? "ACH" : "";
    request.oprID = this.state.accountNameValue;
    request.entryType = this.getEntryType(selectedRow.plaidAmount);
    request.IncompleteUniqueNo = 0;
    request.isDraftUpdate = "No";
    request.reconcileAccPer = "";
    request.SplitItems = [];
    request.isSplitChange = "false";
    request.TempUniqueNo = "";
    request.OldpageType = "TempUploadFiles";
    request.isSaveDraft = "Yes";
    request.UserComments = userComments;
    request.bankSyncId = selectedRow.syncID;
    request.callFrom = "banksync";
    this.setState({ isLoader: true });

    registersevice
      .SaveTransaction(request, [])
      .then(async (result: any | null) => {
        if (result !== null) {
          this.setState({ isLoader: false });
          this.closeCommentModal(false);
          if (result.saveStatus === "Success") {
            let arr: any = {};
            arr.id = this.state.CardUniqueNo;
            arr.status = "active";
            this.getTableData();
            toast.success("Record Saved Successfully", {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "TransSlideout",
            });
          } else {
            Utils.toastError(result.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "TransSlideout",
            });
          }
        }
        resolve();
      })
      .catch((error) => {
        Utils.toastError("Something went wrong.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "TransSlideout",
        });
        this.setState({ isLoader: false });
        reject();
      });

  }

  getUserCommentData = (mentionData: any) => {
    //debugger;
    let selectedRow = Object.assign({}, JSON.parse(JSON.stringify(this.state.selectedRowForFlag)));
    let trxDate = selectedRow.date;
    let comment: any = "";
    let comment1: any = "";
    let displayUser: any[] = [];

    let storage = JSON.parse(localStorage.getItem("storage")!);
    let userName = storage === null ? 0 : (storage.userName as any);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let currentDateTime = new Date().toLocaleString(); //March 22nd at 3:32pm
    let userComments: any = [];

    comment = mentionData.newPlainTextValue.trim();
    if (comment.length > 0) {
      if (mentionData.mentions.length > 0) {
        mentionData.mentions.forEach(element => {
          let userCount = displayUser.filter(x => x.display === element.display.trim()).length;

          if (userCount === 0) {
            comment1 = comment.replaceAll(element.display.trim(), '<span>' + element.display.trim() + '</span>')
            comment = comment1;
            displayUser.push(element.display.trim());
          }

          let usercomment: any = {};
          usercomment.uniqueNo = 0;
          usercomment.entryType = this.getEntryType(selectedRow.plaidAmount);
          usercomment.logInuser = userName;
          usercomment.createDate = currentDateTime.toString();
          usercomment.toUser = element.display.trim().replace("@", "");
          usercomment.comment = mentionData.newPlainTextValue;
          usercomment.htmlcomment = comment1.replaceAll('\n', '<br/>');
          usercomment.tenantID = tenantID;
          usercomment.date = trxDate;
          usercomment.vendor = "";
          usercomment.amount = selectedRow.plaidAmount;
          usercomment.status = "Flag for Review";

          userComments.push(usercomment);

        });

        if (userComments.length > 0) {
          userComments.forEach(ele => {
            // ele.htmlcomment = ele.htmlcomment.replaceAll('\n', '<br/>');
            ele.htmlcomment = comment1.replaceAll('\n', '<br/>');
          });
        }
      }
      else {
        comment1 = mentionData.newPlainTextValue;
        let usercomment: any = {};
        usercomment.uniqueNo = 0;
        usercomment.entryType = selectedRow.checkType;;
        usercomment.logInuser = userName;
        usercomment.createDate = new Date().toLocaleString().toString();
        usercomment.toUser = "";
        usercomment.comment = mentionData.newPlainTextValue;
        usercomment.htmlcomment = comment1.replaceAll('\n', '<br/>');
        usercomment.tenantID = tenantID;
        usercomment.date = trxDate;
        usercomment.vendor = "";
        usercomment.amount = selectedRow.plaidAmount;
        usercomment.status = "Flag for Review";
        userComments.push(usercomment);
      }
    }
    return userComments;
  }

  openDraftTrxSlideout = (row, rowIndex) => {
    let request: any = {};
    request.uniqueNo = row.flagTrID;
    request.hotelId = row.Hotelid;
    request.oprId = -1;
    request.checkNo = "";
    request.etype = row.flagTrType;
    request.series = "";

    registersevice
      .getSlideoutData(request)
      .then(async (result: any | null) => {
        if (result != null && result.length > 0) {
          let draftTrxAccDetails: any = {};
          draftTrxAccDetails.oprID = result[0]?.oprID;
          draftTrxAccDetails.acctType = this.state.selectedAccountType;
          draftTrxAccDetails.accountName = result[0]?.acctName;
          let draftTrxSelectedRow: any = { checkno: "", hid: result[0]?.hotelID, groupID: 0, oprId: result[0]?.oprID, series: "", invPostFix: "", isDraftUpdate: row.flagTrType === "DraftTransaction" ? "Yes" : "No" };
          this.setState({ draftTrxSlideoutData: result[0], draftTrxAccDetails, draftTrxSelectedRow }, () => {
            this.setState({ isDraftTrxSlideout: true, });
          });
        } else {

        }
        resolve();
      })
      .catch((error) => {
        reject();
      });
  }

  hideDraftTrxSlideout = (isSave) => {
    this.setState({
      draftTrxSlideoutData: {},
      isDraftTrxSlideout: false,
      draftTrxAccDetails: {
        oprID: -1,
        acctType: "",
        accountName: ""
      },
      draftTrxSelectedRow: {},
    }, () => {
      if (isSave) {
        this.getTableData();
      }
    });
  }

  saveTransactionStatus = async (
    entryType,
    actionType,
    dataToSave: any = []
  ) => {
    if (!this.state.isAdjustment && this.state.isAlerted) {
      let res: any;
      res = await Promise.all([this.UpdateFromAdjustment()]);
      console.log(res);
    }

    this.setState({ isAdjustment: false });
    let BankTranDate = this.state.tableData.filter((o) => o.syncID === dataToSave[0]?.syncID)[0]?.date;
    let bulkSelectRow = false,
      bulkSelect = false;
    let { hidValue, accountNameValue, bulkSelectArray, tenantID } = this.state;
    let newTableData = [...this.state.tableData];
    let requestArray: any = [];
    if (dataToSave.length === 0) {
      for (const key in bulkSelectArray) {
        dataToSave.push(bulkSelectArray[key]);
      }
    }

    dataToSave.map((item) => {
      let request: any = {};
      if (entryType === "bulk") {
        request.oprid = accountNameValue;
        request.Hotelid = hidValue;
        request.Tenantid = tenantID;
        request.Type = actionType;
        request.SaveType = entryType;
        request.trUniqueNo = item.trUniqueNo ? item.trUniqueNo : 0;
        request.transType = item.transType ? item.transType : "";
        request.updateTable = item.updateTable ? item.updateTable : "";
        request.SyncId = item.syncID ? item.syncID : 0;
        request.groupUniqueNo = item.groupUniqueNo ? item.groupUniqueNo : "";
        request.backHid = item.backHId;
        request.transDate = this.state.tableData.filter((o) => o.syncID === item?.syncID)[0]?.date;
        if (item.rowId) {
          newTableData = newTableData.filter(
            (tableObj) => tableObj.syncID !== item.rowId
          );
        } else {
          newTableData = newTableData.filter((item1) => item.id !== item1.id);
        }
      } else {
        request.SyncId = item.syncID ? item.syncID : 0;
        request.oprid = accountNameValue;
        request.Hotelid = hidValue;
        request.Tenantid = tenantID;
        request.Type = actionType;
        request.SaveType = entryType;
        request.trUniqueNo = item.trUniqueNo ? item.trUniqueNo : 0;
        request.transType = item.transType ? item.transType : "";
        request.updateTable = item.updateTable ? item.updateTable : "";
        request.groupUniqueNo = item.groupUniqueNo ? item.groupUniqueNo : "";
        request.backHid = item.backHid;
        request.transDate = BankTranDate;
        newTableData = newTableData.filter(
          (tableObj) => tableObj.syncID !== item.syncID
        );
      }
      requestArray.push(request);
    });

    bankSyncServices
      .SaveBankSyncTranctionStatus(requestArray)
      .then((res: any) => {
        if (res !== null && res.message.toLowerCase() === "success") {
          this.setState({
            tableData: newTableData,
            confirmSpinner: false,
            expandedList: [],
          });
          toast.success("Transaction has been saved successfully.", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });

          this.getTableData();
        } else {
          if (res?.message?.toLowerCase() == "inactive accounting period can not be confirmed.") {
            this.setState({
              confirmSpinner: false,
            });
            confirmAlert({
              title: "Alert!",
              message: `This bank transaction cannot be reconciled, because the payment date is within a closed accounting period.  Please open the accounting period and then reconcile this transaction.`,
              buttons: [
                {
                  label: "Ok",
                  onClick: () => {
                    reject();
                    if (this.BankSyncChildTableRef.current !== null) {
                      this.BankSyncChildTableRef?.current.enableConfirmBtn();
                      this.BankSyncChildTableRef?.current.resetTableData();
                      this.BankSyncChildTableRef?.current.ClearSelection();
                    }
                  }
                },
              ],
              closeOnEscape: false,
              closeOnClickOutside: false,
            });
          } else {
            Utils.toastError(res.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            this.setState(
              {
                bulkSelectRow: false,
                bulkSelect: false,
                bulkSelectArray: {},
                selectedRows: [],
                expandedList: [],
                isloader: true,
                istableloader: true,
              },
              () => {
                this.getTableData();
              }
            );
            this.setState({
              confirmSpinner: false,
            });
          }
        }
      })
      .catch((err) => {
        Utils.toastError("Transaction update failed.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        this.setState({
          confirmSpinner: false,
        });
      });
    $("#footerBulkSelect").prop("checked", false).prop("indeterminate", false);
    this.setState({
      bulkSelectRow,
      bulkSelect,
      bulkSelectArray: {},
      selectedRows: [],
      hasMultipleTxn: [],
      hasMultipleAdjustments: false,
    });
  };

  getBankBalance = (request) => {
    bankSyncServices
      .getBankBalance(request)
      .then((resp) => {
        if (resp !== null) {
          let res = resp[0];
          let sharedHIDs = res.sharing.split(",");
          let { manualUploadColumns } = this.state
          let statusNotUploadedYet: any = res.balUpdatetime.toString().toLocaleLowerCase().includes("not uploaded yet");
          let colData = res.manualUploadColumns === undefined ? [] : res.manualUploadColumns.split(",");
          manualUploadColumns.CustomField = ((this.state.isSyncedAcc || res?.balUpdatetime?.includes("Updated on")) ? true : colData.some((o: any) => o.toString().toLocaleLowerCase() === "customfield"));
          manualUploadColumns.Description = ((this.state.isSyncedAcc || res?.balUpdatetime?.includes("Updated on")) ? true : statusNotUploadedYet ? true : colData.some((o: any) => o.toString().toLocaleLowerCase() === "description"));
          this.setState(
            {
              sharedHIDList: sharedHIDs,
              hasManualStatementUploadPermission: res?.hasManualStatementUploadPermission === "true" ? true : false,
              sharedHID: res.sharing,
              lastUpdatedStatus: res.balUpdatetime,
              accountBalance: res.accBalance,
              showBalance: res.glBalancee === "true" ? true : false,
              showReconnectButton:
                res.plaidError.toLowerCase() === "yes" ? true : false,
              errorCodeMsg: res.errorCodeMsg,
              showBanner: res.errorCodeMsg !== "",
              hasConnectPermission:
                res.hasConnectPermission.toLowerCase() === "true"
                  ? true
                  : false,
              manualUploadColumns
            },
            () => {
              this.getTableData();
            }
          );
        }
      })
      .catch((error) => {
        Utils.toastError(error.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };

  getParamValueByParamName(paramName: string): any {
    let valuesArray = decodeURIComponent(this.props.history.location.search)
      ?.replace("?", "")
      ?.split("&");
    window.history.replaceState(null, "");
    return valuesArray;
  }

  loadFromAccount = () => {
    const location = this.props.location;
    if (location.state !== undefined) {
      const stateLength = Object.keys(location.state).length;
      if (stateLength > 0) {
        let { oprid } = location.state;
        this.setState({ selectedOprID: oprid, loadingFromOther: true });
      }
    }
  };

  selectHotelDropDown = (hotelDetails: any) => {
    const valuesArray = this.getParamValueByParamName("");
    if (valuesArray.length > 1) {
      return;
    }
    this.setState({
      hotelName: hotelDetails.lettercode,
      hidValue: hotelDetails.hotelID,
    });
    let repeatlist = [],
      regularlist = [],
      splitlist = [],
      accountBal = [],
      viewType = "regular";
    let dayFilter: any[] = [
      { id: 1, name: "Last 30 Days" },
      { id: 2, name: "Last 60 Days" },
      { id: 3, name: "Last 90 Days" },
    ];
    let dayValue = 1,
      totalAcctBal = 0,
      totalsharedHIDCount = 0,
      totalsharedHIDTemp = 0;
    let totalsharedHID: any[] = [];
    let sharedcode = "";
    let accountNamelist: any[] = [];
    let accountNames: any[] = [];
    console.log("4", accountNames);

    let accountName = "Select Account Name";
    let accountNameValue = -1;
    const { defaultFilters } = this.state;
    this.setState(
      {
        defaultDensityView: defaultFilters[0],
        transactionFilterText: defaultFilters[1],
        dayText: defaultFilters[2],
        selectType: defaultFilters[3],
        transactionFilterValue: defaultFilters[4],
        repeatlist,
        regularlist,
        splitlist,
        accountBal,
        viewType,
        dayFilter,
        dayValue,
        totalAcctBal,
        totalsharedHID,
        totalsharedHIDTemp,
        totalsharedHIDCount,
        sharedcode,
        accountNamelist,
        accountNames,
        accountName,
        accountNameValue,
        dateRange: "",
        start: "",
        end: "",
        isSelect: true,
        ehidSected: 0,
        selectedEhid: hotelDetails,
        bulkSelectArrayList: [],
        hasManualStatementUploadPermission: false,
        statusFilterSelected: "Flagged",
        statusFilter: [
          { id: 1, name: "Needs Review", class: "orange", isChecked: false },
          { id: 2, name: "Reviewed", class: "blue", isChecked: false },
          { id: 3, name: "Review Complete", class: "green", isChecked: false }
        ],
      },
      () => {
        let registerRequest1: any = {};
        registerRequest1.hotelID = hotelDetails.hotelID;
        registerRequest1.lettercode = hotelDetails.lettercode;
        registerRequest1.logInUserID = this.state.logInUserID;
        registerRequest1.PageType = "BankSync";
        this.getRegisterNames(registerRequest1);
      }
    );
    if (this.childHID.current !== null) {
      let hidList = this.childHID.current.handleHIDList();
      this.setState({ hotelList: hidList });
    }
  };

  getRegisterNames = (registerRequest: any) => {
    let selectedaccount = "";
    let selectedStatus = "";
    bankSyncServices
      .BankSyncAccounts(registerRequest)
      .then(async (result: any[] | null) => {
        if (result !== null && result.length > 0) {
          let accountNames: any[] = [];
          result.sort((a, b) =>
            a.status !== b.status ? (a.status < b.status ? -1 : 1) : 0
          );
          result.forEach((element) => {
            if (element.status.toLowerCase() === "active") {
              let acctName: any = {};
              let oprID = element.oprID;
              let name = element.name;
              acctName.id = oprID;
              acctName.sort = element.orderBy;
              acctName.name = name;
              acctName.status = element.status;
              acctName.bName = element.bName;
              acctName.acctType = element.acctType;
              acctName.isSynced = Number(element.isSynced);
              accountNames.push(acctName);

              if (Number(this.state.selectedOprID) === Number(oprID)) {
                selectedaccount = name;
                selectedStatus = element.status;
              }
            }
          });

          let isDeviderSync: any = { bName: "SYNCED ACCOUNTS", id: 0, name: "Devider", sort: 0, status: "isLabelSyncd", };
          let isDeviderOther: any = { bName: "OTHER ACCOUNTS", id: 0, name: "Devider", sort: 0, status: "isLabelOther", };
          let isSyncAcc = accountNames.filter((item) => item.isSynced === 1);
          isSyncAcc = _.sortBy(isSyncAcc, "sort");
          let isOthrAcc = accountNames.filter((item) => item.isSynced === 0);
          isOthrAcc = _.sortBy(isOthrAcc, "sort");
          let combineGroup: any = [];
          isSyncAcc.length > 0 && combineGroup.push(isDeviderSync);
          combineGroup.push(...isSyncAcc);
          isOthrAcc.length > 0 && combineGroup.push(isDeviderOther);
          combineGroup = [...combineGroup, ...isOthrAcc];
          this.setState({ accountNames: [] })
          accountNames = _.sortBy(accountNames, "sort");
          this.setState({ accountNamelist: result });
          const location = this.props.location;
          if (location.state !== undefined) {
            const stateLength = Object.keys(location.state).length;
            if (stateLength > 0) {
              let arr: any = {};
              arr.id = this.state.selectedOprID;
              arr.status = selectedStatus;

              this.setState(
                { accountNames: combineGroup, accountName: selectedaccount },
                () => {
                  this.handleaccountNameSelectedItem(1, arr);
                }
              );
            }
          } else if (this.state.isAfterAddConnection) {
            const oldaccount = this.state.selectedAccount;
            let arr: any = {};
            arr.id = oldaccount?.id;
            arr.status = oldaccount?.status;
            setTimeout(() => {
              this.setState(
                { accountNames: combineGroup, accountName: oldaccount?.name !== undefined ? oldaccount?.name : "Select Account Name" },
                () => {
                  if (oldaccount?.name !== undefined) {
                    this.handleaccountNameSelectedItem(1, arr);
                  }
                });
            }, 100);
            this.setState({ isAfterAddConnection: false });
          } else {
            if (accountNames.length === 1) {
              this.handleaccountNameSelectedItem(1, accountNames[0]);
              this.setState({ accountName: accountNames[0].name }, () => {
                this.setState({
                  accountNames: combineGroup,
                  showReconnectButton: false,
                  errorCodeMsg: "",
                  showBanner: false,
                  hasConnectPermission: false,
                });
              });
            } else {
              this.setState({
                accountNames: combineGroup,
                showReconnectButton: false,
                errorCodeMsg: "",
                showBanner: false,
                hasConnectPermission: false,
              });
            }
          }
          resolve();
        }
      })
      .catch((error) => {
        reject();
      });
  };

  getDefaultView() {
    this.setState({ pageloader: true });
    laborPerformance
      .getDefaultViewProperties("bankSync")
      .then(async (response: any | null) => {
        if (response?.length > 0) {
          let defaultDensityView = "";
          let transactionFilterText = "";
          let transactionFilterValue = 1;
          let selectType = "";
          let DayText = "Last 30 Days";
          response.map((item) => {
            if (item.fieldName === "DayCount") {
              if (item.fieldValue === "60") {
                DayText = "Last 60 Days";
              } else if (item.fieldValue === "90") {
                DayText = "Last 90 Days";
              }
              selectType = item.fieldValue;
            } else if (item.fieldName === "transType") {
              transactionFilterText = item.fieldValue;
              if (item.fieldValue !== "For Review") {
                transactionFilterValue = 2;
              }
            } else if (item.fieldName === "sort") {
              defaultDensityView = item.fieldValue;
            }
          });
          this.setState({
            defaultFilters: [
              defaultDensityView,
              transactionFilterText,
              DayText,
              selectType,
              transactionFilterValue,
            ],
            defaultDensityView,
            transactionFilterText,
            selectType,
            dayText: DayText,
            transactionFilterValue,
          });
        } else {
          this.setState({
            defaultDensityView: this.state.densityView[1].densityType,
          });
        }
      })
      .catch((error) => {
        this.setState({
          defaultDensityView: this.state.densityView[1].densityType,
        });
        Utils.toastError(error.message, { position: toast.POSITION.BOTTOM_RIGHT });
      });
  }

  saveDefaultView = (): void => {
    const laborSaveAsDefaultdtos: any[] = [];
    const { defaultDensityView, transactionFilterText, selectType } =
      this.state;
    laborSaveAsDefaultdtos.push({
      fieldName: "sort",
      fieldValue: defaultDensityView,
      pageName: "bankSync",
    });
    laborSaveAsDefaultdtos.push({
      fieldName: "transType",
      fieldValue: transactionFilterText,
      pageName: "bankSync",
    });
    laborSaveAsDefaultdtos.push({
      fieldName: "DayCount",
      fieldValue: selectType,
      pageName: "bankSync",
    });
    laborPerformance
      .saveDefaultViewProperties(laborSaveAsDefaultdtos)
      .then(async (result: any | null) => {
        if (result != null) {
          if (result.message === "Success") {
            toast.success(result?.result?.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        }
      })
      .catch((error) => {
        Utils.toastError(error.message, { position: toast.POSITION.BOTTOM_RIGHT });
        this.setState({ showLoader: false });
      });
  };

  hideBankSlideOut(mode) {
    if (mode === 1) {
      this.CleanSearch();
      this.setState({ isShowSlideout: false, showLoader: true }, () => {
        let arr: any = {};
        arr.id = this.state.CardUniqueNo;
        arr.status = "active";
        this.handleaccountNameSelectedItem(1, arr);
      });
    } else {
      this.setState({ isShowSlideout: false });
    }
  }

  hideCreditCardSlideOut(mode) {
    if (mode === 1) {
      let request = {} as any;
      request.hotelID = this.state.hidValue;
      request.lettercode = this.state.hotelName;
    } else if (mode === 3) {
      let arr: any = {};
      arr.id = this.state.CardUniqueNo;
      arr.status = "active";
      this.handleaccountNameSelectedItem(1, arr);
    }
    this.setState({ isCCShowSlideout: false });
  }

  handleaccountNameSelectedItem = (controlID: any, arr: any) => {
    const { defaultFilters } = this.state;
    this.setState({
      istableloader: true,
      isloader: true,
      accountStatus: arr.status,
      selectedAccount: arr,
      bulkSelectArrayList: []
    });
    this.setState({ customDate: false });
    let sharedHIDs: any[] = [];
    let totalsharedHIDCount = 0;
    let acctType = "";
    let sharedcode = "";
    let name = "";
    let isCCA = false;
    this.state.accountNamelist
      .filter((x) => x.oprID === arr.id)
      .forEach((element) => {
        let hidCode: any = [];
        hidCode = element.sharedHID.split("[");
        totalsharedHIDCount = element.hidCount;
        acctType = element.acctType;
        sharedcode = element.sharedcode;
        name = element.name;
        isCCA = element.acctType.toString().toLocaleLowerCase() === "c";
        hidCode.forEach((item) => {
          let sharedHID: any = {};
          let lettercodes: any = [];
          lettercodes = item.split("_");
          let splitHid = lettercodes[0];
          let splitcode = lettercodes[1];
          sharedHID.hid = splitHid;
          sharedHID.lettercode = splitcode;
          sharedHID.isChecked = false;
          sharedHIDs.push(sharedHID);
        });
      });

    let transferRequest = { ...this.state.transferRequest };
    transferRequest.acctType = acctType;
    let balanceRequest: any = {};
    balanceRequest.oprId = arr.id;

    this.setState(
      {
        defaultDensityView: defaultFilters[0],
        transactionFilterText: defaultFilters[1],
        dayText: defaultFilters[2],
        selectType: defaultFilters[3],
        transactionFilterValue: defaultFilters[4],
        CardUniqueNo: arr.id,
        accountName: name,
        transferRequest,
        loadingFromOther: false,
        isCCAcc: isCCA,
        accountNameValue: arr.id,
        totalsharedHIDCount: totalsharedHIDCount,
        totalsharedHID: sharedHIDs,
        totalsharedHIDTemp: sharedHIDs,
        sharedcode: sharedcode,
        registerList: [],
        dateRange: "",
        start: "",
        end: "",
        isSelect: true,
        ehidSected: 0,
        viewType: "regular",
        selectedAccountType: acctType,
        bulkSelectRow: false,
        bulkSelect: false,
        bulkSelectArray: {},
        selectedRows: [],
        expandedList: [],
        bankName: arr.bName,
        acctType: arr.acctType,
        sortingField: "date",
        sortingOrder: "asc",
        isSyncedAcc: (arr?.isSynced === 1),
        statusFilterSelected: "Flagged",
        statusFilter: [
          { id: 1, name: "Needs Review", class: "orange", isChecked: false },
          { id: 2, name: "Reviewed", class: "blue", isChecked: false },
          { id: 3, name: "Review Complete", class: "green", isChecked: false }
        ],
      },
      () => {
        this.CleanSearch();
        let balanceRequest: any = {};
        balanceRequest.oprId = arr.id;
        balanceRequest.oprType = acctType;
        if (acctType !== "" && acctType !== undefined) {
          setTimeout(() => {
            this.getBankBalance(balanceRequest);
          }, 100);
          this.getRegisterPermission();
        }
        else {
          this.setState({ istableloader: false, isloader: false, tableData: [] });
          return;
        }
      }
    );
    let { moreAction } = this.state;
    if (acctType === "C") {
      moreAction = this.state.moreAction.filter(
        (item) => item.name !== "Repeating"
      );
    } else {
      const ifExist = this.state.moreAction.find(
        (item) => item.name === "Repeating"
      );
      if (ifExist === undefined) {
        let MoreData: any = [];
        let more: any = {};
        more.name = "Repeating";
        more.eventKey = "repeating";
        MoreData.push(more);
        moreAction = this.state.moreAction.concat(MoreData);
      }
    }
    this.setState({ moreAction });
  };

  handleSelectedItem = (controlID: any, id: any) => {
    let selectType = "0";
    let dayText: any;
    let dayValue: any;
    let transactionFilterText: any;
    let updatedDayFilter = [...this.state.dayFilter];

    if (controlID === 1) {
      if (id === 1) {
        transactionFilterText = "For Review";
        updatedDayFilter = updatedDayFilter.filter(item => item.id !== 4);
      } else if (id === 2) {
        selectType = "30";
        dayText = "Last 30 Days";
        dayValue = 1;
        transactionFilterText = "Completed";
        if (!updatedDayFilter.find(item => item.id === 4)) {
          updatedDayFilter.push({ id: 4, name: "Custom" });
        }
      } else if (id === 3) {
        selectType = "30";
        dayText = "Last 30 Days";
        dayValue = 1;
        transactionFilterText = "Excluded";
        updatedDayFilter = updatedDayFilter.filter(item => item.id !== 4);
      }
      this.setState({
        transactionFilterText,
        transactionFilterValue: id,
        selectType,
        dayText,
        dayFilter: updatedDayFilter
      });
    } else {
      if (id === 1) {
        selectType = "30";
        dayText = "Last 30 Days";
        dayValue = id;
      } else if (id === 2) {
        selectType = "60";
        dayText = "Last 60 Days";
        dayValue = id;
      } else if (id === 3) {
        selectType = "90";
        dayText = "Last 90 Days";
        dayValue = id;
      } else if (id === 4) {
        let { dateRange } = this.state;
        selectType = "DateRange";
        dayText = "Custom";
        dayValue = id;
        this.setState({ customDate: true });

        const startDatee = new Date();
        const endDatee = new Date();
        let startDate;
        let endDate;
        let startDateFormatted: any;
        let endDateFormatted: any;

        endDatee.setFullYear(startDatee.getFullYear() - 1);
        startDateFormatted = this.formatDate(endDatee);
        endDateFormatted = this.formatDate(startDatee);

        if (dateRange === "") {
          let now = new Date();
          let pre30days;
          if (this.state.dayValue === 1) {
            pre30days = new Date(now.setDate(now.getDate() - 30))
          } else if (this.state.dayValue === 2) {
            pre30days = new Date(now.setDate(now.getDate() - 60))
          } else if (this.state.dayValue === 3) {
            pre30days = new Date(now.setDate(now.getDate() - 90))
          }

          let calenderYear = pre30days.getFullYear();
          let calenderDate = pre30days.getDate() > 9 ? pre30days.getDate() : 0 + "" + pre30days.getDate();
          let calenderMonth = pre30days.getMonth() + 1 > 9 ? pre30days.getMonth() + 1 : 0 + "" + (pre30days.getMonth() + 1);
          startDate = calenderMonth + "/" + calenderDate + "/" + calenderYear;

          let datee = new Date();
          let calenderYeare = datee.getFullYear();
          let calenderDatee = datee.getDate() > 9 ? datee.getDate() : 0 + "" + datee.getDate();
          let calenderMonthe = datee.getMonth() + 1 > 9 ? datee.getMonth() + 1 : 0 + "" + (datee.getMonth() + 1);
          endDate = calenderMonthe + "/" + calenderDatee + "/" + calenderYeare;
        } else {
          startDate = this.state.dateRange.split("-")[0].trim()
          endDate = this.state.dateRange.split("-")[1].trim()
        }
        this.setState({
          dateRange: startDate + " - " + endDate, start: startDate, end: endDate,
          minDate: startDateFormatted, maxDate: endDateFormatted, dayText, selectType
        })
        setTimeout(() => {
          $('#dtrange').trigger('click');
        }, 100);
      }
      this.setState({
        dayValue: dayValue,
        dayText,
        selectType,
        dayFilter: updatedDayFilter,
      });
    }

    if (id !== 4) {
      this.setState(
        {
          bulkSelectRow: false,
          bulkSelect: false,
          bulkSelectArray: {},
          selectedRows: [],
          expandedList: [],
          isloader: true,
          istableloader: true,
          sortingField: "date",
          sortingOrder: "asc",
          dateRange: "", customDate: false, dayValue: dayValue, dayText
        },
        () => {
          this.getTableData();
        }
      );
    }
  };

  formatDate = (date) => {
    const month = `${date.getMonth() + 1}`.padStart(2, '0');
    const day = `${date.getDate()}`.padStart(2, '0');
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };

  handleDateCallback(start, end, label) {
    let selectType = "0";
    let date = new Date(start._d);
    let calenderYear = date.getFullYear();
    let calenderDate = date.getDate() > 9 ? date.getDate() : 0 + "" + date.getDate();
    let calenderMonth = date.getMonth() + 1 > 9 ? date.getMonth() + 1 : 0 + "" + (date.getMonth() + 1);
    let tempDate = calenderMonth + "/" + calenderDate + "/" + calenderYear;

    let datee = new Date(end._d);
    let calenderYeare = datee.getFullYear();
    let calenderDatee = datee.getDate() > 9 ? datee.getDate() : 0 + "" + datee.getDate();
    let calenderMonthe = datee.getMonth() + 1 > 9 ? datee.getMonth() + 1 : 0 + "" + (datee.getMonth() + 1);
    let tempDatee = calenderMonthe + "/" + calenderDatee + "/" + calenderYeare;

    selectType = "DateRange";
    this.setState({ dateRange: tempDate + " - " + tempDatee, start: tempDate, end: tempDatee, selectType });
    this.getTableData();
  }

  handleDensityView = (viewName) => {
    this.setState({ defaultDensityView: viewName });
  };

  rowEvents = {
    onClick: (e: any, row: any, rowIndex: any) => { }
  };

  CleanSearch = () => {
    if (this.tableSearchBankSync.current !== null) {
      this.tableSearchBankSync.current.CleanSearch();
    }
  };

  searchedData = (newResult: any) => {
    this.footerBulkClose();
  };

  handleOnSelect = (row, isSelect) => {
    let {
      tableData,
      selectedRows,
      bulkSelectRow,
      bulkSelect,
      bulkSelectArray,
      hasMultipleTxn,
      hasMultipleAdjustments,
      batchTotal,
      bulkSelectArrayList
    } = this.state;
    if (isSelect) {
      if (!row.singleTx) {
        hasMultipleTxn.push(row.id);
      } else {
        if (!hasMultipleAdjustments) {
          hasMultipleAdjustments =
            Number(Math.abs(row.plaidAmount)) !==
            Number(Math.abs(row.transactions[0].data.amount));
        }
      }
      selectedRows = [...this.state.selectedRows, row.id];
      bulkSelectArray[row.id] = row;
      console.log("select", row);
      batchTotal += row.plaidAmount;
      if (row?.transactions[0]?.data)
        bulkSelectArrayList.push(row?.transactions[0]?.data);
    } else {
      if (!row.singleTx) {
        hasMultipleTxn = hasMultipleTxn.filter((x) => x !== row.id);
      } else {
        if (hasMultipleAdjustments) {
          hasMultipleAdjustments = !(
            Number(Math.abs(row.plaidAmount)) !==
            Number(Math.abs(row.transactions[0].data.amount))
          );
        }
      }
      selectedRows = this.state.selectedRows.filter((x) => x !== row.id);
      delete bulkSelectArray[row.id];
      batchTotal -= row.plaidAmount;
      bulkSelectArrayList = bulkSelectArrayList.filter((o) => o?.syncID != row?.syncID);
    }
    if (selectedRows.length === 0) {
      bulkSelectRow = false;
      bulkSelect = false;
      $("#footerBulkSelect")
        .prop("checked", false)
        .prop("indeterminate", false);
    } else if (
      selectedRows.length > 0 &&
      selectedRows.length < tableData.length
    ) {
      bulkSelectRow = false;
      bulkSelect = true;
      $("#footerBulkSelect").prop("checked", false).prop("indeterminate", true);
    } else if (selectedRows.length === tableData.length) {
      bulkSelectRow = true;
      bulkSelect = true;
      $("#footerBulkSelect").prop("checked", true).prop("indeterminate", false);
    }
    let IsPendingPay = false;
    IsPendingPay = bulkSelectArrayList.some((o) => (o?.badge === "Draft" || o?.badge === 'Awaiting Approval' || o?.badge === 'Awaiting Payment'));
    this.setState(() => ({
      batchTotal,
      selectedRows,
      bulkSelectRow,
      bulkSelect,
      bulkSelectArray,
      hasMultipleTxn,
      hasMultipleAdjustments,
      isPendingPayment: IsPendingPay,
      bulkSelectArrayList
    }));
  };

  handleOnSelectAll = (isSelect, rows) => {
    let {
      selectedRows,
      bulkSelectArray,
      hasMultipleTxn,
      hasMultipleAdjustments,
      batchTotal,
      bulkSelectArrayList
    } = this.state;
    batchTotal = 0;
    if (rows.length > 0) {
      if (isSelect) {
        rows.forEach((item) => {
          if (!item.singleTx) {
            hasMultipleTxn.push(item.id);
          } else {
            if (!hasMultipleAdjustments) {
              hasMultipleAdjustments =
                Number(Math.abs(item.plaidAmount)) !==
                Number(Math.abs(item.transactions[0]?.data.amount));
            }
          }
          selectedRows.push(item.id);
          bulkSelectArray[item.id] = item;
          batchTotal += item.plaidAmount;
          if (item?.transactions?.length > 0)
            bulkSelectArrayList.push(item?.transactions[0]?.data);
        });
        $("#footerBulkSelect")
          .prop("checked", true)
          .prop("indeterminate", false);
      } else {
        hasMultipleTxn = [];
        selectedRows = [];
        bulkSelectArray = {};
        hasMultipleAdjustments = false;
        this.footerBulkClose();
        bulkSelectArrayList = [];
      }
      let IsPendingPay = false;
      rows.forEach(element => {
        IsPendingPay = selectedRows.length > 0 ? (element?.badge === "Draft" || element?.badge === 'Awaiting Approval' || element?.badge === 'Awaiting Payment') : false
      });

      this.setState(() => ({
        selectedRows,
        bulkSelectArray,
        bulkSelectRow: isSelect,
        bulkSelect: isSelect,
        hasMultipleTxn,
        hasMultipleAdjustments,
        batchTotal,
        isPendingPayment: IsPendingPay,
        bulkSelectArrayList
      }));
    } else {
      this.setState(() => ({
        batchTotal
      }));
    }
  };
  footerBulkClose = () => {
    this.setState(
      {
        selectedRows: [],
        bulkSelect: false,
        bulkSelectRow: false,
        bulkSelectArray: {},
        hasMultipleTxn: [],
        hasMultipleAdjustments: false,
        batchTotal: 0,
        bulkSelectArrayList: []
      },
      () => {
        $("#footerBulkSelect")
          .prop("checked", false)
          .prop("indeterminate", false);
      }
    );
  };
  footerBulkSelectAll = (event) => {
    let {
      tableData,
      selectedRows,
      bulkSelectArray,
      hasMultipleTxn,
      hasMultipleAdjustments,
      bulkSelectArrayList
    } = this.state;
    selectedRows = [];
    let checked = event.target.checked;
    if (checked) {
      tableData.forEach((item) => {
        if (!item.singleTx) {
          hasMultipleTxn.push(item.id);
        } else {
          if (!hasMultipleAdjustments) {
            hasMultipleAdjustments =
              Number(Math.abs(item.plaidAmount)) !==
              Number(Math.abs(item.transactions[0]?.data.amount));
          }
        }
        selectedRows.push(item.id);
        bulkSelectArray[item.id] = item;
        if (item.transactions?.length > 0)
          bulkSelectArrayList.push(item?.transactions[0]?.data);
      });
    } else {
      bulkSelectArray = {};
      selectedRows = [];
      hasMultipleAdjustments = false;
      this.footerBulkClose();
      bulkSelectArrayList = [];
    }
    if (selectedRows.length > 0 && selectedRows.length < tableData.length) {
      $("#footerBulkSelect").prop("checked", false).prop("indeterminate", true);
    } else if (selectedRows.length === tableData.length) {
      $("#footerBulkSelect").prop("checked", true).prop("indeterminate", false);
    }

    let IsPendingPay = false;
    IsPendingPay = bulkSelectArrayList.some((o) => (o?.badge === "Draft" || o?.badge === 'Awaiting Approval' || o?.badge === 'Awaiting Payment'));

    this.setState({
      bulkSelectRow: checked,
      selectedRows,
      bulkSelectArray,
      bulkSelect: checked,
      hasMultipleTxn,
      hasMultipleAdjustments,
      isPendingPayment: IsPendingPay,
      bulkSelectArrayList
    });
  };
  handleExpandCol = (row, rowIndex) => {
    let tableData = _.cloneDeep(this.state.tableData);
    tableData.map((item) => {
      if (item.id === row.id) {
        item.isExpand = !item.isExpand;
      }
    });
    this.setState(() => ({
      expandedList: [],
      tableData: tableData,
    }));
  };

  transationSlideoutSave = (saveReq, saveRes) => {
    const { entryType } = this.state;
    if (entryType === "edit") {
      this.setState({ entryType: "" });
      return;
    }
    let requestArray: any = [];
    let request: any = {};
    const { hidValue, accountNameValue, tenantID, AddTransationSyncID } = this.state;
    let isPageType = (saveReq.pageType === "BankSyncIncomplete") ? "IncompleteInvoice" : saveReq.pageType === "BankSyncInvoice" ? "Invoice" : saveReq.pageType;
    request.SyncId = AddTransationSyncID;
    request.HotelId = hidValue;
    request.Tenantid = tenantID;
    request.Uniqueno = saveRes.uniqueno;
    request.Oprid = accountNameValue;
    request.PageType = isPageType;
    request.UpdateTabale = "";
    console.log(request, "transationSlideoutSave");
    requestArray.push(request);
    this.setState({ addTranReq: requestArray });
    bankSyncServices
      .SavePlaidTranSyncMatchingDetailsStatus(requestArray)
      .then((response: any) => {
        if (response.success) {
          if (saveReq.pageType?.includes("BankSync")) {
            toast.success("Transaction has been saved successfully.", { position: toast.POSITION.BOTTOM_RIGHT });
            this.setState({ invoiceSlideout: false }, () => {
              this.getTableData();
            })
          } else {
            this.setState({ reconcileAfterSave: true }, () => { });
          }
        } else {
          Utils.toastError("Something went wrong.", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch((error) => {
        Utils.toastError(error, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };

  getTransactionSlideoutData = async (row) => {
    if (
      row &&
      (row.updateTable?.toLowerCase().includes("invoice") ||
        row.updateTable?.toLowerCase().includes("creditcard"))
    ) {
      const { hidValue, accountNameValue, slideoutData } = this.state;
      let requestObj: any = {
        Hotelid: hidValue,
        oprid: accountNameValue,
        TransUniqueno: row.trUniqueNo,
        TableType: row.updateTable,
        PageType: "BankSync",
      };
      bankSyncServices
        .OpenSlideoutParameter(requestObj)
        .then((response) => {
          if (response !== null) {
            let SlideoutData = { ...slideoutData };
            let data = response.result[0];
            if (row.updateTable?.toLowerCase().includes("invoice")) {
              SlideoutData.selectedRow = {
                hid: data.backhid,
                groupID: data.uniqueno,
                oprId: data.oprid,
                checkno: data.ckNo,
                invPostFix: data.invPostfix,
                series: data.series,
              };
            } else {
              SlideoutData.oprId = data.oprid;
              SlideoutData.vid = data.vid;
            }

            this.setState(
              {
                slideoutData: SlideoutData,
                trslideoutData: SlideoutData
              },
              () => {
                this.setState((state) => {
                  if (row.updateTable?.toLowerCase().includes("invoice")) {
                    return { ...state, isSlideOut: true };
                  } else {
                    return { ...state, isCCSlideout: true };
                  }
                });
              }
            );
          }
        })
        .catch((error) => {
          Utils.toastError(error, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        });
    } else {
      if (row === undefined) {
        this.setState((state) => {
          return { ...state, isSlideOut: false, isCCSlideout: false };
        });
      } else {
        if (row.transCode.toLowerCase().includes("credit")) {
          this.setState((state) => {
            return { ...state, isCCSlideout: true };
          });
        } else if (row.transCode.toLowerCase() === "transfer") {
          let { trslideoutData, transferRequest } = this.state;
          trslideoutData.hotelID = this.state.hidValue;
          trslideoutData.lettercode = this.state.hotelName;
          trslideoutData.oprID = this.state.accountNameValue;
          trslideoutData.acctType = this.state.acctType;
          trslideoutData.amount = row?.amount;
          trslideoutData.date = row?.transDate;
          trslideoutData.slideType = row?.slideType;
          let hasGLPermission: any = [];
          hasGLPermission = this.state.permissionlist.filter((x) => x.eType.toLocaleLowerCase().includes("View Bank and GL Balances".toLocaleLowerCase()));
          if (hasGLPermission.length > 0) {
            hasGLPermission.forEach((items) => {
              transferRequest.glArray.push(items.eType.charAt(0));
            });
          }
          this.setState((state) => {
            return { ...state, trslideoutData, transferRequest, isTransferSlideOut: true };
          });
        } else if (row.transCode.toLowerCase() === "invoice") {
          let rowIndex = 0;

          row.callFrom = "BankSyncInvoice";
          this.OpenInvoiceSlideOut("", row, rowIndex, "invoice", "draft");
        } else {
          this.setState((state) => {
            return { ...state, isSlideOut: !state.isSlideOut };
          });
        }
      }
    }
  };

  hideSlideOut = (isSave, row, rowIndex) => {
    let slideoutData: any = {};
    let pageType = "";
    let AddTransationSyncID;
    let entryType;
    if (row) {
      if (row.transType?.toLowerCase() === "epay") {
        Utils.toastError("Grouped entries cannot be opened from here.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "banksyncpage"
        });
        return;
      } else if (row.transType?.toLowerCase() === "directdeposit") {
        Utils.toastError("Payroll entries cannot be opened from here.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "banksyncpage"
        });
        return;
      } else if (row.updateTable?.toLowerCase() === "depositentry") {
        Utils.toastError("Daily entries cannot be opened from here.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "banksyncpage"
        });
        return;
      }
      if (row.transCode.search(/CreditCardDep/i) !== -1) {
        pageType = "CCCREDIT";
      } else if (row.transCode.search(/CreditCardWith/i) !== -1) {
        pageType = "CCDEBIT";
      } else if (row.transCode.search(/deposit/i) !== -1) {
        pageType = "Deposit";
      } else if (row.transCode.search(/withdrawal/i) !== -1) {
        pageType = "Withdrawal";
      } else {
        pageType = this.getEtype(row.transCode, "main");
      }
      slideoutData.pageType = pageType;
      slideoutData.accDetails = {
        acctType: this.state.selectedAccountType,
        oprID: this.state.accountNameValue,
        accountName: this.state.accountName,
        description: row.description,
        transDate: row.transDate,
        totalAmount: row.amount,
        isNewTransBankSync: row.isNewTransBankSync
      };
      slideoutData.hidValue = this.state.hidValue;
      slideoutData.hotelName = this.state.hotelName;
      slideoutData.uniqueNo = row.trUniqueNo;
      slideoutData.selectedRow = {
        hid: row.backHid,
        groupID: row.trUniqueNo,
        oprId: this.state.accountNameValue,
        checkno: isNaN(parseInt(row.checkNo)) ? row.checkNo : row.checkNo,
        invPostFix: "",
        series: row.series ? row.series : "",
      };
      entryType = "edit";
      if (row.trUniqueNo === 0) {
        if (pageType === "CCCREDIT" || pageType === "CCDEBIT") {
          slideoutData.oprId = -1;
          slideoutData.vid = 0;
          slideoutData.selectedRow = {};
          slideoutData.isBankSyncPage = true;
        } else {
          slideoutData.selectedRow = {};
          slideoutData.isBankSyncPage = true;
          slideoutData.accper = this.state.accountPeriod;
        }
        entryType = "add";
      }
      AddTransationSyncID = row.syncID;
    }
    if (isSave) {
      this.setState(
        (state) => {
          return {
            ...state,
            slideoutData,
            isSlideOut: false,
            isCCSlideout: false,
            expandedList: [],
            isloader: true,
            istableloader: true,
            AddTransationSyncID: 0,
            entryType,
            invoiceSlideout: false
          };
        },
        () => {
          this.getTableData();
        }
      );
    } else {
      this.setState(
        (state) => {
          return {
            ...state,
            slideoutData,
            AddTransationSyncID,
            entryType,
            invoiceSlideout: false
          };
        },
        () => {
          this.getTransactionSlideoutData(row);
        }
      );
    }
  };

  getRegisterPermission = () => {
    let permissionRequest: any = {};
    permissionRequest.hotelID = this.state.hidValue;
    permissionRequest.oprID = this.state.accountNameValue;
    permissionRequest.pageName = "Register";
    registersevice
      .getRegisterPermission(permissionRequest)
      .then(async (result: any[] | null) => {
        if (result !== null) {
          let glBalanceType = this.state.transferRequest.acctType === "M" ? "Misc View Bank and GL Balances"
            : this.state.transferRequest.acctType === "O" ? "Op View Bank and GL Balances"
              : this.state.transferRequest.acctType === "P" ? "Py View Bank and GL Balances" : "";

          let gLBalancePerm = result.filter((x) => x.eType === glBalanceType).length;
          this.setState({
            permissionlist: result,
            isGLBalncePerm: this.state.transferRequest.acctType === "C" ? true : gLBalancePerm === 1 ? true : false,
          });
        }
        resolve();
      })
      .catch((error) => {
        reject();
      });
  };

  hideTrSlideOut(hasEvent, row, rowIndex) {
    //debugger;
    let trslideoutData: any = {};
    let AddTransationSyncID: any = 0;
    let { transferRequest } = this.state;
    if (row) {
      let updateTable = "Transfer";
      if (row.updateTable) {
        updateTable = row.updateTable;
      } else if (row.transactions[0].data?.updateTable) {
        updateTable = row.transactions[0].data?.updateTable;
      }
      let acctType = "O";
      if (updateTable?.search(/misc/i) !== -1) {
        acctType = "M";
      } else if (updateTable?.search(/payroll/i) !== -1) {
        acctType = "P";
      }
      trslideoutData.pageType = "Transfer";
      trslideoutData.accDetails = {
        acctType: acctType,//this.state.selectedAccountType,
        oprID: this.state.accountNameValue,
        accountName: this.state.accountName,
      };
      trslideoutData.hidValue = row.backHid;
      trslideoutData.hotelName = this.state.hotelName;
      trslideoutData.uniqueNo = row.trUniqueNo;
      transferRequest.acctType = acctType;
    }
    AddTransationSyncID = row?.syncID;

    this.setState({ trslideoutData: trslideoutData, transferRequest }, () => {
      this.setState((state) => {
        return {
          ...state,
          isTransferSlideOut: !state.isTransferSlideOut,
          AddTransationSyncID
        };
      }, () => {
        hasEvent && this.getTableData();
      });
    });
  }

  OpenInvoiceSlideOut = (e, newrow, rowIndex, rowType, type) => {
    let invoiceslideoutData: any = {};
    if (newrow) {
      let row: any = {};
      let pageType = "";
      let etype = newrow.badge === "Draft" ? "INCOMPLETEINVOICE" : "Invoice";
      row.hid = (rowType === "invoice" ? newrow.backHid ? newrow.backHid : this.state.hidValue : newrow.hotelid);
      row.etype = "Invoice";
      row.vendor = (rowType === "invoice" ? newrow.venderName : newrow.vendor);
      row.backHIDCode = (rowType === "invoice" ? newrow.backHIDLetterCode ? newrow.backHIDLetterCode : this.state.hotelName : newrow.lettercode);
      row.vid = (rowType === "invoice" ? 0 : newrow.vid);
      row.oprId = (rowType === "invoice" ? this.state.accountNameValue : newrow.oprID);
      row.uniqueno = (rowType === "invoice" ? newrow.trUniqueNo : newrow.uniqueno);
      row.ckno = (rowType === "invoice" ? newrow.checkNo : newrow.ckno);
      row.series = newrow.series ? newrow.series : "";
      row.invPostFix = newrow.postFix ? newrow.postFix : "";
      row.groupID = (rowType === "invoice" ? newrow.trUniqueNo : newrow.groupID);
      pageType = (type === "draft") ? "IncompleteInvoice" : (rowType === "invoice" ? etype : this.getEtype(row.etype, rowType));
      invoiceslideoutData.pageType = pageType;
      invoiceslideoutData.oprID = row.oprId;
      invoiceslideoutData.oprID = row.vid;
      invoiceslideoutData.hidValue = this.state.hidValue;
      invoiceslideoutData.hotelName = this.state.hotelName;
      invoiceslideoutData.uniqueNo = row.uniqueno;
      invoiceslideoutData.isFromRepeating = false;
      invoiceslideoutData.selectedRow = { ...row, ...newrow };
    }
    this.setState({ invoiceslideoutData }, () => {
      this.setState((state) => {
        return {
          ...state,
          invoiceSlideout: !state.invoiceSlideout,
          isSlideOut: !state,
          isTransferSlideOut: false,
        };
      });
    });
    if (e === true) {
      this.setState(
        (state) => {
          return {
            ...state,
            invoiceslideoutData,
            isSlideOut: false,
            isCCSlideout: false,
            expandedList: [],
            isloader: true,
            istableloader: true,
          };
        },
        () => {
          this.getTableData();
        }
      );
    }
  };

  sortImage = (order) => {
    if (!order || order === "asc") {
      return (
        <span className="ascending">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.4712 8.47133L11.5286 7.52867L8.66656 10.3907V4H7.33323V10.3907L4.47123 7.52867L3.52856 8.47133L7.9999 12.9427L12.4712 8.47133Z" fill="#1D1E20" />
          </svg>
        </span>
      );
    } else if (order === "desc") {
      return (
        <span className="descending">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.4712 8.47133L11.5286 7.52867L8.66656 10.3907V4H7.33323V10.3907L4.47123 7.52867L3.52856 8.47133L7.9999 12.9427L12.4712 8.47133Z" fill="#1D1E20" />
          </svg>
        </span>
      )
    }
    return null;
  };


  handleSortByColumnOnLoad = (field) => {
    if (field === "MatchedEntry") {
      let sortedData = this.state.tableData.sort((aData, bData) => {
        let a = aData.MatchedEntry;
        let b = bData.MatchedEntry;
        if (a?.toString()?.trim()?.toLowerCase() === b?.toString()?.trim()?.toLowerCase()) {
          return 0;
        }
        if (this.state.sortingOrder === "desc") {
          if (!a) return 1;
          if (!b) return -1;

          let AmatchCount = a.split(",");
          let BmatchCount = b.split(",");
          if (AmatchCount.length > 1 && BmatchCount.length > 1) {
            let aDate = a.split(",")[0];
            let bDate = b.split(",")[0];
            return new Date(aDate).valueOf() - new Date(bDate).valueOf();
          } else if (AmatchCount.length > 1 || BmatchCount.length > 1) {
            if (AmatchCount.length > 1) {
              return -1;
            }
            if (BmatchCount.length > 1) {
              return 1;
            }
          } else {
            let aDate = a.split(" ")[0];
            let bDate = b.split(" ")[0];
            return aDate - bDate;
          }
        } else {
          if (!a) return -1;
          if (!b) return 1;

          let AmatchCount = a.split(",");
          let BmatchCount = b.split(",");
          if (AmatchCount.length > 1 && BmatchCount.length > 1) {
            let aDate = a.split(",")[0];
            let bDate = b.split(",")[0];
            return new Date(aDate).valueOf() - new Date(bDate).valueOf();
          } else if (AmatchCount.length > 1 || BmatchCount.length > 1) {
            if (AmatchCount.length > 1) {
              return 1;
            }
            if (BmatchCount.length > 1) {
              return -1;
            }
          } else {
            let aDate = a.split(" ")[0];
            let bDate = b.split(" ")[0];
            return bDate - aDate;
          }
        }
      });
      this.setState({ tableData: [] }, () => {
        this.setState({ tableData: sortedData, istableloader: false, });
      });
    }

    else {
      this.sortTableData(this.state.tableData, this.state.sortingField);
    }
  };

  handleSortByColumn = (field) => {
    this.setState(
      {
        sortingField: field,
        sortingOrder: this.state.sortingOrder === "asc" ? "desc" : "asc",
      },
      () => {
        if (field === "MatchedEntry") {
          let sortedData = this.state.tableData.sort((aData, bData) => {
            let a = aData.MatchedEntry;
            let b = bData.MatchedEntry;
            if (a?.toString()?.trim()?.toLowerCase() === b?.toString()?.trim()?.toLowerCase()) {
              return 0;
            }
            if (this.state.sortingOrder === "desc") {
              if (!a) return 1;
              if (!b) return -1;

              let AmatchCount = a.split(",");
              let BmatchCount = b.split(",");
              if (AmatchCount.length > 1 && BmatchCount.length > 1) {
                let aDate = a.split(",")[0];
                let bDate = b.split(",")[0];
                return new Date(aDate).valueOf() - new Date(bDate).valueOf();
              } else if (AmatchCount.length > 1 || BmatchCount.length > 1) {
                if (AmatchCount.length > 1) {
                  return -1;
                }
                if (BmatchCount.length > 1) {
                  return 1;
                }
              } else {
                let aDate = a.split(" ")[0];
                let bDate = b.split(" ")[0];
                return aDate - bDate;
              }
            } else {
              if (!a) return -1;
              if (!b) return 1;

              let AmatchCount = a.split(",");
              let BmatchCount = b.split(",");
              if (AmatchCount.length > 1 && BmatchCount.length > 1) {
                let aDate = a.split(",")[0];
                let bDate = b.split(",")[0];
                return new Date(aDate).valueOf() - new Date(bDate).valueOf();
              } else if (AmatchCount.length > 1 || BmatchCount.length > 1) {
                if (AmatchCount.length > 1) {
                  return 1;
                }
                if (BmatchCount.length > 1) {
                  return -1;
                }
              } else {
                let aDate = a.split(" ")[0];
                let bDate = b.split(" ")[0];
                return bDate - aDate;
              }
            }
          });
          this.setState({ tableData: [] }, () => {
            this.setState({ tableData: sortedData });
          });
        }
        else {
          this.sortTableData(this.state.tableData, this.state.sortingField);
        }
      }
    );
  };

  sortTableData = (data: any, fieldName) => {
    if (this.state.sortingOrder === "asc") {
      data = _.orderBy(data, [fieldName === "date" ? "date1" : fieldName, "date1"], ["asc", "asc"]);
    } else if (this.state.sortingOrder === "desc") {
      data = _.orderBy(data, [fieldName === "date" ? "date1" : fieldName, "date1"], ["desc", "asc"]);
    }
    this.setState({ tableData: data });
  };

  handleNotesChange(e) {
    this.setState({ notes: e.target.value.trim() });
  }

  getExport = () => {
    exportDataSet = []
    let tableData = Object.assign([], JSON.parse(JSON.stringify(this.state.tableData)));
    let dateObj = new Date();
    let objmonth = (dateObj.getMonth() + 1) < 10 ? "0" + (dateObj.getMonth() + 1).toString() : (dateObj.getMonth() + 1).toString();
    let objdate = (dateObj.getUTCDate()) < 10 ? "0" + (dateObj.getUTCDate()).toString() : (dateObj.getUTCDate()).toString();
    let objHr = (dateObj.getHours()) < 10 ? "0" + (dateObj.getHours()).toString() : (dateObj.getHours()).toString();
    let objMM = (dateObj.getMinutes()) < 10 ? "0" + (dateObj.getMinutes()).toString() : (dateObj.getMinutes()).toString();
    let objSS = (dateObj.getSeconds()) < 10 ? "0" + (dateObj.getSeconds()).toString() : (dateObj.getSeconds()).toString();

    let excelfileName = "BankSync-" + (dateObj.getUTCFullYear().toString().substring(2, 4))
      + "-" + objmonth + "" + objdate
      + " " + objHr.toString() + "." + objMM.toString() + "." + objSS.toString();
    let lastUpdatedStatus = this.state.lastUpdatedStatus?.toLocaleLowerCase()
    let isCustomField = this.state.manualUploadColumns.CustomField && (lastUpdatedStatus.includes("last manual upload") || lastUpdatedStatus.includes("not uploaded yet")) ? true : false;

    let dateFormat = "MM/DD/YY";

    tableData.forEach(item => {
      let row: any = {};
      const isCheckEntry = +(item.checkType) > 0;
      row["Date"] = moment(item.date).format(dateFormat);
      this.state.manualUploadColumns.Description && (row["Description"] = item.description);
      row["Check / Type"] = item.checkType;
      row["Amount"] = item.plaidAmount;
      isCustomField && (row["Custom Field"] = item.customField);
      row["Matched Entry"] = item.MatchedEntry + ((isCheckEntry && item.MatchedEntry !== "" && item.checkNo !== null) ? ", Check Number: " + (this.state.transactionFilterText !== "Completed" ? item.checkNo : item.checkNumbers) : "");
      this.state.transactionFilterText === "For Review" && this.state.showFlaggedCol && (row["Flagged"] = item.reviewStatus);
      this.state.transactionFilterText === "Completed" && (row["Adj."] = item.adjustment);
      exportDataSet.push(row);
    })
    this.setState({ exportData: exportDataSet, excelfileName: excelfileName }, () => {
      let tempLink = document.getElementById("btnexport1") as any;
      tempLink.click();
      toast.success("File has been downloaded successfully.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "banksyncpage",
      })
    })
  }

  toggleManualStatUploadModal = (updated: any, isReload: any = false) => {
    if (updated) {
      this.setState({ showManualUploadModal: true });
    } else {
      this.setState({ showManualUploadModal: false });
    }
    if (isReload) {
      let req: any = {};
      let balReq: any = {};
      req.hotelID = this.state.hidValue;
      req.lettercode = this.state.hotelName;
      req.logInUserID = this.state.logInUserID;
      req.PageType = "BankSync";
      balReq.oprId = this.state.accountNameValue;
      balReq.oprType = this.state.acctType;
      this.setState({ selectedRows: [], bulkSelect: false, batchTotal: 0 });
      this.getRegisterNames(req);
      this.getTableData();
      this.getBankBalance(balReq);
    }
  };

  itemCheckChange = (event: any, item: any, type: any) => {
    // debugger;
    if (type === "status") {
      let { statusFilter, statusFilterSelected } = this.state;
      statusFilter.forEach((o: any) => (o?.name?.toString()?.toLocaleLowerCase() === item.name?.toString()?.toLocaleLowerCase()) && (o.isChecked = event.target.checked))
      statusFilterSelected = this.selectedStatus();
      this.setState({ statusFilter, statusFilterSelected }, () => {
        this.filterByFlaggedStatus();
      });
    }
  }

  selectedStatus = () => {
    let { statusFilter } = this.state;
    let checkedLen = statusFilter.filter((o: any) => o.isChecked);
    let isStatusSelected = (checkedLen.length > 1 ? `${checkedLen.length} Statuses Selected` : (checkedLen.length === 1 ? checkedLen[0].name : "Flagged"));
    return isStatusSelected;
  }

  handleItemSelectAll(event: any, type: any) {
    // debugger;
    if (type === "status") {
      let { statusFilter, statusFilterSelected } = this.state;
      statusFilter.forEach((o: any) => o.isChecked = event.target.checked)
      statusFilterSelected = this.selectedStatus();
      this.setState({ statusFilter, statusFilterSelected }, () => {
        this.filterByFlaggedStatus();
      })
    }
  }

  filterByFlaggedStatus = () => {
    // debugger;
    let tableData: any = [];
    if (this.state.statusFilter.filter((e) => e.isChecked).length === 0) {
      tableData = Object.assign([], JSON.parse(JSON.stringify(this.state.tableDataCopy)));
    } else {
      this.state.tableDataCopy.forEach((data) => {
        this.state.statusFilter.forEach((status) => {
          if (status.isChecked && data.reviewStatus === status.name) {
            tableData.push(data);
          }
        })
      })
    }
    this.setState({ tableData, }, () => {
      this.handleSortByColumnOnLoad(this.state.sortingField);
    })
  }

  render() {
    const isStatusClass = (status: any) => {
      let statusClass = "";
      if (status.toString().toLowerCase() === "needs review") {
        statusClass = "orange";
      } else if (status.toString().toLowerCase() === "reviewed") {
        statusClass = "blue";
      } else if (status.toString().toLowerCase() === "review complete") {
        statusClass = "green";
      } else if (status.toString().toLowerCase() === "flag for review") {
        statusClass = "light";
      } else if (status.toString().toLowerCase() === "complete review") {
        statusClass = "light";
      }
      return statusClass;
    }

    const statusCheckLabel = (item: any, statusClass: any) => (<strong className={`${statusClass} tag`}>{item.name}</strong>);

    const headerSortingClasses = (column, sortOrder, isLastSorting, colIndex) => sortOrder === "asc" ? "active" : "active";
    const renderDateheaderFormatter = (column: any) => (
      <div className={`d-inline-block ${this.state.sortingField === "date" ? "active" : ""}`} onClick={() => this.handleSortByColumn("date")}>{column.text}
        {this.state.tableData.length > 0 && this.sortImage(this.state.sortingOrder)}
      </div>
    )
    const renderColumnsheaderFormatter = (column: any, type: any) => (
      <div className={`d-inline-block ${this.state.sortingField === type ? "active" : ""}`} onClick={() => this.handleSortByColumn(type)}>
        {column.text}
        {this.state.tableData.length > 0 && this.sortImage(this.state.sortingOrder)}
      </div>
    );

    const renderEllipsisWithTooltip = (value: any) => (<EllipsisWithTooltip placement="top">{value}</EllipsisWithTooltip>);
    const renderPlaidAmount = (value: any) => {
      let amt = String(value);
      let dollerSign = "$";
      if (amt.toString().includes("-")) {
        amt = amt.slice(1);
        dollerSign = "-$";
      }
      return (
        <div className="amount-value">
          {dollerSign}
          {Utils.amountFormatted(Number(amt).toFixed(2))}
        </div>
      );
    }

    const renderMatchedEntry = (row: any, rowIndex: any) => {
      let isExpand = row.isExpand;
      let allowSlideout = false;
      if (row.singleTx) {
        allowSlideout = true;
      }
      const isCheckEntry = +(row.checkType) > 0;
      const isCheckNo = +(row.checkNo) > 0
      return (
        <div className="matched-entry-value" onClick={() => {
          if (!row.singleTx) {
            let tableData = _.cloneDeep(this.state.tableData);
            let expandedList: any = [];
            tableData.map((item) => {
              if (item.id === row.id) {
                item.isExpand = !isExpand;
              } else {
                item.isExpand = false;
              }
            });
            isExpand = !isExpand;
            if (isExpand) {
              expandedList.push(row.id);
            }
            this.setState({ expandedList, tableData });
            return;
          }
          if (!allowSlideout) {
            return;
          }
          if (row.flagTrType === "DraftTransaction") {
            this.openDraftTrxSlideout(row, rowIndex);
          } else {
            if (row.badge.search(/Register/i) !== 0 && row.transCode.search(/invoice/i) !== -1) {
              this.OpenInvoiceSlideOut("", row, rowIndex, "invoice", "invoice");
            } else if (row.transCode.search(/transfer/i) !== -1) {
              this.hideTrSlideOut(false, row, rowIndex);
            } else if (row.transCode.search(/invoice/i) !== -1) {
              this.hideSlideOut(false, row, rowIndex);
            } else {
              this.hideSlideOut(false, row, rowIndex);
            }
          }
        }}
        >
          {this.state.transactionFilterText !== "Completed" ?
            <EllipsisWithTooltip placement="bottom">
              {row.MatchedEntry}
              {isCheckNo && isCheckEntry && row.MatchedEntry != "" && row.checkNo !== null ? ", Check Number: " + row.checkNo : ""}
            </EllipsisWithTooltip>
            :
            <EllipsisWithTooltip placement="bottom">
              {row.MatchedEntry}
              {isCheckNo && isCheckEntry && row.MatchedEntry != "" && row.checkNo !== null ? ", Check Number: " + row.checkNumbers : ""}
            </EllipsisWithTooltip>
          }
        </div>
      );
    }

    const renderColAdjustment = (value: any) => {
      let amt = String(value);
      return (
        <div className="amount-value">
          {amt === "0" ? "-" : Utils.currencyFormat(Number(amt).toFixed(2))}
        </div>
      );
    }

    const renderRightColComponent = (row: any, rowIndex: any) => {
      let isExpand = row.isExpand;
      let shouldDisable = false;
      shouldDisable = row.badge === 'Draft' ||
        row.badge === 'Awaiting Approval' ||
        row.badge === 'Awaiting Payment' ? true : false;
      return (
        <div className="d-flex text-right action-col">
          <div className="">
            {row.tranType === "Pending" && row.singleTx ? (
              <>
                {shouldDisable ?
                  <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip id="custom-Tooltip">This transaction has not yet been posted.</Tooltip>
                    }>
                    <Button
                      className="confirm-btn"
                      disabled={row.confirmSpinner || shouldDisable}
                      onClick={() => {
                        this.confirmTransation("single", "Completed", [
                          { ...row },
                        ]);
                      }}
                    >
                      {row.confirmSpinner && (
                        <Spinner
                          className="mr-2"
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                      Confirm
                    </Button>
                  </OverlayTrigger> :
                  <Button
                    className="confirm-btn"
                    disabled={row.confirmSpinner || shouldDisable}
                    onClick={() => {
                      this.confirmTransation("single", "Completed", [
                        { ...row },
                      ]);
                    }}
                  >
                    {row.confirmSpinner && (
                      <Spinner
                        className="mr-2"
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                    Confirm
                  </Button>}
              </>
            ) : (
              <>
                {row.tranType === "Pending" && (
                  <Button
                    className="btn-outline-primary review-btn"
                    onClick={() => {
                      let tableData = _.cloneDeep(this.state.tableData);
                      let expandedList: any = [];
                      tableData.map((item) => {
                        if (item.id === row.id) {
                          item.isExpand = !isExpand;
                        } else {
                          item.isExpand = false;
                        }
                      });
                      isExpand = !isExpand;
                      if (isExpand) {
                        expandedList.push(row.id);
                      }
                      this.setState({ expandedList, tableData, selectedPlaidAmount: row?.plaidAmount });
                    }}
                  >
                    {isExpand ? "Close" : "Review"}
                  </Button>
                )}
              </>
            )}
          </div>
          {row.tranType === "Pending" && (
            <div className="">
              <Dropdown
                id="more-action"
                className="more-action"
                alignRight
              >
                <Dropdown.Toggle
                  className="btn-outline-primary btn btn-primary btn-ghost more"
                  id="dropdown-more"
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                    <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                    <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                  </svg>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {row.isFlag !== "Yes" && row.showDraft === 1 &&
                    <Dropdown.Item
                      eventKey={"flagTransaction"} onClick={(e) => {
                        e.stopPropagation();
                        this.openCommentModal(row);
                      }}
                      key={`flagTransaction${rowIndex}`}
                    >
                      Flag for Review
                    </Dropdown.Item>
                  }
                  <Dropdown.Item
                    eventKey={"Delete"}
                    onClick={(e) => {
                      e.stopPropagation();
                      this.saveTransactionStatus("single", "Excluded", [
                        { ...row },
                      ]);
                    }}
                    key={rowIndex}
                  >
                    Exclude Transaction
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}
        </div>
      );
    }

    const hiddenCustomField = () => {
      let isShow: boolean = false;
      isShow = (this.state.manualUploadColumns.CustomField && (this.state.lastUpdatedStatus?.toLocaleLowerCase().includes("last manual upload") || this.state.lastUpdatedStatus?.toLocaleLowerCase().includes("not uploaded yet")))
      return isShow;
    }

    const hiddenDescription = () => {
      return true
    }

    const columns = [
      {
        dataField: "date",
        text: "Date",
        headerSortingClasses,
        headerClasses: "bsyncColDate",
        searchable: true,
        headerFormatter: (column, colIndex) => renderDateheaderFormatter(column),
        formatter: (cell: any, row: any, rowIndex: any) => renderEllipsisWithTooltip(row.date),
      },
      {
        dataField: "description",
        text: "Description",
        headerClasses: "bsyncColDes",
        classes: "bsyncColDes",
        searchable: this.state.manualUploadColumns.Description ? true : false,
        hidden: this.state.manualUploadColumns.Description ? false : true,
        headerSortingClasses,
        headerFormatter: (column, colIndex) => renderColumnsheaderFormatter(column, "description"),
        formatter: (cell: any, row: any, rowIndex: any) => renderEllipsisWithTooltip(row.description),
      },
      {
        dataField: "checkType",
        text: "Check / Type",
        headerClasses: "bsyncColCheckType",
        headerSortingClasses,
        headerFormatter: (column, colIndex) => renderColumnsheaderFormatter(column, "checkType"),
        formatter: (cell: any, row: any, rowIndex: any) => renderEllipsisWithTooltip(row.checkType),
      },
      {
        dataField: "plaidAmount",
        text: "Amount",
        headerClasses: "bsyncColAmt right-align",
        classes: "right-align pad-right-16",
        searchable: true,
        headerSortingClasses,
        headerFormatter: (column, colIndex) => renderColumnsheaderFormatter(column, "plaidAmount"),
        formatter: (cell: any, row: any, rowIndex: any) => renderPlaidAmount(row.plaidAmount),
      },
      {
        dataField: "customField",
        text: "Custom Field",
        headerClasses: "bsyncColDes",
        classes: "bsyncColDes",
        searchable: hiddenCustomField() ? true : false,
        hidden: hiddenCustomField() ? false : true,
        headerSortingClasses,
        headerFormatter: (column, colIndex) => renderColumnsheaderFormatter(column, "customField"),
        formatter: (cell: any, row: any, rowIndex: any) => renderEllipsisWithTooltip(row.customField),
      }, {
        dataField: "MatchedEntry",
        text: "Matched Entry",
        headerClasses: "bsyncColMatchedEntry right-align",
        classes: "right-align pad-right-16 bsyncColMatchedEntry",
        searchable: false,
        headerSortingClasses,
        headerFormatter: (column, colIndex) => renderColumnsheaderFormatter(column, "MatchedEntry"),
        formatter: (cell: any, row: any, rowIndex: any) => renderMatchedEntry(row, rowIndex),
      }, {
        dataField: "reviewStatus",
        text: "Flagged",
        headerClasses: "flag-col",
        classes: "flag-col",
        searchable: true,
        hidden: this.state.transactionFilterText === "For Review" && this.state.showFlaggedCol ? false : true,
        formatter: (cell: any, row: any, rowIndex: any) => {
          if (row.isFlag === "Yes" && row.showFlag === 1) {
            return (
              <div className="" onClick={() => this.openDraftTrxSlideout(row, rowIndex)}>
                <div className={`tag ${row.reviewStatus === "Needs Review" ? "orange" : row.reviewStatus === "Reviewed" ? "blue" : "green"}`}><EllipsisWithTooltip placement="top">{row.reviewStatus}</EllipsisWithTooltip></div>
                {/* <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.9 2.5L9.66 1.3C9.57 0.84 9.16 0.5 8.68 0.5H1.5C0.95 0.5 0.5 0.95 0.5 1.5V16.5C0.5 17.05 0.95 17.5 1.5 17.5C2.05 17.5 2.5 17.05 2.5 16.5V10.5H8.1L8.34 11.7C8.43 12.17 8.84 12.5 9.32 12.5H14.5C15.05 12.5 15.5 12.05 15.5 11.5V3.5C15.5 2.95 15.05 2.5 14.5 2.5H9.9Z" />
                </svg> */}
              </div>
            );
          } else {
            return <></>
          }
        }
      }, {
        dataField: "adjustment",
        text: "Adj.",
        headerClasses: "right-align",
        classes: "right-align pad-right-16",
        hidden: this.state.transactionFilterText !== "Completed" ? true : false,
        headerSortingClasses,
        headerFormatter: (column, colIndex) => renderColumnsheaderFormatter(column, "adjustment"),
        formatter: (cell: any, row: any, rowIndex: any) => renderColAdjustment(row.adjustment),
      },
      {
        dataField: "",
        text: "",
        headerAlign: "right",
        headerClasses: "bsyncColcl action-sticky",
        hidden: this.state.transactionFilterText === "For Review" ? false : true,
        formatter: (cell: any, row: any, rowIndex: any) => renderRightColComponent(row, rowIndex),
      },
    ];

    const handleOnExpand = (row, isExpand, rowIndex, e) => {
      let tableData = _.cloneDeep(this.state.tableData);
      tableData.map((item) => {
        if (item.id === row.id) {
          item.isExpand = !item.isExpand;
        } else {
          item.isExpand = false;
        }
      });
      if (isExpand) {
        this.setState(() => ({
          expandedList: [row.id],
          tableData: tableData,
          selectedPlaidAmount: row?.plaidAmount
        }));
      } else {
        this.setState(() => ({
          expandedList: [],
          tableData: tableData,
        }));
      }
    };

    const selectRow = {
      mode: "checkbox",
      selected: this.state.selectedRows,
      headerClasses: "selectionHeader",
      onSelect: this.handleOnSelect,
      onSelectAll: this.handleOnSelectAll,
    };
    const defaultSorted = [
      {
        dataField: "date",
        order: "asc",
      },
    ];

    const expandHeaderColumnRenderer = (isAnyExpands: any) => {
      if (isAnyExpands) {
        return <div></div>;
      }
      return <div></div>;
    }

    const expandColumnRenderer = (expanded: any, rowKey: any) => {
      let isnonexpend = false;
      this.state.nonExpandRow.forEach(element => {
        if (rowKey === element) {
          isnonexpend = true;
        }
      });
      if (isnonexpend) {
        return (
          <div></div>
        )
      }
      else {
        if (expanded) {
          return (
            <span className="chevronExpended">
              <img src={chevronRight} alt="chevron Right" />
            </span>
          );
        }
        return (
          <span className="chevronCollpsed">
            <img src={chevronRight} alt="chevron Right" />
          </span>
        );
      }
    }

    const expandRow = {
      parentClassName: "parent-expand-foo",
      renderer: (row: any, rowIndex: any) => (
        <div className="rowExpended" id="laborDayDetails">
          <BankSyncChildTable
            transactionFilterType={this.state.transactionFilterText}
            ref={this.BankSyncChildTableRef}
            expandColapseRow={this.handleExpandCol.bind(this, row, rowIndex)}
            row={row}
            transactions={row.transactions}
            saveTransactionStatus={this.saveTransactionStatus.bind(this)}
            confirmTransation={this.confirmTransation.bind(this)}
            hideSlideOut={this.hideSlideOut.bind(this)}
            OpenInvoiceSlideOut={this.OpenInvoiceSlideOut.bind(this)}
            hideTrSlideOut={this.hideTrSlideOut.bind(this)}
            showConfirmAlert={this.showConfirmAlert}
            openDraftTrxSlideout={this.openDraftTrxSlideout.bind(this)}
            {...this.state}
          />
        </div>
      ),
      nonExpandable: this.state.nonExpandRow,
      expanded: this.state.expandedList,
      showExpandColumn: true,
      expandByColumnOnly: true,
      onExpand: handleOnExpand,
      onlyOneExpanding: true,
      expandHeaderColumnRenderer: ({ isAnyExpands }) => expandHeaderColumnRenderer(isAnyExpands),
      expandColumnRenderer: ({ expanded, rowKey }) => expandColumnRenderer(expanded, rowKey),
    };

    let errMsg = "We are having trouble connecting to this bank account. ";

    if (this.state.errorCodeMsg?.toUpperCase() == "ITEM_LOGIN_REQUIRED" ||
      this.state.errorCodeMsg?.toUpperCase() == "ITEM_NOT_FOUND" ||
      this.state.errorCodeMsg?.toUpperCase() == "USER_PERMISSION_REVOKED") {
      errMsg = 'For security purposes, many banks require regular reauthentication. After reauthentication, Inn-Flow will retrieve your transaction history. ';
    }

    return (
      <React.Fragment>
        <div>
          <ToastContainer
            autoClose={3000}
            containerId={"banksyncpage"}
            enableMultiContainer
          />

          <div className="bank-sync">
            <Container fluid className="body-sec">
              {this.state.hasBankPerm && (
                <div className="page-heading underline d-flex">
                  <HidSelector
                    onClickEvent={this.selectHotelDropDown}
                    ref={this.childHID}
                    modulename={"banksync"}
                  />
                  {this.state.loadingFromOther && (
                    <Spinner
                      style={{ fontSize: "12px" }}
                      animation="border"
                      size="sm"
                      variant="primary"
                    />
                  )}
                  {this.state.hasBankPerm && (
                    <div className="mr-auto d-flex align-items-center">
                      Bank Sync
                    </div>
                  )}
                  <div className="action-group d-flex">
                    {this.state.hasManualStatementUploadPermission && (
                      <button type="button" className="nonActiveBtn" onClick={() => { this.toggleManualStatUploadModal(true, "") }}>
                        Manual Statement Upload
                      </button>
                    )}

                    {this.state.hasBankPerm && this.state.isConnectBankPerm && (
                      <Button type="button" onClick={() => { this.showBankSyncWizard() }}>
                        Bank Sync Connections
                      </Button>
                    )}
                    {this.state.tableData.length > 0 && (
                      <div className="section">

                        <Button className="without-border margin-right-15" variant="primary" style={{ display: "none" }}>
                          <CSVLink id="btnexport1" data={exportDataSet}
                            filename={this.state.excelfileName + ".csv"}>Export</CSVLink></Button>
                        <Dropdown className="more-action three-dot" alignRight onSelect={this.getExport}>
                          <Dropdown.Toggle className="btn-outline-primary btn btn-primary more" id="dropdown-more">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                              <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                              <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                            </svg>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item eventKey={"excel"}>Export</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>

                    )}
                  </div>
                </div>
              )}
              {this.state.hasNoSubscription && (
                <div className="page-heading underline d-flex">
                  No Advanced Accounting Subscription
                </div>
              )}
              {this.state.hidValue !== "Select" && (
                <div className="main-Page">
                  <div className="bdy-sec">
                    <ToolkitProvider
                      keyField="Modal_rNo"
                      data={this.state.tableData}
                      columns={columns}
                      search={{
                        afterSearch: (newResult: any) => {
                          this.searchedData(newResult);
                        },
                      }}
                      rowEvents={this.rowEvents}
                    >
                      {(props: {
                        searchProps: JSX.IntrinsicAttributes;
                        baseProps: JSX.IntrinsicAttributes;
                      }) => (
                        <div>
                          {this.state.hotelName !== "Select" && this.state.accountNames.length > 0 && (
                            <div className="search-header registerHeaderWithoutSearch d-flex">
                              <div className="filter-section">
                                <div className="singleSearchDropdownListWithIcon">
                                  <SingleSearchDropdownList
                                    id={"tableAccountName"}
                                    ref={this.accountName}
                                    itemList={this.state.accountNames}
                                    handleSelectedItem={this.handleaccountNameSelectedItem.bind(
                                      this,
                                      1
                                    )}
                                    defaultItem={this.state.HIDToNM}
                                    defaultText={"Search..."}
                                    defaultName={this.state.accountName}
                                    calHomeIcon={true}
                                    currentChecked={this.state.accountName}
                                    controlID="2"
                                    pageType={"Register"}
                                    isStatusbadge={true}
                                  />
                                </div>
                              </div>
                              {this.state.accountNameValue !== -1 &&
                                this.state.sharedHIDList.length > 1 &&
                                !this.state.istableloader && (
                                  <>
                                    <div className="shared-badge badge grey-bg">
                                      <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={
                                          <Tooltip id={"ehid-list"}>
                                            {this.state.sharedHID}
                                          </Tooltip>
                                        }
                                      >
                                        <span>{`Shared (${this.state.sharedHIDList.length})`}</span>
                                      </OverlayTrigger>
                                    </div>
                                  </>
                                )}
                              {this.state.accountNameValue !== -1 &&
                                this.state.lastUpdatedStatus !== null && (
                                  <>
                                    <div className="ml-auto">
                                      {this.state.showBalance && !this.state.lastUpdatedStatus?.toLocaleLowerCase().includes("not uploaded yet") && (
                                        <div className="Accbalance">

                                          {!this.state.lastUpdatedStatus?.toLocaleLowerCase().includes("last manual upload") && (
                                            <>
                                              <span className="balTitle">Bank Account Balance</span>
                                              <strong className="balMain">{"$"}{Utils.amountFormatted(Number(this.state.accountBalance).toFixed(2))}</strong>
                                            </>
                                          )}
                                          {(this.state.lastUpdatedStatus?.toLocaleLowerCase() !== "syncedwithplaid" && !this.state.lastUpdatedStatus?.toLocaleLowerCase().includes("updated on")) && (
                                            <div className="timeStamp-badge"><span>{this.state.lastUpdatedStatus}</span></div>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  </>
                                )}
                            </div>
                          )}
                          {this.state.accountNameValue !== -1 &&
                            (
                              <>
                                <div className="search-header registerSearchHeader d-flex">
                                  <>
                                    {!this.state.isloader ? (
                                      <div className="registerSearch">
                                        <TableSearch
                                          id="tableSearch"
                                          key="tableSearch"
                                          ref={this.tableSearchBankSync}
                                          {...props.searchProps}
                                          CleanSearch={this.CleanSearch.bind(
                                            this
                                          )}
                                          placeholder={
                                            "Filter Bank Transactions"
                                          }
                                        />
                                      </div>
                                    ) : (
                                      <div className="single-loader loadingAnimation"></div>
                                    )}
                                  </>
                                  <div className="filter-section cusDateMargin d-flex align-items-center">
                                    {!this.state.isloader ? (
                                      <>
                                        {this.state.accountNamelist.length >
                                          0 && (
                                            <>
                                              <div className="transactionFilter">
                                                <SingleDropdownListWithoutSearch
                                                  id={"transactionFilter"}
                                                  itemList={
                                                    this.state.transactionFilter
                                                  }
                                                  handleSelectedItem={this.handleSelectedItem.bind(
                                                    this,
                                                    1
                                                  )}
                                                  defaultName={
                                                    this.state
                                                      .transactionFilterText
                                                  }
                                                />
                                              </div>
                                            </>
                                          )}

                                        {this.state.transactionFilterValue ===
                                          1 && this.state.showFlaggedCol &&
                                          <div className='status-filter'>
                                            <Dropdown className="more-action more-action bg-blue single-select-image">
                                              <Dropdown.Toggle id="groups-dropdown" className="filter-btn">
                                                <div className="d-flex align-items-center">
                                                  <div className="txt-area">
                                                    <EllipsisWithTooltip placement="bottom">
                                                      {this.state.statusFilterSelected}
                                                    </EllipsisWithTooltip>
                                                  </div>
                                                  <div className="drop-arrow ml-0 d-flex">
                                                    <FiChevronDown />
                                                  </div>
                                                </div>
                                              </Dropdown.Toggle>
                                              <Dropdown.Menu>
                                                <div className="list-area">
                                                  {this.state.statusFilter.map((item: any, idx: any) => {
                                                    let statusClass = isStatusClass(item?.name);
                                                    return (
                                                      <div className="dropdown-item" key={item.id}>
                                                        <Form.Check
                                                          custom
                                                          type="checkbox"
                                                          id={`checkId_${item.id}`}
                                                          name={item.name}
                                                          label={statusCheckLabel(item, statusClass)}
                                                          value={item.id}
                                                          onChange={(event) => this.itemCheckChange(event, item, "status")}
                                                          checked={item.isChecked}
                                                        />
                                                      </div>
                                                    )
                                                  })}
                                                </div>
                                                {(this.state.statusFilter.length > 0) && (
                                                  <>
                                                    <Dropdown.Divider />
                                                    <div className="selectClearAll dropdown-item">
                                                      <Form.Check
                                                        custom
                                                        type="checkbox"
                                                        id={`itemSelectAllStatus`}
                                                        label={this.state.statusFilter.some(o => o.isChecked === false) ? "Select All" : "Clear All"}
                                                        value={this.state.statusFilter.some(o => o.isChecked === false) ? "selectAll" : "clearAll"}
                                                        onChange={(event) => this.handleItemSelectAll(event, "status")}
                                                        checked={this.state.statusFilter.some(o => o.isChecked === false) ? false : true}
                                                      />
                                                    </div>
                                                  </>
                                                )}
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        }

                                        {this.state.transactionFilterValue !==
                                          1 && (
                                            <>
                                              <SingleDropdownListWithoutSearch
                                                id={"tabledayCount"}
                                                itemList={this.state.dayFilter}
                                                handleSelectedItem={this.handleSelectedItem.bind(
                                                  this,
                                                  2
                                                )}
                                                defaultName={this.state.dayText}
                                                calIcon={true}
                                              />
                                            </>
                                          )}
                                        {!this.state.isloader ?
                                          <>
                                            {this.state.customDate && (
                                              <div className="dateRangePicker hideDisableDate">
                                                <DateRangePicker
                                                  onCallback={this.handleDateCallback}
                                                  initialSettings={{
                                                    minDate: this.state.minDate,
                                                    maxDate: this.state.maxDate,
                                                    startDate: new Date(this.state.start), endDate: new Date(this.state.end)
                                                  }}
                                                >
                                                  <div className="d-flex align-items-center info">
                                                    <div className="shared-list" id="dtrange">{this.state.dateRange}</div>
                                                  </div>
                                                </DateRangePicker>
                                              </div>
                                            )}
                                          </>
                                          : <div className="single-loader loadingAnimation"></div>
                                        }
                                      </>
                                    ) : (
                                      <div className="single-loader loadingAnimation"></div>
                                    )}
                                  </div>

                                  <div className="filter-section ml-auto hasGearIcon">
                                    {!this.state.isloader ? (
                                      <Dropdown className="more-action bg-blue dropdown single-select-image" >
                                        <Dropdown.Toggle id="rolesList">
                                          <div className="d-flex align-items-center">
                                            <svg
                                              width="14"
                                              height="14"
                                              viewBox="0 0 14 14"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path d="M0.562614 9.18059L1.89595 11.4873C2.07995 11.8059 2.48795 11.9153 2.80661 11.7306L3.73728 11.1933C4.12395 11.4979 4.55128 11.7479 5.00061 11.9346V12.9999C5.00061 13.3679 5.29861 13.6666 5.66728 13.6666H8.33395C8.70261 13.6666 9.00062 13.3679 9.00062 12.9999V11.9346C9.44995 11.7479 9.87661 11.4979 10.2639 11.1933L11.1946 11.7306C11.5126 11.9139 11.9219 11.8039 12.1053 11.4873L13.4386 9.18059C13.6219 8.86325 13.5119 8.45325 13.1953 8.26992L12.2806 7.74125C12.3153 7.49525 12.3333 7.24725 12.3333 6.99992C12.3333 6.75259 12.3153 6.50459 12.2793 6.25858L13.1939 5.72992C13.5113 5.54659 13.6213 5.13659 13.4373 4.81925L12.1039 2.51259C11.9199 2.19392 11.5119 2.08459 11.1933 2.26925L10.2626 2.80659C9.87595 2.50192 9.44928 2.25125 8.99995 2.06525V0.999919C8.99995 0.631919 8.70195 0.333252 8.33328 0.333252H5.66661C5.29795 0.333252 4.99995 0.631919 4.99995 0.999919V2.06525C4.55061 2.25192 4.12395 2.50192 3.73661 2.80659L2.80661 2.26925C2.48728 2.08459 2.07995 2.19392 1.89595 2.51259L0.562614 4.81925C0.379281 5.13659 0.489281 5.54659 0.805948 5.72992L1.72061 6.25858C1.68461 6.50459 1.66661 6.75259 1.66661 6.99992C1.66661 7.24725 1.68461 7.49525 1.72061 7.74125L0.805948 8.26992C0.488614 8.45325 0.378614 8.86325 0.562614 9.18059ZM6.99995 4.33325C8.47061 4.33325 9.66661 5.52925 9.66661 6.99992C9.66661 8.47059 8.47061 9.66659 6.99995 9.66659C5.52928 9.66659 4.33328 8.47059 4.33328 6.99992C4.33328 5.52925 5.52928 4.33325 6.99995 4.33325Z" />
                                            </svg>
                                            <div className="drop-arrow ml-1 d-flex">
                                              <FiChevronDown />
                                            </div>
                                          </div>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                          <>
                                            <div className="gearIconHeader headerTop">
                                              <strong>
                                                Density View Control
                                              </strong>
                                            </div>
                                            {this.state.densityView.map(
                                              (items, index) => (
                                                <div
                                                  className={
                                                    this.state
                                                      .defaultDensityView ===
                                                      items.densityType
                                                      ? "dropdownItem active"
                                                      : "dropdownItem"
                                                  }
                                                  key={items.id}
                                                  onClick={(e: any) =>
                                                    this.handleDensityView(
                                                      items.densityType
                                                    )
                                                  }
                                                >
                                                  {items.name}
                                                  {this.state
                                                    .defaultDensityView ===
                                                    items.densityType && (
                                                      <svg
                                                        width="18"
                                                        height="18"
                                                        viewBox="0 0 18 18"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                      >
                                                        <path
                                                          d="M7.49998 11.6895L5.03023 9.21973L3.96973 10.2802L7.49998 13.8105L14.7802 6.53023L13.7197 5.46973L7.49998 11.6895Z"
                                                          fill="#2CAF92"
                                                        ></path>
                                                      </svg>
                                                    )}
                                                </div>
                                              )
                                            )}
                                            <div className="m-0 dropdown-divider"></div>
                                            <Dropdown.Item
                                              className="saveDefaultBtn"
                                              onClick={() =>
                                                this.saveDefaultView()
                                              }
                                            >
                                              Save as Default View
                                            </Dropdown.Item>
                                          </>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    ) : (
                                      <div className="single-loader loadingAnimation"></div>
                                    )}
                                  </div>
                                </div>
                              </>
                            )}
                          {this.state.showBanner && (
                            <div className="notification-banners red-bg">
                              <div>
                                Reauthentication required {" "}
                                <span className="Bank-Reauth">
                                  <span className="err-icon">
                                    <svg
                                      stroke="currentColor"
                                      fill="#6a6e73"
                                      stroke-width="0"
                                      version="1.1"
                                      viewBox="0 0 16 16"
                                      height="1em"
                                      width="1em"
                                      xmlns="http://www.w3.org/2000/svg"
                                      style={{ marginTop: "-3px" }}
                                    >
                                      <path d="M7 4.75c0-0.412 0.338-0.75 0.75-0.75h0.5c0.412 0 0.75 0.338 0.75 0.75v0.5c0 0.412-0.338 0.75-0.75 0.75h-0.5c-0.412 0-0.75-0.338-0.75-0.75v-0.5z"></path>
                                      <path d="M10 12h-4v-1h1v-3h-1v-1h3v4h1z"></path>
                                      <path d="M8 0c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8zM8 14.5c-3.59 0-6.5-2.91-6.5-6.5s2.91-6.5 6.5-6.5 6.5 2.91 6.5 6.5-2.91 6.5-6.5 6.5z"></path>
                                    </svg>{" "}
                                  </span>
                                  <span className="customToolTipBank">
                                    {errMsg}
                                  </span>
                                </span>
                              </div>
                              {this.state.showReconnectButton &&
                                this.state.hasConnectPermission && (
                                  <button
                                    className="btn btn-primary btn-ghost reauthenticateBtn"
                                    onClick={() => this.bankSyncOnReconnect()}
                                  >
                                    Reauthenticate
                                  </button>
                                )}
                            </div>
                          )}
                          {this.state.accountNameValue !== -1 &&
                            (
                              <div className={this.state.bulkSelect ? "bottom-spacer" : ""}>
                                {!this.state.istableloader && (
                                  <div
                                    className={`${this.state.defaultDensityView} fixHeader100 forBankSyncMainTable tableToggleRow regular banksyncScrollArea bankSyncTableDesign ${this.state.showBanner ? "scroll-height" : ""}`}
                                  >
                                    {this.state.transactionFilterValue === 2 ? (
                                      <BootstrapTable
                                        {...props.baseProps}
                                        keyField="id"
                                        hover
                                        rowEvents={this.rowEvents}
                                        expandRow={expandRow}
                                        defaultSorted={defaultSorted}
                                      />
                                    ) : (
                                      <BootstrapTable
                                        {...props.baseProps}
                                        keyField="id"
                                        hover
                                        rowEvents={this.rowEvents}
                                        expandRow={expandRow}
                                        selectRow={this.state.tableData.length > 0 && selectRow}
                                        defaultSorted={defaultSorted}
                                      />
                                    )}
                                    {(this.state.tableData.length === 0) && (
                                      <div className="BankSyncNoDataFrame">
                                        <div className="FrameText">
                                          <strong>No Data Yet</strong>
                                          <span>Manually upload or configure bank sync connections</span>
                                        </div>
                                        {/* <img src={BankSyncNoDataFrame} alt="No Data yet" /> */}
                                        <div>
                                          <svg width="444" height="331" viewBox="0 0 444 331" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_8_103417)">
                                              <mask id="mask0_8_103417" style={{ "maskType": "alpha" }} maskUnits="userSpaceOnUse" x="-31" y="0" width="336" height="336">
                                                <rect width="336" height="336" transform="translate(-31)" fill="url(#paint0_radial_8_103417)" />
                                              </mask>
                                              <g mask="url(#mask0_8_103417)">
                                                <path d="M-23 6.5C-23 7.32843 -23.6716 8 -24.5 8C-25.3284 8 -26 7.32843 -26 6.5C-26 5.67157 -25.3284 5 -24.5 5C-23.6716 5 -23 5.67157 -23 6.5Z" fill="#D0D5DD" />
                                                <path d="M-4 6.5C-4 7.32843 -4.67157 8 -5.5 8C-6.32843 8 -7 7.32843 -7 6.5C-7 5.67157 -6.32843 5 -5.5 5C-4.67157 5 -4 5.67157 -4 6.5Z" fill="#D0D5DD" />
                                                <path d="M15 6.5C15 7.32843 14.3284 8 13.5 8C12.6716 8 12 7.32843 12 6.5C12 5.67157 12.6716 5 13.5 5C14.3284 5 15 5.67157 15 6.5Z" fill="#D0D5DD" />
                                                <path d="M34 6.5C34 7.32843 33.3284 8 32.5 8C31.6716 8 31 7.32843 31 6.5C31 5.67157 31.6716 5 32.5 5C33.3284 5 34 5.67157 34 6.5Z" fill="#D0D5DD" />
                                                <path d="M53 6.5C53 7.32843 52.3284 8 51.5 8C50.6716 8 50 7.32843 50 6.5C50 5.67157 50.6716 5 51.5 5C52.3284 5 53 5.67157 53 6.5Z" fill="#D0D5DD" />
                                                <path d="M72 6.5C72 7.32843 71.3284 8 70.5 8C69.6716 8 69 7.32843 69 6.5C69 5.67157 69.6716 5 70.5 5C71.3284 5 72 5.67157 72 6.5Z" fill="#D0D5DD" />
                                                <path d="M91 6.5C91 7.32843 90.3284 8 89.5 8C88.6716 8 88 7.32843 88 6.5C88 5.67157 88.6716 5 89.5 5C90.3284 5 91 5.67157 91 6.5Z" fill="#D0D5DD" />
                                                <path d="M110 6.5C110 7.32843 109.328 8 108.5 8C107.672 8 107 7.32843 107 6.5C107 5.67157 107.672 5 108.5 5C109.328 5 110 5.67157 110 6.5Z" fill="#D0D5DD" />
                                                <path d="M129 6.5C129 7.32843 128.328 8 127.5 8C126.672 8 126 7.32843 126 6.5C126 5.67157 126.672 5 127.5 5C128.328 5 129 5.67157 129 6.5Z" fill="#D0D5DD" />
                                                <path d="M148 6.5C148 7.32843 147.328 8 146.5 8C145.672 8 145 7.32843 145 6.5C145 5.67157 145.672 5 146.5 5C147.328 5 148 5.67157 148 6.5Z" fill="#D0D5DD" />
                                                <path d="M167 6.5C167 7.32843 166.328 8 165.5 8C164.672 8 164 7.32843 164 6.5C164 5.67157 164.672 5 165.5 5C166.328 5 167 5.67157 167 6.5Z" fill="#D0D5DD" />
                                                <path d="M186 6.5C186 7.32843 185.328 8 184.5 8C183.672 8 183 7.32843 183 6.5C183 5.67157 183.672 5 184.5 5C185.328 5 186 5.67157 186 6.5Z" fill="#D0D5DD" />
                                                <path d="M205 6.5C205 7.32843 204.328 8 203.5 8C202.672 8 202 7.32843 202 6.5C202 5.67157 202.672 5 203.5 5C204.328 5 205 5.67157 205 6.5Z" fill="#D0D5DD" />
                                                <path d="M224 6.5C224 7.32843 223.328 8 222.5 8C221.672 8 221 7.32843 221 6.5C221 5.67157 221.672 5 222.5 5C223.328 5 224 5.67157 224 6.5Z" fill="#D0D5DD" />
                                                <path d="M243 6.5C243 7.32843 242.328 8 241.5 8C240.672 8 240 7.32843 240 6.5C240 5.67157 240.672 5 241.5 5C242.328 5 243 5.67157 243 6.5Z" fill="#D0D5DD" />
                                                <path d="M262 6.5C262 7.32843 261.328 8 260.5 8C259.672 8 259 7.32843 259 6.5C259 5.67157 259.672 5 260.5 5C261.328 5 262 5.67157 262 6.5Z" fill="#D0D5DD" />
                                                <path d="M281 6.5C281 7.32843 280.328 8 279.5 8C278.672 8 278 7.32843 278 6.5C278 5.67157 278.672 5 279.5 5C280.328 5 281 5.67157 281 6.5Z" fill="#D0D5DD" />
                                                <path d="M300 6.5C300 7.32843 299.328 8 298.5 8C297.672 8 297 7.32843 297 6.5C297 5.67157 297.672 5 298.5 5C299.328 5 300 5.67157 300 6.5Z" fill="#D0D5DD" />
                                                <path d="M-23 25.5C-23 26.3284 -23.6716 27 -24.5 27C-25.3284 27 -26 26.3284 -26 25.5C-26 24.6716 -25.3284 24 -24.5 24C-23.6716 24 -23 24.6716 -23 25.5Z" fill="#D0D5DD" />
                                                <path d="M-4 25.5C-4 26.3284 -4.67157 27 -5.5 27C-6.32843 27 -7 26.3284 -7 25.5C-7 24.6716 -6.32843 24 -5.5 24C-4.67157 24 -4 24.6716 -4 25.5Z" fill="#D0D5DD" />
                                                <path d="M15 25.5C15 26.3284 14.3284 27 13.5 27C12.6716 27 12 26.3284 12 25.5C12 24.6716 12.6716 24 13.5 24C14.3284 24 15 24.6716 15 25.5Z" fill="#D0D5DD" />
                                                <path d="M34 25.5C34 26.3284 33.3284 27 32.5 27C31.6716 27 31 26.3284 31 25.5C31 24.6716 31.6716 24 32.5 24C33.3284 24 34 24.6716 34 25.5Z" fill="#D0D5DD" />
                                                <path d="M53 25.5C53 26.3284 52.3284 27 51.5 27C50.6716 27 50 26.3284 50 25.5C50 24.6716 50.6716 24 51.5 24C52.3284 24 53 24.6716 53 25.5Z" fill="#D0D5DD" />
                                                <path d="M72 25.5C72 26.3284 71.3284 27 70.5 27C69.6716 27 69 26.3284 69 25.5C69 24.6716 69.6716 24 70.5 24C71.3284 24 72 24.6716 72 25.5Z" fill="#D0D5DD" />
                                                <path d="M91 25.5C91 26.3284 90.3284 27 89.5 27C88.6716 27 88 26.3284 88 25.5C88 24.6716 88.6716 24 89.5 24C90.3284 24 91 24.6716 91 25.5Z" fill="#D0D5DD" />
                                                <path d="M110 25.5C110 26.3284 109.328 27 108.5 27C107.672 27 107 26.3284 107 25.5C107 24.6716 107.672 24 108.5 24C109.328 24 110 24.6716 110 25.5Z" fill="#D0D5DD" />
                                                <path d="M129 25.5C129 26.3284 128.328 27 127.5 27C126.672 27 126 26.3284 126 25.5C126 24.6716 126.672 24 127.5 24C128.328 24 129 24.6716 129 25.5Z" fill="#D0D5DD" />
                                                <path d="M148 25.5C148 26.3284 147.328 27 146.5 27C145.672 27 145 26.3284 145 25.5C145 24.6716 145.672 24 146.5 24C147.328 24 148 24.6716 148 25.5Z" fill="#D0D5DD" />
                                                <path d="M167 25.5C167 26.3284 166.328 27 165.5 27C164.672 27 164 26.3284 164 25.5C164 24.6716 164.672 24 165.5 24C166.328 24 167 24.6716 167 25.5Z" fill="#D0D5DD" />
                                                <path d="M186 25.5C186 26.3284 185.328 27 184.5 27C183.672 27 183 26.3284 183 25.5C183 24.6716 183.672 24 184.5 24C185.328 24 186 24.6716 186 25.5Z" fill="#D0D5DD" />
                                                <path d="M205 25.5C205 26.3284 204.328 27 203.5 27C202.672 27 202 26.3284 202 25.5C202 24.6716 202.672 24 203.5 24C204.328 24 205 24.6716 205 25.5Z" fill="#D0D5DD" />
                                                <path d="M224 25.5C224 26.3284 223.328 27 222.5 27C221.672 27 221 26.3284 221 25.5C221 24.6716 221.672 24 222.5 24C223.328 24 224 24.6716 224 25.5Z" fill="#D0D5DD" />
                                                <path d="M243 25.5C243 26.3284 242.328 27 241.5 27C240.672 27 240 26.3284 240 25.5C240 24.6716 240.672 24 241.5 24C242.328 24 243 24.6716 243 25.5Z" fill="#D0D5DD" />
                                                <path d="M262 25.5C262 26.3284 261.328 27 260.5 27C259.672 27 259 26.3284 259 25.5C259 24.6716 259.672 24 260.5 24C261.328 24 262 24.6716 262 25.5Z" fill="#D0D5DD" />
                                                <path d="M281 25.5C281 26.3284 280.328 27 279.5 27C278.672 27 278 26.3284 278 25.5C278 24.6716 278.672 24 279.5 24C280.328 24 281 24.6716 281 25.5Z" fill="#D0D5DD" />
                                                <path d="M300 25.5C300 26.3284 299.328 27 298.5 27C297.672 27 297 26.3284 297 25.5C297 24.6716 297.672 24 298.5 24C299.328 24 300 24.6716 300 25.5Z" fill="#D0D5DD" />
                                                <path d="M-23 44.5C-23 45.3284 -23.6716 46 -24.5 46C-25.3284 46 -26 45.3284 -26 44.5C-26 43.6716 -25.3284 43 -24.5 43C-23.6716 43 -23 43.6716 -23 44.5Z" fill="#D0D5DD" />
                                                <path d="M-4 44.5C-4 45.3284 -4.67157 46 -5.5 46C-6.32843 46 -7 45.3284 -7 44.5C-7 43.6716 -6.32843 43 -5.5 43C-4.67157 43 -4 43.6716 -4 44.5Z" fill="#D0D5DD" />
                                                <path d="M15 44.5C15 45.3284 14.3284 46 13.5 46C12.6716 46 12 45.3284 12 44.5C12 43.6716 12.6716 43 13.5 43C14.3284 43 15 43.6716 15 44.5Z" fill="#D0D5DD" />
                                                <path d="M34 44.5C34 45.3284 33.3284 46 32.5 46C31.6716 46 31 45.3284 31 44.5C31 43.6716 31.6716 43 32.5 43C33.3284 43 34 43.6716 34 44.5Z" fill="#D0D5DD" />
                                                <path d="M53 44.5C53 45.3284 52.3284 46 51.5 46C50.6716 46 50 45.3284 50 44.5C50 43.6716 50.6716 43 51.5 43C52.3284 43 53 43.6716 53 44.5Z" fill="#D0D5DD" />
                                                <path d="M72 44.5C72 45.3284 71.3284 46 70.5 46C69.6716 46 69 45.3284 69 44.5C69 43.6716 69.6716 43 70.5 43C71.3284 43 72 43.6716 72 44.5Z" fill="#D0D5DD" />
                                                <path d="M91 44.5C91 45.3284 90.3284 46 89.5 46C88.6716 46 88 45.3284 88 44.5C88 43.6716 88.6716 43 89.5 43C90.3284 43 91 43.6716 91 44.5Z" fill="#D0D5DD" />
                                                <path d="M110 44.5C110 45.3284 109.328 46 108.5 46C107.672 46 107 45.3284 107 44.5C107 43.6716 107.672 43 108.5 43C109.328 43 110 43.6716 110 44.5Z" fill="#D0D5DD" />
                                                <path d="M129 44.5C129 45.3284 128.328 46 127.5 46C126.672 46 126 45.3284 126 44.5C126 43.6716 126.672 43 127.5 43C128.328 43 129 43.6716 129 44.5Z" fill="#D0D5DD" />
                                                <path d="M148 44.5C148 45.3284 147.328 46 146.5 46C145.672 46 145 45.3284 145 44.5C145 43.6716 145.672 43 146.5 43C147.328 43 148 43.6716 148 44.5Z" fill="#D0D5DD" />
                                                <path d="M167 44.5C167 45.3284 166.328 46 165.5 46C164.672 46 164 45.3284 164 44.5C164 43.6716 164.672 43 165.5 43C166.328 43 167 43.6716 167 44.5Z" fill="#D0D5DD" />
                                                <path d="M186 44.5C186 45.3284 185.328 46 184.5 46C183.672 46 183 45.3284 183 44.5C183 43.6716 183.672 43 184.5 43C185.328 43 186 43.6716 186 44.5Z" fill="#D0D5DD" />
                                                <path d="M205 44.5C205 45.3284 204.328 46 203.5 46C202.672 46 202 45.3284 202 44.5C202 43.6716 202.672 43 203.5 43C204.328 43 205 43.6716 205 44.5Z" fill="#D0D5DD" />
                                                <path d="M224 44.5C224 45.3284 223.328 46 222.5 46C221.672 46 221 45.3284 221 44.5C221 43.6716 221.672 43 222.5 43C223.328 43 224 43.6716 224 44.5Z" fill="#D0D5DD" />
                                                <path d="M243 44.5C243 45.3284 242.328 46 241.5 46C240.672 46 240 45.3284 240 44.5C240 43.6716 240.672 43 241.5 43C242.328 43 243 43.6716 243 44.5Z" fill="#D0D5DD" />
                                                <path d="M262 44.5C262 45.3284 261.328 46 260.5 46C259.672 46 259 45.3284 259 44.5C259 43.6716 259.672 43 260.5 43C261.328 43 262 43.6716 262 44.5Z" fill="#D0D5DD" />
                                                <path d="M281 44.5C281 45.3284 280.328 46 279.5 46C278.672 46 278 45.3284 278 44.5C278 43.6716 278.672 43 279.5 43C280.328 43 281 43.6716 281 44.5Z" fill="#D0D5DD" />
                                                <path d="M300 44.5C300 45.3284 299.328 46 298.5 46C297.672 46 297 45.3284 297 44.5C297 43.6716 297.672 43 298.5 43C299.328 43 300 43.6716 300 44.5Z" fill="#D0D5DD" />
                                                <path d="M-23 63.5C-23 64.3284 -23.6716 65 -24.5 65C-25.3284 65 -26 64.3284 -26 63.5C-26 62.6716 -25.3284 62 -24.5 62C-23.6716 62 -23 62.6716 -23 63.5Z" fill="#D0D5DD" />
                                                <path d="M-4 63.5C-4 64.3284 -4.67157 65 -5.5 65C-6.32843 65 -7 64.3284 -7 63.5C-7 62.6716 -6.32843 62 -5.5 62C-4.67157 62 -4 62.6716 -4 63.5Z" fill="#D0D5DD" />
                                                <path d="M15 63.5C15 64.3284 14.3284 65 13.5 65C12.6716 65 12 64.3284 12 63.5C12 62.6716 12.6716 62 13.5 62C14.3284 62 15 62.6716 15 63.5Z" fill="#D0D5DD" />
                                                <path d="M34 63.5C34 64.3284 33.3284 65 32.5 65C31.6716 65 31 64.3284 31 63.5C31 62.6716 31.6716 62 32.5 62C33.3284 62 34 62.6716 34 63.5Z" fill="#D0D5DD" />
                                                <path d="M53 63.5C53 64.3284 52.3284 65 51.5 65C50.6716 65 50 64.3284 50 63.5C50 62.6716 50.6716 62 51.5 62C52.3284 62 53 62.6716 53 63.5Z" fill="#D0D5DD" />
                                                <path d="M72 63.5C72 64.3284 71.3284 65 70.5 65C69.6716 65 69 64.3284 69 63.5C69 62.6716 69.6716 62 70.5 62C71.3284 62 72 62.6716 72 63.5Z" fill="#D0D5DD" />
                                                <path d="M91 63.5C91 64.3284 90.3284 65 89.5 65C88.6716 65 88 64.3284 88 63.5C88 62.6716 88.6716 62 89.5 62C90.3284 62 91 62.6716 91 63.5Z" fill="#D0D5DD" />
                                                <path d="M110 63.5C110 64.3284 109.328 65 108.5 65C107.672 65 107 64.3284 107 63.5C107 62.6716 107.672 62 108.5 62C109.328 62 110 62.6716 110 63.5Z" fill="#D0D5DD" />
                                                <path d="M129 63.5C129 64.3284 128.328 65 127.5 65C126.672 65 126 64.3284 126 63.5C126 62.6716 126.672 62 127.5 62C128.328 62 129 62.6716 129 63.5Z" fill="#D0D5DD" />
                                                <path d="M148 63.5C148 64.3284 147.328 65 146.5 65C145.672 65 145 64.3284 145 63.5C145 62.6716 145.672 62 146.5 62C147.328 62 148 62.6716 148 63.5Z" fill="#D0D5DD" />
                                                <path d="M167 63.5C167 64.3284 166.328 65 165.5 65C164.672 65 164 64.3284 164 63.5C164 62.6716 164.672 62 165.5 62C166.328 62 167 62.6716 167 63.5Z" fill="#D0D5DD" />
                                                <path d="M186 63.5C186 64.3284 185.328 65 184.5 65C183.672 65 183 64.3284 183 63.5C183 62.6716 183.672 62 184.5 62C185.328 62 186 62.6716 186 63.5Z" fill="#D0D5DD" />
                                                <path d="M205 63.5C205 64.3284 204.328 65 203.5 65C202.672 65 202 64.3284 202 63.5C202 62.6716 202.672 62 203.5 62C204.328 62 205 62.6716 205 63.5Z" fill="#D0D5DD" />
                                                <path d="M224 63.5C224 64.3284 223.328 65 222.5 65C221.672 65 221 64.3284 221 63.5C221 62.6716 221.672 62 222.5 62C223.328 62 224 62.6716 224 63.5Z" fill="#D0D5DD" />
                                                <path d="M243 63.5C243 64.3284 242.328 65 241.5 65C240.672 65 240 64.3284 240 63.5C240 62.6716 240.672 62 241.5 62C242.328 62 243 62.6716 243 63.5Z" fill="#D0D5DD" />
                                                <path d="M262 63.5C262 64.3284 261.328 65 260.5 65C259.672 65 259 64.3284 259 63.5C259 62.6716 259.672 62 260.5 62C261.328 62 262 62.6716 262 63.5Z" fill="#D0D5DD" />
                                                <path d="M281 63.5C281 64.3284 280.328 65 279.5 65C278.672 65 278 64.3284 278 63.5C278 62.6716 278.672 62 279.5 62C280.328 62 281 62.6716 281 63.5Z" fill="#D0D5DD" />
                                                <path d="M300 63.5C300 64.3284 299.328 65 298.5 65C297.672 65 297 64.3284 297 63.5C297 62.6716 297.672 62 298.5 62C299.328 62 300 62.6716 300 63.5Z" fill="#D0D5DD" />
                                                <path d="M-23 82.5C-23 83.3284 -23.6716 84 -24.5 84C-25.3284 84 -26 83.3284 -26 82.5C-26 81.6716 -25.3284 81 -24.5 81C-23.6716 81 -23 81.6716 -23 82.5Z" fill="#D0D5DD" />
                                                <path d="M-4 82.5C-4 83.3284 -4.67157 84 -5.5 84C-6.32843 84 -7 83.3284 -7 82.5C-7 81.6716 -6.32843 81 -5.5 81C-4.67157 81 -4 81.6716 -4 82.5Z" fill="#D0D5DD" />
                                                <path d="M15 82.5C15 83.3284 14.3284 84 13.5 84C12.6716 84 12 83.3284 12 82.5C12 81.6716 12.6716 81 13.5 81C14.3284 81 15 81.6716 15 82.5Z" fill="#D0D5DD" />
                                                <path d="M34 82.5C34 83.3284 33.3284 84 32.5 84C31.6716 84 31 83.3284 31 82.5C31 81.6716 31.6716 81 32.5 81C33.3284 81 34 81.6716 34 82.5Z" fill="#D0D5DD" />
                                                <path d="M53 82.5C53 83.3284 52.3284 84 51.5 84C50.6716 84 50 83.3284 50 82.5C50 81.6716 50.6716 81 51.5 81C52.3284 81 53 81.6716 53 82.5Z" fill="#D0D5DD" />
                                                <path d="M72 82.5C72 83.3284 71.3284 84 70.5 84C69.6716 84 69 83.3284 69 82.5C69 81.6716 69.6716 81 70.5 81C71.3284 81 72 81.6716 72 82.5Z" fill="#D0D5DD" />
                                                <path d="M91 82.5C91 83.3284 90.3284 84 89.5 84C88.6716 84 88 83.3284 88 82.5C88 81.6716 88.6716 81 89.5 81C90.3284 81 91 81.6716 91 82.5Z" fill="#D0D5DD" />
                                                <path d="M110 82.5C110 83.3284 109.328 84 108.5 84C107.672 84 107 83.3284 107 82.5C107 81.6716 107.672 81 108.5 81C109.328 81 110 81.6716 110 82.5Z" fill="#D0D5DD" />
                                                <path d="M129 82.5C129 83.3284 128.328 84 127.5 84C126.672 84 126 83.3284 126 82.5C126 81.6716 126.672 81 127.5 81C128.328 81 129 81.6716 129 82.5Z" fill="#D0D5DD" />
                                                <path d="M148 82.5C148 83.3284 147.328 84 146.5 84C145.672 84 145 83.3284 145 82.5C145 81.6716 145.672 81 146.5 81C147.328 81 148 81.6716 148 82.5Z" fill="#D0D5DD" />
                                                <path d="M167 82.5C167 83.3284 166.328 84 165.5 84C164.672 84 164 83.3284 164 82.5C164 81.6716 164.672 81 165.5 81C166.328 81 167 81.6716 167 82.5Z" fill="#D0D5DD" />
                                                <path d="M186 82.5C186 83.3284 185.328 84 184.5 84C183.672 84 183 83.3284 183 82.5C183 81.6716 183.672 81 184.5 81C185.328 81 186 81.6716 186 82.5Z" fill="#D0D5DD" />
                                                <path d="M205 82.5C205 83.3284 204.328 84 203.5 84C202.672 84 202 83.3284 202 82.5C202 81.6716 202.672 81 203.5 81C204.328 81 205 81.6716 205 82.5Z" fill="#D0D5DD" />
                                                <path d="M224 82.5C224 83.3284 223.328 84 222.5 84C221.672 84 221 83.3284 221 82.5C221 81.6716 221.672 81 222.5 81C223.328 81 224 81.6716 224 82.5Z" fill="#D0D5DD" />
                                                <path d="M243 82.5C243 83.3284 242.328 84 241.5 84C240.672 84 240 83.3284 240 82.5C240 81.6716 240.672 81 241.5 81C242.328 81 243 81.6716 243 82.5Z" fill="#D0D5DD" />
                                                <path d="M262 82.5C262 83.3284 261.328 84 260.5 84C259.672 84 259 83.3284 259 82.5C259 81.6716 259.672 81 260.5 81C261.328 81 262 81.6716 262 82.5Z" fill="#D0D5DD" />
                                                <path d="M281 82.5C281 83.3284 280.328 84 279.5 84C278.672 84 278 83.3284 278 82.5C278 81.6716 278.672 81 279.5 81C280.328 81 281 81.6716 281 82.5Z" fill="#D0D5DD" />
                                                <path d="M300 82.5C300 83.3284 299.328 84 298.5 84C297.672 84 297 83.3284 297 82.5C297 81.6716 297.672 81 298.5 81C299.328 81 300 81.6716 300 82.5Z" fill="#D0D5DD" />
                                                <path d="M-23 101.5C-23 102.328 -23.6716 103 -24.5 103C-25.3284 103 -26 102.328 -26 101.5C-26 100.672 -25.3284 100 -24.5 100C-23.6716 100 -23 100.672 -23 101.5Z" fill="#D0D5DD" />
                                                <path d="M-4 101.5C-4 102.328 -4.67157 103 -5.5 103C-6.32843 103 -7 102.328 -7 101.5C-7 100.672 -6.32843 100 -5.5 100C-4.67157 100 -4 100.672 -4 101.5Z" fill="#D0D5DD" />
                                                <path d="M15 101.5C15 102.328 14.3284 103 13.5 103C12.6716 103 12 102.328 12 101.5C12 100.672 12.6716 100 13.5 100C14.3284 100 15 100.672 15 101.5Z" fill="#D0D5DD" />
                                                <path d="M34 101.5C34 102.328 33.3284 103 32.5 103C31.6716 103 31 102.328 31 101.5C31 100.672 31.6716 100 32.5 100C33.3284 100 34 100.672 34 101.5Z" fill="#D0D5DD" />
                                                <path d="M53 101.5C53 102.328 52.3284 103 51.5 103C50.6716 103 50 102.328 50 101.5C50 100.672 50.6716 100 51.5 100C52.3284 100 53 100.672 53 101.5Z" fill="#D0D5DD" />
                                                <path d="M72 101.5C72 102.328 71.3284 103 70.5 103C69.6716 103 69 102.328 69 101.5C69 100.672 69.6716 100 70.5 100C71.3284 100 72 100.672 72 101.5Z" fill="#D0D5DD" />
                                                <path d="M91 101.5C91 102.328 90.3284 103 89.5 103C88.6716 103 88 102.328 88 101.5C88 100.672 88.6716 100 89.5 100C90.3284 100 91 100.672 91 101.5Z" fill="#D0D5DD" />
                                                <path d="M110 101.5C110 102.328 109.328 103 108.5 103C107.672 103 107 102.328 107 101.5C107 100.672 107.672 100 108.5 100C109.328 100 110 100.672 110 101.5Z" fill="#D0D5DD" />
                                                <path d="M129 101.5C129 102.328 128.328 103 127.5 103C126.672 103 126 102.328 126 101.5C126 100.672 126.672 100 127.5 100C128.328 100 129 100.672 129 101.5Z" fill="#D0D5DD" />
                                                <path d="M148 101.5C148 102.328 147.328 103 146.5 103C145.672 103 145 102.328 145 101.5C145 100.672 145.672 100 146.5 100C147.328 100 148 100.672 148 101.5Z" fill="#D0D5DD" />
                                                <path d="M167 101.5C167 102.328 166.328 103 165.5 103C164.672 103 164 102.328 164 101.5C164 100.672 164.672 100 165.5 100C166.328 100 167 100.672 167 101.5Z" fill="#D0D5DD" />
                                                <path d="M186 101.5C186 102.328 185.328 103 184.5 103C183.672 103 183 102.328 183 101.5C183 100.672 183.672 100 184.5 100C185.328 100 186 100.672 186 101.5Z" fill="#D0D5DD" />
                                                <path d="M205 101.5C205 102.328 204.328 103 203.5 103C202.672 103 202 102.328 202 101.5C202 100.672 202.672 100 203.5 100C204.328 100 205 100.672 205 101.5Z" fill="#D0D5DD" />
                                                <path d="M224 101.5C224 102.328 223.328 103 222.5 103C221.672 103 221 102.328 221 101.5C221 100.672 221.672 100 222.5 100C223.328 100 224 100.672 224 101.5Z" fill="#D0D5DD" />
                                                <path d="M243 101.5C243 102.328 242.328 103 241.5 103C240.672 103 240 102.328 240 101.5C240 100.672 240.672 100 241.5 100C242.328 100 243 100.672 243 101.5Z" fill="#D0D5DD" />
                                                <path d="M262 101.5C262 102.328 261.328 103 260.5 103C259.672 103 259 102.328 259 101.5C259 100.672 259.672 100 260.5 100C261.328 100 262 100.672 262 101.5Z" fill="#D0D5DD" />
                                                <path d="M281 101.5C281 102.328 280.328 103 279.5 103C278.672 103 278 102.328 278 101.5C278 100.672 278.672 100 279.5 100C280.328 100 281 100.672 281 101.5Z" fill="#D0D5DD" />
                                                <path d="M300 101.5C300 102.328 299.328 103 298.5 103C297.672 103 297 102.328 297 101.5C297 100.672 297.672 100 298.5 100C299.328 100 300 100.672 300 101.5Z" fill="#D0D5DD" />
                                                <path d="M-23 120.5C-23 121.328 -23.6716 122 -24.5 122C-25.3284 122 -26 121.328 -26 120.5C-26 119.672 -25.3284 119 -24.5 119C-23.6716 119 -23 119.672 -23 120.5Z" fill="#D0D5DD" />
                                                <path d="M-4 120.5C-4 121.328 -4.67157 122 -5.5 122C-6.32843 122 -7 121.328 -7 120.5C-7 119.672 -6.32843 119 -5.5 119C-4.67157 119 -4 119.672 -4 120.5Z" fill="#D0D5DD" />
                                                <path d="M15 120.5C15 121.328 14.3284 122 13.5 122C12.6716 122 12 121.328 12 120.5C12 119.672 12.6716 119 13.5 119C14.3284 119 15 119.672 15 120.5Z" fill="#D0D5DD" />
                                                <path d="M34 120.5C34 121.328 33.3284 122 32.5 122C31.6716 122 31 121.328 31 120.5C31 119.672 31.6716 119 32.5 119C33.3284 119 34 119.672 34 120.5Z" fill="#D0D5DD" />
                                                <path d="M53 120.5C53 121.328 52.3284 122 51.5 122C50.6716 122 50 121.328 50 120.5C50 119.672 50.6716 119 51.5 119C52.3284 119 53 119.672 53 120.5Z" fill="#D0D5DD" />
                                                <path d="M72 120.5C72 121.328 71.3284 122 70.5 122C69.6716 122 69 121.328 69 120.5C69 119.672 69.6716 119 70.5 119C71.3284 119 72 119.672 72 120.5Z" fill="#D0D5DD" />
                                                <path d="M91 120.5C91 121.328 90.3284 122 89.5 122C88.6716 122 88 121.328 88 120.5C88 119.672 88.6716 119 89.5 119C90.3284 119 91 119.672 91 120.5Z" fill="#D0D5DD" />
                                                <path d="M110 120.5C110 121.328 109.328 122 108.5 122C107.672 122 107 121.328 107 120.5C107 119.672 107.672 119 108.5 119C109.328 119 110 119.672 110 120.5Z" fill="#D0D5DD" />
                                                <path d="M129 120.5C129 121.328 128.328 122 127.5 122C126.672 122 126 121.328 126 120.5C126 119.672 126.672 119 127.5 119C128.328 119 129 119.672 129 120.5Z" fill="#D0D5DD" />
                                                <path d="M148 120.5C148 121.328 147.328 122 146.5 122C145.672 122 145 121.328 145 120.5C145 119.672 145.672 119 146.5 119C147.328 119 148 119.672 148 120.5Z" fill="#D0D5DD" />
                                                <path d="M167 120.5C167 121.328 166.328 122 165.5 122C164.672 122 164 121.328 164 120.5C164 119.672 164.672 119 165.5 119C166.328 119 167 119.672 167 120.5Z" fill="#D0D5DD" />
                                                <path d="M186 120.5C186 121.328 185.328 122 184.5 122C183.672 122 183 121.328 183 120.5C183 119.672 183.672 119 184.5 119C185.328 119 186 119.672 186 120.5Z" fill="#D0D5DD" />
                                                <path d="M205 120.5C205 121.328 204.328 122 203.5 122C202.672 122 202 121.328 202 120.5C202 119.672 202.672 119 203.5 119C204.328 119 205 119.672 205 120.5Z" fill="#D0D5DD" />
                                                <path d="M224 120.5C224 121.328 223.328 122 222.5 122C221.672 122 221 121.328 221 120.5C221 119.672 221.672 119 222.5 119C223.328 119 224 119.672 224 120.5Z" fill="#D0D5DD" />
                                                <path d="M243 120.5C243 121.328 242.328 122 241.5 122C240.672 122 240 121.328 240 120.5C240 119.672 240.672 119 241.5 119C242.328 119 243 119.672 243 120.5Z" fill="#D0D5DD" />
                                                <path d="M262 120.5C262 121.328 261.328 122 260.5 122C259.672 122 259 121.328 259 120.5C259 119.672 259.672 119 260.5 119C261.328 119 262 119.672 262 120.5Z" fill="#D0D5DD" />
                                                <path d="M281 120.5C281 121.328 280.328 122 279.5 122C278.672 122 278 121.328 278 120.5C278 119.672 278.672 119 279.5 119C280.328 119 281 119.672 281 120.5Z" fill="#D0D5DD" />
                                                <path d="M300 120.5C300 121.328 299.328 122 298.5 122C297.672 122 297 121.328 297 120.5C297 119.672 297.672 119 298.5 119C299.328 119 300 119.672 300 120.5Z" fill="#D0D5DD" />
                                                <path d="M-23 139.5C-23 140.328 -23.6716 141 -24.5 141C-25.3284 141 -26 140.328 -26 139.5C-26 138.672 -25.3284 138 -24.5 138C-23.6716 138 -23 138.672 -23 139.5Z" fill="#D0D5DD" />
                                                <path d="M-4 139.5C-4 140.328 -4.67157 141 -5.5 141C-6.32843 141 -7 140.328 -7 139.5C-7 138.672 -6.32843 138 -5.5 138C-4.67157 138 -4 138.672 -4 139.5Z" fill="#D0D5DD" />
                                                <path d="M15 139.5C15 140.328 14.3284 141 13.5 141C12.6716 141 12 140.328 12 139.5C12 138.672 12.6716 138 13.5 138C14.3284 138 15 138.672 15 139.5Z" fill="#D0D5DD" />
                                                <path d="M34 139.5C34 140.328 33.3284 141 32.5 141C31.6716 141 31 140.328 31 139.5C31 138.672 31.6716 138 32.5 138C33.3284 138 34 138.672 34 139.5Z" fill="#D0D5DD" />
                                                <path d="M53 139.5C53 140.328 52.3284 141 51.5 141C50.6716 141 50 140.328 50 139.5C50 138.672 50.6716 138 51.5 138C52.3284 138 53 138.672 53 139.5Z" fill="#D0D5DD" />
                                                <path d="M72 139.5C72 140.328 71.3284 141 70.5 141C69.6716 141 69 140.328 69 139.5C69 138.672 69.6716 138 70.5 138C71.3284 138 72 138.672 72 139.5Z" fill="#D0D5DD" />
                                                <path d="M91 139.5C91 140.328 90.3284 141 89.5 141C88.6716 141 88 140.328 88 139.5C88 138.672 88.6716 138 89.5 138C90.3284 138 91 138.672 91 139.5Z" fill="#D0D5DD" />
                                                <path d="M110 139.5C110 140.328 109.328 141 108.5 141C107.672 141 107 140.328 107 139.5C107 138.672 107.672 138 108.5 138C109.328 138 110 138.672 110 139.5Z" fill="#D0D5DD" />
                                                <path d="M129 139.5C129 140.328 128.328 141 127.5 141C126.672 141 126 140.328 126 139.5C126 138.672 126.672 138 127.5 138C128.328 138 129 138.672 129 139.5Z" fill="#D0D5DD" />
                                                <path d="M148 139.5C148 140.328 147.328 141 146.5 141C145.672 141 145 140.328 145 139.5C145 138.672 145.672 138 146.5 138C147.328 138 148 138.672 148 139.5Z" fill="#D0D5DD" />
                                                <path d="M167 139.5C167 140.328 166.328 141 165.5 141C164.672 141 164 140.328 164 139.5C164 138.672 164.672 138 165.5 138C166.328 138 167 138.672 167 139.5Z" fill="#D0D5DD" />
                                                <path d="M186 139.5C186 140.328 185.328 141 184.5 141C183.672 141 183 140.328 183 139.5C183 138.672 183.672 138 184.5 138C185.328 138 186 138.672 186 139.5Z" fill="#D0D5DD" />
                                                <path d="M205 139.5C205 140.328 204.328 141 203.5 141C202.672 141 202 140.328 202 139.5C202 138.672 202.672 138 203.5 138C204.328 138 205 138.672 205 139.5Z" fill="#D0D5DD" />
                                                <path d="M224 139.5C224 140.328 223.328 141 222.5 141C221.672 141 221 140.328 221 139.5C221 138.672 221.672 138 222.5 138C223.328 138 224 138.672 224 139.5Z" fill="#D0D5DD" />
                                                <path d="M243 139.5C243 140.328 242.328 141 241.5 141C240.672 141 240 140.328 240 139.5C240 138.672 240.672 138 241.5 138C242.328 138 243 138.672 243 139.5Z" fill="#D0D5DD" />
                                                <path d="M262 139.5C262 140.328 261.328 141 260.5 141C259.672 141 259 140.328 259 139.5C259 138.672 259.672 138 260.5 138C261.328 138 262 138.672 262 139.5Z" fill="#D0D5DD" />
                                                <path d="M281 139.5C281 140.328 280.328 141 279.5 141C278.672 141 278 140.328 278 139.5C278 138.672 278.672 138 279.5 138C280.328 138 281 138.672 281 139.5Z" fill="#D0D5DD" />
                                                <path d="M300 139.5C300 140.328 299.328 141 298.5 141C297.672 141 297 140.328 297 139.5C297 138.672 297.672 138 298.5 138C299.328 138 300 138.672 300 139.5Z" fill="#D0D5DD" />
                                                <path d="M-23 158.5C-23 159.328 -23.6716 160 -24.5 160C-25.3284 160 -26 159.328 -26 158.5C-26 157.672 -25.3284 157 -24.5 157C-23.6716 157 -23 157.672 -23 158.5Z" fill="#D0D5DD" />
                                                <path d="M-4 158.5C-4 159.328 -4.67157 160 -5.5 160C-6.32843 160 -7 159.328 -7 158.5C-7 157.672 -6.32843 157 -5.5 157C-4.67157 157 -4 157.672 -4 158.5Z" fill="#D0D5DD" />
                                                <path d="M15 158.5C15 159.328 14.3284 160 13.5 160C12.6716 160 12 159.328 12 158.5C12 157.672 12.6716 157 13.5 157C14.3284 157 15 157.672 15 158.5Z" fill="#D0D5DD" />
                                                <path d="M34 158.5C34 159.328 33.3284 160 32.5 160C31.6716 160 31 159.328 31 158.5C31 157.672 31.6716 157 32.5 157C33.3284 157 34 157.672 34 158.5Z" fill="#D0D5DD" />
                                                <path d="M53 158.5C53 159.328 52.3284 160 51.5 160C50.6716 160 50 159.328 50 158.5C50 157.672 50.6716 157 51.5 157C52.3284 157 53 157.672 53 158.5Z" fill="#D0D5DD" />
                                                <path d="M72 158.5C72 159.328 71.3284 160 70.5 160C69.6716 160 69 159.328 69 158.5C69 157.672 69.6716 157 70.5 157C71.3284 157 72 157.672 72 158.5Z" fill="#D0D5DD" />
                                                <path d="M91 158.5C91 159.328 90.3284 160 89.5 160C88.6716 160 88 159.328 88 158.5C88 157.672 88.6716 157 89.5 157C90.3284 157 91 157.672 91 158.5Z" fill="#D0D5DD" />
                                                <path d="M110 158.5C110 159.328 109.328 160 108.5 160C107.672 160 107 159.328 107 158.5C107 157.672 107.672 157 108.5 157C109.328 157 110 157.672 110 158.5Z" fill="#D0D5DD" />
                                                <path d="M129 158.5C129 159.328 128.328 160 127.5 160C126.672 160 126 159.328 126 158.5C126 157.672 126.672 157 127.5 157C128.328 157 129 157.672 129 158.5Z" fill="#D0D5DD" />
                                                <path d="M148 158.5C148 159.328 147.328 160 146.5 160C145.672 160 145 159.328 145 158.5C145 157.672 145.672 157 146.5 157C147.328 157 148 157.672 148 158.5Z" fill="#D0D5DD" />
                                                <path d="M167 158.5C167 159.328 166.328 160 165.5 160C164.672 160 164 159.328 164 158.5C164 157.672 164.672 157 165.5 157C166.328 157 167 157.672 167 158.5Z" fill="#D0D5DD" />
                                                <path d="M186 158.5C186 159.328 185.328 160 184.5 160C183.672 160 183 159.328 183 158.5C183 157.672 183.672 157 184.5 157C185.328 157 186 157.672 186 158.5Z" fill="#D0D5DD" />
                                                <path d="M205 158.5C205 159.328 204.328 160 203.5 160C202.672 160 202 159.328 202 158.5C202 157.672 202.672 157 203.5 157C204.328 157 205 157.672 205 158.5Z" fill="#D0D5DD" />
                                                <path d="M224 158.5C224 159.328 223.328 160 222.5 160C221.672 160 221 159.328 221 158.5C221 157.672 221.672 157 222.5 157C223.328 157 224 157.672 224 158.5Z" fill="#D0D5DD" />
                                                <path d="M243 158.5C243 159.328 242.328 160 241.5 160C240.672 160 240 159.328 240 158.5C240 157.672 240.672 157 241.5 157C242.328 157 243 157.672 243 158.5Z" fill="#D0D5DD" />
                                                <path d="M262 158.5C262 159.328 261.328 160 260.5 160C259.672 160 259 159.328 259 158.5C259 157.672 259.672 157 260.5 157C261.328 157 262 157.672 262 158.5Z" fill="#D0D5DD" />
                                                <path d="M281 158.5C281 159.328 280.328 160 279.5 160C278.672 160 278 159.328 278 158.5C278 157.672 278.672 157 279.5 157C280.328 157 281 157.672 281 158.5Z" fill="#D0D5DD" />
                                                <path d="M300 158.5C300 159.328 299.328 160 298.5 160C297.672 160 297 159.328 297 158.5C297 157.672 297.672 157 298.5 157C299.328 157 300 157.672 300 158.5Z" fill="#D0D5DD" />
                                                <path d="M-23 177.5C-23 178.328 -23.6716 179 -24.5 179C-25.3284 179 -26 178.328 -26 177.5C-26 176.672 -25.3284 176 -24.5 176C-23.6716 176 -23 176.672 -23 177.5Z" fill="#D0D5DD" />
                                                <path d="M-4 177.5C-4 178.328 -4.67157 179 -5.5 179C-6.32843 179 -7 178.328 -7 177.5C-7 176.672 -6.32843 176 -5.5 176C-4.67157 176 -4 176.672 -4 177.5Z" fill="#D0D5DD" />
                                                <path d="M15 177.5C15 178.328 14.3284 179 13.5 179C12.6716 179 12 178.328 12 177.5C12 176.672 12.6716 176 13.5 176C14.3284 176 15 176.672 15 177.5Z" fill="#D0D5DD" />
                                                <path d="M34 177.5C34 178.328 33.3284 179 32.5 179C31.6716 179 31 178.328 31 177.5C31 176.672 31.6716 176 32.5 176C33.3284 176 34 176.672 34 177.5Z" fill="#D0D5DD" />
                                                <path d="M53 177.5C53 178.328 52.3284 179 51.5 179C50.6716 179 50 178.328 50 177.5C50 176.672 50.6716 176 51.5 176C52.3284 176 53 176.672 53 177.5Z" fill="#D0D5DD" />
                                                <path d="M72 177.5C72 178.328 71.3284 179 70.5 179C69.6716 179 69 178.328 69 177.5C69 176.672 69.6716 176 70.5 176C71.3284 176 72 176.672 72 177.5Z" fill="#D0D5DD" />
                                                <path d="M91 177.5C91 178.328 90.3284 179 89.5 179C88.6716 179 88 178.328 88 177.5C88 176.672 88.6716 176 89.5 176C90.3284 176 91 176.672 91 177.5Z" fill="#D0D5DD" />
                                                <path d="M110 177.5C110 178.328 109.328 179 108.5 179C107.672 179 107 178.328 107 177.5C107 176.672 107.672 176 108.5 176C109.328 176 110 176.672 110 177.5Z" fill="#D0D5DD" />
                                                <path d="M129 177.5C129 178.328 128.328 179 127.5 179C126.672 179 126 178.328 126 177.5C126 176.672 126.672 176 127.5 176C128.328 176 129 176.672 129 177.5Z" fill="#D0D5DD" />
                                                <path d="M148 177.5C148 178.328 147.328 179 146.5 179C145.672 179 145 178.328 145 177.5C145 176.672 145.672 176 146.5 176C147.328 176 148 176.672 148 177.5Z" fill="#D0D5DD" />
                                                <path d="M167 177.5C167 178.328 166.328 179 165.5 179C164.672 179 164 178.328 164 177.5C164 176.672 164.672 176 165.5 176C166.328 176 167 176.672 167 177.5Z" fill="#D0D5DD" />
                                                <path d="M186 177.5C186 178.328 185.328 179 184.5 179C183.672 179 183 178.328 183 177.5C183 176.672 183.672 176 184.5 176C185.328 176 186 176.672 186 177.5Z" fill="#D0D5DD" />
                                                <path d="M205 177.5C205 178.328 204.328 179 203.5 179C202.672 179 202 178.328 202 177.5C202 176.672 202.672 176 203.5 176C204.328 176 205 176.672 205 177.5Z" fill="#D0D5DD" />
                                                <path d="M224 177.5C224 178.328 223.328 179 222.5 179C221.672 179 221 178.328 221 177.5C221 176.672 221.672 176 222.5 176C223.328 176 224 176.672 224 177.5Z" fill="#D0D5DD" />
                                                <path d="M243 177.5C243 178.328 242.328 179 241.5 179C240.672 179 240 178.328 240 177.5C240 176.672 240.672 176 241.5 176C242.328 176 243 176.672 243 177.5Z" fill="#D0D5DD" />
                                                <path d="M262 177.5C262 178.328 261.328 179 260.5 179C259.672 179 259 178.328 259 177.5C259 176.672 259.672 176 260.5 176C261.328 176 262 176.672 262 177.5Z" fill="#D0D5DD" />
                                                <path d="M281 177.5C281 178.328 280.328 179 279.5 179C278.672 179 278 178.328 278 177.5C278 176.672 278.672 176 279.5 176C280.328 176 281 176.672 281 177.5Z" fill="#D0D5DD" />
                                                <path d="M300 177.5C300 178.328 299.328 179 298.5 179C297.672 179 297 178.328 297 177.5C297 176.672 297.672 176 298.5 176C299.328 176 300 176.672 300 177.5Z" fill="#D0D5DD" />
                                                <path d="M-23 196.5C-23 197.328 -23.6716 198 -24.5 198C-25.3284 198 -26 197.328 -26 196.5C-26 195.672 -25.3284 195 -24.5 195C-23.6716 195 -23 195.672 -23 196.5Z" fill="#D0D5DD" />
                                                <path d="M-4 196.5C-4 197.328 -4.67157 198 -5.5 198C-6.32843 198 -7 197.328 -7 196.5C-7 195.672 -6.32843 195 -5.5 195C-4.67157 195 -4 195.672 -4 196.5Z" fill="#D0D5DD" />
                                                <path d="M15 196.5C15 197.328 14.3284 198 13.5 198C12.6716 198 12 197.328 12 196.5C12 195.672 12.6716 195 13.5 195C14.3284 195 15 195.672 15 196.5Z" fill="#D0D5DD" />
                                                <path d="M34 196.5C34 197.328 33.3284 198 32.5 198C31.6716 198 31 197.328 31 196.5C31 195.672 31.6716 195 32.5 195C33.3284 195 34 195.672 34 196.5Z" fill="#D0D5DD" />
                                                <path d="M53 196.5C53 197.328 52.3284 198 51.5 198C50.6716 198 50 197.328 50 196.5C50 195.672 50.6716 195 51.5 195C52.3284 195 53 195.672 53 196.5Z" fill="#D0D5DD" />
                                                <path d="M72 196.5C72 197.328 71.3284 198 70.5 198C69.6716 198 69 197.328 69 196.5C69 195.672 69.6716 195 70.5 195C71.3284 195 72 195.672 72 196.5Z" fill="#D0D5DD" />
                                                <path d="M91 196.5C91 197.328 90.3284 198 89.5 198C88.6716 198 88 197.328 88 196.5C88 195.672 88.6716 195 89.5 195C90.3284 195 91 195.672 91 196.5Z" fill="#D0D5DD" />
                                                <path d="M110 196.5C110 197.328 109.328 198 108.5 198C107.672 198 107 197.328 107 196.5C107 195.672 107.672 195 108.5 195C109.328 195 110 195.672 110 196.5Z" fill="#D0D5DD" />
                                                <path d="M129 196.5C129 197.328 128.328 198 127.5 198C126.672 198 126 197.328 126 196.5C126 195.672 126.672 195 127.5 195C128.328 195 129 195.672 129 196.5Z" fill="#D0D5DD" />
                                                <path d="M148 196.5C148 197.328 147.328 198 146.5 198C145.672 198 145 197.328 145 196.5C145 195.672 145.672 195 146.5 195C147.328 195 148 195.672 148 196.5Z" fill="#D0D5DD" />
                                                <path d="M167 196.5C167 197.328 166.328 198 165.5 198C164.672 198 164 197.328 164 196.5C164 195.672 164.672 195 165.5 195C166.328 195 167 195.672 167 196.5Z" fill="#D0D5DD" />
                                                <path d="M186 196.5C186 197.328 185.328 198 184.5 198C183.672 198 183 197.328 183 196.5C183 195.672 183.672 195 184.5 195C185.328 195 186 195.672 186 196.5Z" fill="#D0D5DD" />
                                                <path d="M205 196.5C205 197.328 204.328 198 203.5 198C202.672 198 202 197.328 202 196.5C202 195.672 202.672 195 203.5 195C204.328 195 205 195.672 205 196.5Z" fill="#D0D5DD" />
                                                <path d="M224 196.5C224 197.328 223.328 198 222.5 198C221.672 198 221 197.328 221 196.5C221 195.672 221.672 195 222.5 195C223.328 195 224 195.672 224 196.5Z" fill="#D0D5DD" />
                                                <path d="M243 196.5C243 197.328 242.328 198 241.5 198C240.672 198 240 197.328 240 196.5C240 195.672 240.672 195 241.5 195C242.328 195 243 195.672 243 196.5Z" fill="#D0D5DD" />
                                                <path d="M262 196.5C262 197.328 261.328 198 260.5 198C259.672 198 259 197.328 259 196.5C259 195.672 259.672 195 260.5 195C261.328 195 262 195.672 262 196.5Z" fill="#D0D5DD" />
                                                <path d="M281 196.5C281 197.328 280.328 198 279.5 198C278.672 198 278 197.328 278 196.5C278 195.672 278.672 195 279.5 195C280.328 195 281 195.672 281 196.5Z" fill="#D0D5DD" />
                                                <path d="M300 196.5C300 197.328 299.328 198 298.5 198C297.672 198 297 197.328 297 196.5C297 195.672 297.672 195 298.5 195C299.328 195 300 195.672 300 196.5Z" fill="#D0D5DD" />
                                                <path d="M-23 215.5C-23 216.328 -23.6716 217 -24.5 217C-25.3284 217 -26 216.328 -26 215.5C-26 214.672 -25.3284 214 -24.5 214C-23.6716 214 -23 214.672 -23 215.5Z" fill="#D0D5DD" />
                                                <path d="M-4 215.5C-4 216.328 -4.67157 217 -5.5 217C-6.32843 217 -7 216.328 -7 215.5C-7 214.672 -6.32843 214 -5.5 214C-4.67157 214 -4 214.672 -4 215.5Z" fill="#D0D5DD" />
                                                <path d="M15 215.5C15 216.328 14.3284 217 13.5 217C12.6716 217 12 216.328 12 215.5C12 214.672 12.6716 214 13.5 214C14.3284 214 15 214.672 15 215.5Z" fill="#D0D5DD" />
                                                <path d="M34 215.5C34 216.328 33.3284 217 32.5 217C31.6716 217 31 216.328 31 215.5C31 214.672 31.6716 214 32.5 214C33.3284 214 34 214.672 34 215.5Z" fill="#D0D5DD" />
                                                <path d="M53 215.5C53 216.328 52.3284 217 51.5 217C50.6716 217 50 216.328 50 215.5C50 214.672 50.6716 214 51.5 214C52.3284 214 53 214.672 53 215.5Z" fill="#D0D5DD" />
                                                <path d="M72 215.5C72 216.328 71.3284 217 70.5 217C69.6716 217 69 216.328 69 215.5C69 214.672 69.6716 214 70.5 214C71.3284 214 72 214.672 72 215.5Z" fill="#D0D5DD" />
                                                <path d="M91 215.5C91 216.328 90.3284 217 89.5 217C88.6716 217 88 216.328 88 215.5C88 214.672 88.6716 214 89.5 214C90.3284 214 91 214.672 91 215.5Z" fill="#D0D5DD" />
                                                <path d="M110 215.5C110 216.328 109.328 217 108.5 217C107.672 217 107 216.328 107 215.5C107 214.672 107.672 214 108.5 214C109.328 214 110 214.672 110 215.5Z" fill="#D0D5DD" />
                                                <path d="M129 215.5C129 216.328 128.328 217 127.5 217C126.672 217 126 216.328 126 215.5C126 214.672 126.672 214 127.5 214C128.328 214 129 214.672 129 215.5Z" fill="#D0D5DD" />
                                                <path d="M148 215.5C148 216.328 147.328 217 146.5 217C145.672 217 145 216.328 145 215.5C145 214.672 145.672 214 146.5 214C147.328 214 148 214.672 148 215.5Z" fill="#D0D5DD" />
                                                <path d="M167 215.5C167 216.328 166.328 217 165.5 217C164.672 217 164 216.328 164 215.5C164 214.672 164.672 214 165.5 214C166.328 214 167 214.672 167 215.5Z" fill="#D0D5DD" />
                                                <path d="M186 215.5C186 216.328 185.328 217 184.5 217C183.672 217 183 216.328 183 215.5C183 214.672 183.672 214 184.5 214C185.328 214 186 214.672 186 215.5Z" fill="#D0D5DD" />
                                                <path d="M205 215.5C205 216.328 204.328 217 203.5 217C202.672 217 202 216.328 202 215.5C202 214.672 202.672 214 203.5 214C204.328 214 205 214.672 205 215.5Z" fill="#D0D5DD" />
                                                <path d="M224 215.5C224 216.328 223.328 217 222.5 217C221.672 217 221 216.328 221 215.5C221 214.672 221.672 214 222.5 214C223.328 214 224 214.672 224 215.5Z" fill="#D0D5DD" />
                                                <path d="M243 215.5C243 216.328 242.328 217 241.5 217C240.672 217 240 216.328 240 215.5C240 214.672 240.672 214 241.5 214C242.328 214 243 214.672 243 215.5Z" fill="#D0D5DD" />
                                                <path d="M262 215.5C262 216.328 261.328 217 260.5 217C259.672 217 259 216.328 259 215.5C259 214.672 259.672 214 260.5 214C261.328 214 262 214.672 262 215.5Z" fill="#D0D5DD" />
                                                <path d="M281 215.5C281 216.328 280.328 217 279.5 217C278.672 217 278 216.328 278 215.5C278 214.672 278.672 214 279.5 214C280.328 214 281 214.672 281 215.5Z" fill="#D0D5DD" />
                                                <path d="M300 215.5C300 216.328 299.328 217 298.5 217C297.672 217 297 216.328 297 215.5C297 214.672 297.672 214 298.5 214C299.328 214 300 214.672 300 215.5Z" fill="#D0D5DD" />
                                                <path d="M-23 234.5C-23 235.328 -23.6716 236 -24.5 236C-25.3284 236 -26 235.328 -26 234.5C-26 233.672 -25.3284 233 -24.5 233C-23.6716 233 -23 233.672 -23 234.5Z" fill="#D0D5DD" />
                                                <path d="M-4 234.5C-4 235.328 -4.67157 236 -5.5 236C-6.32843 236 -7 235.328 -7 234.5C-7 233.672 -6.32843 233 -5.5 233C-4.67157 233 -4 233.672 -4 234.5Z" fill="#D0D5DD" />
                                                <path d="M15 234.5C15 235.328 14.3284 236 13.5 236C12.6716 236 12 235.328 12 234.5C12 233.672 12.6716 233 13.5 233C14.3284 233 15 233.672 15 234.5Z" fill="#D0D5DD" />
                                                <path d="M34 234.5C34 235.328 33.3284 236 32.5 236C31.6716 236 31 235.328 31 234.5C31 233.672 31.6716 233 32.5 233C33.3284 233 34 233.672 34 234.5Z" fill="#D0D5DD" />
                                                <path d="M53 234.5C53 235.328 52.3284 236 51.5 236C50.6716 236 50 235.328 50 234.5C50 233.672 50.6716 233 51.5 233C52.3284 233 53 233.672 53 234.5Z" fill="#D0D5DD" />
                                                <path d="M72 234.5C72 235.328 71.3284 236 70.5 236C69.6716 236 69 235.328 69 234.5C69 233.672 69.6716 233 70.5 233C71.3284 233 72 233.672 72 234.5Z" fill="#D0D5DD" />
                                                <path d="M91 234.5C91 235.328 90.3284 236 89.5 236C88.6716 236 88 235.328 88 234.5C88 233.672 88.6716 233 89.5 233C90.3284 233 91 233.672 91 234.5Z" fill="#D0D5DD" />
                                                <path d="M110 234.5C110 235.328 109.328 236 108.5 236C107.672 236 107 235.328 107 234.5C107 233.672 107.672 233 108.5 233C109.328 233 110 233.672 110 234.5Z" fill="#D0D5DD" />
                                                <path d="M129 234.5C129 235.328 128.328 236 127.5 236C126.672 236 126 235.328 126 234.5C126 233.672 126.672 233 127.5 233C128.328 233 129 233.672 129 234.5Z" fill="#D0D5DD" />
                                                <path d="M148 234.5C148 235.328 147.328 236 146.5 236C145.672 236 145 235.328 145 234.5C145 233.672 145.672 233 146.5 233C147.328 233 148 233.672 148 234.5Z" fill="#D0D5DD" />
                                                <path d="M167 234.5C167 235.328 166.328 236 165.5 236C164.672 236 164 235.328 164 234.5C164 233.672 164.672 233 165.5 233C166.328 233 167 233.672 167 234.5Z" fill="#D0D5DD" />
                                                <path d="M186 234.5C186 235.328 185.328 236 184.5 236C183.672 236 183 235.328 183 234.5C183 233.672 183.672 233 184.5 233C185.328 233 186 233.672 186 234.5Z" fill="#D0D5DD" />
                                                <path d="M205 234.5C205 235.328 204.328 236 203.5 236C202.672 236 202 235.328 202 234.5C202 233.672 202.672 233 203.5 233C204.328 233 205 233.672 205 234.5Z" fill="#D0D5DD" />
                                                <path d="M224 234.5C224 235.328 223.328 236 222.5 236C221.672 236 221 235.328 221 234.5C221 233.672 221.672 233 222.5 233C223.328 233 224 233.672 224 234.5Z" fill="#D0D5DD" />
                                                <path d="M243 234.5C243 235.328 242.328 236 241.5 236C240.672 236 240 235.328 240 234.5C240 233.672 240.672 233 241.5 233C242.328 233 243 233.672 243 234.5Z" fill="#D0D5DD" />
                                                <path d="M262 234.5C262 235.328 261.328 236 260.5 236C259.672 236 259 235.328 259 234.5C259 233.672 259.672 233 260.5 233C261.328 233 262 233.672 262 234.5Z" fill="#D0D5DD" />
                                                <path d="M281 234.5C281 235.328 280.328 236 279.5 236C278.672 236 278 235.328 278 234.5C278 233.672 278.672 233 279.5 233C280.328 233 281 233.672 281 234.5Z" fill="#D0D5DD" />
                                                <path d="M300 234.5C300 235.328 299.328 236 298.5 236C297.672 236 297 235.328 297 234.5C297 233.672 297.672 233 298.5 233C299.328 233 300 233.672 300 234.5Z" fill="#D0D5DD" />
                                                <path d="M-23 253.5C-23 254.328 -23.6716 255 -24.5 255C-25.3284 255 -26 254.328 -26 253.5C-26 252.672 -25.3284 252 -24.5 252C-23.6716 252 -23 252.672 -23 253.5Z" fill="#D0D5DD" />
                                                <path d="M-4 253.5C-4 254.328 -4.67157 255 -5.5 255C-6.32843 255 -7 254.328 -7 253.5C-7 252.672 -6.32843 252 -5.5 252C-4.67157 252 -4 252.672 -4 253.5Z" fill="#D0D5DD" />
                                                <path d="M15 253.5C15 254.328 14.3284 255 13.5 255C12.6716 255 12 254.328 12 253.5C12 252.672 12.6716 252 13.5 252C14.3284 252 15 252.672 15 253.5Z" fill="#D0D5DD" />
                                                <path d="M34 253.5C34 254.328 33.3284 255 32.5 255C31.6716 255 31 254.328 31 253.5C31 252.672 31.6716 252 32.5 252C33.3284 252 34 252.672 34 253.5Z" fill="#D0D5DD" />
                                                <path d="M53 253.5C53 254.328 52.3284 255 51.5 255C50.6716 255 50 254.328 50 253.5C50 252.672 50.6716 252 51.5 252C52.3284 252 53 252.672 53 253.5Z" fill="#D0D5DD" />
                                                <path d="M72 253.5C72 254.328 71.3284 255 70.5 255C69.6716 255 69 254.328 69 253.5C69 252.672 69.6716 252 70.5 252C71.3284 252 72 252.672 72 253.5Z" fill="#D0D5DD" />
                                                <path d="M91 253.5C91 254.328 90.3284 255 89.5 255C88.6716 255 88 254.328 88 253.5C88 252.672 88.6716 252 89.5 252C90.3284 252 91 252.672 91 253.5Z" fill="#D0D5DD" />
                                                <path d="M110 253.5C110 254.328 109.328 255 108.5 255C107.672 255 107 254.328 107 253.5C107 252.672 107.672 252 108.5 252C109.328 252 110 252.672 110 253.5Z" fill="#D0D5DD" />
                                                <path d="M129 253.5C129 254.328 128.328 255 127.5 255C126.672 255 126 254.328 126 253.5C126 252.672 126.672 252 127.5 252C128.328 252 129 252.672 129 253.5Z" fill="#D0D5DD" />
                                                <path d="M148 253.5C148 254.328 147.328 255 146.5 255C145.672 255 145 254.328 145 253.5C145 252.672 145.672 252 146.5 252C147.328 252 148 252.672 148 253.5Z" fill="#D0D5DD" />
                                                <path d="M167 253.5C167 254.328 166.328 255 165.5 255C164.672 255 164 254.328 164 253.5C164 252.672 164.672 252 165.5 252C166.328 252 167 252.672 167 253.5Z" fill="#D0D5DD" />
                                                <path d="M186 253.5C186 254.328 185.328 255 184.5 255C183.672 255 183 254.328 183 253.5C183 252.672 183.672 252 184.5 252C185.328 252 186 252.672 186 253.5Z" fill="#D0D5DD" />
                                                <path d="M205 253.5C205 254.328 204.328 255 203.5 255C202.672 255 202 254.328 202 253.5C202 252.672 202.672 252 203.5 252C204.328 252 205 252.672 205 253.5Z" fill="#D0D5DD" />
                                                <path d="M224 253.5C224 254.328 223.328 255 222.5 255C221.672 255 221 254.328 221 253.5C221 252.672 221.672 252 222.5 252C223.328 252 224 252.672 224 253.5Z" fill="#D0D5DD" />
                                                <path d="M243 253.5C243 254.328 242.328 255 241.5 255C240.672 255 240 254.328 240 253.5C240 252.672 240.672 252 241.5 252C242.328 252 243 252.672 243 253.5Z" fill="#D0D5DD" />
                                                <path d="M262 253.5C262 254.328 261.328 255 260.5 255C259.672 255 259 254.328 259 253.5C259 252.672 259.672 252 260.5 252C261.328 252 262 252.672 262 253.5Z" fill="#D0D5DD" />
                                                <path d="M281 253.5C281 254.328 280.328 255 279.5 255C278.672 255 278 254.328 278 253.5C278 252.672 278.672 252 279.5 252C280.328 252 281 252.672 281 253.5Z" fill="#D0D5DD" />
                                                <path d="M300 253.5C300 254.328 299.328 255 298.5 255C297.672 255 297 254.328 297 253.5C297 252.672 297.672 252 298.5 252C299.328 252 300 252.672 300 253.5Z" fill="#D0D5DD" />
                                                <path d="M-23 272.5C-23 273.328 -23.6716 274 -24.5 274C-25.3284 274 -26 273.328 -26 272.5C-26 271.672 -25.3284 271 -24.5 271C-23.6716 271 -23 271.672 -23 272.5Z" fill="#D0D5DD" />
                                                <path d="M-4 272.5C-4 273.328 -4.67157 274 -5.5 274C-6.32843 274 -7 273.328 -7 272.5C-7 271.672 -6.32843 271 -5.5 271C-4.67157 271 -4 271.672 -4 272.5Z" fill="#D0D5DD" />
                                                <path d="M15 272.5C15 273.328 14.3284 274 13.5 274C12.6716 274 12 273.328 12 272.5C12 271.672 12.6716 271 13.5 271C14.3284 271 15 271.672 15 272.5Z" fill="#D0D5DD" />
                                                <path d="M34 272.5C34 273.328 33.3284 274 32.5 274C31.6716 274 31 273.328 31 272.5C31 271.672 31.6716 271 32.5 271C33.3284 271 34 271.672 34 272.5Z" fill="#D0D5DD" />
                                                <path d="M53 272.5C53 273.328 52.3284 274 51.5 274C50.6716 274 50 273.328 50 272.5C50 271.672 50.6716 271 51.5 271C52.3284 271 53 271.672 53 272.5Z" fill="#D0D5DD" />
                                                <path d="M72 272.5C72 273.328 71.3284 274 70.5 274C69.6716 274 69 273.328 69 272.5C69 271.672 69.6716 271 70.5 271C71.3284 271 72 271.672 72 272.5Z" fill="#D0D5DD" />
                                                <path d="M91 272.5C91 273.328 90.3284 274 89.5 274C88.6716 274 88 273.328 88 272.5C88 271.672 88.6716 271 89.5 271C90.3284 271 91 271.672 91 272.5Z" fill="#D0D5DD" />
                                                <path d="M110 272.5C110 273.328 109.328 274 108.5 274C107.672 274 107 273.328 107 272.5C107 271.672 107.672 271 108.5 271C109.328 271 110 271.672 110 272.5Z" fill="#D0D5DD" />
                                                <path d="M129 272.5C129 273.328 128.328 274 127.5 274C126.672 274 126 273.328 126 272.5C126 271.672 126.672 271 127.5 271C128.328 271 129 271.672 129 272.5Z" fill="#D0D5DD" />
                                                <path d="M148 272.5C148 273.328 147.328 274 146.5 274C145.672 274 145 273.328 145 272.5C145 271.672 145.672 271 146.5 271C147.328 271 148 271.672 148 272.5Z" fill="#D0D5DD" />
                                                <path d="M167 272.5C167 273.328 166.328 274 165.5 274C164.672 274 164 273.328 164 272.5C164 271.672 164.672 271 165.5 271C166.328 271 167 271.672 167 272.5Z" fill="#D0D5DD" />
                                                <path d="M186 272.5C186 273.328 185.328 274 184.5 274C183.672 274 183 273.328 183 272.5C183 271.672 183.672 271 184.5 271C185.328 271 186 271.672 186 272.5Z" fill="#D0D5DD" />
                                                <path d="M205 272.5C205 273.328 204.328 274 203.5 274C202.672 274 202 273.328 202 272.5C202 271.672 202.672 271 203.5 271C204.328 271 205 271.672 205 272.5Z" fill="#D0D5DD" />
                                                <path d="M224 272.5C224 273.328 223.328 274 222.5 274C221.672 274 221 273.328 221 272.5C221 271.672 221.672 271 222.5 271C223.328 271 224 271.672 224 272.5Z" fill="#D0D5DD" />
                                                <path d="M243 272.5C243 273.328 242.328 274 241.5 274C240.672 274 240 273.328 240 272.5C240 271.672 240.672 271 241.5 271C242.328 271 243 271.672 243 272.5Z" fill="#D0D5DD" />
                                                <path d="M262 272.5C262 273.328 261.328 274 260.5 274C259.672 274 259 273.328 259 272.5C259 271.672 259.672 271 260.5 271C261.328 271 262 271.672 262 272.5Z" fill="#D0D5DD" />
                                                <path d="M281 272.5C281 273.328 280.328 274 279.5 274C278.672 274 278 273.328 278 272.5C278 271.672 278.672 271 279.5 271C280.328 271 281 271.672 281 272.5Z" fill="#D0D5DD" />
                                                <path d="M300 272.5C300 273.328 299.328 274 298.5 274C297.672 274 297 273.328 297 272.5C297 271.672 297.672 271 298.5 271C299.328 271 300 271.672 300 272.5Z" fill="#D0D5DD" />
                                                <path d="M-23 291.5C-23 292.328 -23.6716 293 -24.5 293C-25.3284 293 -26 292.328 -26 291.5C-26 290.672 -25.3284 290 -24.5 290C-23.6716 290 -23 290.672 -23 291.5Z" fill="#D0D5DD" />
                                                <path d="M-4 291.5C-4 292.328 -4.67157 293 -5.5 293C-6.32843 293 -7 292.328 -7 291.5C-7 290.672 -6.32843 290 -5.5 290C-4.67157 290 -4 290.672 -4 291.5Z" fill="#D0D5DD" />
                                                <path d="M15 291.5C15 292.328 14.3284 293 13.5 293C12.6716 293 12 292.328 12 291.5C12 290.672 12.6716 290 13.5 290C14.3284 290 15 290.672 15 291.5Z" fill="#D0D5DD" />
                                                <path d="M34 291.5C34 292.328 33.3284 293 32.5 293C31.6716 293 31 292.328 31 291.5C31 290.672 31.6716 290 32.5 290C33.3284 290 34 290.672 34 291.5Z" fill="#D0D5DD" />
                                                <path d="M53 291.5C53 292.328 52.3284 293 51.5 293C50.6716 293 50 292.328 50 291.5C50 290.672 50.6716 290 51.5 290C52.3284 290 53 290.672 53 291.5Z" fill="#D0D5DD" />
                                                <path d="M72 291.5C72 292.328 71.3284 293 70.5 293C69.6716 293 69 292.328 69 291.5C69 290.672 69.6716 290 70.5 290C71.3284 290 72 290.672 72 291.5Z" fill="#D0D5DD" />
                                                <path d="M91 291.5C91 292.328 90.3284 293 89.5 293C88.6716 293 88 292.328 88 291.5C88 290.672 88.6716 290 89.5 290C90.3284 290 91 290.672 91 291.5Z" fill="#D0D5DD" />
                                                <path d="M110 291.5C110 292.328 109.328 293 108.5 293C107.672 293 107 292.328 107 291.5C107 290.672 107.672 290 108.5 290C109.328 290 110 290.672 110 291.5Z" fill="#D0D5DD" />
                                                <path d="M129 291.5C129 292.328 128.328 293 127.5 293C126.672 293 126 292.328 126 291.5C126 290.672 126.672 290 127.5 290C128.328 290 129 290.672 129 291.5Z" fill="#D0D5DD" />
                                                <path d="M148 291.5C148 292.328 147.328 293 146.5 293C145.672 293 145 292.328 145 291.5C145 290.672 145.672 290 146.5 290C147.328 290 148 290.672 148 291.5Z" fill="#D0D5DD" />
                                                <path d="M167 291.5C167 292.328 166.328 293 165.5 293C164.672 293 164 292.328 164 291.5C164 290.672 164.672 290 165.5 290C166.328 290 167 290.672 167 291.5Z" fill="#D0D5DD" />
                                                <path d="M186 291.5C186 292.328 185.328 293 184.5 293C183.672 293 183 292.328 183 291.5C183 290.672 183.672 290 184.5 290C185.328 290 186 290.672 186 291.5Z" fill="#D0D5DD" />
                                                <path d="M205 291.5C205 292.328 204.328 293 203.5 293C202.672 293 202 292.328 202 291.5C202 290.672 202.672 290 203.5 290C204.328 290 205 290.672 205 291.5Z" fill="#D0D5DD" />
                                                <path d="M224 291.5C224 292.328 223.328 293 222.5 293C221.672 293 221 292.328 221 291.5C221 290.672 221.672 290 222.5 290C223.328 290 224 290.672 224 291.5Z" fill="#D0D5DD" />
                                                <path d="M243 291.5C243 292.328 242.328 293 241.5 293C240.672 293 240 292.328 240 291.5C240 290.672 240.672 290 241.5 290C242.328 290 243 290.672 243 291.5Z" fill="#D0D5DD" />
                                                <path d="M262 291.5C262 292.328 261.328 293 260.5 293C259.672 293 259 292.328 259 291.5C259 290.672 259.672 290 260.5 290C261.328 290 262 290.672 262 291.5Z" fill="#D0D5DD" />
                                                <path d="M281 291.5C281 292.328 280.328 293 279.5 293C278.672 293 278 292.328 278 291.5C278 290.672 278.672 290 279.5 290C280.328 290 281 290.672 281 291.5Z" fill="#D0D5DD" />
                                                <path d="M300 291.5C300 292.328 299.328 293 298.5 293C297.672 293 297 292.328 297 291.5C297 290.672 297.672 290 298.5 290C299.328 290 300 290.672 300 291.5Z" fill="#D0D5DD" />
                                                <path d="M-23 310.5C-23 311.328 -23.6716 312 -24.5 312C-25.3284 312 -26 311.328 -26 310.5C-26 309.672 -25.3284 309 -24.5 309C-23.6716 309 -23 309.672 -23 310.5Z" fill="#D0D5DD" />
                                                <path d="M-4 310.5C-4 311.328 -4.67157 312 -5.5 312C-6.32843 312 -7 311.328 -7 310.5C-7 309.672 -6.32843 309 -5.5 309C-4.67157 309 -4 309.672 -4 310.5Z" fill="#D0D5DD" />
                                                <path d="M15 310.5C15 311.328 14.3284 312 13.5 312C12.6716 312 12 311.328 12 310.5C12 309.672 12.6716 309 13.5 309C14.3284 309 15 309.672 15 310.5Z" fill="#D0D5DD" />
                                                <path d="M34 310.5C34 311.328 33.3284 312 32.5 312C31.6716 312 31 311.328 31 310.5C31 309.672 31.6716 309 32.5 309C33.3284 309 34 309.672 34 310.5Z" fill="#D0D5DD" />
                                                <path d="M53 310.5C53 311.328 52.3284 312 51.5 312C50.6716 312 50 311.328 50 310.5C50 309.672 50.6716 309 51.5 309C52.3284 309 53 309.672 53 310.5Z" fill="#D0D5DD" />
                                                <path d="M72 310.5C72 311.328 71.3284 312 70.5 312C69.6716 312 69 311.328 69 310.5C69 309.672 69.6716 309 70.5 309C71.3284 309 72 309.672 72 310.5Z" fill="#D0D5DD" />
                                                <path d="M91 310.5C91 311.328 90.3284 312 89.5 312C88.6716 312 88 311.328 88 310.5C88 309.672 88.6716 309 89.5 309C90.3284 309 91 309.672 91 310.5Z" fill="#D0D5DD" />
                                                <path d="M110 310.5C110 311.328 109.328 312 108.5 312C107.672 312 107 311.328 107 310.5C107 309.672 107.672 309 108.5 309C109.328 309 110 309.672 110 310.5Z" fill="#D0D5DD" />
                                                <path d="M129 310.5C129 311.328 128.328 312 127.5 312C126.672 312 126 311.328 126 310.5C126 309.672 126.672 309 127.5 309C128.328 309 129 309.672 129 310.5Z" fill="#D0D5DD" />
                                                <path d="M148 310.5C148 311.328 147.328 312 146.5 312C145.672 312 145 311.328 145 310.5C145 309.672 145.672 309 146.5 309C147.328 309 148 309.672 148 310.5Z" fill="#D0D5DD" />
                                                <path d="M167 310.5C167 311.328 166.328 312 165.5 312C164.672 312 164 311.328 164 310.5C164 309.672 164.672 309 165.5 309C166.328 309 167 309.672 167 310.5Z" fill="#D0D5DD" />
                                                <path d="M186 310.5C186 311.328 185.328 312 184.5 312C183.672 312 183 311.328 183 310.5C183 309.672 183.672 309 184.5 309C185.328 309 186 309.672 186 310.5Z" fill="#D0D5DD" />
                                                <path d="M205 310.5C205 311.328 204.328 312 203.5 312C202.672 312 202 311.328 202 310.5C202 309.672 202.672 309 203.5 309C204.328 309 205 309.672 205 310.5Z" fill="#D0D5DD" />
                                                <path d="M224 310.5C224 311.328 223.328 312 222.5 312C221.672 312 221 311.328 221 310.5C221 309.672 221.672 309 222.5 309C223.328 309 224 309.672 224 310.5Z" fill="#D0D5DD" />
                                                <path d="M243 310.5C243 311.328 242.328 312 241.5 312C240.672 312 240 311.328 240 310.5C240 309.672 240.672 309 241.5 309C242.328 309 243 309.672 243 310.5Z" fill="#D0D5DD" />
                                                <path d="M262 310.5C262 311.328 261.328 312 260.5 312C259.672 312 259 311.328 259 310.5C259 309.672 259.672 309 260.5 309C261.328 309 262 309.672 262 310.5Z" fill="#D0D5DD" />
                                                <path d="M281 310.5C281 311.328 280.328 312 279.5 312C278.672 312 278 311.328 278 310.5C278 309.672 278.672 309 279.5 309C280.328 309 281 309.672 281 310.5Z" fill="#D0D5DD" />
                                                <path d="M300 310.5C300 311.328 299.328 312 298.5 312C297.672 312 297 311.328 297 310.5C297 309.672 297.672 309 298.5 309C299.328 309 300 309.672 300 310.5Z" fill="#D0D5DD" />
                                                <path d="M-23 329.5C-23 330.328 -23.6716 331 -24.5 331C-25.3284 331 -26 330.328 -26 329.5C-26 328.672 -25.3284 328 -24.5 328C-23.6716 328 -23 328.672 -23 329.5Z" fill="#D0D5DD" />
                                                <path d="M-4 329.5C-4 330.328 -4.67157 331 -5.5 331C-6.32843 331 -7 330.328 -7 329.5C-7 328.672 -6.32843 328 -5.5 328C-4.67157 328 -4 328.672 -4 329.5Z" fill="#D0D5DD" />
                                                <path d="M15 329.5C15 330.328 14.3284 331 13.5 331C12.6716 331 12 330.328 12 329.5C12 328.672 12.6716 328 13.5 328C14.3284 328 15 328.672 15 329.5Z" fill="#D0D5DD" />
                                                <path d="M34 329.5C34 330.328 33.3284 331 32.5 331C31.6716 331 31 330.328 31 329.5C31 328.672 31.6716 328 32.5 328C33.3284 328 34 328.672 34 329.5Z" fill="#D0D5DD" />
                                                <path d="M53 329.5C53 330.328 52.3284 331 51.5 331C50.6716 331 50 330.328 50 329.5C50 328.672 50.6716 328 51.5 328C52.3284 328 53 328.672 53 329.5Z" fill="#D0D5DD" />
                                                <path d="M72 329.5C72 330.328 71.3284 331 70.5 331C69.6716 331 69 330.328 69 329.5C69 328.672 69.6716 328 70.5 328C71.3284 328 72 328.672 72 329.5Z" fill="#D0D5DD" />
                                                <path d="M91 329.5C91 330.328 90.3284 331 89.5 331C88.6716 331 88 330.328 88 329.5C88 328.672 88.6716 328 89.5 328C90.3284 328 91 328.672 91 329.5Z" fill="#D0D5DD" />
                                                <path d="M110 329.5C110 330.328 109.328 331 108.5 331C107.672 331 107 330.328 107 329.5C107 328.672 107.672 328 108.5 328C109.328 328 110 328.672 110 329.5Z" fill="#D0D5DD" />
                                                <path d="M129 329.5C129 330.328 128.328 331 127.5 331C126.672 331 126 330.328 126 329.5C126 328.672 126.672 328 127.5 328C128.328 328 129 328.672 129 329.5Z" fill="#D0D5DD" />
                                                <path d="M148 329.5C148 330.328 147.328 331 146.5 331C145.672 331 145 330.328 145 329.5C145 328.672 145.672 328 146.5 328C147.328 328 148 328.672 148 329.5Z" fill="#D0D5DD" />
                                                <path d="M167 329.5C167 330.328 166.328 331 165.5 331C164.672 331 164 330.328 164 329.5C164 328.672 164.672 328 165.5 328C166.328 328 167 328.672 167 329.5Z" fill="#D0D5DD" />
                                                <path d="M186 329.5C186 330.328 185.328 331 184.5 331C183.672 331 183 330.328 183 329.5C183 328.672 183.672 328 184.5 328C185.328 328 186 328.672 186 329.5Z" fill="#D0D5DD" />
                                                <path d="M205 329.5C205 330.328 204.328 331 203.5 331C202.672 331 202 330.328 202 329.5C202 328.672 202.672 328 203.5 328C204.328 328 205 328.672 205 329.5Z" fill="#D0D5DD" />
                                                <path d="M224 329.5C224 330.328 223.328 331 222.5 331C221.672 331 221 330.328 221 329.5C221 328.672 221.672 328 222.5 328C223.328 328 224 328.672 224 329.5Z" fill="#D0D5DD" />
                                                <path d="M243 329.5C243 330.328 242.328 331 241.5 331C240.672 331 240 330.328 240 329.5C240 328.672 240.672 328 241.5 328C242.328 328 243 328.672 243 329.5Z" fill="#D0D5DD" />
                                                <path d="M262 329.5C262 330.328 261.328 331 260.5 331C259.672 331 259 330.328 259 329.5C259 328.672 259.672 328 260.5 328C261.328 328 262 328.672 262 329.5Z" fill="#D0D5DD" />
                                                <path d="M281 329.5C281 330.328 280.328 331 279.5 331C278.672 331 278 330.328 278 329.5C278 328.672 278.672 328 279.5 328C280.328 328 281 328.672 281 329.5Z" fill="#D0D5DD" />
                                                <path d="M300 329.5C300 330.328 299.328 331 298.5 331C297.672 331 297 330.328 297 329.5C297 328.672 297.672 328 298.5 328C299.328 328 300 328.672 300 329.5Z" fill="#D0D5DD" />
                                              </g>
                                            </g>
                                            <g clip-path="url(#clip1_8_103417)">
                                              <mask id="mask1_8_103417" style={{ "maskType": "alpha" }} maskUnits="userSpaceOnUse" x="139" y="57" width="336" height="336">
                                                <rect width="336" height="336" transform="translate(139 57)" fill="url(#paint1_radial_8_103417)" />
                                              </mask>
                                              <g mask="url(#mask1_8_103417)">
                                                <path d="M147 63.5C147 64.3284 146.328 65 145.5 65C144.672 65 144 64.3284 144 63.5C144 62.6716 144.672 62 145.5 62C146.328 62 147 62.6716 147 63.5Z" fill="#D0D5DD" />
                                                <path d="M166 63.5C166 64.3284 165.328 65 164.5 65C163.672 65 163 64.3284 163 63.5C163 62.6716 163.672 62 164.5 62C165.328 62 166 62.6716 166 63.5Z" fill="#D0D5DD" />
                                                <path d="M185 63.5C185 64.3284 184.328 65 183.5 65C182.672 65 182 64.3284 182 63.5C182 62.6716 182.672 62 183.5 62C184.328 62 185 62.6716 185 63.5Z" fill="#D0D5DD" />
                                                <path d="M204 63.5C204 64.3284 203.328 65 202.5 65C201.672 65 201 64.3284 201 63.5C201 62.6716 201.672 62 202.5 62C203.328 62 204 62.6716 204 63.5Z" fill="#D0D5DD" />
                                                <path d="M223 63.5C223 64.3284 222.328 65 221.5 65C220.672 65 220 64.3284 220 63.5C220 62.6716 220.672 62 221.5 62C222.328 62 223 62.6716 223 63.5Z" fill="#D0D5DD" />
                                                <path d="M242 63.5C242 64.3284 241.328 65 240.5 65C239.672 65 239 64.3284 239 63.5C239 62.6716 239.672 62 240.5 62C241.328 62 242 62.6716 242 63.5Z" fill="#D0D5DD" />
                                                <path d="M261 63.5C261 64.3284 260.328 65 259.5 65C258.672 65 258 64.3284 258 63.5C258 62.6716 258.672 62 259.5 62C260.328 62 261 62.6716 261 63.5Z" fill="#D0D5DD" />
                                                <path d="M280 63.5C280 64.3284 279.328 65 278.5 65C277.672 65 277 64.3284 277 63.5C277 62.6716 277.672 62 278.5 62C279.328 62 280 62.6716 280 63.5Z" fill="#D0D5DD" />
                                                <path d="M299 63.5C299 64.3284 298.328 65 297.5 65C296.672 65 296 64.3284 296 63.5C296 62.6716 296.672 62 297.5 62C298.328 62 299 62.6716 299 63.5Z" fill="#D0D5DD" />
                                                <path d="M318 63.5C318 64.3284 317.328 65 316.5 65C315.672 65 315 64.3284 315 63.5C315 62.6716 315.672 62 316.5 62C317.328 62 318 62.6716 318 63.5Z" fill="#D0D5DD" />
                                                <path d="M337 63.5C337 64.3284 336.328 65 335.5 65C334.672 65 334 64.3284 334 63.5C334 62.6716 334.672 62 335.5 62C336.328 62 337 62.6716 337 63.5Z" fill="#D0D5DD" />
                                                <path d="M356 63.5C356 64.3284 355.328 65 354.5 65C353.672 65 353 64.3284 353 63.5C353 62.6716 353.672 62 354.5 62C355.328 62 356 62.6716 356 63.5Z" fill="#D0D5DD" />
                                                <path d="M375 63.5C375 64.3284 374.328 65 373.5 65C372.672 65 372 64.3284 372 63.5C372 62.6716 372.672 62 373.5 62C374.328 62 375 62.6716 375 63.5Z" fill="#D0D5DD" />
                                                <path d="M394 63.5C394 64.3284 393.328 65 392.5 65C391.672 65 391 64.3284 391 63.5C391 62.6716 391.672 62 392.5 62C393.328 62 394 62.6716 394 63.5Z" fill="#D0D5DD" />
                                                <path d="M413 63.5C413 64.3284 412.328 65 411.5 65C410.672 65 410 64.3284 410 63.5C410 62.6716 410.672 62 411.5 62C412.328 62 413 62.6716 413 63.5Z" fill="#D0D5DD" />
                                                <path d="M432 63.5C432 64.3284 431.328 65 430.5 65C429.672 65 429 64.3284 429 63.5C429 62.6716 429.672 62 430.5 62C431.328 62 432 62.6716 432 63.5Z" fill="#D0D5DD" />
                                                <path d="M451 63.5C451 64.3284 450.328 65 449.5 65C448.672 65 448 64.3284 448 63.5C448 62.6716 448.672 62 449.5 62C450.328 62 451 62.6716 451 63.5Z" fill="#D0D5DD" />
                                                <path d="M470 63.5C470 64.3284 469.328 65 468.5 65C467.672 65 467 64.3284 467 63.5C467 62.6716 467.672 62 468.5 62C469.328 62 470 62.6716 470 63.5Z" fill="#D0D5DD" />
                                                <path d="M147 82.5C147 83.3284 146.328 84 145.5 84C144.672 84 144 83.3284 144 82.5C144 81.6716 144.672 81 145.5 81C146.328 81 147 81.6716 147 82.5Z" fill="#D0D5DD" />
                                                <path d="M166 82.5C166 83.3284 165.328 84 164.5 84C163.672 84 163 83.3284 163 82.5C163 81.6716 163.672 81 164.5 81C165.328 81 166 81.6716 166 82.5Z" fill="#D0D5DD" />
                                                <path d="M185 82.5C185 83.3284 184.328 84 183.5 84C182.672 84 182 83.3284 182 82.5C182 81.6716 182.672 81 183.5 81C184.328 81 185 81.6716 185 82.5Z" fill="#D0D5DD" />
                                                <path d="M204 82.5C204 83.3284 203.328 84 202.5 84C201.672 84 201 83.3284 201 82.5C201 81.6716 201.672 81 202.5 81C203.328 81 204 81.6716 204 82.5Z" fill="#D0D5DD" />
                                                <path d="M223 82.5C223 83.3284 222.328 84 221.5 84C220.672 84 220 83.3284 220 82.5C220 81.6716 220.672 81 221.5 81C222.328 81 223 81.6716 223 82.5Z" fill="#D0D5DD" />
                                                <path d="M242 82.5C242 83.3284 241.328 84 240.5 84C239.672 84 239 83.3284 239 82.5C239 81.6716 239.672 81 240.5 81C241.328 81 242 81.6716 242 82.5Z" fill="#D0D5DD" />
                                                <path d="M261 82.5C261 83.3284 260.328 84 259.5 84C258.672 84 258 83.3284 258 82.5C258 81.6716 258.672 81 259.5 81C260.328 81 261 81.6716 261 82.5Z" fill="#D0D5DD" />
                                                <path d="M280 82.5C280 83.3284 279.328 84 278.5 84C277.672 84 277 83.3284 277 82.5C277 81.6716 277.672 81 278.5 81C279.328 81 280 81.6716 280 82.5Z" fill="#D0D5DD" />
                                                <path d="M299 82.5C299 83.3284 298.328 84 297.5 84C296.672 84 296 83.3284 296 82.5C296 81.6716 296.672 81 297.5 81C298.328 81 299 81.6716 299 82.5Z" fill="#D0D5DD" />
                                                <path d="M318 82.5C318 83.3284 317.328 84 316.5 84C315.672 84 315 83.3284 315 82.5C315 81.6716 315.672 81 316.5 81C317.328 81 318 81.6716 318 82.5Z" fill="#D0D5DD" />
                                                <path d="M337 82.5C337 83.3284 336.328 84 335.5 84C334.672 84 334 83.3284 334 82.5C334 81.6716 334.672 81 335.5 81C336.328 81 337 81.6716 337 82.5Z" fill="#D0D5DD" />
                                                <path d="M356 82.5C356 83.3284 355.328 84 354.5 84C353.672 84 353 83.3284 353 82.5C353 81.6716 353.672 81 354.5 81C355.328 81 356 81.6716 356 82.5Z" fill="#D0D5DD" />
                                                <path d="M375 82.5C375 83.3284 374.328 84 373.5 84C372.672 84 372 83.3284 372 82.5C372 81.6716 372.672 81 373.5 81C374.328 81 375 81.6716 375 82.5Z" fill="#D0D5DD" />
                                                <path d="M394 82.5C394 83.3284 393.328 84 392.5 84C391.672 84 391 83.3284 391 82.5C391 81.6716 391.672 81 392.5 81C393.328 81 394 81.6716 394 82.5Z" fill="#D0D5DD" />
                                                <path d="M413 82.5C413 83.3284 412.328 84 411.5 84C410.672 84 410 83.3284 410 82.5C410 81.6716 410.672 81 411.5 81C412.328 81 413 81.6716 413 82.5Z" fill="#D0D5DD" />
                                                <path d="M432 82.5C432 83.3284 431.328 84 430.5 84C429.672 84 429 83.3284 429 82.5C429 81.6716 429.672 81 430.5 81C431.328 81 432 81.6716 432 82.5Z" fill="#D0D5DD" />
                                                <path d="M451 82.5C451 83.3284 450.328 84 449.5 84C448.672 84 448 83.3284 448 82.5C448 81.6716 448.672 81 449.5 81C450.328 81 451 81.6716 451 82.5Z" fill="#D0D5DD" />
                                                <path d="M470 82.5C470 83.3284 469.328 84 468.5 84C467.672 84 467 83.3284 467 82.5C467 81.6716 467.672 81 468.5 81C469.328 81 470 81.6716 470 82.5Z" fill="#D0D5DD" />
                                                <path d="M147 101.5C147 102.328 146.328 103 145.5 103C144.672 103 144 102.328 144 101.5C144 100.672 144.672 100 145.5 100C146.328 100 147 100.672 147 101.5Z" fill="#D0D5DD" />
                                                <path d="M166 101.5C166 102.328 165.328 103 164.5 103C163.672 103 163 102.328 163 101.5C163 100.672 163.672 100 164.5 100C165.328 100 166 100.672 166 101.5Z" fill="#D0D5DD" />
                                                <path d="M185 101.5C185 102.328 184.328 103 183.5 103C182.672 103 182 102.328 182 101.5C182 100.672 182.672 100 183.5 100C184.328 100 185 100.672 185 101.5Z" fill="#D0D5DD" />
                                                <path d="M204 101.5C204 102.328 203.328 103 202.5 103C201.672 103 201 102.328 201 101.5C201 100.672 201.672 100 202.5 100C203.328 100 204 100.672 204 101.5Z" fill="#D0D5DD" />
                                                <path d="M223 101.5C223 102.328 222.328 103 221.5 103C220.672 103 220 102.328 220 101.5C220 100.672 220.672 100 221.5 100C222.328 100 223 100.672 223 101.5Z" fill="#D0D5DD" />
                                                <path d="M242 101.5C242 102.328 241.328 103 240.5 103C239.672 103 239 102.328 239 101.5C239 100.672 239.672 100 240.5 100C241.328 100 242 100.672 242 101.5Z" fill="#D0D5DD" />
                                                <path d="M261 101.5C261 102.328 260.328 103 259.5 103C258.672 103 258 102.328 258 101.5C258 100.672 258.672 100 259.5 100C260.328 100 261 100.672 261 101.5Z" fill="#D0D5DD" />
                                                <path d="M280 101.5C280 102.328 279.328 103 278.5 103C277.672 103 277 102.328 277 101.5C277 100.672 277.672 100 278.5 100C279.328 100 280 100.672 280 101.5Z" fill="#D0D5DD" />
                                                <path d="M299 101.5C299 102.328 298.328 103 297.5 103C296.672 103 296 102.328 296 101.5C296 100.672 296.672 100 297.5 100C298.328 100 299 100.672 299 101.5Z" fill="#D0D5DD" />
                                                <path d="M318 101.5C318 102.328 317.328 103 316.5 103C315.672 103 315 102.328 315 101.5C315 100.672 315.672 100 316.5 100C317.328 100 318 100.672 318 101.5Z" fill="#D0D5DD" />
                                                <path d="M337 101.5C337 102.328 336.328 103 335.5 103C334.672 103 334 102.328 334 101.5C334 100.672 334.672 100 335.5 100C336.328 100 337 100.672 337 101.5Z" fill="#D0D5DD" />
                                                <path d="M356 101.5C356 102.328 355.328 103 354.5 103C353.672 103 353 102.328 353 101.5C353 100.672 353.672 100 354.5 100C355.328 100 356 100.672 356 101.5Z" fill="#D0D5DD" />
                                                <path d="M375 101.5C375 102.328 374.328 103 373.5 103C372.672 103 372 102.328 372 101.5C372 100.672 372.672 100 373.5 100C374.328 100 375 100.672 375 101.5Z" fill="#D0D5DD" />
                                                <path d="M394 101.5C394 102.328 393.328 103 392.5 103C391.672 103 391 102.328 391 101.5C391 100.672 391.672 100 392.5 100C393.328 100 394 100.672 394 101.5Z" fill="#D0D5DD" />
                                                <path d="M413 101.5C413 102.328 412.328 103 411.5 103C410.672 103 410 102.328 410 101.5C410 100.672 410.672 100 411.5 100C412.328 100 413 100.672 413 101.5Z" fill="#D0D5DD" />
                                                <path d="M432 101.5C432 102.328 431.328 103 430.5 103C429.672 103 429 102.328 429 101.5C429 100.672 429.672 100 430.5 100C431.328 100 432 100.672 432 101.5Z" fill="#D0D5DD" />
                                                <path d="M451 101.5C451 102.328 450.328 103 449.5 103C448.672 103 448 102.328 448 101.5C448 100.672 448.672 100 449.5 100C450.328 100 451 100.672 451 101.5Z" fill="#D0D5DD" />
                                                <path d="M470 101.5C470 102.328 469.328 103 468.5 103C467.672 103 467 102.328 467 101.5C467 100.672 467.672 100 468.5 100C469.328 100 470 100.672 470 101.5Z" fill="#D0D5DD" />
                                                <path d="M147 120.5C147 121.328 146.328 122 145.5 122C144.672 122 144 121.328 144 120.5C144 119.672 144.672 119 145.5 119C146.328 119 147 119.672 147 120.5Z" fill="#D0D5DD" />
                                                <path d="M166 120.5C166 121.328 165.328 122 164.5 122C163.672 122 163 121.328 163 120.5C163 119.672 163.672 119 164.5 119C165.328 119 166 119.672 166 120.5Z" fill="#D0D5DD" />
                                                <path d="M185 120.5C185 121.328 184.328 122 183.5 122C182.672 122 182 121.328 182 120.5C182 119.672 182.672 119 183.5 119C184.328 119 185 119.672 185 120.5Z" fill="#D0D5DD" />
                                                <path d="M204 120.5C204 121.328 203.328 122 202.5 122C201.672 122 201 121.328 201 120.5C201 119.672 201.672 119 202.5 119C203.328 119 204 119.672 204 120.5Z" fill="#D0D5DD" />
                                                <path d="M223 120.5C223 121.328 222.328 122 221.5 122C220.672 122 220 121.328 220 120.5C220 119.672 220.672 119 221.5 119C222.328 119 223 119.672 223 120.5Z" fill="#D0D5DD" />
                                                <path d="M242 120.5C242 121.328 241.328 122 240.5 122C239.672 122 239 121.328 239 120.5C239 119.672 239.672 119 240.5 119C241.328 119 242 119.672 242 120.5Z" fill="#D0D5DD" />
                                                <path d="M261 120.5C261 121.328 260.328 122 259.5 122C258.672 122 258 121.328 258 120.5C258 119.672 258.672 119 259.5 119C260.328 119 261 119.672 261 120.5Z" fill="#D0D5DD" />
                                                <path d="M280 120.5C280 121.328 279.328 122 278.5 122C277.672 122 277 121.328 277 120.5C277 119.672 277.672 119 278.5 119C279.328 119 280 119.672 280 120.5Z" fill="#D0D5DD" />
                                                <path d="M299 120.5C299 121.328 298.328 122 297.5 122C296.672 122 296 121.328 296 120.5C296 119.672 296.672 119 297.5 119C298.328 119 299 119.672 299 120.5Z" fill="#D0D5DD" />
                                                <path d="M318 120.5C318 121.328 317.328 122 316.5 122C315.672 122 315 121.328 315 120.5C315 119.672 315.672 119 316.5 119C317.328 119 318 119.672 318 120.5Z" fill="#D0D5DD" />
                                                <path d="M337 120.5C337 121.328 336.328 122 335.5 122C334.672 122 334 121.328 334 120.5C334 119.672 334.672 119 335.5 119C336.328 119 337 119.672 337 120.5Z" fill="#D0D5DD" />
                                                <path d="M356 120.5C356 121.328 355.328 122 354.5 122C353.672 122 353 121.328 353 120.5C353 119.672 353.672 119 354.5 119C355.328 119 356 119.672 356 120.5Z" fill="#D0D5DD" />
                                                <path d="M375 120.5C375 121.328 374.328 122 373.5 122C372.672 122 372 121.328 372 120.5C372 119.672 372.672 119 373.5 119C374.328 119 375 119.672 375 120.5Z" fill="#D0D5DD" />
                                                <path d="M394 120.5C394 121.328 393.328 122 392.5 122C391.672 122 391 121.328 391 120.5C391 119.672 391.672 119 392.5 119C393.328 119 394 119.672 394 120.5Z" fill="#D0D5DD" />
                                                <path d="M413 120.5C413 121.328 412.328 122 411.5 122C410.672 122 410 121.328 410 120.5C410 119.672 410.672 119 411.5 119C412.328 119 413 119.672 413 120.5Z" fill="#D0D5DD" />
                                                <path d="M432 120.5C432 121.328 431.328 122 430.5 122C429.672 122 429 121.328 429 120.5C429 119.672 429.672 119 430.5 119C431.328 119 432 119.672 432 120.5Z" fill="#D0D5DD" />
                                                <path d="M451 120.5C451 121.328 450.328 122 449.5 122C448.672 122 448 121.328 448 120.5C448 119.672 448.672 119 449.5 119C450.328 119 451 119.672 451 120.5Z" fill="#D0D5DD" />
                                                <path d="M470 120.5C470 121.328 469.328 122 468.5 122C467.672 122 467 121.328 467 120.5C467 119.672 467.672 119 468.5 119C469.328 119 470 119.672 470 120.5Z" fill="#D0D5DD" />
                                                <path d="M147 139.5C147 140.328 146.328 141 145.5 141C144.672 141 144 140.328 144 139.5C144 138.672 144.672 138 145.5 138C146.328 138 147 138.672 147 139.5Z" fill="#D0D5DD" />
                                                <path d="M166 139.5C166 140.328 165.328 141 164.5 141C163.672 141 163 140.328 163 139.5C163 138.672 163.672 138 164.5 138C165.328 138 166 138.672 166 139.5Z" fill="#D0D5DD" />
                                                <path d="M185 139.5C185 140.328 184.328 141 183.5 141C182.672 141 182 140.328 182 139.5C182 138.672 182.672 138 183.5 138C184.328 138 185 138.672 185 139.5Z" fill="#D0D5DD" />
                                                <path d="M204 139.5C204 140.328 203.328 141 202.5 141C201.672 141 201 140.328 201 139.5C201 138.672 201.672 138 202.5 138C203.328 138 204 138.672 204 139.5Z" fill="#D0D5DD" />
                                                <path d="M223 139.5C223 140.328 222.328 141 221.5 141C220.672 141 220 140.328 220 139.5C220 138.672 220.672 138 221.5 138C222.328 138 223 138.672 223 139.5Z" fill="#D0D5DD" />
                                                <path d="M242 139.5C242 140.328 241.328 141 240.5 141C239.672 141 239 140.328 239 139.5C239 138.672 239.672 138 240.5 138C241.328 138 242 138.672 242 139.5Z" fill="#D0D5DD" />
                                                <path d="M261 139.5C261 140.328 260.328 141 259.5 141C258.672 141 258 140.328 258 139.5C258 138.672 258.672 138 259.5 138C260.328 138 261 138.672 261 139.5Z" fill="#D0D5DD" />
                                                <path d="M280 139.5C280 140.328 279.328 141 278.5 141C277.672 141 277 140.328 277 139.5C277 138.672 277.672 138 278.5 138C279.328 138 280 138.672 280 139.5Z" fill="#D0D5DD" />
                                                <path d="M299 139.5C299 140.328 298.328 141 297.5 141C296.672 141 296 140.328 296 139.5C296 138.672 296.672 138 297.5 138C298.328 138 299 138.672 299 139.5Z" fill="#D0D5DD" />
                                                <path d="M318 139.5C318 140.328 317.328 141 316.5 141C315.672 141 315 140.328 315 139.5C315 138.672 315.672 138 316.5 138C317.328 138 318 138.672 318 139.5Z" fill="#D0D5DD" />
                                                <path d="M337 139.5C337 140.328 336.328 141 335.5 141C334.672 141 334 140.328 334 139.5C334 138.672 334.672 138 335.5 138C336.328 138 337 138.672 337 139.5Z" fill="#D0D5DD" />
                                                <path d="M356 139.5C356 140.328 355.328 141 354.5 141C353.672 141 353 140.328 353 139.5C353 138.672 353.672 138 354.5 138C355.328 138 356 138.672 356 139.5Z" fill="#D0D5DD" />
                                                <path d="M375 139.5C375 140.328 374.328 141 373.5 141C372.672 141 372 140.328 372 139.5C372 138.672 372.672 138 373.5 138C374.328 138 375 138.672 375 139.5Z" fill="#D0D5DD" />
                                                <path d="M394 139.5C394 140.328 393.328 141 392.5 141C391.672 141 391 140.328 391 139.5C391 138.672 391.672 138 392.5 138C393.328 138 394 138.672 394 139.5Z" fill="#D0D5DD" />
                                                <path d="M413 139.5C413 140.328 412.328 141 411.5 141C410.672 141 410 140.328 410 139.5C410 138.672 410.672 138 411.5 138C412.328 138 413 138.672 413 139.5Z" fill="#D0D5DD" />
                                                <path d="M432 139.5C432 140.328 431.328 141 430.5 141C429.672 141 429 140.328 429 139.5C429 138.672 429.672 138 430.5 138C431.328 138 432 138.672 432 139.5Z" fill="#D0D5DD" />
                                                <path d="M451 139.5C451 140.328 450.328 141 449.5 141C448.672 141 448 140.328 448 139.5C448 138.672 448.672 138 449.5 138C450.328 138 451 138.672 451 139.5Z" fill="#D0D5DD" />
                                                <path d="M470 139.5C470 140.328 469.328 141 468.5 141C467.672 141 467 140.328 467 139.5C467 138.672 467.672 138 468.5 138C469.328 138 470 138.672 470 139.5Z" fill="#D0D5DD" />
                                                <path d="M147 158.5C147 159.328 146.328 160 145.5 160C144.672 160 144 159.328 144 158.5C144 157.672 144.672 157 145.5 157C146.328 157 147 157.672 147 158.5Z" fill="#D0D5DD" />
                                                <path d="M166 158.5C166 159.328 165.328 160 164.5 160C163.672 160 163 159.328 163 158.5C163 157.672 163.672 157 164.5 157C165.328 157 166 157.672 166 158.5Z" fill="#D0D5DD" />
                                                <path d="M185 158.5C185 159.328 184.328 160 183.5 160C182.672 160 182 159.328 182 158.5C182 157.672 182.672 157 183.5 157C184.328 157 185 157.672 185 158.5Z" fill="#D0D5DD" />
                                                <path d="M204 158.5C204 159.328 203.328 160 202.5 160C201.672 160 201 159.328 201 158.5C201 157.672 201.672 157 202.5 157C203.328 157 204 157.672 204 158.5Z" fill="#D0D5DD" />
                                                <path d="M223 158.5C223 159.328 222.328 160 221.5 160C220.672 160 220 159.328 220 158.5C220 157.672 220.672 157 221.5 157C222.328 157 223 157.672 223 158.5Z" fill="#D0D5DD" />
                                                <path d="M242 158.5C242 159.328 241.328 160 240.5 160C239.672 160 239 159.328 239 158.5C239 157.672 239.672 157 240.5 157C241.328 157 242 157.672 242 158.5Z" fill="#D0D5DD" />
                                                <path d="M261 158.5C261 159.328 260.328 160 259.5 160C258.672 160 258 159.328 258 158.5C258 157.672 258.672 157 259.5 157C260.328 157 261 157.672 261 158.5Z" fill="#D0D5DD" />
                                                <path d="M280 158.5C280 159.328 279.328 160 278.5 160C277.672 160 277 159.328 277 158.5C277 157.672 277.672 157 278.5 157C279.328 157 280 157.672 280 158.5Z" fill="#D0D5DD" />
                                                <path d="M299 158.5C299 159.328 298.328 160 297.5 160C296.672 160 296 159.328 296 158.5C296 157.672 296.672 157 297.5 157C298.328 157 299 157.672 299 158.5Z" fill="#D0D5DD" />
                                                <path d="M318 158.5C318 159.328 317.328 160 316.5 160C315.672 160 315 159.328 315 158.5C315 157.672 315.672 157 316.5 157C317.328 157 318 157.672 318 158.5Z" fill="#D0D5DD" />
                                                <path d="M337 158.5C337 159.328 336.328 160 335.5 160C334.672 160 334 159.328 334 158.5C334 157.672 334.672 157 335.5 157C336.328 157 337 157.672 337 158.5Z" fill="#D0D5DD" />
                                                <path d="M356 158.5C356 159.328 355.328 160 354.5 160C353.672 160 353 159.328 353 158.5C353 157.672 353.672 157 354.5 157C355.328 157 356 157.672 356 158.5Z" fill="#D0D5DD" />
                                                <path d="M375 158.5C375 159.328 374.328 160 373.5 160C372.672 160 372 159.328 372 158.5C372 157.672 372.672 157 373.5 157C374.328 157 375 157.672 375 158.5Z" fill="#D0D5DD" />
                                                <path d="M394 158.5C394 159.328 393.328 160 392.5 160C391.672 160 391 159.328 391 158.5C391 157.672 391.672 157 392.5 157C393.328 157 394 157.672 394 158.5Z" fill="#D0D5DD" />
                                                <path d="M413 158.5C413 159.328 412.328 160 411.5 160C410.672 160 410 159.328 410 158.5C410 157.672 410.672 157 411.5 157C412.328 157 413 157.672 413 158.5Z" fill="#D0D5DD" />
                                                <path d="M432 158.5C432 159.328 431.328 160 430.5 160C429.672 160 429 159.328 429 158.5C429 157.672 429.672 157 430.5 157C431.328 157 432 157.672 432 158.5Z" fill="#D0D5DD" />
                                                <path d="M451 158.5C451 159.328 450.328 160 449.5 160C448.672 160 448 159.328 448 158.5C448 157.672 448.672 157 449.5 157C450.328 157 451 157.672 451 158.5Z" fill="#D0D5DD" />
                                                <path d="M470 158.5C470 159.328 469.328 160 468.5 160C467.672 160 467 159.328 467 158.5C467 157.672 467.672 157 468.5 157C469.328 157 470 157.672 470 158.5Z" fill="#D0D5DD" />
                                                <path d="M147 177.5C147 178.328 146.328 179 145.5 179C144.672 179 144 178.328 144 177.5C144 176.672 144.672 176 145.5 176C146.328 176 147 176.672 147 177.5Z" fill="#D0D5DD" />
                                                <path d="M166 177.5C166 178.328 165.328 179 164.5 179C163.672 179 163 178.328 163 177.5C163 176.672 163.672 176 164.5 176C165.328 176 166 176.672 166 177.5Z" fill="#D0D5DD" />
                                                <path d="M185 177.5C185 178.328 184.328 179 183.5 179C182.672 179 182 178.328 182 177.5C182 176.672 182.672 176 183.5 176C184.328 176 185 176.672 185 177.5Z" fill="#D0D5DD" />
                                                <path d="M204 177.5C204 178.328 203.328 179 202.5 179C201.672 179 201 178.328 201 177.5C201 176.672 201.672 176 202.5 176C203.328 176 204 176.672 204 177.5Z" fill="#D0D5DD" />
                                                <path d="M223 177.5C223 178.328 222.328 179 221.5 179C220.672 179 220 178.328 220 177.5C220 176.672 220.672 176 221.5 176C222.328 176 223 176.672 223 177.5Z" fill="#D0D5DD" />
                                                <path d="M242 177.5C242 178.328 241.328 179 240.5 179C239.672 179 239 178.328 239 177.5C239 176.672 239.672 176 240.5 176C241.328 176 242 176.672 242 177.5Z" fill="#D0D5DD" />
                                                <path d="M261 177.5C261 178.328 260.328 179 259.5 179C258.672 179 258 178.328 258 177.5C258 176.672 258.672 176 259.5 176C260.328 176 261 176.672 261 177.5Z" fill="#D0D5DD" />
                                                <path d="M280 177.5C280 178.328 279.328 179 278.5 179C277.672 179 277 178.328 277 177.5C277 176.672 277.672 176 278.5 176C279.328 176 280 176.672 280 177.5Z" fill="#D0D5DD" />
                                                <path d="M299 177.5C299 178.328 298.328 179 297.5 179C296.672 179 296 178.328 296 177.5C296 176.672 296.672 176 297.5 176C298.328 176 299 176.672 299 177.5Z" fill="#D0D5DD" />
                                                <path d="M318 177.5C318 178.328 317.328 179 316.5 179C315.672 179 315 178.328 315 177.5C315 176.672 315.672 176 316.5 176C317.328 176 318 176.672 318 177.5Z" fill="#D0D5DD" />
                                                <path d="M337 177.5C337 178.328 336.328 179 335.5 179C334.672 179 334 178.328 334 177.5C334 176.672 334.672 176 335.5 176C336.328 176 337 176.672 337 177.5Z" fill="#D0D5DD" />
                                                <path d="M356 177.5C356 178.328 355.328 179 354.5 179C353.672 179 353 178.328 353 177.5C353 176.672 353.672 176 354.5 176C355.328 176 356 176.672 356 177.5Z" fill="#D0D5DD" />
                                                <path d="M375 177.5C375 178.328 374.328 179 373.5 179C372.672 179 372 178.328 372 177.5C372 176.672 372.672 176 373.5 176C374.328 176 375 176.672 375 177.5Z" fill="#D0D5DD" />
                                                <path d="M394 177.5C394 178.328 393.328 179 392.5 179C391.672 179 391 178.328 391 177.5C391 176.672 391.672 176 392.5 176C393.328 176 394 176.672 394 177.5Z" fill="#D0D5DD" />
                                                <path d="M413 177.5C413 178.328 412.328 179 411.5 179C410.672 179 410 178.328 410 177.5C410 176.672 410.672 176 411.5 176C412.328 176 413 176.672 413 177.5Z" fill="#D0D5DD" />
                                                <path d="M432 177.5C432 178.328 431.328 179 430.5 179C429.672 179 429 178.328 429 177.5C429 176.672 429.672 176 430.5 176C431.328 176 432 176.672 432 177.5Z" fill="#D0D5DD" />
                                                <path d="M451 177.5C451 178.328 450.328 179 449.5 179C448.672 179 448 178.328 448 177.5C448 176.672 448.672 176 449.5 176C450.328 176 451 176.672 451 177.5Z" fill="#D0D5DD" />
                                                <path d="M470 177.5C470 178.328 469.328 179 468.5 179C467.672 179 467 178.328 467 177.5C467 176.672 467.672 176 468.5 176C469.328 176 470 176.672 470 177.5Z" fill="#D0D5DD" />
                                                <path d="M147 196.5C147 197.328 146.328 198 145.5 198C144.672 198 144 197.328 144 196.5C144 195.672 144.672 195 145.5 195C146.328 195 147 195.672 147 196.5Z" fill="#D0D5DD" />
                                                <path d="M166 196.5C166 197.328 165.328 198 164.5 198C163.672 198 163 197.328 163 196.5C163 195.672 163.672 195 164.5 195C165.328 195 166 195.672 166 196.5Z" fill="#D0D5DD" />
                                                <path d="M185 196.5C185 197.328 184.328 198 183.5 198C182.672 198 182 197.328 182 196.5C182 195.672 182.672 195 183.5 195C184.328 195 185 195.672 185 196.5Z" fill="#D0D5DD" />
                                                <path d="M204 196.5C204 197.328 203.328 198 202.5 198C201.672 198 201 197.328 201 196.5C201 195.672 201.672 195 202.5 195C203.328 195 204 195.672 204 196.5Z" fill="#D0D5DD" />
                                                <path d="M223 196.5C223 197.328 222.328 198 221.5 198C220.672 198 220 197.328 220 196.5C220 195.672 220.672 195 221.5 195C222.328 195 223 195.672 223 196.5Z" fill="#D0D5DD" />
                                                <path d="M242 196.5C242 197.328 241.328 198 240.5 198C239.672 198 239 197.328 239 196.5C239 195.672 239.672 195 240.5 195C241.328 195 242 195.672 242 196.5Z" fill="#D0D5DD" />
                                                <path d="M261 196.5C261 197.328 260.328 198 259.5 198C258.672 198 258 197.328 258 196.5C258 195.672 258.672 195 259.5 195C260.328 195 261 195.672 261 196.5Z" fill="#D0D5DD" />
                                                <path d="M280 196.5C280 197.328 279.328 198 278.5 198C277.672 198 277 197.328 277 196.5C277 195.672 277.672 195 278.5 195C279.328 195 280 195.672 280 196.5Z" fill="#D0D5DD" />
                                                <path d="M299 196.5C299 197.328 298.328 198 297.5 198C296.672 198 296 197.328 296 196.5C296 195.672 296.672 195 297.5 195C298.328 195 299 195.672 299 196.5Z" fill="#D0D5DD" />
                                                <path d="M318 196.5C318 197.328 317.328 198 316.5 198C315.672 198 315 197.328 315 196.5C315 195.672 315.672 195 316.5 195C317.328 195 318 195.672 318 196.5Z" fill="#D0D5DD" />
                                                <path d="M337 196.5C337 197.328 336.328 198 335.5 198C334.672 198 334 197.328 334 196.5C334 195.672 334.672 195 335.5 195C336.328 195 337 195.672 337 196.5Z" fill="#D0D5DD" />
                                                <path d="M356 196.5C356 197.328 355.328 198 354.5 198C353.672 198 353 197.328 353 196.5C353 195.672 353.672 195 354.5 195C355.328 195 356 195.672 356 196.5Z" fill="#D0D5DD" />
                                                <path d="M375 196.5C375 197.328 374.328 198 373.5 198C372.672 198 372 197.328 372 196.5C372 195.672 372.672 195 373.5 195C374.328 195 375 195.672 375 196.5Z" fill="#D0D5DD" />
                                                <path d="M394 196.5C394 197.328 393.328 198 392.5 198C391.672 198 391 197.328 391 196.5C391 195.672 391.672 195 392.5 195C393.328 195 394 195.672 394 196.5Z" fill="#D0D5DD" />
                                                <path d="M413 196.5C413 197.328 412.328 198 411.5 198C410.672 198 410 197.328 410 196.5C410 195.672 410.672 195 411.5 195C412.328 195 413 195.672 413 196.5Z" fill="#D0D5DD" />
                                                <path d="M432 196.5C432 197.328 431.328 198 430.5 198C429.672 198 429 197.328 429 196.5C429 195.672 429.672 195 430.5 195C431.328 195 432 195.672 432 196.5Z" fill="#D0D5DD" />
                                                <path d="M451 196.5C451 197.328 450.328 198 449.5 198C448.672 198 448 197.328 448 196.5C448 195.672 448.672 195 449.5 195C450.328 195 451 195.672 451 196.5Z" fill="#D0D5DD" />
                                                <path d="M470 196.5C470 197.328 469.328 198 468.5 198C467.672 198 467 197.328 467 196.5C467 195.672 467.672 195 468.5 195C469.328 195 470 195.672 470 196.5Z" fill="#D0D5DD" />
                                                <path d="M147 215.5C147 216.328 146.328 217 145.5 217C144.672 217 144 216.328 144 215.5C144 214.672 144.672 214 145.5 214C146.328 214 147 214.672 147 215.5Z" fill="#D0D5DD" />
                                                <path d="M166 215.5C166 216.328 165.328 217 164.5 217C163.672 217 163 216.328 163 215.5C163 214.672 163.672 214 164.5 214C165.328 214 166 214.672 166 215.5Z" fill="#D0D5DD" />
                                                <path d="M185 215.5C185 216.328 184.328 217 183.5 217C182.672 217 182 216.328 182 215.5C182 214.672 182.672 214 183.5 214C184.328 214 185 214.672 185 215.5Z" fill="#D0D5DD" />
                                                <path d="M204 215.5C204 216.328 203.328 217 202.5 217C201.672 217 201 216.328 201 215.5C201 214.672 201.672 214 202.5 214C203.328 214 204 214.672 204 215.5Z" fill="#D0D5DD" />
                                                <path d="M223 215.5C223 216.328 222.328 217 221.5 217C220.672 217 220 216.328 220 215.5C220 214.672 220.672 214 221.5 214C222.328 214 223 214.672 223 215.5Z" fill="#D0D5DD" />
                                                <path d="M242 215.5C242 216.328 241.328 217 240.5 217C239.672 217 239 216.328 239 215.5C239 214.672 239.672 214 240.5 214C241.328 214 242 214.672 242 215.5Z" fill="#D0D5DD" />
                                                <path d="M261 215.5C261 216.328 260.328 217 259.5 217C258.672 217 258 216.328 258 215.5C258 214.672 258.672 214 259.5 214C260.328 214 261 214.672 261 215.5Z" fill="#D0D5DD" />
                                                <path d="M280 215.5C280 216.328 279.328 217 278.5 217C277.672 217 277 216.328 277 215.5C277 214.672 277.672 214 278.5 214C279.328 214 280 214.672 280 215.5Z" fill="#D0D5DD" />
                                                <path d="M299 215.5C299 216.328 298.328 217 297.5 217C296.672 217 296 216.328 296 215.5C296 214.672 296.672 214 297.5 214C298.328 214 299 214.672 299 215.5Z" fill="#D0D5DD" />
                                                <path d="M318 215.5C318 216.328 317.328 217 316.5 217C315.672 217 315 216.328 315 215.5C315 214.672 315.672 214 316.5 214C317.328 214 318 214.672 318 215.5Z" fill="#D0D5DD" />
                                                <path d="M337 215.5C337 216.328 336.328 217 335.5 217C334.672 217 334 216.328 334 215.5C334 214.672 334.672 214 335.5 214C336.328 214 337 214.672 337 215.5Z" fill="#D0D5DD" />
                                                <path d="M356 215.5C356 216.328 355.328 217 354.5 217C353.672 217 353 216.328 353 215.5C353 214.672 353.672 214 354.5 214C355.328 214 356 214.672 356 215.5Z" fill="#D0D5DD" />
                                                <path d="M375 215.5C375 216.328 374.328 217 373.5 217C372.672 217 372 216.328 372 215.5C372 214.672 372.672 214 373.5 214C374.328 214 375 214.672 375 215.5Z" fill="#D0D5DD" />
                                                <path d="M394 215.5C394 216.328 393.328 217 392.5 217C391.672 217 391 216.328 391 215.5C391 214.672 391.672 214 392.5 214C393.328 214 394 214.672 394 215.5Z" fill="#D0D5DD" />
                                                <path d="M413 215.5C413 216.328 412.328 217 411.5 217C410.672 217 410 216.328 410 215.5C410 214.672 410.672 214 411.5 214C412.328 214 413 214.672 413 215.5Z" fill="#D0D5DD" />
                                                <path d="M432 215.5C432 216.328 431.328 217 430.5 217C429.672 217 429 216.328 429 215.5C429 214.672 429.672 214 430.5 214C431.328 214 432 214.672 432 215.5Z" fill="#D0D5DD" />
                                                <path d="M451 215.5C451 216.328 450.328 217 449.5 217C448.672 217 448 216.328 448 215.5C448 214.672 448.672 214 449.5 214C450.328 214 451 214.672 451 215.5Z" fill="#D0D5DD" />
                                                <path d="M470 215.5C470 216.328 469.328 217 468.5 217C467.672 217 467 216.328 467 215.5C467 214.672 467.672 214 468.5 214C469.328 214 470 214.672 470 215.5Z" fill="#D0D5DD" />
                                                <path d="M147 234.5C147 235.328 146.328 236 145.5 236C144.672 236 144 235.328 144 234.5C144 233.672 144.672 233 145.5 233C146.328 233 147 233.672 147 234.5Z" fill="#D0D5DD" />
                                                <path d="M166 234.5C166 235.328 165.328 236 164.5 236C163.672 236 163 235.328 163 234.5C163 233.672 163.672 233 164.5 233C165.328 233 166 233.672 166 234.5Z" fill="#D0D5DD" />
                                                <path d="M185 234.5C185 235.328 184.328 236 183.5 236C182.672 236 182 235.328 182 234.5C182 233.672 182.672 233 183.5 233C184.328 233 185 233.672 185 234.5Z" fill="#D0D5DD" />
                                                <path d="M204 234.5C204 235.328 203.328 236 202.5 236C201.672 236 201 235.328 201 234.5C201 233.672 201.672 233 202.5 233C203.328 233 204 233.672 204 234.5Z" fill="#D0D5DD" />
                                                <path d="M223 234.5C223 235.328 222.328 236 221.5 236C220.672 236 220 235.328 220 234.5C220 233.672 220.672 233 221.5 233C222.328 233 223 233.672 223 234.5Z" fill="#D0D5DD" />
                                                <path d="M242 234.5C242 235.328 241.328 236 240.5 236C239.672 236 239 235.328 239 234.5C239 233.672 239.672 233 240.5 233C241.328 233 242 233.672 242 234.5Z" fill="#D0D5DD" />
                                                <path d="M261 234.5C261 235.328 260.328 236 259.5 236C258.672 236 258 235.328 258 234.5C258 233.672 258.672 233 259.5 233C260.328 233 261 233.672 261 234.5Z" fill="#D0D5DD" />
                                                <path d="M280 234.5C280 235.328 279.328 236 278.5 236C277.672 236 277 235.328 277 234.5C277 233.672 277.672 233 278.5 233C279.328 233 280 233.672 280 234.5Z" fill="#D0D5DD" />
                                                <path d="M299 234.5C299 235.328 298.328 236 297.5 236C296.672 236 296 235.328 296 234.5C296 233.672 296.672 233 297.5 233C298.328 233 299 233.672 299 234.5Z" fill="#D0D5DD" />
                                                <path d="M318 234.5C318 235.328 317.328 236 316.5 236C315.672 236 315 235.328 315 234.5C315 233.672 315.672 233 316.5 233C317.328 233 318 233.672 318 234.5Z" fill="#D0D5DD" />
                                                <path d="M337 234.5C337 235.328 336.328 236 335.5 236C334.672 236 334 235.328 334 234.5C334 233.672 334.672 233 335.5 233C336.328 233 337 233.672 337 234.5Z" fill="#D0D5DD" />
                                                <path d="M356 234.5C356 235.328 355.328 236 354.5 236C353.672 236 353 235.328 353 234.5C353 233.672 353.672 233 354.5 233C355.328 233 356 233.672 356 234.5Z" fill="#D0D5DD" />
                                                <path d="M375 234.5C375 235.328 374.328 236 373.5 236C372.672 236 372 235.328 372 234.5C372 233.672 372.672 233 373.5 233C374.328 233 375 233.672 375 234.5Z" fill="#D0D5DD" />
                                                <path d="M394 234.5C394 235.328 393.328 236 392.5 236C391.672 236 391 235.328 391 234.5C391 233.672 391.672 233 392.5 233C393.328 233 394 233.672 394 234.5Z" fill="#D0D5DD" />
                                                <path d="M413 234.5C413 235.328 412.328 236 411.5 236C410.672 236 410 235.328 410 234.5C410 233.672 410.672 233 411.5 233C412.328 233 413 233.672 413 234.5Z" fill="#D0D5DD" />
                                                <path d="M432 234.5C432 235.328 431.328 236 430.5 236C429.672 236 429 235.328 429 234.5C429 233.672 429.672 233 430.5 233C431.328 233 432 233.672 432 234.5Z" fill="#D0D5DD" />
                                                <path d="M451 234.5C451 235.328 450.328 236 449.5 236C448.672 236 448 235.328 448 234.5C448 233.672 448.672 233 449.5 233C450.328 233 451 233.672 451 234.5Z" fill="#D0D5DD" />
                                                <path d="M470 234.5C470 235.328 469.328 236 468.5 236C467.672 236 467 235.328 467 234.5C467 233.672 467.672 233 468.5 233C469.328 233 470 233.672 470 234.5Z" fill="#D0D5DD" />
                                                <path d="M147 253.5C147 254.328 146.328 255 145.5 255C144.672 255 144 254.328 144 253.5C144 252.672 144.672 252 145.5 252C146.328 252 147 252.672 147 253.5Z" fill="#D0D5DD" />
                                                <path d="M166 253.5C166 254.328 165.328 255 164.5 255C163.672 255 163 254.328 163 253.5C163 252.672 163.672 252 164.5 252C165.328 252 166 252.672 166 253.5Z" fill="#D0D5DD" />
                                                <path d="M185 253.5C185 254.328 184.328 255 183.5 255C182.672 255 182 254.328 182 253.5C182 252.672 182.672 252 183.5 252C184.328 252 185 252.672 185 253.5Z" fill="#D0D5DD" />
                                                <path d="M204 253.5C204 254.328 203.328 255 202.5 255C201.672 255 201 254.328 201 253.5C201 252.672 201.672 252 202.5 252C203.328 252 204 252.672 204 253.5Z" fill="#D0D5DD" />
                                                <path d="M223 253.5C223 254.328 222.328 255 221.5 255C220.672 255 220 254.328 220 253.5C220 252.672 220.672 252 221.5 252C222.328 252 223 252.672 223 253.5Z" fill="#D0D5DD" />
                                                <path d="M242 253.5C242 254.328 241.328 255 240.5 255C239.672 255 239 254.328 239 253.5C239 252.672 239.672 252 240.5 252C241.328 252 242 252.672 242 253.5Z" fill="#D0D5DD" />
                                                <path d="M261 253.5C261 254.328 260.328 255 259.5 255C258.672 255 258 254.328 258 253.5C258 252.672 258.672 252 259.5 252C260.328 252 261 252.672 261 253.5Z" fill="#D0D5DD" />
                                                <path d="M280 253.5C280 254.328 279.328 255 278.5 255C277.672 255 277 254.328 277 253.5C277 252.672 277.672 252 278.5 252C279.328 252 280 252.672 280 253.5Z" fill="#D0D5DD" />
                                                <path d="M299 253.5C299 254.328 298.328 255 297.5 255C296.672 255 296 254.328 296 253.5C296 252.672 296.672 252 297.5 252C298.328 252 299 252.672 299 253.5Z" fill="#D0D5DD" />
                                                <path d="M318 253.5C318 254.328 317.328 255 316.5 255C315.672 255 315 254.328 315 253.5C315 252.672 315.672 252 316.5 252C317.328 252 318 252.672 318 253.5Z" fill="#D0D5DD" />
                                                <path d="M337 253.5C337 254.328 336.328 255 335.5 255C334.672 255 334 254.328 334 253.5C334 252.672 334.672 252 335.5 252C336.328 252 337 252.672 337 253.5Z" fill="#D0D5DD" />
                                                <path d="M356 253.5C356 254.328 355.328 255 354.5 255C353.672 255 353 254.328 353 253.5C353 252.672 353.672 252 354.5 252C355.328 252 356 252.672 356 253.5Z" fill="#D0D5DD" />
                                                <path d="M375 253.5C375 254.328 374.328 255 373.5 255C372.672 255 372 254.328 372 253.5C372 252.672 372.672 252 373.5 252C374.328 252 375 252.672 375 253.5Z" fill="#D0D5DD" />
                                                <path d="M394 253.5C394 254.328 393.328 255 392.5 255C391.672 255 391 254.328 391 253.5C391 252.672 391.672 252 392.5 252C393.328 252 394 252.672 394 253.5Z" fill="#D0D5DD" />
                                                <path d="M413 253.5C413 254.328 412.328 255 411.5 255C410.672 255 410 254.328 410 253.5C410 252.672 410.672 252 411.5 252C412.328 252 413 252.672 413 253.5Z" fill="#D0D5DD" />
                                                <path d="M432 253.5C432 254.328 431.328 255 430.5 255C429.672 255 429 254.328 429 253.5C429 252.672 429.672 252 430.5 252C431.328 252 432 252.672 432 253.5Z" fill="#D0D5DD" />
                                                <path d="M451 253.5C451 254.328 450.328 255 449.5 255C448.672 255 448 254.328 448 253.5C448 252.672 448.672 252 449.5 252C450.328 252 451 252.672 451 253.5Z" fill="#D0D5DD" />
                                                <path d="M470 253.5C470 254.328 469.328 255 468.5 255C467.672 255 467 254.328 467 253.5C467 252.672 467.672 252 468.5 252C469.328 252 470 252.672 470 253.5Z" fill="#D0D5DD" />
                                                <path d="M147 272.5C147 273.328 146.328 274 145.5 274C144.672 274 144 273.328 144 272.5C144 271.672 144.672 271 145.5 271C146.328 271 147 271.672 147 272.5Z" fill="#D0D5DD" />
                                                <path d="M166 272.5C166 273.328 165.328 274 164.5 274C163.672 274 163 273.328 163 272.5C163 271.672 163.672 271 164.5 271C165.328 271 166 271.672 166 272.5Z" fill="#D0D5DD" />
                                                <path d="M185 272.5C185 273.328 184.328 274 183.5 274C182.672 274 182 273.328 182 272.5C182 271.672 182.672 271 183.5 271C184.328 271 185 271.672 185 272.5Z" fill="#D0D5DD" />
                                                <path d="M204 272.5C204 273.328 203.328 274 202.5 274C201.672 274 201 273.328 201 272.5C201 271.672 201.672 271 202.5 271C203.328 271 204 271.672 204 272.5Z" fill="#D0D5DD" />
                                                <path d="M223 272.5C223 273.328 222.328 274 221.5 274C220.672 274 220 273.328 220 272.5C220 271.672 220.672 271 221.5 271C222.328 271 223 271.672 223 272.5Z" fill="#D0D5DD" />
                                                <path d="M242 272.5C242 273.328 241.328 274 240.5 274C239.672 274 239 273.328 239 272.5C239 271.672 239.672 271 240.5 271C241.328 271 242 271.672 242 272.5Z" fill="#D0D5DD" />
                                                <path d="M261 272.5C261 273.328 260.328 274 259.5 274C258.672 274 258 273.328 258 272.5C258 271.672 258.672 271 259.5 271C260.328 271 261 271.672 261 272.5Z" fill="#D0D5DD" />
                                                <path d="M280 272.5C280 273.328 279.328 274 278.5 274C277.672 274 277 273.328 277 272.5C277 271.672 277.672 271 278.5 271C279.328 271 280 271.672 280 272.5Z" fill="#D0D5DD" />
                                                <path d="M299 272.5C299 273.328 298.328 274 297.5 274C296.672 274 296 273.328 296 272.5C296 271.672 296.672 271 297.5 271C298.328 271 299 271.672 299 272.5Z" fill="#D0D5DD" />
                                                <path d="M318 272.5C318 273.328 317.328 274 316.5 274C315.672 274 315 273.328 315 272.5C315 271.672 315.672 271 316.5 271C317.328 271 318 271.672 318 272.5Z" fill="#D0D5DD" />
                                                <path d="M337 272.5C337 273.328 336.328 274 335.5 274C334.672 274 334 273.328 334 272.5C334 271.672 334.672 271 335.5 271C336.328 271 337 271.672 337 272.5Z" fill="#D0D5DD" />
                                                <path d="M356 272.5C356 273.328 355.328 274 354.5 274C353.672 274 353 273.328 353 272.5C353 271.672 353.672 271 354.5 271C355.328 271 356 271.672 356 272.5Z" fill="#D0D5DD" />
                                                <path d="M375 272.5C375 273.328 374.328 274 373.5 274C372.672 274 372 273.328 372 272.5C372 271.672 372.672 271 373.5 271C374.328 271 375 271.672 375 272.5Z" fill="#D0D5DD" />
                                                <path d="M394 272.5C394 273.328 393.328 274 392.5 274C391.672 274 391 273.328 391 272.5C391 271.672 391.672 271 392.5 271C393.328 271 394 271.672 394 272.5Z" fill="#D0D5DD" />
                                                <path d="M413 272.5C413 273.328 412.328 274 411.5 274C410.672 274 410 273.328 410 272.5C410 271.672 410.672 271 411.5 271C412.328 271 413 271.672 413 272.5Z" fill="#D0D5DD" />
                                                <path d="M432 272.5C432 273.328 431.328 274 430.5 274C429.672 274 429 273.328 429 272.5C429 271.672 429.672 271 430.5 271C431.328 271 432 271.672 432 272.5Z" fill="#D0D5DD" />
                                                <path d="M451 272.5C451 273.328 450.328 274 449.5 274C448.672 274 448 273.328 448 272.5C448 271.672 448.672 271 449.5 271C450.328 271 451 271.672 451 272.5Z" fill="#D0D5DD" />
                                                <path d="M470 272.5C470 273.328 469.328 274 468.5 274C467.672 274 467 273.328 467 272.5C467 271.672 467.672 271 468.5 271C469.328 271 470 271.672 470 272.5Z" fill="#D0D5DD" />
                                                <path d="M147 291.5C147 292.328 146.328 293 145.5 293C144.672 293 144 292.328 144 291.5C144 290.672 144.672 290 145.5 290C146.328 290 147 290.672 147 291.5Z" fill="#D0D5DD" />
                                                <path d="M166 291.5C166 292.328 165.328 293 164.5 293C163.672 293 163 292.328 163 291.5C163 290.672 163.672 290 164.5 290C165.328 290 166 290.672 166 291.5Z" fill="#D0D5DD" />
                                                <path d="M185 291.5C185 292.328 184.328 293 183.5 293C182.672 293 182 292.328 182 291.5C182 290.672 182.672 290 183.5 290C184.328 290 185 290.672 185 291.5Z" fill="#D0D5DD" />
                                                <path d="M204 291.5C204 292.328 203.328 293 202.5 293C201.672 293 201 292.328 201 291.5C201 290.672 201.672 290 202.5 290C203.328 290 204 290.672 204 291.5Z" fill="#D0D5DD" />
                                                <path d="M223 291.5C223 292.328 222.328 293 221.5 293C220.672 293 220 292.328 220 291.5C220 290.672 220.672 290 221.5 290C222.328 290 223 290.672 223 291.5Z" fill="#D0D5DD" />
                                                <path d="M242 291.5C242 292.328 241.328 293 240.5 293C239.672 293 239 292.328 239 291.5C239 290.672 239.672 290 240.5 290C241.328 290 242 290.672 242 291.5Z" fill="#D0D5DD" />
                                                <path d="M261 291.5C261 292.328 260.328 293 259.5 293C258.672 293 258 292.328 258 291.5C258 290.672 258.672 290 259.5 290C260.328 290 261 290.672 261 291.5Z" fill="#D0D5DD" />
                                                <path d="M280 291.5C280 292.328 279.328 293 278.5 293C277.672 293 277 292.328 277 291.5C277 290.672 277.672 290 278.5 290C279.328 290 280 290.672 280 291.5Z" fill="#D0D5DD" />
                                                <path d="M299 291.5C299 292.328 298.328 293 297.5 293C296.672 293 296 292.328 296 291.5C296 290.672 296.672 290 297.5 290C298.328 290 299 290.672 299 291.5Z" fill="#D0D5DD" />
                                                <path d="M318 291.5C318 292.328 317.328 293 316.5 293C315.672 293 315 292.328 315 291.5C315 290.672 315.672 290 316.5 290C317.328 290 318 290.672 318 291.5Z" fill="#D0D5DD" />
                                                <path d="M337 291.5C337 292.328 336.328 293 335.5 293C334.672 293 334 292.328 334 291.5C334 290.672 334.672 290 335.5 290C336.328 290 337 290.672 337 291.5Z" fill="#D0D5DD" />
                                                <path d="M356 291.5C356 292.328 355.328 293 354.5 293C353.672 293 353 292.328 353 291.5C353 290.672 353.672 290 354.5 290C355.328 290 356 290.672 356 291.5Z" fill="#D0D5DD" />
                                                <path d="M375 291.5C375 292.328 374.328 293 373.5 293C372.672 293 372 292.328 372 291.5C372 290.672 372.672 290 373.5 290C374.328 290 375 290.672 375 291.5Z" fill="#D0D5DD" />
                                                <path d="M394 291.5C394 292.328 393.328 293 392.5 293C391.672 293 391 292.328 391 291.5C391 290.672 391.672 290 392.5 290C393.328 290 394 290.672 394 291.5Z" fill="#D0D5DD" />
                                                <path d="M413 291.5C413 292.328 412.328 293 411.5 293C410.672 293 410 292.328 410 291.5C410 290.672 410.672 290 411.5 290C412.328 290 413 290.672 413 291.5Z" fill="#D0D5DD" />
                                                <path d="M432 291.5C432 292.328 431.328 293 430.5 293C429.672 293 429 292.328 429 291.5C429 290.672 429.672 290 430.5 290C431.328 290 432 290.672 432 291.5Z" fill="#D0D5DD" />
                                                <path d="M451 291.5C451 292.328 450.328 293 449.5 293C448.672 293 448 292.328 448 291.5C448 290.672 448.672 290 449.5 290C450.328 290 451 290.672 451 291.5Z" fill="#D0D5DD" />
                                                <path d="M470 291.5C470 292.328 469.328 293 468.5 293C467.672 293 467 292.328 467 291.5C467 290.672 467.672 290 468.5 290C469.328 290 470 290.672 470 291.5Z" fill="#D0D5DD" />
                                                <path d="M147 310.5C147 311.328 146.328 312 145.5 312C144.672 312 144 311.328 144 310.5C144 309.672 144.672 309 145.5 309C146.328 309 147 309.672 147 310.5Z" fill="#D0D5DD" />
                                                <path d="M166 310.5C166 311.328 165.328 312 164.5 312C163.672 312 163 311.328 163 310.5C163 309.672 163.672 309 164.5 309C165.328 309 166 309.672 166 310.5Z" fill="#D0D5DD" />
                                                <path d="M185 310.5C185 311.328 184.328 312 183.5 312C182.672 312 182 311.328 182 310.5C182 309.672 182.672 309 183.5 309C184.328 309 185 309.672 185 310.5Z" fill="#D0D5DD" />
                                                <path d="M204 310.5C204 311.328 203.328 312 202.5 312C201.672 312 201 311.328 201 310.5C201 309.672 201.672 309 202.5 309C203.328 309 204 309.672 204 310.5Z" fill="#D0D5DD" />
                                                <path d="M223 310.5C223 311.328 222.328 312 221.5 312C220.672 312 220 311.328 220 310.5C220 309.672 220.672 309 221.5 309C222.328 309 223 309.672 223 310.5Z" fill="#D0D5DD" />
                                                <path d="M242 310.5C242 311.328 241.328 312 240.5 312C239.672 312 239 311.328 239 310.5C239 309.672 239.672 309 240.5 309C241.328 309 242 309.672 242 310.5Z" fill="#D0D5DD" />
                                                <path d="M261 310.5C261 311.328 260.328 312 259.5 312C258.672 312 258 311.328 258 310.5C258 309.672 258.672 309 259.5 309C260.328 309 261 309.672 261 310.5Z" fill="#D0D5DD" />
                                                <path d="M280 310.5C280 311.328 279.328 312 278.5 312C277.672 312 277 311.328 277 310.5C277 309.672 277.672 309 278.5 309C279.328 309 280 309.672 280 310.5Z" fill="#D0D5DD" />
                                                <path d="M299 310.5C299 311.328 298.328 312 297.5 312C296.672 312 296 311.328 296 310.5C296 309.672 296.672 309 297.5 309C298.328 309 299 309.672 299 310.5Z" fill="#D0D5DD" />
                                                <path d="M318 310.5C318 311.328 317.328 312 316.5 312C315.672 312 315 311.328 315 310.5C315 309.672 315.672 309 316.5 309C317.328 309 318 309.672 318 310.5Z" fill="#D0D5DD" />
                                                <path d="M337 310.5C337 311.328 336.328 312 335.5 312C334.672 312 334 311.328 334 310.5C334 309.672 334.672 309 335.5 309C336.328 309 337 309.672 337 310.5Z" fill="#D0D5DD" />
                                                <path d="M356 310.5C356 311.328 355.328 312 354.5 312C353.672 312 353 311.328 353 310.5C353 309.672 353.672 309 354.5 309C355.328 309 356 309.672 356 310.5Z" fill="#D0D5DD" />
                                                <path d="M375 310.5C375 311.328 374.328 312 373.5 312C372.672 312 372 311.328 372 310.5C372 309.672 372.672 309 373.5 309C374.328 309 375 309.672 375 310.5Z" fill="#D0D5DD" />
                                                <path d="M394 310.5C394 311.328 393.328 312 392.5 312C391.672 312 391 311.328 391 310.5C391 309.672 391.672 309 392.5 309C393.328 309 394 309.672 394 310.5Z" fill="#D0D5DD" />
                                                <path d="M413 310.5C413 311.328 412.328 312 411.5 312C410.672 312 410 311.328 410 310.5C410 309.672 410.672 309 411.5 309C412.328 309 413 309.672 413 310.5Z" fill="#D0D5DD" />
                                                <path d="M432 310.5C432 311.328 431.328 312 430.5 312C429.672 312 429 311.328 429 310.5C429 309.672 429.672 309 430.5 309C431.328 309 432 309.672 432 310.5Z" fill="#D0D5DD" />
                                                <path d="M451 310.5C451 311.328 450.328 312 449.5 312C448.672 312 448 311.328 448 310.5C448 309.672 448.672 309 449.5 309C450.328 309 451 309.672 451 310.5Z" fill="#D0D5DD" />
                                                <path d="M470 310.5C470 311.328 469.328 312 468.5 312C467.672 312 467 311.328 467 310.5C467 309.672 467.672 309 468.5 309C469.328 309 470 309.672 470 310.5Z" fill="#D0D5DD" />
                                                <path d="M147 329.5C147 330.328 146.328 331 145.5 331C144.672 331 144 330.328 144 329.5C144 328.672 144.672 328 145.5 328C146.328 328 147 328.672 147 329.5Z" fill="#D0D5DD" />
                                                <path d="M166 329.5C166 330.328 165.328 331 164.5 331C163.672 331 163 330.328 163 329.5C163 328.672 163.672 328 164.5 328C165.328 328 166 328.672 166 329.5Z" fill="#D0D5DD" />
                                                <path d="M185 329.5C185 330.328 184.328 331 183.5 331C182.672 331 182 330.328 182 329.5C182 328.672 182.672 328 183.5 328C184.328 328 185 328.672 185 329.5Z" fill="#D0D5DD" />
                                                <path d="M204 329.5C204 330.328 203.328 331 202.5 331C201.672 331 201 330.328 201 329.5C201 328.672 201.672 328 202.5 328C203.328 328 204 328.672 204 329.5Z" fill="#D0D5DD" />
                                                <path d="M223 329.5C223 330.328 222.328 331 221.5 331C220.672 331 220 330.328 220 329.5C220 328.672 220.672 328 221.5 328C222.328 328 223 328.672 223 329.5Z" fill="#D0D5DD" />
                                                <path d="M242 329.5C242 330.328 241.328 331 240.5 331C239.672 331 239 330.328 239 329.5C239 328.672 239.672 328 240.5 328C241.328 328 242 328.672 242 329.5Z" fill="#D0D5DD" />
                                                <path d="M261 329.5C261 330.328 260.328 331 259.5 331C258.672 331 258 330.328 258 329.5C258 328.672 258.672 328 259.5 328C260.328 328 261 328.672 261 329.5Z" fill="#D0D5DD" />
                                                <path d="M280 329.5C280 330.328 279.328 331 278.5 331C277.672 331 277 330.328 277 329.5C277 328.672 277.672 328 278.5 328C279.328 328 280 328.672 280 329.5Z" fill="#D0D5DD" />
                                                <path d="M299 329.5C299 330.328 298.328 331 297.5 331C296.672 331 296 330.328 296 329.5C296 328.672 296.672 328 297.5 328C298.328 328 299 328.672 299 329.5Z" fill="#D0D5DD" />
                                                <path d="M318 329.5C318 330.328 317.328 331 316.5 331C315.672 331 315 330.328 315 329.5C315 328.672 315.672 328 316.5 328C317.328 328 318 328.672 318 329.5Z" fill="#D0D5DD" />
                                                <path d="M337 329.5C337 330.328 336.328 331 335.5 331C334.672 331 334 330.328 334 329.5C334 328.672 334.672 328 335.5 328C336.328 328 337 328.672 337 329.5Z" fill="#D0D5DD" />
                                                <path d="M356 329.5C356 330.328 355.328 331 354.5 331C353.672 331 353 330.328 353 329.5C353 328.672 353.672 328 354.5 328C355.328 328 356 328.672 356 329.5Z" fill="#D0D5DD" />
                                                <path d="M375 329.5C375 330.328 374.328 331 373.5 331C372.672 331 372 330.328 372 329.5C372 328.672 372.672 328 373.5 328C374.328 328 375 328.672 375 329.5Z" fill="#D0D5DD" />
                                                <path d="M394 329.5C394 330.328 393.328 331 392.5 331C391.672 331 391 330.328 391 329.5C391 328.672 391.672 328 392.5 328C393.328 328 394 328.672 394 329.5Z" fill="#D0D5DD" />
                                                <path d="M413 329.5C413 330.328 412.328 331 411.5 331C410.672 331 410 330.328 410 329.5C410 328.672 410.672 328 411.5 328C412.328 328 413 328.672 413 329.5Z" fill="#D0D5DD" />
                                                <path d="M432 329.5C432 330.328 431.328 331 430.5 331C429.672 331 429 330.328 429 329.5C429 328.672 429.672 328 430.5 328C431.328 328 432 328.672 432 329.5Z" fill="#D0D5DD" />
                                                <path d="M451 329.5C451 330.328 450.328 331 449.5 331C448.672 331 448 330.328 448 329.5C448 328.672 448.672 328 449.5 328C450.328 328 451 328.672 451 329.5Z" fill="#D0D5DD" />
                                                <path d="M470 329.5C470 330.328 469.328 331 468.5 331C467.672 331 467 330.328 467 329.5C467 328.672 467.672 328 468.5 328C469.328 328 470 328.672 470 329.5Z" fill="#D0D5DD" />
                                                <path d="M147 348.5C147 349.328 146.328 350 145.5 350C144.672 350 144 349.328 144 348.5C144 347.672 144.672 347 145.5 347C146.328 347 147 347.672 147 348.5Z" fill="#D0D5DD" />
                                                <path d="M166 348.5C166 349.328 165.328 350 164.5 350C163.672 350 163 349.328 163 348.5C163 347.672 163.672 347 164.5 347C165.328 347 166 347.672 166 348.5Z" fill="#D0D5DD" />
                                                <path d="M185 348.5C185 349.328 184.328 350 183.5 350C182.672 350 182 349.328 182 348.5C182 347.672 182.672 347 183.5 347C184.328 347 185 347.672 185 348.5Z" fill="#D0D5DD" />
                                                <path d="M204 348.5C204 349.328 203.328 350 202.5 350C201.672 350 201 349.328 201 348.5C201 347.672 201.672 347 202.5 347C203.328 347 204 347.672 204 348.5Z" fill="#D0D5DD" />
                                                <path d="M223 348.5C223 349.328 222.328 350 221.5 350C220.672 350 220 349.328 220 348.5C220 347.672 220.672 347 221.5 347C222.328 347 223 347.672 223 348.5Z" fill="#D0D5DD" />
                                                <path d="M242 348.5C242 349.328 241.328 350 240.5 350C239.672 350 239 349.328 239 348.5C239 347.672 239.672 347 240.5 347C241.328 347 242 347.672 242 348.5Z" fill="#D0D5DD" />
                                                <path d="M261 348.5C261 349.328 260.328 350 259.5 350C258.672 350 258 349.328 258 348.5C258 347.672 258.672 347 259.5 347C260.328 347 261 347.672 261 348.5Z" fill="#D0D5DD" />
                                                <path d="M280 348.5C280 349.328 279.328 350 278.5 350C277.672 350 277 349.328 277 348.5C277 347.672 277.672 347 278.5 347C279.328 347 280 347.672 280 348.5Z" fill="#D0D5DD" />
                                                <path d="M299 348.5C299 349.328 298.328 350 297.5 350C296.672 350 296 349.328 296 348.5C296 347.672 296.672 347 297.5 347C298.328 347 299 347.672 299 348.5Z" fill="#D0D5DD" />
                                                <path d="M318 348.5C318 349.328 317.328 350 316.5 350C315.672 350 315 349.328 315 348.5C315 347.672 315.672 347 316.5 347C317.328 347 318 347.672 318 348.5Z" fill="#D0D5DD" />
                                                <path d="M337 348.5C337 349.328 336.328 350 335.5 350C334.672 350 334 349.328 334 348.5C334 347.672 334.672 347 335.5 347C336.328 347 337 347.672 337 348.5Z" fill="#D0D5DD" />
                                                <path d="M356 348.5C356 349.328 355.328 350 354.5 350C353.672 350 353 349.328 353 348.5C353 347.672 353.672 347 354.5 347C355.328 347 356 347.672 356 348.5Z" fill="#D0D5DD" />
                                                <path d="M375 348.5C375 349.328 374.328 350 373.5 350C372.672 350 372 349.328 372 348.5C372 347.672 372.672 347 373.5 347C374.328 347 375 347.672 375 348.5Z" fill="#D0D5DD" />
                                                <path d="M394 348.5C394 349.328 393.328 350 392.5 350C391.672 350 391 349.328 391 348.5C391 347.672 391.672 347 392.5 347C393.328 347 394 347.672 394 348.5Z" fill="#D0D5DD" />
                                                <path d="M413 348.5C413 349.328 412.328 350 411.5 350C410.672 350 410 349.328 410 348.5C410 347.672 410.672 347 411.5 347C412.328 347 413 347.672 413 348.5Z" fill="#D0D5DD" />
                                                <path d="M432 348.5C432 349.328 431.328 350 430.5 350C429.672 350 429 349.328 429 348.5C429 347.672 429.672 347 430.5 347C431.328 347 432 347.672 432 348.5Z" fill="#D0D5DD" />
                                                <path d="M451 348.5C451 349.328 450.328 350 449.5 350C448.672 350 448 349.328 448 348.5C448 347.672 448.672 347 449.5 347C450.328 347 451 347.672 451 348.5Z" fill="#D0D5DD" />
                                                <path d="M470 348.5C470 349.328 469.328 350 468.5 350C467.672 350 467 349.328 467 348.5C467 347.672 467.672 347 468.5 347C469.328 347 470 347.672 470 348.5Z" fill="#D0D5DD" />
                                                <path d="M147 367.5C147 368.328 146.328 369 145.5 369C144.672 369 144 368.328 144 367.5C144 366.672 144.672 366 145.5 366C146.328 366 147 366.672 147 367.5Z" fill="#D0D5DD" />
                                                <path d="M166 367.5C166 368.328 165.328 369 164.5 369C163.672 369 163 368.328 163 367.5C163 366.672 163.672 366 164.5 366C165.328 366 166 366.672 166 367.5Z" fill="#D0D5DD" />
                                                <path d="M185 367.5C185 368.328 184.328 369 183.5 369C182.672 369 182 368.328 182 367.5C182 366.672 182.672 366 183.5 366C184.328 366 185 366.672 185 367.5Z" fill="#D0D5DD" />
                                                <path d="M204 367.5C204 368.328 203.328 369 202.5 369C201.672 369 201 368.328 201 367.5C201 366.672 201.672 366 202.5 366C203.328 366 204 366.672 204 367.5Z" fill="#D0D5DD" />
                                                <path d="M223 367.5C223 368.328 222.328 369 221.5 369C220.672 369 220 368.328 220 367.5C220 366.672 220.672 366 221.5 366C222.328 366 223 366.672 223 367.5Z" fill="#D0D5DD" />
                                                <path d="M242 367.5C242 368.328 241.328 369 240.5 369C239.672 369 239 368.328 239 367.5C239 366.672 239.672 366 240.5 366C241.328 366 242 366.672 242 367.5Z" fill="#D0D5DD" />
                                                <path d="M261 367.5C261 368.328 260.328 369 259.5 369C258.672 369 258 368.328 258 367.5C258 366.672 258.672 366 259.5 366C260.328 366 261 366.672 261 367.5Z" fill="#D0D5DD" />
                                                <path d="M280 367.5C280 368.328 279.328 369 278.5 369C277.672 369 277 368.328 277 367.5C277 366.672 277.672 366 278.5 366C279.328 366 280 366.672 280 367.5Z" fill="#D0D5DD" />
                                                <path d="M299 367.5C299 368.328 298.328 369 297.5 369C296.672 369 296 368.328 296 367.5C296 366.672 296.672 366 297.5 366C298.328 366 299 366.672 299 367.5Z" fill="#D0D5DD" />
                                                <path d="M318 367.5C318 368.328 317.328 369 316.5 369C315.672 369 315 368.328 315 367.5C315 366.672 315.672 366 316.5 366C317.328 366 318 366.672 318 367.5Z" fill="#D0D5DD" />
                                                <path d="M337 367.5C337 368.328 336.328 369 335.5 369C334.672 369 334 368.328 334 367.5C334 366.672 334.672 366 335.5 366C336.328 366 337 366.672 337 367.5Z" fill="#D0D5DD" />
                                                <path d="M356 367.5C356 368.328 355.328 369 354.5 369C353.672 369 353 368.328 353 367.5C353 366.672 353.672 366 354.5 366C355.328 366 356 366.672 356 367.5Z" fill="#D0D5DD" />
                                                <path d="M375 367.5C375 368.328 374.328 369 373.5 369C372.672 369 372 368.328 372 367.5C372 366.672 372.672 366 373.5 366C374.328 366 375 366.672 375 367.5Z" fill="#D0D5DD" />
                                                <path d="M394 367.5C394 368.328 393.328 369 392.5 369C391.672 369 391 368.328 391 367.5C391 366.672 391.672 366 392.5 366C393.328 366 394 366.672 394 367.5Z" fill="#D0D5DD" />
                                                <path d="M413 367.5C413 368.328 412.328 369 411.5 369C410.672 369 410 368.328 410 367.5C410 366.672 410.672 366 411.5 366C412.328 366 413 366.672 413 367.5Z" fill="#D0D5DD" />
                                                <path d="M432 367.5C432 368.328 431.328 369 430.5 369C429.672 369 429 368.328 429 367.5C429 366.672 429.672 366 430.5 366C431.328 366 432 366.672 432 367.5Z" fill="#D0D5DD" />
                                                <path d="M451 367.5C451 368.328 450.328 369 449.5 369C448.672 369 448 368.328 448 367.5C448 366.672 448.672 366 449.5 366C450.328 366 451 366.672 451 367.5Z" fill="#D0D5DD" />
                                                <path d="M470 367.5C470 368.328 469.328 369 468.5 369C467.672 369 467 368.328 467 367.5C467 366.672 467.672 366 468.5 366C469.328 366 470 366.672 470 367.5Z" fill="#D0D5DD" />
                                                <path d="M147 386.5C147 387.328 146.328 388 145.5 388C144.672 388 144 387.328 144 386.5C144 385.672 144.672 385 145.5 385C146.328 385 147 385.672 147 386.5Z" fill="#D0D5DD" />
                                                <path d="M166 386.5C166 387.328 165.328 388 164.5 388C163.672 388 163 387.328 163 386.5C163 385.672 163.672 385 164.5 385C165.328 385 166 385.672 166 386.5Z" fill="#D0D5DD" />
                                                <path d="M185 386.5C185 387.328 184.328 388 183.5 388C182.672 388 182 387.328 182 386.5C182 385.672 182.672 385 183.5 385C184.328 385 185 385.672 185 386.5Z" fill="#D0D5DD" />
                                                <path d="M204 386.5C204 387.328 203.328 388 202.5 388C201.672 388 201 387.328 201 386.5C201 385.672 201.672 385 202.5 385C203.328 385 204 385.672 204 386.5Z" fill="#D0D5DD" />
                                                <path d="M223 386.5C223 387.328 222.328 388 221.5 388C220.672 388 220 387.328 220 386.5C220 385.672 220.672 385 221.5 385C222.328 385 223 385.672 223 386.5Z" fill="#D0D5DD" />
                                                <path d="M242 386.5C242 387.328 241.328 388 240.5 388C239.672 388 239 387.328 239 386.5C239 385.672 239.672 385 240.5 385C241.328 385 242 385.672 242 386.5Z" fill="#D0D5DD" />
                                                <path d="M261 386.5C261 387.328 260.328 388 259.5 388C258.672 388 258 387.328 258 386.5C258 385.672 258.672 385 259.5 385C260.328 385 261 385.672 261 386.5Z" fill="#D0D5DD" />
                                                <path d="M280 386.5C280 387.328 279.328 388 278.5 388C277.672 388 277 387.328 277 386.5C277 385.672 277.672 385 278.5 385C279.328 385 280 385.672 280 386.5Z" fill="#D0D5DD" />
                                                <path d="M299 386.5C299 387.328 298.328 388 297.5 388C296.672 388 296 387.328 296 386.5C296 385.672 296.672 385 297.5 385C298.328 385 299 385.672 299 386.5Z" fill="#D0D5DD" />
                                                <path d="M318 386.5C318 387.328 317.328 388 316.5 388C315.672 388 315 387.328 315 386.5C315 385.672 315.672 385 316.5 385C317.328 385 318 385.672 318 386.5Z" fill="#D0D5DD" />
                                                <path d="M337 386.5C337 387.328 336.328 388 335.5 388C334.672 388 334 387.328 334 386.5C334 385.672 334.672 385 335.5 385C336.328 385 337 385.672 337 386.5Z" fill="#D0D5DD" />
                                                <path d="M356 386.5C356 387.328 355.328 388 354.5 388C353.672 388 353 387.328 353 386.5C353 385.672 353.672 385 354.5 385C355.328 385 356 385.672 356 386.5Z" fill="#D0D5DD" />
                                                <path d="M375 386.5C375 387.328 374.328 388 373.5 388C372.672 388 372 387.328 372 386.5C372 385.672 372.672 385 373.5 385C374.328 385 375 385.672 375 386.5Z" fill="#D0D5DD" />
                                                <path d="M394 386.5C394 387.328 393.328 388 392.5 388C391.672 388 391 387.328 391 386.5C391 385.672 391.672 385 392.5 385C393.328 385 394 385.672 394 386.5Z" fill="#D0D5DD" />
                                                <path d="M413 386.5C413 387.328 412.328 388 411.5 388C410.672 388 410 387.328 410 386.5C410 385.672 410.672 385 411.5 385C412.328 385 413 385.672 413 386.5Z" fill="#D0D5DD" />
                                                <path d="M432 386.5C432 387.328 431.328 388 430.5 388C429.672 388 429 387.328 429 386.5C429 385.672 429.672 385 430.5 385C431.328 385 432 385.672 432 386.5Z" fill="#D0D5DD" />
                                                <path d="M451 386.5C451 387.328 450.328 388 449.5 388C448.672 388 448 387.328 448 386.5C448 385.672 448.672 385 449.5 385C450.328 385 451 385.672 451 386.5Z" fill="#D0D5DD" />
                                                <path d="M470 386.5C470 387.328 469.328 388 468.5 388C467.672 388 467 387.328 467 386.5C467 385.672 467.672 385 468.5 385C469.328 385 470 385.672 470 386.5Z" fill="#D0D5DD" />
                                              </g>
                                            </g>
                                            <rect x="109.622" y="114.975" width="131" height="154" rx="7.5" transform="rotate(-16.5671 109.622 114.975)" fill="white" stroke="#065A8C" />
                                            <rect x="125.658" y="118.029" width="102" height="136" rx="8" transform="rotate(-16.5671 125.658 118.029)" fill="#E0E3E7" />
                                            <path d="M202.687 102.417L149.429 118.261C148.738 118.465 147.993 118.384 147.358 118.033C146.722 117.683 146.247 117.093 146.038 116.392L142.193 103.468C141.985 102.767 142.06 102.013 142.401 101.372C142.742 100.731 143.321 100.256 144.012 100.049L197.269 84.2059C197.96 84.0012 198.705 84.083 199.341 84.4333C199.977 84.7837 200.452 85.3739 200.661 86.0745L204.506 98.9986C204.713 99.6997 204.639 100.453 204.298 101.094C203.957 101.735 203.377 102.211 202.687 102.417Z" fill="#065A8C" />
                                            <path d="M172.351 97.8786C175.792 96.855 177.751 93.2359 176.728 89.7951C175.704 86.3543 172.085 84.3947 168.644 85.4183C165.203 86.4419 163.244 90.061 164.267 93.5019C165.291 96.9427 168.91 98.9022 172.351 97.8786Z" fill="#065A8C" />
                                            <path d="M171.496 95.0032C173.348 94.452 174.404 92.5032 173.852 90.6505C173.301 88.7977 171.352 87.7426 169.5 88.2938C167.647 88.8449 166.592 90.7937 167.143 92.6465C167.694 94.4992 169.643 95.5543 171.496 95.0032Z" fill="white" />
                                            <rect x="194.5" y="119.5" width="131" height="154" rx="7.5" fill="white" stroke="#065A8C" />
                                            <rect x="209" y="127" width="102" height="136" rx="8" fill="#E0E3E7" />
                                            <path d="M287.282 134H231.718C230.997 133.999 230.306 133.708 229.797 133.191C229.287 132.674 229.001 131.973 229 131.242V117.758C229.001 117.027 229.287 116.326 229.797 115.809C230.306 115.292 230.997 115.001 231.718 115H287.282C288.003 115.001 288.694 115.292 289.203 115.809C289.713 116.326 289.999 117.027 290 117.758V131.242C289.999 131.973 289.713 132.674 289.203 133.191C288.694 133.708 288.003 133.999 287.282 134Z" fill="#065A8C" />
                                            <path d="M259.5 121C263.09 121 266 118.09 266 114.5C266 110.91 263.09 108 259.5 108C255.91 108 253 110.91 253 114.5C253 118.09 255.91 121 259.5 121Z" fill="#065A8C" />
                                            <path d="M259.5 118C261.433 118 263 116.433 263 114.5C263 112.567 261.433 111 259.5 111C257.567 111 256 112.567 256 114.5C256 116.433 257.567 118 259.5 118Z" fill="white" />
                                            <defs>
                                              <radialGradient id="paint0_radial_8_103417" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(168 168) rotate(90) scale(168 168)">
                                                <stop />
                                                <stop offset="1" stop-opacity="0" />
                                              </radialGradient>
                                              <radialGradient id="paint1_radial_8_103417" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(168 168) rotate(90) scale(168 168)">
                                                <stop />
                                                <stop offset="1" stop-opacity="0" />
                                              </radialGradient>
                                              <clipPath id="clip0_8_103417">
                                                <rect width="273.912" height="273.912" fill="white" />
                                              </clipPath>
                                              <clipPath id="clip1_8_103417">
                                                <rect width="273.912" height="273.912" fill="white" transform="translate(170 57)" />
                                              </clipPath>
                                            </defs>
                                          </svg>


                                        </div>
                                      </div>
                                    )}
                                  </div>

                                )}

                              </div>
                            )}
                        </div>
                      )}
                    </ToolkitProvider>

                    {this.state.istableloader && !this.state.showBanner && (
                      <div className="loader-responsive register">
                        <div className="performanceList">
                          <div className="tableList">
                            <ul>
                              <li className="d-flex">
                                <div className="list d-flex align-self-center">
                                  <div className="group">
                                    <div className="loadingAnimation"></div>
                                  </div>
                                </div>
                                <div className="list d-flex align-self-center">
                                  <div className="group">
                                    <div className="loadingAnimation"></div>
                                  </div>
                                </div>
                                <div className="list d-flex align-self-center">
                                  <div className="group">
                                    <div className="loadingAnimation"></div>
                                  </div>
                                </div>
                                <div className="list d-flex align-self-center">
                                  <div className="group">
                                    <div className="loadingAnimation"></div>
                                  </div>
                                </div>
                                <div className="list d-flex align-self-center">
                                  <div className="group">
                                    <div className="loadingAnimation"></div>
                                  </div>
                                </div>
                                <div className="list d-flex align-self-center">
                                  <div className="group">
                                    <div className="loadingAnimation"></div>
                                  </div>
                                </div>
                                <div className="list d-flex align-self-center">
                                  <div className="group">
                                    <div className="loadingAnimation"></div>
                                  </div>
                                </div>
                                <div className="list d-flex align-self-center">
                                  <div className="group">
                                    <div className="loadingAnimation"></div>
                                  </div>
                                </div>
                                <div className="list d-flex align-self-center">
                                  <div className="group">
                                    <div className="loadingAnimation"></div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}

              <div
                className={
                  this.state.bulkSelect
                    ? "bankSync-TabFooter"
                    : "d-none bankSync-TabFooter"
                }
              >
                <div className="bulkSelectFooter">
                  <Form.Check
                    type="checkbox"
                    label=""
                    id="footerBulkSelect"
                    checked={this.state.bulkSelectRow}
                    onClick={(e: any) => this.footerBulkSelectAll(e)}
                  />
                  <span className="shift-selected-count">
                    {this.state.selectedRows.length} Selected
                  </span>
                  <div className="FooterHorLine"></div>
                  <span className="shift-selected-count"> Total: {Utils.currencyFormat(this.state.batchTotal)}</span>
                  <div className="action-group d-flex flex-row mr-auto">
                    {this.state.transactionFilterValue === 1 && (
                      <>
                        {this.state.hasMultipleTxn.length === 0 &&
                          !this.state.hasMultipleAdjustments &&
                          !this.state.isPendingPayment && (
                            <Button
                              disabled={this.state.confirmSpinner}
                              onClick={() => {
                                this.confirmTransation("bulk", "Completed");
                              }}
                            >
                              {this.state.confirmSpinner && (
                                <Spinner
                                  className="mr-2"
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                              )}
                              Confirm
                            </Button>
                          )}
                        <Button
                          className="btn-outline-primary"
                          disabled={this.state.confirmSpinner}
                          onClick={() => {
                            this.saveTransactionStatus("bulk", "Excluded");
                          }}
                        >
                          {this.state.confirmSpinner && (
                            <Spinner
                              className="mr-2"
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          )}
                          Exclude
                        </Button>
                      </>
                    )}
                    {this.state.transactionFilterValue === 3 && (
                      <>
                        <Button
                          className="btn-outline-primary"
                          disabled={this.state.confirmSpinner}
                          onClick={() => {
                            this.saveTransactionStatus("bulk", "Pending");
                          }}
                        >
                          {this.state.confirmSpinner && (
                            <Spinner
                              className="mr-2"
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          )}
                          Unexclude
                        </Button>
                      </>
                    )}
                  </div>
                  <Button
                    className="closeButton btn-ghost-light"
                    onClick={() => this.footerBulkClose()}
                  >
                    &times;
                  </Button>
                </div>
              </div>
              {this.state.isSlideOut && (
                <>
                  <TransactionSlideout
                    {...this.state.slideoutData}
                    hideSlideOut={this.hideSlideOut.bind(this)}
                    OpenInvoiceSlideOut={this.OpenInvoiceSlideOut.bind(this)}
                    transationSlideoutSave={this.transationSlideoutSave.bind(
                      this
                    )}
                    callFrom="BankSync"
                  />
                </>
              )}
              {this.state.isCCSlideout && (
                <CreditCardSlideout
                  {...this.state.slideoutData}
                  hideCCSlideOut={this.hideSlideOut.bind(this)}
                  transationSlideoutSave={this.transationSlideoutSave.bind(
                    this
                  )}
                  callFrom="BankSync"
                />
              )}
              {this.state.isTransferSlideOut && (
                <TransferSlideout
                  accDetails={this.state.trslideoutData}
                  hidValue={this.state.hidValue}
                  hotelName={this.state.hotelName}
                  uniqueNo={this.state.trUniqueNo}
                  pageType={"BankSyncTransfer"}
                  transferRequest={this.state.transferRequest}
                  {...this.state.trslideoutData}
                  hideTrSlideOut={this.hideTrSlideOut.bind(this)}
                  transationSlideoutSave={this.transationSlideoutSave.bind(
                    this
                  )}
                />
              )}
              {this.state.invoiceSlideout && (
                <InvoiceEntrySlideout
                  {...this.state.invoiceslideoutData}
                  hideSlideOut={this.hideSlideOut.bind(this)}
                  OpenInvoiceSlideOut={this.OpenInvoiceSlideOut.bind(this)}
                  isFromRepeating={false}
                  callFrom="BankSync"
                  transationSlideoutSave={this.transationSlideoutSave.bind(
                    this
                  )}
                />
              )}
              {this.state.showAdjustmentModal && (
                <AdjustmentModal
                  isAddAdjustment={this.state.showAdjustmentModal}
                  hideAdjustmentModal={this.hideAdjustmentModal.bind(this)}
                  confirmTxData={this.state.confirmTxData}
                  rowEntryType={this.state.rowEntryType}
                  {...this.state.adjustmentData}
                  isAlerted={this.state.isAlerted}
                  UpdateFromAdjustment={this.UpdateFromAdjustment}
                />
              )}
              {this.state.isShowSlideout && (
                <BankAccountSlideout
                  hidValue={this.state.hidValue}
                  hotelName={this.state.hotelName}
                  actionType={"edit"}
                  callFrom={"BankSync"}
                  oprID={this.state.CardUniqueNo}
                  accountName={this.state.bankName}
                  acctType={this.state.acctType}
                  hideSlideOut={this.hideBankSlideOut.bind(this)}
                  accDisplayName={this.state.accountName}
                />
              )}
              {this.state.isCCShowSlideout && (
                <CreditCardAccountSlideout
                  hidValue={this.state.hidValue}
                  hotelName={this.state.hotelName}
                  actionType={"edit"}
                  oprID={this.state.CardUniqueNo}
                  callFrom={"BankSync"}
                  acctType={"CC"}
                  hideCreditCardSlideOut={this.hideCreditCardSlideOut.bind(
                    this
                  )}
                  accDisplayName={this.state.accountName}
                  accountName={this.state.bankName}
                ></CreditCardAccountSlideout>
              )}
              {this.state.isBankWizardShow && (
                <BankSyncWizard
                  showBankSyncWizard={this.showBankSyncWizard}
                  hotelID={'All'}
                  reloadPage={this.reloadPage.bind(this)}
                  callFrom={callingPage}
                  oprID={this.state.CardUniqueNo}
                ></BankSyncWizard>
              )}
              {this.state.showManualUploadModal &&
                <ManualStatementUploadModal
                  {...this.state}
                  show={this.state.showManualUploadModal}
                  toggleManualStatUploadModal={this.toggleManualStatUploadModal.bind(this)} />
              }
              {this.state.showCommentModal && (
                <CommentWithoutList
                  hotelid={this.state.hidValue}
                  closeCommentModal={this.closeCommentModal}
                  addReviewComment={this.createAndFlagDraftTrx}
                  title={'Flag for Review'}
                />
              )}
              {this.state.isDraftTrxSlideout && (
                <>
                  {this.state.selectedAccountType === 'C' ? (
                    <CreditCardSlideout
                      accDetails={this.state.draftTrxAccDetails}
                      hidValue={this.state.hidValue}
                      hotelName={this.state.hotelName}
                      uniqueNo={this.state.draftTrxSlideoutData?.uniqueno}
                      oprId={this.state.draftTrxSlideoutData?.oprID}
                      vid={this.state.draftTrxSlideoutData?.vid}
                      pageType={this.state.draftTrxSlideoutData?.entryType.toLowerCase() === "ccwithdrawal" || "creditcardwithdrawal" ? "CCDEBIT" : "CCCREDIT"}
                      hideCCSlideOut={this.hideDraftTrxSlideout.bind(this)}
                      slideOutFrom="register-page-creditcard-slideout"
                      selectedRow={this.state.draftTrxSelectedRow}
                      callFrom={this.state.draftTrxSelectedRow.isDraftUpdate === "Yes" ? "Draft" : "BankSync"}
                    />
                  ) : (
                    <TransactionSlideout
                      accDetails={this.state.draftTrxAccDetails}
                      hidValue={this.state.hidValue}
                      hotelName={this.state.hotelName}
                      uniqueNo={this.state.draftTrxSlideoutData?.uniqueno}
                      pageType={this.getEtype(this.state.draftTrxSlideoutData?.entryType, "main")}
                      hideSlideOut={this.hideDraftTrxSlideout.bind(this)}
                      OpenInvoiceSlideOut={this.OpenInvoiceSlideOut.bind(this)}
                      selectedRow={this.state.draftTrxSelectedRow}
                      isFromRepeating={false}
                      callFrom={this.state.draftTrxSelectedRow.isDraftUpdate === "Yes" ? "Draft" : "BankSync"}
                    />
                  )
                  }
                </>
              )}
              {this.state.isLoader && (
                <div className="loader-spinner reconciliation-summary d-flex justify-content-center align-items-center">
                  <Spinner animation="border" variant="success" />
                </div>
              )}

            </Container>
          </div>
        </div>
      </React.Fragment>
    );
  }

  showBankSyncWizard = () => {
    callingPage = "";
    this.setState({ isBankWizardShow: !this.state.isBankWizardShow });
  }

  reloadPage = (mode) => {
    if (mode == 2) {
      let arr: any = {};
      arr.id = this.state.CardUniqueNo;
      arr.status = "active";
      this.handleaccountNameSelectedItem(1, arr);
    }
    else {
      this.setState({ isAfterAddConnection: true });
      this.selectHotelDropDown(this.state.selectedEhid);
    }
  }

  bankSyncOnReconnect = () => {
    callingPage = "BankSync";
    setTimeout(() => {
      this.setState({ isBankWizardShow: !this.state.isBankWizardShow });
    }, 100);
  }
}
