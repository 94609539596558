import * as React from "react";
// import { Form } from "react-bootstrap";
import { Hotel } from './../../../Common/Services/Hotel';
import { IHotelResponseDto } from "../../../Common/Contracts/IHotel";
import { MultiHotelSelector } from "./MultiHotelSelector";

export class AccountSelector extends React.Component<any, any> {
  private multiselectChild: any;
  private SingleselectChild: any;

  constructor(props: any) {
    super(props);
    this.multiselectChild = React.createRef();
    this.state = {
      hotelList: [],
      selectedLetterCode: [],
    };
  }
  componentDidMount() {

    let getUserHotelAccess: any;
  //   if(this.props?.isAll) {
  //     if(this.props?.notIncludeEnterpriseAccounts) {
  //       getUserHotelAccess = Hotel.getUserHotelAccessAll(this.props?.moduleName, true,this.props?.showOnlyHotelAccounts);
  //    } else {
  //       getUserHotelAccess = Hotel.getUserHotelAccessAll(this.props?.moduleName,false,this.props?.showOnlyHotelAccounts);
  //    }
  //  } else {
       
        // getUserHotelAccess = Hotel.getUserHotelAccessDashboard(this.props?.moduleName, this.props?.notIncludeEnterpriseAccounts ,this.props?.showOnlyHotelAccounts, this.props?.featureType);
        getUserHotelAccess = Hotel.getUserHotelAccessBIDashboard(this.props?.hotelTypes, this.props?.featureIds);
     
    // }
    getUserHotelAccess.then(async (result: IHotelResponseDto[] | null) => {
        if (result != null && result.length > 0) {
          let hotelList: any[] = [];
          result.forEach((element) => {
            let newItem = {} as any;
            newItem.hotelID = element.hotelID;
            newItem.value = element.lettercode;
            newItem.description = element.hotelName;
            newItem.isChecked = false;
            newItem.hotelType=  element.hotelType;
            hotelList.push(newItem);
          });
          if(typeof this.props["hasHotelDDLHaveSingleItem"] === "function") {
          if(hotelList?.length === 1) {
            this.props.hasHotelDDLHaveSingleItem(true)
            hotelList = hotelList?.map(item => {
              if(item.hotelID === hotelList[0]?.hotelID) {
                item.isChecked = true;
              }
              return item;
            });
            this.updateMultiSelectItem([hotelList[0]]);
          } else {
            this.props.hasHotelDDLHaveSingleItem(false)
          
          }

         
        }

        if(!this.props.defaultValue) {
          hotelList?.map(x=> {
             x.isChecked =true;
             return x;
          });
this.updateMultiSelectItem(hotelList)
        }
        
          this.setState({ hotelList: hotelList });
          if(typeof this.props?.getHotels ==="function"){
            this.props?.getHotels(hotelList);
            }
        }
      })
      .catch((error) => {
        // Need to handle this
        console.log(error);
      });
  }

  updateHotelList = (newHotelList, isSelectAll: boolean = false) => {
    
    let hotelList = [...this.state.hotelList] 
    if(isSelectAll && newHotelList.length === 0) {
      hotelList = hotelList.map(hotel =>  {
          hotel.isChecked = true; 
        return hotel
      } );
    } else {

    const newHotelIds = newHotelList.map(r=> r.hotelID);
    hotelList = hotelList.map(hotel =>  {
      if(newHotelIds.includes(hotel.hotelID)) {
        hotel.isChecked = true; 
      } else {
        hotel.isChecked = false; 
      }
      return hotel
    } );
  }


      this.setState({ hotelList }, () => {
        this.props.handleBusinessAccountChange(hotelList?.filter(r=> r?.isChecked));
      });
      if( typeof this.props["getHotels"] ==="function"){
      this.props?.getHotels(hotelList);
      }

 
  };
  

  removalChips = (removalChips) => {
    let hotelList=[...this.state.hotelList]
    // let tempHotelList: any = [...this.state.hotelList];
    if(removalChips){
      let parentId = removalChips.uniqueno;
      for (let i = this.state.hotelList.length - 1; i >= 0; i--) {
        if (Number(this.state.hotelList[i].rGroupID) === Number(parentId)) {
          hotelList.splice(i, 1);
        }
      }
      // hotelList.pop();
      this.setState({ hotelList });
      if(typeof this.props?.getHotels ==="function"){
        this.props?.getHotels(hotelList);
        }

    }else{
      this.setState({hotelList:[]})
      if(typeof this.props?.getHotels ==="function"){
        this.props?.getHotels(hotelList);
        }

    }
    
  };


  componentDidUpdate = (prevprops , prevState)=> {
    let hotelList = [...this.state.hotelList];
    const   { defaultValue} = this.props; 
    if(((prevState?.hotelList?.length !== hotelList?.length) || (prevprops.defaultValue !== defaultValue) )  &&  hotelList?.length > 0  && defaultValue?.length > 0 ) {
    let hotelIDs = defaultValue.split(",")?.map(r=> +r); 
    const selectedHotels =  hotelList?.filter(r=> hotelIDs?.includes(r.hotelID))
    hotelIDs = hotelIDs.filter(r=> selectedHotels?.map(r=> r?.hotelID)?.includes(r));
    const selectLength = selectedHotels?.filter(r=> r?.isChecked)?.length;
    //if(hotelIDs?.length !== selectLength) {
      hotelList = hotelList?.map(item => {
            if(hotelIDs?.includes(item?.hotelID)) {
              item.isChecked = true;
            } else {
              item.isChecked = false;
            }
            return item;
      }) 
      this.setState({hotelList});
      if(typeof this.props?.getHotels ==="function"){
      this.props?.getHotels(hotelList);
      }

    //}
}

if(prevprops?.defaultValue !==defaultValue && !defaultValue)
{
  let hotelList = [...this.state.hotelList];
  hotelList = hotelList?.map(item => {
          item.isChecked = false;
        return item;
  }) 
  this.setState({hotelList});
  if(typeof this.props?.getHotels ==="function"){
    this.props?.getHotels(hotelList);
    }

  }
}


  updateMultiSelectItem = (selectedItem: any) => {

    this.setState({ selectedLetterCode: selectedItem }, () => {
      this.props.handleBusinessAccountChange(selectedItem, true);
    });
  };

  updateSingleSearchItem = (selectedItem: any) => {
    this.setState({ selectedLetterCode: selectedItem }, () => {
      this.props.handleBusinessAccountChange(selectedItem, true);
    });
  };

  dataChange = () => {
    this.setState({ isUnsavedChanges: true });
  };

  // disablebuton=(e: React.MouseEvent<HTMLElement>)=>
  // {
  //   
  //   const target = e.target as HTMLElement;
  //   let attr = target.getAttribute("btnselection");
  //   console.log(attr);
  // style={{visibility: this.state.show ? "visible" : "hidden",}}
  // }

  render() {
    const {isDashboard = false} =this.props
    return (
      <div
        id="businessAccountSelector"
        className={this.props.isDisabled ? "account-selector noPointer" : "account-selector" }>
        {/* <Form.Label className="sec-ttl">Select Accounts</Form.Label> */}
       
          {/* <Form.Group controlId="hotelAccountSelector">
            <Form.Label className="sub-ttl">Hotel Accounts</Form.Label>
          </Form.Group> */}
        
        <MultiHotelSelector
          isDashboard={isDashboard}
          ref={this.SingleselectChild}
          hasHotelDDLHaveSingleItem = {this.props?.hasHotelDDLHaveSingleItem}
          itemList={this.state.hotelList}
          showBlueBg={this.props?.showBlueBg}
          updateMultiSelectItem={this.updateMultiSelectItem.bind(this)}
          dataChange={this.dataChange.bind(this)}
         // disablebuton={this.disablebuton}
          isSelectAll={true}
          filtertitle={"Filter EHIDs"}
          selectedLabel={this.props.selectedLabel}
        />
      </div>
    );
  }
}
