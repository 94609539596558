import React from "react";
import { Col, Dropdown, Form, Row } from "react-bootstrap";
import { AllHotelBrandSelector } from "../../../Common/Services/ReportGroup";
import { ReportGroupSelector } from "../../Reports/ReportGroupSelector";
import { AccountSelector } from './AccountSelector';
import { ReportGroupSelectorForChart } from "./ReportGroupSelectorForChart";

export class HotelGroupSelector extends React.Component<any, any> {
    private buisnessAccountRef: any;
    constructor(props: any) {
        super(props);
        this.buisnessAccountRef = React.createRef();
        this.state = {
            HotelBrand: "",
            subGroupSelectValue: this.props?.subGroupSelectValue,
            showAllHotels: false,
            selectedHIDs: this.props?.hotelIds,
            hasHotelDDLHaveSingleItem: false
        };
    }

    handleReportGroupChange = (item: any) => {
        if (item?.groupName) {
            this.setState({
                subGroupSelectValue: item?.groupName === "All" ? "" : item?.groupName,
            });
            this.handleGroupItemChange(item);
        }
    };

    handleGroupItemChange = (item: any, initial = false) => {
        if (!item) {
            return false;
        }
        const childcall: any = this.buisnessAccountRef.current;
        if (+item?.groupID === 0 || +item?.groupID === -1) {
            this.setState({ HotelBrand: "" });
            if (+item?.groupID === 0) {
                childcall.updateHotelList([], true);
            } else {
                childcall.updateHotelList([]);
            }
            this.setState({
                subGroupSelectValue: item.groupName,
                groupName: item.groupName,
            });
            return;
        }

        this.setState({
            HotelBrand: item.subGroupName,
            subGroupSelectValue: item.subGroupName,
        });
        AllHotelBrandSelector.GetHotelIdsByGroupIdBIDashboard(
            item.subGroupID,
            this.props?.hotelTypes,
            this.props?.featureIds
        ).then(async (result: any | null) => {
            if (result != null) {
                const hotelList: any = [];
                result.forEach((element) => {
                    let newItem = {} as any;
                    newItem.hotelID = element.hotelID;
                    newItem.isChecked = true;
                    hotelList.push(newItem);
                });

                if (childcall.current !== null) {
                    if (initial && this.state.selectedHIDs?.length > 0) {
                        let hotelIDs = this.state.selectedHIDs.split(",")?.map((r) => +r);
                        const selectedHotels = hotelList?.map((r) => {
                            if (hotelIDs?.includes(r.hotelID)) {
                                r.isChecked = true;
                            } else {
                                r.isChecked = false;
                            }
                            return r;
                        });

                        childcall.updateHotelList(selectedHotels);
                    }

                    if (!initial) {
                        childcall.updateHotelList(hotelList);
                    }
                }
            }
        });
    };

    hasHotelDDLHaveSingleItem = (value) => {
        if (value) {
            this.setState({ hasHotelDDLHaveSingleItem: value });
        }
    }

    handleBusinessAccountChange = (value: any, reset = false) => {
        let newItem = "";
        value.forEach((element: any) => {
            newItem += element.hotelID + ",";
        });
        newItem = newItem?.substring(0, newItem.length - 1);

        if (reset) {
            this.setState({
                groupName: "",
                HotelBrand: "",
                subGroupSelectValue: this.props.isDashboard ? "Group" : "Select a Group",
            }, () => this.props?.onHotelChange(newItem, this.state.subGroupSelectValue, (value?.length === 1 ? value[0]?.description : "")));
        } else {
            this.props?.onHotelChange(newItem, this.state.subGroupSelectValue, (value?.length === 1 ? value[0]?.description : ""));
        }

        this.setState({
            selectedHIDs: newItem
        });
    };


    updateHotelList = (selectedHotels: any[]) => {
        const childcall: any = this.buisnessAccountRef.current;
        if (childcall.current !== null) {
            childcall.updateHotelList(selectedHotels);
        }
    }
    render() {
        const { hasHotelDDLHaveSingleItem, showAllHotels, selectedHIDs,


        } = this.state;

        const { subGroupSelectValue, isDashboard = false } = this.props;
        return (
            <div className="reportGrpCont d-flex flex-direction-row">
                <div className="reportGrpSelector">
                    <div className="">
                        {!isDashboard && <label>Group</label>}
                        <ReportGroupSelectorForChart
                            isDashboard={isDashboard}
                            hasHotelDDLHaveSingleItem={hasHotelDDLHaveSingleItem}
                            defaultValue={subGroupSelectValue}
                            showBlueBg={false}
                            moduleName={this.props?.moduleName}
                            // featureType={this.props?.featureType}
                            hotelTypes={this.props?.hotelTypes}
                            featureIds={this.props?.featureIds}
                            isAll={showAllHotels}
                            notIncludeEnterpriseAccounts={this.props?.notIncludeEnterpriseAccounts}
                            showOnlyHotelAccounts={this.props?.showOnlyHotelAccounts}
                            handleReportGroupChange={
                                this.handleReportGroupChange
                            }
                        />
                    </div>
                </div>
                <div className="">
                    {!isDashboard && <label>Accounts</label>}
                    <div className="ehid-picker d-flex account-selector-dropdown">
                        <AccountSelector
                            isDashboard={isDashboard}
                            hasHotelDDLHaveSingleItem={this.hasHotelDDLHaveSingleItem}
                            defaultValue={selectedHIDs}
                            custom
                            showBlueBg={false}
                            moduleName={this.props?.moduleName}
                            hotelTypes={this.props?.hotelTypes}
                            featureIds={this.props.featureIds}
                            ref={this.buisnessAccountRef}
                            handleBusinessAccountChange={
                                this.handleBusinessAccountChange
                            }
                            notIncludeEnterpriseAccounts={this.props?.notIncludeEnterpriseAccounts}
                            showOnlyHotelAccounts={this.props?.showOnlyHotelAccounts}
                            getHotels={this.props?.getHotels}
                            selectedLabel={this.props.selectedLabel}
                        />
                    </div>
                </div>
            </div>
        );
    }
}