import * as React from "react";
import { Form, Image, OverlayTrigger, Tooltip } from "react-bootstrap";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import OutsideClickHandler from "react-outside-click-handler";
import  _  from "lodash";

const getSelectedItemsList =(list)=>{
  const data=list.map(item  => item?.description)
  return data.join(", ")
}

const getSelectedItemsLength =(list)=>{
  const data=list.map(item  => item?.description)
  return data.join("").length
}
export class MultiSelectUsersRoles extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      selectedItems: [],
      filterData: [],
      filterDataCopy: [],
      opened: false,
      itemList: props.itemList,
      isSelectAll: props.isSelectAll,
      filtertitle: props.filtertitle,
      isSelect: true,
      inputValue: "",
      isTooltipVisible: "visible",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
    this.handleSelectAll = this.handleSelectAll.bind(this);
    this.handleClearAll = this.handleClearAll.bind(this);
  }
  componentDidMount() {
    const activeChip = this.state.itemList.filter(
      (itemList: { isChecked: boolean }) => itemList.isChecked === true
    );
    this.setState({
      selectedItems: activeChip,
      filterData: this.state.itemList,
    });
  }


  componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): void {
    if(!_.isEqual(prevProps.dependent,this.props.dependent)){
      this.setState({ itemList: this.props.itemList });
    }
  }

  componentWillReceiveProps(nextProps: { itemList: any }) {
    // this.setState({ itemList: nextProps.itemList });
  }

  showSelected = () => {
    const activeChip = this.props.itemList.filter(
      (itemList: { isChecked: boolean }) => itemList.isChecked === true
    );
    this.setState({
      selectedItems: activeChip,
      filterData: this.state.itemList,
    });
  };

  /**For remove COA(s) list **/
  handleRemoveSpecificChip = (idx: number) => () => {
    const itemList = [...this.state.itemList];
    const selectedItems = [...this.state.selectedItems];
    const removeSelect = this.getIndex(selectedItems[idx].value, "removeChip");
    this.props.dataChange();
    if (removeSelect !== -1) {
      itemList[removeSelect].isChecked = false;
    }
    selectedItems.splice(idx, 1);
    this.setState({ selectedItems });
    this.setState({ itemList });
    const coaSelectedItem = this.state.itemList.filter(
      (itemList: { isChecked: boolean }) => itemList.isChecked === true
    );
    this.props.updateMultiSelectItem(coaSelectedItem);
  };

  handleCheckCheckbox = (itemValue) => {
    const { itemList } = this.state;
    const updatedList = itemList.map((item) =>
        item.value === itemValue ? { ...item, isChecked: !item.isChecked } : item
    );

    const selectedItems = updatedList.filter((item) => item.isChecked);

    this.setState({
        itemList: updatedList,
        filterData: updatedList,
        selectedItems,
    });

    if (this.props.updateMultiSelectItem) {
        this.props.updateMultiSelectItem(selectedItems);
    }
  };
  /************checkbox check unchek********/
  // handleCheckCheckbox = (idx: any) => {
  //   this.props.dataChange();

  //   const itemList = [...this.state.itemList];
  //   const selectedItems = [...this.state.selectedItems];

  //   // Find the index of the clicked item
  //   const index = itemList.findIndex((xvalue) => xvalue.value === idx);

  //   const isAlreadyExist = selectedItems.find((item) => item?.value == idx);
  //   // Toggle the isChecked state
  //   itemList[index].isChecked = !isAlreadyExist;

  //   this.setState({
  //     itemList,
  //     filterDataCopy: itemList,
  //     filterData:itemList
  //   });

  //   // Create the item object
  //   const item = {
  //     ...itemList[index],
  //     value: itemList[index].value,
  //     isChecked: itemList[index].isChecked,
  //   };

  //   // Add or remove item from selectedItems
  //   if (item.isChecked) {
  //     const _selectedItems = [...selectedItems, item];
  //     this.setState({
  //       selectedItems: _selectedItems,
  //     });
  //     this.props.updateMultiSelectItem(_selectedItems);
  //   } else {
  //     const removeIndex = this.getIndex(item.value, "selectbox");
  //     selectedItems.splice(removeIndex, 1);
  //     this.setState({ selectedItems });
  //     this.props.updateMultiSelectItem(selectedItems);
  //   }

  //   // // Update parent component
  //   // const coaSelectedItem = itemList.filter((x) => x.isChecked);
  // };

  //Get array Index
  getIndex(val: any, type: string) {
    if (type === "removeChip") {
      let itemList = [...this.state.itemList];
      return itemList.findIndex((obj) => obj.value === val);
    } else {
      let selectedItems = [...this.state.selectedItems];
      return selectedItems.findIndex((obj) => obj.value === val);
    }
  }
  handleChange(e: { target: { value: string } }) {
    const updatedList = this.state.itemList.filter(
      (item: { description: string }) => {
        return (
          item.description.toLowerCase().indexOf(e.target.value.toLowerCase()) >
          -1
        );
      }
    );

    this.setState({ filterData: updatedList, inputValue: e.target.value });
  }

  updateSavedItem(data: any) {
    let allCoaList = [...this.state.itemList];
    allCoaList.forEach((element) => {
      element.isChecked = false;
    });
    this.setState({ itemList: allCoaList });

    // data.forEach((element: { value: any }) => {
    //   this.state.itemList.filter(
    //     (itemList: { value: any }) => itemList.value === element.value
    //   )[0].isChecked = true;
    // });
    data.forEach((element /*: { value: any }*/) => {
      let hasIschecked = this.state.itemList.filter(
        (itemList: { value: any }) => itemList.value === element.value
      );
   
      if (hasIschecked.length > 0) {
        hasIschecked[0].isChecked = true;
      }
    });

    this.setState({ selectedItems: data });
  }

  handleClick() {
    if (this.props.multiSelectOpen) {
      this.props.multiSelectOpen();
    }

    this.setState((prevState: any) => ({
      opened: !prevState.opened,
    }));
    this.setState({ inputValue: "", filterData: this.state.itemList });
  }

  handleOutsideClick(e: any) {
    this.setState({ inputValue: "", opened: false });
  }

  handleSelectAll(e: any) {
    this.props.dataChange();
    let allItemList = [...this.state.itemList];
    allItemList.forEach((element) => {
      element.isChecked = true;
    });
    this.setState({ itemList: allItemList, isSelect: false, opened: false });
    this.setState({ selectedItems: allItemList });
    this.props.updateMultiSelectItem(allItemList);
  }

  handleClearAll(e: any) {
    this.props.dataChange();
    let allItemList = [...this.state.itemList];
    allItemList.forEach((element) => {
      element.isChecked = false;
    });
    this.setState({ itemList: allItemList, isSelect: true });
    this.setState({ selectedItems: [] });
    this.props.updateMultiSelectItem([]);
  }

  render() {
    const hoverOutEllipsis = () => {
      $(".tooltip").removeClass("show");
    };

    const employees = this.state.filterData.filter((item) => item.type == 1);
    const roles = this.state.filterData.filter((item) => item.type == 2);

    let btnDisabled =
      this.props.isDisable !== undefined && this.props.isDisable;

    return (
      <OutsideClickHandler onOutsideClick={this.handleOutsideClick}>
        <div
          className={
            this.state.opened ? "multiSelectSearch opened" : "multiSelectSearch"
          }
        >
          <div className="fieldArea">
            <div
              className="chip bg-white"
              // tabIndex={this.props?.tabIndex || 0}
              onKeyUp={(e) =>
                this.props?.tabIndex && e?.keyCode === 9
                  ? this.state.opened === false
                    ? this.handleClick()
                    : this.handleOutsideClick(e)
                  : {}
              }
              onClick={this.handleClick}
            >
              <div>
                {this.state.selectedItems.length == 0 ? (
                  <span className="placeholder">Search users or roles</span>
                ) : getSelectedItemsLength(this.state.selectedItems) > 24 ? (
                  <OverlayTrigger
                    key={"overlay1-" + 0}
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip
                        id={"coa" + 0}
                        // style={{ visibility: this.state.isTooltipVisible }}
                      >
                        {getSelectedItemsList(this.state.selectedItems)}
                      </Tooltip>
                    }
                  >
                    <span style={{ color: "#000" }}>
                      {this.state.selectedItems.length} Selected
                    </span>
                  </OverlayTrigger>
                ) : (
                  this.state.selectedItems.map((item, idx) => {
                    return (
                      <div className="chipSelect" id={idx} key={idx}>
                        <div className="chipVal">{item.value}</div>
                        <button
                          id="btnremove"
                          type="button"
                          className="cross"
                          onClick={this.handleRemoveSpecificChip(idx)}
                          disabled={
                            this.props.managePermission === undefined
                              ? false
                              : !this.props.managePermission
                          }
                        >
                          <svg
                            width="8"
                            height="8"
                            viewBox="0 0 8 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.79465 0.229492L3.96598 3.05749L1.13798 0.229492L0.195312 1.17216L3.02331 4.00016L0.195312 6.82816L1.13798 7.77083L3.96598 4.94282L6.79465 7.77083L7.73731 6.82816L4.90931 4.00016L7.73731 1.17216L6.79465 0.229492Z"
                              fill="#6A6E73"
                            />
                          </svg>
                        </button>
                      </div>
                    );
                  })
                )}
              </div>
            </div>
            <div className="coaSearch">
              <div className="dropDownPanel">
                <div className="SearchInput">
                  <input
                    key={"text-Filter"}
                    type="text"
                    className="form-control"
                    onChange={this.handleChange}
                    placeholder={this.state.filtertitle}
                    value={this.state.inputValue}
                  />
                </div>
                <ul>
                  <li className="list-header">
                    <small>People</small>
                  </li>
                  {employees.length > 0 ? (
                    employees.map((item, idx) => (
                      <li
                        key={`employee-${idx}`}
                        className={`${item.isChecked ? "selected" : ""}`}
                        onClick={() => this.handleCheckCheckbox(item.value)}
                      >
                        {!item.imagePath ? (
                          <svg
                            width="32"
                            height="32"
                            viewBox="0 0 40 40"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              cx="20"
                              cy="20"
                              r="20"
                              fill="#E5F5FF"
                            ></circle>
                            <path
                              d="M20.0013 20.2819C22.5548 20.2819 24.6422 18.1945 24.6422 15.6409C24.6422 13.0874 22.5548 11 20.0013 11C17.4477 11 15.3604 13.0874 15.3604 15.6409C15.3604 18.1945 17.4477 20.2819 20.0013 20.2819Z"
                              fill="#065A8C"
                            ></path>
                            <path
                              d="M27.9658 23.9905C27.8442 23.6865 27.6821 23.4027 27.4997 23.1393C26.5674 21.7612 25.1286 20.8492 23.5073 20.6263C23.3047 20.606 23.0817 20.6465 22.9196 20.7681C22.0684 21.3964 21.0551 21.7206 20.0013 21.7206C18.9474 21.7206 17.9341 21.3964 17.0829 20.7681C16.9208 20.6465 16.6979 20.5857 16.4952 20.6263C14.8739 20.8492 13.4148 21.7612 12.5028 23.1393C12.3204 23.4027 12.1583 23.7067 12.0367 23.9905C11.9759 24.1121 11.9962 24.2539 12.057 24.3755C12.2191 24.6592 12.4217 24.943 12.6041 25.1862C12.8878 25.5713 13.1918 25.9157 13.5364 26.24C13.8201 26.5237 14.1444 26.7872 14.4686 27.0506C16.0696 28.2464 17.9949 28.8746 19.981 28.8746C21.9671 28.8746 23.8924 28.2463 25.4934 27.0506C25.8176 26.8075 26.1419 26.5237 26.4256 26.24C26.7499 25.9157 27.0741 25.5712 27.3579 25.1862C27.5605 24.9227 27.743 24.6592 27.9051 24.3755C28.0063 24.2539 28.0266 24.112 27.9658 23.9905Z"
                              fill="#065A8C"
                            ></path>
                          </svg>
                        ) : (
                          <Image
                            src={item.imagePath}
                            roundedCircle
                            width={32}
                            height={32}
                            className="me-3"
                          />
                        )}
                        <div style={{ flex: 1 }} className="d-flex">
                          <div style={{ flex: 1 }}>
                            <div style={{width: "220px"}}><EllipsisWithTooltip placement="bottom">{item.employee}</EllipsisWithTooltip></div>
                            <small className="text-muted d-block" style={{width: "220px"}}>
                              <EllipsisWithTooltip placement="bottom">{item?.roleName || "No Role"}</EllipsisWithTooltip>
                            </small>
                          </div>

                          <div>{item.isChecked ? 
                              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.49998 11.6895L5.03023 9.21973L3.96973 10.2802L7.49998 13.8105L14.7802 6.53023L13.7197 5.46973L7.49998 11.6895Z" fill="#2CAF92"></path></svg>
                              : null}
                          </div>
                        </div>
                      </li>
                    ))
                  ) : (
                    <div className="pl-2">No Peoples</div>
                  )}

                  <li className="list-header">
                    <small>Roles</small>
                  </li>
                  {roles.length > 0 ? (
                    roles?.map((item, idx) => (
                      <li
                        key={`role-${idx}`}
                        className={`${item.isChecked ? "selected" : ""}`}
                        onClick={() => this.handleCheckCheckbox(item.value)}
                      >
                        {!item.imagePath ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="none"
                          >
                            <circle cx="16" cy="16" r="16" fill="#D9D9D9" />
                            <path
                              d="M8 17C9.1 17 10 16.1 10 15C10 13.9 9.1 13 8 13C6.9 13 6 13.9 6 15C6 16.1 6.9 17 8 17ZM9.13 18.1C8.76 18.04 8.39 18 8 18C7.01 18 6.07 18.21 5.22 18.58C4.48 18.9 4 19.62 4 20.43V22H8.5V20.39C8.5 19.56 8.73 18.78 9.13 18.1ZM24 17C25.1 17 26 16.1 26 15C26 13.9 25.1 13 24 13C22.9 13 22 13.9 22 15C22 16.1 22.9 17 24 17ZM28 20.43C28 19.62 27.52 18.9 26.78 18.58C25.93 18.21 24.99 18 24 18C23.61 18 23.24 18.04 22.87 18.1C23.27 18.78 23.5 19.56 23.5 20.39V22H28V20.43ZM20.24 17.65C19.07 17.13 17.63 16.75 16 16.75C14.37 16.75 12.93 17.14 11.76 17.65C10.68 18.13 10 19.21 10 20.39V22H22V20.39C22 19.21 21.32 18.13 20.24 17.65ZM12.07 20C12.16 19.77 12.2 19.61 12.98 19.31C13.95 18.93 14.97 18.75 16 18.75C17.03 18.75 18.05 18.93 19.02 19.31C19.79 19.61 19.83 19.77 19.93 20H12.07ZM16 12C16.55 12 17 12.45 17 13C17 13.55 16.55 14 16 14C15.45 14 15 13.55 15 13C15 12.45 15.45 12 16 12ZM16 10C14.34 10 13 11.34 13 13C13 14.66 14.34 16 16 16C17.66 16 19 14.66 19 13C19 11.34 17.66 10 16 10Z"
                              fill="#6A6E73"
                            />
                          </svg>
                        ) : (
                          <Image
                            src={item.imagePath}
                            roundedCircle
                            width={32}
                            height={32}
                            className="me-3"
                          />
                        )}

                        <div style={{width: "220px"}}><EllipsisWithTooltip placement="bottom">{item.roleName}</EllipsisWithTooltip></div>
                        <div>{item.isChecked ? 
                          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.49998 11.6895L5.03023 9.21973L3.96973 10.2802L7.49998 13.8105L14.7802 6.53023L13.7197 5.46973L7.49998 11.6895Z" fill="#2CAF92"></path></svg>
                          : null
                          }
                        </div>
                      </li>
                    ))
                  ) : (
                    <div className="p-2">No Roles</div>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </OutsideClickHandler>
    );
  }
}
