import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import CustomLegends from "./CustomLegends";
import { getCommonOptions } from "./cardConfig";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { isValidChartData } from "../../Data/options";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarChartCard = ({
  data,
  chartTitle,
  formatType,
  metric,
  isDummyData,
}) => {
  const renderBarChart = () => {
    if (!data || !isValidChartData(data)) {
      return <>Something went wrong.</>;
    }

    const datasets = data.datasets?.map((x: any) => x);
    const commonOptions = getCommonOptions(formatType, metric, isDummyData);

    const barOptions: any = {
      ...commonOptions,
      categoryPercentage: 1,
      barPercentage: 0.75,
      plugins:{
        ...commonOptions.plugins,
        tooltip:{
          ...commonOptions.plugins.tooltip,
          mode:"x"
        }
      }
      // maintainAspectRatio:false
    };

    return (
      <>
        <Bar data={data} options={barOptions} />
        <CustomLegends datasets={datasets} />
      </>
    );
  };

  return (
    <div
      className="bar-chart-metric chart-metric"
      style={{ width: "100%", margin: "0 auto" }}
    >
      <h3 className="chart-heading" style={{ whiteSpace: "pre-wrap" }}>
        <EllipsisWithTooltip placement="top">{chartTitle}</EllipsisWithTooltip>
      </h3>
      {renderBarChart()}
    </div>
  );
};

export default BarChartCard;
