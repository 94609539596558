import React from 'react'
import emptyDashboardImg from "../../../Common/Assets/Images/empty-dashboard.png"
function EmptyDashboard() {
  return (
    <div className='empty-dashboard-view text-center'>
      <div>
        <h6>Ready to start analyzing?</h6>
        <p>Create your own dashboard or use one of our templates to get started!</p>
        <div className="img mx-auto">
          <img src={emptyDashboardImg} className='img-fluid' alt="empty dashboard view" />
        </div>
      </div>
    </div>
  )
}

export default EmptyDashboard
