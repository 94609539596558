import React, { useState, useRef, useEffect, useMemo } from "react";
import {
  Modal,
  Form,
  Button,
  ListGroup,
  Image,
  Spinner,
  Dropdown,
} from "react-bootstrap";
import { toast } from "react-toastify";
import { isTemplateExpression } from "typescript";
import { PortfolioDashboard } from "../../../Common/Services/PortfolioDashboard";
import { MultiSelectUsersRoles } from "./MultiSelectUsersRoles";
import "./ShareDashboard.scss";
import defaultUser from "../../../Common/Assets/Images/profile.png";

const permissionLevels: any[] = [
  { id: "1", name: "Can View", description: "Can only view" },
  {
    id: "2",
    name: "Can Edit",
    description:
      "Gives full access to Add Metric Cards, Change Layout, and Share",
    isSensitive: true,
  },
  {
    id: "3",
    name: "Remove",
    description: "Remove user completely",
    isRemove: true,
  },
];

const getPermissionLevels = (canView = 0, canWrite = 0) => {
  return permissionLevels.find((item) => {
    if (item.id == "1" && canView) {
      return true;
    } else if (item.id == "2" && canWrite) {
      return true;
    } else if (item.id == "3") {
      return true;
    }
    return false;
  });
};

const PermissionsDropdown = ({
  selectedPermission,
  setSelectedPermission,
  permissionList,
}) => {
  return (
    <Dropdown className="view-dropdown">
      <Dropdown.Toggle>
        <div className="btn-text">{selectedPermission.name}</div>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {permissionList.map((level) => (
          <Dropdown.Item
            key={level.id}
            onClick={() => setSelectedPermission(level)}
          >
            <div
              className={
                level.isSensitive
                  ? "d-flex align-items-center justify-content-between"
                  : ""
              }
            >
              <h6
                className={
                  level.name.toLowerCase() == "remove" ? "show-red" : ""
                }
              >
                {level.name}
              </h6>
              {level.isSensitive && <div className="badge">Sensitive</div>}
            </div>
            <p> {level.description}</p>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default function ShareDashboard({
  dashboardId,
  show,
  employees,
  dashboardAccessList,
  handleClose,
  onShareDashboard,
}) {
  const multiselectChild = useRef<any>();
  const [searchQuery, setSearchQuery] = useState("");

  const [selectedUsersRoles, setSelectedUsersRoles] = useState<any[]>([]);
  const [userRoleAccessList, setUserRoleAccessList] = useState<any[]>([]);
  const [isSubmitting, setIsSubmitting] = useState<any>(false);

  const [selectedPermission, setSelectedPermission] = useState<any>({
    id: "1",
    name: "Can View",
  });

  useEffect(() => {
    setUserRoleAccessList(dashboardAccessList);
  }, [dashboardAccessList]);

  const SaveDashboardUserRoles = () => {
    setIsSubmitting(true);

    const userRoles = selectedUsersRoles.map((item) => ({
      UserUniqueno: item.type == 1 ? item.userUniqueno : 0,
      RoleId: item.type == 2 ? item.roleId : 0,
      CanView: selectedPermission.id == "1" ? 1 : 0,
      CanWrite: selectedPermission.id == "2" ? 1 : 0,
    }));

    const request = {
      DashboardId: dashboardId,
      UserRoles: userRoles,
    };

    PortfolioDashboard.SaveDashboardUserRoles(request)
      .then((res) => {
        if (res.success) {
          // toast.success("Dashboard Access granted successfully.", {
          //   position: toast.POSITION.BOTTOM_RIGHT,
          // });
          multiselectChild.current.handleClearAll();
          onShareDashboard();
        } else {
          toast.error("Something is Wrong", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch((err) => {
        toast.error("Something is Wrong", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const updateMultiSelectItem = (selectedItems) => {
    setSelectedUsersRoles(selectedItems);
  };

  const UpdateDashboardUserRole = (
    userRole,
    canView,
    canWrite,
    isReload = false
  ) => {
    const request = {
      DashboardId: dashboardId,
      UserUniqueno: userRole.type == 1 ? userRole.userUniqueno : 0,
      RoleId: userRole.type == 2 ? userRole.roleId : 0,
      CanView: canView,
      CanWrite: canWrite,
    };
    PortfolioDashboard.UpdateDashboardUserRole(request)
      .then((res) => {
        if (res.success) {
          // toast.success("Dashboard Access changed successfully.", {
          //   position: toast.POSITION.BOTTOM_RIGHT,
          // });

          if (isReload) {
            onShareDashboard();
          }
        } else {
          toast.error("Something is Wrong", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch((err) => {
        toast.error("Something is Wrong", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };

  const handleEditPermission = (permission, userRole) => {
    let canView = permission.id == 1 ? 1 : 0;
    let canWrite = permission.id == 2 ? 1 : 0;

    const _userRoleAccessList = userRoleAccessList
      .map((item) =>
        userRole.id == item.id
          ? { ...item, canView: canView, canWrite: canWrite }
          : item
      )
      .filter((item) => !(item.canView == 0 && item.canWrite == 0));
    UpdateDashboardUserRole(userRole, canView, canWrite, permission.id == 3);
    setUserRoleAccessList(_userRoleAccessList);
  };

  const users = userRoleAccessList
    ?.filter((item) => item.type == 1)
    .sort((a, b) => b.isCreator - a.isCreator);
  const roles = userRoleAccessList?.filter((item) => item.type == 2);

  const uniqueEmployees = useMemo(() => {
    return employees.filter((emp) =>
      !userRoleAccessList.some((role) => {
        // Ensure type matches before comparing
        if (emp.type === role.type) {
          if (role.type === 1) {
            return role.userUniqueno === emp.userUniqueno;
          } else if (role.type === 2) {
            return role.roleId === emp.roleId;
          }
        }
        return false;
      })
    );
  }, [employees, userRoleAccessList]);

  return (
    <Modal
      show={show}
      className="share-dashboard-modal"
      onHide={handleClose}
      size="lg"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Share Dashboard</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex gap-2 mb-4 search-controls">
          <div className="search-bar">
            <div className="search-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <path
                  d="M7.66016 8.40283C9.31701 8.40283 10.6602 7.05969 10.6602 5.40283C10.6602 3.74598 9.31701 2.40283 7.66016 2.40283C6.0033 2.40283 4.66016 3.74598 4.66016 5.40283C4.66016 7.05969 6.0033 8.40283 7.66016 8.40283Z"
                  fill="#84888C"
                />
                <path
                  d="M7.92266 9.91033C5.87516 9.83533 1.66016 10.8553 1.66016 12.9028V13.6528C1.66016 14.0653 1.99766 14.4028 2.41016 14.4028H8.81516C6.96266 12.3328 7.89266 9.98533 7.92266 9.91033Z"
                  fill="#84888C"
                />
                <path
                  d="M14.7327 12.9178C15.0852 12.3178 15.2577 11.5903 15.0927 10.8028C14.8377 9.57283 13.8027 8.59033 12.5577 8.43283C10.5852 8.17783 8.92016 9.83533 9.18266 11.8078C9.34766 13.0528 10.3227 14.0878 11.5527 14.3428C12.3402 14.5078 13.0677 14.3353 13.6677 13.9828L15.0627 15.3778C15.3552 15.6703 15.8277 15.6703 16.1202 15.3778C16.4127 15.0853 16.4127 14.6128 16.1202 14.3203L14.7327 12.9178ZM12.1602 12.9028C11.3352 12.9028 10.6602 12.2278 10.6602 11.4028C10.6602 10.5778 11.3352 9.90283 12.1602 9.90283C12.9852 9.90283 13.6602 10.5778 13.6602 11.4028C13.6602 12.2278 12.9852 12.9028 12.1602 12.9028Z"
                  fill="#84888C"
                />
              </svg>
            </div>
            <MultiSelectUsersRoles
              ref={multiselectChild}
              itemList={uniqueEmployees.map((item) => ({
                ...item,
                description: item.employee,
                value: item.employee,
              }))}
              updateMultiSelectItem={updateMultiSelectItem}
              dataChange={() => {}}
              dependent={userRoleAccessList}
              isSelectAll={true}
              filtertitle={"Search"}
            />
            {/* <Form.Control
                autoFocus
                type="text"
                placeholder="Type to search users or roles"
                value={searchQuery}
                onChange={handleInputChange}
              /> */}
            <PermissionsDropdown
              selectedPermission={selectedPermission}
              setSelectedPermission={setSelectedPermission}
              permissionList={permissionLevels.filter(
                (item) => !item?.isRemove
              )}
            />
          </div>
          <Button
            variant="primary"
            className="add-btn"
            disabled={isSubmitting || selectedUsersRoles.length == 0}
            onClick={SaveDashboardUserRoles}
          >
            Add
          </Button>
        </div>

        <div
          className={`users-list-body ${
            roles.length + users.length >= 4 ? "pr-2" : ""
          }`}
        >
          <div className="users-list">
            <h6 className="list-header">People</h6>
            <ListGroup variant="flush">
              {users.length > 0 ? (
                users.map((user) => (
                  <ListGroup.Item
                    key={user.id}
                    className="d-flex align-items-center border-0"
                  >
                    {!user.imagePath ? (
                      <svg
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="20" cy="20" r="20" fill="#E5F5FF"></circle>
                        <path
                          d="M20.0013 20.2819C22.5548 20.2819 24.6422 18.1945 24.6422 15.6409C24.6422 13.0874 22.5548 11 20.0013 11C17.4477 11 15.3604 13.0874 15.3604 15.6409C15.3604 18.1945 17.4477 20.2819 20.0013 20.2819Z"
                          fill="#065A8C"
                        ></path>
                        <path
                          d="M27.9658 23.9905C27.8442 23.6865 27.6821 23.4027 27.4997 23.1393C26.5674 21.7612 25.1286 20.8492 23.5073 20.6263C23.3047 20.606 23.0817 20.6465 22.9196 20.7681C22.0684 21.3964 21.0551 21.7206 20.0013 21.7206C18.9474 21.7206 17.9341 21.3964 17.0829 20.7681C16.9208 20.6465 16.6979 20.5857 16.4952 20.6263C14.8739 20.8492 13.4148 21.7612 12.5028 23.1393C12.3204 23.4027 12.1583 23.7067 12.0367 23.9905C11.9759 24.1121 11.9962 24.2539 12.057 24.3755C12.2191 24.6592 12.4217 24.943 12.6041 25.1862C12.8878 25.5713 13.1918 25.9157 13.5364 26.24C13.8201 26.5237 14.1444 26.7872 14.4686 27.0506C16.0696 28.2464 17.9949 28.8746 19.981 28.8746C21.9671 28.8746 23.8924 28.2463 25.4934 27.0506C25.8176 26.8075 26.1419 26.5237 26.4256 26.24C26.7499 25.9157 27.0741 25.5712 27.3579 25.1862C27.5605 24.9227 27.743 24.6592 27.9051 24.3755C28.0063 24.2539 28.0266 24.112 27.9658 23.9905Z"
                          fill="#065A8C"
                        ></path>
                      </svg>
                    ) : (
                      <Image
                        src={user.imagePath}
                        roundedCircle
                        width={40}
                        height={40}
                        className="me-3"
                      />
                    )}
                    <div className="flex-grow-1 user-details">
                      <div>{user.employee}</div>
                      <small className="text-muted">{user.roleName}</small>
                    </div>
                    {user.isCreator == 1 ? (
                      <small className="textMuted">Creator</small>
                    ) : (
                      <PermissionsDropdown
                        selectedPermission={getPermissionLevels(
                          user.canView,
                          user.canWrite
                        )}
                        setSelectedPermission={(item) =>
                          handleEditPermission(item, user)
                        }
                        permissionList={permissionLevels}
                      />
                    )}
                  </ListGroup.Item>
                ))
              ) : (
                <>No Peoples</>
              )}
            </ListGroup>
          </div>

          <div className="users-list mt-2">
            <h6 className="list-header">Roles</h6>
            <ListGroup variant="flush">
              {roles.length > 0 ? (
                roles.map((role) => (
                  <ListGroup.Item
                    key={role.id}
                    className="d-flex align-items-center border-0"
                  >
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                      >
                        <circle cx="16" cy="16" r="16" fill="#D9D9D9" />
                        <path
                          d="M8 17C9.1 17 10 16.1 10 15C10 13.9 9.1 13 8 13C6.9 13 6 13.9 6 15C6 16.1 6.9 17 8 17ZM9.13 18.1C8.76 18.04 8.39 18 8 18C7.01 18 6.07 18.21 5.22 18.58C4.48 18.9 4 19.62 4 20.43V22H8.5V20.39C8.5 19.56 8.73 18.78 9.13 18.1ZM24 17C25.1 17 26 16.1 26 15C26 13.9 25.1 13 24 13C22.9 13 22 13.9 22 15C22 16.1 22.9 17 24 17ZM28 20.43C28 19.62 27.52 18.9 26.78 18.58C25.93 18.21 24.99 18 24 18C23.61 18 23.24 18.04 22.87 18.1C23.27 18.78 23.5 19.56 23.5 20.39V22H28V20.43ZM20.24 17.65C19.07 17.13 17.63 16.75 16 16.75C14.37 16.75 12.93 17.14 11.76 17.65C10.68 18.13 10 19.21 10 20.39V22H22V20.39C22 19.21 21.32 18.13 20.24 17.65ZM12.07 20C12.16 19.77 12.2 19.61 12.98 19.31C13.95 18.93 14.97 18.75 16 18.75C17.03 18.75 18.05 18.93 19.02 19.31C19.79 19.61 19.83 19.77 19.93 20H12.07ZM16 12C16.55 12 17 12.45 17 13C17 13.55 16.55 14 16 14C15.45 14 15 13.55 15 13C15 12.45 15.45 12 16 12ZM16 10C14.34 10 13 11.34 13 13C13 14.66 14.34 16 16 16C17.66 16 19 14.66 19 13C19 11.34 17.66 10 16 10Z"
                          fill="#6A6E73"
                        />
                      </svg>
                    </div>
                    <div className="flex-grow-1 user-details">
                      <div>{role.roleName}</div>
                      <small>Any user with the {role.roleName} role</small>
                    </div>
                    <PermissionsDropdown
                      selectedPermission={getPermissionLevels(
                        role.canView,
                        role.canWrite
                      )}
                      setSelectedPermission={(item) =>
                        handleEditPermission(item, role)
                      }
                      permissionList={permissionLevels}
                    />
                  </ListGroup.Item>
                ))
              ) : (
                <>No Roles</>
              )}
            </ListGroup>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
