const LayoutTypes = [
  { partitions: [0.5, 0.5], cols: 12 },
  { partitions: [0.33333, 0.33333, 0.33333], cols: 12 },
  { partitions: [0.66667, 0.33333], cols: 12 },
  { partitions: [0.33333, 0.66667], cols: 12 },
];

function calculateHeight(partitionWidth, cardHeight) {
  // Base height mapping for bar/progress charts
  const heightMapping = {
    0.66667: 3, // For partition width 0.666
    0.5: 2.3, // For partition width 0.5
    0.33333: 2, // For partition width 0.333
  };

  const baseHeight =
    heightMapping[partitionWidth] 

  // Adjust the height for the card type
  return cardHeight === 1 ? 1 : baseHeight; // Reduce height for number cards
}

function reverseLayout(layout, layoutTypeIndex = 3) {
  const layoutType = LayoutTypes[layoutTypeIndex - 1];
  if (!layoutType) {
    throw new Error(`Invalid layoutTypeIndex: ${layoutTypeIndex}`);
  }

  const { cols, partitions } = layoutType;

  let reversedLayout = layout.map((item) => {
    // Calculate the proportion of x within the total cols
    const proportionX = Math.round((item.x / cols) * 100) / 100;

    // Determine the columnNo by comparing proportionX against cumulative partitions
    let cumulativeWidth = 0;
    let columnNo = 0;

    for (let i = 0; i < partitions.length; i++) {
      cumulativeWidth += partitions[i];
      cumulativeWidth=Math.round((cumulativeWidth) * 100) / 100;
      if (proportionX < cumulativeWidth) {
        columnNo = i;
        break;
      }
    }

    // Recalculate the x position based on column index
    const normalizedX = columnNo;

    return {
      ...item,
      columnNo, // Standard column index (0-based)
      rowNo: Math.round(item.y), // Standard row number (keep y as-is)
      x: normalizedX, // Column index instead of scaled x
      y: Math.round(item.y), // Ensure y is rounded to an integer
    };
  });

  return reversedLayout;
}

function generateLayout(cards, layoutTypeIndex = 3) {
 

  const layoutType = LayoutTypes[layoutTypeIndex-1];
  if (!layoutType) {
    throw new Error(`Invalid layoutTypeIndex: ${layoutTypeIndex}`);
  }

  const { cols, partitions } = layoutType;
  let layouts:any = [];

  cards.forEach((card) => {
    // Clamp the columnNo to the valid range
    const maxColumnIndex = partitions.length - 1;
    const columnNo = Math.min(card.columnNo, maxColumnIndex);

    let columnWidth = partitions[columnNo]; // Width of the current column
    let previousColumnsWidth = partitions
      .slice(0, card.columnNo)
      .reduce((sum, width) => sum + width, 0); // Cumulative width before this column
    // Optimize cardHeight by scaling with partition size
    const optimizedHeight = calculateHeight(columnWidth, card.cardHeight);

    let layoutItem = {
      w: Math.round(columnWidth * cols), // Width scaled by the number of columns
      h: optimizedHeight, // Fixed height (can be adjusted as needed)
      x: Math.round((previousColumnsWidth * cols)), // Horizontal position
      y: card.rowNo, // Vertical position
      i: card.id.toString(), // Unique identifier for each layout item
      moved: false,
      static: false
      // id:card.id
    };

    layouts.push(layoutItem);
  });

  return layouts;
}

function findEmptyColumnAndAddConfig(layout, layoutTypeIndex) {
  const layoutType = LayoutTypes[layoutTypeIndex-1];
  const { partitions, cols } = layoutType;
  const columnWidths = partitions.map(partition => Math.round(partition * cols)); // Normalize widths
  const columnXPositions:any = [];

  // Calculate starting x positions for columns
  let currentX = 0;
  for (const width of columnWidths) {
      columnXPositions.push(currentX);
      currentX += width;
  }

  layout=layout.filter(item => !item?.i?.startsWith("empty"))
  // Group items by column x positions
  const columns:any = columnXPositions.map(() => []); // Create empty arrays for each column
  layout.forEach(item => {
      for (let i = 0; i < columnXPositions.length; i++) {
          const startX = columnXPositions[i];
          const endX = columnXPositions[i + 1] || cols; // Last column spans to max cols
          if (item.x >= startX && item.x < endX) {
              columns[i].push(item);
              break;
          }
      }
  });

  // Check for empty columns
  let emptyColumnIndex = -1;
  for (let i = 0; i < columns.length; i++) {
      if (columns[i].length === 0) {
          emptyColumnIndex = i;
          break;
      }
  }

   // Check for empty columns and add placeholders for all empty columns
   const placeholders:any = [];
   columns.forEach((columnItems, columnIndex) => {
       if (columnItems.length === 0) {
           const placeholderConfig = {
               i: `empty${Date.now()}_${columnIndex}`, // Unique ID for the placeholder
               w: columnWidths[columnIndex],
               h: 1,
               x: columnXPositions[columnIndex],
               y: 0, // Place at the top of the column
               moved: false,
               static: true,
               id: `empty${Date.now()}_${columnIndex}`,
           };
           placeholders.push(placeholderConfig);
       }
   });
   layout.push(...placeholders);

  return  layout;
}

function cleanArrayOfObjects(arr) {
  return arr.map(obj => 
    Object.fromEntries(
      Object.entries(obj).filter(([_, value]) => value !== undefined)
    )
  );
}

function getColumnX(layoutTypeIndex, columnNo) {
  // Get the layout configuration
  const layoutType = LayoutTypes[layoutTypeIndex - 1]; // Use index starting from 1
  if (!layoutType) {
    throw new Error(`Invalid layoutTypeIndex: ${layoutTypeIndex}`);
  }

  const { partitions, cols } = layoutType;

  // Validate the columnNo
  if (columnNo < 0 || columnNo >= partitions.length) {
    throw new Error(`Invalid columnNo: ${columnNo} for layout type ${layoutTypeIndex}`);
  }

  // Calculate the x position based on the column
  let cumulativeWidth = 0;
  for (let i = 0; i < columnNo; i++) {
    cumulativeWidth += partitions[i];
  }

  // Convert cumulative width to a proportional x value
  const columnX = Math.round(cumulativeWidth * cols);

  return columnX;
}


function updateLayout(currentLayout, updatedMetricItem, layoutTypeIndex) {
  const layoutType = LayoutTypes[layoutTypeIndex - 1]; // Use index starting from 1
  if (!layoutType) {
    throw new Error(`Invalid layoutTypeIndex: ${layoutTypeIndex}`);
  }

  const { partitions, cols } = layoutType;
  const { id, columnNo: updatedCol, rowNo: updatedRow, cardHeight: updatedHeight, x: updatedX } = updatedMetricItem;

  // Clone the current layout to avoid mutating the original
  const newLayout = [...currentLayout];

  // Step 1: Find the index of the item being updated
  const itemIndex = newLayout.findIndex(item => item.i === id.toString());
  if (itemIndex === -1) {
    throw new Error(`Item with id ${id} not found in the current layout`);
  }
  

  // let columnWidth = partitions[updatedCol]; // Width of the current column
 
  // const optimizedHeight = calculateHeight(columnWidth, updatedHeight);

    // Step 2: Calculate the height difference
  const previousHeight = newLayout[itemIndex].h; // Original height of the card
  const columnWidth = partitions[updatedCol]; // Width of the current column
  const optimizedHeight = calculateHeight(columnWidth, updatedHeight);
  const heightDifference = optimizedHeight - previousHeight;

  // Step 2: Update the properties of the edited item
  const updatedItem = {
    ...newLayout[itemIndex],
    // y: updatedRow, // Move the card to the new row position
    h: optimizedHeight, // Update the card height
  };

  newLayout[itemIndex] = updatedItem;
  const xValue=getColumnX(layoutTypeIndex,updatedCol)
  // Step 3: Adjust the y values of all cards in the same column (`x = 8`) that are below the updated card's row
  newLayout.forEach(item => {
    if (item.x === xValue && item.y >= updatedRow) {
      item.y += heightDifference;
    }
  });

  // Return the updated layout
  return newLayout;
}

function deleteMetric(currentLayout, metricId, layoutTypeIndex) {
  const layoutType = LayoutTypes[layoutTypeIndex - 1]; // Use index starting from 1
  if (!layoutType) {
    throw new Error(`Invalid layoutTypeIndex: ${layoutTypeIndex}`);
  }

  const { partitions } = layoutType;

  // Clone the current layout to avoid mutating the original
  const newLayout = [...currentLayout];

  // Step 1: Find the index of the item being deleted
  const itemIndex = newLayout.findIndex(item => item.i === metricId.toString());
  if (itemIndex === -1) {
    throw new Error(`Item with id ${metricId} not found in the current layout`);
  }

  // Step 2: Get the details of the item being deleted
  const deletedItem = newLayout[itemIndex];
  const { x: deletedX, y: deletedY, h: deletedHeight } = deletedItem;

  // Step 3: Remove the deleted item from the layout
  newLayout.splice(itemIndex, 1);
  
  // Step 4: Adjust the `y` values of all items in the same column (`x = deletedX`) that are below the deleted item
  newLayout.forEach(item => {
    if (item.x === deletedX && item.y > deletedY) {
      item.y -= deletedHeight; // Reduce the `y` position by the height of the deleted item
    }
  });

  // Return the updated layout
  return newLayout;
}


export {updateLayout,deleteMetric, generateLayout ,cleanArrayOfObjects, LayoutTypes, calculateHeight,reverseLayout, findEmptyColumnAndAddConfig};
