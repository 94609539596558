import * as React from "react";
import { Button, Dropdown, Form } from "react-bootstrap";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import OutsideClickHandler from "react-outside-click-handler";
import profile from "../Assets/Images/profile.png";
import { EntityManagementService } from "../../../Common/Services/EntityManagementService";
import { request } from "http";
import { resolve, reject } from "q";
import { Utils } from "../../../Common/Utilis";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IAddCommonAreaSubType } from "../../../Common/Contracts/IFacilities";

export class CASubType extends React.Component<any, any> {
  private inputElement: any;
  constructor(props: any) {
    super(props);
    this.inputElement = React.createRef();
    this.state = {
      row: this.props.row,
      inputValue: "",
      SubTypeList: this.props.SubTypeList,
      filterTypeList: [],
      subType : this.props?.row?.subTypeName,
      addSubtypeDropdown: false,
      refreshKey: 0,
      newSubType : "",
      filterList: [],
      //SubTypeList: [],
      filteredList : [],
      copyFilterList : [],
      copySubTypeList : []
    };
    this.handleSearchType = this.handleSearchType.bind(this);
  }

 
  componentDidMount() {
  }

  


  handleDropdownChange(item) {
    debugger;
    this.setState({ inputValue: "" });
    this.props.subTypeSelected(item, this.state.row);
  }

  handleSearchType = (e: { target: { value: string } }) => {

    this.setState({ loaderTrue: true ,inputValue: e.target.value});
    let data = [...this.state.copyFilterList]
    const updatedList = data.filter(
      (item: { subTypeName: string }) => {
        return (
          item.subTypeName.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1
        );
      }
    );

    this.setState({ filteredList: [] }, () => {
      this.setState({
        inputValue: e.target.value,
        loaderTrue: false,
        filteredList: updatedList,

      });
    })

  };

  onSubTypeAddClick = () => {
    this.setState({ addSubtypeDropdown: true });
  }

  hideAddType = () => {
    this.setState({ addSubtypeDropdown: false });
  }

  handleOpen = (row: { id: number; typeID: number; typeName: string }) => {
    debugger;
    if (row.typeName !== "Guest") {
      const filteredList = this.state.SubTypeList.filter(
        (subType: { typeID: number }) => subType.typeID === row.typeID
      );
  
      const updatedList = [{ subTypeID: 0, subTypeName: "Select SubType" }, ...filteredList];
  
      if (JSON.stringify(updatedList) !== JSON.stringify(this.state.SubTypeList)) {
        this.setState({ filteredList: updatedList, copyFilterList: updatedList });
      }
    } else {
      let subtypeList = [...this.state.SubTypeList];
      let subtypefilteredList = subtypeList.filter((x) => x.type === "Guest");
  
      const updatedList = [{subTypeID: 0, subTypeName: "Select SubType" }, ...subtypefilteredList];
  
      this.setState({ filteredList: updatedList, copyFilterList: updatedList });
    }
  };
  
  

  callFromParent = () => {

  }

  handleSaveNewSubType = () => {
    debugger;
    let request = {} as IAddCommonAreaSubType;
    request.SubTypeName = this.state.newSubType;
    request.Hid = this.props.hotelId;
    request.Type = "Common";
    request.TypeID = this.props.row.typeID;
    EntityManagementService.AddLocationCommonAreaSubType(request)

    .then(async (result: any | null) => {
      debugger;
      let successToastMessage = "";
      if (result != null && result.result.messageCode === "Success") {
        this.setState({ addSubtypeDropdown: false });
        debugger;
        toast.success("SubType added sucessfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "CommonArea",
        });

        const addedSubType = {
          subTypeName: result.result.subTypeName,
          subTypeID: result.result.subtypeID,
          typeID : this.props.row.typeID
      };
  
      this.setState((prevState) => ({
        copySubTypeList: [...prevState.SubTypeList, addedSubType], 
        subType: addedSubType.subTypeName, 
        addSubtypeDropdown: false, 
        newSubType: "", 
    }));

    console.log("this.state.SubTypeList",this.state.SubTypeList);
    

    this.handleDropdownChange(addedSubType);
        
        //this.setState({SubTypeList : this.props.SubTypeList})
      } else {
        this.setState({ addSubtypeDropdown: false });
        toast.error("Subtype name already exist", {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "CommonArea",
        });
      }
      resolve();
    })
    .catch((err: any) => {
      Utils.toastError(
        "SubType name already exist",
        {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "CommonArea",
        }
      );
      reject();
    });
  }

  render() {
    
    return (

            <div className="subtype-wrapper" >
             <Dropdown
               className="subtype-dropdown position-static"
               onToggle={(isOpen) => {
                if (isOpen) {
                  this.handleOpen(this.props.row); // Load filtered SubTypeList when opening
                }
              }}
            >
              <Dropdown.Toggle
                 id="dropdown-importFrom"
                 className={this.props?.row?.subTypeValidation ? "alert-danger" : ""}
                //  onClick={() => {
                //   setTimeout(() => this.handle(this.state.row), 1000); // Delayed state update
                // }}
                 
               >
                <div className="btn-text">
                  <EllipsisWithTooltip placement="bottom">{this.state.subType || "Select Subtype"}</EllipsisWithTooltip>
                </div>
              </Dropdown.Toggle>
               <Dropdown.Menu>
                 <div className="search-sec">
                  <input
                    type="text"
                     className="form-control"
                     onChange={this.handleSearchType}
                     placeholder="Subtype Search"
                     value={this.state.inputValue}
                     ref={this.inputElement}
                   />
                 </div>
                  <div className="menu">
                    {this.state.filteredList?.length > 0 ? (
                    this.state.filteredList.map((item: any) => (
                      <Dropdown.Item
                      onClick={() => this.handleDropdownChange(item)}
                      key={item.id}
                      >
                        <EllipsisWithTooltip placement="bottom">{item.subTypeName}</EllipsisWithTooltip>
                        {/* <button>
                          <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"></path></svg>
                        </button> */}
                      </Dropdown.Item>
                    ))
                  ) : (
                    <Dropdown.Item>No Subtype match your current filters.</Dropdown.Item>
                  )}
                  </div>
                  {/* <Button className="add-subtype" onClick={this.onSubTypeAddClick}>Add Sub Type</Button> */}
               </Dropdown.Menu>
             </Dropdown>
              {
                this.state.addSubtypeDropdown &&
                <div className="edit-dashboard-dropdown">
                <div className="d-flex align-items-center dropdown-header">
                  <button 
                  onClick={this.hideAddType}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
                      <path d="M5.49961 0.5875C5.17461 0.2625 4.64961 0.2625 4.32461 0.5875L0.499609 4.4125C0.174609 4.7375 0.174609 5.2625 0.499609 5.5875L4.32461 9.4125C4.64961 9.7375 5.17461 9.7375 5.49961 9.4125C5.82461 9.0875 5.82461 8.5625 5.49961 8.2375L2.26628 4.99583L5.49961 1.7625C5.82461 1.4375 5.81628 0.904167 5.49961 0.5875Z" fill="#2CAF92"/>
                      </svg>  
                  </button> 
                  <h4>Add Type</h4>
                </div>
                  <div>
                    <div className="dropdown-body">
                      <Form>
                        <Form.Group controlId="subtype">
                          <Form.Label>Label</Form.Label>
                            <div>
                              <Form.Control
                                type="text"
                                placeholder="Enter value"
                                value={this.state.newSubType}
                                onChange={(e) => this.setState({ newSubType: e.target.value })}
                                autoComplete="off"
                              />
                            </div> 
                        </Form.Group>
                      </Form>
                    </div>
                    <div className="d-flex justify-content-end footer">
                      <Button variant="outline" 
                      onClick={this.hideAddType} 
                      tabIndex={3}>
                        Cancel
                      </Button>
                      <Button  
                       onClick={this.handleSaveNewSubType} 
                      className="ms-2" tabIndex={4}>
                        Save
                      </Button>
                    </div>
                  </div>
                </div> 
              }
           </div>
     );
    //   </OutsideClickHandler>
    
    
  }
}
