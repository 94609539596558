import * as React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Button, Container, Dropdown, Form, Spinner } from "react-bootstrap";
import { FiChevronDown } from "react-icons/fi";
import { ToastContainer, toast } from "react-toastify";
import { UserManagement } from "../../../Common/Services/UserManagement";
import { HotelFeatureAccessService } from "../../../Common/Services/HotelFeatureAccessService";
import "../../../Common/Assets/Styles/Modules/epay-onboarding.scss";
import { ReactPageLoader } from "../../../Common/Components/ReactPageLoader";
import { resolve, reject } from "q";
import _ from "lodash";
import { CommonService } from "../../../Common/Services/CommonService";
import { Utils } from "../../../Common/Utilis";
import { APIIntegrationServiceNew } from "../../../Common/Services/APIIntegartionServiceNew";
import "./APIIntegration.scss";
export class APIIntegrationNew extends React.Component<any, any> {
  private tenantSelector: any;
  constructor(props: any) {
    super(props);
    this.tenantSelector = React.createRef();
    this.state = {
      selectedTenant: { id: -1, name: "", status: "" },
      tenantSearch: "",
      isTenantLoading: false,
      tenantsList: [],
      filterTenantList: [],
      isTableLoading: false,
      isSaveEnabled: false,
      isSubmitLoading: false,
      headerData: [],
      advAccHotelData: [],
      advAccHotelDataCopy: [],
      tableData: [],
      tableDataCopy: [],
      subscribedDynamicColumnsArray: [],
      notSubscribedDynamicColumnsArray: [],
      dynamicColumnArray: [],
      applyCheckUncheckClass: false,
    };
  }

  componentDidMount() {
    //Component file
    this.getTenantsList();
  }
  getTenantsList = () => {
    this.setState({ isTenantLoading: true });
    APIIntegrationServiceNew.GetLaborTenantList("labor").then(
      async (result: any) => {
        debugger;
        const data = result;
        let filterItem: any[] = [];
        filterItem = data.filter(
          (item: any) => item.status.toString().toLocaleLowerCase() === "active"
        );
        this.setState({
          tenantsList: filterItem,
          filterTenantList: filterItem,
          isTenantLoading: false,
        });
      }
    );
  };

  searchItems = (e: any) => {
    const dataList = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.tenantsList))
    );
    let { filterTenantList } = this.state;
    let result = e.target.value;
    this.setState({ tenantSearch: result });
    if (result !== "") {
      filterTenantList = dataList.filter((o: any) =>
        o.tenantName.toLowerCase().includes(result.toLowerCase().trim())
      );
    } else {
      filterTenantList = dataList;
    }
    this.setState({ filterTenantList });
  };

  tenantDDToggle = (e: any) => {
    const dataList = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.tenantsList))
    );
    this.setState({ tenantSearch: "", filterTenantList: dataList });
    e === true &&
      setTimeout(() => {
        $("#idTenantSearch").focus();
      }, 50);
    setTimeout(() => {
      this.tenantSelector.current.getElementsByClassName("active")[0]?.focus();
    }, 100);
  };

  tanentChange = (item: any) => {
    debugger;
    let { selectedTenant } = this.state;
    selectedTenant.id = item.tenantID;
    selectedTenant.name = item.tenantName;
    selectedTenant.status = item.status;
    this.setState(
      {
        selectedTenant,
        isTableLoading: true,
        isSaveEnabled: false,
        APIIntegrationHotelData: [],
        APIIntegrationHotelDataCopy: [],
        tableData: [],
        tableDataCopy: [],
      },
      () => {
        this.GetAPIIntegrationHeader(item.tenantID);
        //this.GetAPIIntegrationHotelDetails(item.tenantID);
      }
    );
  };

  GetAPIIntegrationHeader = (tenantid: any) => {
    this.setState({ isTableLoading: true });
    let request: any = {};
    request.selectedTenantId = tenantid;
    APIIntegrationServiceNew.GetAPIIntegrationHeader(request)
      .then(async (result: any | null) => {
        debugger;
        if (result !== null && result.length > 0) {
          this.setState({ headerData: result }, () => {
            let dynamicColumnArray: any = [];
            this.state.headerData.length > 0 &&
              this.state.headerData.map((header) => {
                let dynamicColumnObj: any = {};
                debugger;
                dynamicColumnObj.dataField = header.name;
                dynamicColumnObj.text = header.name;
                dynamicColumnObj.headerFormatter = (column, colIndex) => {
                  return (
                    <div
                      className={`d-flex ${
                        this.headerShowCheck(header.name)
                          ? "show-half-check"
                          : ""
                      }`}
                    >
                      <Form.Check
                        custom
                        id={"chkheader1_" + header.name}
                        key={"chkheader1_" + header.name}
                        type="checkbox"
                        label={""}
                        checked={this.isColumnHeaderChecked(header.name, 1)}
                        onChange={(e) =>
                          this.handleHeaderCheckbox(e, column.dataField, 1)
                        }
                      />
                      <div>{column.text}</div>
                    </div>
                  );
                };
                dynamicColumnObj.formatter = (
                  cell: any,
                  row: any,
                  rowIndex: any
                ) => {
                  return (
                    <div className="bdr-left height-48 d-flex align-items-center">
                      <Form.Check
                        custom
                        id={"chk1_" + header.name + row.id}
                        key={"chk1_" + header.name + row.id}
                        type="checkbox"
                        label={""}
                        checked={row[header.name] === "Yes" ? true : false}
                        onChange={(e) =>
                          this.handleCheckbox(e, row, header.name)
                        }
                        //    disabled={this.state.disabledCheckboxes[`${row["ehid"]}_${row["id"]}`]}
                      />
                    </div>
                  );
                };
                dynamicColumnArray.push(dynamicColumnObj);
              });
            this.setState({ dynamicColumnArray, isTableLoading: false });
            this.GetAPIIntegrationHotelDetails(tenantid);
          });
        } else {
          this.setState({ dynamicColumnArray: [], isTableLoading: false });
          this.GetAPIIntegrationHotelDetails(tenantid);
        }
        resolve();
      })
      .catch((error) => {
        reject();
      });
  };

  GetAPIIntegrationHotelDetails = (tenantid: any) => {
    debugger;
    this.setState({ isTableLoading: true });
    let request: any = {};
    request.selectedTenantId = tenantid;
    APIIntegrationServiceNew.GetAPIIntegrationHotelDetails(request)
      .then(async (result: any | null) => {
        if (result !== null && result.length > 0) {
          this.setState(
            {
              APIIntegrationHotelData: result,
              APIIntegrationHotelDataCopy: result,
              isTableLoading: false,
            },
            () => {
              let tableData: any = [];
              let dintinctEhidArr = this.state.APIIntegrationHotelData.map(
                (data) => data.ehid
              ).filter((value, index, self) => self.indexOf(value) === index);
              console.log("dataaaaa", this.state.APIIntegrationHotelData);

              dintinctEhidArr.map((ehid, index) => {
                debugger;
                let tableDataObj = {};
                tableDataObj["id"] = index;
                tableDataObj["ehid"] = ehid;
                tableDataObj["hotelid"] =
                  this.state.APIIntegrationHotelData.filter(
                    (data) => data.ehid === ehid
                  )[0].hotelID;
                tableDataObj["isCheckAll"] = "No";
                this.state.headerData.map((header, index) => {
                  tableDataObj[header.name] =
                    this.state.APIIntegrationHotelData.filter(
                      (data) => data.ehid === ehid && data.name === header.name
                    )[0].checkValue;
                });
                tableDataObj["viewType"] =
                  this.state.APIIntegrationHotelData.filter(
                    (data) => data.ehid === ehid
                  )[0].viewType;
                tableData.push(tableDataObj);
              });
              debugger;
              this.setState({ tableData, tableDataCopy: tableData }, () => {
                let tableData = Object.assign(
                  [],
                  JSON.parse(JSON.stringify(this.state.tableData))
                );
                for (const element of tableData) {
                  for (let j = 0; j < this.state.headerData.length; j++) {
                    if (element[this.state.headerData[j]["name"]] === "Yes") {
                    } else {
                      element["isCheckAll"] = "No";
                      break;
                    }
                    element["isCheckAll"] = "Yes";
                  }
                }
                this.setState({ tableData, tableDataCopy: tableData });
              });
            }
          );
        }
        resolve();
      })
      .catch((error) => {
        this.setState({ isTableLoading: false });
        Utils.toastError(error.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        reject();
      });
  };

  handleSave = () => {
    this.setState({ isSubmitLoading: true, isTableLoading: true });
    let request = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.APIIntegrationHotelData))
    );
    request.map(
      (items) => (items["selectedTenantId"] = this.state.selectedTenant.id)
    );
    APIIntegrationServiceNew.SaveAPIIntegrationHid(request)
      .then(async (result: any | null) => {
        if (result.saveStatus.toLowerCase() === "success") {
          toast.success(result.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          this.setState(
            {
              isSubmitLoading: false,
              isSaveEnabled: false,
              isTableLoading: false,
            },
            () => {
              this.GetAPIIntegrationHotelDetails(this.state.selectedTenant.id);
            }
          );
        } else {
          this.setState({ isSubmitLoading: false });
          Utils.toastError("Something went wrong.", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
        resolve();
      })
      .catch((error) => {
        this.setState({ isSubmitLoading: false });
        Utils.toastError("Something went wrong.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        reject();
      });
  };

  handleCheckbox = (event, row, type) => {
    if (!this.state.isSubmitLoading) {
      let tableData = Object.assign(
        [],
        JSON.parse(JSON.stringify(this.state.tableData))
      );
      let APIIntegrationHotelData = Object.assign(
        [],
        JSON.parse(JSON.stringify(this.state.APIIntegrationHotelData))
      );
      for (let i = 0; i < APIIntegrationHotelData.length; i++) {
        if (
          APIIntegrationHotelData[i]["ehid"] === row.ehid &&
          APIIntegrationHotelData[i]["name"] === type
        ) {
          APIIntegrationHotelData[i].checkValue = event.target.checked
            ? "Yes"
            : "No";
          APIIntegrationHotelData[i].isUpdated = "Yes";
        }
      }
      for (let i = 0; i < tableData.length; i++) {
        if (
          tableData[i]["ehid"] === row.ehid &&
          tableData[i]["id"] === row.id
        ) {
          tableData[i][type] = event.target.checked ? "Yes" : "No";
        }
      }
      this.setState(
        {
          tableData,
          APIIntegrationHotelData,
          isSaveEnabled: true,
          applyCheckUncheckClass: true,
        },
        () => {
          let tableData = Object.assign(
            [],
            JSON.parse(JSON.stringify(this.state.tableData))
          );
          for (let i = 0; i < tableData.length; i++) {
            if (
              tableData[i]["ehid"] === row.ehid &&
              tableData[i]["id"] === row.id
            ) {
              for (let j = 0; j < this.state.headerData.length; j++) {
                if (tableData[i][this.state.headerData[j]["name"]] === "Yes") {
                } else {
                  tableData[i]["isCheckAll"] = "No";
                  this.setState({ tableData });
                  return;
                }
              }
              tableData[i]["isCheckAll"] = "Yes";
              this.setState({ tableData });
            }
          }
        }
      );
    }
  };

  handleCheckAllRow = (event, row) => {
    debugger;
    let tableData = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.tableData))
    );
    let APIIntegrationHotelData = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.APIIntegrationHotelData))
    );
    for (let i = 0; i < APIIntegrationHotelData.length; i++) {
      if (APIIntegrationHotelData[i]["ehid"] === row.ehid) {
        APIIntegrationHotelData[i].checkValue = event.target.checked
          ? "Yes"
          : "No";
        APIIntegrationHotelData[i].isUpdated = "Yes";
      }
    }
    for (let i = 0; i < tableData.length; i++) {
      if (tableData[i]["ehid"] === row.ehid && tableData[i]["id"] === row.id) {
        for (let j = 0; j < this.state.headerData.length; j++) {
          tableData[i][this.state.headerData[j]["name"]] = event.target.checked
            ? "Yes"
            : "No";
        }
        tableData[i]["isCheckAll"] = event.target.checked ? "Yes" : "No";
      }
    }
    this.setState(
      { tableData, APIIntegrationHotelData, isSaveEnabled: true },
      () => {
        let tableData = Object.assign(
          [],
          JSON.parse(JSON.stringify(this.state.tableData))
        );
        for (let i = 0; i < tableData.length; i++) {
          if (
            tableData[i]["ehid"] === row.ehid &&
            tableData[i]["id"] === row.id
          ) {
            for (let j = 0; j < this.state.headerData.length; j++) {
              if (tableData[i][this.state.headerData[j]["name"]] === "Yes") {
              } else {
                tableData[i]["isCheckAll"] = "No";
                this.setState({ tableData });
                return;
              }
            }
            tableData[i]["isCheckAll"] = "Yes";
            this.setState({ tableData });
          }
        }
      }
    );
  };

  handleHeaderCheckbox = (event, type, viewType) => {
    debugger;
    let tableData = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.tableData))
    );
    let APIIntegrationHotelData = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.APIIntegrationHotelData))
    );

    if (type === "isCheckAll") {
      for (let i = 0; i < APIIntegrationHotelData.length; i++) {
        // if (APIIntegrationHotelData[i]["viewType"] === viewType) {
        APIIntegrationHotelData[i].checkValue = event.target.checked
          ? "Yes"
          : "No";
        APIIntegrationHotelData[i].isUpdated = "Yes";
        // }
      }
      for (let i = 0; i < tableData.length; i++) {
        // if (tableData[i]["viewType"] === viewType) {
        for (let j = 0; j < this.state.headerData.length; j++) {
          tableData[i][this.state.headerData[j]["name"]] = event.target.checked
            ? "Yes"
            : "No";
        }
        tableData[i]["isCheckAll"] = event.target.checked ? "Yes" : "No";
        // }
      }
    } else {
      for (let i = 0; i < APIIntegrationHotelData.length; i++) {
        if (APIIntegrationHotelData[i]["name"] === type) {
          APIIntegrationHotelData[i].checkValue = event.target.checked
            ? "Yes"
            : "No";
          APIIntegrationHotelData[i].isUpdated = "Yes";
        }
      }
      for (let i = 0; i < tableData.length; i++) {
        // if (tableData[i]["viewType"] === viewType) {
        tableData[i][type] = event.target.checked ? "Yes" : "No";
        // }
      }
    }
    this.setState(
      {
        tableData,
        APIIntegrationHotelData,
        isSaveEnabled: true,
        applyCheckUncheckClass: false,
      },
      () => {
        let tableData = Object.assign(
          [],
          JSON.parse(JSON.stringify(this.state.tableData))
        );
        for (let i = 0; i < tableData.length; i++) {
          // debugger;
          for (let j = 0; j < this.state.headerData.length; j++) {
            if (tableData[i][this.state.headerData[j]["name"]] === "Yes") {
            } else {
              tableData[i]["isCheckAll"] = "No";
              break;
            }
            tableData[i]["isCheckAll"] = "Yes";
          }
        }
        this.setState({ tableData });
      }
    );
  };

  isColumnHeaderChecked = (type, viewType) => {
    // debugger;
    //let tableData = Object.assign([], JSON.parse(JSON.stringify(this.state.tableData.filter(x => x.viewType === viewType))));
    if (this.state.tableData.length === 0) {
      return false;
    }
    for (let i = 0; i < this.state.tableData.length; i++) {
      if (this.state.tableData[i][type] === "Yes") {
      } else {
        return false;
      }
    }
    return true;
  };
  headerShowCheck = (type) => {
    if (this.state.tableData.length === 0) {
      return false;
    }
    const checkedEhid = this.state.tableData.filter(
      (item) => item[type] === "Yes"
    );
    if (
      checkedEhid.length != 0 &&
      checkedEhid.length != this.state.tableData.length
    ) {
      return true;
    }
    return false;
  };
  render() {
    const columns = [
      {
        dataField: "ehid",
        text: "EHID",
        headerClasses: "",
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div className="d-flex align-items-center">
              <div className="col-text sub-module">{row.ehid}</div>
            </div>
          );
        },
      },
      {
        dataField: "isCheckAll",
        text: "Check All",
        headerClasses: "",
        classes: "",
        headerFormatter: (column, colIndex) => {
          return (
            <div
              className={`d-flex ${
                this.headerShowCheck("isCheckAll") ? "show-half-check" : ""
              }`}
            >
              <Form.Check
                custom
                id={"chkheader1_isCheckAll"}
                key={"chkheader1_isCheckAll"}
                type="checkbox"
                label={""}
                checked={this.isColumnHeaderChecked("isCheckAll", 1)}
                onChange={(e) =>
                  this.handleHeaderCheckbox(e, column.dataField, 1)
                }
              />
              <div>{column.text}</div>
            </div>
          );
        },
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div className="bdr-left height-48 d-flex align-items-center">
              <Form.Check
                custom
                id={"chk1_isCheckAll" + row.id}
                key={"chk1_isCheckAll" + row.id}
                type="checkbox"
                label={""}
                checked={row?.isCheckAll === "Yes" ? true : false}
                onChange={(e) => this.handleCheckAllRow(e, row)}
              />
            </div>
          );
        },
      },
      ...this.state.dynamicColumnArray,
    ];
    const {
      isTenantLoading,
      filterTenantList,
      selectedTenant,
      isTableLoading,
      isSubmitLoading,
      isSaveEnabled,
    } = this.state;
    const checkItem = () => {
      return (
        <span className="itemcheck">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.49998 11.6895L5.03023 9.21973L3.96973 10.2802L7.49998 13.8105L14.7802 6.53023L13.7197 5.46973L7.49998 11.6895Z"
              fill="#2CAF92"
            />
          </svg>
        </span>
      );
    };
    return (
      <div className="api-integration-page">
        <Container fluid className="body-sec pos-rel">
          <ToastContainer
            autoClose={3000}
            containerId={"AAccounting"}
            enableMultiContainer
          />

          <div className="page-heading underline d-flex">
            <div className="mr-auto">API Access</div>
            <div className="action-group d-flex">{/**/}</div>
          </div>
          <div className="d-flex headerDownControl">
            <div className="ePayTenantSelector">
              <label>Tenant</label>
              <div className="d-flex">
                <Dropdown
                  className={`${
                    isTenantLoading ? "disabled-area" : ""
                  } ePayTenantSelectorDropdown`}
                  onToggle={this.tenantDDToggle}
                >
                  <Dropdown.Toggle className="ePayButton" id="dropdown-Tenant">
                    <div className="d-flex align-items-center justify-content-center">
                      <div className="">
                        {selectedTenant.id !== -1
                          ? selectedTenant.name
                          : "Select Tenant"}
                      </div>
                      <div className="ml-auto drop-arrow d-flex">
                        <FiChevronDown />
                      </div>
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    className="ePayTenantSelectorMenu"
                    ref={this.tenantSelector}
                  >
                    <div className="ePayTenantSearch">
                      <Form.Group controlId="ePayTenantSearch">
                        <Form.Control
                          id="idTenantSearch"
                          type="text"
                          name="search"
                          value={this.state.tenantSearch}
                          autoComplete="off"
                          onChange={this.searchItems}
                          placeholder="Search Tenant"
                          autoFocus={true}
                        />
                      </Form.Group>
                    </div>
                    <div className="dropdownItems">
                      {filterTenantList.length > 0 ? (
                        <>
                          {filterTenantList.map((item: any, idx: any) => (
                            <Dropdown.Item
                              className={
                                selectedTenant.name === item.tenantName
                                  ? "active"
                                  : ""
                              }
                              key={idx}
                              eventKey={item.tenantID}
                              onClick={() => this.tanentChange(item)}
                            >
                              <div className="d-flex align-items-center justify-content-center">
                                <div className=""> {item.tenantName}</div>
                                <div className="ml-auto">
                                  {item.tenantID === selectedTenant.id &&
                                    checkItem()}
                                </div>
                              </div>
                            </Dropdown.Item>
                          ))}
                        </>
                      ) : (
                        <Dropdown.Item>No Item Found</Dropdown.Item>
                      )}
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>

          {selectedTenant.id !== -1 && (
            <>
              {isTableLoading ? (
                <ReactPageLoader useas={"payroll-position-mapping"} />
              ) : (
                <>
                  <div className="inner-hide api-integration-setup">
                    <div className="api-integration-setup-wrapper">
                      <div className="ePayItemBody">
                        <BootstrapTable
                          keyField="rno"
                          data={this.state.tableData}
                          columns={columns}
                          //noDataIndication={'No record found.'}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="ePaySubmitPanel">
                    <Button
                      disabled={!isSaveEnabled || isSubmitLoading}
                      onClick={this.handleSave}
                    >
                      {isSubmitLoading && (
                        <Spinner
                          size="sm"
                          className="disabled-area"
                          animation="border"
                        />
                      )}{" "}
                      Save
                    </Button>
                  </div>
                </>
              )}
            </>
          )}
        </Container>
      </div>
    );
  }
}
