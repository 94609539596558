import * as React from 'react';
import { WorkOrder } from '../../Common/Services/WorkOrder';
import { IWorkOrderCommentRequestDto } from '../../Common/Contracts/IWorkOrder';
import { Utils } from '../../Common/Utilis';
import "./workOrderDetails.scss";
import { resolve, reject } from "q";

export default class AddHistoryBox extends React.Component<any, any> {
    private scrollToBottomlist: any;
    constructor(props: any) {
        super(props);
        this.scrollToBottomlist = React.createRef();
        this.state = {
            hotelid: this.props.hotelid,
            entryType: this.props.entryType,
            workOrderId: this.props.workOrderId,            
           // precommentlist: this.props.precommentlist,
           precommentlist:[],
            value: '',
            mentionData: null,
            commentlist: [],
            users: [],
            htmlHistory: [],
            userComments: [],
            logInUser: "",
            logInUserImage: "",
            isCommentAdd: false,
            addCommentDisabled : this.props.addCommentDisabled,
            workOrderName : this.props.parentState.parentRowData.name,
        };
    }

   
    reloadHistory = () => {
      this.getHistoryWorkOrder();
    }

    componentDidMount() {
        this.getHistoryWorkOrder();
        // this.usernameList();

    }
    getHistoryWorkOrder()
    {
        this.setState({ isworkLoader: true });
        let request = {} as IWorkOrderCommentRequestDto;
        request.hotelid =  Number(this.state.hotelid);
        request.workOrderID =Number(this.state.workOrderId);
        WorkOrder.getHistoryWorkOrder(request)
          .then(async (result: any | null) => {          
            if (result != null && result.length > 0) {
              this.setState({ precommentlist: result},()=>{
                this.loadPrevHistory();
              });
            } else {
              this.setState({ precommentlist: []});
            }
           
          
            resolve();
          })
          .catch((err) => {
            this.setState({ isworkLoader: false });
            Utils.toastError(`Server Error, ${err}`, {
            });
    
            reject();
          });
    }
    getBadgeClass(value) {
      switch (value) {
          case "Open": return "badge open";
          case "In Progress": return "badge progress-badge";
          case "On Hold": return "badge hold-badge";
          case "Completed": return "badge completed";
          default: return "badge";
      }
  }
    loadPrevHistory() {
      const { precommentlist } = this.state;
    
      if (precommentlist.length > 0) {
        const itemComment = precommentlist.map((element,index) => {
          let message = "";
          switch (element.columnName) {
            case "CreateWorkOrder":
              message = `${element.createBy} created Work Order <b>${element.oldValue}</b>`;
              break;
            case "Title":
              message = `${element.createBy} changed Work Order name from <b>${element.oldValue || "None"}</b> to <b>${element.newValue || "None"}</b>`;
              break;
            case "Status":
              const oldClass = this.getBadgeClass(element.oldValue);
              const newClass = this.getBadgeClass(element.newValue);
              message = `${element.createBy} changed Work Order Status from <span class="${oldClass}">${element.oldValue || "None"}</span> to <span class="${newClass}">${element.newValue || "None"}</span>`;
              break;
            case "AssignedTo":
              message = `${element.createBy} reassigned Work Order from <b>${element.oldValue}</b> to <b>${element.newValue}</b>`;
              break;
            case "Description":
              message = `${element.createBy} updated Description from <b>${element.oldValue || "None"}</b> to <b>${element.newValue || "None"}</b>`;
              break;
            case "Location":
              message = `${element.createBy} changed Location of Work Order from <b>${element.oldValue || "None"}</b> to <b>${element.newValue || "None"}</b>`;
              break;
            case "PriorityType":
              message = `${element.createBy} changed Priority from <b class="priority ${element.oldValue.toLowerCase()}">${element.oldValue || "None"}</b> to <b class="priority ${element.newValue.toLowerCase()}">${element.newValue || "None"}</b>`;
              break;
              case "FileAdd":
              message = `${element.createBy} attached <b>${element.oldValue}</b>`;
              break;
              case "FileDelete":
              message = `${element.createBy} removed <b>${element.oldValue}</b>`;
              break;
              case "Comments":
              message = `${element.createBy} added a comment to ${this.state.workOrderName} : <b>${element.oldValue}</b>`;
              break;
            default:
              message = `${element.createBy} changed ${element.columnName} from  <b> ${element.oldValue || "None"}</b> to <b> ${element.newValue || "None"}</b>`;
              break;
          }
          return (
            <div key={element.ordered} className="timeline-item">
              <div className="timeline-dot"></div>
              {<div className="timeline-line"></div>}
              <div className="timeline-content">
                <span className="timeline-date">
                  {element.modifyedDate}
                </span>
                <div
                  className="timeline-message"
                  dangerouslySetInnerHTML={{ __html: message }}
                ></div>
              </div>
            </div>
          );
        });
        this.setState({ htmlHistory: itemComment });
      }
    }

    render() {
        return (
            <div className="comment-box">
            <div className="comment-list">
              <ul>{this.state.htmlHistory}</ul>
            </div>
          </div>
        );
    }
}