import React from "react";
import { Button, Col, Dropdown, Form, Modal, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { Utils } from "../../Common/Utilis";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import Joi from "joi-browser";
import { DropDownList } from "../Reports/common-components/dropdown-list";
import { StaticArrays } from "../../Common/StaticArrays";
import _ from "lodash";
import { Facility } from "../../Common/Services/Facility";
import LocationSingleSelector from "../Facilities/LocationSingleSelector";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import Dropzone from 'react-dropzone';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus, faPaperclip, faFile, faFileImage, faFileExcel, faFilePdf, faFileCsv, faFileWord, faFileText, faFilePowerpoint } from "@fortawesome/free-solid-svg-icons";
import { WorkOrder } from "../../Common/Services/WorkOrder";
import { resolve, reject } from "q";
import { tenantID } from "../../Common/Services/LocalStorage";
import "./workOrderDetails.scss";
import { confirmAlert } from "react-confirm-alert";




export class AddWorkOrder extends React.Component<any, any> {
    private scrollheight: any;
    
  private FormSchema = Joi.object({
    title: Joi.string().max(50)
    .required()
    .error((errors) => {
      return errors.map((error) => {
        return { message: "Title is required" };
      });
    }),

    location: Joi.number()
    .required()
    .min(1)
    .error((errors) => {
      return errors.map((error) => {
        return { message: "Please select Location" };
      });
    }),

    priorityType: Joi.string()
    .required()
    .min(1)
    .error((errors) => {
      return errors.map((error) => {
        return { message: "Please select Priority" };
      });
    }),

    description: Joi.string()
    .required()
    .error((errors) => {
      return errors.map((error) => {
        return { message: "Details is required" };
      });
    }),

    assignedTo: Joi.number()
  });  
  

  state: any = {
    FormData: {
        title: '',
      description:  '',
      location:-1,
      priorityType:-1,
      assignedTo:-1
      
    },
    errors: {},
    isDataSaving: false,
    isFormNotValid: true,
    inputPosition: 0,
    dateList: [{label: "11/11/21", value: "11/11/21"}, {label: "11/11/21", value: "11/11/21"}],
    isEditButton: false,
    savedfiles: [],
    newSelectfiles: [],
    moreActionFileUpload1: [
        { name: "Delete", eventKey: "delete" },
        { name: "Download", eventKey: "download" }
    ],
    fileSize: 5242880,
    files: [],
    workOrderId : 0,
    rooms: [],
    newFiles: [],
    priorityList: [

      { id: "Low", name: "Low" },
      { id: "Medium", name: "Medium" },
      { id: "High", name: "High" },
      { id: "Urgent", name: "Urgent" },
  ],
  // assignToList: [{ id: 0, name: "Select a technician" }, ...this.props.assignToList],
  isChecked: false,
  modalshow: this.props.showAddWorkOrderModal,   
  isSaving: false,
  isBoxFocused: true
  };

  componentDidMount = () => {
    this.getrooms();
    this.getHotels();
  };

  getrooms = () =>  {
    WorkOrder.GetFacilityRooms(this.props.hid)
      .then(async (result: any | null) => {
        let DayApproveData: any = [];
        if (result != null && result.length > 0) {  
          let Data = result.map(x => {
              return { id: x?.roomID, name: x?.roomName };
            })   
            
          this.setState({
            rooms: Data
          });
        }
        resolve();
      })
      .catch((err) => {
        Utils.toastError(`Server Error, ${err}`, {         
        });
        
        reject();
      });
  }



  
  getHotels = () => {
    this.setState({ hotelList: this.props?.userHotels });
  };

  hideWorkModal = () => {
    this.props?.iscloseModulePopup();
    
  }

  onFieldChange = (event, inputPosition) => {
    const { FormData } = this.state;
    let value = event.target.value;
    const fieldName = event.target.name;
    if(fieldName === "title" || fieldName === "description"){
      value = value?.toString()?.trimLeft();
    }
   
    FormData[fieldName] = value;
    this.setState({FormData},()=> {
      this.validationOnClick(inputPosition)
    }); 
  };

 



  SaveWorkOrder = () => {
    if (!this.state.isSaving) {
      this.setState({ isSaving: true });
    }
    let {
        FormData: {
            title,
            location,
            priorityType,
            assignedTo,
            description,
            // hidValue
        }
      } = this.state; 

    const data={
        hid:this.props.hid,
        LocationID: location,
        Title: title,
        priorityType: priorityType,
        description: description,
        assignedTo: assignedTo,
        AssetID:0,

        }
    if(this.state.isDataSaving === true || this.state.isFormNotValid === true)
    {
      this.validationOnClick(5)
      if(location === -1){
        this.setState({isBoxFocused : false})
      }
      Utils.toastError(`Please populate all required fields before continuing.`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "addGarnishment",
      });
      this.setState({ isSaving: false });
    }
    else{
      WorkOrder.SaveWorkOrder(data)
      .then((result: any) => {
          if(result.messageCode === "Success"){
            this.setState({workOrderId: result.id});
            this.handleSave();
            if(this.state.isChecked){
              const    FormData = {
                title:  '',
                priorityType:-1,
                description:  '',
                location:-1,
                assignedTo:-1
            };
              this.setState({modalshow: true ,FormData,newSelectfiles:[],isFormNotValid: true});
              this.props?.isRequestSave(0,true);
             }
             else{
              this.props?.isRequestSave(result.id,false);
             }
            toast.success(`Work Order Added successfully.`, {
               position: toast.POSITION.BOTTOM_RIGHT,
               containerId: "addGarnishment",
             });    
          }
          // else if(result.messageCode === "Fail"){
          //   Utils.toastError(`Work Order already exist.`, {
          //     position: toast.POSITION.BOTTOM_RIGHT,
          //     containerId: "addGarnishment",
          //   }); 
          // }
          setTimeout(() => {
            this.setState({ isSaving: false });
          }, 1000);
      }).catch((error)=>{
          this.setState({isDataSaving:false})       
          //  Utils.toastError(error.response.data, {
          //     position: toast.POSITION.BOTTOM_RIGHT,
          //     containerId: "addGarnishment",
          //   });
            this.hideWorkModal()
      })
    }
  };

  
  validateConfigurationFormSchema = (isAssetNameChanged=false) => {
    const valid = Joi.validate(
      this.state.FormData,
      this.FormSchema,
      {
        abortEarly: false,
      }
    );

    const newErrorObject: any = {};
    if (valid.error) {
      valid.error.details.forEach((err) => {
        newErrorObject[err.path.join(".")] = err.message;
      });
    }
    if (Object.keys(newErrorObject).length === 0) {
      this.setState({ isFormNotValid: false });
    } else {
      this.setState({ isFormNotValid: true});
    }
    this.setState({ errors: newErrorObject });
    return newErrorObject;
  };

  getFieldError = (fieldName: string, fieldPosition: number) => {
    const { errors, inputPosition } = this.state;
    return (
      <>

        {Object.keys(errors).length > 0 && fieldPosition <= inputPosition && (
          <span className="validation-message">{errors[fieldName]}</span>
        )}
      </>
    );
  };


  validationOnClick = (inputPosition) => {
    if(inputPosition !== 3){
    this.setState({ inputPosition }, () =>
      this.validateConfigurationFormSchema()
    );
    }
  };

  handleBoxFocus = () => {
    this.setState({isBoxFocused :true});
  };

  handleBoxBlur = () => {
    this.setState({isBoxFocused :false});
  };
  
  isTempDeleteWorkOrder = (file: any) => {       
    let data = [...this.state.newSelectfiles]
    let index = data.findIndex((item) => item.etype === "newfile");
    data.splice(index, 1);
    this.setState({ newSelectfiles: data });
}
  
  handleSelectFileUpload = (eventKey: any, file, index) => {
        let masg = "";
        masg = "Are you sure you want to delete this Attachment?"
        confirmAlert({
            title: "Delete Attachment",
            message: masg,
            buttons: [
                {
                    label: "Yes",
                    onClick: () => this.isTempDeleteWorkOrder(file),
                },
                {
                    label: "No",
                    onClick: () => reject(),
                },
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
        });
}


  onFileChange = (event: any, mode) => {
    let newfiles: any = [];
    if (event.target.files.length > 0) {
        for (let i = 0; i < event.target.files.length; i++) {
            let fileRow = event.target.files[i];
            newfiles.push(fileRow)
        }
        this.onDrop(newfiles, mode);
        let fileList = document.getElementById('formControlsFile') as any
        fileList.value = "";
    }
};

onDrop = (filelist: any, mode: any) => {
  let size = this.state.fileSize;//5242880//5MB
  let newfiles: any = [];
  let files: any = [];
  let isReturn = true;
  let filestext = "";
  let filesizeval = Number(Number(this.state.fileSize) / (1024 * 1024))
  filelist.forEach(element => {
      if (element.size > size) {
          filestext = filestext + element.name + ","
          isReturn = false;
      }
  })
  
  if (!isReturn) {
      let msg = "One or more files are greater than " + filesizeval + "MB.";
      Utils.toastError(msg, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "InvoiceSlideout",
      });
  }
  let isInvalidFile = false;
  filelist.forEach(element => {
      if ((element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'pdf'
          || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'doc'
          || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'docx'
          || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'xls'
          || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'xlsx'
          || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'csv'
          || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'txt'
          || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'bmp'
          || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'tif'
          || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'ppt'
          || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'pptx'
          || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'rtf'
          || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'jpg'
          || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'jpeg'
          || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'png'
          || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'msg'
          || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'eml'
      ) && element.size <= size) {
          newfiles.push(element)
          files.push(element)
      } else {
          isInvalidFile = true;
      }
  });
  if (isInvalidFile && isReturn) {
      let msg = "";
      if (filelist.length === 1) {
          msg = "This file type is not supported."
      } else {
          msg = "Some files are not supported."
      }
      Utils.toastError(msg, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "InvoiceSlideout",
      });
  }

  let newfileData = [...this.state.newSelectfiles];
  for (let i = 0; i < newfiles.length; i++) {
      newfiles[i].etype = "newfile";
      newfileData.push(newfiles[i]);
  }


  this.setState({ files, newSelectfiles: newfileData, changeValue: true }, () => {
      if (mode === 2) {
      } else {
          if (files.length > 0) {
          }
      }

  })
}
GetUploadFileList = () => {

  let requestlist: any = {};
  requestlist.hotelID = Number(this.state.parentRowData?.hid);
  requestlist.workOrderID = Number(this.state.parentRowData.workOrderID);

  WorkOrder.GetUploadFileList(requestlist)
      .then(async (result: any | null) => {
          if (result !== null) {

              for (let i = 0; i < result.length; i++) {
                  this["myRef" + i] = React.createRef();
              }
              this.setState({ savedfiles: [] }, () => {
                  this.setState({ savedfiles: result, files: [], newSelectfiles: [] }, () => {

                  });
              });
          }
          resolve();
      })
      .catch((error) => {
          reject();
      });
};

handleSaveFiles(files: any) {
  let request: any = {};
  request.hotelID = this.props.hid;
  // request.workOrderID = Number(this.state.parentRowData.workOrderID);
  request.workOrderID = this.state.workOrderId
  WorkOrder.SaveWorkOrderFile(request, files)
      .then(async (result: any | null) => {
          if (result !== null) {
              if (result.saveStatus === "Success") {
                  // toast.success("Attachment uploaded successfully", {
                  //     position: toast.POSITION.BOTTOM_RIGHT,
                  //     containerId: "InvoiceSlideout",
                  // });

                  // let formData = { ...this.state.formData };
                  // if (this.props.pageType === "Invoice") {
                  //     // formData.uniqueno = 0;
                  //     // formData.incompleteUniqueno = 0;
                  // } else {
                  //     formData.incompleteUniqueno = result.actualID;
                  //     formData.uniqueno = 0;
                  // }

                  // if (request.uniqueno === 0) {
                  //    // this.GetTransactionComment(result.actualID);
                  // }

                  //  this.setState({ invoiceUniqueNo: result.actualID,, isDisabled: false })
                  this.GetUploadFileList();
              }
              else {
                  Utils.toastError(result.message, {
                      position: toast.POSITION.BOTTOM_RIGHT,
                      containerId: "InvoiceSlideout",
                  });
                  this.setState({ isDisabled: false })
                  if (result.messageCode === "Validation") {
                      //this.handleDiscard(request.uniqueno);
                  }
              }
          }

          resolve();
      })
      .catch((error) => {

          reject();
      });

}

handleSave=()=> {
        
  let request: any = {};
  request.hotelID = this.props.hid;
  request.workOrderID = this.state.workOrderId;  
  request.locationId=this.state.FormData.location;
  request.priorityId=this.state.FormData.priorityType;
  request.assignedId=this.state.FormData.assignedTo;
  request.description=this.state.FormData.description; 
  request.assetId= 0;
  request.dueDate="";

  // let files = [...this.state.savedfiles];
   let newfileData = [...this.state.newSelectfiles];
  // for (let i = 0; i < newfileData.length; i++) {          
  //     files.push(newfileData[i]);
  // }
 
  WorkOrder.SaveWorkOrderFile(request, newfileData)
      .then(async (result: any | null) => {
          if (result !== null) {
              if (result.saveStatus === "Success") {
                  // toast.success("Attachment uploaded successfully", {
                  //     position: toast.POSITION.BOTTOM_RIGHT,
                  //     containerId: "InvoiceSlideout",
                  // });

                  // let formData = { ...this.state.formData };
                  // if (this.props.pageType === "Invoice") {
                  //     // formData.uniqueno = 0;
                  //     // formData.incompleteUniqueno = 0;
                  // } else {
                  //     formData.incompleteUniqueno = result.actualID;
                  //     formData.uniqueno = 0;
                  // }

                  // if (request.uniqueno === 0) {
                  //    // this.GetTransactionComment(result.actualID);
                  // }

                  //  this.setState({ invoiceUniqueNo: result.actualID,, isDisabled: false })
                  this.GetUploadFileList();
              }
              else {
                  Utils.toastError(result.message, {
                      position: toast.POSITION.BOTTOM_RIGHT,
                      containerId: "InvoiceSlideout",
                  });
                  this.setState({ isDisabled: false })
                  if (result.messageCode === "Validation") {
                      //this.handleDiscard(request.uniqueno);
                  }
              }
          }

          resolve();
      })
      .catch((error) => {

          reject();
      });

}


handlePreview = (file: any) => {

    this.setState({ fileSelect: '', isOpenViewer: false }, () => {
        this.setState({ fileSelect: file, isOpenViewer: true, fileName: file.name }, () => {
            if (this.state.isOpenViewer) {
                setTimeout(() => {
                    const innerWidthSec = this.scrollheight.current.getElement().getBoundingClientRect().height;
                    this.setState({ scrollAreaHeight: innerWidthSec })
                }, 100);
            }
        })
    })
}

hideBulkselect() {
    this.setState({ bulkSelect: false, chkIds: [], selectedChk: [], isExtractOption: false, isMapped: "No" })
    for (let i = 0; i < this.state.savedfiles.length; i++) {
        this["myRef" + i].current.hideBulkSelect();
    }
}

handleCheckboxChange = (event) => {
  if(event.target.checked === true){
    this.setState({isChecked: true});    
  }
  else{
    this.setState({isChecked: false});
  }
 
};


OpenViewer() {
    if (this.state.bulkSelect && this.state.isOpenViewer) {
        this.hideBulkselect();
    }
    this.setState(prevState => ({
        isOpenViewer: !prevState.isOpenViewer,
        fileSelect: this.state.savedfiles.length > 0 ? this.state.savedfiles[0] : this.state.newSelectfiles[0]
        , isMapped: 'No', isExtractOption: false
    }), () => {
        this.setState({ defaultView: "doc-view" })
        if (this.state.isOpenViewer) {
            setTimeout(() => {
                const innerWidthSec = this.scrollheight.current.getElement().getBoundingClientRect().height;
                this.setState({ scrollAreaHeight: innerWidthSec })
            }, 100);
        }
    });

}

  render() {    
    const files = this.state.newSelectfiles.map((file, index) => (


        <>
            <li key={file.name}>
                <div className="d-flex align-items-center justify-content-start">
                      <div className="icon">
                        {(file?.type)?.split('/')[0] === 'image' && (
                            <FontAwesomeIcon icon={faFileImage} />
                        )}
                        {(file?.name.substring(file?.name.lastIndexOf(".") + 1, file?.name.length)) === 'pdf' && (
                            <FontAwesomeIcon icon={faFilePdf} />
                        )}
                        {['doc', 'docx','word'].includes(file?.name?.substring(file?.name.lastIndexOf(".") + 1).toLowerCase()) && (
                            <FontAwesomeIcon icon={faFileWord} />
                        )} 
                        {['xlsx', 'xls'].includes(file?.name?.substring(file?.name.lastIndexOf(".") + 1).toLowerCase()) && (
                          <FontAwesomeIcon icon={faFileExcel} />
                        )}
                        {['ppt', 'pptx'].includes(file?.name?.substring(file?.name.lastIndexOf(".") + 1).toLowerCase()) && (
                          <FontAwesomeIcon icon={faFilePowerpoint} />
                        )}
                        {(file?.name.substring(file?.name.lastIndexOf(".") + 1, file?.name.length)) === 'txt' && (
                            <FontAwesomeIcon icon={faFileText} />
                        )}
                        {(file?.name.substring(file?.name.lastIndexOf(".") + 1, file?.name.length)) === 'csv' && (
                            <FontAwesomeIcon icon={faFileCsv} />
                        )}
                        {!['image', 'pdf', 'xlsx','xls','csv', 'word','txt','doc', 'docx','ppt','pptx'].includes(file?.type?.split('/')[0] === 'image'
                                ? 'image'
                                : file?.name?.substring(file?.name.lastIndexOf(".") + 1)?.toLowerCase()
                        ) && (
                            <FontAwesomeIcon icon={faFile} />
                        )}
                    </div> 
                    <div className="file-info d-flex align-items-center">
                        <div className="name-loader d-flex align-items-center">
                            <div className="name">
                                <EllipsisWithTooltip placement="bottom">
                                    <div className="full-name" onClick={() => { this.handlePreview(file) }}>{file.name}</div>
                                </EllipsisWithTooltip>
                            </div>
                        </div> 
                        <div className="formate-percentage">
                          <span className="formate d-flex align-items-center justify-content-center">
                              {(file?.name.substring(file?.name.lastIndexOf(".") + 1, file?.name.length))}
                          </span>
                      </div>
                    </div> 
                    <div className="action text-center">
                      <button className="btn-outline-primary remove-pdf more btn btn-primary  border-0 h-auto py-1 px-2 ml-2" onClick={() => { this.handleSelectFileUpload("TempDelete", file, 0) }} >
                          <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z"></path></svg>
                      </button>
                    </div>
                </div>
            </li>
        </>
    ));

    const files1 = this.state.savedfiles.map((file, index) => (
        <>
            <li key={file.name}>
                <div className="d-flex align-items-center justify-content-start">
                    <div className="icon">
                        {(file?.type)?.split('/')[0] === 'image' && (
                            <FontAwesomeIcon icon={faFileImage} />
                        )}
                        {(file?.name.substring(file?.name.lastIndexOf(".") + 1, file?.name.length)) === 'pdf' && (
                            <FontAwesomeIcon icon={faFilePdf} />
                        )}
                        {(file?.name.substring(file?.name.lastIndexOf(".") + 1, file?.name.length)) === 'xlsx' && (
                            <FontAwesomeIcon icon={faFileExcel} />
                        )}
                        {(file?.name.substring(file?.name.lastIndexOf(".") + 1, file?.name.length)) === 'csv' && (
                            <FontAwesomeIcon icon={faFileCsv} />
                        )}
                        {(file?.name.substring(file?.name.lastIndexOf(".") + 1, file?.name.length)) === 'word' && (
                            <FontAwesomeIcon icon={faFileWord} />
                        )}
                        {(((file?.type)?.split('/')[0] !== 'image') &&
                            ((file?.name.substring(file?.name.lastIndexOf(".") + 1, file?.name.length)) !== 'pdf') &&
                            ((file?.name.substring(file?.name.lastIndexOf(".") + 1, file?.name.length)) !== 'xlsx')
                            && ((file?.name.substring(file?.name.lastIndexOf(".") + 1, file?.name.length)) !== 'csv')
                            && ((file?.name.substring(file?.name.lastIndexOf(".") + 1, file?.name.length)) !== 'word'))
                            && (
                                <FontAwesomeIcon icon={faFile} />
                            )}
                    </div>
                    <div className="file-info d-flex align-items-center">
                        <div className="name-loader d-flex align-items-center">
                            <div className="name">
                                <EllipsisWithTooltip placement="bottom">
                                    <div className="full-name" >{file.name}</div>
                                </EllipsisWithTooltip>
                                <div className="details">{file.createDate} - {file.pageno} Page</div>
                            </div>
                        </div>
                        <div className="formate-percentage">
                            {/* <Spinner animation="border" variant="success" size="sm" /> */}
                            <div className="formate d-flex justify-content-center align-items-center">
                                {(file?.name.substring(file?.name.lastIndexOf(".") + 1, file?.name.length))}
                            </div>
                            {/* <div className="percentage d-flex justify-content-center align-items-center">45%</div> */}
                        </div>
                    </div>
                    <div className="action">
                        {!this.state.bulkSelect && (
                            <Dropdown className="more-action" alignRight onSelect={(event: any) => this.handleSelectFileUpload(event, file, index)}>
                                <Dropdown.Toggle className="btn-outline-primary btn btn-primary more" id="dropdown-more">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                                        <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                                        <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                                    </svg>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {this.state.moreActionFileUpload1.map(
                                        (item: any, idx: any) => (
                                            <>
                                                {this.state.isPermission === "Yes" && this.state.formData.incompleteUniqueno > 0 && this.state.formData.uniqueno === 0 && (
                                                    <Dropdown.Item
                                                        className={((this.state.formData.actionType === "copy" || this.state.isError === "NotEdit" || this.state.formData.adj.toLowerCase() === "y") && this.state.moreActionFileUpload1[idx].eventKey === "delete") ? "isHide" : ""}
                                                        //className={((this.state.formData.actionType === "copy" || this.state.isError === "NotEdit") && this.state.formData.uniqueno > 0) ? "isHide" : ""}
                                                        eventKey={this.state.moreActionFileUpload1[idx].eventKey}
                                                        key={idx}
                                                    >
                                                        {this.state.moreActionFileUpload1[idx].name}
                                                    </Dropdown.Item>
                                                )}



                                                {this.state.invoiceUniqueNo > 0 && this.state.formData.uniqueno > 0 && (
                                                    <Dropdown.Item
                                                        className={((this.state.formData.actionType === "copy" || this.state.isError === "NotEdit" || this.state.isEdit === "No" || this.state.formData.adj.toLowerCase() === "y") && this.state.moreActionFileUpload1[idx].eventKey === "delete") ? "isHide" : ""}
                                                        eventKey={this.state.moreActionFileUpload1[idx].eventKey}
                                                        key={idx}
                                                    >
                                                        {this.state.moreActionFileUpload1[idx].name}
                                                    </Dropdown.Item>
                                                )}
                                            </>



                                        )
                                    )}
                                </Dropdown.Menu>
                            </Dropdown>
                        )}
                    </div>
                </div>
            </li>
        </>
    ));
    const {
      FormData: {
          title,
          location,
          description,
          priorityType,
          assignedTo
      },
      errors,
      isDataSaving,
      isFormNotValid,
      inputPosition,
      dateList,
      
    } = this.state;

    const { showAddWorkOrderModal,assignToList,locationTypesList} = this.props;
    
    
    return (
      <>
        <div className="">
          <ToastContainer containerId={"addDeduction"} autoClose={3000} />

          <Modal
            className="add-room-type-modal add-new-asset add-work-order-modal"
            show={showAddWorkOrderModal}
            onHide={this.hideWorkModal}
            aria-labelledby="contained-modal-title-vcenter"
            centered
           // keyboard={this.props?.showDeductionModal}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Add Work Order
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-section">
                <div className="body-section">
                <Form.Group 
                      onBlur={() => this.validationOnClick(1)}
                      controlId="location" className="d-flex">
                    {/* <Col
                      sm={"12"}
                      className={`mb-0 ${
                        errors?.location && inputPosition >= 1
                          ? "validation-error"
                          : ""
                      }`}
                    > */}
                    <Col
                      sm={"12"}
                      className={`mb-0 ${(errors?.location && !this.state.isBoxFocused && inputPosition >= 1) ? "validation-error" : ""
                        }`}
                      onFocus={this.handleBoxFocus}
                      onBlur={this.handleBoxBlur}
                    >
                      <Form.Label>Location <b>*</b></Form.Label>

                      {locationTypesList.length > 0 &&  <DropDownList
                          isIcon={false}
                          placeHolder={"Select a location"}
                          data={locationTypesList}
                          defaultValue={location}
                          isSearchRequired={true}
                          label={"name"}
                          value={"id"}
                          placeHolderItem={{ id: -1, name: "Select a location" }}
                          // autoComplete="off"
                          onDropDownChange={(item) => {
                            if (!item) {
                              return;
                            }
                            this.onFieldChange(
                              Utils.BuildCustomEventObject("location", item.id),
                              1
                            );
                          }}
                          selectedItem={[
                            ...[{ id: -1, name: "Select a location" }],
                            ...locationTypesList,
                          ].find((r: any) => r?.id === location)}
                        />
                        }
                     
                      {/* {this.getFieldError("priorityType", 2)} */}
                    {/* </Col> */}
                     
                      {/* {this.getFieldError("location", 1)} */}
                    </Col>
                      </Form.Group>


               
                  
                  <Form.Group 
                      onBlur={() => this.validationOnClick(2)}
                      controlId="priorityType" className="d-flex"
                      >
                    <Col
                      sm={"6"}
                      className={`mb-0 pl-0 pr-2 ${
                        errors?.priorityType && inputPosition >= 2
                          ? "validation-error"
                          : ""
                      }`}
                    >
                      <Form.Label>Priority <b>*</b></Form.Label>
                      
                      
                    {this.state.priorityList.length > 0 &&  <DropDownList
                          placeHolder={"Select Priority"}
                          data={this.state.priorityList}
                          defaultValue={priorityType}
                          isSearchRequired={false}
                          label={"name"}
                          value={"id"}
                          placeHolderItem={{ id: -1, name: "Select Priority" }}
                          // autoComplete="off"
                          onDropDownChange={(item) => {
                            if (!item) {
                              return;
                            }
                            this.onFieldChange(
                              Utils.BuildCustomEventObject("priorityType", item.name),
                              2
                            );
                          }}
                          selectedItem={[
                            ...[{ id: -1, name: "Select Priority" }],
                            ...this.state.priorityList,
                          ].find((r: any) => r?.name === priorityType)}
                        />}
                     
                      {/* {this.getFieldError("priorityType", 2)} */}
                    </Col>
                    {/* </Form.Group> */}
                    {/* <Form.Group 
                      onBlur={() => this.validationOnClick(3)}
                      controlId="assignedTo" className="d-flex"> */}
                    <Col
                      sm={"6"}
                      className={`pr-0 pl-2 assigned-to-dropdown`}
                    >
                      <Form.Label>Assign To</Form.Label>
                      
                      
                      {assignToList.length > 0 &&  <DropDownList
                          placeHolder={"Select a technician"}
                          data={assignToList}
                          defaultValue={assignedTo}
                          isSearchRequired={true}
                          label={"name"}
                          value={"id"}
                          placeHolderItem={{ id: -1, name: "Select a technician" }}
                          // autoComplete="off"
                          onDropDownChange={(item) => {
                            if (!item) {
                              return;
                            }
                            this.onFieldChange(
                              Utils.BuildCustomEventObject("assignedTo", item.id),
                              3
                            );
                          }}
                          selectedItem={[
                            ...[{ id: -1, name: "Select a technician" }],
                            ...assignToList,
                          ].find((r: any) => r?.id === assignedTo)}
                        />}
                         {/* {this.getFieldError("assignedTo", 3)} */}
                        </Col>
                      </Form.Group>

                      <Form.Group 
                      onBlur={() => this.validationOnClick(4)}
                      controlId="title" className="d-flex">
                    <Col
                      sm={"12"}
                      className={`mb-0 ${
                        errors?.title && inputPosition >= 4
                          ? "validation-error"
                          : ""
                      }`}
                    >
                      <Form.Label>Title <b>*</b></Form.Label>
                      <OverlayTrigger
                        placement="bottom"
                        defaultShow={false}
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                            <Tooltip id={"title"} className={(title.length <= 47) ? "tootltip-hide": ""}>
                                {title}
                            </Tooltip> 
                        }
                        >
                      <Form.Control
                        type="text"
                        className="form-control"
                        maxLength={50}
                        id="txtassetName"
                        name="title"
                        autoComplete="title"
                        value={title}
                        placeholder="Describe the problem in a few words"
                        onChange={(e) => this.onFieldChange(e, 4)}
                        onBlur={()=>{this.setState({FormData:{...this.state.FormData,title:title.trimRight()}})}}
                      />
                        </OverlayTrigger>
                      {/* {this.getFieldError("title", 4)} */}
                    </Col>
                  </Form.Group>

              
                  <Form.Group  onBlur={() => this.validationOnClick(5)} controlId="description" className="d-flex">
                    <Col
                      sm={"12"}
                      className={`mb-0 ${
                        errors?.description && inputPosition >= 5
                          ? "validation-error"
                          : ""
                      }`}
                      onClick={() => this.validationOnClick(5)}
                    >
                      <Form.Label>Details <b>*</b></Form.Label>
                      <Form.Control as="textarea"
                      
                        className="form-control"
                        id="description"
                        rows={1}
                        name="description"
                        autoComplete="description"
                        value={description}
                        // maxLength={250}
                        placeholder="Give as much detail as possible about the problem, location and anything else relevant"
                        onChange={(e) => this.onFieldChange(e, 5)}
                        onBlur={()=>{this.setState({FormData:{...this.state.FormData,description:description.trimRight()}})}}

                      />
                      {/* {this.getFieldError("description", 5)} */}
                    </Col>
                  </Form.Group>

                  <Form.Group controlId="exampleForm.ControlTextarea1" >
                      <div>
                          <Form.Label className="mr-auto">Files</Form.Label>
                          {this.state.savedfiles.length > 0 && (
                              <div className="upload-link">
                                  {/* <button type="button" className="btn wht-bg link-btn mr-auto" onClick={this.OpenViewer}>
                                      {this.state.isOpenViewer ? 'Close Viewer' : 'Open Viewer'}
                                  </button> */}
                                  <button type="button" className="btn wht-bg link-btn mr-auto">
                                  </button>
                              </div>
                          )}
                      </div>
                      <div>

                          {/* <Dropzone
                              onDrop={this.onDrop}                                              
                          >
                              {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
                                  <>
                                      <section className={isDragActive ? "upload-area default drag-active " : "container upload-area default"}>
                                          {!this.state.bulkSelect && (                                                                     
                                              <div {...getRootProps({
                                                  className: 'dropzone d-flex align-items-center',
                                                  onClick: (this.state.newSelectfiles.length > 0 || this.state.savedfiles.length > 0) ?
                                                      event => event.stopPropagation() : event => event
                                              })}>
                                                  <input {...getInputProps()} />
                                                  {(this.state.newSelectfiles.length < 1 && this.state.savedfiles.length < 1) && (
                                                      <div className="text">Drag and drop invoices or <span>browse files</span> to upload.</div>
                                                  )}
                                                  {(this.state.newSelectfiles.length > 0 || this.state.savedfiles.length > 0) && isDragActive && (
                                                      <div className="text">Drag and drop invoices here to upload.</div>
                                                  )}                                                           
                                              </div>
                                          )}
                                    
                                              <aside className="d-flex flex-column">
                                                  <ul>{files}</ul>
                                                  <ul>{files1}</ul>
                                              </aside>
                                      </section>
                                     
                                  </>
                              )}
                          </Dropzone> */}
                          <Dropzone
                                            // disabled={isBedgeProcessing}
                                            onDrop={this.onDrop}
                                        // accept="image/*,application/pdf,.doc,.docx,.xls,.xlsx,.csv,.txt,.bmp,.tif,.ppt,.pptx,.rtf,.jpg,.png,.msg,.eml"
                                        >
                                            {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
                                                <>
                                                    <section className={isDragActive ? "container py-0 upload-area default drag-active " : "container upload-area default py-0"}>

                                                        {!this.state.bulkSelect && (

                                                            <div {...getRootProps({
                                                                className: 'dropzone d-flex align-items-center',
                                                                onClick: (this.state.newSelectfiles.length > 0 || this.state.savedfiles.length > 0) ?
                                                                    event => event.stopPropagation() : event => event
                                                            })}>
                                                                <input {...getInputProps()} />
                                                                {(this.state.newSelectfiles.length < 1 && this.state.savedfiles.length < 1) && (
                                                                    <p className="text pb-2">Drag and drop files or <span>browse files</span> to upload.</p>
                                                                )}
                                                                {(this.state.newSelectfiles.length > 0 || this.state.savedfiles.length > 0) && isDragActive && (
                                                                    <p className="text pb-2">Drag and drop files here to upload.</p>
                                                                )}
                                                                {/* {!isDragActive && (<p>Drag and drop or <span>browse files</span> to upload</p>)} */}

                                                            </div>

                                                        )}

                                                        {/* {this.state.bulkSelect && ( 
                                                                        <div className="dropzone d-flex align-items-center">
                                                                            <p>Drag and drop or <span>browse files</span> to upload</p>
                                                                        </div>
                                                                   )}   */}
                                                        {(this.state.newSelectfiles.length > 0 || this.state.savedfiles.length > 0) && (
                                                            <aside className="w-100">
                                                                <ul className="p-0 m-0">{files}</ul>
                                                                <ul className="p-0">{files1}</ul>
                                                            </aside>
                                                        )}
                                                    </section>
                                                    {(!this.state.bulkSelect && (this.state.newSelectfiles.length > 0 || this.state.savedfiles.length > 0)) && (
                                                        <div className="upload-link mrgn-top">
                                                            <button type="button" className="btn p-0 wht-bg link-btn mr-auto" >Add Attachment</button>
                                                            <Form.Control id="formControlsFile" type="file" multiple onChange={(event: any) => this.onFileChange(event, "1")}
                                                                accept="image/*,application/pdf,.doc,.docx,.xls,.xlsx,.csv,.txt,.bmp,.tif,.ppt,.pptx,.rtf,.jpg,.png,.msg,.eml" />

                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        </Dropzone>
                      </div>
                  </Form.Group>
                 
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer className="justify-content-between">
              <Form.Check
                type="checkbox"
                checked={this.state.isChecked}
                label="Create another work order"
                
                onChange={(e) => this.handleCheckboxChange(e)}
              /> 
              <Button
                disabled={this.state.isSaving}
                className="btn btn-primary"
                onClick={this.SaveWorkOrder}
              >
              Add Work Order
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </>
    );
  }
}
