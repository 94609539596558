import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import OutsideClickHandler from "react-outside-click-handler";
const defaultItem = { dashboardName: "", description: "" };

const AddRenameModal: React.FC<any> = ({
  show = false,
  item = defaultItem,
  onSave,
  onClose,
  mode,
  isSubmitting,
}) => {
  const [name, setName] = useState(item?.dashboardName || "");
  const [description, setDescription] = useState(item?.description || "");
  const [templateId, setTemplateId] = useState(0);
  const [inputPosition, setInputPosition] = useState(0);
  const [error, setError] = useState("");

  const handleSave = () => {
    onSave({ ...item, dashboardName: name, description, templateId });
  };

  const handleBlur = (inputPos) => {
    if (inputPos == 1) {
      setName((prev) => prev.trim());
      if (name.trim() === "") {
        setError("Please Enter Dashboard Name");
      } else {
        setError("");
      }
    }

    setInputPosition(inputPos);
  };

  const handleTemplate = (id) => {
    setTemplateId(id);
  };

  const handleKeyDown = (e, id) => {
    if (e.key === "Enter" || e.key === " ") {
      handleTemplate(id);
    }
  };
  const content = (
    <div>
      <div className="dropdown-body">
        <Form>
          {mode == "add" && (
            <Form.Group>
              <Form.Label className="mb-3">
                Build your own or choose a template
              </Form.Label>
              <div className="list-buttons d-flex align-items-center">
                <div>
                  <Button
                    type="button"
                    // tabIndex={0}
                    className={`${templateId == 0 ? "active" : ""}`}
                    onClick={() => handleTemplate(0)}
                    onKeyDown={(e) => handleKeyDown(e, 0)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="34"
                      height="35"
                      viewBox="0 0 34 35"
                      fill="none"
                    >
                      <path
                        d="M16.9987 0.770813C7.7987 0.770813 0.332031 8.23748 0.332031 17.4375C0.332031 26.6375 7.7987 34.1041 16.9987 34.1041C26.1987 34.1041 33.6654 26.6375 33.6654 17.4375C33.6654 8.23748 26.1987 0.770813 16.9987 0.770813ZM23.6654 19.1041H18.6654V24.1041C18.6654 25.0208 17.9154 25.7708 16.9987 25.7708C16.082 25.7708 15.332 25.0208 15.332 24.1041V19.1041H10.332C9.41537 19.1041 8.66537 18.3541 8.66537 17.4375C8.66537 16.5208 9.41537 15.7708 10.332 15.7708H15.332V10.7708C15.332 9.85415 16.082 9.10415 16.9987 9.10415C17.9154 9.10415 18.6654 9.85415 18.6654 10.7708V15.7708H23.6654C24.582 15.7708 25.332 16.5208 25.332 17.4375C25.332 18.3541 24.582 19.1041 23.6654 19.1041Z"
                        fill="#065A8C"
                      />
                    </svg>
                  </Button>
                  <h6>Blank</h6>
                </div>
                <div>
                  <Button
                    type="button"
                    // tabIndex={2}
                    className={`${templateId == 1 ? "active" : ""}`}
                    onClick={() => handleTemplate(1)}
                    onKeyDown={(e) => handleKeyDown(e, 1)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="57"
                      height="57"
                      viewBox="0 0 57 57"
                      fill="none"
                    >
                      <rect
                        x="0.421875"
                        y="0.4375"
                        width="56"
                        height="56"
                        rx="28"
                        fill="#E5F5FF"
                      />
                      <path
                        d="M43.5247 22.7291C41.1081 22.7291 39.7581 25.1291 40.3081 26.9125L34.3914 32.8458C33.8914 32.6958 33.1581 32.6958 32.6581 32.8458L28.4081 28.5958C28.9747 26.8125 27.6247 24.3958 25.1914 24.3958C22.7747 24.3958 21.4081 26.7958 21.9747 28.5958L14.3747 36.1791C12.5914 35.6291 10.1914 36.9791 10.1914 39.3958C10.1914 41.2291 11.6914 42.7291 13.5247 42.7291C15.9414 42.7291 17.2914 40.3291 16.7414 38.5458L24.3247 30.9458C24.8247 31.0958 25.5581 31.0958 26.0581 30.9458L30.3081 35.1958C29.7414 36.9791 31.0914 39.3958 33.5247 39.3958C35.9414 39.3958 37.3081 36.9958 36.7414 35.1958L42.6747 29.2791C44.4581 29.8291 46.8581 28.4791 46.8581 26.0625C46.8581 24.2291 45.3581 22.7291 43.5247 22.7291Z"
                        fill="#065A8C"
                      />
                      <path
                        d="M33.5247 24.3958L35.0914 20.9458L38.5247 19.3958L35.0914 17.8458L33.5247 14.3958L31.9914 17.8458L28.5247 19.3958L31.9914 20.9458L33.5247 24.3958Z"
                        fill="#065A8C"
                      />
                      <path
                        d="M14.3581 27.7291L15.1914 24.3958L18.5247 23.5625L15.1914 22.7291L14.3581 19.3958L13.5247 22.7291L10.1914 23.5625L13.5247 24.3958L14.3581 27.7291Z"
                        fill="#065A8C"
                      />
                    </svg>
                  </Button>
                  <h6>Key Metrics</h6>
                </div>
              </div>
            </Form.Group>
          )}
          <Form.Group controlId="dashboardName">
            <Form.Label>
              Dashboard Name <span>*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Dashboard Name"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
                setError("");
              }}
              // tabIndex={3}
              maxLength={50}
              className={error ? "error-invalid" : ""}
              onBlur={() => handleBlur(1)}
            />
            {error && inputPosition >= 1 && (
              <div className="error-message">{error}</div>
            )}
          </Form.Group>
          <Form.Group controlId="dashboardDescription" className="mt-3">
            <Form.Label>Description</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              // tabIndex={4}
              onBlur={() => handleBlur(2)}
              maxLength={100}
            />
          </Form.Group>
        </Form>
      </div>
      <div className="d-flex justify-content-end footer">
        <Button
          variant="outline"
          onClick={onClose}
          // tabIndex={5}
        >
          Cancel
        </Button>
        <Button
          disabled={!name.trim() || isSubmitting}
          onClick={handleSave}
          className="ms-2"
          // tabIndex={6}
        >
          {mode == "add" ? "Create" : "Save"}
        </Button>
      </div>
    </div>
  );

  return mode === "add" ? (
    <Modal
      show={show}
      onHide={onClose}
      centered
      className="create-dashboard-modal"
      autoFocus
      aria-labelledby="contained-modal-title-vcenter"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {mode === "add" ? "Create Dashboard" : "Edit Dashboard"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{content}</Modal.Body>
    </Modal>
  ) : (
    <OutsideClickHandler
      onOutsideClick={(e) => {
        onClose(); // Ensure this triggers the close action
      }}
    >
      <div className="border rounded edit-dashboard-dropdown">
        <div className="d-flex align-items-center dropdown-header">
          <button onClick={onClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="6"
              height="10"
              viewBox="0 0 6 10"
              fill="none"
            >
              <path
                d="M5.49961 0.5875C5.17461 0.2625 4.64961 0.2625 4.32461 0.5875L0.499609 4.4125C0.174609 4.7375 0.174609 5.2625 0.499609 5.5875L4.32461 9.4125C4.64961 9.7375 5.17461 9.7375 5.49961 9.4125C5.82461 9.0875 5.82461 8.5625 5.49961 8.2375L2.26628 4.99583L5.49961 1.7625C5.82461 1.4375 5.81628 0.904167 5.49961 0.5875Z"
                fill="#2CAF92"
              />
            </svg>
          </button>
          <h4>{mode === "add" ? "Create Dashboard" : "Edit Dashboard"}</h4>
        </div>
        {content}
      </div>
    </OutsideClickHandler>
  );
};

export default AddRenameModal;
