import React from "react";
import {
  Container,
} from "react-bootstrap";
import { HotelGroupSelector } from "./Components/HotelGroupSelector";
import _ from "lodash";
import { ILaborSaveAsDefaultDto } from "../../Common/Contracts/ILaborSaveAsDefaultDto";
import { LaborPerformance } from "../../Common/Services/LaborPerfomance";
import { toast, ToastContainer } from "react-toastify";
import "./addRgbColor.scss";
import { CalendarSelector } from "./Components/CalendarSelector";
import AccountingDashboardTable from "./AccountingDashboardTable";
import { Hotel } from "../../Common/Services/Hotel";
import moment from "moment";
import { resolve, reject } from "q";
import { Register as registersevice } from "../../Common/Services/Register";
import { Utils } from "../../Common/Utilis";

const scrollRef: any = React.createRef();




export class AccountingDashboard extends React.Component<any, any> {
  private pageName: string = "AccountingDashboard";
  private hasPageWidth: any;


  


  constructor(props: any) {
    super(props);
    this.hasPageWidth = React.createRef();
    this.state = {
      // showDashboard: true,
      isLoadingDefaultValue: true,
      isLoadingDefaultDate:true,
      moduleName: "Accounting",
      // featureType: 1,
      featureIds: "1",
      hotelTypes: "2",
      columnSelectorData:[],
      //Common Filter
      hotelIds: 0,
      subGroupSelectValue: "Group",
      chartPeriodValue: 1,
      comparisonPeriodValue: 3,
      chartPeriodValueChart: 1,
      comparisonPeriodValueChart: -1,
      hotelList: [],
      currentHotelIndex: 0,
      hotelName: "",
      calendarDate: null,
      pageHeading: "Accounting",
      chartData: {
        labels: [],
        type: "line",
        datasets: [],
      },
      isChartLoading: false,
      parameterId: 1,
      totalDataValue: 0,
      totalVariance: 0,
      showCheckBoxes: false,

      isChartLoadingMain: false,
      isCellClicked:false,

      parameterList: [],
      lastChartData:{
        labels: [],
        type: "line",
        datasets: [],
      },
      accountingSortingData:{},
    };
  }

  componentDidMount(): void {
    // this.GetAdvanceFeatureFlagPermission(-1);
    this.getDefaultView();
    this.setDate();
  }

  setDate = ()=>{
    this.setState({ isLoadingDefaultDate: true })
    Hotel.getGblGetNow().then((res)=> {
      if(res)
      {
        const value=moment(res).subtract(1, 'days').format("MM/DD/YY")  
        this.setState({calendarDate:value,isLoadingDefaultDate:false})
      }
      else{
        this.setState({isLoadingDefaultDate:false})
      }
    }).catch(err =>{
      this.setState({isLoadingDefaultDate:false})
    })
  }
  
  handleCalendarDate = (date) => {
    if(this.state?.calendarDate !==date) {
    this.setState({ calendarDate: date });
    }
  }

  handleMainChartLoading = (value) => {
    this.setState({ isChartLoadingMain: value });
  }

  handleIsCellClicked = (value) => {
    this.setState({ isCellClicked: value });
  }


  handleHotelChange = (
    hotelIds: any,
    subGroupSelectValue: any,
    hotelName: any
  ) => {


    this.setState(
      {
        hotelIds,
        hotelName,
        subGroupSelectValue
      }
    );
  };



  getDefaultView() {
    this.setState({ isLoadingDefaultValue: true })
    LaborPerformance.getDefaultViewProperties(this.pageName + this.state.pageHeading)
      .then(async (response: any | null) => {

        if (response?.length > 0) {
          const result = response;

          const subGroupSelectValue = result.find(
            (item) => item.fieldName === "subGroupSelectValue"
          )?.fieldValue;

          const hotelIds = result.find(
            (item) => item.fieldName === "hotelIds"
          )?.fieldValue || 0;


          const chartPeriodValue = +result.find(
            (item) => item.fieldName === "chartPeriodValue"
          )?.fieldValue || 1;


          const comparisonPeriodValue = +result.find(
            (item) => item.fieldName === "comparisonPeriodValue"
          )?.fieldValue || 1;

          let columnSelectorData = result.find(
            (item) => item.fieldName === "columnSelectorData"
          )?.fieldValue || ""
          if(columnSelectorData) {
            columnSelectorData = JSON.parse(columnSelectorData) || [];
            columnSelectorData= Array.isArray(columnSelectorData) ?columnSelectorData:[];
          }
          else {
            columnSelectorData = [];

          }

          let accountingSortingData = result.find(
            (item) => item.fieldName === "accountingSortingData"
          )?.fieldValue || ""
          if(accountingSortingData) {
            accountingSortingData = JSON.parse(accountingSortingData) || {};
          }
          else {
            accountingSortingData = {};

          }

          // this.setState({ isLoadingDefaultValue: true }, () => {
            this.setState(
              {
                subGroupSelectValue,
                hotelIds,
                isLoadingDefaultValue: false,
                chartPeriodValue,
                comparisonPeriodValue,
                columnSelectorData,
                accountingSortingData
              });

          // }

        } else {
          this.setState({ isLoadingDefaultValue: false });
        }
      })
      .catch((error) => {
        this.setState({ isLoadingDefaultValue: false })

        Utils.toastError(error.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      })

  }



  handleSaveDefaultView = (columnSelectorData =null ,accountingSortingData =null): void => {
    const laborSaveAsDefaultdtos: ILaborSaveAsDefaultDto[] = [];
    const {
      subGroupSelectValue,
      hotelIds,
      hotelName,
      calendarDate,
      chartPeriodValue,
      comparisonPeriodValue
    } = this.state;

    const pageName = this.pageName + this.state.pageHeading;
    laborSaveAsDefaultdtos.push({
      fieldName: "subGroupSelectValue",
      fieldValue: subGroupSelectValue,
      pageName: pageName
    });
    laborSaveAsDefaultdtos.push({
      fieldName: "hotelIds",
      fieldValue: hotelIds,
      pageName: pageName,
    });

    laborSaveAsDefaultdtos.push({
      fieldName: "hotelName",
      fieldValue: hotelName,
      pageName: pageName,
    });

    laborSaveAsDefaultdtos.push({
      fieldName: "calendarDate",
      fieldValue: calendarDate,
      pageName: pageName,
    });

    laborSaveAsDefaultdtos.push({
      fieldName: "chartPeriodValue",
      fieldValue: chartPeriodValue,
      pageName: pageName,
    });

    laborSaveAsDefaultdtos.push({
      fieldName: "comparisonPeriodValue",
      fieldValue: comparisonPeriodValue,
      pageName: pageName,
    });
    if(columnSelectorData && columnSelectorData!=='none'){

      laborSaveAsDefaultdtos.push({
        fieldName: "columnSelectorData",
        fieldValue:  JSON.stringify(columnSelectorData),
        pageName: pageName,
      });
    }

    if(accountingSortingData!=='none') {
      laborSaveAsDefaultdtos.push({
        fieldName: "accountingSortingData",
        fieldValue:  JSON.stringify(accountingSortingData),
        pageName: pageName,
      });
      }
    
    LaborPerformance.saveDefaultViewProperties(laborSaveAsDefaultdtos)
      .then(async (result: any | null) => {
        this.setState({ loaderTrue: false });
        if (result != null) {
          if (result.message === "Success") {
            toast.success(result?.result?.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        }
      })
      .catch((error) => {
        Utils.toastError(error.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };


  onChartPeriodChange = (item) => {
    this.setState({ chartPeriodValue: item.value });
  }

  onComparisonPeriodChange = (item) => {
    this.setState({ comparisonPeriodValue: item.value });
  }

  // GetAdvanceFeatureFlagPermission(hotelValue: any) {
  //   registersevice.GetAdvanceFeatureFlagPermission("Dashboards",hotelValue)
  //       .then(async (result: any | null) => {
  //           if (result !== null) {
  //               this.setState({showDashboard: result.featureFlagPermission === "Yes" ? true : false});
  //           }
  //           else {
  //             this.setState({showDashboard: false});
  //           }
  //           resolve();
  //       })
  //       .catch((error) => {
  //           reject();
  //       });
  // }

  render() {

    const { hotelIds, isLoadingDefaultValue, isLoadingDefaultDate,subGroupSelectValue,isChartLoadingMain,isCellClicked,
      chartPeriodValue, comparisonPeriodValue, moduleName, calendarDate,columnSelectorData, featureIds, hotelTypes
       } = this.state



    return (
      <>
        {/* {!this.state.showDashboard && (
          <div className="labor-dashboard">
            <Container fluid className="body-sec">
              <div className="page-heading underline d-flex">
                <div className="mr-auto d-flex align-items-center">Accounting</div>
              </div>
              <div className="main-Page">
                <div className="bdy-sec advanced-accounting-upgrade-msg">
                  This is an Advanced Accounting Feature. <span>Click here</span> to let our team know you would like to upgrade.
                </div>
              </div>
            </Container>
          </div>
        )} */}

        {/* {this.state.showDashboard && ( */}
          <div className="labor-dashboard userManagementModule ">
            {/* <ToastContainer autoClose={3000} /> */}
            <Container fluid className="body-sec p-0" ref={scrollRef}>
              <ToastContainer
                autoClose={3000}
                containerId={"accountinghBoard"}
                enableMultiContainer
              />
              <div className="header-area">
                <div className="position-relative pt-1">
                {hotelIds != 0 && hotelIds?.split(",").length > 50 ? <span className="performance-notification">Selecting more than 50 hotels will decrease dashboard performance.</span> : <></>}

                  <div className="page-heading page-header">
                    <div>
                      <h5 className="title-badge">Accounting</h5>
                      <div className="mr-auto d-flex align-items-center">
                        Daily Flash
                        <span className="badge color-grey-beta">BETA</span>
                      </div>
                    </div>
                   
                    <div className="navigation-menus">
                    {!isLoadingDefaultDate && <CalendarSelector
                            id={"chartPeriodSelector"}
                            defaultValue={calendarDate}
                            onChange={this.handleCalendarDate}
                          ></CalendarSelector>}
                      </div>
                  </div>

              
                </div>
              </div>

              <div className="widget-area">
                <AccountingDashboardTable
                  hotelIds={hotelIds}
                  subGroupSelectValue={subGroupSelectValue}
                  handleHotelChange={this.handleHotelChange}
                  moduleName={moduleName}
                  // featureType={featureType}
                  hotelTypes={hotelTypes}
                  featureIds={featureIds}              
                  calendarDate={calendarDate}
                  chartPeriodValue={chartPeriodValue}
                  onChartPeriodChange={this.onChartPeriodChange}
                  comparisonPeriodValue={comparisonPeriodValue}
                  onComparisonPeriodChange={this.onComparisonPeriodChange}
                  handleSaveDefaultView={this.handleSaveDefaultView}
                  isChartLoadingMain={isChartLoadingMain}
                  handleMainChartLoading={this.handleMainChartLoading}
                  isCellClicked={isCellClicked}
                  handleIsCellClicked={this.handleIsCellClicked}
                  isDefaultSaveDataLoaded ={!this.state.isLoadingDefaultValue}
                  columnSelectorData = {columnSelectorData}
                  accountingSortingData={this.state.accountingSortingData}
                />

              </div>

            </Container>
          </div>
        {/* )} */}
      </>
    )

  }
}
