import Instense from "./Axios-config";
import {
  IHotelwithmoduleDtoReport,
  IHotelwithmoduleDto,
  IHotelResponseDto,
  IHotelResponseWithDataDto,
  IHotelWithDataModuleDto,
  IHotelRequestByPageDto,
  IGetOTARDataDto,
  IAcceptOTARDto,
  ISplitScheduleShiftDto,
  IEditOTARScheduleDto,
  ICoadto,
  IAccountPerioddto,
} from "../Contracts/IHotel";
import { ITipEntryPermission } from "../Contracts/ILaborForecast";
import { ModuleReports } from "./ModuleReports";

export class Hotel {
  public static getUserHotelAccess = async (
    moduleName: string,
    notIncludeEnterpriseAccounts: boolean = false,
    showOnlyHotelAccounts: boolean = false
  ): Promise<IHotelResponseDto[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let userUniqueID = storage === null ? 0 : (storage.user_UniqueID as any);
    let request = {} as IHotelwithmoduleDto;
    request.userUniqueID = userUniqueID;
    request.tenantID = tenantID;
    request.moduleName = moduleName;
    request.notIncludeEnterpriseAccounts = notIncludeEnterpriseAccounts;
    request.showOnlyHotelAccounts = showOnlyHotelAccounts;
    let url = "";
    if (window.location.pathname.includes("tip-entry")) {
      url = `/Hotel/UserHotelAccessForTips`;
    } else {
      url = `/Hotel/UserHotelAccess`;
    }
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as IHotelResponseDto[];
      return result;
    });
  };

  public static UserHotelAccessWithData = async (
    moduleName: string,
    pageType: string
  ): Promise<IHotelResponseWithDataDto[] | null> => {
    // debugger;
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let userUniqueID = storage === null ? 0 : (storage.user_UniqueID as any);
    let request = {} as IHotelWithDataModuleDto;
    request.userUniqueID = userUniqueID;
    request.tenantID = tenantID;
    request.pageType = pageType;
    request.moduleName = moduleName;
    request.notIncludeEnterpriseAccounts = false;
    request.showOnlyHotelAccounts = false;
    request.hotelID = -1;
    let url = `/Hotel/UserHotelAccessWithData`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as IHotelResponseWithDataDto[];
      return result;
    });
  };

  public static getUserHotelAccessByModuleName = async (
    moduleName: string,
    notIncludeEnterpriseAccounts: boolean = false,
    showOnlyHotelAccounts: boolean = false
  ): Promise<IHotelResponseDto[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let userUniqueID = storage === null ? 0 : (storage.user_UniqueID as any);
    let request = {} as IHotelwithmoduleDto;
    request.userUniqueID = userUniqueID;
    request.tenantID = tenantID;
    let Module_Id = ModuleReports.getModuleId(localStorage.mainMenu as any);
    if (Module_Id == 4) {
      request.moduleName = "Labor Management";
    } else if (Module_Id == 8) {
      request.moduleName = "Accounting";
    }
    request.notIncludeEnterpriseAccounts = notIncludeEnterpriseAccounts;
    request.showOnlyHotelAccounts = showOnlyHotelAccounts;
    let url = "";
    if (window.location.pathname.includes("tip-entry")) {
      url = `/Hotel/UserHotelAccessForTips`;
    } else {
      url = `/Hotel/UserHotelAccess`;
    }
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as IHotelResponseDto[];
      return result;
    });
  };

  public static getUserHotelAccessReport = async (
    moduleName: string,
    notIncludeEnterpriseAccounts: boolean = false,
    showOnlyHotelAccounts: boolean = false,
    reportMasterId: number,
    payrollSubscriptionRequired: boolean
  ): Promise<IHotelResponseDto[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let userUniqueID = storage === null ? 0 : (storage.user_UniqueID as any);
    let request = {} as IHotelwithmoduleDtoReport;
    request.userUniqueID = userUniqueID;
    request.tenantID = tenantID;
    request.moduleName = "Accounting";
    request.notIncludeEnterpriseAccounts = notIncludeEnterpriseAccounts;
    request.showOnlyHotelAccounts = showOnlyHotelAccounts;
    request.userName = storage.userName;
    request.pageType = "Report";
    request.ReportId = reportMasterId;
    request.payrollSubscriptionRequired = payrollSubscriptionRequired;
    let url = "";
    url = `/Hotel/UserHotelAccessReport`;

    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as IHotelResponseDto[];
      return result;
    });
  };

  public static getAllActiveHotelAccess = async (
    moduleName: string,
    notIncludeEnterpriseAccounts: boolean = false
  ): Promise<IHotelResponseDto[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let userUniqueID = storage === null ? 0 : (storage.user_UniqueID as any);
    let request = {} as IHotelwithmoduleDto;
    request.userUniqueID = userUniqueID;
    request.tenantID = tenantID;
    request.moduleName = moduleName;
    request.notIncludeEnterpriseAccounts = notIncludeEnterpriseAccounts;
    //
    const url = `/Hotel/ALLHotelAccess`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as IHotelResponseDto[];
      return result;
    });
  };

  public static getAllActiveHotelAccessNew = async (
    moduleName: string,
    notIncludeEnterpriseAccounts: boolean = false
  ): Promise<IHotelResponseDto[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let userUniqueID = storage === null ? 0 : (storage.user_UniqueID as any);
    let request = {} as IHotelwithmoduleDto;
    request.userUniqueID = userUniqueID;
    request.tenantID = tenantID;
    request.moduleName = moduleName;
    request.notIncludeEnterpriseAccounts = notIncludeEnterpriseAccounts;
    //
    const url = `/Hotel/ALLHotelAccessNew`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as IHotelResponseDto[];
      return result;
    });
  };

  public static getAllActiveInactiveHotelAccessNew = async (
    notIncludeEnterpriseAccounts: boolean = false
  ): Promise<IHotelResponseDto[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let userUniqueID = storage === null ? 0 : (storage.user_UniqueID as any);
    let request = {} as IHotelwithmoduleDto;
    request.userUniqueID = userUniqueID;
    request.tenantID = tenantID;
    request.notIncludeEnterpriseAccounts = notIncludeEnterpriseAccounts;
    //
    const url = `/Hotel/ALLActiveInactiveHotel`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as IHotelResponseDto[];
      return result;
    });
  };

  public static getUserHotelAccessUM = async (
    notIncludeEnterpriseAccounts = false,
    payrollSubscriptionRequired: boolean = false
  ): Promise<IHotelResponseDto[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let userUniqueID = storage === null ? 0 : (storage.user_UniqueID as any);
    let request = {} as IHotelwithmoduleDto;
    request.userUniqueID = userUniqueID;
    request.tenantID = tenantID;
    request.notIncludeEnterpriseAccounts = notIncludeEnterpriseAccounts;
    request.payrollSubscriptionRequired = payrollSubscriptionRequired;
    const url = `/Hotel/UserHotelAccessUM`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = (response?.data?.result as IHotelResponseDto[]) || [];
      return result;
    });
  };

  public static getUserHotelAccessAllByModuleName = async (
    notIncludeEnterpriseAccounts: boolean = false,
    showOnlyHotelAccounts: boolean = false,
    payrollSubscriptionRequired:boolean=false
  ): Promise<IHotelResponseDto[] | null> => {
    // debugger;
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let userUniqueID = storage === null ? 0 : (storage.user_UniqueID as any);
    let request = {} as IHotelwithmoduleDto;
    request.userUniqueID = userUniqueID;
    request.tenantID = tenantID;
   

    let Module_Id = ModuleReports.getModuleId(localStorage.mainMenu as any);
    if (Module_Id == 4) {
      request.moduleName = "Labor Management";
    } else if (Module_Id == 8) {
      request.moduleName = "Accounting";
    }
    request.payrollSubscriptionRequired = payrollSubscriptionRequired;
    request.notIncludeEnterpriseAccounts = notIncludeEnterpriseAccounts;
    request.showOnlyHotelAccounts = showOnlyHotelAccounts;
    const url = `/Hotel/UserHotelAccessAll`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as IHotelResponseDto[];
      return result;
    });
  };

  public static getUserHotelAccessAll = async (
    moduleName: string,
    notIncludeEnterpriseAccounts: boolean = false,
    showOnlyHotelAccounts: boolean = false
  ): Promise<IHotelResponseDto[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let userUniqueID = storage === null ? 0 : (storage.user_UniqueID as any);
    let request = {} as IHotelwithmoduleDto;
    request.userUniqueID = userUniqueID;
    request.tenantID = tenantID;
    request.moduleName = moduleName;
    request.notIncludeEnterpriseAccounts = notIncludeEnterpriseAccounts;
    request.showOnlyHotelAccounts = showOnlyHotelAccounts;
    const url = `/Hotel/UserHotelAccessAll`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as IHotelResponseDto[];
      return result;
    });
  };

  public static getUserHotelAccessbyPage = async (
    request: IHotelRequestByPageDto
  ): Promise<IHotelResponseDto[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let userUniqueID = storage === null ? 0 : (storage.user_UniqueID as any);
    request.userUniqueID = userUniqueID;
    request.tenantID = tenantID;
    const url = `/Hotel/UserHotelAccessByPage`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as IHotelResponseDto[];
      return result;
    });
  };

  public static getGblGetNow = async (): Promise<any | null> => {
    const url = `/Hotel/GblGetNow`;
    return Instense.post(url).then((response) => {
      let result = response.data.result;
      return result;
    });
  };

  public static GetOverTimeRiskData = async (
    request: IGetOTARDataDto
  ): Promise<any | null> => {
    const url = `/LaborPTO/GetOTARData`;
    return Instense.post(url, request).then((response) => {
      let result = response.data as any;
      return result;
    });
  };

  public static AcceptOTARData = async (
    request: IAcceptOTARDto
  ): Promise<any | null> => {
    const url = `/LaborPTO/AcceptOTAR`;
    return Instense.post(url, request).then((response) => {
      let result = response.data as any;
      return result;
    });
  };

  public static SplitScheduleShiftData = async (
    request: ISplitScheduleShiftDto
  ): Promise<any | null> => {
    const url = `/LaborPTO/SplitScheduleShift`;
    return Instense.post(url, request).then((response) => {
      let result = response.data as any;
      return result;
    });
  };

  public static EditOTARScheduleData = async (
    request: IEditOTARScheduleDto
  ): Promise<any | null> => {
    const url = `/LaborPTO/EditOTARSchedule`;
    return Instense.post(url, request).then((response) => {
      let result = response.data as any;
      return result;
    });
  };

  //ScheduleNo
  public static DeleteScheduleShiftRow = async (
    request: any
  ): Promise<any | null> => {
    const url = `/LaborPTO/DeleteScheduleShift`;
    return Instense.get(url + "?ScheduleNo=" + request).then((response) => {
      let result = response.data as any;
      return result;
    });
  };

  public static GetTipEntryPermissionData = async (
    request: ITipEntryPermission
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let rolId = storage === null ? 0 : (storage.rolId as any);
    request.TenantID = tenantID;
    request.RoleID = rolId;

    const url = `/RolePermission/GetPageSubPermissionDetails`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;

      return result;
    });
  };
  public static GetPendingTimeoffCount = async (
    request: any
  ): Promise<any | null> => {
    const url = `/LaborPTO/GetPendingTimeoffCount`;
    return Instense.post(url, request).then((response) => {
      let result = response.data as any;
      return result;
    });
  };

  public static GetPayrollExportData = async (
    request: any
  ): Promise<any | null> => {
    const url = `/LaborPTO/GetPayrollExportData`;

    return Instense.post(url, request).then((response) => {
      let result = response.data as any;
      return result;
    });
  };

  public static UpdateStatusLaborDepartmentMaster = async (
    request: any
  ): Promise<any | null> => {
    const url = `/Department/UpdateStatusLaborDepartmentMaster`;
    return Instense.post(url, request).then((response) => {
      let result = response.data as any;
      return result;
    });
  };

  public static UpdateStatusEmploymentTitle = async (
    request: any
  ): Promise<any | null> => {
    const url = `/Department/UpdateStatusEmploymentTitle`;
    return Instense.post(url, request).then((response) => {
      let result = response.data as any;
      return result;
    });
  };

  public static getAllEntities = async (): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let userUniqueID = storage === null ? 0 : (storage.user_UniqueID as any);
    let request = {} as any;
    request.userUniqueID = userUniqueID;
    request.tenantID = tenantID;
    const url = `/Hotel/GetHotelList`;
    return Instense.get(url, { params: request }).then((response) => {
      // debugger
      let result = response.data as any[];
      return result;
    });
  };

  public static HotelListForTransfer = async (
    moduleName: string,
    notIncludeEnterpriseAccounts: boolean = false
  ): Promise<IHotelResponseDto[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let userUniqueID = storage === null ? 0 : (storage.user_UniqueID as any);
    let request = {} as IHotelwithmoduleDto;
    request.userUniqueID = userUniqueID;
    request.tenantID = tenantID;
    request.moduleName = moduleName;
    request.notIncludeEnterpriseAccounts = notIncludeEnterpriseAccounts;
    let url = `/Hotel/HotelListForTransfer`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as IHotelResponseDto[];
      return result;
    });
  };

  public static getallCoa = async (
    moduleName: string,
    notIncludeEnterpriseAccounts: boolean = false
  ): Promise<ICoadto[] | null> => {
    // debugger;
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let userName = storage === null ? 0 : (storage.userName as any);
    let hotelID = storage === null ? 0 : (storage.hotelID as any);
    let request = {} as ICoadto;
    request.hotelID = hotelID;
    request.loginUserName = userName;
    request.tenantID = tenantID;
    const url = `/Reports/GetChartOfAccount`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as ICoadto[];
      // debugger;
      return result;
    });
  };

  public static GetEnableDigitalACHPaymentsHotel = async (): Promise<
    any[] | null
  > => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let userUniqueID = storage === null ? 0 : (storage.user_UniqueID as any);
    let request = {} as any;
    request.userUniqueID = userUniqueID;
    request.tenantID = tenantID;
    const url = `/Hotel/GetEnableDigitalACHPayments`;
    return Instense.get(url, { params: request }).then((response) => {
      // debugger
      let result = response.data as any[];
      return result;
    });
  };

  public static CheckDigitalACHPayments = async (): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let userUniqueID = storage === null ? 0 : (storage.user_UniqueID as any);
    let request = {} as any;
    request.tenantID = tenantID;
    request.UserUniqueno = userUniqueID;
    request.Type = "enablebutton";

    const url = `/Hotel/CheckDigitalACHPayments`;
    return Instense.get(url, { params: request }).then((response) => {
      // debugger
      let result = response.data as any;
      return result;
    });
  };

  public static EnableDigitalACHPayments = async (): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let userUniqueID = storage === null ? 0 : (storage.user_UniqueID as any);
    // debugger;
    let request = {} as any;
    request.TenantId = tenantID;
    request.useruniqueno = userUniqueID;
    request.Type = "checkSubscription";
    const url = `/Hotel/EnableDigitalACHPayments`;
    return Instense.post(url, request).then((response) => {
      let result = response.data as any;
      return result;
    });
  };
  public static EnableDACHPaymentForHID = async (
    request
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let userUniqueID = storage === null ? 0 : (storage.user_UniqueID as any);
    request.useruniqueno = userUniqueID;
    request.TenantId = tenantID;
    const url = `/Hotel/SaveDigitalACHPaymentsStatus`;
    return Instense.post(url, request).then((response) => {
      let result = response.data as any;
      return result;
    });
  };

  public static getUserHotelAccessDashboard = async (
    moduleName: string,
    notIncludeEnterpriseAccounts: boolean = false,
    showOnlyHotelAccounts: boolean = false,
    featureType: number = 0  // passs  1 for Interactive Reports and pass 2 for Dashboards
  ): Promise<IHotelResponseDto[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let userUniqueID = storage === null ? 0 : (storage.user_UniqueID as any);
    let request = {} as IHotelwithmoduleDto;
    request.userUniqueID = userUniqueID;
    request.tenantID = tenantID;
    request.moduleName = moduleName;
    request.notIncludeEnterpriseAccounts = notIncludeEnterpriseAccounts;
    request.showOnlyHotelAccounts = showOnlyHotelAccounts;
    request.featureType = featureType;

    let url = `/Hotel/V2_UserHotelAccessDashboard`;

    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as IHotelResponseDto[];
      return result;
    });
  };

  public static getUserHotelAccessBIDashboard = async (
    hotelTypes: string,
    featureIds: string
  ): Promise<IHotelResponseDto[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let userUniqueID = storage === null ? 0 : (storage.user_UniqueID as any);
    let request = {} as IHotelwithmoduleDto;
    request.userUniqueID = userUniqueID;
    request.tenantID = tenantID;
    request.hotelTypes = hotelTypes;
    request.featureIds = featureIds;

    let url = `/Hotel/V2_UserHotelAccessBIDashboard`;

    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as IHotelResponseDto[];
      return result;
    });
  };

  public static getAccotingPeriodForStartandEnd = async (
    moduleName: string,
    notIncludeEnterpriseAccounts: boolean = false,
    reportid: string,
    startValue: string
  ): Promise<IAccountPerioddto[] | null> => {
    //debugger;
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let typ;
    let dateType;
    if (reportid == "2164" || reportid == "2166" || reportid == "2167") {
      if (startValue === "Start") {
        typ = "All";
        dateType = "Start";
      } else if (startValue === "End") {
        typ = "All";
        dateType = "End";
      }
    } else {
      if (startValue === "Start") {
        typ = "OpenEnd"; //storage === null ? 0 : (storage.userName as any);
        dateType = "Start"; // storage === null ? 0 : (storage.hotelID as any);
      } else if (startValue === "End") {
        typ = "OpenEnd";
        dateType = "End";
      } else if (startValue === "PriorYear") {
        typ = "PriorYear";
        dateType = "Start";
      }
    }
    let request = {} as IAccountPerioddto;
    request.Typ = typ;
    request.dateType = dateType;
    request.tenantID = tenantID;
    const url = `/Reports/GetAccountingPeriod`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as IAccountPerioddto[];
      //  debugger;
      return result;
    });
  };

  public static getUserHotelByPositivePay = async (
    PayFileFormatId: number
  ): Promise<IHotelResponseDto[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let userName = storage === null ? 0 : (storage.userName as any);
    let request = {} as any;
    request.UserName = userName;
    request.TenantID = tenantID;
    request.FileFormat = PayFileFormatId;
    const url = `/Reports/GetPositivePayHIDList`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as IHotelResponseDto[];
      return result;
    });
  };

  public static ValidateSingleOrMultipleCCodeForCity = async (
    Hid: string,
    PayFileFormatId: number
  ): Promise<IHotelResponseDto[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let request = {} as any;
    request.TenantID = tenantID;
    request.FileFormat = PayFileFormatId;
    request.Hid = Hid;
    const url = `/Reports/ValidateSingleOrMultipleCCodeForCity`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as IHotelResponseDto[];
      return result;
    });
  };
  public static UserHotelAccessAccounts = async (
    oprID: Number
  ): Promise<IHotelResponseDto[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let request = {} as any;
    request.TenantID = tenantID;
    request.OprID = oprID;
    let url = `/Hotel/UserHotelAccessAccounts`;
    return Instense.post(url, request).then((response) => {
      let result = response.data.result as any[];
      return result;
    });
  };
  public static DisableEpay = async (
    ActionType: string
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let request = {} as any;
    request.TenantID = tenantID;
    request.ActionType = ActionType;
    const url = `/Vendor/DisableEpay`;
    return Instense.post(url, request).then((response) => {
      let result = response.data as any;
      return result;
    });
  };

  public static AddePayEntityManagementEmail = async (): Promise<
    any[] | null
  > => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let request = {} as any;
    request.TenantID = tenantID;
    const url = `/Hotel/AddePayEntityManagementEmail`;
    return Instense.post(url, null, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static CheckAddePayEntityManagementEmail = async (): Promise<
    any[] | null
  > => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    const url = `/Hotel/CheckAddePayEntityManagementEmail`;
    return Instense.get(url + "?TenantId=" + tenantID).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static UserHotelAccessForCommInbx = async (
    transType
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = storage === null ? 0 : storage.tenantID;
    const url = `/Hotel/UserHotelAccessForCommInbx`;
    return Instense.get(
      url + `?TenantId=${tenantID}&TransType=${transType}`
    ).then((response) => {
      let result: any = response.data.result;
      return result;
    });
  };

  public static ALLHotelForSelectedTenant = async (selectedTenant: number
  ): Promise<IHotelResponseDto[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let request: any = {};  
    request.tenantID = tenantID;
    request.notIncludeEnterpriseAccounts = false;
    request.selectedtenantID = selectedTenant;
    const url = `/Hotel/ALLHotelForSelectedTenant`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as IHotelResponseDto[];
      return result;
    });
  };

  public static DownloadInvoiceFileAttachments = async (request: any
    ): Promise<IHotelResponseDto[] | null> => {
      let storage = JSON.parse(localStorage.getItem("storage")!);
      let tenantID = storage === null ? 0 : (storage.tenantID as any);
      request.tenantID = tenantID;
      const url = `/Register/DownloadInvoiceFileAttachments`;
      return Instense.get(url, { params: request }).then((response) => {
        let result = response.data.result as IHotelResponseDto[];
        return result;
      });
    };

  public static GetAllTenant = async (): Promise<any[] | null> => {    
        const url = `/Hotel/GetAllTenant`;
    return Instense.get(url).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static getDownloadInvoiceType = async (): Promise<any[] | null> => {
    const url = `Hotel/DownloadInvoiceType`;
    return Instense.get(url).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };
}
