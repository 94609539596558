import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import $ from "jquery";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import filterFactory from "react-bootstrap-table2-filter";
import TableSearch from "../../../Common/Components/TableSearch";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import {
  Button,
  Dropdown,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { FiChevronDown } from "react-icons/fi";
import { resolve, reject } from "q";
import { Utils } from "../../../Common/Utilis";
import { InvoiceEntrySlideout } from "../../Register/InvoiceEntrySlideout";
import { LaborPerformance as laborPerformance } from "../../../Common/Services/LaborPerfomance";
import _ from "lodash";
import { confirmAlert } from "react-confirm-alert";
import Spinner from "react-bootstrap/Spinner";
import { InvoiceApprovalS } from "../../../Common/Services/InvoiceApprovals";
import chevronRight from "../../../Common/Assets/Images/icons/bx-chevron-right.svg";
import { TransactionSlideout } from "../../Register/TransactionSlideout";
import { bankSyncServices } from "../../../Common/Services/bankSyncServices";
import { Register as registersevice } from "../../../Common/Services/Register";
import { ColumnKeyCreator } from "ag-grid-community";
export class ProcessingTable extends React.Component<any, any> {
  private child: any;
  private wom_vendorWidth: any;
  private scrollArea: any;

  iconFormatter = (column, colIndex) => {
    return (
      <div className="wom_hasGearIcon">
        <Dropdown
          className="more-action bg-blue rolePermissiondropdown dropdown wom_dropdown"
          onToggle={this.handleToggle}
        >
          <Dropdown.Toggle
            id="dropdown-ptoType"
            className="pl-1 pr-1"
            disabled={this.state.tableData.length === 0}
          >
            <div className="d-flex align-items-center wom_svg">
              <div className="d-flex align-items-center wom_svg">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M16.3914 2H3.60861C2.72146 2 2 2.79733 2 3.77778V16.2222C2 17.2027 2.72146 18 3.60861 18H16.3914C17.2785 18 18 17.2027 18 16.2222V6.44356V5.55467V4.66578V3.77778V3.77689C17.9992 2.79644 17.2769 2 16.3914 2ZM6.82582 16.2222H3.60861V6.44356H6.82582V16.2222ZM11.6516 16.2222H8.43442V6.44356H11.6516V16.2222ZM13.2602 16.2222V6.44356H16.3914L16.3922 16.2222H13.2602Z" />
                </svg>
                <div className="drop-arrow d-flex">
                  <FiChevronDown />
                </div>
              </div>
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu className="wom_tableColumnListSelector" id="colList3">
            <div className="header">Columns</div>
            <div
              className={`${
                this.state.bulkSelect
                  ? "dropdownItems paymentColumSelFoot"
                  : "dropdownItems paymentColumSel"
              }`}
            >
              {this.state.tableColumnList.map((items, index) => (
                <>
                  {!items.isHide && (
                    <div className="dropdown-item" key={items.id}>
                      <EllipsisWithTooltip placement="bottom">
                        <div className="wom_formCheck wom_formCheckHasPadd">
                          <Form.Check
                            type="checkbox"
                            key={`list_${items.id}`}
                            id={`list_${items.id}`}
                            name={items.name.toLowerCase()}
                            label={
                              items.name === "AP"
                                ? "Accounting Period"
                                : items.name
                            }
                            value={items.id}
                            onChange={(event) =>
                              this.onColumnChange(event, items, index)
                            }
                            checked={items.isChecked}
                            disabled={items.disabled}
                          />
                        </div>
                      </EllipsisWithTooltip>
                    </div>
                  )}
                </>
              ))}
            </div>
            <div className="m-0 dropdown-divider"></div>
            <Dropdown.Item
              className="wom_saveDefaultBtn"
              onClick={this.saveDefaultView}
            >
              Save as Default View
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  };

  renderCusConfirmAlert = (onClose: any) => {
    this.setState({ isColumnChange: false });
    return (
      <div className="viom_CustomConfirmAlert">
        <h1 className="viom_alertTitle">Note:</h1>
        <p className="viom_alertMessage">
          Your column selection may cause horizontal scrolling due to the number
          selected. To eliminate scrolling, limit the number of columns you
          select.
        </p>
        <div className="viom_alertButtonGroup">
          <button className="viom_alertButton viom_success" onClick={onClose}>
            Ok
          </button>
        </div>
      </div>
    );
  };

  handleToggle = (event) => {
    if (event === false && this.state.isColumnChange) {
      let defaultWidth = this.scrollArea.current.getBoundingClientRect().width;
      let currentWidth =
        this.scrollArea.current.childNodes[0].childNodes[0].childNodes[0].getBoundingClientRect()
          .width;
      if (defaultWidth < currentWidth) {
        confirmAlert({
          customUI: ({ onClose }) => this.renderCusConfirmAlert(onClose),
        });
      }
    }
  };

  hoverOutEllipsis = () => {
    $(".tooltip").removeClass("show");
  };

  renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {props.statusDate}
    </Tooltip>
  );
  headerFormatter = (column: any) => (
    <div ref={this.wom_vendorWidth}>{column.text}</div>
  );
  randerFormatter = (row: any, colName: any) => (
    <div
      onClick={() => {
        this.rowClickEvents(row, "main");
      }}
    >
      <EllipsisWithTooltip placement="top">{colName}</EllipsisWithTooltip>
    </div>
  );
  randerFormatter2 = (row: any, colName: any) => (
    <div
      onClick={() => {
        this.rowClickEvents(row, "main");
      }}
      onWheel={() => this.hoverOutEllipsis()}
    >
      <EllipsisWithTooltip placement="top">{colName}</EllipsisWithTooltip>
    </div>
  );
  randerFormatterOverlayTrigger = (row: any, colName: any) => (
    <div
      onClick={() => {
        this.rowClickEvents(row, "main");
      }}
      onWheel={() => this.hoverOutEllipsis()}
    >
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={this.renderTooltip(row)}
      >
        <span className="ml-0 badge badgeGray">{colName}</span>
      </OverlayTrigger>
    </div>
  );

  renderRightDropdownTogle = (row) => {
    if (row.cancelShow) {
      return (
        <Dropdown
          id="more-action"
          onToggle={(e) => {
            this.setZindex(e, row);
          }}
          className="more-action"
          alignRight
        >
          <Dropdown.Toggle
            className="btn-outline-primary btn btn-primary btn-ghost more"
            id="dropdown-more"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
              <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
              <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
            </svg>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              eventKey={"Cancel_Payment"}
              onClick={(e: any) => this.cancelEpayInvoicePayment(e, row)}
            >
              Cancel Payment
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      );
    } else {
      return <></>;
    }
  };

  renderCompanyheaderClasses = () => {
    let isCondition =
      this.state.columnsHeader.filter(
        (o) => o.dataField !== "extra" && o.hidden
      ).length > 3
        ? "setDyW3"
        : "";
    return `vendorProcess-vendor ${
      this.state.expList.length > 0 ? "left40" : ""
    } ${
      this.state.columnsHeader.filter(
        (o) => o.dataField !== "extra" && o.hidden
      ).length > 5
        ? "setDyW5"
        : isCondition
    }`;
  };
  renderCompanyClasses = () => {
    let isCondition =
      this.state.columnsHeader.filter(
        (o) => o.dataField !== "extra" && o.hidden
      ).length > 3
        ? "setDyW3"
        : "";
    return `vendorProcess-vendor ${
      this.state.expList.length > 0 ? "left40" : ""
    } ${
      this.state.columnsHeader.filter(
        (o) => o.dataField !== "extra" && o.hidden
      ).length > 5
        ? "setDyW5"
        : isCondition
    }`;
  };

  renderInvoiceNoheaderClasses = () => {
    let isCondition =
      this.state.columnsHeader.filter(
        (o) => o.dataField !== "extra" && o.hidden
      ).length > 3
        ? "setLDyW3"
        : "";
    return `vendorProcess-number ${
      this.state.expList.length > 0 ? "setNumberLeft" : ""
    } ${this.state.expList.length > 0 ? "left40" : ""} ${
      this.state.columnsHeader.filter(
        (o) => o.dataField !== "extra" && o.hidden
      ).length > 5
        ? "setLDyW5"
        : isCondition
    }`;
  };
  renderInvoiceNoClasses = (row: any) => {
    let isCondition =
      this.state.columnsHeader.filter(
        (o) => o.dataField !== "extra" && o.hidden
      ).length > 3
        ? "setLDyW3"
        : "";
    return `vendorProcess-number ${row.hasArrow ? "whiteRow" : ""} ${
      this.state.expList.length > 0 ? "setNumberLeft" : ""
    } ${this.state.expList.length > 0 ? "left40" : ""} ${
      this.state.columnsHeader.filter(
        (o) => o.dataField !== "extra" && o.hidden
      ).length > 5
        ? "setLDyW5"
        : isCondition
    }`;
  };

  columnsHeader = [
    {
      dataField: "company",
      text: "Vendor",

      headerFormatter: (column, colIndex) =>
        this.renderColumnsHeaderFormatter(column),
      //   headerFormatter: (column, colIndex) => {
      //     return <div ref={this.wom_vendorWidth}>{column.text}</div>;
      //   },
      formatter: (cell: any, row: any, rowIndex: any) => {
        return (
          <div
            onClick={() => {
              this.rowClickEvents(row, "main");
            }}
          >
            <EllipsisWithTooltip placement="top">
              {row.company}
            </EllipsisWithTooltip>
          </div>
        );
      },
      headerClasses: (cell: any, row: any, rowIndex: any) =>
        `sortable vendorProcess-vendor ${
          this.state.expList.length > 0 ? "left40" : ""
        } ${
          this.state.columnsHeader.filter(
            (o) => o.dataField !== "extra" && o.hidden
          ).length > 5
            ? "setDyW5"
            : this.state.columnsHeader.filter(
                (o) => o.dataField !== "extra" && o.hidden
              ).length > 3
            ? "setDyW3"
            : ""
        }`,
      classes: (cell: any, row: any, rowIndex: any) =>
        `vendorProcess-vendor ${
          this.state.expList.length > 0 ? "left40" : ""
        } ${
          this.state.columnsHeader.filter(
            (o) => o.dataField !== "extra" && o.hidden
          ).length > 5
            ? "setDyW5"
            : this.state.columnsHeader.filter(
                (o) => o.dataField !== "extra" && o.hidden
              ).length > 3
            ? "setDyW3"
            : ""
        }`,
    },
    {
      dataField: "invoiceNo",
      text: "Number",

      headerFormatter: (column, colIndex) =>
        this.renderColumnsHeaderFormatter(column),
      formatter: (cell: any, row: any, rowIndex: any) => {
        return (
          <div
            onClick={() => {
              this.rowClickEvents(row, "main");
            }}
          >
            <EllipsisWithTooltip placement="top">
              {row.invoiceNo}
            </EllipsisWithTooltip>
          </div>
        );
      },
      headerClasses: (cell: any, row: any, rowIndex: any) =>
        `sortable vendorProcess-number ${
          this.state.expList.length > 0 ? "setNumberLeft" : ""
        } ${this.state.expList.length > 0 ? "left40" : ""} ${
          this.state.columnsHeader.filter(
            (o) => o.dataField !== "extra" && o.hidden
          ).length > 5
            ? "setLDyW5"
            : this.state.columnsHeader.filter(
                (o) => o.dataField !== "extra" && o.hidden
              ).length > 3
            ? "setLDyW3"
            : ""
        }`,
      classes: (cell: any, row: any, rowIndex: any) =>
        `vendorProcess-number ${row.hasArrow ? "whiteRow" : ""} ${
          this.state.expList.length > 0 ? "setNumberLeft" : ""
        } ${this.state.expList.length > 0 ? "left40" : ""} ${
          this.state.columnsHeader.filter(
            (o) => o.dataField !== "extra" && o.hidden
          ).length > 5
            ? "setLDyW5"
            : this.state.columnsHeader.filter(
                (o) => o.dataField !== "extra" && o.hidden
              ).length > 3
            ? "setLDyW3"
            : ""
        }`,
    },
    {
      dataField: "accper",
      text: "AP",

      headerFormatter: (column, colIndex) =>
        this.renderColumnsHeaderFormatter(column),
      headerClasses: "sortable vendorProcess-ap",
      formatter: (cell: any, row: any, rowIndex: any) =>
        this.randerFormatter(row, row.accper),
      classes: (cell: any, row: any, rowIndex: any) =>
        row.hasArrow ? "vendorProcess-ap whiteRow" : "vendorProcess-ap",
    },
    {
      dataField: "billDate",
      text: "Invoice Date",

      headerFormatter: (column, colIndex) =>
        this.renderColumnsHeaderFormatter(column),
      formatter: (cell: any, row: any, rowIndex: any) =>
        this.randerFormatter(row, row.billDate),
      headerClasses: "sortable vendorProcess-invoiceDate",
      classes: (cell: any, row: any, rowIndex: any) =>
        row.hasArrow
          ? "vendorProcess-invoiceDate whiteRow"
          : "vendorProcess-invoiceDate",
    },
    {
      dataField: "dueDate",
      text: "Due Date",

      headerFormatter: (column, colIndex) =>
        this.renderColumnsHeaderFormatter(column),
      formatter: (cell: any, row: any, rowIndex: any) =>
        this.randerFormatter(row, row.dueDate),
      hidden: true,
      headerClasses: "sortable vendorProcess-dueDate",
      classes: (cell: any, row: any, rowIndex: any) =>
        row.hasArrow
          ? "vendorProcess-dueDate whiteRow"
          : "vendorProcess-dueDate",
    },
    {
      dataField: "transTotal",
      text: "Amount",

      headerFormatter: (column, colIndex) =>
        this.renderColumnsHeaderFormatter(column),
      formatter: (cell: any, row: any, rowIndex: any) =>
        this.randerFormatter(
          row,
          Utils.currencyFormatWithOutdoller(row.transTotal)
        ),
      headerClasses: "sortable vendorProcess-amount",
      classes: "vendorProcess-amount",
    },
    {
      dataField: "etype",
      text: "Method",

      headerFormatter: (column, colIndex) =>
        this.renderColumnsHeaderFormatter(column),
      formatter: (cell: any, row: any, rowIndex: any) => {
        return this.randerFormatter(row, row?.actualEType);
      },
      headerClasses: "sortable vendorProcess-method",
      classes: "vendorProcess-method",
    },
    {
      dataField: "submittedOn",
      text: "Submitted On",

      headerFormatter: (column, colIndex) =>
        this.renderColumnsHeaderFormatter(column),
      formatter: (cell: any, row: any, rowIndex: any) => {
        return (
          <div
            onClick={() => {
              this.rowClickEvents(row, "main");
            }}
          >
            <EllipsisWithTooltip placement="top">
              {row.submittedOn}
            </EllipsisWithTooltip>
          </div>
        );
      },

      headerClasses: "sortable vendorProcess-submittedOn",
      classes: "vendorProcess-submittedOn",
    },

    {
      dataField: "addContract",
      text: "Account No.",
      headerFormatter: (column, colIndex) =>
        this.renderColumnsHeaderFormatter(column),
      formatter: (cell: any, row: any, rowIndex: any) =>
        this.randerFormatter2(row, row.addContract),
      headerClasses: "sortable vendorProcess-customer",
      classes: "vendorProcess-customer",
    },
    {
      dataField: "internalNotes",
      text: "Notes",
      headerFormatter: (column, colIndex) =>
        this.renderColumnsHeaderFormatter(column),

      formatter: (cell: any, row: any, rowIndex: any) =>
        this.randerFormatter2(row, row.internalNotes),
      headerClasses: "sortable vendorProcess-notes",
      classes: (cell: any, row: any, rowIndex: any) =>
        row.hasArrow ? "vendorProcess-notes whiteRow" : "vendorProcess-notes",
    },
    {
      dataField: "tabStatus",
      text: "Status",

      headerFormatter: (column, colIndex) =>
        this.renderColumnsHeaderFormatter(column),
      headerClasses: "sortable vendorProcess-status",
      classes: "vendorProcess-status",
      // formatter: (cell: any, row: any, rowIndex: any) =>
      //   this.randerFormatter(row, row.tabStatus),
      formatter: (cell: any, row: any, rowIndex: any) => (
        <div
          onClick={() => {
            this.rowClickEvents(row, "main");
          }}
          onWheel={() => this.hoverOutEllipsis()}
        >
          <span className="ml-0 badge badgeGray">{row.tabStatus}</span>
        </div>
      ),
    },
    {
      dataField: "statusDate",
      text: "Status Date",
      headerFormatter: (column, colIndex) =>
        this.renderColumnsHeaderFormatter(column),
      headerClasses: "sortable vendorProcess-statusDate",
      classes: "vendorProcess-statusDate",
      // formatter: (cell: any, row: any, rowIndex: any) =>
      //   this.randerFormatter(row, row.statusDate),
      formatter: (cell: any, row: any, rowIndex: any) => (
        <div
          onClick={() => {
            this.rowClickEvents(row, "main");
          }}
        >
          <EllipsisWithTooltip placement="bottom">
            {row.statusDate}
          </EllipsisWithTooltip>
        </div>
      ),
    },
    {
      dataField: "",
      text: "",

      headerClasses: "vendorProcess-icon text-right",
      classes: (cell: any, row: any, rowIndex: any) =>
        `vendorProcess-icon text-right ${row.isDropShow ? "setZindex" : ""}`,
      headerFormatter: this.iconFormatter,
      formatter: (cell: any, row: any, rowIndex: any) =>
        this.renderRightDropdownTogle(row),
    },
    {
      dataField: "transTotal1",
      text: "",
      headerClasses: "d-none",
      classes: "d-none",
      formatter: (cell: any, row: any, rowIndex: any) =>
        this.randerFormatter(row, row.transTotal1),
    },
    {
      dataField: "extra",
      text: "",
      headerClasses: "d-none",
      classes: "d-none",
      hidden: true,
    },
  ];

  constructor(props: any) {
    super(props);
    this.child = React.createRef();
    this.wom_vendorWidth = React.createRef();
    this.scrollArea = React.createRef();
    this.state = {
      wom_vendorWidthCol: 0,
      hidValue: this.props.hidValue,
      hotelName: "",
      bulkSelect: false,
      bulkSelectRow: false,
      tableData: [],
      searchResult: [],
      resetTableData: [],
      isSpinner: false,
      isBulkSelected: false,
      isSlideOut: false,
      uniqueNo: 0,
      sildeoutCode: "",
      sildeoutValue: "",
      selectedRows: [],
      densityView: [
        { id: 1, densityType: "densityView_Wide", name: "WIDE" },
        { id: 2, densityType: "densityView_Narrow", name: "NARROW" },
        { id: 3, densityType: "groupUnpaid", name: "Group Unpaid" },
      ],
      defaultDensityView: this.props.parentState.defaultDensityView,
      tableColumnList: [
        {
          id: "company",
          name: "Vendor",
          isChecked: true,
          disabled: true,
          isHide: false,
        },
        {
          id: "invoiceNo",
          name: "Number",
          isChecked: true,
          disabled: true,
          isHide: false,
        },
        {
          id: "accper",
          name: "AP",
          isChecked: true,
          disabled: false,
          isHide: false,
        },
        {
          id: "billDate",
          name: "Invoice Date",
          isChecked: true,
          disabled: false,
          isHide: false,
        },
        {
          id: "dueDate",
          name: "Due Date",
          isChecked: false,
          disabled: false,
          isHide: false,
        },
        {
          id: "transTotal",
          name: "Amount",
          isChecked: true,
          disabled: false,
          isHide: false,
        },
        {
          id: "etype",
          name: "Method",
          isChecked: true,
          disabled: false,
          isHide: false,
        },
        {
          id: "submittedOn",
          name: "Submitted On",
          isChecked: true,
          disabled: false,
          isHide: false,
        },
        {
          id: "addContract",
          name: "Account Number",
          isChecked: false,
          disabled: false,
          isHide: false,
        },
        {
          id: "internalNotes",
          name: "Notes",
          isChecked: false,
          disabled: false,
          isHide: false,
        },
        {
          id: "tabStatus",
          name: "Status",
          isChecked: true,
          disabled: false,
          isHide: false,
        },
        {
          id: "statusDate",
          name: "Status Date",
          isChecked: true,
          disabled: false,
          isHide: false,
        },
        {
          id: "extra",
          name: "extra",
          isChecked: false,
          disabled: false,
          isHide: true,
        },
      ],
      columnsHeader: this.columnsHeader,
      columnSortingList: [
        { id: "company", name: "Vendor", isActive: true, isHidden: false },
        { id: "invoiceNo", name: "Number", isActive: false, isHidden: false },
        {
          id: "accper",
          name: "Accounting Period",
          isActive: false,
          isHidden: false,
        },
        {
          id: "billDate",
          name: "Invoice Date",
          isActive: false,
          isHidden: false,
        },
        { id: "dueDate", name: "Due Date", isActive: false, isHidden: true },
        { id: "transTotal", name: "Amount", isActive: false, isHidden: false },
        { id: "etype", name: "Method", isActive: false, isHidden: false },
        {
          id: "addContract",
          name: "Account Number",
          isActive: false,
          isHidden: false,
        },
        {
          id: "internalNotes",
          name: "Notes",
          isActive: false,
          isHidden: false,
        },
        { id: "tabStatus", name: "Status", isActive: false, isHidden: false },
        {
          id: "statusDate",
          name: "Status Date",
          isActive: false,
          isHidden: false,
        },
        { id: "extra", name: "extra", isActive: false, isHidden: true },
      ],
      SortBy: { id: "company", name: "Vendor" },
      defSortBy: { id: "company", name: "Vendor" },
      parentType:
        this.props.parentState?.currentSelectedType[0]?.type?.toLowerCase(),
      loaderTrue: false,
      nonSelectedRows: [],
      expList: [],
      expandedList: [],
      isTransationSlideOut: false,
      slideoutData: {},
      pageType: "",
      sortingField: "company",
      sortingOrder: "asc",
    };
  }

  componentDidMount() {
    this.setState({ isSpinner: true });
    this.paymentDetails(this.props.parentState.filteredData);
  }

  sortImage = (order) => {
    if (!order || order === "asc") {
      return (
        <span className="ascending">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.4712 8.47133L11.5286 7.52867L8.66656 10.3907V4H7.33323V10.3907L4.47123 7.52867L3.52856 8.47133L7.9999 12.9427L12.4712 8.47133Z"
              fill="#1D1E20"
            />
          </svg>
        </span>
      );
    } else if (order === "desc") {
      return (
        <span className="descending">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.4712 8.47133L11.5286 7.52867L8.66656 10.3907V4H7.33323V10.3907L4.47123 7.52867L3.52856 8.47133L7.9999 12.9427L12.4712 8.47133Z"
              fill="#1D1E20"
            />
          </svg>
        </span>
      );
    }
    return null;
  };

  renderColumnsHeaderFormatter = (column: any) => (
    <div
      className={`d-inline-block  ${
        this.state.sortingField === column?.dataField ? "active" : ""
      }`}
      onClick={() => this.handleSortByColumn(column?.dataField)}
    >
      {column.text}
      {this.state.tableData.length > 0 &&
        this.sortImage(this.state.sortingOrder)}
    </div>
  );

  handleSortByColumn = (field, isDefaultSort = false) => {
    this.setState(
      {
        sortingField: field,
        sortingOrder: isDefaultSort
          ? this.state.sortingOrder
          : this.state.sortingOrder === "asc"
          ? "desc"
          : "asc",
      },
      () => {
        this.sortTableData(this.state.tableData, this.state.sortingField);
      }
    );
  };

  sortTableData = (data: any, fieldName) => {
    if (fieldName === "etype") {
      fieldName = "actualEType";
    }

    var dateFields = ["billDate", "submittedOn", "statusDate", "dueDate"];

    if (this.state.sortingOrder === "asc") {
      data = _.orderBy(
        data,
        [
          (obj) =>
            dateFields.includes(fieldName)
              ? new Date(obj[fieldName])
              : obj[fieldName],
        ],
        ["asc", "asc"]
      );
    } else if (this.state.sortingOrder === "desc") {
      data = _.orderBy(
        data,
        [
          (obj) =>
            dateFields.includes(fieldName)
              ? new Date(obj[fieldName])
              : obj[fieldName],
        ],
        ["desc", "asc"]
      );
    }
    this.setState({ isSpinner: false, tableData: _.cloneDeep(data) });
  };

  cancelEpayInvoicePayment = (event: any, row: any) => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = storage === null ? 0 : storage.tenantID;
    let request: any = {};
    let reqArray: any[] = [];
    request.TenantID = tenantID;
    request.HID = row?.backHID;
    request.uniqueno = row?.uniqueno;
    reqArray.push(request);
    confirmAlert({
      title: "Alert",
      message: "Are you sure you want to cancel this payment?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            this.cancelPaymentCall(reqArray);
          },
        },
        { label: "No", onClick: () => reject() },
      ],
    });
  };

  cancelPaymentCall = (reqArray: any) => {
    this.setState({ loaderTrue: true });
    InvoiceApprovalS.CancelEpayInvoicePayment(reqArray)
      .then(async (result: any) => {
        if (result?.success) {
          if (result?.result?.toString().toLocaleLowerCase() === "success") {
            toast.success("Payment canceled.", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            this.loadBackData();
          } else if (
            result?.result?.toString().toLocaleLowerCase() === "statuspaid"
          ) {
            Utils.toastError(
              "The transaction has already been funded and cannot be canceled. Please refresh the page to see the updated status.",
              { position: toast.POSITION.BOTTOM_RIGHT }
            );
          } else {
            Utils.toastError(result?.result, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        } else {
          Utils.toastError(result?.result, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
        this.setState({ loaderTrue: false });
        resolve();
      })
      .catch((error) => {
        this.setState({ loaderTrue: false });
        Utils.toastError(`Error: ${error?.message}`, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        reject();
      });
  };

  setZindex = (e: any, row: any) => {
    let { tableData } = this.state;
    tableData = tableData.map((items) => {
      if (items.rowNo === row.rowNo) {
        return { ...items, isDropShow: e };
      } else {
        return items;
      }
    });
    this.setState({ tableData, showDropDown: e });
  };

  paymentDetails = (result: any) => {
    let { expList } = this.state;
    this.setState({ isSpinner: true, tableData: [], resetTableData: [] });
    let { nonSelectedRows } = this.state;
    result.map((items) => {
      if (items.digitAchStatus?.toString().toLocaleLowerCase() === "funded") {
        items["tabStatus"] = "Paid-Funded";
      } else if (
        items.digitAchStatus?.toString().toLocaleLowerCase() === "open"
      ) {
        items["tabStatus"] = "Paid-Sent";
      } else {
        items["tabStatus"] = "Processing";
      }
      items["wom_vendorWidthCol"] = "";
      items["isDropShow"] = false;
      items["etype"] =
        items.etype.toLowerCase() === "ach" && items.isDigital === 1
          ? "D-ACH"
          : items.etype;

      if (items?.tabStatus === "Paid-Sent") {
        items.actualEType = items?.actualEType
          ? `ePay - ${items?.actualEType}`
          : "ePay";
      } else {
        items.actualEType = items?.etype;
      }
      return items;
    });
    result.forEach((item) => {
      nonSelectedRows.push(item.rowNo);
      item["hasArrow"] = false;
      result.forEach((item1) => {
        if (Number(item.viewType) === 1) {
          if (item1.groupID === item.groupID && Number(item1.viewType) === 2) {
            expList.push(item.rowNo);
            nonSelectedRows = nonSelectedRows.filter((x) => x !== item.rowNo);
            item.hasArrow = true;
          }
        }
      });
    });

    this.setState(
      {
        tableData: result.slice(0, 1000),
        resetTableData: result,
        nextCount: 1000,
        nonSelectedRows,
        expList,
      },
      () => {
        this.setDefaultColumn(this.props.parentState.defaultColumn);
        this.sortTableData(
          _.cloneDeep(this.state.tableData),
          this.state.sortingField
        );
        if (this.state.tableData.length === 0)
          $("#serachInput").prop("disabled", true);
        else $("#serachInput").prop("disabled", false);
      }
    );
  };

  CleanSearch() {
    this.child.current.CleanSearch();
  }

  rowClickEvents = (row: any, type) => {
    if (type === "child" || !row.hasArrow) {
      this.setState(
        {
          uniqueNo: row.uniqueno,
          sildeoutCode: row.letterCode,
          sildeoutValue: row.hid,
        },
        () => {
          this.setState({ isSlideOut: true });
        }
      );
    }
  };

  hideSlideOut(isSave) {
    if (isSave) {
      this.setState({ isSlideOut: false, isTransationSlideOut: false }, () => {
        this.props?.refreshTable(
          this.state.parentType,
          this.props?.parentState.key
        );
      });
    } else {
      this.setState({ isSlideOut: false, isTransationSlideOut: false });
    }
  }

  getEtype(mtype, rowType) {
    let etype = "";
    etype = registersevice.getEtype(mtype, rowType);
    return etype;
  }

  OpenInvoiceSlideOut = (e, row, rowIndex, rowType) => {
    if (rowType === "main") {
      let slideoutData: any = {};
      let etype = "";
      etype = this.getEtype(row.etype, "main");
      slideoutData.pageType = etype;
      slideoutData.accDetails = {
        acctType: etype,
        oprID: row.oprID,
        accountName: "",
        description: row.description,
        transDate: row.transDate,
        totalAmount: row.amount,
        isNewTransBankSync: "",
      };
      slideoutData.hidValue = this.state.hidValue;
      slideoutData.hotelName = this.state.hotelName;
      slideoutData.uniqueNo = row.uniqueno;
      slideoutData.selectedRow = {
        pageType: etype,
        hid: row.hotelid,
        groupID: row.groupID,
        oprId: row.oprID,
        checkno: row.ckno,
        invPostFix: "",
        series: row.series ? row.series : "",
      };
      this.setState({
        pageType: etype,
        slideoutData,
        isSlideOut: false,
        isTransationSlideOut: true,
      });
    } else {
      this.rowClickEvents(row, rowType);
    }
  };

  transationSlideoutSave = (saveReq, saveRes) => {
    const { entryType } = this.state;
    if (entryType === "edit") {
      this.setState({ entryType: "" });
      return;
    }
    let requestArray: any = [];
    let request: any = {};
    const { hidValue, accountNameValue, tenantID, AddTransationSyncID } =
      this.state;
    request.SyncId = AddTransationSyncID;
    request.HotelId = hidValue;
    request.Tenantid = tenantID;
    request.Uniqueno = saveRes.uniqueno;
    request.Oprid = accountNameValue;
    request.PageType = saveReq.pageType;
    request.UpdateTabale = "";
    console.log(request, "transationSlideoutSave");
    requestArray.push(request);
    this.setState({ addTranReq: requestArray });
    bankSyncServices
      .SavePlaidTranSyncMatchingDetailsStatus(requestArray)
      .then((response) => {
        this.setState({ reconcileAfterSave: true }, () => {});
      })
      .catch((error) => {
        Utils.toastError(error, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };

  handleDensityView = (e: any, viewName) => {
    this.setState({ defaultDensityView: viewName }, () => {
      this.props?.handleDensityView(viewName);
    });
  };

  saveDefaultView = (): void => {
    const laborSaveAsDefaultdtos: any[] = [];
    let tableColumnList = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.tableColumnList))
    );
    let selectedColumns: any = [];
    tableColumnList.forEach((item) => {
      if (item.isChecked) {
        selectedColumns.push(item.id);
      }
    });
    let isGrouped = this.props.isGroupUnpaid ? "Yes" : "No";
    laborSaveAsDefaultdtos.push({
      fieldName: "view",
      fieldValue: this.state.defaultDensityView + "," + isGrouped,
      pageName: this.state.parentType,
    });
    laborSaveAsDefaultdtos.push({
      fieldName: "column",
      fieldValue: selectedColumns.toString(),
      pageName: this.state.parentType,
    });
    laborSaveAsDefaultdtos.push({
      fieldName: "sortingField",
      fieldValue: this.state.sortingField,
      pageName: this.state.parentType,
    });
    laborSaveAsDefaultdtos.push({
      fieldName: "sortingOrder",
      fieldValue: this.state.sortingOrder,
      pageName: this.state.parentType,
    });
    laborPerformance
      .saveDefaultViewProperties(laborSaveAsDefaultdtos)
      .then(async (result: any | null) => {
        if (result != null) {
          if (result.message === "Success") {
            toast.success(result?.result?.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        }
      })
      .catch((error) => {
        Utils.toastError(error.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        this.setState({ showLoader: false });
      });
  };

  setDefaultColumn = (columns: any) => {
    let { tableColumnList, columnsHeader, columnSortingList } = this.state;
    if (columns?.length > 0) {
      let resData = columns.split(",");
      tableColumnList.forEach((item) => {
        item.isChecked = false;
      });
      columnSortingList.forEach((item) => {
        item.isHidden = true;
      });
      columnsHeader.forEach((item) => {
        if (
          item.dataField === "company" ||
          item.dataField === "invoiceNo" ||
          item.dataField === "accper" ||
          item.dataField === "billDate" ||
          item.dataField === "dueDate" ||
          item.dataField === "transTotal" ||
          item.dataField === "etype" ||
          item.dataField === "submittedOn" ||
          item.dataField === "tabStatus" ||
          item.dataField === "statusDate" ||
          item.dataField === "addContract" ||
          item.dataField === "internalNotes" ||
          item.dataField === "extra"
        ) {
          item.hidden = true;
          item.searchable = false;
        }
      });

      resData.forEach((ritm) => {
        columnsHeader.forEach((item) => {
          if (item.dataField === ritm) {
            if (item.dataField === "extra") {
              item.hidden = true;
              item.searchable = false;
            } else {
              item.hidden = false;
              item.searchable = true;
            }
          }
        });
        tableColumnList.forEach((item) => {
          if (item.id === ritm) {
            item.isHide = false;
            item.isChecked = true;
          }
        });
        columnSortingList.forEach((item) => {
          if (item.id === ritm) {
            item.isHidden = false;
          }
        });
      });
      this.setState(
        () => ({ columnsHeader, tableColumnList, columnSortingList }),
        () => {
          this.setDefaultSort(
            this.props.parentState?.sortingField,
            this.props.parentState?.sortingOrder
          );
        }
      );
    } else {
      columnsHeader.forEach((item) => {
        if (
          item.dataField === "dueDate" ||
          item.dataField === "addContract" ||
          item.dataField === "internalNotes" ||
          item.dataField === "extra"
        ) {
          item.hidden = true;
          item.searchable = false;
        }
      });
      columnSortingList.forEach((item) => {
        if (
          item.id === "addContract" ||
          item.id === "internalNotes" ||
          item.id === "dueDate" ||
          item.id === "extra"
        ) {
          item.isHidden = true;
        }
      });
      tableColumnList.forEach((item) => {
        if (
          item.id === "addContract" ||
          item.id === "internalNotes" ||
          item.id === "dueDate" ||
          item.id === "extra"
        ) {
          item.isChecked = false;
        }
      });
      this.setState(
        { tableColumnList, columnsHeader, columnSortingList },
        () => {
          this.setDefaultSort(
            this.props.parentState?.sortingField,
            this.props.parentState?.sortingOrder
          );
        }
      );
    }
  };

  setDefaultSort = (
    defaultSortingField: string,
    defaultSortingOrder: string
  ) => {
    if (defaultSortingField?.toString()?.length > 0) {
      this.setState(
        {
          sortingField: defaultSortingField,
          sortingOrder: defaultSortingOrder,
        },
        () => {
          this.handleSortByColumn(defaultSortingField, true);
          setTimeout(() => {
            this.updateColumns();
          }, 100);
        }
      );
    } else {
      this.handleSortByColumn(this.state.sortingField, true);
      setTimeout(() => {
        this.updateColumns();
      }, 100);
    }
  };

  updateColumns = () => {
    let { tableData } = this.state;
    tableData = tableData.map((items) => {
      return {
        ...items,
        wom_vendorWidthCol:
          Number(
            this.wom_vendorWidth?.current?.getBoundingClientRect().width +
              0 +
              14
          )
            .toFixed()
            .toString() + "px",
      };
    });
    this.setState((curr: any) => ({
      ...curr,
      tableData: tableData,
      loaderTrue: false,
      wom_vendorWidthCol:
        Number(
          this.wom_vendorWidth?.current?.getBoundingClientRect().width + 0 + 14
        )
          .toFixed()
          .toString() + "px",
    }));
  };

  onColumnChange = (e: any, items: any, index: any) => {
    this.setState({ loaderTrue: true });
    let data = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.tableColumnList))
    );
    data[index].isChecked = e.target.checked;
    setTimeout(() => {
      let { columnsHeader, columnSortingList, SortBy } = this.state;
      columnSortingList[index].isHidden = data[index].isChecked ? false : true;
      if (
        columnSortingList[index].isActive === true &&
        columnSortingList[index].isHidden === true
      ) {
        columnSortingList[index].isActive = false;
        if (
          columnSortingList[index + 1] !== undefined &&
          columnSortingList[index + 1].isHidden !== true
        ) {
          columnSortingList[index + 1].isActive = true;
          SortBy.name = columnSortingList[index + 1].name;
          SortBy.id = columnSortingList[index + 1].id;
        } else {
          columnSortingList[0].isActive = true;
          SortBy.name = "Vendor";
          SortBy.id = "company";
        }
      }
      columnsHeader[index].hidden = data[index].isChecked ? false : true;
      columnsHeader[index].searchable = data[index].isChecked;
      if (items.id === "transTotal") {
        columnsHeader[11].searchable = data[index].isChecked;
      }
      this.setState(
        () => ({
          tableColumnList: data,
          columnsHeader,
          columnSortingList,
          SortBy,
          isColumnChange: true,
        }),
        () => {
          this.updateColumns();
        }
      );
    }, 0);
  };

  searchResult = (resultAfterSearch: any) => {
    let { searchResult, tableData } = this.state;
    searchResult = [];
    let afterSearchUniqueno: any = [];
    resultAfterSearch.forEach((item) => afterSearchUniqueno.push(item.rowNo));
    afterSearchUniqueno.forEach((uNo) => {
      tableData.filter((item) => {
        if (item.rowNo === uNo) {
          searchResult.push(item);
        }
        return item;
      });
    });
    this.setState(
      { bulkSelectRow: false, selectedRows: [], searchResult },
      () => {
        this.updateColumns();
      }
    );
  };

  loadBackData() {
    this.props.refreshTable(this.state.parentType, this.props?.parentState.key);
  }

  handleSortTable = (eventKey: any) => {
    let { columnSortingList, tableData, SortBy } = this.state;
    columnSortingList.map((item) => (item.isActive = item.id === eventKey));
    let isRow = columnSortingList.filter((item) => item.id === eventKey);
    SortBy.name = isRow[0].name;
    SortBy.id = isRow[0].id;

    if (eventKey === "company") {
      tableData = _.orderBy(
        tableData,
        [(obj) => obj?.company?.toUpperCase()],
        [(obj) => new Date(obj.dueDate)],
        ["asc"]
      );
    } else if (eventKey === "invoiceNo") {
      tableData = _.orderBy(
        tableData,
        [(obj) => obj?.invoiceNo?.toUpperCase()],
        [(obj) => new Date(obj.dueDate)],
        ["asc"]
      );
    } else if (eventKey === "dueDate") {
      tableData = _.orderBy(
        tableData,
        [(obj) => new Date(obj.dueDate)],
        ["asc"]
      );
    } else if (eventKey === "billDate") {
      tableData = _.orderBy(
        _.orderBy(tableData, [(obj) => new Date(obj.billDate)], ["asc"]),
        [(obj) => new Date(obj.dueDate)],
        ["asc"]
      );
    } else if (eventKey === "accper") {
      tableData = _.orderBy(
        tableData,
        [(obj) => new Date(obj.accper1)],
        [(obj) => new Date(obj.dueDate)],
        ["asc"]
      );
    } else {
      tableData = _.sortBy(
        _.orderBy(tableData, [(obj) => new Date(obj.dueDate)], ["asc"]),
        eventKey
      );
    }
    this.setState({
      SortBy,
      tableData,
      columnSortingList,
      isSpinner: false,
    });
  };

  handleScroll = (e) => {
    if (this.state.showDropDown) {
      $("#more-action").click();
    }
  };

  handleToggleRows = (type) => {
    let { expList, expandedList } = this.state;
    expandedList = type === "Collapse_All" ? [] : expList;
    this.setState(
      () => ({ expandedList }),
      () => {
        setTimeout(() => {
          this.updateColumns();
        }, 100);
      }
    );
  };

  handleOnExpand = (row, isExpand, rowIndex, e) => {
    if (row.hasArrow && isExpand) {
      this.setState(
        () => ({
          expandedList: [...this.state.expandedList, row.rowNo],
        }),
        () => {
          setTimeout(() => {
            this.updateColumns();
          }, 200);
        }
      );
    } else {
      this.setState(
        () => ({
          expandedList: this.state.expandedList.filter((x) => x !== row.rowNo),
        }),
        () => {
          setTimeout(() => {
            this.updateColumns();
          }, 200);
        }
      );
    }
  };

  render() {
    const bulkResponsiveList = () => {
      return (
        <div className="loader-responsive register">
          <div className="performanceList">
            <div className="tableList">
              <ul>
                <li className="d-flex">
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                    </div>
                  </div>
                  <div className="list d-flex align-self-center">
                    <div className="group">
                      <div className="loadingAnimation"></div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      );
    };

    const sortSvg = () => {
      return (
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M4.5 15L7.5 12H5.25V3H3.75V12H1.5L4.5 15ZM8.25 6H15V7.5H8.25V6ZM8.25 9H13.5V10.5H8.25V9ZM8.25 3H16.5V4.5H8.25V3ZM8.25 12H12V13.5H8.25V12Z" />
        </svg>
      );
    };

    const gearSvg = () => {
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M1.56286 10.1808L2.89619 12.4875C3.08019 12.8062 3.48819 12.9155 3.80686 12.7308L4.73752 12.1935C5.12419 12.4982 5.55152 12.7482 6.00086 12.9348V14.0002C6.00086 14.3682 6.29886 14.6668 6.66752 14.6668H9.33419C9.70286 14.6668 10.0009 14.3682 10.0009 14.0002V12.9348C10.4502 12.7482 10.8769 12.4982 11.2642 12.1935L12.1949 12.7308C12.5129 12.9142 12.9222 12.8042 13.1055 12.4875L14.4389 10.1808C14.6222 9.8635 14.5122 9.4535 14.1955 9.27016L13.2809 8.7415C13.3155 8.4955 13.3335 8.2475 13.3335 8.00016C13.3335 7.75283 13.3155 7.50483 13.2795 7.25883L14.1942 6.73016C14.5115 6.54683 14.6215 6.13683 14.4375 5.8195L13.1042 3.51283C12.9202 3.19416 12.5122 3.08483 12.1935 3.2695L11.2629 3.80683C10.8762 3.50216 10.4495 3.2515 10.0002 3.0655V2.00016C10.0002 1.63216 9.70219 1.3335 9.33352 1.3335H6.66686C6.29819 1.3335 6.00019 1.63216 6.00019 2.00016V3.0655C5.55086 3.25216 5.12419 3.50216 4.73686 3.80683L3.80686 3.2695C3.48752 3.08483 3.08019 3.19416 2.89619 3.51283L1.56286 5.8195C1.37953 6.13683 1.48953 6.54683 1.80619 6.73016L2.72086 7.25883C2.68486 7.50483 2.66686 7.75283 2.66686 8.00016C2.66686 8.2475 2.68486 8.4955 2.72086 8.7415L1.80619 9.27016C1.48886 9.4535 1.37886 9.8635 1.56286 10.1808ZM8.00019 5.3335C9.47086 5.3335 10.6669 6.5295 10.6669 8.00016C10.6669 9.47083 9.47086 10.6668 8.00019 10.6668C6.52952 10.6668 5.33352 9.47083 5.33352 8.00016C5.33352 6.5295 6.52952 5.3335 8.00019 5.3335Z" />
        </svg>
      );
    };

    const checkSvg = () => {
      return (
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.49998 11.6895L5.03023 9.21973L3.96973 10.2802L7.49998 13.8105L14.7802 6.53023L13.7197 5.46973L7.49998 11.6895Z"
            fill="#2CAF92"
          />
        </svg>
      );
    };

    let {
      selectedRows,
      tableData,
      defaultDensityView,
      sortingField,
      sortingOrder,
    } = this.state;
    let densityView = defaultDensityView;
    let className;
    let tableLen = tableData;
    let isLenLess =
      selectedRows.length > 0 && selectedRows.length < tableLen.length;
    className = isLenLess ? "showTip" : "";

    const renderCompanyHeaderFormatter = () => (
      <div ref={this.wom_vendorWidth}></div>
    );
    const renderChildFormatter = (row: any, colName: any) => (
      <div
        onClick={() => {
          this.rowClickEvents(row, "child");
        }}
      >
        <EllipsisWithTooltip placement="top">{colName}</EllipsisWithTooltip>
      </div>
    );
    const renderChildFormatterW = (row: any, colName: any) => (
      <div
        className="setw100"
        onClick={() => {
          this.rowClickEvents(row, "child");
        }}
      >
        <EllipsisWithTooltip placement="top">{colName}</EllipsisWithTooltip>
      </div>
    );
    const renderChildFormatterH = (row, colName) => (
      <div
        onClick={() => {
          this.rowClickEvents(row, "child");
        }}
        onWheel={() => this.hoverOutEllipsis()}
      >
        <EllipsisWithTooltip placement="top">{colName}</EllipsisWithTooltip>
      </div>
    );

    const columnsChild = [
      {
        dataField: "",
        text: "",
        headerClasses: "expand-cell-header",
        classes: "expand-cell",
      },
      {
        dataField: "company",
        text: "",
        headerFormatter: (column, colIndex) => renderCompanyHeaderFormatter(),
        headerClasses: (cell: any, row: any, rowIndex: any) =>
          this.state.expList.length > 0
            ? "left40 vendorProcess-vendor"
            : "vendorProcess-vendor",
        classes: (cell: any, row: any, rowIndex: any) =>
          this.state.expList.length > 0
            ? "left40 vendorProcess-vendor whiteRow"
            : "vendorProcess-vendor whiteRow",
      },
      {
        dataField: "invoiceNo",
        text: "Number",
        formatter: (cell: any, row: any, rowIndex: any) =>
          renderChildFormatterW(row, row.invoiceNo),
        headerClasses: (cell: any, row: any, rowIndex: any) =>
          this.state.expList.length > 0
            ? "setNumberLeft vendorProcess-number"
            : "vendorProcess-number",
        classes: (cell: any, row: any, rowIndex: any) =>
          this.state.expList.length > 0
            ? "setNumberLeft vendorProcess-number"
            : "vendorProcess-number",
      },
      {
        dataField: "accper",
        text: "AP",
        formatter: (cell: any, row: any, rowIndex: any) =>
          renderChildFormatter(row, row.accper),
        headerClasses: "vendorProcess-ap",
        classes: "vendorProcess-ap",
      },
      {
        dataField: "billDate",
        text: "Invoice Date",
        formatter: (cell: any, row: any, rowIndex: any) =>
          renderChildFormatter(row, row.billDate),
        headerClasses: "vendorProcess-invoiceDate",
        classes: "vendorProcess-invoiceDate",
      },
      {
        dataField: "dueDate",
        text: "Due Date",
        formatter: (cell: any, row: any, rowIndex: any) =>
          renderChildFormatter(row, row.dueDate),
        headerClasses: "vendorProcess-dueDate",
        classes: "vendorProcess-dueDate",
      },
      {
        dataField: "transTotal",
        text: "Amount",
        formatter: (cell: any, row: any, rowIndex: any) =>
          renderChildFormatter(
            row,
            Utils.currencyFormatWithOutdoller(row.amount)
          ),
        headerClasses: "vendorProcess-amount",
        classes: "vendorProcess-amount",
      },
      {
        dataField: "",
        text: "",
        headerClasses: "vendorProcess-method",
        classes: "vendorProcess-method",
      },
      {
        dataField: "addContract",
        text: "Account No.",
        formatter: (cell: any, row: any, rowIndex: any) =>
          renderChildFormatterH(row, row.addContract),
        headerClasses: "vendorProcess-customer",
        classes: "vendorProcess-customer",
      },
      {
        dataField: "internalNotes",
        text: "Notes",
        formatter: (cell: any, row: any, rowIndex: any) =>
          renderChildFormatterH(row, row.internalNotes),
        headerClasses: "vendorProcess-notes",
        classes: "vendorProcess-notes",
      },
      {
        dataField: "",
        text: "",
        headerClasses: "vendorProcess-status",
        classes: "vendorProcess-status",
      },
      {
        dataField: "",
        text: "",
        classes: "vendorProcess-icon text-right",
        headerClasses: "vendorProcess-icon text-right",
      },
      {
        dataField: "transTotal1",
        text: "",
        classes: "d-none",
        headerClasses: "d-none",
      },
      {
        dataField: "extra",
        text: "",
        classes: "d-none",
        headerClasses: "d-none",
        hidden: true,
      },
    ];

    const renderChild = (row) => {
      let uniqueID = Math.floor(Math.random() * 90 + 10);
      let splitItems = this.state.tableData.filter(
        (x) => x.groupID === row.groupID /* && (x.viewType == 2)*/
      );
      return (
        <div className="wom_nestedChildTable innerInvoiceTable">
          <BootstrapTable
            key={uniqueID}
            id={uniqueID}
            keyField="uniqueno"
            data={splitItems}
            columns={columnsChild}
            hover
            noDataIndication={"No activity for the selected row"}
          />
        </div>
      );
    };

    let rowClasses = (row) => {
      let className = row.hasArrow ? "hasArrowClass" : "NoArrowClass";
      return className;
    };

    const expandHeaderColumnRenderer = (isAnyExpands) => {
      if (isAnyExpands) {
        return <div></div>;
      }
      return <div></div>;
    };

    const expandColumnRenderer = (expanded) => (
      <span className={expanded ? "chevronExpended" : "chevronCollpsed"}>
        <img src={chevronRight} alt="chevron Right" />
      </span>
    );

    const expandSplitRow = {
      renderer: (row: any, rowIndex: any) => renderChild(row),
      expanded: this.state.expandedList,
      showExpandColumn: true,
      expandByColumnOnly: true,
      nonExpandable: this.state.nonSelectedRows,
      onExpand: this.handleOnExpand,
      expandHeaderColumnRenderer: ({ isAnyExpands }) =>
        expandHeaderColumnRenderer(isAnyExpands),
      expandColumnRenderer: ({ expanded }) => expandColumnRenderer(expanded),
    };

    return (
      <>
        {this.state.loaderTrue && (
          <div className="matrixSpinnerCenter">
            <Spinner animation="border" variant="secondary" />
          </div>
        )}
        {this.state.isTransationSlideOut && (
          <TransactionSlideout
            pageType={this.state.pageType}
            {...this.state.slideoutData}
            hideSlideOut={this.hideSlideOut.bind(this)}
            OpenInvoiceSlideOut={this.OpenInvoiceSlideOut.bind(this)}
            transationSlideoutSave={this.transationSlideoutSave.bind(this)}
            callFrom="BankSync"
          />
        )}
        {this.state.isSlideOut && (
          <InvoiceEntrySlideout
            mainHidValue={this.props.hidValue}
            hidValue={this.props.hidValue}
            hotelName={this.state.sildeoutCode}
            uniqueNo={this.state.uniqueNo}
            pageType={this.state.isSlideOut ? "Invoice" : this.state.pageType}
            saveAction={"Payment"}
            oprId={this.state.oprId}
            vid={this.state.vid}
            hideSlideOut={this.hideSlideOut.bind(this)}
            loadBackData={this.loadBackData.bind(this)}
            OpenInvoiceSlideOut={this.OpenInvoiceSlideOut.bind(this)}
          />
        )}

        <ToolkitProvider
          keyField="rowNo"
          data={tableData.filter((x) => Number(x.viewType) === 1)}
          columns={this.columnsHeader}
          search={{
            afterSearch: (newResult) => this.searchResult(newResult),
          }}
          defaultSorted={{ dataField: sortingField, order: sortingOrder }}
        >
          {(props: {
            searchProps: JSX.IntrinsicAttributes;
            baseProps: JSX.IntrinsicAttributes;
          }) => (
            <>
              <div className="InnerTabPanel-Pay">
                <div className="TabBody-Pay">
                  <div className="search-header d-flex">
                    <div className="search-filter">
                      <label id="labeltableSearch" className="search-label">
                        <TableSearch
                          id="tableSearch"
                          key="tableSearch"
                          ref={this.child}
                          {...props.searchProps}
                          CleanSearch={this.CleanSearch.bind(this)}
                          placeholder={"Filter"}
                        />
                      </label>
                    </div>
                    <div className="d-flex ml-auto">
                      {this.state.expList.length > 0 && (
                        <Button
                          type="button"
                          onClick={(e: any) =>
                            this.handleToggleRows(
                              this.state.expandedList.length > 0
                                ? "Collapse_All"
                                : "Expend_All"
                            )
                          }
                          className="btn-outline-primary PCbtnHeight mr-2"
                          disabled={tableData.length === 0}
                        >
                          {this.state.expandedList.length > 0
                            ? "Collapse All"
                            : "Expand All"}
                        </Button>
                      )}

                      {/* <div
                        className={`${
                          tableData.length === 0 ? "disabled-area" : ""
                        } hasShortingBtn wom_hasGearIcon mr-2`}
                      >
                        <Dropdown
                          className="pos-static more-action bg-blue dropdown wom_dropdown"
                          alignRight
                        >
                          <Dropdown.Toggle
                            id="dropdown-ptoType"
                            disabled={
                              tableData.length === 0 || this.state.isSpinner
                            }
                          >
                            <div className="d-flex align-items-center wom_svg">
                              <div className="d-flex align-items-center wom_svg">
                                {sortSvg()}
                                {this.state.SortBy.name}
                                <div className="drop-arrow d-flex ml-1">
                                  <FiChevronDown />
                                </div>
                              </div>
                            </div>
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="wom_DencityMenu">
                            <div className="wom_MenuOverlap">
                              {this.state.columnSortingList.map(
                                (item, index) => {
                                  if (item.isHidden) {
                                    return <></>;
                                  } else {
                                    return (
                                      <div
                                        key={item.id}
                                        className={
                                          item.isActive
                                            ? "active wom_dropdownItem"
                                            : "wom_dropdownItem"
                                        }
                                        onClick={(e) =>
                                          this.handleSortTable(item.id)
                                        }
                                      >
                                        <div className="d-flex">
                                          <div className="mr-auto">
                                            {item.name}
                                          </div>
                                          <div className="ml-auto">
                                            {item.isActive && checkSvg()}
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  }
                                }
                              )}
                            </div>
                            <div className="m-0 dropdown-divider"></div>
                            <Dropdown.Item
                              className="wom_saveDefaultBtn"
                              onClick={() => this.saveDefaultView()}
                            >
                              Save as Default View
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div> */}
                      <div className="wom_hasGearIcon">
                        <Dropdown className="more-action bg-blue dropdown wom_dropdown">
                          <Dropdown.Toggle
                            id="dropdown-ptoType"
                            disabled={tableData.length === 0}
                          >
                            <div className="d-flex align-items-center wom_svg">
                              {gearSvg()}
                              <div className="drop-arrow d-flex">
                                <FiChevronDown />
                              </div>
                            </div>
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="wom_DencityMenu">
                            <>
                              <div className="wom_gearIconHeader wom_headerTop">
                                <strong>Density View Control</strong>
                              </div>
                              {this.state.densityView.map((items, index) => (
                                <>
                                  {items.densityType !== "groupUnpaid" && (
                                    <div
                                      className={
                                        densityView === items.densityType
                                          ? "wom_dropdownItem wom_ddactive"
                                          : "wom_dropdownItem"
                                      }
                                      key={items.id}
                                      onClick={(e: any) =>
                                        this.handleDensityView(
                                          e,
                                          items.densityType
                                        )
                                      }
                                    >
                                      {items.name}
                                      {densityView === items.densityType && (
                                        <>{checkSvg()}</>
                                      )}
                                    </div>
                                  )}
                                </>
                              ))}
                              <div className="m-0 dropdown-divider"></div>
                              <Dropdown.Item
                                className="wom_saveDefaultBtn"
                                onClick={() => this.saveDefaultView()}
                              >
                                Save as Default View
                              </Dropdown.Item>
                            </>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                  <div
                    id="CombinedTable-Pay"
                    onScroll={(e) => this.handleScroll(e)}
                    className={`CombinedTable-Pay isProcessingTable payHasNoFooterProcess ${className}`}
                    ref={this.scrollArea}
                  >
                    {this.state.isSpinner ? (
                      <div className="bulkImportList mt-0">
                        <div className="bulkImportList1 d-flex">
                          <div className="single-loader loadingAnimation"></div>
                        </div>
                        <>{bulkResponsiveList()}</>
                        <>{bulkResponsiveList()}</>
                        <>{bulkResponsiveList()}</>
                      </div>
                    ) : (
                      <div
                        className={
                          this.state.isBulkSelected
                            ? `${densityView} disabled-area stickey vendorProcess`
                            : `${densityView} stickey vendorProcess`
                        }
                      >
                        <BootstrapTable
                          {...props.baseProps}
                          keyField="rowNo"
                          hover
                          noDataIndication={
                            "There are no Invoices with this payment method."
                          }
                          expandRow={
                            this.state.expList.length > 0 && expandSplitRow
                          }
                          rowClasses={rowClasses}
                          filter={filterFactory()}
                          defaultSorted={{
                            dataField: sortingField,
                            order: sortingOrder,
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </ToolkitProvider>
      </>
    );
  }
}
