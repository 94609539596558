import {
  IGetfacilitiesListRequest,
  IFacilities,
  IGetAssetsListRequest,
  IAssetDto,
  IAssetByLocation,
  IMaintenanceByLocation,
  IFacilityOverview,
} from "../Contracts/IFacilities";
import {
  IFacilityProcedureListRequest,
  IFacilityProcedureWithTasksSaveRequest,
  IFacilityProcedures,
  IFacilityTasks,
} from "../Contracts/IProcedures";
import Instense from "./Axios-config";

export class Facility {
  public static GetFacilities = async (
    request: IGetfacilitiesListRequest
  ): Promise<IFacilities[] | null> => {
    const url = `/Facility/GetFacilities`;
    let storage = JSON.parse(localStorage.getItem("storage")!);
    request.tenantId = storage === null ? 0 : (storage.tenantID as any);
    return Instense.post(url, request).then((response) => {
      let result = response?.data?.result as IFacilities[];
      return result;
    });
  };

  public static GetDistinctRoomCategories = async (): Promise<
    string[] | null
  > => {
    const url = `/Facility/GetDistinctRoomCategories`;
    return Instense.get(url).then((response) => {
      let result = response?.data?.result as string[];
      return result;
    });
  };

  public static GetDistinctRoomTypes = async (
    hid: number
  ): Promise<IFacilities[] | null> => {
    const url = `/Facility/GetDistinctRoomTypes`;
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let TenantId = storage === null ? 0 : (storage.tenantID as any);

    return Instense.get(url, { params: { hid, TenantId } }).then((response) => {
      let result = response?.data?.result as IFacilities[];
      return result;
    });
  };

  public static GetDistinctLocationType  = async (
    hid: number
  ): Promise<IFacilities[] | null> => {
    const url = `/Facility/GetDistinctLocationType`;
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let TenantId = storage === null ? 0 : (storage.tenantID as any);

    return Instense.get(url, { params: { hid, TenantId } }).then((response) => {
      let result = response?.data?.result as IFacilities[];
      return result;
    });
  };

  public static GetDistinctAssetTypeList = async () => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);

    const url = `/Assets/GetDistinctAssetTypeList`;
    return Instense.get(url).then((response) => {
      let result = response?.data?.result as any[];
      return result;
    });
  };

  public static GetDistinctRoomsList = async (Hid) => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let TenantId = storage === null ? 0 : (storage.tenantID as any);

    const url = `/Assets/GetDistinctRooms`;
    return Instense.get(url, { params: { Hid, TenantId } }).then((response) => {
      let result = response?.data?.result as any[];
      return result;
    });
  };

  public static GetDistinctRoomsAllList = async (Hid) => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let TenantId = storage === null ? 0 : (storage.tenantID as any);

    const url = `/Assets/GetDistinctRooms`;
    return Instense.get(url, { params: { Hid, TenantId } }).then((response) => {
      let result = response?.data?.result as any[];
      return result;
    });
  };

  public static CheckAddEditPermission = async () => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);

    const userName = storage === null ? 0 : (storage.userName as any);
    const TenantID = tenantID;

    const url = `/Assets/CheckAddEditPermission`;
    return Instense.get(url, {
      params: { userName: userName, TenantID: TenantID },
    }).then((response) => {
      let result = response?.data?.result;
      return result;
    });
  };

  public static FacilityMaintenanceCheckAddEditPermission = async () => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);

    const userName = storage === null ? 0 : (storage.userName as any);
    const TenantID = tenantID;

    const url = `/FacilityMaintenance/CheckAddEditPermission`;
    return Instense.get(url, {
      params: { userName: userName, TenantID: TenantID },
    }).then((response) => {
      let result = response?.data?.result;
      return result;
    });
  };

  public static SaveAssets = async (request) => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.createdBy = storage === null ? 0 : (storage.user_UniqueID as any);
    // request.TenantID = tenantID;

    const url = `/Assets/SaveAssets`;
    return Instense.post(url, request).then((response) => {
      let result = response.data as any;
      return result;
    });
  };

  public static ValidateAssetName = async (request) => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.TenantID = tenantID;

    const url = `/Assets/SaveAssets`;
    return Instense.post(url, request).then((response) => {
      let result = response?.data?.result as any[];
      return result;
    });
  };

  public static GetAssets = async (
    request: IGetAssetsListRequest
  ): Promise<IAssetDto[] | null> => {
    const url = `/Assets/GetAssets`;
    return Instense.post(url, request).then((response) => {
      let result = response?.data?.result as IAssetDto[];
      return result;
    });
  };

  public static GetAssetByLocation = async (
    request: IAssetByLocation
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    const url = `/Assets/Get_AssetByLocation`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static GetMaintenanceByLocation = async (
    request: IMaintenanceByLocation
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;
    request.createdby = storage === null ? 0 : (storage.user_UniqueID as any);
    const url = `/Facility/GetLocationWiseMaintenance`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static FacilityMaintenanceCheckEditStatusPermission = async () => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);

    const userName = storage === null ? 0 : (storage.userName as any);
    const TenantID = tenantID;

    const url = `/FacilityMaintenance/CheckEditStatusPermission`;
    return Instense.get(url, {
      params: { userName: userName, TenantID: TenantID },
    }).then((response) => {
      let result = response?.data?.result;
      return result;
    });
  };

  public static GetFacilityOverviewStat = async (): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let request: any = {};
    request.tenantId = tenantID;

    const url = `/FacilityMaintenance/GetFacilityOverviewStat`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as IFacilityOverview[];
      return result;
    });
  };

  public static ResponseExcelFile = async (files, hid, onUploadProgress) => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    const formData: FormData = new FormData();
    files.forEach((element) => {
      formData.append("file", element);
    });
    formData.append("hid", hid);
    formData.append("tenantId", tenantID);
    const url = `/Assets/ResponseExcelFile`;
    return Instense.post(url, formData, { onUploadProgress }).then(
      (response) => {
        let result = response.data as any;
        return result;
      }
    );
  };

  public static BulkSaveAssets = async (request) => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantId = tenantID;
    const url = `/Assets/BulkSaveAssets`;
    return Instense.post(url, request).then((response) => {
      let result = response.data as any;
      return result;
    });
  };

  public static ValidatebulkAssetsData = async (request) => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantId = tenantID;
    const url = `/Assets/ValidatebulkAssetsData`;
    return Instense.post(url, request).then((response) => {
      let result = response.data as any;
      return result;
    });
  };
}
