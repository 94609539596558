import * as React from "react";
import { ReactPageLoader } from "../../../Common/Components/ReactPageLoader";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { AddRoomConfiguration } from "./AddRoomConfiguration";
import { Dropdown } from "react-bootstrap";
import { AddRoomType } from "./AddRoomType";
import { AddConferenceRoom } from "./AddConferenceRoom";

import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import moment from "moment";
import Calendar from "react-calendar";
import OutsideClickHandler from "react-outside-click-handler";
import { EntityManagementService } from "../../../Common/Services/EntityManagementService";
import { toast } from "react-toastify";
import { Utils } from "../../../Common/Utilis";
import { LaborDashBoard } from "../../../Common/Services/LaborDashBoard";
import { IDataAggrigation } from "../../../Common/Contracts/IDashboardWidget";
const { SearchBar } = Search;

export class RoomsOld extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      leftPanelLoader: false,
      rightPanelLoader: false,
      showAddRoomTypeModal: false,
      showRoomConfigurationModal: false,
      currentSelectedDate: "",
      leftPanelDates: [],
      isSelected: false,
      selected: [],
      roomTypeData: [],
      roomTypeRowEdit: {},
      rightTableData: [],
      calenderStartDateShow: false,
      isDataSubmitting: false,
      conferenceRoomRowEdit: {},
      conferenceRoomData: [],
      showAddCoferenceRoomModal: false,
      isRoomTypesArrayModified: false,
      hideConferenceSection: true,
      conferenceRooms: []
    };
  }

  componentDidMount() {
    this.getRoomTypes();
    this.getConferenceRooms();
  }

  calenderDateHide = () => {
    this.setState({ calenderStartDateShow: false });
  };

  calenderDateDisplay = () => {
    this.setState({ calenderStartDateShow: true });
  };

  discard = ()=> {
  //  this.setState({selected:[],currentSelectedDate: ""});
    this.getRoomTypes();
  }
  getRoomTypes = (isUpdating = false) => {
    EntityManagementService.GetRoomType(
      this.props?.entityRowData?.hotelId
    ).then((roomTypeData: any) => {
      if (roomTypeData?.length > 0) {
        const leftPanelDates = roomTypeData?.map((item) => ({
          date: moment(item?.accountingDate).format("MM/DD/YYYY"),
        }));
        // debugger;
        if (this.state?.currentSelectedDate) {
          const rightTableData = roomTypeData.find(
            (r) =>
              moment(r.accountingDate).format("MM/DD/YYYY") ===
              this.state?.currentSelectedDate
          )?.roomTypeNew;
         const _roomTypeData = [...this.state?.roomTypeData]?.map((item) => {
          item.isDataModified =false;
            if (
              moment(item.accountingDate).format("MM/DD/YYYY") ===
              this.state?.currentSelectedDate
            ) {
              item.roomTypeNew = rightTableData;
              
            }
            return item;
          });


          this.setState({ rightTableData: [] },()=> this.setState({ rightTableData ,roomTypeData: _roomTypeData}));
        } else {
        }
        if(!isUpdating) { 
          if(leftPanelDates?.filter(x=>x?.date ===this.state?.currentSelectedDate)?.length ===0) {
            this.setState({ selected:[],currentSelectedDate: "" });      
          }
        this.setState({ leftPanelDates });
        }
      } else {
        this.setState({ selected:[],currentSelectedDate: "" ,rightTableData:[],leftPanelDates:[]});  
      }
      if(!isUpdating) { 
        this.props.getRoomsTabModificationStatus(false);

      this.setState({ roomTypeData, isRoomTypesArrayModified: false });
      }
    });
  };


  getConferenceRooms = () => {
    this.setState({ conferenceRoomData: [] });
    EntityManagementService.GetConferenceRooms(
      this.props?.entityRowData?.hotelId
    ).then((conferenceRoomData: any) => {
        this.setState({ conferenceRoomData });
    });
  };


  handleShowRoomTypeModal = () => {
    this.setState({ roomTypeRowEdit: {} }, () => {
      this.setState({ showAddRoomTypeModal: true });
    });
  };
  handleHideRoomTypeModal = (isReload: boolean = false) => {
    // debugger;
   
    this.setState({ showAddRoomTypeModal: false, roomTypeRowEdit: {} });
  };

  handleShowConferenceRoomModal = () => {
    this.setState({ conferenceRoomRowEdit: {} }, () => {
      this.setState({ showAddCoferenceRoomModal: true });
    });
  };
  handleHideConferenceRoomModal = (isReload: boolean = false) => {
    if (isReload) {
      this.getConferenceRooms();
    }
    this.setState({
      showAddCoferenceRoomModal: false,
      conferenceRoomRowEdit: {},
    });
  };



  updateRoomTypesList = (data) => {
    data.isDataModified = true;
    let roomTypeData = [...this.state.roomTypeData];
    const roomData = this.state.roomTypeData.filter(
      (r) =>
        moment(r.accountingDate).format("MM/DD/YYYY") ===
        this.state?.currentSelectedDate
    );

    if (roomData.length === 0) {
      roomTypeData.push({
        accountingDate: this.state?.currentSelectedDate,
        roomTypeNew: [],
        isDataModified: true
      });
    }

    const rightTableData =
      roomTypeData.find(
        (r) =>
          moment(r.accountingDate).format("MM/DD/YYYY") ===
          this.state?.currentSelectedDate
      )?.roomTypeNew || [];
     var index = rightTableData.findIndex(x=> +x.uniqueno === +data?.uniqueno);
     if(index<0 || +data?.uniqueno ===0) {  
    rightTableData.push(data);
     } else {
      rightTableData[index] = data;
     }
    roomTypeData = roomTypeData.map((item) => {
      if (
        moment(item.accountingDate).format("MM/DD/YYYY") ===
        this.state?.currentSelectedDate
      ) {
        item.roomTypeNew = rightTableData;
      }
      return item;
    });
    this.props.getRoomsTabModificationStatus(true);

    this.setState({
      roomTypeData,
      rightTableData,
      isRoomTypesArrayModified: true,
    });
  };

  // dataAggrationRoomCount = (hid, fromdate,todate)=>{
  //   const reqeust:IDataAggrigation={ 
  //     HID:hid,
  //     Fromdate:fromdate,
  //     Todate:todate,
  //     Triggered:"roomcount"
  //   }
  //   LaborDashBoard.DataAggeragationAccountingDashboardSinglehoteldata(reqeust).
  //   then((res)=>{
  //     console.log({res})
  //   }).catch((err)=>{
  //     console.log({err})
  //   })

  //   LaborDashBoard.DataAggeragationLaborSinglehoteldata(reqeust).
  //   then((res)=>{
  //     console.log({res})
  //   }).catch((err)=>{
  //     console.log({err})
  //   })
  // }
  // getNextSelectedDate =(selectedDate, datesList)=>{
  //   const dates=datesList.map(x=>x.date)
  //   for (let index = 0; index < dates.length; index++) {
  //     const item = dates[index];
  //     if(item == selectedDate)
  //     {
  //       const nextIndex=index+1
  //       return dates[nextIndex] || moment().format("MM/DD/YYYY") 
  //     }
      
  //   }
  // }



  saveRoomTypeBulkData = ()=> {
     this.setState({ isDataSubmitting: true });
    EntityManagementService.SaveRoomType(this.state.rightTableData,[],this.props.entityRowData?.hotelId,this.state.currentSelectedDate,this.state.currentSelectedDate,[])
      .then((result) => {
        this.setState({ isDataSubmitting: false});
        if (result?.message === "Success") {

          //  const nextDate=this.getNextSelectedDate(this.state.currentSelectedDate,this.state.leftPanelDates)
          // this.dataAggrationRoomCount(this.props.entityRowData?.hotelId,this.state.currentSelectedDate,nextDate)
          this.props?.isReloadRequired(true);
          this.getRoomTypes(true);
          this.props.getRoomsTabModificationStatus(false);
          this.setState({ isRoomTypesArrayModified: false});
          toast.success("Room types saved successfully.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "addGarnishment",
          });
        } else {
          Utils.toastError(result?.result, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "addGarnishment",
          });
        }
      })
      .catch((error) => {
        this.setState({ isDataSubmitting: false });
        Utils.toastError(error?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "addGarnishment",
        });
      });
  }

  handleShowRoomConfigurationModal = () => {
    this.setState({ showRoomConfigurationModal: true });
  };

  handleHideRoomConfigurationModal = (isReload: boolean = false) => {
    this.setState({ showRoomConfigurationModal: false });
  };

  setLeaftPanelDates = (date: any) => {

    const leftPanelDates = [...this.state?.leftPanelDates];
    if(leftPanelDates?.filter(item=> moment(item.date).format("MM/YYYY") === moment(date).format("MM/YYYY"))?.length>=2) {
      Utils.toastError("Maximum two accounting dates are allowed for the hotel room type.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "addGarnishment",
      });
      return;
    }
    this.setState({
      isSelected: true,
      selected: [date],
    });
    let roomTypeData = [...this.state.roomTypeData];
    const roomData = this.state.roomTypeData.filter(
      (r) =>
        moment(r.accountingDate).format("MM/DD/YYYY") ===
        date
    );

    if (roomData.length === 0 ) {
      roomTypeData.push({
        accountingDate: date,
        roomTypeNew: [],
        isDataModified: true
      });
    }


    const rightTableData = this.state.roomTypeData.find(
      (r) => moment(r.accountingDate).format("MM/DD/YYYY") === date
    )?.roomTypeNew;
    leftPanelDates.push({ date });
    this.props.getRoomsTabModificationStatus(true);
    this.setState({
      leftPanelDates,
      currentSelectedDate: date,
      isRoomTypesArrayModified: true,
      rightTableData,
      roomTypeData
    });
  };
  rowEvents = {
    onClick: (e: any, row: any, rowIndex: any) => {
      let selectedRow: Array<any> = [];
      const rightTableData = this.state.roomTypeData.find(
        (r) => moment(r.accountingDate).format("MM/DD/YYYY") === row?.date
      )?.roomTypeNew;


     let isDataModified  = rightTableData?.filter(x=>x?.isDataModified )?.length >0;

     isDataModified = this.state.roomTypeData.filter(
      (r) => moment(r.accountingDate).format("MM/DD/YYYY") === row?.date && r?.isDataModified
    )?.length > 0

      selectedRow.push(row.date);
    this.props.getRoomsTabModificationStatus(isDataModified);

      this.setState({
        rightTableData,
        isSelected: true,
        currentSelectedDate: row?.date,
        selected: selectedRow,
        isRoomTypesArrayModified: isDataModified? true : false
      });
    },
  };

  editRoomType = (row) => {
    this.setState({ roomTypeRowEdit: row }, () => {
      this.setState({ showAddRoomTypeModal: true });
    });
  };
  
  deleteRoomType = (row) => {
    let roomTypeData = [...this.state.roomTypeData];
    let rightTableData =
      roomTypeData.find(
        (r) =>
          moment(r.accountingDate).format("MM/DD/YYYY") ===
          this.state?.currentSelectedDate
      )?.roomTypeNew || [];
     if (+row?.uniqueno === 0) {
      rightTableData = rightTableData.filter(item=> item!==row);
      roomTypeData = roomTypeData.map((item) => {
        if (
          moment(item.accountingDate).format("MM/DD/YYYY") ===
          this.state?.currentSelectedDate
        ) {
          item.roomTypeNew = rightTableData;
        }
        return item;
      });

      
    this.setState({
      roomTypeData,
      rightTableData
    });
    } else {
        EntityManagementService.DeleteRoomType(row.uniqueno)
        .then((result) => {
          this.setState({ isDataSubmitting: false});
          if (result?.message === "Success") {
            // rightTableData = rightTableData.filter(item=> item!==row);
            // roomTypeData = roomTypeData.map((item) => {
            //   if (
            //     moment(item.accountingDate).format("MM/DD/YYYY") ===
            //     this.state?.currentSelectedDate
            //   ) {
            //     item.roomTypeNew = rightTableData;
            //   }
            //   return item;
            // });
      

            this.getRoomTypes();
            
          // this.setState({
          //   roomTypeData,
          //   rightTableData
          // });
            toast.success("Room type deleted successfully.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "addGarnishment",
            });
          } else {
            Utils.toastError(result?.result, {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "addGarnishment",
            });
          }
        })
        .catch((error) => {
          this.setState({ isDataSubmitting: false });
          Utils.toastError(error?.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "addGarnishment",
          });
        });
    }
  
    

  };


  deleteConfereneRoom = (row) => {
        EntityManagementService.DeleteConferenceRooms(row.roomID)
        .then((result) => {
          if (result?.message === "Success") {
            this.getConferenceRooms();
            toast.success("Conference room deleted successfully.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "addGarnishment",
            });
          } else {
            Utils.toastError(result?.result, {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "addGarnishment",
            });
          }
        })
        .catch((error) => {
          Utils.toastError(error?.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "addGarnishment",
          });
        });
    }

  editConferenceRoom = (row) => {
    this.setState({ conferenceRoomRowEdit: row }, () => {
      this.setState({ showAddCoferenceRoomModal: true });
    });
  };

  render() {
    const rowClasses = (row: { action: string }, rowIndex: any) => {
      if (this.state.isExpand === true && row.action === "Expended") {
        return "row-Expended";
      } else if (this.state.isExpand === true) {
        return "row-Collapsed";
      } else {
        return "row-Action";
      }
    };

    const searchGridColumns: any[] = [
      {
        dataField: "date",
        text: "",
      },
    ];
    const selectRow = {
      mode: "radio",
      hideSelectColumn: true,
      classes: "active-row",
      selected: this.state.isSelected ? this.state.selected : "",
    };

    const hideRow = {
      mode: "radio",
      hideSelectColumn: true,
      classes: "",
    };
    const roomGridColumns: any[] = [
      {
        dataField: "roomType",
        text: "Name",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="room-type-name">
            <EllipsisWithTooltip placement="bottom">{cell}</EllipsisWithTooltip>
          </div>
        ),
        footer: () => {
          return <div>Total Room Count</div>;
        },
      },
      {
        dataField: "capacity",
        text: "Count",
        footer: (columnData: any[]) => {
          const total = columnData.reduce((a: number, b: number) => +a + +b, 0);
          return <div>{total}</div>;
        },
      },

      {
        dataField: "notes",
        text: "Notes",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="room-type-notes">
            <EllipsisWithTooltip placement="bottom">{cell}</EllipsisWithTooltip>
          </div>
        ),
        footer: () => {
          return <div>{""}</div>;
        }
      },
      {
        dataField: "",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <Dropdown
            onClick={(e) => {
              e.stopPropagation();
            }}
            className="more-action"
            style={{position:'static'}}
            alignRight
          >
            <Dropdown.Toggle
             disabled={!this.props?.hasEditPermission}
              className="btn-outline-primary btn btn-primary btn-ghost more"
              id="dropdown-more"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
              </svg>
            </Dropdown.Toggle>
            <Dropdown.Menu>
            {+row?.uniqueno>0 &&
              <Dropdown.Item
                key={"edit"}
                eventKey={"edit"}
                onClick={() => this.editRoomType(row)}
              >
                Edit
              </Dropdown.Item>
      }

<Dropdown.Item
                key={"delete"}
                eventKey={"delete"}
                onClick={() => this.deleteRoomType(row)}
              >
                Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ),
        footer: () => {
          return <div>{""}</div>;
        }
      },
    ];

    const conferenceRoomGridColumns: any[] = [
      {
        dataField: "roomName",
        text: "Room Name",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="room-type-name">
            <EllipsisWithTooltip placement="bottom">{cell}</EllipsisWithTooltip>
          </div>
        ),
      },
      {
        dataField: "size",
        text: "Size",
      },
      {
        dataField: "seatingCapacity",
        text: "Seating Capacity",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="room-type-notes">
            <EllipsisWithTooltip placement="bottom">{cell}</EllipsisWithTooltip>
          </div>
        ),
      },
      {
        dataField: "video",
        text: "Video",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="room-type-notes">
            <EllipsisWithTooltip placement="bottom">{cell}</EllipsisWithTooltip>
          </div>
        ),
      },
      {
        dataField: "",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <Dropdown
            onClick={(e) => {
              e.stopPropagation();
            }}
            className="more-action"
            alignRight
          >
            <Dropdown.Toggle
             disabled={!this.props?.hasEditPermission}
              className="btn-outline-primary btn btn-primary btn-ghost more"
              id="dropdown-more"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
              </svg>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                key={"edit"}
                eventKey={"edit"}
                 onClick={()=>this.editConferenceRoom(row)}
              >
                Edit
              </Dropdown.Item>
              <Dropdown.Item
                key={"delete"}
                eventKey={"delete"}
                 onClick={()=>this.deleteConfereneRoom(row)}
              >
                Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ),
      },
    ];

    const {
      leftPanelLoader,
      roomTypeData,
      rightTableData,
      leftPanelDates,
      currentSelectedDate,
      showRoomConfigurationModal,
      showAddRoomTypeModal,
      calenderStartDateShow,
      conferenceRoomRowEdit,
      conferenceRoomData,
      isDataSubmitting,
      showAddCoferenceRoomModal,
      isRoomTypesArrayModified,
      hideConferenceSection
    } = this.state;

    return (
      <div className="body-section">
        <div className="heading">Guest Rooms</div>
        <div className="budget-settings d-flex justify-content-start">
          <div className="left-panel">
            <ToolkitProvider
              keyField="ptoID"
              data={leftPanelDates}
              columns={searchGridColumns}
              search
            >
              {(props: {
                searchProps: JSX.IntrinsicAttributes;
                baseProps: JSX.IntrinsicAttributes;
              }) => (
                <div>
                  <div className="search-header">
                    <SearchBar
                      {...props.searchProps}
                      placeholder={"Search..."}
                    />
                  </div>

                  {leftPanelLoader ? (
                    <ReactPageLoader useas="timeOffSettingsLeftPanel" />
                  ) : (
                    <div className="left-body">
                      <BootstrapTable
                        {...props.baseProps}
                        keyField="date"
                        rowEvents={this.rowEvents}
                        hover
                        condensed
                        selectRow={this.state.isSelected ? selectRow : hideRow}
                      />
{ this.props?.hasEditPermission&&
                      <div
                        className="add-Budget"
                        onClick={this.calenderDateDisplay}
                      >
                        Add Room Configuration
                      </div>
  }

                      {calenderStartDateShow && (
                        <div className="calender">
                          <OutsideClickHandler
                            onOutsideClick={this.calenderDateHide}
                          >
                            <Calendar
                              calendarType={"US"}
                           //   maxDate={new Date()}
                              onChange={(date) => {
                                const value = moment(date).format("MM/DD/YYYY");
                                this.setLeaftPanelDates(value);
                                this.calenderDateHide();
                              }}
                              // value={
                              //   currentSelectedDate
                              //     ? new Date(currentSelectedDate)
                              //     : new Date()
                              // }
                            />
                          </OutsideClickHandler>
                        </div>
                      )}
                      {/* </div> */}

                      {/* <AddRoomConfiguration
                        setLeaftPanelDates={this.setLeaftPanelDates}
                        leftPanelDates={leftPanelDates}
                        showRoomConfigurationModal={showRoomConfigurationModal}
                        handleHideRoomConfigurationModal={
                          this.handleHideRoomConfigurationModal
                        }
                      /> */}
                    </div>
                  )}
                </div>
              )}
            </ToolkitProvider>
          </div>
          <div className="right-panel">
            {this.state.rightPanelLoader ? (
              <ReactPageLoader useas="timeOffSettingsRightPanel" />
            ) : (
              <div>
                <div className="pos-header d-flex align-content-center flex-wrap">
                  {this.state.selected[0]}
                </div>
                <div className="pos-body">
                {rightTableData?.length >0  && <BootstrapTable
                    keyField="uniqueno"
                    data={rightTableData || []}
                    columns={roomGridColumns}
                    rowClasses={rowClasses}
                    hover
                  />
                }
                  {currentSelectedDate &&  this.props?.hasEditPermission  &&(
                    <div
                      className="add-room-type"
                      onClick={() => this.handleShowRoomTypeModal()}
                    >
                      Add Room Type
                    </div>
                  )}
                   {isRoomTypesArrayModified &&  this.props?.hasEditPermission && ( 
                    <div className="fixed-action ml-auto">
                      <div className="d-flex align-content-center flex-wrap">
                        <div className="mr-auto message">
                        {rightTableData?.length>0 &&   <span>You have unsaved changes</span> }
                        </div>
                        <button
                          disabled={isDataSubmitting}
                          type="button"
                          className="btn btn-primary btn-discard"
                          onClick={() => this.discard()}
                        >
                          Discard
                        </button>
{rightTableData?.length>0 &&
                        <button
                          disabled={isDataSubmitting}
                          type="button"
                          className="btn btn-primary"
                            onClick={() => this.saveRoomTypeBulkData()}
                        >
                          Save
                        </button>
  }
                      </div>
                    </div>
                   )} 
                  {showAddRoomTypeModal && (
                    <AddRoomType
                      date={currentSelectedDate}
                      roomTypeRowEdit={this.state.roomTypeRowEdit}
                      hotelId={this.props?.entityRowData?.hotelId}
                      showAddRoomTypeModal={showAddRoomTypeModal}
                      handleHideRoomTypeModal={this.handleHideRoomTypeModal}
                      updateRoomTypesList={this.updateRoomTypesList}
                     
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        {!hideConferenceSection && <>
        <div className="separator-line"></div>
        <div className="heading">Conference Rooms</div>
        <div className="conference-rooms-table">
          <BootstrapTable
            keyField="uniqueno"
            data={conferenceRoomData || []}
            columns={conferenceRoomGridColumns}
            rowClasses={rowClasses}
            hover
          />
{ this.props?.hasEditPermission &&
          <div
            className="add-conference-room-wrapper"
            onClick={() => this.handleShowConferenceRoomModal()}
          >
            Add Conference Room
          </div>}
          {showAddCoferenceRoomModal && (
            <AddConferenceRoom
            isReloadRequired=  {this.props?.isReloadRequired}
              conferenceRoomRowEdit={conferenceRoomRowEdit}
              hotelId={this.props?.entityRowData?.hotelId}
              showAddCoferenceRoomModal={showAddCoferenceRoomModal}
              handleHideConferenceRoomModal={this.handleHideConferenceRoomModal}
            />
          )}
        </div>
        </>
  }
      </div>
    );
  }
}
