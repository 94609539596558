import React, { useRef, useState,useEffect } from "react";
import { Dropdown } from "react-bootstrap";

interface DropdownSection {
  header: string;
  items: Array<{ label: string; value: string }>;
}

interface ShowOnlyDropdownProps {
  defaultValue:any,
  sections: DropdownSection[];
  tabIndex?: number;
  style?: React.CSSProperties;
  onSelect?: (item:any) => void;
}

const ShowOnlyDropdown: React.FC<ShowOnlyDropdownProps> = (props) => {
  const [placeholder, setPlaceholder] = useState("Select Filter");
  const toggleRef = useRef<any>();
  const { sections,  tabIndex, style, onSelect =(item:any)=>{}} = props;

  const onTabKeyDown = (e: React.KeyboardEvent) => {
    e.preventDefault();
    if (toggleRef?.current?.ariaExpanded === "false" && e.key === "Tab") {
      toggleRef?.current?.click();
    }
  };

   // Update selected item when defaultValue changes
   useEffect(() => {
    if (props.defaultValue) {
      setPlaceholder(props.defaultValue?.label);
      
    }
  }, [props.defaultValue]);



  const handleItemClick = (item: any) => {
    setPlaceholder(item?.label);
    onSelect(item)
  };

  return (
    <Dropdown
      style={style}
      tabIndex={tabIndex}
      className="single-select"
      onKeyUp={onTabKeyDown}
     
    >
      <Dropdown.Toggle ref={toggleRef} className={placeholder !== "Select Filter" ? "item-selected" : ""} variant="outline-secondary" id="dropdown-basic">
        {placeholder}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {sections.map((section, index) => (
          <React.Fragment key={index}>
            <Dropdown.Header>{section.header}</Dropdown.Header>
            {section.items.map((item) => (
              <Dropdown.Item
                key={item.value}
                onClick={() => handleItemClick(item)}
              >
                {item.label}
              </Dropdown.Item>
            ))}
            {index < sections.length - 1 && <Dropdown.Divider />}
          </React.Fragment>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ShowOnlyDropdown;
